<div class="d-block">
  <h4 class="mb-3">{{ title }}</h4>
  <canvas
    baseChart
    width="400"
    height="400"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [colors]="chartColors"
    [legend]="chartLegend"
    [chartType]="chartType"
    [plugins]="chartPlugins"
  >
  </canvas>
  <div class="form-group row mt-3 ml-2" *ngIf="chartTypeName">
    <label class="col-4">Charts Type</label>
    <div class="col-8">
      <div class="custom-control custom-radio custom-control-inline">
        <input
          [name]="chartTypeName"
          (click)="changeChartType($event)"
          [id]="chartTypeName + '_0'"
          type="radio"
          class="custom-control-input"
          value="line"
          checked
        />
        <label [for]="chartTypeName + '_0'" class="custom-control-label">Line</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          [name]="chartTypeName"
          (click)="changeChartType($event)"
          [id]="chartTypeName + '_1'"
          type="radio"
          class="custom-control-input"
          value="bar"
        />
        <label [for]="chartTypeName + '_1'" class="custom-control-label">Bar</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          [name]="chartTypeName"
          (click)="changeChartType($event)"
          [id]="chartTypeName + '_2'"
          type="radio"
          class="custom-control-input"
          value="horizontalBar"
        />
        <label [for]="chartTypeName + '_2'" class="custom-control-label">Horizontal bar</label>
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          [name]="chartTypeName"
          (click)="changeChartType($event)"
          [id]="chartTypeName + '_3'"
          type="radio"
          class="custom-control-input"
          value="radar"
        />
        <label [for]="chartTypeName + '_3'" class="custom-control-label">Radar</label>
      </div>
    </div>
  </div>
</div>
