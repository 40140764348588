import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExternalJob } from '../../data/model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { JobService } from '../../services/job.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-job-management-modal',
  templateUrl: './job-management-modal.component.html',
  styleUrls: ['./job-management-modal.component.scss'],
})
export class JobManagementModalComponent implements OnInit {
  jobManagementForm: FormGroup;
  title: string;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  @Input() jobManagement: ExternalJob;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private statusHelperService: StatusHelperService,
    private jobService: JobService,
    private responseHelperService: ResponseHelperService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.jobManagement) {
      this.title = 'Edit Job';
      this.jobManagementForm = this.formBuilder.group({
        name: [this.jobManagement.name, Validators.required],
        className: [this.jobManagement.className, Validators.required],
        status: [this.jobManagement.status, Validators.required],
        description: [this.jobManagement.description],
        enabled: [this.jobManagement.enabled],
      });
    } else {
      this.title = 'New Job';
      this.jobManagementForm = this.formBuilder.group({
        name: ['', Validators.required],
        className: ['', Validators.required],
        status: ['', Validators.required],
        description: [''],
        enabled: [false],
      });
    }
  }

  ok(): void {
    let id = null;
    this.jobManagementForm.addControl('jobSelector', new FormControl('E'));
    if (this.jobManagement) {
      this.jobManagementForm.addControl('id', new FormControl(this.jobManagement.id));
      id = this.jobManagement.id;
    }
    this.jobService.createOdUpdateRewardType(id, this.jobManagementForm.value).subscribe(
      () => {
        this.responseHelperService.success('Job successfully updated', true);
        this.successEvent.emit();
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
