import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppDataService } from '../app-data.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Filter, SearchFilters } from '../data/class';
import { OrgConfiguration } from '../data/model';
import { IdentifierTypesModalComponent } from '../identifier-types-modal/identifier-types-modal.component';
import { SearchFilterComponent } from '../search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';
import { TableHelperService } from '../services/helpers/table-helper.service';
import { OrganizationConfigurationService } from '../services/organization-configuration.service';
import { OrganizationsService } from '../services/organizations.service';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-identifier-types-tab',
  templateUrl: './identifier-types-tab.component.html',
  styleUrls: ['./identifier-types-tab.component.scss'],
})
export class IdentifierTypesTabComponent implements OnInit {
  @Input() public personId: number;
  @Input() public orgUsername: string;
  organizationId: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  faTrash = faTrash;
  faPencilAlt = faPencilAlt;

  searchText = null;
  roleScope = null;
  show = true;
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters = new SearchFilters();
  lock: boolean;
  lengthToCompare: number;
  lockedTables = false;
  helpers: any;
  filterButton: boolean = false;
  filterdata: SearchFilters;
  appliedFilters: Filter[];
  filterData: any;

  identifiers: any[] = [];

  constructor(
    private toastr: ToastrService,
    private modalHelper: NgbModal,
    private organizationsService: OrganizationsService,
    private globalData: AppDataService,
    private organizationConfigurationService: OrganizationConfigurationService,
    private applyfilterService: ApplyFilterService,
    private utiltyService: UtilityService,
    private tableHelperService: TableHelperService
  ) {}

  ngOnInit(): void {
    const getOrgByUsername = this.organizationsService.getOrgByUsername.bind(this.organizationsService);
    getOrgByUsername(this.orgUsername).then((data: any) => {
      this.organizationId = data.entity.id;
      this.initNewSearch();
    });

    this.searchFilters = {
      formName: 'campaignSearch',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'roleScope',
          placeholder: 'Select roles',
          type: 'array',
          choices: this.tableHelperService.getRoles(),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          label: 'Roles',
        },
      ],
    };
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getIdentifierTypes(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    this.filter.orgId = this.organizationId;
    this.filter.cfgCode = 'InstrumentTypeFilter';
    this.filter.search = this.searchText;
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getIdentifierTypes(concat: boolean): void {
    this.filter = this.getFilters(this.filter);
    this.isLoading = true;
    let result;
    result = this.organizationConfigurationService
      .getAllOrganizationConfigurations(this.utiltyService.removeNullAndEmptyProperties(this.filter))
      .subscribe(
        (data: any) => {
          if (concat) {
            this.identifiers = this.identifiers.concat(data.entity.aaData);
          } else {
            this.identifiers = data.entity.aaData;
          }
          this.lengthToCompare = data.entity.aaData.length;
          this.newLengthEvent.emit(this.lengthToCompare);
          this.filter.offset += this.limit;
          this.lockedTables = data.entity.aaData.length !== this.limit;
          this.lock = this.lockedTables;
          this.isLoading = false;
        },
        () => {
          this.lockedTables = false;
          this.isLoading = false;
          this.lock = false;
          this.toastr.error('Error occured!');
        }
      );
  }

  openIdentifierModal(orgConfiguration: OrgConfiguration): void {
    this.openModal(orgConfiguration);
  }

  openDeleteIdentifierModal(orgConfiguration: OrgConfiguration): void {
    this.deleteIdentifier(orgConfiguration);
  }

  FilterModel(): void {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Roles';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  openModal(orgConfiguration: OrgConfiguration): void {
    const instance = this.modalHelper.open(IdentifierTypesModalComponent);
    instance.componentInstance.orgConfiguration = orgConfiguration;
    instance.componentInstance.organizationId = this.organizationId;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.toastr.success(
          `Organization configuration successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`
        );
        this.initNewSearch();
        instance.close();
      }
    });
    instance.componentInstance.errorEvent.subscribe((data: any) => {
      this.toastr.error(data.error.error);
    });
  }

  deleteIdentifier(orgConfiguration: OrgConfiguration): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.id = orgConfiguration.id;
    instance.componentInstance.text = 'Delete organization configuration type with ID: ' + orgConfiguration.id;
    instance.componentInstance.header = 'Organization configuration types';
    instance.componentInstance.title = 'Organization configuration types';

    instance.componentInstance.successEvent.subscribe(() => {
      this.organizationConfigurationService.deleteOrganizationConfiguration(orgConfiguration.id).subscribe(() => {
        this.toastr.success('Organization configuration successfully removed');
        this.initNewSearch();
        instance.close();
      });
    });
  }
}
