<div class="checkbox checkbox-info pull-left">
  <mat-checkbox name="showAllCampaigns" [(ngModel)]="showAllCampaigns">Display All Programs</mat-checkbox>
</div>
<table class="table table-hover">
  <thead>
    <tr>
      <td>Program</td>
      <td>Max Reward</td>
      <td>Program Status</td>
      <td></td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let camp of campaigns | callback: filterCampaigns.bind(this)">
      <!-- need trackBy: camp.id? -->
      <td>{{ camp.name }}</td>
      <td>{{ camp.cap }} {{ camp.capRewardCodes }}</td>
      <td>{{ camp.status }}</td>
      <td [class]="">
        <fa-icon [icon]="faInfoCircle" (click)="showCampaignDetails(camp)" id="campaignInfoPanelInfoIcon"></fa-icon>
      </td>
    </tr>
  </tbody>
</table>
