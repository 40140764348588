import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class PartyRelationshipService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getPartyRelationships(): any {
    return this.http.get(this.globalData.baseUrl + 'party-relationship-types');
  }
}
