import { AppEnvironmentData } from './environment.type';

export const environment: AppEnvironmentData = {
  production: true,
  baseHref: '',
  baseUrl: 'cheetah/api/',
  baseChirpUrl: 'chirp/api/',
  baseGoatUrl: 'goat/api/',
  // These values are populated via the dev.yml file during Github Action builds
  releaseName: '#RELEASE_NAME#',
  buildNumber: '#BUILD_NUMBER#',
};
