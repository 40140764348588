<div>
  <div>
    <div class="full-height-scroll" *ngIf="show">
      <div class="filters_container2">
        <div class="right_container3">
          <button class="filter-btn" (click)="FilterModel()">
            FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
          </button>
          <button class="btn-add" style="height: 36px" (click)="communicationStrategyModal()">ADD STRATEGY</button>
        </div>
      </div>
      <app-filter-tags
        *ngIf="searchFilters"
        isPopup="true"
        [(filterButton)]="filterButton"
        [(searchFilters)]="searchFilters"
        [(appliedFilters)]="appliedFilters"
      ></app-filter-tags>
      <div class="table-communication-strategy">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th style="width: 28px"></th>
              <th class="table_field_name">Name</th>
              <th class="table_field_name">Expiring promotions</th>
              <th class="table_field_name">New promotions</th>
              <th class="table_field_name">No activity</th>
              <th class="table_field_name">Activity reward</th>
              <th class="table_field_name">Priority</th>
              <th class="table_field_name">Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let cs of communicationStrategies">
              <tr>
                <td (click)="selectCommStrategy($event, cs.id)">
                  <span *ngIf="selectedId !== cs.id" title="Expand to see history" [hidden]="!cs.id">
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                  </span>
                  <span *ngIf="selectedId === cs.id" title="Expand to see history" [hidden]="!cs.id">
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                  </span>
                </td>
                <td>
                  <!-- <a routerLink="/communication-strategy/{{ cs.id }}" class="link_next_page">{{ cs.name }}</a> -->
                  <div>{{ cs.name }}</div>
                </td>
                <td>
                  <a routerLink="/communications/{{ cs.expiringPromotionsId }}">{{ cs.expiringPromotionsName }}</a>
                </td>
                <td>
                  <a routerLink="/communications/{{ cs.newPromotionsId }}">{{ cs.newPromotionsName }}</a>
                </td>
                <td>
                  <a routerLink="/communications/{{ cs.noActivityId }}">{{ cs.noActivityName }}</a>
                </td>
                <td>
                  <a routerLink="/communications/{{ cs.activityRewardId }}">{{ cs.activityRewardName }}</a>
                </td>
                <td>{{ cs.priority }}</td>
                <td>
                  <div class="float-left actions inline-table">
                    <button
                      class="btn btn-outline-primary btn-sm edit action"
                      (click)="addConfig(cs.id)"
                      title="Add configuration"
                    >
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                    <a
                      class="btn btn-outline-primary btn-sm edit action"
                      (click)="communicationStrategyModal(cs)"
                      title="Edit"
                      ><fa-icon [icon]="faPencilAlt"></fa-icon
                    ></a>
                    <button
                      class="btn btn-outline-primary btn-sm edit action"
                      [disabled]="cs.status === 'DELETED'"
                      (click)="deleteCommunicationStrategy(cs)"
                      title="Delete"
                    >
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </td>
              </tr>
              <tr *ngIf="selectedId === cs.id && configurations?.length > 0">
                <td colspan="8" style="background: #e3f3fe; padding: 18px 18px 0 18px">
                  <div class="table_sub_container" *ngFor="let conf of configurations">
                    <div class="column" style="width: 180px">
                      <span class="heading">Endpoint type:</span>
                      <span style="margin: 0 10px">
                        <img
                          src="../../../assets/images/Api.png"
                          alt="Vector"
                          style="margin-right: 10px; width: 24px; height: 24px"
                          *ngIf="conf.endpointTypeName === 'API'"
                        />
                        <fa-icon [icon]="faPhoneAlt" *ngIf="conf.endpointTypeName === 'PHONE'"></fa-icon>
                        <fa-icon [icon]="faHome" *ngIf="conf.endpointTypeName === 'ADDRESS'"></fa-icon>
                        <fa-icon [icon]="faAt" *ngIf="conf.endpointTypeName === 'EMAIL'"></fa-icon>
                      </span>
                      <span class="txt" (click)="ontest(conf.endpointTypeName)">{{ conf.endpointTypeName }}</span>
                    </div>
                    <div class="column">
                      <span class="heading" style="width: 81px">Send in period:</span>

                      <span class="txt" style="width: 112px"
                        >{{ createDateFromTime(conf.fromTime) | date: "hh:mm a" }} -
                        {{ createDateFromTime(conf.toTime) | date: "hh:mm a" }}</span
                      >
                    </div>
                    <div class="column">
                      <span class="heading" style="width: 98px">Black out periods:</span>
                      <span class="txt" style="width: 130px">{{ conf.blackOutPeriods }}</span>
                    </div>
                    <div class="column">
                      <span class="heading" style="width: 120px">Communications cap:</span>
                      <div style="width: 200px; display: flex; flex-direction: row; flex-grow: wrap; gap: 8px">
                        <div *ngFor="let cap of conf.commCaps; let i = index">
                          <span class="txt" *ngIf="cap.maxCommunicationsNum && cap.maxCommunicationsPeriod"
                            >{{ cap.maxCommunicationsNum }} per {{ cap.maxCommunicationsPeriod }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <span class="heading">Filters:</span>
                      <div style="width: 200px; display: flex; flex-direction: row; flex-grow: wrap; gap: 8px">
                        <span (click)="sampldData(conf)">{{ conf.filters ? conf.filters.length : 0 }}</span>
                      </div>
                    </div>
                    <div class="edit_btn">
                      <button
                        class="btn btn-outline-primary btn-sm edit action"
                        style="background: #fff"
                        (click)="editConfigurationModal(conf)"
                        title="Edit"
                      >
                        <fa-icon [icon]="faPencilAlt"></fa-icon>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="selectedId === cs.id && configurations?.length === 0">
                <td colspan="8" style="background: #e3f3fe">
                  <div class="no_data">No Data</div>
                </td>
              </tr>
            </ng-container>

            <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list
        (onScroll)="getCommunicationStrategy(true)"
        [lock]="lock"
        [isLoading]="isLoading"
      ></app-add-more-data-to-list>
    </div>
  </div>
  <!-- <div class="col-sm-3">
    <div class="row justify-content-center mb-30">
      <h2><span class="light-blue">Configuration:</span></h2>
    </div>
    <div id="contact-1" class="tab-pane active" [hidden]="!configurations || configurations.length === 0">
      <div class="border-card margin-top-10" *ngFor="let conf of configurations">
        <div class="row integration-details-bar"></div>
        <div class="border-card top-margin-border-0">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <span class="float-left"><b>ID:</b></span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ conf.id }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left"><b>Send in period</b></span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right"
                  >{{ createDateFromTime(conf.fromTime) | date: "hh:mm a" }} -
                  {{ createDateFromTime(conf.toTime) | date: "hh:mm a" }}</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span><b>Black out periods: </b></span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right word-break-break-all">{{ conf.blackOutPeriods }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span><b>Communications cap:</b></span>
              </div>
              <div class="col-md-6">
                <ul class="list-none">
                  <li *ngFor="let cap of conf.commCaps; let i = index">
                    <span
                      class="panel-container text-right"
                      *ngIf="cap.maxCommunicationsNum && cap.maxCommunicationsPeriod"
                      >{{ cap.maxCommunicationsNum }} per {{ cap.maxCommunicationsPeriod }}</span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left"><b>Opt-In/Opt-Out</b></span>
              </div>
              <div class="col-md-6">
                <span class="panel-container text-right">{{ conf.optInOptOut }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span><b>Endpoint type:</b></span>
              </div>
              <div class="col-md-6">
                <span class="panel-container text-right">{{ conf.endpointTypeName }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-card mt-0">
          <div class="col-md-2"></div>
          <div class="col-md-4"></div>
          <div class="col-md-6 panel-container">
            <button
              class="btn mr-1 btn-dark-gray btn-xs delete action float-right"
              (click)="deleteConfiguration(conf.id, i)"
              title="Delete"
            >
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
            <button
              class="btn mr-1 btn-dark-gray btn-xs edit action float-right"
              (click)="editConfigurationModal(conf)"
              title="Edit"
            >
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div> -->
</div>
