import { environment } from '../../../environments/environment';
import { getDecodedToken } from '../token/token.service';

let appConfig;
let loggingInitialized: boolean;

function getNewRelic(): typeof window.newrelic {
  return window.newrelic;
}

export async function initLogging(): Promise<void> {
  configLoggingServices();
  loggingInitialized = true;
}

function configNewRelic() {
  const newrelic = getNewRelic();
  if (!newrelic)
    throw new Error(`New Relic not found on window.newrelic.
    Check that the new relic script loaded in the index.html file and
    initLogging was called at app startup`);

  newrelic.addRelease(environment.releaseName, environment.buildNumber);
}

function configLoggingServices() {
  configNewRelic();
}

function newRelicReady() {
  return !!getNewRelic();
}

enum LogMethod {
  error = 'error',
  info = 'info',
  warn = 'warn',
  debug = 'debug',
}

type LogParams = { method: LogMethod; message: string | Error; namespace?: string; data?: Record<string, any> };

function logNewRelicError(params: LogParams) {
  const { message, namespace, data } = params;
  const newrelic = getNewRelic();
  if (newrelic) {
    newrelic.noticeError(message, data);
  }
}

function decorateLogParamsWithMetaData(params: LogParams) {
  const { data, namespace } = params;
  const decodedToken = getDecodedToken();
  const decoratedData = {
    ...data,
    namespace,
    userId: decodedToken?.partyId,
  };
  return {
    ...params,
    data: decoratedData,
  };
}

function logError(params: LogParams) {
  const { message, namespace, data } = params;
  const decodedToken = getDecodedToken();
  const decoratedParams = decorateLogParamsWithMetaData(params);
  logNewRelicError(decoratedParams);
  consoleLog(decoratedParams);
}

function log(params: LogParams) {
  const { method, data, message } = params;
  switch (method) {
    case LogMethod.info:
    case LogMethod.warn:
    case LogMethod.debug:
      consoleLog(params);
      break;
    case LogMethod.error:
      logError(params);
      break;
    default:
      throwMethodNotFoundError(method);
  }
}

function consoleLog(params: Pick<LogParams, 'method' | 'data' | 'message' | 'namespace'>): void {
  const { method, data = {}, message, namespace } = params;
  console[method](message, {
    namespace,
    ...data,
  });
}

function throwMethodNotFoundError(method: never): void {
  throw new Error('Method was not included in log switch method. This should never happen');
}

type LogMethodParams = Omit<LogParams, 'method'>;

export type Logger = {
  warn: (params: LogMethodParams) => void;
  debug: (params: LogMethodParams) => void;
  error: (params: LogMethodParams) => void;
  info: (params: LogMethodParams) => void;
};

type CreateLogMethodParams = {
  method: LogMethod;
  namespace: string;
};

function createLogMethod({ method, namespace }: CreateLogMethodParams): (params: LogMethodParams) => void {
  return function (params: LogMethodParams) {
    log({
      namespace,
      ...params,
      method,
    });
  };
}

/**
 * Maps through the log methods and returns log methods that decorate the params with the namespace.
 */
function getLogger(params: { namespace?: string }): Logger {
  const { namespace } = params;
  return Object.keys(LogMethod).reduce((logger, methodKey) => {
    const newLogger = { ...logger };
    newLogger[methodKey] = (params: LogMethodParams) =>
      log({
        ...params,
        namespace,
        method: methodKey as LogMethod,
      });
    return newLogger;
  }, {} as Logger);
}

export default getLogger;
