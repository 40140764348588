<div class="row m-b search-filter-header-top full-width">
  <div class="col-md-10">
    <button
      type="button"
      class="btn btn-sm btn-primary search-filter-load-activity"
      (click)="searchFilters.addActivity()"
      *ngIf="globalData.isPartner"
    >
      <fa-icon [icon]="faRunning"></fa-icon> Load New Activity
    </button>
  </div>
  <div class="col-md-2">
    <a class="float-right clear-filter-nrm" (click)="searchFilters.clearAll()"
      ><span class="float-right">Clear all filters</span></a
    >
  </div>
</div>
<div class="row search-filter-header-top">
  <div class="col-md-10">
    <button
      type="button"
      class="btn btn-sm btn-primary ng-scope search-filter-resubmit"
      (click)="searchFilters.triggerProcessing()"
      [disabled]="selectedActivitiesCount === 0"
      *ngIf="globalData.isCSRManager"
    >
      <span [hidden]="!(selectedActivitiesCount > 0)">({{ selectedActivitiesCount }})</span>
      <span class="ng-binding ng-scope"><fa-icon [icon]="faCheckCircle"></fa-icon>&nbsp;Re-submit for processing</span>
    </button>
  </div>
  <div class="col-md-2" *ngIf="searchFilters.formName === 'activityTabSearch'">
    <button
      type="submit"
      class="btn btn-primary search-filter-apply float-right"
      [disabled]="globalData.isRequestInProgress"
    >
      <fa-icon [hidden]="!globalData.isRequestInProgress" [icon]="faSpinner" class="rotate fa-fw"></fa-icon>
      <span [hidden]="!!globalData.isRequestInProgress">Apply</span>
    </button>
  </div>
</div>
