import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
  constructor() {}

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    window.history.go(-2);
  }
}
