const audioCtx = new AudioContext();
var xhrOverride = new XMLHttpRequest();
xhrOverride.responseType = 'arraybuffer';

export async function playWav(wavData: ArrayBuffer) {
  const source = audioCtx.createBufferSource();
  await audioCtx.decodeAudioData(wavData, (data) => (source.buffer = data));
  await source.connect(audioCtx.destination);
  source.start();
  return source;
}

export async function fetchWavData(signedUrl: string): Promise<ArrayBuffer | undefined> {
  const response = await fetch(signedUrl);
  if (response.status === 404) {
    console.log('Files not found');
  } else if (response.status === 401) {
    console.log('Token Expired');
  } else if (response.status === 400) {
    console.log('An error has occurred');
  } else {
    return response.arrayBuffer();
  }
}
