import { Segment, ContentGroup } from './model';
export class Filter {
  limit?: number;
  offset?: number;
  start?: number;
  dir?: string;
  column?: number;
  statuses?: string[];
  fromDate?: string;
  toDate?: string;
  createdOnStartDate?: string;
  createdOnEndDate?: string;
  search?: string;
  code?: string;
  stageLoadId?: number;
  campaigns?: any[];
  name: string;
  value?: any;
  label?: string;
  placeholder?: string;
  inputType?: string;
  singleSelection?: boolean;
  choices?: Choice[];
  nameProp?: string;
  valueProp?: any;
  selectedCount?: number;
  type?: string;
  singleLabel?: string;
  level?: number;
  section?: Section;
  client?: any;
  sponsorId?: number;
  packageName?: string;
  promotionId?: number;
  types?: any[];
  memberId?: number;
  jobSelector?: string;
  commId?: number;
  effectiveDate?: string;
  excludeDeleted?: boolean;
  accounts?: any[];
  allowEmpty?: boolean;
  member?: number;
  pSponsorId?: number;
  status?: string;
  partyId?: number;
  activityId?: number;
  if?: boolean;
  includeDeleted?: boolean;
  selectedPackageId?: number;
  includeCount?: boolean;
  sponsorshipTag?: string;
  promotions?: any[];
  multiple?: boolean;
  packageId?: number;
  segmentIds?: number[];
  parentId?: number;
  communicationName?: string;
  endDate?: boolean;
  withoutParent?: boolean;
  clearCallback?: () => void;
  onChangeCallback?: (value: any) => void;
  cfgCode?: string;
  orgId?: number;
  hideableSegments?: boolean;
  segmentMembers?: any[];
  protocolIds?: any[];
}

export class Choice {
  state?: boolean;
  value: any;
  isDefault?: boolean;
  label?: string;
}

export class SearchFilters {
  filters: Filter[];
  sections?: Section[];
  title?: string;
  inputClass?: string;
  waitUntilApply?: boolean;
  showSearchFilters?: boolean;
  formName: string;
  segmentLessFilter?: Filter;
  buttonsWithText?: boolean;
  addEntityButtons?: Button[];
  newUser?: () => void;
  applyFilters?: () => void;
  searchCallback?: () => void;
  clearAll?: () => void;
  onClear?: () => void;
  clearSelf?: (filter: Filter, individualValue: any) => void;
  getFilters?: (existing: Filter) => any;
  getFilterRef?: (filterName: string) => Filter;
  triggerProcessing?: () => void;
  addActivity?: () => void;
}

export class Section {
  key: string;
  name?: string;
  open?: boolean;
  additionalRow?: boolean;
}

export class Gender {
  name: string;
}

export class Email {
  emailAddress: string;
  isPrimary: boolean;
}

export class Tab {
  title: string;
  active: boolean;
  idx?: number;
}

export class Button {
  class?: string;
  icon?: any;
  title?: string;
  buttonText: string;
  showButtonText?: boolean;
  callback?: () => void;
  if?: boolean;
  disabled?: boolean;
}

export class EndpointType {
  name: string;
}

export class CommunicationType {
  id: number;
  name: string;
  description?: string;
}

export class CommunicationAttribute {
  name: string;
  value: string;
  effectiveDate?: Date;
  expirationDate?: Date;
  displayName?: string;
}

export class CommunicationHistory {
  id: number;
  content: string;
  communicationTimestamp?: string;
  deliveryProtocol?: any;
  delivered?: boolean;
  processingStatus?: any;
}

export type CallFlowCommunicationContent = {
  communicationId: number;
  languageId: number;
  id?: number;
  template?: { callFlowId?: string | undefined; defaultCallFromNumber?: string | undefined } | undefined;
};

export class CommunicationContent {
  communicationId: number;
  languageId: number;
  id?: number;
  subject?: string;
  template?: string;
}

export class CommunicationSegment {
  segment: Segment;
  fromDate: Date;
  toDate: Date;
  name?: string;
}

export class ShowDeleted {
  state: boolean;
}

export class DateObject {
  year: number;
  month: number;
  day: number;
}

export class ProcessingLogSearch {
  createdOn: string;
  message: string;
  rejectCode: string;
  promotionSponsorId: string;
  campaignId: string;
  programId: string;
  accountTxnId: string;
  amount: string;
}

export class ImportFile {
  importType: string;
  fieldMappings: any;
  memberInstrument: MemberInstrument[];
  customAttribute: CustomAttribute[];
  segmentMapping: SegmentMapping[];
  memberRelationship: MemberRelationship[];
  selectedField?: any;
  selectedEditField?: any;
  dropdownDisplay?: any;
  expandedLookup?: boolean;
  openFieldChevronList?: ManifestFieldChevronList;
  fieldMappingDefinition?: any;
  displayNames?: any;
  selected?: boolean;
  complexTypesDropdown?: any;
  typeFields?: any;
  selectedComplexType?: any;
  selectedType?: any;
}

export class FieldProperty {
  fieldIndex: number;
  fieldName: string;
  fieldDefault: string;
  hashField: string;
  concatField: string;
  concatDelimiter: string;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
}

export class MemberInstrument {
  instrumentTypeField: string;
  instrumentTypeDefault: string;
  fieldIndex: number;
  primary: boolean;
  expiryField: string;
  expiryDefault: string;
  activationField: string;
  activationDefault: string;
  hashField: string;
  fieldLength: string;
  fieldRegex: string;
  fieldName: string;
  concatField: string;
  concatDelimiter: string;
  required: boolean;
}

export class CustomAttribute {
  attrName: string;
  attrNameIndex: number;
  fieldIndex: number;
  attrValueDefault: string;
  fieldName: string;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
  effectiveDateIndex: string;
  expirationDateIndex: string;
}

export class SegmentMapping {
  fieldName: string;
  fieldIndex: number;
  fieldDefault: string;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
  concatField: string;
  concatDelimiter: string;
  effectiveDateIndex: string;
  replace: boolean;
}

export class MemberRelationship {
  relationshipName: string;
  bidirectional: boolean;
  fromRoleName: string;
  toRoleName: string;
  attrName: string;
  fromDate: string;
  toDate: string;
  fieldName: string;
  fieldIndex: number;
  required: boolean;
  fieldLength: number;
  fieldRegex: string;
}

export class ManifestFieldChevronList {
  fieldPropertyIndexes: any;
  memberInstrumentIndex: number;
  customAttributeIndex: number;
  segmentMappingIndex: number;
  memberRelationshipIndex: number;
}

export class ChevronList {
  alternativeIndex: number;
  promoCodeIndex: number;
  rewardTypeIndex: number;
  behaviorCodeIndex: number;
}

export class OutreachConfigSchema30 {
  cfgRef: string;
  entryConditions: EntryConditions;
  exitConditions: ExitConditions;
  instanceAttributeBindings: InstanceAttributeBindings[];
}

export class EntryConditions {
  genderRestriction: GenderRestriction;
  ageRestrictions: any[];
  preferenceRestrictions: PreferenceRestriction[];
  attributeRestrictions: AttributeRestriction[];
  temporalConditions: TemporalConditions[];
}

export class GenderRestriction {
  gender: string;
  condition: boolean;
  message: string;
}

export class AgeRestriction {
  period: string;
  periodCount: number;
  direction: string;
  message: string;
}

export class PreferenceRestriction {
  channel: string;
  condition: boolean;
  message: string;
}

export class AttributeRestriction {
  attrName: string;
  attrValue: string;
  condition: boolean;
  message: string;
}

export class TemporalConditions {
  timeFromEventType: string;
  period: string;
  periodCount: number;
  deltaPlus: number;
  deltaMinus: number;
  attrName: number;
  attrValue: number;
  endpointType: string;
  behaviorCode: string;
  promotionCode: string;
  message: string;
}

export class ExitConditions {
  attributeRestrictions: AttributeRestrictions[];
  optOutPreference: OptOutPreference[];
  rewards: Reward[];
  behaviors: Behavior[];
}

export class AttributeRestrictions {
  attrName: string;
  attrValue: string;
  condition: boolean;
  message: string;
}

export class OptOutPreference {
  channel: string;
  condition: boolean;
  message: string;
}

export class Reward {
  promotionList: string[];
  rewardTypes: string[];
  period: string;
  periodCount: string;
  message: string;
}

export class Behavior {
  behaviorCodes: string[];
  period: string;
  periodCount: number;
  message: string;
}

export class InstanceAttributeBindings {
  bindingName: string;
  bindingValue: string;
  message: string;
}

export type CallFlowOutreachContent = {
  id?: number;
  contentGroup: ContentGroup;
  endpointType: string;
  language: any;
  subject?: string;
  segment?: any;
  status?: string;
  brandCode?: string;
  template?: { callFlowId?: string | undefined; defaultCallFromNumber?: string | undefined } | undefined;
};
