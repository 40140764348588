import { OutreachConfigSchema30, EntryConditions, ExitConditions, InstanceAttributeBindings } from './../../data/class';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { faChevronUp, faChevronDown, faTimes, faSave, faPen } from '@fortawesome/free-solid-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ToastrService } from 'ngx-toastr';
import { Communication } from 'src/app/data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonModalComponent } from 'src/app/components/json-modal/json-modal.component';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-communication-configuration-tab',
  templateUrl: './communication-configuration-tab.component.html',
  styleUrls: ['./communication-configuration-tab.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state(
        'open',
        style({
          height: '*',
        })
      ),
      state(
        'close',
        style({
          height: '0px',
          visibility: 'hidden',
        })
      ),
      transition('open <=> close', animate(200)),
    ]),
  ],
  providers: [JsonPipe],
})
export class CommunicationConfigurationTabComponent implements OnChanges {
  communicationConnfiguration: OutreachConfigSchema30;
  @Input() selectedComm: Communication;
  @Input() isValid: boolean;
  @Input() commName: string;
  rulesJson: OutreachConfigSchema30;
  numberOfBoxes = 11;
  expandedLookup: boolean[] = Array(this.numberOfBoxes).fill(false);
  enabledSave = false;
  isGenderRestrictionsValid = true;
  isAgeRestrictionsValid = true;
  isPreferenceRestrictionsValid = true;
  isEntryAttributeRestrictionsValid = true;
  isTemporalConditionsValid = true;
  isExitAttributeRestrictionsValid = true;
  isPreferenceConditionsValid = true;
  isRewardsValid = true;
  isBehaviorsValid = true;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  faSave = faSave;
  faPen = faPen;
  rules: string;

  constructor(
    private communicationsService: CommunicationsService,
    private toastr: ToastrService,
    private modalHelper: NgbModal,
    private jsonPipe: JsonPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedComm) {
      this.rulesJson = null;
      this.parseJson(changes.selectedComm.currentValue.rules);
    }
  }

  resizeIbox(index?: number): void {
    this.expandedLookup[index] = !this.expandedLookup[index];
  }

  parseJson(rules: string): void {
    if (rules !== null && rules !== undefined) {
      this.rulesJson = JSON.parse(rules);
    }
    if (this.rulesJson === null || this.isObjectEmpty(this.rulesJson)) {
      this.initializeRulesJson();
    }
  }

  initializeRulesJson(): void {
    this.rulesJson = new OutreachConfigSchema30();
    this.rulesJson.cfgRef = this.selectedComm.commTypeId + '-' + this.selectedComm.name;
    this.rulesJson.entryConditions = new EntryConditions();
    this.rulesJson.exitConditions = new ExitConditions();
    this.rulesJson.instanceAttributeBindings = new Array<InstanceAttributeBindings>();
  }

  isObjectEmpty(obj: any): boolean {
    if (obj === null || obj === undefined) {
      return true;
    }
    return Object.keys(obj).length === 0;
  }

  entryConditionsChangeHandler(restriction: any, restrictionType: string): void {
    switch (restrictionType) {
      case 'genderRestriction':
        if (this.isObjectEmpty(restriction) || restriction === undefined) {
          delete this.rulesJson.entryConditions[restrictionType];
          this.isGenderRestrictionsValid = true;
        } else {
          this.rulesJson.entryConditions[restrictionType] = restriction;
        }
        break;
      case 'ageRestrictions':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.entryConditions[restrictionType];
          this.isAgeRestrictionsValid = true;
        } else {
          this.rulesJson.entryConditions[restrictionType] = restriction;
        }
        break;
      case 'preferenceRestrictions':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.entryConditions[restrictionType];
          this.isPreferenceRestrictionsValid = true;
        } else {
          this.rulesJson.entryConditions[restrictionType] = restriction;
        }
        break;
      case 'attributeRestrictions':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.entryConditions[restrictionType];
          this.isEntryAttributeRestrictionsValid = true;
        } else {
          this.rulesJson.entryConditions[restrictionType] = restriction;
        }
        break;
      case 'temporalConditions':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.entryConditions[restrictionType];
          this.isTemporalConditionsValid = true;
        } else {
          this.rulesJson.entryConditions[restrictionType] = restriction;
        }
        break;
    }
  }

  exitConditionsChangeHandler(restriction: any, restrictionType: string): void {
    switch (restrictionType) {
      case 'attributeRestrictions':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.exitConditions[restrictionType];
          this.isExitAttributeRestrictionsValid = true;
        } else {
          this.rulesJson.exitConditions[restrictionType] = restriction;
        }
        break;
      case 'optOutPreferences':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.exitConditions[restrictionType];
          this.isPreferenceConditionsValid = true;
        } else {
          this.rulesJson.exitConditions[restrictionType] = restriction;
        }
        break;
      case 'rewards':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.exitConditions[restrictionType];
          this.isRewardsValid = true;
        } else {
          this.rulesJson.exitConditions[restrictionType] = restriction;
        }
        break;
      case 'behaviors':
        if (restriction === null || restriction.length === 0) {
          delete this.rulesJson.exitConditions[restrictionType];
          this.isBehaviorsValid = true;
        } else {
          this.rulesJson.exitConditions[restrictionType] = restriction;
        }
        break;
    }
  }

  instanceAttributeBindingsChangeHandler(restriction: any): void {
    if (restriction === null || restriction.length === 0) {
      delete this.rulesJson.instanceAttributeBindings;
    } else {
      this.rulesJson.instanceAttributeBindings = restriction;
    }
  }

  saveCommunicationConfiguration(): void {
    if (this.rulesJson.cfgRef == null) {
      this.rulesJson.cfgRef = this.selectedComm.commTypeId + '-' + this.selectedComm.name;
    }
    this.communicationsService.updateRulesConfiguration(this.selectedComm.id, this.rulesJson).subscribe(
      (data: any) => {
        this.toastr.success('Communication configuration updated successfully!');
      },
      () => {
        this.toastr.error('Error while updating Communication configuration');
      }
    );
  }

  isBuilderFormValid(): boolean {
    return (
      this.isGenderRestrictionsValid &&
      this.isAgeRestrictionsValid &&
      this.isPreferenceRestrictionsValid &&
      this.isEntryAttributeRestrictionsValid &&
      this.isTemporalConditionsValid &&
      this.isExitAttributeRestrictionsValid &&
      this.isPreferenceConditionsValid &&
      this.isRewardsValid &&
      this.isBehaviorsValid
    );
  }

  copy(): void {
    navigator.clipboard.writeText(this.jsonPipe.transform(this.rulesJson)).then(
      () => {},
      (error) => {
        this.toastr.error(error);
      }
    );
  }

  edit(): void {
    const instance = this.modalHelper.open(JsonModalComponent, { size: 'xl' });
    instance.componentInstance.json = this.rulesJson;
    instance.componentInstance.title = 'Edit configuration';
    instance.componentInstance.canEdit = true;
    instance.componentInstance.jsonChangeCallBack = (json: any) => {};
    instance.componentInstance.successEvent.subscribe((data: any) => {
      instance.close();
      this.rulesJson = JSON.parse(data);
      this.saveCommunicationConfiguration();
    });
  }
}
