import { ReportClassificationHelperService } from './../services/helpers/report-classification-helper.service';
import { Component, Input, OnChanges } from '@angular/core';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ReportsService } from '../services/reports.service';
import { PersonsService } from '../services/persons.service';
import { AppDataService } from '../app-data.service';
import {
  faUpload,
  faChartPie,
  faChartLine,
  faChartBar,
  faThList,
  faSyncAlt,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from '../services/helpers/response-helper.service';

@Component({
  selector: 'app-report-data',
  templateUrl: './report-data.component.html',
  styleUrls: ['./report-data.component.scss'],
})
export class ReportDataComponent implements OnChanges {
  @Input() report: any;
  @Input() externalAppUrl: string;
  @Input() renderForm: boolean;
  reportForm: FormGroup;
  filteredSegments = [];
  chartOpt = {};
  chartOpts = ['BAR CHART', 'PIE CHART', 'LINE CHART'];
  selectedSegments = [];
  faUpload = faUpload;
  faChartPie = faChartPie;
  faChartLine = faChartLine;
  faChartBar = faChartBar;
  faThList = faThList;
  faSyncAlt = faSyncAlt;
  faFile = faFile;

  constructor(
    private statusHelperService: StatusHelperService,
    private reportClassificationHelperService: ReportClassificationHelperService,
    private formBuilder: FormBuilder,
    private reportService: ReportsService,
    private responseHelperService: ResponseHelperService,
    public globalData: AppDataService,
    private personsService: PersonsService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');
  classifications = this.reportClassificationHelperService.getClassifications();

  ngOnChanges(): void {
    if (!this.reportForm && this.renderForm) {
      this.report.reportAttributes.forEach((attr) => {
        if (attr.attrName === '_CHARTTYPE') {
          this.chartOpt = attr.attrValue;
        }
      });

      this.reportForm = this.formBuilder.group({
        name: [this.report.name, Validators.required],
        status: [this.report.status, Validators.required],
        classification: [this.report.classification, Validators.required],
        description: [this.report.description],
        dashboardReport: [this.report.dashboardReport],
        interactiveReport: [this.report.interactiveReport],
        outboundReport: [this.report.outboundReport],
        reportAttributes: [this.report.reportAttributes],
        forStaging: [this.report.forStaging],
        sqlQuery: [this.report.sqlQuery],
        chartOpt: [this.chartOpt],
        externalAppUrl: [this.externalAppUrl],
        rendererTemplateSource: [this.report.rendererTemplateSource],
      });
      this.getPersonSegments();
    }
  }

  getPersonSegments(): void {
    const params = {
      children: true,
    };
    this.personsService.getPersonSegments(this.globalData.userId, params).subscribe((data: any) => {
      if (data.success) {
        this.filteredSegments = data.entity;
      }
    });
  }

  uploadFile(element: any): void {
    const fd = new FormData();
    fd.append('file', element.files[0]);
    const id = element.attributes.getNamedItem('id-el').value;
    this.submitDetails();
  }

  submitDetails(): void {
    let params = JSON.parse(JSON.stringify(this.reportForm.value));
    delete params.chartOpt;
    delete params.externalAppUrl;
    params = {
      ...params,
      clientParams: this.report.clientParams,
      id: this.report.id,
      segmentsCollection: this.report.segmentsCollection,
      systemParams: this.report.systemParams,
    };
    this.reportService.updateDetails(this.report.id, params).subscribe(
      (data: any) => {
        this.responseHelperService.success('Report details successfully updated', false);
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      }
    );
  }

  updateDetails(): void {
    if (this.report.reportAttributes) {
      const reportAttr = this.filterByAttrName('EXTERNAL_APP_URL');
      if (reportAttr && reportAttr.length > 0) {
        this.report.reportAttributes.forEach((attr: any) => {
          if (attr.attrName === 'EXTERNAL_APP_URL' || attr.attrName === null) {
            attr.attrName = 'EXTERNAL_APP_URL';
            attr.attrValue = this.reportForm.value.externalAppUrl;
            attr.reportId = this.report.id;
          }
        });
      } else {
        this.report.reportAttributes.push({
          attrValue: this.reportForm.value.externalAppUrl,
          attrName: 'EXTERNAL_APP_URL',
          reportId: this.report.id,
        });
      }
    } else {
      this.report.reportAttributes = [];
      this.report.reportAttributes.push({
        attrValue: this.reportForm.value.externalAppUrl,
        attrName: 'EXTERNAL_APP_URL',
        reportId: this.report.id,
      });
    }
    this.submitDetails();
  }

  updateChartType(): void {
    this.chartOpt = this.reportForm.value.chartOpt;
    if (this.report.reportAttributes) {
      const reportAttr = this.filterByAttrName('_CHARTTYPE');
      if (reportAttr && reportAttr.length > 0) {
        this.report.reportAttributes.forEach((attr: any) => {
          if (attr.attrName === '_CHARTTYPE' || attr.attrName === null) {
            attr.attrName = '_CHARTTYPE';
            attr.attrValue = this.chartOpt;
            attr.reportId = this.report.id;
          }
        });
      } else {
        this.report.reportAttributes.push({
          attrValue: this.chartOpt,
          attrName: '_CHARTTYPE',
          reportId: this.report.id,
        });
      }
    } else {
      this.report.reportAttributes = [];
      this.report.reportAttributes.push({
        attrValue: this.chartOpt,
        attrName: '_CHARTTYPE',
        reportId: this.report.id,
      });
    }
  }

  filterByAttrName(name: string): any[] | undefined {
    if (this.report && this.report.reportAttributes) {
      return this.report.reportAttributes.filter((item: any) => {
        return item.attrName === name;
      });
    } else {
      return undefined;
    }
  }

  getChartTypeAttr(): void {
    this.report.reportAttributes.forEach((attr: any) => {
      if (attr.attrName === '_CHARTTYPE' || attr.attrName === null) {
        attr.Name = '_CHARTTYPE';
        return attr;
      }
    });
  }
}
