import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { SecurityService } from 'src/app/services/security/security.service';

@Component({
  selector: 'app-redemption-items-modal',
  templateUrl: './redemption-items-modal.component.html',
  styleUrls: ['./redemption-items-modal.component.scss'],
})
export class RedemptionItemsModalComponent {
  @Input() redemptionItems: any[];
  @Input() title: string;
  type = 'redemptionItem';
  constructor(
    private modalHelper: NgbModal,
    public globalData: AppDataService,
    public securityService: SecurityService
  ) {}

  cancel(): void {
    this.modalHelper.dismissAll();
  }
}
