import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from 'src/app/services/communications.service';
import { FormArray } from '@angular/forms';
import { DeliveryProtocol } from 'src/app/data/model';

@Component({
  selector: 'app-delivery-options-modal',
  templateUrl: './delivery-options-modal.component.html',
  styleUrls: ['./delivery-options-modal.component.scss'],
})
export class DeliveryOptionsModalComponent implements OnInit {
  title: string;
  errorMsg = '';
  deliveryOptionsForm: FormGroup;
  faTimes = faTimes;
  faSave = faSave;
  faTrash = faTrash;
  faPlus = faPlus;
  optionsToRemove: any[] = [];
  @Input() delivery: DeliveryProtocol;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.title = 'Delivery options';
    this.deliveryOptionsForm = this.formBuilder.group({
      options: this.formBuilder.array([]),
    });
    if (this.delivery.deliveryOptions.length > 0) {
      this.delivery.deliveryOptions.forEach((option: any) => {
        this.addOption(option.optionName, option.optionValue);
      });
    } else {
      this.addOption();
    }
  }

  ok(): void {
    let formValue: any[] = this.deliveryOptionsForm.controls.options.value;
    formValue.forEach((e: any) => (e.protocolId = this.delivery.id));
    this.optionsToRemove.forEach((option: any) => {
      if (option.id) {
        this.communicationsService.deleteDeliveryOption(option.protocolId, option.id).subscribe(
          () => {},
          (data: any) => {
            this.responseHelperService.error(this, data.error.error);
          }
        );
      }
    });
    this.communicationsService.upsertDeliveryOptions(this.delivery.id, formValue).subscribe(
      (data: any) => {
        this.responseHelperService.success('Delivery Options saved successfully!', true);
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  removeOption(i: number): void {
    this.optionsToRemove.push(this.getOptions().controls[i].value);
    this.getOptions().removeAt(i);
  }

  addOption(optionName?: string, optionValue?: string, id?: number): void {
    this.getOptions().push(this.newOption(optionName, optionValue, id));
  }

  newOption(optionName?: string, optionValue?: string, id?: number): FormGroup {
    return this.formBuilder.group({
      optionName: [optionName || '', Validators.required],
      optionValue: [optionValue || '', Validators.required],
      id: [id],
    });
  }

  getOptions(): FormArray {
    return this.deliveryOptionsForm.controls.options as FormArray;
  }
}
