import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Campaign, ChannelConfiguration, Communication, DeliveryOption, DeliveryProtocol } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ChannelConfigurationService } from 'src/app/services/channel-configuration.service';
import { PromotionService } from 'src/app/services/promotion.service';

import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { Filter } from '../../data/class';
import { Promotion } from '../../data/model';

@Component({
  selector: 'app-channel-configuration-modal',
  templateUrl: './channel-configuration-modal.component.html',
  styleUrls: ['./channel-configuration-modal.component.scss'],
})
export class ChannelConfigurationModalComponent implements OnInit {
  @Input() deliveryProtocol: DeliveryProtocol;
  @Input() channelConfig = new ChannelConfiguration();
  @Input() campaign: Campaign;
  @Input() deliveryOption: DeliveryOption;
  @Input() isDeliveryOption: boolean = true;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string = 'Add/Edit Setting';
  limit = 10;
  channelForm: FormGroup;
  errorMsg: string;
  communications: Communication[] = [];
  promotions: Promotion[] = [];
  lengthToComparePromotion = 10;
  lengthToCompareCommunication = 10;
  filterPromotion = new Filter();
  filterCommunications = new Filter();
  commSelect$ = new Subject<string>();
  promotionSelect$ = new Subject<string>();
  changeValue = false;

  constructor(
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private responseHelperService: ResponseHelperService,
    private communicationsService: CommunicationsService,
    private channelConfigurationService: ChannelConfigurationService,
    private promotionsService: PromotionService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.deliveryOption || this.channelConfig.id) {
      this.channelForm = this.formBuilder.group({
        name: [this.deliveryOption ? this.deliveryOption.optionName : this.channelConfig.name, Validators.required],
        value: [this.deliveryOption ? this.deliveryOption.optionValue : this.channelConfig.value, Validators.required],
        promotionId: [this.channelConfig.id ? this.channelConfig.promotionId : null],
        communicationId: [this.channelConfig.id ? this.channelConfig.communicationId : null],
        sensitive: [this.channelConfig.id ? this.channelConfig.sensitive : this.deliveryOption.sensitive],
      });
    } else {
      this.channelForm = this.formBuilder.group({
        name: ['', Validators.required],
        value: ['', Validators.required],
        promotionId: [],
        communicationId: [],
        sensitive: [false],
      });
    }
    if (!this.isDeliveryOption) {
      this.filterCommunications.offset = 0;
      this.filterCommunications.limit = this.limit;
      this.filterPromotion.offset = 0;
      this.filterPromotion.limit = this.limit;
      this.onSearchCommunications();
      this.onSearchPromotions();
      this.getCommunications(false);
      this.getPromotions(false);
    }
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  saveForm() {
    if (this.isDeliveryOption) {
      var formValues = this.channelForm.value;
      if (this.deliveryOption) {
        this.deliveryOption.optionName = formValues.name;
        this.deliveryOption.optionValue = formValues.value;
        this.deliveryOption.sensitive = formValues.sensitive;
        this.communicationsService.updateDeliveryOptions(this.deliveryOption, this.changeValue).subscribe(
          (data: any) => {
            this.responseHelperService.success('Delivery Options updated successfully!', true);
            this.successEvent.emit('success');
          },
          (data: any) => {
            this.responseHelperService.error(this, data.error.error);
          }
        );
      } else {
        const newDeliveryOption = {
          id: null,
          communicationId: null,
          protocolId: this.deliveryProtocol.id,
          optionName: formValues.name,
          optionValue: formValues.value,
          sensitive: formValues.sensitive,
        };
        this.communicationsService.insertDeliveryOptions(newDeliveryOption).subscribe(
          (data: any) => {
            this.responseHelperService.success('Delivery Options saved successfully!', true);
            this.successEvent.emit('success');
          },
          (data: any) => {
            this.responseHelperService.error(this, data.error.error);
          }
        );
      }
    } else {
      var formValues = this.channelForm.value;
      this.channelConfig.campaignId = this.campaign.id;
      this.channelConfig.promotionId = formValues.promotionId;
      this.channelConfig.communicationId = formValues.communicationId;
      this.channelConfig.deliveryProtocolId = this.deliveryProtocol.id;
      this.channelConfig.name = formValues.name;
      this.channelConfig.value = formValues.value;
      this.channelConfig.sensitive = formValues.sensitive;
      this.channelConfigurationService
        .createOrUpdateChannelConfiguration(this.channelConfig, this.changeValue)
        .subscribe(
          (data: any) => {
            if (this.channelConfig.id) {
              this.responseHelperService.success('Channel configuration updated successfully!', true);
            } else {
              this.responseHelperService.success('Channel configuration created successfully!', true);
            }
            this.successEvent.emit('success');
          },
          (data: any) => {
            this.responseHelperService.error(this, data.error.error);
          }
        );
    }
  }

  getCommunications(concat: boolean) {
    if (this.lengthToCompareCommunication === this.limit) {
      this.communicationsService.getCommunications(this.filterCommunications).subscribe(
        (comms) => {
          if (concat) {
            this.communications = this.communications.concat(comms.entity.aaData);
          } else {
            this.communications = comms.entity.aaData;
          }
          this.lengthToCompareCommunication = comms.entity.aaData.length;
          this.filterCommunications.offset += this.limit;
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    }
  }

  getPromotions(concat: boolean) {
    if (this.lengthToComparePromotion === this.limit) {
      this.promotionsService.getPromotions(this.filterPromotion).subscribe(
        (promotions) => {
          if (concat) {
            this.promotions = this.promotions.concat(promotions.entity.aaData);
          } else {
            this.promotions = promotions.entity.aaData;
          }
          this.lengthToComparePromotion = promotions.entity.aaData.length;
          this.filterPromotion.offset += this.limit;
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    }
  }

  onSearchCommunications() {
    this.commSelect$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => this.communicationsService.getCommunications(this.setFilterForComm(term)))
      )
      .subscribe((data: any) => {
        this.communications = data.entity.aaData;
        this.filterCommunications.offset += this.limit;
      });
  }

  onSearchPromotions() {
    this.promotionSelect$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => this.promotionsService.getPromotions(this.setFilterForPromotion(term)))
      )
      .subscribe((data: any) => {
        this.promotions = data.entity.aaData;
        this.filterPromotion.offset += this.limit;
      });
  }

  sensitiveValueChanged(input) {
    if (this.channelConfig.sensitive || this.deliveryOption.sensitive) {
      this.changeValue = true;
      this.channelForm.get('value').setValue(input.key, { onlySelf: true });
    }
    if (!this.channelConfig.sensitive) {
      this.changeValue = true;
    }
  }

  setFilterForComm(term): any {
    this.filterCommunications.offset = 0;
    this.lengthToCompareCommunication = 10;
    if (term) {
      this.filterCommunications.communicationName = term;
    } else {
      delete this.filterCommunications.communicationName;
    }
    return this.filterCommunications;
  }

  setFilterForPromotion(term): any {
    this.filterPromotion.offset = 0;
    this.lengthToComparePromotion = 10;
    if (term) {
      this.filterPromotion.code = term;
    } else {
      delete this.filterPromotion.code;
    }
    return this.filterPromotion;
  }
}
