import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import React from "react";

export default function Link(props: MuiLinkProps) {
  const propsWithDefaults: MuiLinkProps = {
    ...props,
    sx: { textDecoration: "none", ...props.sx },
  };
  return <MuiLink {...propsWithDefaults} />;
}
