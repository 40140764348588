import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-number',
  templateUrl: './filter-number.component.html',
  styleUrls: ['./filter-number.component.scss'],
})
export class FilterNumberComponent implements OnInit {
  @Input() section: any;
  @Input() filter: any;
  pattern = '\\d*$|^(?:)$';
  numberForm: FormGroup;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.numberForm = this.formBuilder.group({
      number: [this.filter.value, Validators.pattern('\\d*$|^(?:)$')],
    });
  }
}
