import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, QueryList } from '@angular/core';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { AgeRestriction } from 'src/app/data/class';

@Component({
  selector: 'app-age-restriction',
  templateUrl: './age-restriction.component.html',
  styleUrls: ['./age-restriction.component.scss'],
})
export class AgeRestrictionComponent implements OnInit {
  @ViewChildren('form') forms: QueryList<any>;
  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @Input() ageRestriction: any;
  @Input() configurationType: string;
  units: any;
  directions: any;
  faTimes = faTimes;
  faPlus = faPlus;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();

  constructor() {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.units = [
      { value: 'DAY', text: 'DAY' },
      { value: 'WEEK', text: 'WEEK' },
      { value: 'MONTH', text: 'MONTH' },
      { value: 'YEAR', text: 'YEAR' },
    ];

    this.directions = [
      { value: 'OLDER', text: 'OLDER' },
      { value: 'YOUNGER', text: 'YOUNGER' },
    ];
  }

  initRestrictionObject(): void {
    let tmpObj: any;
    if (this.configurationType === 'rules') {
      tmpObj = new AgeRestriction();
    } else {
      tmpObj = {
        class:
          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Eligibility$AgeRestriction',
      };
    }

    if (this.ageRestriction) {
      this.ageRestriction.push(tmpObj);
    } else {
      this.ageRestriction = [];
      this.ageRestriction.push(tmpObj);
    }
  }

  updateRestrictions(): void {
    if (this.configurationType === 'rules') {
      this.debouncer.next(this.ageRestriction);
      let valid = true;
      for (const form of this.forms) {
        valid = valid && form.valid;
      }
      this.isValidChange.emit(valid);
    } else {
      this.debouncer.next(this.ageRestriction);
    }
  }

  removeRestriction(restriction) {
    this.ageRestriction.splice(this.ageRestriction.indexOf(restriction), 1);
    this.updateRestrictions();
  }

  keyPressNumbers(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 49 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
