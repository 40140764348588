import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormsModule } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-candidate-communication-cap',
  templateUrl: './candidate-communication-cap.component.html',
  styleUrls: ['./candidate-communication-cap.component.scss'],
})
export class CandidateCommunicationCapComponent implements OnInit {
  @Input() commCandidateCap: any;
  periods: any;
  inclusionValues: any;
  isDelivered: any;
  faTimes = faTimes;
  faPlus = faPlus;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  @ViewChild(RegexSearchModalComponent) regexSearchModalComponent: RegexSearchModalComponent;

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.periods = [
      { value: 'CAMPAIGN', text: 'CAMPAIGN' },
      { value: 'YEAR', text: 'YEAR' },
      { value: 'QUARTER', text: 'QUARTER' },
      { value: 'MONTH', text: 'MONTH' },
      { value: 'WEEK', text: 'WEEK' },
      { value: 'DAY', text: 'DAY' },
      { value: 'PLANYEAR', text: 'PLANYEAR' },
      { value: 'CUSTOM', text: 'CUSTOM' },
    ];

    this.isDelivered = [
      { value: true, text: 'YES' },
      { value: false, text: 'NO' },
    ];
  }

  regexSearchModal(
    searchFor: string,
    item: any,
    size: string,
    limitToOne: boolean,
    setValue: boolean,
    itemIndex: number
  ): void {
    this.openRegexSearchModal(searchFor, item, size, limitToOne, setValue, itemIndex);
  }

  openRegexSearchModal(
    searchFor: string,
    item: any,
    size: string,
    limitToOne: boolean,
    setValue: boolean,
    itemIndex: number
  ): void {
    const instance = this.modalHelper.open(RegexSearchModalComponent);
    instance.componentInstance.searchFor = searchFor;
    instance.componentInstance.item = item;
    instance.componentInstance.size = size;
    instance.componentInstance.limitToOne = limitToOne;
    instance.componentInstance.setValue = setValue;
    instance.componentInstance.itemIndex = itemIndex;
    instance.componentInstance.successEvent.subscribe((text: any) => {
      console.log(text);
      console.log(item);
      this.commCandidateCap[itemIndex][item.name] = text;
      this.updateCaps();
      instance.close();
    });
  }

  initCapObject(): void {
    console.log(this.commCandidateCap);
    var tmpObj = {
      class:
        'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Caps$CommCandidateCap',
    };
    if (this.commCandidateCap) {
      this.commCandidateCap.push(tmpObj);
    } else {
      this.commCandidateCap = [];
      this.commCandidateCap.push(tmpObj);
    }
  }

  updateCaps() {
    this.debouncer.next(this.commCandidateCap);
  }

  removeCap(cap: any) {
    this.commCandidateCap.splice(this.commCandidateCap.indexOf(cap), 1);
    this.updateCaps();
  }
}
