import { Component, Input, OnInit } from '@angular/core';
import { ActivityInstrument } from '../data/model';
import { getDisplayText } from '../member/member-tabs/instrument-modal/instrument-modal.util';

@Component({
  selector: 'app-instrument-number-label',
  templateUrl: './instrument-number-label.component.html',
  styleUrls: [],
})
export class InstrumentNumberLabelComponent implements OnInit {
  @Input() instrument: ActivityInstrument;
  displayText: string;

  constructor() {}

  ngOnInit(): void {
    console.log(
      'instrumentModal - instrumentType object ',
      this.instrument.instrumentType,
      this.instrument.instrumentNumber
    );
    this.displayText = getDisplayText(this.instrument.instrumentType.displayFormat, this.instrument.instrumentNumber);
  }
}
