import { AppDataService } from './../app-data.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Gender, Segment } from '../data/model';
import { Observable } from 'rxjs';
import { EntityResponse } from './http.service';

type responseOptionParams = HttpParams | { [param: string]: string | string[] };

type responseOptions = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe: 'response';
  params?: responseOptionParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class PersonsService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setResponseOptionParams(params: responseOptionParams): responseOptions {
    const paramsToSend: responseOptions = {
      observe: 'response',
      params,
    };
    return paramsToSend;
  }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getAttributes(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-attributes/party/' + partyId);
  }

  getProspectiveMember(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-prospective/' + partyId);
  }

  getAttributeProvenance(partyId: number, attrName: string): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-attributes/' + partyId + '/provenance/' + attrName);
  }

  deleteAttribute(partyId: number, attrValueId: number): any {
    return this.http.put<any>(this.globalData.baseUrl + 'party-attributes/' + partyId + '/delete/' + attrValueId, null);
  }

  getPersonSegmentList(partyId: number, withChildren: boolean): Observable<HttpEvent<EntityResponse<Segment[]>>> {
    const params = this.setResponseOptionParams({
      //children: withChildren.toString(),
    });
    // @ts-ignore typing issues in @angular/common/http
    return this.http.get<HttpEvent<EntityResponse<Segment[]>>>(
      this.globalData.baseUrl + 'segments/party/full-tree/' + partyId,
      params
    );
  }

  getPersonSegments(partyId: number, params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'segments/party/' + partyId, this.setParams(params));
  }

  getSegmentLeafNodes = function (): any {
    return this.http.get(this.globalData.baseUrl + 'segments/leaf-nodes', {});
  };

  getPrimaryPartyEmail(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-emails/party/' + partyId);
  }

  getAllSegmentsNodes(partyId?: number, withChildren?: boolean): Observable<HttpEvent<EntityResponse<Segment[]>>> {
    if (partyId) {
      return this.getPersonSegments(partyId, withChildren);
    }
    return this.http.get<HttpEvent<EntityResponse<Segment[]>>>(this.globalData.baseUrl + 'segments/nodes');
  }

  getPartyAttributeHistory(partyId: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'party-attributes/party/' + partyId + '/history',
      this.setParams(params)
    );
  }

  getPersonSegmentsTree(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'segments/party/' + partyId + '/tree');
  }

  addSegmentsToParty(id: number, data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'members/segments/' + id, data);
  }

  removeSegmentFromUser(memberId: number, segmentId: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'members/' + memberId + '/segments/' + segmentId);
  }

  createOrUpdateAttribute(attrValueId: number, data: any): any {
    if (attrValueId) {
      return this.http.put(this.globalData.baseUrl + 'party-attributes/' + attrValueId, data);
    }
    return this.http.post(this.globalData.baseUrl + 'party-attributes/', data);
  }

  getAllGenders(): Observable<EntityResponse<Gender[]>> {
    return this.http.get<EntityResponse<Gender[]>>(this.globalData.baseUrl + 'members/genders');
  }

  getAllSegmentsTree(params?: any): any {
    if (params) {
      return this.http.get<any>(this.globalData.baseUrl + 'segments/tree', this.setParams(params));
    }
    return this.http.get<any>(this.globalData.baseUrl + 'segments/tree');
  }

  createOrUpdateSegment(id: number, segment: Segment): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'segments/' + id, segment);
    }
    return this.http.post(this.globalData.baseUrl + 'segments', segment);
  }

  levelSegments(segments: Segment[]): Segment[] {
    // Segments are sorted in hierarchy order, with level set to steps from their root ancestor.
    type SegmentCache = {
      segmentIdToSegment: Record<number, Segment>;
      segmentIdToChildSegments: Record<number, Segment[]>;
    };

    const cache: SegmentCache = segments.reduce(
      (acc: SegmentCache, segment: Segment) => {
        acc.segmentIdToSegment[segment.id] = segment;
        if (segment.parentId === segment.id || (!segment.parentId && segment.parentId !== 0)) {
          return acc;
        }
        if (!acc.segmentIdToChildSegments[segment.parentId]) {
          acc.segmentIdToChildSegments[segment.parentId] = [];
        }
        acc.segmentIdToChildSegments[segment.parentId].push(segment);
        return acc;
      },
      {
        segmentIdToSegment: {},
        segmentIdToChildSegments: {},
      }
    );
    const roots: Segment[] = segments.filter(
      (segment) => !cache.segmentIdToSegment[segment.parentId] || segment.parentId === segment.id
    );

    function getOrderedChildArray(
      segment: Segment,
      segmentIdToChildSegments: Record<number, Segment[]>,
      foundAlready: Record<number, boolean> = {},
      level: number = 0
    ): Segment[] {
      const children = segmentIdToChildSegments[segment.id];
      const result: Segment[] = [{ ...segment, level }];
      if (!!foundAlready[segment.id] && level !== 0) {
        throw new Error(
          `unexpected circular Segments at ${JSON.stringify(segment)} level ${level}, found already: ${JSON.stringify(
            foundAlready
          )}, cache has ${JSON.stringify(segmentIdToChildSegments)}`
        );
      }
      if (!children || !children.length) {
        return result;
      }
      foundAlready[segment.id] = true;
      return result.concat(
        children.flatMap((child) => getOrderedChildArray(child, segmentIdToChildSegments, foundAlready, level + 1))
      );
    }

    return roots.flatMap((parent) => getOrderedChildArray(parent, cache.segmentIdToChildSegments, {}, 0));
  }
}
