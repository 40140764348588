<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-9 col-sm-12 content-container">
      <div class="ibox">
        <div class="ibox-content">
          <span *ngIf="show">
            <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
          </span>
          <div class="row">
            <table class="table table-pointable table-bordered" width="100%">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Last name</th>
                  <th>First name</th>
                  <th>Email</th>
                  <th>Roles</th>
                  <th>Segments</th>
                  <th>Last Login</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users" (click)="selectUser($event, user)">
                  <td>
                    <a (click)="goToUser($event, user)" href="">{{ user.id }}</a>
                  </td>
                  <td>{{ user.lastName }}</td>
                  <td>{{ user.firstName }}</td>
                  <td class="word-break-break-all">{{ user.emailsList }}</td>
                  <td class="word-break-break-all">{{ user.partyAccessRolesList }}</td>
                  <td>{{ user.segmentsList }}</td>
                  <td>{{ user.lastLogin }}</td>
                  <td>{{ user.status }}</td>
                </tr>
                <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
              </tbody>
            </table>
          </div>
          <app-add-more-data-to-list (onScroll)="getUsers(true)" [lock]="lock" [isLoading]="isLoading">
          </app-add-more-data-to-list>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <app-member-details-panel
        *ngIf="selectedPersonId"
        [type]="'users'"
        [selectedPersonId]="selectedPersonId"
        [openTicket]="openTicket"
        (navigateEvent)="navigateToTab($event)"
      >
      </app-member-details-panel>
    </div>
  </div>
</div>
