import { faSpinner, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Component, Input } from '@angular/core';
import { AppDataService } from 'src/app/app-data.service';
import { GlobalEventService } from 'src/app/services/global-event.service';

@Component({
  selector: 'app-filter-button-panel',
  templateUrl: './filter-button-panel.component.html',
  styleUrls: ['./filter-button-panel.component.scss'],
})
export class FilterButtonPanelComponent {
  @Input() searchFilters: any;
  faCheckSquare = faCheckSquare;
  faSpinner = faSpinner;

  constructor(public appDataService: AppDataService, private globalEventService: GlobalEventService) {
    this.globalEventService.getGlobalEvent().subscribe((response: any) => {
      if (response.toComponent === 'filter-button-panel') {
        if (response.action === 'disableButtons') {
          response.data.forEach((element: any) => {
            this.searchFilters.addEntityButtons[element.buttonIndex].disabled = element.disabled;
          });
        }
      }
    });
  }
}
