import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCalendar, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Attribute } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { PersonsService } from 'src/app/services/persons.service';
import { PromotionService } from 'src/app/services/promotion.service';

@Component({
  selector: 'app-communication-attribute-modal',
  templateUrl: './communication-attribute-modal.component.html',
  styleUrls: ['./communication-attribute-modal.component.scss'],
})
export class CommunicationAttributeModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() attr: Attribute;
  @Input() commId: number;
  @Input() personId: number;
  @Input() promotionId: number;
  @Input() resourceType: string;
  title: string;
  commAttrForm: FormGroup;
  errorMsg = '';
  faSave = faSave;
  faTimes = faTimes;
  faCalendar = faCalendar;
  maxDate = undefined;
  date = new Date();
  message = '';
  edit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private dateHelperService: DateHelperService,
    private communicationsService: CommunicationsService,
    private personsService: PersonsService,
    private promotionService: PromotionService,
    private responseHelperService: ResponseHelperService
  ) {
    const current = new Date();
    this.maxDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  ngOnInit(): void {
    if (this.attr && this.attr.attrName) {
      this.edit = true;
      this.title = 'Edit attribute ' + this.attr.attrName;
      this.commAttrForm = this.formBuilder.group({
        attrName: [this.attr.attrName, Validators.required],
        attrValue: [this.attr.attrValue, Validators.required],
        effectiveFrom: [
          this.dateHelperService.stringToNgbDate(this.getFormattedDate(this.attr.effectiveFrom)),
          Validators.required,
        ],
        effectiveTo: [null],
        displayName: [this.attr.displayName],
        attrValueId: [this.attr.attribValueId],
      });
      if (this.attr.effectiveTo !== null) {
        const effectiveToStr = new Date(this.attr.effectiveTo);
        if (effectiveToStr < new Date('2099-12-31')) {
          this.commAttrForm.patchValue({
            effectiveTo: this.dateHelperService.stringToNgbDate(this.getFormattedDate(this.attr.effectiveTo)),
          });
        }
      }
      this.message = 'Attribute successfully edited';
    } else {
      this.message = 'Attribute successfully created';
      this.edit = false;
      this.title = 'Add attribute';
      this.commAttrForm = this.formBuilder.group({
        attrName: ['', Validators.required],
        attrValue: ['', Validators.required],
        effectiveFrom: [this.dateHelperService.stringToNgbDate(this.getFormattedDate(new Date())), Validators.required],
        effectiveTo: [null],
        displayName: [null],
      });
    }
  }

  ok(): void {
    let params;
    let service;
    this.errorMsg = '';
    if (this.resourceType === 'party') {
      service = this.personsService;
      if (!this.attr) {
        params = {
          attrName: this.commAttrForm.get('attrName').value,
          attrValue: this.commAttrForm.get('attrValue').value,
          effectiveFrom: this.dateHelperService.ngbDateToString(this.commAttrForm.get('effectiveFrom').value),
          commandType: 'CREATE',
        };
        if (!this.dateHelperService.ngbDateToString(this.commAttrForm.get('effectiveTo').value)) {
          params.effectiveTo = '9999-12-30T23:00:00.000+0000';
        } else {
          params.effectiveTo =
            this.dateHelperService.ngbDateToString(this.commAttrForm.get('effectiveTo').value) + 'T00:00:00.000-0300';
        }
      } else {
        this.attr.attrName = this.commAttrForm.get('attrName').value;
        this.attr.attrValue = this.commAttrForm.get('attrValue').value;
        this.attr.effectiveFrom =
          this.dateHelperService.ngbDateToString(this.commAttrForm.get('effectiveFrom').value) + 'T00:00:00.000-0300';
        this.attr.commandType = 'CREATE';
        if (!this.dateHelperService.ngbDateToString(this.commAttrForm.get('effectiveTo').value)) {
          this.attr.effectiveTo = '9999-12-30T23:00:00.000+0000';
        } else {
          this.attr.effectiveTo =
            this.dateHelperService.ngbDateToString(this.commAttrForm.get('effectiveTo').value) + 'T00:00:00.000-0300';
        }
        params = this.attr;
      }
      if (params.effectiveFrom) {
        if (!this.isLater(params.effectiveTo, params.effectiveFrom)) {
          this.errorMsg = 'Effective Date must be before Expiration Date';
        }
      }

      params.partyId = this.personId;
      delete params.displayName;
    } else if (this.resourceType === 'promotion') {
      service = this.promotionService;
      params = {
        attrName: this.commAttrForm.get('attrName').value,
        attrValue: this.commAttrForm.get('attrValue').value,
        promotionId: this.promotionId,
        displayName: this.commAttrForm.get('displayName').value,
      };
      params.createdOn = new Date().toLocaleDateString();
    } else {
      service = this.communicationsService;
      params = {
        attrName: this.commAttrForm.get('attrName').value,
        attrValue: this.commAttrForm.get('attrValue').value,
        commId: this.commId,
      };
      params.id = this.attr ? this.attr.id : null;
    }
    if (!this.errorMsg) {
      service
        .createOrUpdateAttribute((this.attr && this.attr.attrValueId) || (this.attr && this.attr.id), params)
        .subscribe(
          () => {
            this.responseHelperService.success(this.message, true);
            this.successEvent.emit();
          },
          (error: any) => {
            this.responseHelperService.error(this, error.error);
          }
        );
    }
  }

  isLater(dateString1: string, dateString2: string): boolean {
    return dateString1 > dateString2;
  }

  getFormattedDate(date): string {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (1 + d.getMonth()).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
  }

  clearDate(name: string): void {
    this.commAttrForm.patchValue({
      [name]: null,
    });
    this.commAttrForm.markAsDirty();
  }
  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
