<div>
  <form>
    <label>The REWARD history for the member must contain:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="attrIntervalRewardCountThreshold !== null">
        <div
          *ngFor="let attrIntRewCntThreshold of attrIntervalRewardCountThreshold; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeThreshold(rewCntThreshold)"
            title="Remove Threshold"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{ attrIntRewCntThreshold.thresholdValue }}"
                >more than</label
              >
              <input
                class="form-control m-b"
                type="number"
                id="thresholdVal_{{ attrIntRewCntThreshold.thresholdValue }}"
                name="thresholdValue"
                [(ngModel)]="attrIntRewCntThreshold.thresholdValue"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: attrIntRewCntThreshold, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Promotions"
                >rewards on Promotions with Code (blank for current promotion, or a comma separated list):</a
              >
              <input
                class="form-control m-b"
                type="text"
                id="behCodes_{{ attrIntRewCntThreshold.promotionCodes }}"
                name="promotionCodes"
                [(ngModel)]="attrIntRewCntThreshold.promotionCodes"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: attrIntRewCntThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >for Behaviors with Codes (comma separated list):</a
              >
              <input
                class="form-control m-b"
                type="text"
                id="behCodes_{{ attrIntRewCntThreshold.thresholdValue }}"
                name="behaviorCodes"
                [(ngModel)]="attrIntRewCntThreshold.behaviorCodes"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label>submitted to the same location:</label>
              <select
                name="locationMatch_{{ attrIntRewCntThreshold.thresholdValue }}"
                class="form-control"
                [(ngModel)]="attrIntRewCntThreshold.locationMatch"
                (ngModelChange)="updateThresholds()"
              >
                <option
                  *ngFor="let tf of trueFalse"
                  [selected]="attrIntRewCntThreshold.locationMatch === tf.value"
                  [value]="tf.value"
                >
                  {{ tf.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="attrName_{{ attrIntRewCntThreshold.thresholdValue }}"
                >For the effective date range (covering the activity date) of the attribute:</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="attrName_{{ attrIntRewCntThreshold.thresholdValue }}"
                name="attrName_"
                [(ngModel)]="attrIntRewCntThreshold.attrName"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="attrValue_{{ attrIntRewCntThreshold.thresholdValue }}"
                >with value (leave blank for any):</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="attrValue_{{ attrIntRewCntThreshold.thresholdValue }}"
                name="attrValue"
                [(ngModel)]="attrIntRewCntThreshold.attrValue"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{ attrIntRewCntThreshold.thresholdValue }}"
                >Include counts from members related by (leave blank for current member only or use a comma separated
                list):</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="relationshipName_{{ attrIntRewCntThreshold.thresholdValue }}"
                name="relationshipName"
                [(ngModel)]="attrIntRewCntThreshold.relationshipName"
                (ngModelChange)="updateThresholds()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Attribute Interval Reward Count Threshold
        </button>
      </div>
    </div>
  </form>
</div>
