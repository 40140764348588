import { Component, Input } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter-expander',
  templateUrl: './filter-expander.component.html',
  styleUrls: ['./filter-expander.component.scss'],
})
export class FilterExpanderComponent {
  @Input() section: any;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  constructor() {}

  toggleOpen(): void {
    this.section.open = !this.section.open;
  }
}
