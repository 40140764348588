<form [formGroup]="contactReasonForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="value" />
      <!-- <div class="mt-1" [hidden]="contactReasonForm.controls.value.valid">
        <small class="text-danger">Please input name</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Code <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="code" />
      <!-- <div class="mt-1" [hidden]="contactReasonForm.controls.code.valid">
        <small class="text-danger">Please input code</small>
      </div> -->
    </div>
    <div class="form-group">
      <label>Survey Path</label>
      <input type="text" class="form-control" formControlName="surveyPath" />
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Status <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select formControlName="status" class="form-control" formControlName="status">
        <option *ngFor="let option of statuses" value="{{ option.value }}">{{ option.value }}</option>
      </select>
      <!-- <div class="mt-1" [hidden]="contactReasonForm.controls.status.valid">
        <small class="text-danger">Select status</small>
      </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!contactReasonForm.valid"></app-modal-buttons>
    <!-- <button class="btn btn-dark-blue" type="submit" [disabled]="!contactReasonForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button> -->
  </div>
</form>
