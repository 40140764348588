<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <div class="row"></div>
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Name</th>
              <th>Hierarchy Source</th>
              <th>Description</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let behavior of behaviors">
              <td>{{ behavior.id }}</td>
              <td>{{ behavior.code }}</td>
              <td>{{ behavior.name }}</td>
              <td>{{ findhierarchySource(behavior.hierarchySourceId).name }}</td>
              <td>{{ behavior.description }}</td>
              <td>{{ behavior.status }}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a class="btn btn-outline-primary btn-sm edit action" (click)="behaviorModal(behavior.id)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                  <button
                    class="btn btn-attention btn-sm delete action"
                    [disabled]="behavior.status === 'DELETED'"
                    (click)="deleteBehavior(behavior)"
                  >
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getBehaviors(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
