import { NotFoundComponent } from './not-found/not-found.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { ProcessingLogsComponent } from './processing-log/processing-logs/processing-logs.component';
import { ProcessingLogComponent } from './processing-log/processing-log/processing-log.component';
import { ManifestComponent } from './manifest/manifest.component';
import { OutboundComponent } from './outbound-batch/outbound/outbound.component';
import { IntegrationStageImportComponent } from './integrations-tab/integration-stage-import/integration-stage-import.component';
import { IntegrationMessagesComponent } from './integrations-tab/integration-messages/integration-messages.component';
import { IntegrationStageLoadComponent } from './integrations-tab/integration-stage-load/integration-stage-load.component';
import { IntegrationsComponent } from './integrations-tab/integrations/integrations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlSegmentGroup, Route } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MembersComponent } from './member/members/members.component';
import { MemberComponent } from './member/member/member.component';
import { LoginGuard } from './services/login-guard.service';
import { CommunicationsComponent } from './communications/communications.component';
import { CommunicationComponent } from './communication/communication.component';
import { ProgramsComponent } from './programs/programs.component';
import { PromotionTemplateBuilderV2Component } from './promotion-config-builder/promotion-template-builder-v2/promotion-template-builder-v2.component';
import { PromotionConfigBuilderV2Component } from './promotion-config-builder/promotion-config-builder-v2/promotion-config-builder-v2.component';
import { OutboundStageLoadsComponent } from './outbound-batch/outbound-stage-loads/outbound-stage-loads.component';
import { OutboundStageExportsComponent } from './outbound-batch/outbound-stage-exports/outbound-stage-exports.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { SystemComponent } from './system-tab/system/system.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnerComponent } from './partner/partner.component';
import { ReportsComponent } from './reports/reports.component';
import { UsersComponent } from './users/users.component';
import { SegmentsComponent } from './segments/segments.component';
import { ReportComponent } from './report/report.component';
import { OrbComponent } from './orb/orb.component';
import { EventsTabComponent } from './member/member-tabs/member-events-tab/member-events-tab.component';
import { MemberMeasuresTabComponent } from './member/member-tabs/member-measures-tab/member-measures-tab.component';
import { InteractionsComponent } from './member/interactions/interactions.component';
import { PackagedSponsorshipComponent } from './packaged-sponsorship/packaged-sponsorship.component';
import { CampaignComponent } from './campaign/campaign.component';
import { ActivitySearchComponent } from './activity-search/activity-search.component';
import { EventSourcesTabComponent } from './integrations-tab/event-sources-tab/event-sources-tab.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { DisplayXmlTabComponent } from './display-xml-tab/display-xml-tab.component';
import { RedemptionDashboardComponent } from './redemption-dashboard/redemption-dashboard.component';
import { CommStrategyTabComponent } from './comm-strategy-tab/comm-strategy-tab.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { ParentsComponent } from './organizations/parents.component';
import { ClientsComponent } from './organizations/clients.component';
import { LinesOfBusinessComponent } from './organizations/lines-of-business.component';
import { CallFlowBuilderComponent } from './call-flow-builder/call-flow-builder.component';
import { SelectOrganizationComponent } from './select-organization/select-organization.component';
import { LiveAgentDashboardComponent } from './live-agent-dashboard/live-agent-dashboard.component';
import {
  MARKET_ROUTE_PATH_WITH_ID,
  PARENTS_ROUTE_PATH,
  PARENT_ROUTE_WITH_ID_KEY,
  CLIENT_ROUTE_PATH_WITH_ID,
  LINE_OF_BUSINESS_ROUTE_PATH_WITH_ID,
  CALL_FLOW_BUILDER_PATH,
  PROGRAM_ROUTE_PATH_WITH_ID,
  PACKAGED_SPONSORSHIP_ROUTE_PATH_WITH_TAG,
  LIVE_AGENT_DASHBOARD_ROUTE_PATH,
} from './route.constants';
import { SurveyBuilderComponent } from './content-hub/survey-builder-component';
import { SurveyBuilderDetailComponent } from './content-hub/survey-builder-detail-component';
import { QuestionLibraryComponent } from './content-hub/question-library-component';
import { NavigationGuard } from './services/navigation.guard';
import { OutreachContentComponent } from './outreach-content/outreach-content.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [LoginGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'logged-out', component: LoggedOutComponent },
  { path: 'communications', component: CommunicationsComponent, canActivate: [LoginGuard] },
  { path: 'communications/:id', component: CommunicationComponent, canActivate: [LoginGuard] },
  { path: 'communication-strategy/:id', component: CommStrategyTabComponent, canActivate: [LoginGuard] },
  { path: 'members', component: MembersComponent, canActivate: [LoginGuard] },
  { path: 'members/:id', component: MemberComponent, canActivate: [LoginGuard] },
  { path: 'outreach-content/:id', component: OutreachContentComponent, canActivate: [LoginGuard] },
  { path: PARENTS_ROUTE_PATH, component: OrganizationsComponent, canActivate: [LoginGuard] },
  { path: PARENT_ROUTE_WITH_ID_KEY, component: ParentsComponent, canActivate: [LoginGuard] },
  { path: CLIENT_ROUTE_PATH_WITH_ID, component: ClientsComponent, canActivate: [LoginGuard] },
  {
    path: LINE_OF_BUSINESS_ROUTE_PATH_WITH_ID,
    component: LinesOfBusinessComponent,
    canActivate: [LoginGuard],
  },
  { path: MARKET_ROUTE_PATH_WITH_ID, component: SponsorComponent, canActivate: [LoginGuard] },
  { path: PROGRAM_ROUTE_PATH_WITH_ID, component: CampaignComponent, canActivate: [LoginGuard] },
  {
    path: PACKAGED_SPONSORSHIP_ROUTE_PATH_WITH_TAG,
    component: PackagedSponsorshipComponent,
    canActivate: [LoginGuard],
  },
  { path: LIVE_AGENT_DASHBOARD_ROUTE_PATH, component: LiveAgentDashboardComponent, canActivate: [LoginGuard] },
  { path: 'survey-builder', component: SurveyBuilderComponent, canActivate: [LoginGuard] },
  { path: 'survey-builder/:id', component: SurveyBuilderDetailComponent, canActivate: [LoginGuard] },
  { path: 'question-library', component: QuestionLibraryComponent, canActivate: [LoginGuard] },

  { path: 'home', component: HomeComponent, canActivate: [LoginGuard] },
  { path: 'processing-log', component: ProcessingLogsComponent, canActivate: [LoginGuard] },
  { path: 'processing-log/:id', component: ProcessingLogComponent, canActivate: [LoginGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [LoginGuard] },
  { path: 'redemption-dashboard', component: RedemptionDashboardComponent, canActivate: [LoginGuard] },
  { path: 'integrations', component: IntegrationsComponent, canActivate: [LoginGuard] },
  { path: 'products', component: ProgramsComponent, canActivate: [LoginGuard] },
  { path: 'products/:id/promotions', component: PromotionsComponent, canActivate: [LoginGuard] },
  {
    path: 'programs/:id/promotions/:promotionId/promotion-template/:templateId',
    component: PromotionTemplateBuilderV2Component,
    canActivate: [LoginGuard],
  },
  {
    path: 'programs/:id/promotions/:promotionId/promotion-display-template/:templateId',
    component: DisplayXmlTabComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'programs/:id/promotions/:promotionId/promotion-display-template',
    component: DisplayXmlTabComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'programs/:id/promotions/:promotionId/promotion-template/:templateId/clone',
    component: PromotionTemplateBuilderV2Component,
    canActivate: [LoginGuard],
  },
  {
    path: 'programs/:id/promotions/:promotionId/promotion-template',
    component: PromotionTemplateBuilderV2Component,
    canActivate: [LoginGuard],
  },
  {
    path: 'markets/:sponsorId/sponsorship/:promoSponsorId/configuration/:cfgId',
    component: PromotionConfigBuilderV2Component,
    canActivate: [LoginGuard],
  },
  {
    path: 'markets/:sponsorId/sponsorship/:promoSponsorId/configuration',
    component: PromotionConfigBuilderV2Component,
    canActivate: [LoginGuard],
  },
  { path: 'integrations/:id/loads', component: IntegrationStageLoadComponent, canActivate: [LoginGuard] },
  {
    path: 'integrations/:id/manifest',
    component: ManifestComponent,
    canActivate: [LoginGuard],
    canDeactivate: [NavigationGuard],
  },
  { path: 'integrations/:id/messages', component: IntegrationMessagesComponent, canActivate: [LoginGuard] },
  {
    path: 'integrations/:integrationId/loads/:loadId',
    component: IntegrationStageImportComponent,
    canActivate: [LoginGuard],
  },
  { path: 'outbounds', component: OutboundComponent, canActivate: [LoginGuard] },
  { path: 'outbounds/:id/loads', component: OutboundStageLoadsComponent, canActivate: [LoginGuard] },
  { path: 'outbounds/:outboundId/loads/:loadId', component: OutboundStageExportsComponent, canActivate: [LoginGuard] },
  { path: 'system', component: SystemComponent, canActivate: [LoginGuard] },
  { path: 'partners', component: PartnersComponent, canActivate: [LoginGuard] },
  { path: 'partners/:id', component: PartnerComponent, canActivate: [LoginGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [LoginGuard] },
  { path: 'users/:id', component: MemberComponent, canActivate: [LoginGuard] },
  { path: 'users', component: UsersComponent, canActivate: [LoginGuard] },
  { path: 'segments', component: SegmentsComponent, canActivate: [LoginGuard] },
  { path: 'reports/:id', component: ReportComponent, canActivate: [LoginGuard] },
  { path: 'reports/orb/:id', component: OrbComponent, canActivate: [LoginGuard] },
  { path: 'events', component: EventsTabComponent, canActivate: [LoginGuard] },
  { path: 'measures', component: MemberMeasuresTabComponent, canActivate: [LoginGuard] },
  { path: 'interactions', component: InteractionsComponent, canActivate: [LoginGuard] },
  {
    path: CALL_FLOW_BUILDER_PATH,
    component: CallFlowBuilderComponent,
    canActivate: [LoginGuard],
  },
  { path: 'select-organization', component: SelectOrganizationComponent, canActivate: [LoginGuard] },
  {
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
      if (segments[0].path === 'markets' && segments[2].path === 'packaged-sponsorship') {
        const sponsorshipTag = segments
          .slice(3)
          .map((segment: UrlSegment) => segment.path)
          .join('/');
        return {
          consumed: segments,
          posParams: { id: new UrlSegment(segments[1].path, {}), sponsorshipTag: new UrlSegment(sponsorshipTag, {}) },
        };
      }
      return null;
    },
    redirectTo: 'markets/:id/packaged-sponsorship/:sponsorshipTag',
  },
  { path: 'activity-search', component: ActivitySearchComponent, canActivate: [LoginGuard] },
  { path: 'eventSources', component: EventSourcesTabComponent, canActivate: [LoginGuard] },
  { path: 'forbidden', component: ForbiddenComponent, canActivate: [LoginGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'new-password', component: NewPasswordComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
