<div>
  <form>
    <label>The member must:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="preferenceRestrictions !== null">
        <div
          *ngFor="let preferenceRestriction of preferenceRestrictions; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeRestriction(preferenceRestriction)"
            title="{{ removeButtonTitle }}"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form #form="ngForm" name="form_{{ i }}">
            <div class="form-group">
              <select
                required
                name="condition_{{ i }}"
                class="form-control"
                [(ngModel)]="preferenceRestriction.condition"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let condition of conditions"
                  [selected]="preferenceRestriction.condition === condition.value"
                  [value]="condition.value"
                >
                  {{ condition.text }}
                </option>
              </select>
              <div
                class="mt-sm"
                *ngIf="preferenceRestriction.condition === undefined || preferenceRestriction.condition === null"
              >
                <small class="text-danger">Select condition</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="channel_{{ i }}">A usable:</label>
              <select
                required
                name="channel_{{ i }}"
                class="form-control"
                [(ngModel)]="preferenceRestriction.channel"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let endpointType of endpointTypes"
                  [selected]="preferenceRestriction.channel === endpointType.name"
                  [value]="endpointType.name"
                >
                  {{ endpointType.name }}
                </option>
              </select>
              <div class="mt-sm" [hidden]="preferenceRestriction.channel">
                <small class="text-danger">Select channel</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="preferenceRestriction.message"
                name="message_{{ i }}"
                (ngModelChange)="updateRestrictions()"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> {{ addButtonTitle }}
        </button>
      </div>
    </div>
  </form>
</div>
