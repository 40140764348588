import { ChevronList, Reward } from './../../data/class';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { faPlus, faTimes, faChevronUp, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss'],
})
export class RewardsComponent implements OnInit {
  @Input() rewards: any;
  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  faPlus = faPlus;
  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTrash = faTrash;
  openFieldChevronList: ChevronList = new ChevronList();
  units: any;

  constructor(private toastr: ToastrService) {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  //THE VALUE MUST MATCH THE DateUtil.PeriodCode enum
  ngOnInit(): void {
    this.units = [
      { value: 'HOUR', text: 'HOUR' },
      { value: 'DAY', text: 'DAY' },
      { value: 'WEEK', text: 'WEEK' },
      { value: 'MONTH', text: 'MONTH' },
      { value: 'QUARTER', text: 'QUARTER' },
      { value: 'YEAR', text: 'YEAR' },
      { value: 'CAMPAIGN', text: 'CAMPAIGN' },
    ];
  }

  initRewardObject(): void {
    const tmpObj = new Reward();

    if (this.rewards) {
      this.rewards.push(tmpObj);
    } else {
      this.rewards = [];
      this.rewards.push(tmpObj);
    }
  }

  updateRewards(): void {
    this.debouncer.next(this.rewards);
    this.isValidChange.emit(this.isFormValid());
  }

  removeReward(restriction: any): void {
    this.rewards.splice(this.rewards.indexOf(restriction), 1);
    this.updateRewards();
  }

  handlePromoCodeEvent(data: any, rewardIndex: number): void {
    switch (data.type) {
      case 'add':
        this.addPromoCode(data.value, rewardIndex);
        break;
      case 'save':
        this.savePromoCode(data.index, data.value, rewardIndex);
        break;
      default:
        this.removePromoCode(data.index, rewardIndex);
        break;
    }
  }

  addPromoCode(selectedPromoCodeModel: any, rewardIndex: number): void {
    if (this.rewards[rewardIndex].promotionList === undefined) {
      this.rewards[rewardIndex].promotionList = new Array<string>();
    }
    this.rewards[rewardIndex].promotionList.push(selectedPromoCodeModel.promotionCode);
    this.updateRewards();
    this.toastr.success('Promo Code added');
  }

  editPromoCode(index: number): void {
    if (this.openFieldChevronList.promoCodeIndex === index) {
      this.openFieldChevronList.promoCodeIndex = null;
    } else {
      this.openFieldChevronList.promoCodeIndex = index;
    }
  }

  removePromoCode(i: any, rewardIndex: number): void {
    this.rewards[rewardIndex].promotionList.splice(i, 1);
    this.updateRewards();
  }

  savePromoCode(i: any, promoCode: any, rewardIndex: number): void {
    if (this.rewards[rewardIndex].promotionList[i].fieldIndex === promoCode.fieldIndex) {
      this.rewards[rewardIndex].promotionList[i] = promoCode.promotionCode;
      this.updateRewards();
      this.toastr.success('Promo Code saved');
    }
    this.toastr.success('Can not save Promo Code');
  }

  editRewardType(index: number): void {
    if (this.openFieldChevronList.rewardTypeIndex === index) {
      this.openFieldChevronList.rewardTypeIndex = null;
    } else {
      this.openFieldChevronList.rewardTypeIndex = index;
    }
  }

  removeRewardType(i: any, rewardIndex: number): void {
    this.rewards[rewardIndex].rewardTypes.splice(i, 1);
    this.updateRewards();
  }

  handleRewardTypeEvent(data: any, rewardIndex: number): void {
    switch (data.type) {
      case 'add':
        this.addRewardType(data.value, rewardIndex);
        break;
      case 'save':
        this.saveRewardType(data.index, data.value, rewardIndex);
        break;
      default:
        this.removeRewardType(data.index, rewardIndex);
        break;
    }
  }

  addRewardType(selectedRewardTypeModel: any, rewardIndex: number): void {
    if (this.rewards[rewardIndex].rewardTypes === undefined) {
      this.rewards[rewardIndex].rewardTypes = new Array<string>();
    }
    this.rewards[rewardIndex].rewardTypes.push(selectedRewardTypeModel.rewardTypeName);
    this.updateRewards();
    this.toastr.success('Reward Type added');
  }

  saveRewardType(i: any, rewardType: any, rewardIndex: number): void {
    if (this.rewards[rewardIndex].rewardTypes[i].fieldIndex === rewardType.fieldIndex) {
      this.rewards[rewardIndex].rewardTypes[i] = rewardType.rewardTypeName;
      this.updateRewards();
      this.toastr.success('Reward Type saved');
    }
    this.toastr.success('Can not save Reward Type');
  }

  keyPressNumbers(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  isFormValid(): boolean {
    let result: boolean;
    for (const reward of this.rewards) {
      if (
        (this.isListExists(reward.promotionList) && !this.isListExists(reward.rewardTypes)) ||
        (!this.isListExists(reward.promotionList) && this.isListExists(reward.rewardTypes)) ||
        (!this.isListExists(reward.promotionList) &&
          !this.isListExists(reward.rewardTypes) &&
          this.isUnrequiredFieldsFilled(reward))
      ) {
        result = false;
      } else {
        result = true;
      }
    }
    return result;
  }

  isListExists(list: any): boolean {
    if (list && list.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isUnrequiredFieldsFilled(reward: any): boolean {
    if (reward.period || reward.periodCount || (reward.message && reward.message !== '')) {
      return true;
    } else {
      return false;
    }
  }
}
