import React from "react";
import { useEffect } from "react";
import { CallFlowOutreachContent } from "../../data/class";
import { CallFlowQueryService } from "../../services/call-flow-query.service";
import { CallFlow } from "../../call-flow-builder/models/model";
import getLogger from "../../services/logging/logging.service";
import Link from "@iridium/iridium-ui/Link";
import { LanguageService } from "../../services/language.service";
import { IridiumVoiceContent, IridiumVoiceContentProps } from "./IridiumVoiceContent";
import { useDebouncedCallback } from "use-debounce";
import { OutreachContentService } from "../../services/outreach-content.service";
import { ToastrService } from "ngx-toastr";
import { ContentGroup, Language, Communication, OutreachContent } from "../../data/model";

export const VIEW_IN_CALLFLOW_BUILDER_LINK_TEST_ID = "view-in-cfb-link";
export const CALL_FLOW_INVALID_HELPER_TEXT_TEST_ID = "call-flow-invalid-helper-text";

const logger = getLogger({ namespace: "CallFlowContent" });

export type IridiumVoiceContentContainerProps = {
  toastr: ToastrService;
  getLanguages: LanguageService["getLanguagesAsPromise"];
  getCallFlowById: CallFlowQueryService["getCallFlowById"];
  createOrUpdateContent: OutreachContentService["createOrUpdateContentAsPromise"];
  communication: Communication;
  contentGroup: ContentGroup;
  getCommunicationContents: OutreachContentService["getCommunicationContentsAsPromise"];
};
export default function IridiumVoiceContentContainer(props: IridiumVoiceContentContainerProps) {
  const {
    createOrUpdateContent,
    communication,
    getCommunicationContents,
    getCallFlowById,
    getLanguages,
    toastr,
    contentGroup,
  } = props;
  const [callFlowId, setCallFlowId] = React.useState("");
  const [savedCallFlowId, setSavedCallFlowId] = React.useState("");
  const [callFlow, setCallFlow] = React.useState<CallFlow | undefined>(undefined);
  const [isLoadingCallFlow, setIsLoadingCallFlow] = React.useState<boolean>(true);
  const [callFlowCommunicationContent, setCallFlowCommunicationContent] = React.useState<
    CallFlowOutreachContent | undefined
  >(undefined);
  const [language, setLanguage] = React.useState<Language>(undefined);

  const debouncedGetCallFlowById = useDebouncedCallback(async (callFlowId) => {
    if (callFlowId) {
      try {
        setCallFlow(undefined);
        setIsLoadingCallFlow(true);
        const callFlow = await getCallFlowById(callFlowId);
        setCallFlow(callFlow);
        setIsLoadingCallFlow(false);
      } catch (err) {
        // Ideally we wouldn't key off of a message here but instead would have an error code
        // Our error handling patterns are going to be reviewed in PHO-387
        setCallFlow(null);
        setIsLoadingCallFlow(false);

        if (err.message === "Unable to retrieve call flow") {
          return;
        }

        logger.error({
          message: "An error occurred while fetching the callflow",
          data: {
            callFlowId,
            err,
          },
        });
        toastr.error(
          `An error occurred while fetching the callflow, please try again and contact support if the issue continues`
        );
      }
    } else {
      setIsLoadingCallFlow(false);
      setCallFlow(undefined);
    }
  }, 1000);

  function isLoading(): boolean {
    return isLoadingCallFlow || !language.id;
  }

  async function fetchLanguages() {
    try {
      const response = await getLanguages({ isoCode: "ADMIN" });
      setLanguage(response.entity[0]);
    } catch (err) {
      logger.error({
        message: "An error occurred while fetching communication languages",
        data: { err },
      });
      toastr.error("An unexpected error occurred, please reload the page and contact support if the issue continues");
    }
  }

  async function fetchCommunicationContents() {
    const response = await getCommunicationContents({ commId: communication.id });
    if (Array.isArray(response.entity) && !!response.entity[0]) {
      const newCallFlowCommunicationContent = mapCommunicationContentToCallFlowCommunicationContent(response.entity[0]);
      setCallFlowCommunicationContent(newCallFlowCommunicationContent);
    } else {
      setCallFlowCommunicationContent({
        contentGroup: contentGroup,
        endpointType: communication.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name,
        language: language,
      });
    }
  }

  async function handleSubmit() {
    const callFlowCommunicationContentToSave: OutreachContent = {
      contentGroup: contentGroup,
      endpointType: communication.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name,
      language: language,
      subject: callFlowCommunicationContent.subject,
      id: callFlowCommunicationContent.id,
      template: {
        ...callFlowCommunicationContent.template,
        callFlowId,
        defaultCallFromNumber: callFlow?.defaultManagedPhoneNumberId || "",
      },
    };

    const serializedCommunicationContent = {
      ...callFlowCommunicationContentToSave,
      template: JSON.stringify(callFlowCommunicationContentToSave.template),
    };

    try {
      await createOrUpdateContent(serializedCommunicationContent);
      await fetchCommunicationContents();
      toastr.success(`Communication content successfully saved`);
    } catch (err) {
      logger.error({
        message: "An error occurred while saving communication content",
        data: {
          err,
          callFlowCommunicationContentToSave,
        },
      });
      toastr.error(
        `An error occurred while saving communication content. Please try again or contact support if the issue persists.`
      );
    }
  }

  useEffect(() => void fetchLanguages(), []);

  useEffect(() => {
    setIsLoadingCallFlow(true);
    void debouncedGetCallFlowById(callFlowId);
  }, [callFlowId]);

  useEffect(() => {
    void fetchCommunicationContents();
  }, [communication.id]);

  useEffect(() => {
    if (callFlowCommunicationContent?.template?.callFlowId) {
      setSavedCallFlowId(callFlowCommunicationContent.template.callFlowId);
      setCallFlowId(callFlowCommunicationContent.template.callFlowId);
    } else {
      setSavedCallFlowId("");
      setCallFlowId("");
    }
  }, [callFlowCommunicationContent?.template?.callFlowId]);

  const callFlowInError = !!callFlowId && callFlow === null;
  let callFlowIdHelperText: React.ReactNode = "";
  if (callFlowInError) {
    callFlowIdHelperText = <span data-testid={CALL_FLOW_INVALID_HELPER_TEXT_TEST_ID}>Call Flow ID is invalid</span>;
  } else if (!!callFlow) {
    callFlowIdHelperText = (
      <Link
        href={`/call-flow-builder?callFlowId=${callFlowId}`}
        target="_blank"
        data-testid={VIEW_IN_CALLFLOW_BUILDER_LINK_TEST_ID}
      >
        View in Callflow Builder
      </Link>
    );
  }

  function handleCallFlowIdChange(callFlowId) {
    setCallFlowId(callFlowId);
  }

  const iridiumVoiceContentProps: IridiumVoiceContentProps = {
    callFlowInError,
    callFlowIdHelperText,
    callFlowId,
    disableSubmit: callFlowInError || callFlowId === savedCallFlowId || isLoading(),
    onSubmit: handleSubmit,
    onCallFlowIdChange: handleCallFlowIdChange,
  };

  return <IridiumVoiceContent {...iridiumVoiceContentProps} />;
}

function mapCommunicationContentToCallFlowCommunicationContent(
  outreachContent: OutreachContent
): CallFlowOutreachContent {
  let template;
  try {
    template = JSON.parse(outreachContent.template);
  } catch {
    logger.error({
      message: "An error occurred while parsing the communicationTemplate",
      data: { outreachContent },
    });
    template = undefined;
  }

  return {
    ...outreachContent,
    template,
  };
}
