<div class="modal-header">
  <h2>Template Preview</h2>
  <button type="button" class="btn btn-sm pull-left" data-dismiss="dialog" (click)="cancel()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
</div>
<div
  class="mt-3"
  style="margin: 0 auto; width: 90%; overflow-x: scroll !important"
  [innerHtml]="template | stringToHtml"
></div>
