import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';
import { Communication } from '../../data/model';
import {
  faAddressBook,
  faArrowRight,
  faChevronDown,
  faChevronUp,
  faCircle,
  faComment,
  faEnvelope,
  faHome,
  faArrowCircleRight,
  faUser,
  faInfoCircle,
  faPhone,
  faPhoneAlt,
  faPlusCircle,
  faTimes,
  faTrash,
  faSearch,
  faSync,
  faCogs,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsModalComponent } from '../../communications-modal/communications-modal.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-communication-parent-tree',
  templateUrl: './communication-parent-tree.component.html',
  styleUrls: ['./communication-parent-tree.component.scss'],
})
export class CommunicationParentTreeComponent implements OnInit, OnChanges {
  communication: Communication;
  @Input() rootComm;
  @Input() selectedCommunication;
  @Output() selectedCommunicationChanges = new EventEmitter<any>();
  @Output() addedCommunicationChanges = new EventEmitter<any>();
  faPlusCircle = faPlusCircle;
  faArrowRight = faArrowRight;
  faCircle = faCircle;
  faEnvelope = faEnvelope;
  faHome = faHome;
  faPhone = faPhone;
  faComment = faComment;
  faArrowCircleRight = faArrowCircleRight;
  faUser = faUser;
  faPhoneAlt = faPhoneAlt;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faAddressBook = faAddressBook;
  faTrash = faTrash;
  faInfo = faInfoCircle;
  faTimes = faTimes;
  faSearch = faSearch;
  faSync = faSync;
  openChildren = [];
  statusHelper: any[];
  potentialParents: Communication[] = [];
  links = [];
  nodes = [];
  linkid = 0;
  update$: Subject<boolean> = new Subject();
  center$: Subject<boolean> = new Subject();
  zoomToFit$: Subject<boolean> = new Subject();
  height = 250;
  y = 100;
  oldY = 0;
  grabbed = false;
  @ViewChild('graph') graph: ElementRef;
  @ViewChild('hr') hr: ElementRef;
  showMinimap = false;
  zoomEnabled: boolean = false;
  faCogs = faCogs;
  faCaretDown = faCaretDown;
  panningEnabled = true;

  constructor(private statusHelperService: StatusHelperService, private modalHelper: NgbModal) {}

  ngOnInit(): void {
    this.statusHelper = this.statusHelperService.getStatus('badge');
  }

  changeValue(e, property): void {
    this[property] = !this[property];
  }

  private addCommunicationsOnGraph() {
    this.nodes = [];
    this.links = [];
    this.nodes.push({ ...this.rootComm, ...{ label: this.rootComm.name } });
    if (this.rootComm.children && this.rootComm.children.length > 0) {
      this.createTree(this.rootComm);
    }
  }

  private createTree(comm) {
    comm.children.forEach((child) => {
      this.nodes.push({ ...child, ...{ label: child.name } });
      this.links.push({
        id: this.linkid,
        source: comm.id,
        target: child.id,
        label: child.name,
      });
      this.linkid++;
      if (child.children && child.children.length > 0) {
        this.createTree(child);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rootComm) {
      this.addCommunicationsOnGraph();
      this.update$.next(true);
    }
  }

  getIcon(communication) {
    if (communication.communicationDeliveryProtocols !== undefined) {
      if (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'EMAIL') {
        return faEnvelope;
      } else if (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'ADDRESS') {
        return faHome;
      } else if (
        communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'PHONE' &&
        communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'IRIDIUM_VOICE'
      ) {
        return faPhone;
      } else if (
        (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'SMS' ||
          communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'PHONE') &&
        communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'TWILIO-SMS'
      ) {
        return faComment;
      } else if (
        communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'PHONE' &&
        (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'AGENT_ULG' ||
          communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'AGENT_AMERIDIAL')
      ) {
        return faUser;
      } else if (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'INIT') {
        return faArrowCircleRight;
      }
      return faAddressBook;
    }
    return faAddressBook;
  }

  selectComm(communication) {
    this.selectedCommunicationChanges.emit(communication);
  }

  addChild(communication?: Communication, fromParentCommunication?: boolean) {
    const instance = this.modalHelper.open(CommunicationsModalComponent);
    if (fromParentCommunication) {
      instance.componentInstance.fromParentCommunication = fromParentCommunication;
    }

    instance.componentInstance.potentialParents = this.potentialParents;
    instance.componentInstance.selectedParent = communication;
    instance.componentInstance.successEvent.subscribe((data) => {
      this.addedCommunicationChanges.emit();
    });
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.grabbed) {
      return;
    }
    this.resizer(event.clientY - this.oldY);
    this.oldY = event.clientY;
    this.update$.next(true);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    this.grabbed = false;
  }

  resizer(offsetY: number) {
    if (this.height + offsetY > 200) {
      this.height += offsetY;
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.graph.nativeElement.contains(event.target) && this.hr.nativeElement.contains(event.target)) {
      this.grabbed = true;
      this.oldY = event.clientY;
    }
  }
}
