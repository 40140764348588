<form [formGroup]="filterForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="name" />
          <!-- <div class="mt-sm" [hidden]="filterForm.controls.name.valid">
            <small class="text-danger">Please input name</small>
          </div> -->
        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Code <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="className" />
          <!-- <div class="mt-sm" [hidden]="filterForm.controls.className.valid">
            <small class="text-danger">Please input class name</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="status" class="form-control">
            <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
          </select>
          <!-- <div class="mt-sm" [hidden]="filterForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Description</label>
          <textarea formControlName="description" class="form-control vertical-expand"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group form-check">
          <input type="checkbox" formControlName="enabled" id="enabled" class="form-check-input" />
          <label for="enabled" class="form-check-label">Enabled</label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!filterForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>
