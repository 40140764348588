import React, { useEffect, useState } from "react";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import Grid from "@iridium/iridium-ui/Grid";
import { LiveAgentTable } from "./components/LiveAgentTable";
import Paper from "@iridium/iridium-ui/Paper";
import ContentWrapper from "../react/components/ContentWrapper";
import { LiveAgentToolBar } from "./components/LiveAgentToolbar";
import LiveAgentFilterDialog from "./components/LiveAgentFilterDialog";
import { PersonsService } from "../services/persons.service";
import { LiveAgentSurveyFilterCriteria, LiveAgentSurveysService } from "../services/live-agent-surveys.service";
import { LiveAgentSurvey } from "../data/model";
import { useDebouncedCallback } from "use-debounce";
import useInfiniteScroll from "react-infinite-scroll-hook";
import getLogger from "../services/logging/logging.service";

import {
  getInfiniteScrollNextFilter,
  getInfiniteScrollState,
  InfiniteScrollState,
  logInfiniteScrollError,
} from "../widgets/InfiniteScrollUtility";
import { throwError } from "rxjs";

export type LiveAgentSurveysProps = {
  toastr: ToastrService;
  route: ActivatedRoute;
  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean>;
  personsService: PersonsService;
  personId: number;
  liveAgentSurveysService: LiveAgentSurveysService;
  segmentId: number;
  allowExport: boolean;
};

export type LiveAgentToolBarProps = {
  isLiveAgentManager: boolean;
  segmentId: number;
};

const getDefaultFilter: () => LiveAgentSurveyFilterCriteria = () => ({
  segments: [],
  startAssessmentDate: null,
  endAssessmentDate: null,
  memberName: null,
  primaryInstrumentNumber: null,
  completedByUserIds: null,
  limit: 20,
  offset: 0,
  statuses: [],
});

const defaultDataState: InfiniteScrollState<LiveAgentSurvey, LiveAgentSurveyFilterCriteria> = {
  isLoading: false,
  canLoadMore: false,
  nextOffset: 0,
  filter: getDefaultFilter(),
  currentResultsFilter: getDefaultFilter(),
  data: [],
};

const logger = getLogger({ namespace: "LiveAgentSurveys" });

export function LiveAgentSurveys(props: LiveAgentSurveysProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLiveAgentManager, setIsLiveAgentManager] = useState(
    props.liveAgentSurveysService.shouldUseLiveAgentManagerEndpoint()
  );
  const [canView, setCanView] = useState(props.liveAgentSurveysService.canAccessLiveAgentDashboard());
  const [dataState, setDataState] = useState(defaultDataState);
  const [liveAgentManagerList, setLiveAgentManagerList] = useState(null);

  const setFilterSegments = (selectedSegmentIds: number[]) => {
    setDataState({ ...dataState, filter: { ...dataState.filter, segments: selectedSegmentIds } });
  };

  const setFilterModalFilter = (filter: LiveAgentSurveyFilterCriteria) => {
    setDataState({
      ...dataState,
      filter,
    });
    setIsModalOpen(false);
  };

  const handleSearch = useDebouncedCallback(async (concatResults?: boolean): Promise<void> => {
    if (dataState.isLoading) {
      return;
    }
    setDataState({ ...dataState, isLoading: true });
    const nextFilter = getInfiniteScrollNextFilter(dataState, concatResults);
    try {
      const response = !isLiveAgentManager
        ? await props.liveAgentSurveysService.getLiveAgentSurveys(nextFilter)
        : await props.liveAgentSurveysService.getLiveAgentManagerSurveys(nextFilter);
      const newState = getInfiniteScrollState(concatResults, response.entity, nextFilter, dataState);
      setDataState(newState);
    } catch (error) {
      logInfiniteScrollError(logger, nextFilter, error);
      setDataState({ ...dataState, isLoading: false });
    }
  }, 500);

  const handleExportCSV = (e) => {
    if (!(dataState.filter.segments.length > 0)) {
      props.toastr.error("You must select at least one market to export");
    } else {
      if (isLiveAgentManager) {
        props.liveAgentSurveysService.exportLiveAgentManager(dataState.filter).then(
          (res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              new Date()
                .toISOString()
                .slice(-24)
                .replace(/[^0-9]/g, "")
                .slice(0, 14) + "-survey-export.csv"
            );
            document.body.appendChild(link);
            link.click();
            props.toastr.success("Surveys successfully exported.");
          },
          () => {
            props.toastr.error("Error while exporting surveys.");
          }
        );
      } else {
        props.liveAgentSurveysService.exportLiveAgent(dataState.filter).then(
          (res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              new Date()
                .toISOString()
                .slice(-24)
                .replace(/[^0-9]/g, "")
                .slice(0, 14) + "-survey-export.csv"
            );
            document.body.appendChild(link);
            link.click();
            props.toastr.success("Surveys successfully exported.");
          },
          () => {
            props.toastr.error("Error while exporting surveys.");
          }
        );
      }
    }
  };

  const handleAgentList = useDebouncedCallback(async () => {
    try {
      const response = await props.liveAgentSurveysService.getLiveAgentList();
      const agentList = response.entity;
      setLiveAgentManagerList(
        agentList.map((agent) => {
          return {
            label: agent.firstName + " " + agent.lastName,
            id: agent.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, 500);

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: dataState.isLoading,
    hasNextPage: dataState.canLoadMore,
    onLoadMore: () => {
      console.log("ref triggered");
      handleSearch(true);
    },
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    handleAgentList();
  }, []);

  useEffect(() => {
    void handleSearch(false);
  }, [dataState.filter]);
  if (canView) {
    return (
      <>
        <ContentWrapper>
          <Grid container component={Paper} p={3} pt={2}>
            <Grid item sx={{ width: "100%" }}>
              <LiveAgentToolBar
                setIsModalOpen={setIsModalOpen}
                exportCSV={handleExportCSV}
                personsService={props.personsService}
                personId={props.personId}
                setFilterSegments={setFilterSegments}
                segmentId={props.segmentId}
                allowExport={props.allowExport}
              />
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <LiveAgentTable
                isLoading={dataState.isLoading}
                canLoadMore={dataState.canLoadMore}
                liveAgentSurveyList={dataState.data}
                organizationPath={"test"}
                entityTypeName={""}
                isLiveAgentManager={isLiveAgentManager}
                infiniteScrollRef={infiniteScrollRef}
              />
            </Grid>
            <Grid item>
              <LiveAgentFilterDialog
                filter={dataState.filter}
                isLiveAgentManager={isLiveAgentManager}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                liveAgentManagerList={liveAgentManagerList}
                onFormSubmit={setFilterModalFilter}
                renderInputs={function (params: {
                  setPendingFilter: (filter: unknown) => void;
                  pendingFilter: unknown;
                }) {
                  throw new Error("Function not implemented.");
                }}
              />
            </Grid>
          </Grid>
        </ContentWrapper>
      </>
    );
  }
  return <h1>Access Denied</h1>;
}
