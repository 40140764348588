import { TemporalConditions } from './../../data/class';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { CommunicationsService } from 'src/app/services/communications.service';

@Component({
  selector: 'app-temporal-conditions',
  templateUrl: './temporal-conditions.component.html',
  styleUrls: ['./temporal-conditions.component.scss'],
})
export class TemporalConditionsComponent implements OnInit {
  @ViewChildren('form') forms: QueryList<any>;
  @Input() temporalConditions: any;
  @Input() configurationType: string;
  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  periods: any;
  conditions: any[];
  timeFromEventType: any;
  endpointTypes: any[];
  faTimes = faTimes;
  faPlus = faPlus;

  constructor(private communicationsServie: CommunicationsService) {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  //THE VALUE MUST MATCH THE DateUtil.PeriodCode enum
  ngOnInit(): void {
    this.periods = [
      { value: 'HOUR', text: 'HOUR' },
      { value: 'DAY', text: 'DAY' },
      { value: 'WEEK', text: 'WEEK' },
      { value: 'MONTH', text: 'MONTH' },
      { value: 'QUARTER', text: 'QUARTER' },
      { value: 'YEAR', text: 'YEAR' },
    ];
    this.conditions = [
      { value: true, text: 'HAS' },
      { value: false, text: 'DOES NOT HAVE' },
    ];
    this.timeFromEventType = [
      'PARENT',
      'ATTRIBUTE_EFFECTIVE',
      'ATTRIBUTE_EXPIRATION',
      'PREFERENCE_EFFECTIVE',
      'PREFERENCE_EXPIRATION',
      'BEHAVIOR',
      'REWARD',
    ];
    this.getAllEndpointTypes();
  }

  getAllEndpointTypes(): void {
    this.communicationsServie.getAllEndpointTypes(false).subscribe((data: any) => {
      this.endpointTypes = data.entity;
    });
  }

  initRestrictionObject(): void {
    const tmpObj = new TemporalConditions();

    if (this.temporalConditions) {
      this.temporalConditions.push(tmpObj);
    } else {
      this.temporalConditions = [];
      this.temporalConditions.push(tmpObj);
    }
  }

  updateRestrictions(): void {
    this.debouncer.next(this.temporalConditions);
    let valid = true;
    for (const form of this.forms) {
      valid = valid && form.valid;
    }
    this.isValidChange.emit(valid);
  }

  removeRestriction(restriction: any): void {
    this.temporalConditions.splice(this.temporalConditions.indexOf(restriction), 1);
    this.updateRestrictions();
  }

  keyPressNumbers(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 49 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
