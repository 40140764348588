import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormsModule } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-candidate-communication-consequence',
  templateUrl: './candidate-communication-consequence.component.html',
  styleUrls: ['./candidate-communication-consequence.component.scss'],
})
export class CandidateCommunicationConsequenceComponent {
  @Input() sendCommunication: any;
  faTimes = faTimes;
  faPlus = faPlus;
  @Input() communicationTypes: any;
  @Input() communications: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  isResponses: any;

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  initConsequenceObject(): void {
    var tmpObj = {
      class:
        'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences$SendCommunication',
    };
    if (this.sendCommunication) {
      this.sendCommunication.push(tmpObj);
    } else {
      this.sendCommunication = [];
      this.sendCommunication.push(tmpObj);
    }

    this.isResponses = [
      { value: true, text: 'true' },
      { value: false, text: 'false' },
    ];
  }

  initBindingObject(consequence): void {
    var tmpObj = {
      class:
        'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences$SendCommunication$CommunicationBindings',
      binding: {
        class:
          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences$SendCommunication$CommunicationBindings$Binding',
        bindingName: '',
        bindingValue: '',
      },
    };
    var i = this.sendCommunication.indexOf(consequence);
    if (this.sendCommunication[i].communicationBindings != null) {
      this.sendCommunication[i].communicationBindings.push(tmpObj);
    } else {
      this.sendCommunication[i].communicationBindings = [];
      this.sendCommunication[i].communicationBindings.push(tmpObj);
    }
  }

  updateConsequences() {
    this.debouncer.next(this.sendCommunication);
  }

  removeConsequence(consequence) {
    this.sendCommunication.splice(this.sendCommunication.indexOf(consequence), 1);
    this.updateConsequences();
  }

  removeBinding(consequence, binding) {
    var i = this.sendCommunication.indexOf(consequence);
    this.sendCommunication[i].communicationBindings.splice(
      this.sendCommunication[i].communicationBindings.indexOf(binding),
      1
    );
  }
}
