import { Directive, ElementRef, OnDestroy, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[appScrollWithMe]',
})
export class ScrollWithMeDirective implements OnInit, OnDestroy {
  @Input() isProgram: boolean;
  top: number;
  prevoiusPosition = 0;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    const wrapper = document.getElementById('wrapper');
    this.top = this.el.nativeElement.getBoundingClientRect().top + wrapper.scrollTop;
    this.scrollEvent({ target: wrapper });
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollEvent, true);
    this.el.nativeElement.style.top = null;
  }

  scrollEvent = (event: any): void => {
    const target = event.target as Element;
    if (target.id === 'wrapper') {
      const scrollYPosition = target.scrollTop;
      if (this.isProgram) {
        this.setPosition(Math.max(scrollYPosition - this.top, 0));
      } else {
        const ibox = document.getElementsByClassName('ibox')[document.getElementsByClassName('ibox').length - 1];
        if (ibox) {
          const { top, bottom } = this.el.nativeElement.getBoundingClientRect();
          if (bottom - top > window.innerHeight) {
            const diff = this.prevoiusPosition - scrollYPosition;
            if (bottom < window.innerHeight - 30 && diff < 0) {
              this.setPosition(scrollYPosition + window.innerHeight - bottom + top - 30 - this.top);
            } else if (top > 0 && diff > 0) {
              this.setPosition(Math.max(scrollYPosition - this.top, 0));
            }
          } else {
            this.setPosition(Math.max(scrollYPosition - this.top, 0));
          }
        }
        this.prevoiusPosition = scrollYPosition;
      }
    }
  };

  setPosition(position: number): void {
    setTimeout(() => {
      const shrinker = document.getElementById('shrinker');
      if (shrinker) {
        shrinker.style.top = 10 + position + 'px';
      }
      this.el.nativeElement.style.marginTop = position + 'px';
    }, 500);
  }
}
