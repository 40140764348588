<form [formGroup]="promotionCategoryForm" novalidate class="com-model">
  <div class="com-outreach-modal">
    <div>
      <span class="title">Category</span>
      <hr class="mb-1 mt-4" />
    </div>
    <div class="mb-4 mt-4">
      <span class="com-title">{{ title }}</span>
    </div>
    <div class="">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label class="com-label">Name</label>
        <input type="text" class="form-control inputfontsize" formControlName="name" placeholder="Add name" />
        <div class="mt-2" [hidden]="promotionCategoryForm.controls['name'].valid">
          <!-- <small class="text-danger">Input a Name</small> -->
        </div>
      </div>

      <div class="form-group">
        <label class="com-label">Sort</label>
        <input name="sort" type="number" class="form-control inputfontsize" formControlName="sort" />
        <div class="mt-2" [hidden]="promotionCategoryForm.controls['sort'].valid">
          <!-- <small class="text-danger">Sort order is required. Please add a Sort Order.</small> -->
        </div>
      </div>

      <div *ngIf="isEdit" class="form-group">
        <label class="com-label">Status</label>
        <!-- <select formControlName="status" class="form-control" formControlName="status">
        <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
      </select> -->
        <ng-select
          formControlName="status"
          class="inputfontsize"
          placeholder="Select status"
          formControlName="status"
          [items]="statuses"
        ></ng-select>
        <div class="mt-2" [hidden]="promotionCategoryForm.controls['status'].valid">
          <!-- <small class="text-danger">Select status</small> -->
        </div>
      </div>

      <div *ngIf="!isEdit" class="form-group">
        <label class="com-label">Status</label>
        <!-- <select formControlName="status" class="form-control" formControlName="status">
          <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
        </select> -->
        <ng-select
          formControlName="status"
          class="inputfontsize"
          placeholder="Select status"
          formControlName="status"
          [items]="statuses"
        ></ng-select>
        <div class="mt-2" [hidden]="promotionCategoryForm.controls['status'].valid">
          <!-- <small class="text-danger">Select status</small> -->
        </div>
      </div>

      <div class="pop-btn-container mt-5 mb-5">
        <!-- <button
        class="btn btn-dark-blue"
        type="submit"
        [disabled]="!promotionCategoryForm.valid"
        (click)="ok()"
        title="Save"
        >
        <fa-icon [icon]="faSave"></fa-icon>
      </button>
      <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button> -->
        <button class="btn-pop-cancel mr-2" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
        <button
          class="btn-pop-success"
          type="submit"
          [disabled]="!promotionCategoryForm.valid"
          (click)="ok()"
          title="Save"
        >
          {{ isEdit ? "SAVE" : "ADD" }}
        </button>
      </div>
    </div>
  </div>
</form>
