import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appNumbersOnly]',
})
export class NumbersOnlyDirective {
  @Input() model: any;

  constructor(private el: ElementRef) {}

  @HostListener('input')
  setNewValue(): void {
    const re = /[^0-9]/g;
    const str = this.el.nativeElement.value;
    const newstr = str.replace(re, '');
    this.el.nativeElement.value = newstr;
    if (this.model) {
      this.model.value = newstr;
    }
  }
}
