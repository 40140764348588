import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-filter-typeahead',
  templateUrl: './filter-typeahead.component.html',
  styleUrls: ['./filter-typeahead.component.scss'],
})
export class FilterTypeaheadComponent implements OnInit {
  @Input() section: any;
  @Input() filter: any;
  search: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map((term) =>
          term.length < 2
            ? []
            : this.filter.choices.filter((v: any) => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 8)
        )
      );
  }
}
