<div class="modal-content modalstyle">
  <!-- <div class="d-flex justify-content-end mr-3 mt-3 cursor-pointer">
    <mat-icon (click)="cancel()">clear</mat-icon>
  </div> -->
  <div class="modal-body" *ngIf="!promotionEdit" style="padding-top: 24px">
    <div class="mb-4">
      <!-- <p class="modal-title mb-2" *ngIf="!showbutton">{{ header }}</p>
      <p class="modal-title mb-2" *ngIf="showbutton" > Attribute details</p> -->
      <p class="modal-title mb-3 com-title" *ngIf="!Strategy && !header">{{ attribute ? "Expire" : title }}</p>
      <p class="title">{{ header }}</p>
      <div class="border-bottom"></div>
    </div>
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">{{ errorMsg }}</div>
    <!-- <p>{{ text || "Are you sure?" }}</p> -->
    <!-- <span class="expire" *ngIf="!showbutton">Expire attribute</span> -->
    <span class="expire">{{ attribute ? "Expire" : "Delete" }} </span>
    <span class="expire">confirmation {{ nametag }}</span>
    <!-- <span class="expire" *ngIf="category">confirmation {{ nametag }}</span>
    <span class="expire" *ngIf="attribute">confirmation {{ nametag }}</span>
    <span class="expire" *ngIf="info">confirmation {{ nametag }}</span>
    <span class="expire" *ngIf="programspop">confirmation {{ nametag }}</span>
    <span class="expire" *ngIf="promotionTypepop">confirmation {{ nametag }}</span>
    <span class="expire" *ngIf="promotionpop">confirmation {{ nametag }}</span>
    <span class="expire" *ngIf="Strategy">confirmation {{ nametag }}</span>
    <span class="expire" *ngIf="Segments">confirmation {{ nametag }}</span> -->
  </div>
  <div class="modal-body" *ngIf="promotionEdit" style="padding-top: 24px">
    <div class="mb-4">
      <p class="title">{{ header }}</p>
      <div class="border-bottom"></div>
    </div>
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">{{ errorMsg }}</div>
    <p>{{ text || "Are you sure?" }}</p>
  </div>
  <div class="pop-btn-container">
    <button type="button" class="btn-pop-success" *ngIf="onlyConfirm" (click)="confirmDelete()">
      {{ okButton || "YES" }}
    </button>
    <button type="button" class="btn-pop-cancel" (click)="cancel()">
      {{ cancelButton || "CANCEL" }}
    </button>
    <button class="btn btn-primary font-weight-bold" *ngIf="promotionEdit && !onlyConfirm" (click)="confirmDelete()">
      {{ importProduct ? "OK" : "Edit" }}
    </button>
    <!-- <button type="button" class="btn btn-primary font-weight-bold" *ngIf="!showbutton" (click)="ok()">{{ okButton || "EXPIRE" }}</button> -->
    <button type="button" class="btn-pop-delete" *ngIf="!promotionEdit && !attribute" (click)="confirmDelete()">
      DELETE
    </button>
    <button type="button" class="btn btn-primary font-weight-bold mr-2" *ngIf="attribute" (click)="confirmDelete()">
      EXPIRE
    </button>
  </div>
</div>