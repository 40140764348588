<form [formGroup]="memberInstrumentForm">
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Identifier Type Field Index</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="instrumentTypeField" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Identifier Type Default</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="instrumentTypeDefault" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Field Index</label>
      </div>
      <div class="col-md-3">
        <input type="number" class="form-control" formControlName="fieldIndex" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label for="primary" class="float-right form-check-label"> Primary </label>
      </div>
      <div class="col-md-3">
        <input id="primary" type="checkbox" formControlName="primary" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Expiry Field</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="expiryField" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Expiry Default</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="expiryDefault" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Activation Field</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="activationField" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Activation Default</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="activationDefault" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Hash Field</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="hashField" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Field Length</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="fieldLength" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Field Regex</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="fieldRegex" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Field Name</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="fieldName" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Concat Field</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="concatField" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label class="float-right control-label">Concat Delimiter</label>
      </div>
      <div class="col-md-3">
        <input type="text" class="form-control" formControlName="concatDelimiter" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-3">
        <label for="required" class="float-right form-check-label"> Required </label>
      </div>
      <div class="col-md-3">
        <input id="required" type="checkbox" formControlName="required" />
      </div>
    </div>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-4 offset-md-3">
        <span *ngIf="index !== null">
          <button class="btn btn-attention" title="Cancel" (click)="emitEvent('reset', index)">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <button
            id="create-member-instrument"
            class="btn btn-dark-blue"
            type="button"
            (click)="emitEvent('save', index)"
            title="Add"
          >
            <fa-icon [icon]="faSave"></fa-icon>
          </button>
        </span>
        <span *ngIf="index === null">
          <button class="btn btn-attention" type="reset" title="Cancel">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <button
            id="create-member-instrument"
            class="btn btn-dark-blue"
            type="button"
            (click)="emitEvent('add')"
            title="Add"
          >
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        </span>
      </div>
    </div>
  </div>
</form>
