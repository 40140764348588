import React, { useState } from "react";
import { LeadingInstructionData, WavInstructionData } from "../services/callflow.service";
import { Instruction, InstructionVariant } from "../models/model";
import createQuestionData from "./QuestionData";
import Grid from "@iridium/iridium-ui/Grid";
import Button from "@iridium/iridium-ui/Button";
import NodeVariantTable from "./NodeVariantTable";
import { Typography } from "@mui/material";
import Box from "@iridium/iridium-ui/node_modules/@mui/material/Box";
import WavDataTable from "./WavDataTable";
import { Tab, Tabs, TextField } from "@iridium/iridium-ui/node_modules/@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@iridium/iridium-ui/Button/IconButton";

export default function SelectedInstruction(props: {
  leadingInstructionDataList: LeadingInstructionData[];
  selectedInstruction: Instruction;
  waveDataList: WavInstructionData[];
  edgesHidden: boolean;
  onPlayWav: (audioFile?: string) => void;
  onEdgeHideShow: (instruction?: string) => void;
  selectedVariant: InstructionVariant | false;
  setSelectedVariant: (selectedVariant?: InstructionVariant) => void;
  expandedVariant: string | false;
  setExpandedVariant: (expandedVariant?: string) => void;
}) {
  const {
    leadingInstructionDataList,
    selectedInstruction,
    onPlayWav,
    onEdgeHideShow,
    selectedVariant,
    setSelectedVariant,
    expandedVariant,
    setExpandedVariant,
  } = props;
  const previousInstructionRows = leadingInstructionDataList.map((leadingInstructionData) =>
    createQuestionData(leadingInstructionData)
  );

  const [tabValue, setTabValue] = useState(0);
  const [advancedSelected, setAdvancedSelected] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ width: "100%" }}
      >
        {value === index && (
          <Grid item xs={12}>
            <Typography>{children}</Typography>
          </Grid>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Grid container rowSpacing={12} sx={{ p: 2 }}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4">{selectedInstruction.id}</Typography>
        <Button onClick={() => onEdgeHideShow(selectedInstruction.id)}>Show/Hide Edges</Button>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Variants" />
            <Tab label="Inputs" />
          </Tabs>
        </Box>
      </Grid>
      <TabPanel value={tabValue} index={0}>
        <Grid item xs={12}>
          <div style={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                mt: 3,
              }}
            >
              <Typography variant="h5">Variants</Typography>
              <Button>Add Variant</Button>
            </Box>
          </div>
          <NodeVariantTable
            selectedInstruction={selectedInstruction}
            setSelectedVariant={setSelectedVariant}
            expandedVariant={expandedVariant}
            setExpandedVariant={setExpandedVariant}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <div style={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                  mt: 3,
                }}
              >
                <Typography variant="h5">Files</Typography>
                <Button>Add File</Button>
              </Box>
            </div>
            {selectedVariant && <WavDataTable onPlayWav={onPlayWav} selectedVariant={selectedVariant} />}
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <TextField id="id" label="ID" sx={{ width: "100%", mb: 2, mt: 2 }} value={selectedInstruction.id} />
        <TextField
          id="NextInstructionId"
          label="Next Instruction Id"
          sx={{ width: "100%", mb: 2 }}
          value={selectedInstruction.nextInstructionId}
        />

        <TextField
          id="tags"
          label="Platform Meta Data: Tags"
          sx={{ width: "100%", mb: 2 }}
          value={selectedInstruction.platformMetaData.tags.join(", ")}
        />
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={11}>
            <Typography>Advanced Options</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              ".MuiIconButton-root.MuiIconButton-colorPrimary.MuiIconButton-sizeSmall": {
                borderWidth: "0px !important",
              },
            }}
          >
            <IconButton sx={{ borderWidth: "0px" }} onClick={() => setAdvancedSelected(!advancedSelected)}>
              {!advancedSelected ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>
        </Grid>

        {advancedSelected && (
          <>
            <TextField
              id="masterCatalogId"
              label="Platform Meta Data: Master Catalog ID"
              sx={{ width: "100%", mb: 2 }}
              value={selectedInstruction.platformMetaData.masterCatalogueId}
            />
            <TextField
              id="skipWhenMemberPhoneNumberRecognized"
              label="Skip When Member Phone Number Recognized"
              sx={{ width: "100%", mb: 2 }}
              value={selectedInstruction.skipWhenMemberPhoneNumberRecognized}
            />
          </>
        )}
      </TabPanel>
    </Grid>
  );
}
