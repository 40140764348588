<form>
  <div class="modal-header">
    <h1>Document Details</h1>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <ul class="list-group activity-list">
        <li class="list-group-item first-item document-modal-li-padding">
          <div class="row">
            <div class="table mb-0">
              <table class="table table-pointable table-hover mb-0">
                <thead>
                  <tr>
                    <th id="document-details-component-id">ID</th>
                    <th id="document-details-component-fileName">File Name</th>
                    <th id="document-details-component-originalFileName">Original File Name</th>
                    <th id="document-details-component-internalId">Internal ID</th>
                    <th id="document-details-component-inserted_at">Inserted At</th>
                    <th id="document-details-component-type">Type</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [(ngModel)]="document">
                    <td>{{ document.node.id }}</td>
                    <td>{{ document.node.filename }}</td>
                    <td>{{ document.node.originalFilename }}</td>
                    <td>{{ document.node.internalId }}</td>
                    <td>{{ document.node.insertedAt | date: "yyyy-MM-dd hh:mm:ss" }}</td>
                    <td>{{ document.node.type }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning" type="button" (click)="close()">Close</button>
  </div>
</form>
