import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class ForgotPasswordService {
  private urlBase: string = this.globalData.baseUrl + 'users/';

  constructor(private globalData: AppDataService, private http: HttpClient) {}

  public forgotPassword(username: string) {
    return this.http.post(this.urlBase + 'password/forgotten', username, {
      headers: { skipInterceptor: 'true' },
    });
  }

  public newPassword(party: any) {
    return this.http.post(this.urlBase + 'new/password', party);
  }
}
