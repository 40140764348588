import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from '../app-data.service';
import { CommunicationsModalComponent } from '../communications-modal/communications-modal.component';
import { Tab } from '../data/class';
import { Communication, DeliveryProtocol } from '../data/model';
import { ChirpService } from '../services/chirp.service';
import { CommunicationsService } from '../services/communications.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import {
  faPencilAlt,
  faClipboardCheck,
  faCircle,
  faEnvelope,
  faHome,
  faPhone,
  faArrowCircleRight,
  faUser,
  faChevronUp,
  faUserPlus,
  faComment,
  faAddressBook,
} from '@fortawesome/free-solid-svg-icons';
import { CommunicationHistoryTabComponent } from '../communication-tabs/communication-history-tab/communication-history-tab.component';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
})
export class CommunicationComponent implements OnInit {
  tabs: Tab[];
  communication: Communication;
  commId: number;
  deliveryProtocols: DeliveryProtocol[];
  faPencilAlt = faPencilAlt;
  faClipboardCheck = faClipboardCheck;
  faCircle = faCircle;
  faEnvelope = faEnvelope;
  faHome = faHome;
  faArrowCircleRight = faArrowCircleRight;
  faUser = faUser;
  faPhone = faPhone;
  faChevronUp = faChevronUp;
  faUserPlus = faUserPlus;
  headerName = '';
  selectedComm: Communication;
  potentialParents: Communication[];
  filterFunctions: any;
  tabIndex: number;
  @ViewChild(CommunicationHistoryTabComponent) child;
  constructor(
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private communicationsService: CommunicationsService,
    private chirpService: ChirpService,
    public appDataService: AppDataService,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private globalData: AppDataService,
    private applyfilterService: ApplyFilterService
  ) {}

  currentUser = this.globalData.username;
  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.communicationsService.getCommunicationStructure(id).subscribe(
        (data: any) => {
          this.parseResponse(data);
        },
        () => {
          this.router.navigate(['/communications']);
        }
      );
    }
    this.tabs = [
      { title: 'Segments', active: true },
      { title: 'Attributes', active: false },
      { title: 'Attachments', active: false },
      { title: 'History', active: false },
    ];
    this.getCommunication(id);
  }
  tabClick(tab) {
    if (tab.index == 4) {
      this.child.clearAllChip();
      //this is hacky, but this tab is being shared and I need it to only show when it's selected. I will fix this when I implement somethign similar for the whole screen.
      this.tabIndex = 999;
    }
    this.applyfilterService.filterfun.subscribe((data) => {
      this.filterFunctions = data;
    });
    if (this.filterFunctions) {
      this.filterFunctions.clearAll();
    }
    this.applyfilterService.getFilterData([]);
  }
  isSelected(name: string): boolean {
    const filteredTabs = new Array<Tab>();
    this.tabs.forEach((tab): void => {
      if (tab.title === name && tab.active) {
        filteredTabs.push(tab);
      }
    });

    if (filteredTabs.length > 0 && filteredTabs[0].active) {
      return true;
    } else {
      return false;
    }
  }

  select(name: string): void {
    this.tabs.forEach((tab) => {
      if (tab.title === name) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    });
  }

  getCommunicationStructure(): void {
    this.communicationsService
      .getCommunicationStructure(this.activatedRoute.snapshot.params.id)
      .subscribe((data: any) => {
        this.parseResponse(data);
      });
  }

  getCommunication(id: number): void {
    this.communicationsService.getCommunication(id).subscribe((data: any) => {
      if (data.success) {
        this.commId = data.entity.id;
        this.selectedComm = data.entity;
        this.headerName = data.entity.name;
        this.title.setTitle(data.entity.name);
        this.chirpService.getDeliveryProtocols(this.commId).subscribe((resp: any) => {
          this.deliveryProtocols = resp.entity;
        });
      }
    });
  }

  getSelectedComm(): Communication {
    return this.selectedComm;
  }

  parseResponse(data: any): void {
    if (data.success) {
      this.communication = data.entity;
      this.headerName = data.entity.name;
      this.potentialParents = [];
      this.getAllPotentialParents(this.communication);
      this.title.setTitle(this.communication.name);
    }
  }

  edit(model: Communication): void {
    const instance = this.modalHelper.open(CommunicationsModalComponent);
    if (model.parentId) {
      instance.componentInstance.fromParentCommunication = true;
    }
    instance.componentInstance.potentialParents = this.potentialParents;
    instance.componentInstance.communication = model;
    instance.componentInstance.successEvent.subscribe(() => {
      this.potentialParents = [];
      this.getCommunicationStructure();
      this.getCommunication(this.commId);
      instance.close();
    });
  }

  selectCommunication(communication: Communication, event?: any): void {
    this.selectedComm = communication;
    if (event) {
      event.stopPropagation();
    }
  }

  selectComm(communication) {
    this.selectedComm = communication;
    this.getCommunication(communication.id);
    this.potentialParents = [];
    this.getAllPotentialParents(this.communication);
  }

  communicationChanged(event: Communication): void {
    this.getCommunication(event.id);
  }

  getDeliveryProtocolName() {
    return this.selectedComm?.communicationDeliveryProtocols?.length > 0
      ? this.selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.name
      : '';
  }

  getAllPotentialParents(communication: Communication): void {
    if (this.selectedComm && this.selectedComm.id != communication.id) {
      if (communication.children) {
        this.potentialParents.push(communication);
        const potentialParentsArray = communication.children;
        potentialParentsArray.forEach((comm: Communication) => {
          this.getAllPotentialParents(comm);
        });
      } else {
        this.potentialParents.push(communication);
      }
    }
  }

  getIcon(communication) {
    if (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'EMAIL') {
      return faEnvelope;
    } else if (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'ADDRESS') {
      return faHome;
    } else if (
      communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'PHONE' &&
      communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'IRIDIUM_VOICE'
    ) {
      return faPhone;
    } else if (
      (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'SMS' ||
        communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'PHONE') &&
      communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'TWILIO-SMS'
    ) {
      return faComment;
    } else if (
      communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'PHONE' &&
      (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'AGENT_ULG' ||
        communication.communicationDeliveryProtocols[0]?.deliveryProtocol.name === 'AGENT_AMERIDIAL')
    ) {
      return faUser;
    } else if (communication.communicationDeliveryProtocols[0]?.deliveryProtocol.endpointType.name === 'INIT') {
      return faArrowCircleRight;
    }
    return faAddressBook;
  }
}
