import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { ProcessingActivityLogService } from 'src/app/services/processing-activity-log.service';

@Component({
  selector: 'app-processing-log-notes-modal',
  templateUrl: './processing-log-notes-modal.component.html',
  styleUrls: ['./processing-log-notes-modal.component.scss'],
})
export class ProcessingLogNotesModalComponent implements OnInit {
  @Input() procActivityLog: any; // TODO: assign type
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  logNoteForm: FormGroup;
  item: any;
  errorMsg = '';
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private processingActivityLogService: ProcessingActivityLogService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.procActivityLog) {
      this.logNoteForm = this.formBuilder.group({
        notes: [this.procActivityLog.notes],
      });
    } else {
      this.logNoteForm = this.formBuilder.group({
        notes: [''],
      });
    }
  }

  ok(): void {
    this.processingActivityLogService
      .updateNote(this.procActivityLog.id, this.procActivityLog.activityId, this.logNoteForm.value.notes)
      .subscribe(
        () => {
          this.successEvent.emit();
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
