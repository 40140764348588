<form [formGroup]="communicationTestForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Member Profile ID <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="partyId" />
      <!-- <div class="m-t-xs" [hidden]="communicationTestForm.controls['partyId'].valid">
        <small class="text-danger">Please Input a Member Profile ID</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Program ID (External)<span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="externalProgramId" />
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Promotion Code</label>
        <span class="mb-1">(Optional)</span>
      </div>
      <input type="text" class="form-control" formControlName="promotionCode" />
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons
      (ok)="ok()"
      (cancel)="cancel()"
      [submitDisabled]="!communicationTestForm.valid"
      [submitText]="'Send'"
    ></app-modal-buttons>
    <!-- <button
      class="btn btn-dark-blue"
      type="submit"
      [disabled]="!communicationTestForm.valid"
      (click)="ok()"
      title="Save"
    >
      <fa-icon [icon]="faPaperPlane"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button> -->
  </div>
</form>
