import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  styleUrls: ['./filter-checkbox.component.scss'],
})
export class FilterCheckboxComponent {
  @Input() section: any;
  @Input() filter: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
