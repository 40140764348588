import React from "react";
import { useEffect, useState } from "react";
import { Organization } from "../data/model";
import {
  OrganizationsService,
  GetAllHierarchicalOrganizationsForParentParams,
  CreateOrUpdateOrganizationParams,
} from "../services/organizations.service";
import { useDebouncedCallback } from "use-debounce";
import useInfiniteScroll from "react-infinite-scroll-hook";
import OrganizationsView from "./OrganizationsView";
import { UtilityService } from "../services/utility.service";
import getLogger from "../services/logging/logging.service";
import { CreateOrUpdateOrganizationFormFields } from "./components/OrganizationsModal/CreateOrEditOrganizationModal";
import {
  EntityPartyRoleName,
  navigateToOrganizationBasedOnChildType,
  OrganizationHierarchyIdLookup,
} from "./services/url-builder.service";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { DEFAULT_PARENT_NAME, DEFAULT_SEARCH_VALUE } from "./constants";
import { ToastrService } from "ngx-toastr";
import {
  PROGRAMS_BORDER_COLOR,
  SPONSORS_BORDER_COLOR,
  CONFIGURATIONS_BORDER_COLOR,
  CONFIGURATIONS_TITLE,
  PROGRAMS_TITLE,
  SPONSORS_TITLE,
  CONFIGURATIONS_TEST_ID,
  PROGRAMS_TEST_ID,
  SPONSORS_TEST_ID,
} from "./components/DataCard/constants";

const utilityService = new UtilityService();

const logger = getLogger({ namespace: "Organizations" });

export type OrganizationsProps = {
  toastr: ToastrService;
  getHierarchicalOrganization: OrganizationsService["getHierarchicalOrganization"];
  getAllHierarchicalOrganizationsForParent: OrganizationsService["getAllHierarchicalOrganizationsForParent"];
  createOrUpdateOrganization: OrganizationsService["createOrUpdateOrganization"];
  getOrganizationStatistics: OrganizationsService["getOrganizationStatistics"];
  getOrgByUsername: OrganizationsService["getOrgByUsername"];
  childEntityPartyRoleName: EntityPartyRoleName;
  route: ActivatedRoute;
  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean>;
};

function getParentIdForContext(params: {
  childEntityPartyRoleName: EntityPartyRoleName;
  routeParams: OrganizationHierarchyIdLookup;
}): undefined | number {
  const { routeParams, childEntityPartyRoleName } = params;
  const { parentId, lineOfBusinessId, clientId, marketId } = routeParams;
  switch (childEntityPartyRoleName) {
    case EntityPartyRoleName.MARKET:
      return lineOfBusinessId;
    case EntityPartyRoleName.LOB:
      return clientId;
    case EntityPartyRoleName.CLIENT:
      return parentId;
    default:
      return undefined;
  }
}

function getParentCode({
  organizationBeingEdited,
  parentOrganization,
}: {
  organizationBeingEdited?: Organization;
  parentOrganization?: Organization;
}) {
  return organizationBeingEdited?.parentCode || parentOrganization?.name || DEFAULT_PARENT_NAME.toUpperCase();
}

function getParentId({
  organizationBeingEdited,
  parentOrganization,
}: {
  organizationBeingEdited?: Organization;
  parentOrganization?: Organization;
}) {
  return organizationBeingEdited?.parentId || parentOrganization?.id;
}

let lastRequestTimestamp = 0;
export type OrganizationListFilter = Partial<GetAllHierarchicalOrganizationsForParentParams>;

export default function Organizations(props: OrganizationsProps): JSX.Element {
  const {
    getAllHierarchicalOrganizationsForParent,
    createOrUpdateOrganization,
    getHierarchicalOrganization,
    getOrganizationStatistics,
    getOrgByUsername,
    childEntityPartyRoleName,
    route,
    navigate,
    toastr,
  } = props;
  const [selectedOrganization, setSelectedOrganization] = React.useState<Organization>(null);
  const [organizationList, setOrganizationList] = React.useState<Organization[]>([]);
  const [parentOrganizationBasedOnChildType, setParentOrganizationBasedOnChildType] = React.useState<
    Organization | undefined
  >();
  const [organizationBeingEdited, setOrganizationBeingEdited] = React.useState<Organization | undefined>();
  const [isLoading, setLoading] = React.useState(false);
  const [canLoadMore, setCanLoadMore] = React.useState(false);
  const [nextOffset, setNextOffset] = React.useState(0);
  const parentName = parentOrganizationBasedOnChildType?.name || DEFAULT_PARENT_NAME.toUpperCase();
  const parentOrgId = parentOrganizationBasedOnChildType?.id;
  const [routeParams, setRouteParams] = useState<OrganizationHierarchyIdLookup>(() => route.snapshot.params);
  const { marketId, lineOfBusinessId, clientId, parentId } = routeParams;

  const [organizationPath, setOrganizationPath] = useState<string>("/organizations");
  const [organizationStatistics, setOrganizationStatistics] = React.useState([
    {
      title: PROGRAMS_TITLE,
      borderTopColor: PROGRAMS_BORDER_COLOR,
      data: undefined,
      testId: CONFIGURATIONS_TEST_ID,
    },
    {
      title: SPONSORS_TITLE,
      borderTopColor: SPONSORS_BORDER_COLOR,
      data: undefined,
      testId: PROGRAMS_TEST_ID,
    },
    {
      title: CONFIGURATIONS_TITLE,
      borderTopColor: CONFIGURATIONS_BORDER_COLOR,
      data: undefined,
      testId: SPONSORS_TEST_ID,
    },
  ]);

  function getStatistics(organizationId?: number) {
    if (organizationId) {
      getOrganizationStatistics({ sponsorPartyId: organizationId })
        .then((response) => {
          setOrganizationStatistics([
            {
              title: PROGRAMS_TITLE,
              borderTopColor: PROGRAMS_BORDER_COLOR,
              data: response.entity.campaignCount,
              testId: CONFIGURATIONS_TEST_ID,
            },
            {
              title: SPONSORS_TITLE,
              borderTopColor: SPONSORS_BORDER_COLOR,
              data: response.entity.promotionSponsorCount,
              testId: PROGRAMS_TEST_ID,
            },
            {
              title: CONFIGURATIONS_TITLE,
              borderTopColor: CONFIGURATIONS_BORDER_COLOR,
              data: response.entity.promotionConfigCount,
              testId: SPONSORS_TEST_ID,
            },
          ]);
        })
        .catch(() => {
          const errorMessage = `There was an error fetching statistics for parent ${parentId}`;
          logger.error({
            message: errorMessage,
            data: { parentId },
          });
          toastr.error(errorMessage);
        });
    } else {
      getOrgByUsername("IcarioTenant")
        .then((data) => {
          getOrganizationStatistics({ sponsorPartyId: data.entity.id })
            .then((response) => {
              setOrganizationStatistics([
                {
                  title: PROGRAMS_TITLE,
                  borderTopColor: PROGRAMS_BORDER_COLOR,
                  data: response.entity.campaignCount,
                  testId: CONFIGURATIONS_TEST_ID,
                },
                {
                  title: SPONSORS_TITLE,
                  borderTopColor: SPONSORS_BORDER_COLOR,
                  data: response.entity.promotionSponsorCount,
                  testId: PROGRAMS_TEST_ID,
                },
                {
                  title: CONFIGURATIONS_TITLE,
                  borderTopColor: CONFIGURATIONS_BORDER_COLOR,
                  data: response.entity.promotionConfigCount,
                  testId: SPONSORS_TEST_ID,
                },
              ]);
            })
            .catch(() => {
              const errorMessage = `There was an error fetching statistics for parent ${parentId}`;
              logger.error({
                message: errorMessage,
                data: { parentId },
              });
              toastr.error(errorMessage);
            });
        })
        .catch(() => {
          const errorMessage = `There was an error fetching root parent ID`;
          logger.error({
            message: errorMessage,
            data: { parentId },
          });
          toastr.error(errorMessage);
        });
    }
  }

  useEffect(() => {
    const parentIdForContext = getParentIdForContext({
      routeParams,
      childEntityPartyRoleName,
    });

    if (parentIdForContext) {
      getStatistics(parentIdForContext);
      getHierarchicalOrganization(parentIdForContext)
        .then((response) => {
          setParentOrganizationBasedOnChildType(response.entity);
        })
        .catch(() => {
          const errorMessage = `There was an error fetching the parent ${childEntityPartyRoleName} id`;
          logger.error({
            message: errorMessage,
            data: { parentId },
          });
          toastr.error(errorMessage);
        });
    } else {
      setParentOrganizationBasedOnChildType(null);
      getStatistics(parentOrgId);
    }
  }, [routeParams]);

  function getDefaultFilter(): GetAllHierarchicalOrganizationsForParentParams {
    return {
      limit: 20,
      parentName,
      parentOrgId,
      keyword: DEFAULT_SEARCH_VALUE,
      offset: 0,
      statuses: [],
    };
  }

  const [filter, setFilter] = React.useState<OrganizationListFilter>(getDefaultFilter);
  const [currentResultsFilter, setCurrentResultsFilter] = React.useState<OrganizationListFilter>(getDefaultFilter);

  useEffect(() => {
    setFilter({
      ...filter,
      parentName,
      parentOrgId,
    });
  }, [parentOrganizationBasedOnChildType]);

  function handleApplyFilter(params: Partial<OrganizationListFilter>): void {
    const { keyword, statuses } = params;
    setFilter({
      ...filter,
      keyword,
      statuses,
    });
    setIsFilterModalOpen(false);
  }

  const [isCreateOrEditModalOpen, setIsCreateOrEditModalOpen] = useState<boolean>(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  function handleEditOrganizationClick(newOrganizationBeingEdited: Organization) {
    setOrganizationBeingEdited(newOrganizationBeingEdited);
    setIsCreateOrEditModalOpen(true);
  }

  function getCreateOrUpdateOrganizationParams(
    isEditingOrganization: boolean,
    organizationForm: CreateOrUpdateOrganizationFormFields
  ): CreateOrUpdateOrganizationParams {
    const parentCode = getParentCode({
      parentOrganization: parentOrganizationBasedOnChildType,
      organizationBeingEdited,
    });
    const parentId = getParentId({
      parentOrganization: parentOrganizationBasedOnChildType,
      organizationBeingEdited,
    });

    if (isEditingOrganization) {
      return {
        ...organizationBeingEdited,
        ...organizationForm,
        parentId: parentOrgId,
      };
    }

    return {
      ...organizationForm,
      parentCode,
      partyRoles: [
        {
          roleName: childEntityPartyRoleName,
        },
      ],
      parentId: parentOrgId,
    };
  }

  async function handleSaveOrganization(organizationForm: CreateOrUpdateOrganizationFormFields) {
    setIsCreateOrEditModalOpen(false);

    const isEditingOrganization = !!organizationBeingEdited;

    const createOrUpdateOrganizationParams = getCreateOrUpdateOrganizationParams(
      isEditingOrganization,
      organizationForm
    );

    try {
      await createOrUpdateOrganization(createOrUpdateOrganizationParams);
      await handleSearch(false);
      const createdOrUpdatedVerb = isEditingOrganization ? "updated" : "created";
      toastr.success(`${childEntityPartyRoleName} ${createdOrUpdatedVerb} successfully`);
    } catch (error) {
      const creatingOrUpdatingVerb = isEditingOrganization ? "updating" : "creating";
      logger.error({
        message: error.error.error, //`An error occurred ${creatingOrUpdatingVerb} an ${childEntityPartyRoleName}`,
        data: {
          createOrUpdateOrganizationParams,
          error,
        },
      });
      toastr.error(error.error.error);
    }
  }

  /*
   This entire function could be more generic and reused. If copy/pasting this please consider refactoring it
   instead.
  */
  const handleSearch = useDebouncedCallback(async (concatResults?: boolean): Promise<void> => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    const offset = concatResults ? nextOffset : 0;
    const requestTimestamp = Date.now();
    lastRequestTimestamp = requestTimestamp;

    const filterQueryParams = utilityService.removeNullAndEmptyProperties({
      ...filter,
      offset,
    });

    try {
      const response = await getAllHierarchicalOrganizationsForParent(filterQueryParams);

      if (requestTimestamp !== lastRequestTimestamp) {
        // Ignore requests that were not the last request
        return;
      }

      let newOrganizations: Organization[] = response.entity;
      setCanLoadMore(newOrganizations?.length === filter.limit);
      if (concatResults) {
        newOrganizations = organizationList.concat(newOrganizations);
      }
      setOrganizationList(newOrganizations);
      setNextOffset(offset + newOrganizations?.length);
      setLoading(false);
      setCurrentResultsFilter({
        ...filter,
        offset: nextOffset,
      });
    } catch (error) {
      logger.error({
        message: `An error occurred while fetching the ${childEntityPartyRoleName} list`,
        data: {
          filterQueryParams,
          error,
        },
      });
    }
  }, 500);

  function handleKeywordChange(newSearchValue): void {
    setFilter({
      ...filter,
      keyword: newSearchValue,
    });
  }

  function handleClearFilters() {
    setFilter(getDefaultFilter());
  }

  useEffect(() => {
    void handleSearch(false);
  }, [filter]);

  function handleAddOrganizationClick() {
    setIsCreateOrEditModalOpen(true);
    setOrganizationBeingEdited(undefined);
  }

  const hasNoResults = !organizationList || (!isLoading && organizationList.length === 0);

  const [infiniteScrollRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: canLoadMore,
    onLoadMore: () => handleSearch(true),
  });

  function handleCreatedOrEditModalClose() {
    setIsCreateOrEditModalOpen(false);
    setOrganizationBeingEdited(null);
  }

  function handleParentOrganizationSelect(organization: Organization) {
    setParentOrganizationBasedOnChildType(null);
    navigateToOrganizationBasedOnChildType({
      // This is the organization that we are routing to
      organizationIdToRouteTo: organization.id,
      navigateMethod: navigate,
      marketId,
      lineOfBusinessId,
      clientId,
      parentId,
      childEntityPartyRoleName,
    });
    getStatistics(organization.id);
  }

  return (
    <OrganizationsView
      childEntityLabel={childEntityPartyRoleName}
      onClearFilters={handleClearFilters}
      organizationBeingEdited={organizationBeingEdited}
      setOrganizationPath={setOrganizationPath}
      organizationStatistics={organizationStatistics}
      organizationPath={organizationPath}
      filter={filter}
      isFilterModalOpen={isFilterModalOpen}
      onFilterOpenModalClose={() => setIsFilterModalOpen(false)}
      isCreateOrEditModalOpen={isCreateOrEditModalOpen}
      isLoading={isLoading}
      parentOrganization={parentOrganizationBasedOnChildType}
      organizationList={organizationList}
      hasNoResults={hasNoResults}
      canLoadMore={canLoadMore}
      infiniteScrollRef={infiniteScrollRef}
      onCreatedOrEditModalClose={handleCreatedOrEditModalClose}
      onEditOrganization={handleEditOrganizationClick}
      onAddOrganization={handleAddOrganizationClick}
      onParentOrganizationSelect={handleParentOrganizationSelect}
      onSearchChange={handleKeywordChange}
      onSaveOrganization={handleSaveOrganization}
      onFilterOpen={() => setIsFilterModalOpen(true)}
      applyFilter={handleApplyFilter}
    />
  );
}
