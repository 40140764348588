<div class="wrapper wrapper-content">
  <div class="row">
    <div class="content-container col-md-4" *ngFor="let report of reports">
      <div class="row">
        <div class="ibox float-e-margins w-100">
          <div class="col-md-12">
            <div class="ibox-title" [ngClass]="report.color + '-top'">
              <a (click)="toggleMe($event, report.name)">
                <fa-icon class="float-right blue-icon-color" [icon]="reportCardExpanded ? faCompressAlt : faExpandAlt">
                </fa-icon>
              </a>
              <h5>{{ report.name }}</h5>
            </div>
            <div class="ibox-content" [ngClass]="report.color + '-bottom'">
              <div [hidden]="!views[report.name]">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <button
                        type="button"
                        class="btn btn-sm btn-primary float-right"
                        (click)="applyMe(report, $event)"
                      >
                        <fa-icon [icon]="faSpinner" *ngIf="report.isLoading"></fa-icon>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mb-lg-0">
                  <div class="col-lg-2 col-md-4" *ngFor="let param of report.clientParams">
                    <!-- INPUT -->
                    <div class="form-group" *ngIf="isInput(param.paramType) && !param.autocompleteAction">
                      <label>{{ param.paramName }}</label>
                      <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="inputData[param.paramCode]"
                        [disabled]="report.isLoading"
                      />
                    </div>
                    <!-- AUTOCOMPLETE -->
                    <div
                      class="form-group"
                      *ngIf="
                        param.autocompleteAction && (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR')
                      "
                    >
                      <label>{{ param.paramName }}</label>
                      <div class="form-group">
                        <select
                          class="form-control"
                          [(ngModel)]="autocompleteData[param.paramCode + '#' + report.id + '#']"
                        >
                          <option *ngFor="let item of autocomplete[param.paramCode]" value="{{ item.name }}">
                            {{ item.name }}:
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="param.autocompleteAction && param.paramType === 'ARRAY'">
                      <ng-select
                        [(ngModel)]="autocompleteDataArray[param.paramCode + '#' + report.id + '#']"
                        [items]="autocompleteArray[param.paramCode]"
                        bindLabel="text"
                        [multiple]="true"
                        [hideSelected]="true"
                        [closeOnSelect]="false"
                      >
                      </ng-select>
                    </div>
                    <!-- DATE PICKER -->
                    <div class="form-group" *ngIf="param.paramType === 'TIMESTAMP'">
                      <label>{{ param.paramName }}</label>
                      <div class="input-group date">
                        <input
                          class="form-control cursor-pointer"
                          readonly
                          (click)="date.toggle()"
                          placeholder="mm/dd/yyyy"
                          name="date"
                          ngbDatepicker
                          #date="ngbDatepicker"
                          [(ngModel)]="dateData[param.paramCode + '#' + report.id + '#']"
                          [footerTemplate]="footerTemplateStartDate"
                        />
                        <ng-template #footerTemplateStartDate>
                          <div class="width-100 datePickerTemplate">
                            <button
                              class="buttonDateTemplate"
                              (click)="clearDateInput(param.paramCode + '#' + report.id + '#'); date.toggle()"
                            >
                              Clear
                            </button>
                          </div>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="date.toggle()" type="button">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chart-wrapper" *ngIf="report.chartData && report.chartData.length > 0">
                  <ng-container *ngIf="getReportChartType(report) === 'pie'">
                    <canvas
                      baseChart
                      [data]="report.chartData"
                      [labels]="report.chartLabels"
                      [options]="options"
                      [legend]="true"
                      [chartType]="getReportChartType(report)"
                    >
                    </canvas>
                  </ng-container>
                  <ng-container *ngIf="getReportChartType(report) !== 'pie'">
                    <canvas
                      baseChart
                      [datasets]="report.chartDataSets"
                      [labels]="report.chartLabels"
                      [options]="options"
                      [legend]="true"
                      [chartType]="getReportChartType(report)"
                    >
                    </canvas>
                  </ng-container>
                </div>
              </div>
              <div [hidden]="!!views[report.name]">
                <div class="row">
                  <div class="col-md-8">
                    <ol>
                      <ng-container
                        *ngIf="
                          getReportChartType(report) === 'pie' &&
                          report.chartLabels &&
                          report.chartLabels.length > 0 &&
                          report.chartData &&
                          report.chartData.length > 0
                        "
                      >
                        <li *ngFor="let i of [0, 1, 2, 3, 4]">
                          {{ report.chartLabels[i] }}: {{ report.chartData[i] }}
                        </li>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          getReportChartType(report) !== 'pie' &&
                          report.chartLabels &&
                          report.chartLabels.length > 0 &&
                          report.chartData[0] &&
                          report.chartData[0].length > 0
                        "
                      >
                        <li *ngFor="let i of [0, 1, 2, 3, 4]">
                          {{ report.chartLabels[i] }}: {{ report.chartData[0][i] }}
                        </li>
                      </ng-container>
                    </ol>
                  </div>
                  <div class="col-md-4 program-details-icon">
                    <span class="fa-stack fa-5x end-5">
                      <ng-container *ngIf="getReportChartType(report) === 'pie'">
                        <fa-layers [fixedWidth]="true">
                          <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
                          <fa-icon [inverse]="true" [icon]="faChartPie" transform="shrink-8" stackItemSize="2x">
                          </fa-icon>
                        </fa-layers>
                      </ng-container>
                      <ng-container *ngIf="getReportChartType(report) === 'bar'">
                        <fa-layers [fixedWidth]="true">
                          <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
                          <fa-icon [inverse]="true" [icon]="faChartBar" transform="shrink-8" stackItemSize="2x">
                          </fa-icon>
                        </fa-layers>
                      </ng-container>
                      <ng-container *ngIf="getReportChartType(report) === 'line'">
                        <fa-layers [fixedWidth]="true">
                          <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
                          <fa-icon [inverse]="true" [icon]="faChartLine" transform="shrink-8" stackItemSize="2x">
                          </fa-icon>
                        </fa-layers>
                      </ng-container>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
