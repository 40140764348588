<div class="full-height-scroll">
  <div class="filters_container2">
    <div class="right_container3">
      <button class="filter-btn" (click)="FilterModel()">FILTERS<mat-icon class="ml-2">filter_list</mat-icon></button>
      <button class="btn-add" style="height: 36px" (click)="addContentGroup()">ADD Content Group</button>
    </div>
    <app-filter-tags
      *ngIf="searchFilters"
      isPopup="true"
      [(filterButton)]="filterButton"
      [(searchFilters)]="searchFilters"
      [(appliedFilters)]="appliedFilters"
    ></app-filter-tags>
  </div>
  <div class="table-communication-strategy">
    <table class="table table-pointable table-bordered">
      <thead>
        <tr>
          <th class="table_field_name">Name</th>
          <th class="table_field_name">Delivery Protocol</th>
          <th class="table_field_name">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contentGroup of contentGroups">
          <td>
            <a routerLink="/outreach-content/{{ contentGroup.id }}" [queryParams]="{ name: contentGroup.name }">{{
              contentGroup.name
            }}</a>
          </td>
          <td>
            {{ contentGroup.deliveryProtocolName }}
          </td>
          <td class="table-buttons">
            <a class="btn btn-sm float-left btn-primary" (click)="addContentGroup(contentGroup)" title="View">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </a>
            <button
              [disabled]="contentGroup.inUse"
              class="btn btn-sm float-left btn-attention"
              (click)="deleteContentGroup(contentGroup)"
              title="{{ contentGroup.inUse ? 'Can not delete content group which has communication' : 'Delete' }}"
            >
              <fa-icon [icon]="faTrash"></fa-icon>
            </button>
          </td>
        </tr>
        <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent"></tr>
      </tbody>
    </table>
  </div>
  <app-add-more-data-to-list (onScroll)="getContentGroups(true)" [lock]="lockedTables" [isLoading]="isLoading">
  </app-add-more-data-to-list>
</div>
