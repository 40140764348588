import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getJobs(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'jobs', this.setParams(params));
  }

  getJob(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'jobs/' + id);
  }

  deleteJob(id: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'jobs/' + id);
  }

  createOdUpdateRewardType(id: number, data: any): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'jobs/' + id, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'jobs', data);
  }
}
