import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faPencilAlt, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { ContactReason } from 'src/app/data/model';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ContactReasonService } from 'src/app/services/contact-reason.service';
import { ContactReasonModalComponent } from '../contact-reason-modal/contact-reason-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-reasons-tab',
  templateUrl: './contact-reasons-tab.component.html',
  styleUrls: ['./contact-reasons-tab.component.scss'],
})
export class ContactReasonsTabComponent implements OnInit {
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  statuses: Choice[] = [];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  show: boolean;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  contactReasons: any[] = [];
  promotions: any[] = [];
  selectedContactReason: any;
  lockedTables = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  filteredStatuses: Choice[] = [];
  disabled: boolean;
  faTimes = faTimes;
  searchFilters: SearchFilters;

  constructor(
    private tableHelperService: TableHelperService,
    private responseHelper: ResponseHelperService,
    private contactReasonService: ContactReasonService,
    private modalHelper: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'contact-reasons-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'keyword',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Contact Reason',
          if: true,
          callback: () => {
            this.contactReasonModal();
          },
          title: 'New Contact Reason',
        },
      ],
    };
    this.show = true;
    this.statuses = this.tableHelperService.getStatuses('CONTACT_REASONS');
  }

  initNewSearch(): void {
    this.initFilter();
    this.getContactReasons(false);
  }

  getContactReasons(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.contactReasonService.getContactReasons(this.filter).subscribe(
      (data) => {
        if (concat) {
          this.contactReasons = this.contactReasons.concat(data.entity);
        } else {
          this.contactReasons = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
  }

  deleteContactReason(contactReason: ContactReason): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete contact reason with ID: ' + contactReason.id;
    instance.componentInstance.header = 'Delete contact reason?';
    instance.componentInstance.successEvent.subscribe(() => {
      contactReason.status = 'DELETED';
      this.contactReasonService.createOrUpdateContactReason(contactReason.id, contactReason).subscribe((data: any) => {
        if (data.success) {
          this.responseHelper.success('Contact reason successfully removed', true);
        }
      });
    });
  }

  contactReasonModal(contactReasonId?: number): void {
    if (contactReasonId) {
      this.contactReasonService.getContactReason(contactReasonId).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(contactReason?: ContactReason): void {
    const instance = this.modalHelper.open(ContactReasonModalComponent);
    instance.componentInstance.contactReason = contactReason;
    instance.componentInstance.statuses = this.statuses;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data.success) {
        this.responseHelper.success(
          `Contact reason successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`,
          true
        );
        this.initNewSearch();
      }
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getContactReasons(false);
  }
}
