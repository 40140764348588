import React, { Ref } from "react";
import { Organization, EntityStatus } from "../../../data/model";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import EditOrganizationButton, { editOrganizationTestId } from "../EditOrganizationButton";
import Link from "../Link";
import TableHead from "../TableHead";
import moment from "moment";

export type OrganizationsTableProps = {
  isLoading: boolean;
  organizationsList: Organization[];
  selectedOrganization: Organization;
  onOrganizationSelect: (organization: Organization) => void;
  onEditOrganization: (organization: Organization) => void;
  infiniteScrollRef: Ref<any>;
  canLoadMore: boolean;
  setOrganizationPath: (path: string) => void;
  organizationPath: string;
  entityTypeName: string;
};

export function OrganizationsTable({
  organizationsList,
  selectedOrganization,
  onOrganizationSelect,
  onEditOrganization,
  isLoading,
  canLoadMore,
  infiniteScrollRef,
  setOrganizationPath,
  organizationPath,
  entityTypeName,
}: OrganizationsTableProps): JSX.Element {
  const hasNoResults = !organizationsList || (!isLoading && organizationsList.length === 0);

  return (
    <TableContainer
      sx={{
        fontSize: "13px !important",
        overflowX: "hidden !important",
      }}
    >
      <Table
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
        aria-label="organizations table"
        size="medium"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">
              {entityTypeName === undefined ? "" : entityTypeName === "parent" ? "Parent" : entityTypeName} name
            </TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Date edited</TableCell>
            <TableCell align="center">Action</TableCell>
            {entityTypeName === 'Market' && (
              <TableCell align="center">Logo</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {organizationsList &&
            organizationsList?.map((org) => {
              const updatedOnDateString = org.updatedOn ? moment(new Date(org.updatedOn)).format("YYYY-MM-DD") : "";
              return (
                <TableRow
                  hover
                  sx={{ cursor: "pointer" }}
                  selected={org.id === selectedOrganization?.id}
                  key={org.name}
                >
                  <TableCell align="left">
                    <Link onClick={() => onOrganizationSelect(org)}>{org.name}</Link>
                  </TableCell>
                  <TableCell align="center">{org.status as EntityStatus}</TableCell>
                  <TableCell align="center">{updatedOnDateString}</TableCell>
                  <TableCell align="center">
                    <EditOrganizationButton
                      onClick={() => onEditOrganization(org)}
                      testId={editOrganizationTestId(org.id)}
                    />
                  </TableCell>
                  {entityTypeName === 'Market' && (
                    <TableCell align="center">
                      {org.logoUrl && (
                        <img src={org.logoUrl} height="30px"/>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          {hasNoResults && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No results to display
              </TableCell>
            </TableRow>
          )}
          {!hasNoResults && !canLoadMore && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No more results
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter ref={infiniteScrollRef} />
      </Table>
    </TableContainer>
  );
}
