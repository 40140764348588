<div class="panel-body">
  <div class="row" *ngIf="!users">
    <div class="col-md-12">
      <div class="checkbox checkbox-info pull-left">
        <mat-checkbox name="showExpired" [(ngModel)]="showExpired">Display Expired Preferences</mat-checkbox>
      </div>
      <button
        type="button"
        class="btn btn-sm btn-info float-right"
        (click)="communicationsModal()"
        *ngIf="isCSRManager"
      >
        <fa-icon [icon]="faUserCheck"></fa-icon> New Preference
      </button>
    </div>
  </div>
  <div class="row" *ngIf="!users">
    <div class="col-md-12 text-center" *ngIf="preferedCommunications.length < 1">
      No preferred communications to show
    </div>
    <ng-container *ngFor="let comm of preferedCommunications">
      <div
        class="col-md-4"
        *ngIf="
          !comm.endDate ||
          comm.endDate === null ||
          comm.endDate === '' ||
          currentDate < (comm.endDate | date: 'yyyy-MM-dd') ||
          showExpired
        "
      >
        <div class="widget lazur-bg">
          <ul class="list-unstyled mt-3">
            <li>
              <span class="float-right" *ngIf="comm.communication?.name === 'DEFAULT'">
                <fa-icon [icon]="faStar"></fa-icon>Default
              </span>
            </li>
            <li><label>ID:</label> {{ comm.id }}</li>
            <li><label>Communication:</label> {{ comm.communication?.name }}</li>
            <li><label>Endpoint Type Name:</label> {{ comm.endpointType }}</li>
            <li *ngIf="comm.partyEmailDTO?.emailAddress"><label>Email:</label> {{ getPrefData(comm, "email") }}</li>
            <li *ngIf="comm.phone?.phoneNumber"><label>Phone:</label> {{ getPrefData(comm, "phone") }}</li>
            <li *ngIf="comm.jSONAddressDTO?.city"><label>Address:</label> {{ getPrefData(comm, "address") }}</li>
            <li><label>Opt out:</label> {{ comm.optOut }}</li>
            <li><label>Start Date:</label> {{ comm.startDate | date: "yyyy-MM-dd" }}</li>
            <li><label>End Date:</label> {{ comm.endDate | date: "yyyy-MM-dd" }}</li>
            <li><label>Created At:</label> {{ comm.createdAt | date: "yyyy-MM-dd" }}</li>
          </ul>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group float-right">
                <button
                  type="button"
                  *ngIf="
                    isCSRManager &&
                    (!comm.endDate ||
                      comm.endDate === null ||
                      comm.endDate === '' ||
                      currentDate < (comm.endDate | date: 'yyyy-MM-dd'))
                  "
                  class="btn btn-warning btn-sm"
                  (click)="communicationsModal(comm)"
                >
                  <fa-icon [icon]="faEdit"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<div class="panel-body mt-2">
  <div class="row">
    <div class="col-md-12">
      <h3>Communication History</h3>
    </div>
  </div>
  <div class="row" *ngIf="show">
    <div class="col-md-12">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row"></div>
      <div class="row mt-3">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Comm ID</th>
              <th>Trace ID</th>
              <th>Comm Name</th>
              <th>Comm Subject</th>
              <th>Comm Date</th>
              <th>Delivery Protocol</th>
              <th>Is Delivered</th>
              <th>Processing Status</th>
              <th width="3%"></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let c of commHistory; let i = index">
              <tr (click)="expandedLookup[i] = !expandedLookup[i]">
                <td>{{ c.id }}</td>
                <td>
                  <a href="/communications/{{ c.communicationId }}">{{ c.communicationId }}</a>
                </td>
                <td>{{ c.traceId }}</td>
                <td>{{ c.communicationName }}</td>
                <td>{{ c.communicationSubject }}</td>
                <td>{{ c.communicationTimestamp }}</td>
                <td>{{ c.deliveryProtocol }}</td>
                <td>{{ c.delivered }}</td>
                <td>{{ c.processingStatus }}</td>
                <td>
                  <span *ngIf="!expandedLookup[i]" class="width-50 display-inline-block" title="Expand to see content">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                  </span>
                  <span *ngIf="expandedLookup[i]" class="width-50 display-inline-block" title="Expand to see history">
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                  </span>
                </td>
              </tr>
              <tr class="additional-data" *ngIf="expandedLookup[i]">
                <td colspan="9">
                  <table class="reward-log table table-pointable additional">
                    <tbody>
                      <tr class="align-text-left">
                        <td><b>Content:</b>{{ c.content }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </tbody>
          <tr app-is-there-more [numColumns]="9" [newLengthEvent]="newLengthEvent"></tr>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getCommunicationsHistory(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
