import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit {

  activeId: number = 1;
  tabs: any[] = [
    { title: 'Program', active: true },
    { title: 'Campaign', active: false },
    { title: 'Promotion Type', active: false },
    { title: 'Promotion Category', active: false },
    { title: 'Packages', active: false },
    { title: 'Communication Strategy', active: false },
  ];
  filterFunctions: any;

  constructor(private applyfilterService: ApplyFilterService, private title: Title) {}
  tabClick(tab) {
    this.applyfilterService.filterfun.subscribe((data) => {
      this.filterFunctions = data;
    });
    if (this.filterFunctions) {
      this.filterFunctions.clearAll();
    }
    this.applyfilterService.getFilterData([]);
  }

  ngOnInit(): void {
    this.title.setTitle('Products');
  }
}
