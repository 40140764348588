import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { faCog, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommunicationStrategyConfigurationModalComponent } from 'src/app/communication-strategy-configuration-modal/communication-strategy-configuration-modal.component';
import { CommunicationStrategyModalComponent } from 'src/app/communication-strategy-modal/communication-strategy-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { CommunicationStrategy, CommunicationStrategyConfiguration } from 'src/app/data/model';
import { CommunicationStrategyService } from 'src/app/services/communications-strategy.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { faChevronRight, faChevronDown, faPhoneAlt, faAt, faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-communication-strategy-tab',
  templateUrl: './communication-strategy-tab.component.html',
  styleUrls: ['./communication-strategy-tab.component.scss'],
})
export class CommunicationStrategyTabComponent implements OnInit, OnDestroy {
  offset = new Date().getTimezoneOffset();
  show: boolean;
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faCog = faCog;
  faChevronRight = faChevronRight;
  faAt = faAt;
  faPhoneAlt = faPhoneAlt;
  faHome = faHome;
  faChevronDown = faChevronDown;
  filteredStatuses: Filter[] = [];
  communicationStrategies: any[] = [];
  showMoreButton: any = { name: 'Show More', active: true };
  fileNamePlease: any = { name: '', success: false };
  lockedTables: boolean;
  configurations: CommunicationStrategyConfiguration[] = [];
  endpointTypes: any[];
  rawViewData;
  comm: any[];
  appliedFilters: Filter[];
  filterButton: boolean = false;
  filterdata: SearchFilters;
  helpers: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  selectedId: number;
  Strategy: false;
  nametag: string;
  filters: any[] = [];
  showParams: boolean[] = [];
  selectedEndpoint: any;
  callOnce: boolean = true;
  constructor(
    private tableHelperService: TableHelperService,
    private toastrService: ToastrService,
    private communicationStrategyService: CommunicationStrategyService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService,
    private utiltyService: UtilityService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.show = true;
    this.searchFilters = {
      formName: 'communicationStrategySearch',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Add keywords',
          label: 'Keywords',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Communication Strategy',
          if: true,
          callback: () => {
            this.communicationStrategyModal();
          },
          title: 'New Communication Strategy',
        },
      ],
    };
    this.initNewSearch();
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getCommunicationStrategy(false);
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getCommunicationStrategy(false);
    this.configurations = null;
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getCommunicationStrategy(false);
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };
  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Strategy';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  showRawData(id: string): void {
    if (this.rawViewData === id) {
      this.rawViewData = '';
    } else {
      this.rawViewData = id;
    }
  }

  getCommunicationStrategy(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.communicationStrategyService.getAllCommunicationStrategy(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.communicationStrategies = this.communicationStrategies.concat(data.entity.aaData);
        } else {
          this.communicationStrategies = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.searchFilters.filters[1].value = this.tableHelperService.extractData(this.filteredStatuses);
  }

  deleteCommunicationStrategy(cs: any): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm', windowClass: 'delete-model' });
    instance.componentInstance.text = 'Delete communication strategy with ID: ' + cs.id;
    instance.componentInstance.header = 'Strategy';
    instance.componentInstance.Strategy = !this.Strategy;
    instance.componentInstance.nametag = cs.name;
    instance.componentInstance.successEvent.subscribe(() => {
      this.communicationStrategyService.deleteCommunicationStrategy(cs.id).subscribe(
        () => {
          this.toastrService.success('Communication strategy removed');
          this.initFilter();
          this.getCommunicationStrategy(false);
          instance.close();
        },
        (data: any) => {
          this.toastr.error(data.error.error);
          instance.close();
        }
      );
    });
  }

  communicationStrategyModal(communicationStrategy?: CommunicationStrategy): void {
    const instance = this.modalHelper.open(CommunicationStrategyModalComponent);
    instance.componentInstance.communicationStrategy = communicationStrategy;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.toastrService.success(
          `Communication strategy successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`
        );
        this.initNewSearch();
        this.selectedId = null;
        instance.close();
      }
    });
  }

  selectCommStrategy(event, id: number): void {
    if (this.selectedId === id) {
      this.selectedId = null;
    } else {
      this.communicationStrategyService.getCommStrategyConfigurations(id).subscribe((configurations: any) => {
        this.configurations = configurations.entity;
        this.selectedId = id;
      });
    }
  }

  addConfig(id: number): void {
    const instance = this.modalHelper.open(CommunicationStrategyConfigurationModalComponent);
    instance.componentInstance.commStrategyId = id;
    instance.componentInstance.successEvent.subscribe((successEvent: string) => {
      if (successEvent) {
        this.toastrService.success('Configuration successfully created');
        this.selectCommStrategy(null, id);
        instance.close();
      }
    });
  }

  deleteConfiguration(id: number, index: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete configuration with ID: ' + id;
    instance.componentInstance.header = 'Delete configuration?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.communicationStrategyService.deleteCommStrategyConfiguration(id).subscribe(
        () => {
          this.toastrService.success('Configuration successfully removed');
          this.configurations.splice(index, 1);
          instance.close();
        },
        (data: any) => {
          this.toastr.error(data.error.error);
          instance.close();
        }
      );
    });
  }

  editConfigurationModal(communicationStrategyConfiguration: CommunicationStrategyConfiguration): void {
    this.communicationStrategyService
      .getCommStrategyConfiguration(communicationStrategyConfiguration.id)
      .subscribe((config: any) => {
        if (config.success) {
          const instance = this.modalHelper.open(CommunicationStrategyConfigurationModalComponent);
          instance.componentInstance.communicationStrategyConfiguration = config.entity;
          instance.componentInstance.commStrategyId = communicationStrategyConfiguration.commStrategyId;
          instance.componentInstance.successEvent.subscribe((response: any) => {
            if (response) {
              this.toastrService.success('Configuration successfully updated');
              this.selectCommStrategy(null, communicationStrategyConfiguration.commStrategyId);
              instance.close();
            }
          });
        }
      });
  }

  createDateFromTime(inputTime: string): Date {
    if (inputTime) {
      const hour = inputTime.split(':')[0];
      const min = inputTime.split(':')[1];
      const date = new Date();
      date.setHours(Number(hour));
      date.setMinutes(Number(min));
      date.setSeconds(0);
      return date;
    }
    return undefined;
  }

  unpackCommStrategyConfig(commStrategyConfig): any | undefined {
    if (commStrategyConfig) {
      const unpacked = Object.assign({}, commStrategyConfig);
      if (commStrategyConfig.blackOutPeriods) {
        unpacked.blackOutPeriods = commStrategyConfig.blackOutPeriods.split(',');
      }
      if (commStrategyConfig.toTime) {
        unpacked.toTime = this.createDateFromTime(commStrategyConfig.toTime);
      }
      if (commStrategyConfig.fromTime) {
        unpacked.fromTime = this.createDateFromTime(commStrategyConfig.fromTime);
      }
      return unpacked;
    }
    return undefined;
  }
}
