<div>
  <form>
    <label>Member completes a behavior</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="behaviors !== null">
        <div *ngFor="let behavior of behaviors; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeBehavior(behavior)"
            title="Remove Behavior"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group pt-4">
              <label>Behavior Codes</label>
              <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" *ngIf="behavior.behaviorCodes">
                <ngb-panel *ngFor="let behaviorCode of behavior.behaviorCodes; let in = index">
                  <ng-template ngbPanelHeader>
                    Behavior Code {{ behaviorCode ? "- " + behaviorCode : "" }}
                    <button
                      ngbPanelToggle
                      class="btn btn-dark-blue float-right"
                      (click)="editBehaviorCode(i)"
                      title="Edit"
                    >
                      <fa-icon [icon]="i === openFieldChevronList.behaviorCodeIndex ? faChevronUp : faChevronDown">
                      </fa-icon>
                    </button>
                    <button class="btn btn-attention float-right" (click)="removeBehaviorCode(in, i)" title="Remove">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <h2>
                      {{ behaviorCode }}
                    </h2>
                    <app-behavior-code-form
                      [index]="in"
                      [behaviorCodeFormModel]="behaviorCode"
                      (behaviorCodeEvent)="handleBehaviorCodeEvent($event, i)"
                    >
                    </app-behavior-code-form>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <app-behavior-code-form
                [index]="null"
                [behaviorCodeFormModel]="behaviorCode"
                (behaviorCodeEvent)="handleBehaviorCodeEvent($event, i)"
              >
              </app-behavior-code-form>
              <div class="mt-sm" [hidden]="behavior.behaviorCodes?.length > 0">
                <small class="text-danger">Add Behavior Code</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="periodCount_{{ i }}">In the past:</label>
              <input
                class="form-control m-b"
                type="number"
                id="periodCount_{{ i }}"
                name="value"
                [(ngModel)]="behavior.periodCount"
                (ngModelChange)="updateBehaviors()"
                (keypress)="keyPressNumbers($event)"
              />
            </div>
            <div class="form-group">
              <select
                class="form-control"
                name="period{{ i }}"
                [(ngModel)]="behavior.period"
                (ngModelChange)="updateBehaviors()"
              >
                <option *ngFor="let unit of units" [selected]="behavior.period === unit.value" [value]="unit.value">
                  {{ unit.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="behavior.message"
                name="message"
                (ngModelChange)="updateBehaviors()"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initBehaviorObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Behavior Condition
        </button>
      </div>
    </div>
  </form>
</div>
