import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import Link from "@iridium/iridium-ui/Link";
import { LiveAgentSurvey } from "src/app/data/model";

export type LiveAgentTableProps = {
  liveAgentSurveyList: LiveAgentSurvey[];
  isLoading: boolean;
  canLoadMore: boolean;
  organizationPath: string;
  entityTypeName: string;
  isLiveAgentManager: boolean;
  infiniteScrollRef: React.Ref<any>;
};

function getFooterText(liveAgentSurveyList: LiveAgentSurvey[], isLoading: boolean, canLoadMore: boolean): string {
  if (isLoading || canLoadMore) {
    return "Loading...";
  }
  const hasNoResults = !liveAgentSurveyList || (!isLoading && liveAgentSurveyList.length === 0);
  return hasNoResults ? "No results to display" : "No further results";
}

function updateDateFormat(date) {
  const d = new Date(date);
  return d.toLocaleDateString();
}

export function LiveAgentTable({
  liveAgentSurveyList,
  isLoading,
  canLoadMore,
  entityTypeName,
  isLiveAgentManager,
  infiniteScrollRef,
}: LiveAgentTableProps): JSX.Element {
  return (
    <TableContainer
      sx={{
        fontSize: "13px !important",
        overflowX: "hidden !important",
        mt: 2,
        height: "70vh",
      }}
    >
      <Table
        style={{ width: "100%" }}
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
        aria-label="organizations table"
        size="medium"
      >
        <TableHead sx={{ backgroundColor: (theme) => theme.palette.grey["100"] }}>
          <TableRow>
            <TableCell align="left">{entityTypeName} First name</TableCell>
            <TableCell align="center">Last name</TableCell>
            <TableCell align="center">Assessment date</TableCell>
            <TableCell align="center">Identifier</TableCell>
            <TableCell align="center">Survey results</TableCell>
            {isLiveAgentManager ? <TableCell align="center">Completed by</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {liveAgentSurveyList.map((liveAgentDataRow, index) => (
            <TableRow
              key={
                liveAgentDataRow.completedByFirstName +
                liveAgentDataRow.memberLastName +
                liveAgentDataRow.submittedAt +
                index
              }
              hover
              sx={{ cursor: "pointer" }}
            >
              <TableCell align="left">
                <Link href={"/members/" + liveAgentDataRow.memberId}>{liveAgentDataRow.memberFirstName}</Link>
              </TableCell>
              <TableCell align="center">{liveAgentDataRow.memberLastName}</TableCell>
              <TableCell align="center">{liveAgentDataRow.submittedAt}</TableCell>
              <TableCell align="center">{liveAgentDataRow.primaryInstrumentNumber}</TableCell>
              <TableCell align="center">{liveAgentDataRow.surveyStatus}</TableCell>
              {isLiveAgentManager ? (
                <TableCell align="center">
                  {liveAgentDataRow.completedByFirstName} {liveAgentDataRow.completedByLastName}
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
        <TableFooter ref={infiniteScrollRef}>
          <TableRow>
            <TableCell
              colSpan={isLiveAgentManager ? 6 : 5}
              align="center"
              variant="footer"
              style={{ borderLeft: "none", borderBottom: "none", borderRight: "none" }}
            >
              {getFooterText(liveAgentSurveyList, isLoading, canLoadMore)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
