import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';
import { getDisplayText } from '../member/member-tabs/instrument-modal/instrument-modal.util';

@Injectable({
  providedIn: 'root',
})
export class InstrumentsService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getPartyInstruments(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'instruments/party/' + id);
  }

  deleteInstrument(id: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'instruments/' + id);
  }

  getInstrument(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'instruments/' + id);
  }

  getPrimaryInstrumentForParty(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'instruments/party/' + partyId + '/primary');
  }

  createOrUpdateInstrument(id: number, data: any): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'instruments/' + id, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'instruments', data);
  }

  getDisplayText(displayMask: string, instrument: string): string {
    return getDisplayText(displayMask, instrument);
  }
}
