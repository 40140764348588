<div>
  <div class="ibox-content xml-preview" *ngIf="configType === 'promoSponsor'">
    <h5>This configuration triggers:</h5>
    <div class="row">
      <div class="cheetah-msgs" [innerHTML]="triggeredConfigs"></div>
    </div>
    <h5>This configuration is triggered by:</h5>
    <div class="row">
      <div class="cheetah-msgs" [innerHTML]="triggeringConfigs"></div>
    </div>
    <h5>This configuration is a part of thresholds in:</h5>
    <div class="row">
      <div class="cheetah-msgs" [innerHTML]="thresholdRelatedConfigs"></div>
    </div>
    <h5>This configuration is a part of caps in:</h5>
    <div class="row">
      <div class="cheetah-msgs" [innerHTML]="capRelatedConfigs"></div>
    </div>
    <h5>This configuration is a gatekeeper for:</h5>
    <div class="row">
      <div class="cheetah-msgs" [innerHTML]="gatekeeperConfigs"></div>
    </div>
    <div *ngIf="templateId">
      <h5>The template for this configuration is:</h5>
      <div class="row">
        <div>
          <a
            href="/programs/{{ promotion.programId }}/promotions/{{ promotion.id }}/promotion-template/{{ templateId }}"
            class="btn btn-dark-blue btn-sm float-right"
            title="Promotion Template"
            >Go to Template</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="ibox-content xml-preview" *ngIf="configType === 'template'">
    <h5>This template is being used in the following configurations:</h5>
    <div class="row">
      <div class="cheetah-msgs" [innerHTML]="templateSponsors"></div>
    </div>
  </div>
</div>
