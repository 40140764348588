import React from "react";
import Grid from "@iridium/iridium-ui/Grid";
import Typography from "@iridium/iridium-ui/Typography";

export interface DataCard {
  title: string;
  borderTopColor: string;
  data: number | undefined;
  testId: string;
}

export default function DataCard({ title, borderTopColor, data = 0, testId }: DataCard): JSX.Element {
  return (
    <Grid
      data-testid={testId}
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: "#E0E0E0",
        borderRadius: "0 0 4px 4px",
        width: "30%",
        maxWidth: "375px",
      }}
    >
      <Grid
        item
        sx={{
          width: "100%",
          height: "24px",
          backgroundColor: borderTopColor,
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ padding: "4px", fontWeight: "bold", color: "#FFF" }}>{title}</Typography>
      </Grid>
      <Grid item sx={{ height: "60px" }}>
        <Typography sx={{ fontWeight: "medium", fontSize: "24px", marginTop: "9px" }}>{data}</Typography>
      </Grid>
    </Grid>
  );
}
