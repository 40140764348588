import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { Filter, SearchFilters, Choice, Section } from 'src/app/data/class';
import { of } from 'rxjs';
import { Promotion } from 'src/app/data/model';
import { FilterDatepickerComponent } from '../filter-datepicker/filter-datepicker.component';
import { SegmentFilterComponent } from 'src/app/segment-filter/segment-filter.component';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { FilterDropdownComponent } from '../filter-dropdown/filter-dropdown.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { AppDataService } from 'src/app/app-data.service';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  @Input() searchFilters: SearchFilters;
  @Input() functions: any;
  @Input() helperData: any;
  @Input() isPopup: boolean;
  @Input() packagepop: boolean;
  helpers: any;
  appliedFilters: Filter[] = [];
  selectedPromotions: any[];
  noFiltersSelected = true;
  someFiltersNotApplied: boolean;
  headerType: string;
  heading: string;
  private clearButtonApplied: boolean;
  @ViewChildren(FilterDropdownComponent) dropdownList: QueryList<FilterDropdownComponent>;
  @ViewChildren(FilterDatepickerComponent) filterDatepickerList: QueryList<FilterDatepickerComponent>;
  @ViewChild(SegmentFilterComponent) filterSegment: SegmentFilterComponent;
  @ViewChild(FilterSelectComponent) filterSelect: FilterSelectComponent;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  @Output() applyFilterClose: EventEmitter<any> = new EventEmitter();

  constructor(private modalHelper: NgbModal, private applyfilterService: ApplyFilterService, private globalData: AppDataService) {}

  ngOnInit(): void {
    if (this.searchFilters.showSearchFilters === undefined) {
      this.searchFilters.showSearchFilters = true;
    }

    this.setDefaultsForFilters();

    if (!this.searchFilters.sections) {
      this.searchFilters.sections = [];
      this.searchFilters.sections.push({ name: 'default', key: 'nosection' });
    } else if (
      this.searchFilters.sections &&
      !this.searchFilters.sections.some((section: Section) => {
        return section.key === 'nosection';
      })
    ) {
      this.searchFilters.sections.unshift({ name: 'default', key: 'nosection', open: null });
    }

    this.searchFilters.sections.forEach((section: Section) => {
      section.open = section.key === 'nosection' || section.additionalRow;
    });

    const formsWithCustomHeaders = {
      activitySearch: 'activity',
      activityTabSearch: 'activity',
      memberSearch: 'member',
      userSearch: 'users',
    };

    this.headerType = formsWithCustomHeaders[this.searchFilters.formName]
      ? formsWithCustomHeaders[this.searchFilters.formName]
      : 'generic';

    this.helpers = {};

    this.helpers.isStringFilter = (filter: Filter): boolean => {
      return (
        !filter.type ||
        (filter.type &&
          (filter.type.toLowerCase() === 'string' ||
            filter.type.toLowerCase() === 'date' ||
            filter.type.toLowerCase() === 'number'))
      );
    };

    this.helpers.isBooleanFilter = (filter: Filter): boolean => {
      return filter.type && (filter.type.toLowerCase() === 'boolean' || filter.type.toLowerCase() === 'segmentless');
    };

    this.helpers.isSimpleArrayFilter = (filter: Filter): boolean => {
      return filter.type && filter.type.toLowerCase() === 'array' && filter.singleLabel !== undefined;
    };

    this.helpers.isSimpleFilter = (filter: Filter): boolean => {
      return (
        this.helpers.isStringFilter(filter) ||
        this.helpers.isBooleanFilter(filter) ||
        this.helpers.isSimpleArrayFilter(filter)
      );
    };

    this.helpers.isArrayFilter = (filter: Filter): boolean => {
      return filter.type && filter.type.toLowerCase() === 'array' && filter.singleLabel === undefined;
    };

    this.helpers.isEmptyFilter = (filter: Filter): boolean => {
      // checks for filter's whose value is empty or null
      // radio filters are technically never actually empty
      return (
        filter.type !== 'radio' &&
        (filter.value === null ||
          filter.value === '' ||
          (Array.isArray(filter.value) && filter.value.length === 0) ||
          (this.helpers.isBooleanFilter(filter) && filter.value === false))
      );
    };

    this.helpers.isNullOrEmpty = (value: any): boolean => {
      return value === null || value === '' || (Array.isArray(value) && value.length === 0);
    };

    this.helpers.showNoneSelected = (): boolean => {
      let showNoneSelected = false;
      if (!this.appliedFilters || this.appliedFilters.length === 0) {
        // actually no selections at all
        showNoneSelected = true;
      } else {
        // something is selected so make sure that there are only defaultRadioChoices selected
        const containsStandardFilter = this.appliedFilters.some((appliedFilter: Filter) => {
          return appliedFilter.type !== 'radio';
        });

        let containsDefaultRadioFilter = false;
        if (!containsStandardFilter) {
          // check for
          this.appliedFilters.forEach((appliedFilter: Filter) => {
            if (appliedFilter.type === 'radio') {
              containsDefaultRadioFilter = appliedFilter.choices.some((choice: Choice) => {
                return appliedFilter.value === choice.value && choice.isDefault;
              });
            }
          });
        }

        showNoneSelected = containsDefaultRadioFilter || containsDefaultRadioFilter;
      }
      return showNoneSelected;
    };

    this.helpers.radio = {};

    this.helpers.radio.getSelectedChoiceLabel = (filter: Filter): any => {
      let choiceName = '';

      filter.choices.forEach((choice: Choice) => {
        if (filter.value === choice.value) {
          choiceName = choice.label;
        }
      });
      return choiceName;
    };

    this.helpers.radio.isDefaultChoiceSelected = (filter: Filter): boolean => {
      let isDefaultChoiceSelected = false;
      if (filter.type === 'radio') {
        filter.choices.forEach((choice: Choice) => {
          if (filter.value === choice.value && choice.isDefault) {
            isDefaultChoiceSelected = true;
          }
        });
      }
      return isDefaultChoiceSelected;
    };

    this.searchFilters.segmentLessFilter = this.searchFilters.filters.filter((each) => {
      return each.name === 'segmentless' || each.name === 'onlySegmentless';
    })[0];

    this.searchFilters.clearAll = (): void => {
      if (this.clearButtonApplied) {
        return;
      }
      let isDefaultChoiceSelected = false;
      const appliedFiltersAfterClear = []; // for filters that default to something like a radio button
      this.searchFilters.filters.forEach((filter: Filter) => {
        if (filter.clearCallback) {
          filter.clearCallback();
        }

        // clear segments for segment filter
        if (filter.inputType === 'segment-filter' && !this.globalData.externalOrgCode) {
          this.filterSegment.clearAll();
        }

        if (filter.type === 'date') {
          this.filterDatepickerList.forEach((filterDatepicker) => {
            if (filterDatepicker.dateModel !== null) {
              filterDatepicker.clearDate();
            }
          });
        }

        if (filter.inputType === 'select') {
          this.filterSelect.clearSelect();
        }

        if (filter.type === 'radio') {
          isDefaultChoiceSelected = this.clearRadio(filter);
          appliedFiltersAfterClear.push(filter); // this will stay applied
        } else {
          // uncheck any checkboxes
          if (filter.choices) {
            filter.choices.forEach((choice: Choice) => {
              choice.state = false;
            });
            filter.selectedCount = 0;
          }

          filter.value = null;
        }
      });
      // dont clear everything out if we left have filters with default values
      this.applyfilterService.getFilterData([]);
      this.appliedFilters = appliedFiltersAfterClear.length > 0 ? appliedFiltersAfterClear : null;
      this.searchFilters.searchCallback();
      if (typeof this.searchFilters.onClear === 'function') {
        this.clearButtonApplied = true;
        this.searchFilters.onClear();
      }
      this.applyfilterService.getPopButtonStatus(false);
    };

    // cleared via the X button on the filter
    this.searchFilters.clearSelf = (filter: Filter, individualValue: any): void => {
      if (filter.type === 'radio') {
        this.clearRadio(filter);
      } else if (filter.inputType === 'segment-filter') {
        this.filterSegment.clearAll();
        this.clearSimple(filter);
      } else if (this.helpers.isArrayFilter(filter)) {
        if (filter.inputType === 'select') {
          this.filterSelect.clearOne(individualValue);
        } else if (filter.inputType === 'dropdown') {
          const select: FilterDropdownComponent = this.dropdownList.find((select: FilterDropdownComponent) => {
            return select.filter.name === filter.name;
          });
          if (individualValue) {
            select?.clearOne(individualValue);
          } else {
            select?.clearAll();
          }
        }
        if (individualValue) {
          this.clearArray(filter, individualValue);
        } else {
          // this.clearArray(filter, individualValue);
          this.clearSimple(filter);
        }
      } else if (this.helpers.isSimpleFilter(filter)) {
        this.clearSimple(filter);
      } else {
        console.error('Invalid type for search filters');
        return;
      }
      this.searchFilters.searchCallback();
    };

    // move filter values (if not null) to the appliedFilters array and re-run the search
    this.searchFilters.applyFilters = (): void => {
      const returnObject = {};
      const newAppliedFilters = [];
      this.searchFilters.filters.forEach((filter: Filter) => {
        if (!this.helpers.isEmptyFilter(filter)) {
          returnObject[filter.name] = filter.value;

          const tempFilter = Object.assign({}, filter);

          newAppliedFilters.push(tempFilter);
        }
      });

      this.appliedFilters = newAppliedFilters;

      this.someFiltersNotApplied = this.checkForChanges(this.searchFilters.filters, this.appliedFilters);

      this.applyFilterClose.emit(this.searchFilters);

      this.searchFilters.searchCallback();
    };

    // get filters that are current applied and add them the passed in
    // filter object and remove a filter from the object if it is no longer applied
    this.searchFilters.getFilters = (existing: Filter): any => {
      this.searchFilters.filters.forEach((filter: Filter) => {
        if (filter.type === 'radio') {
          if (this.helpers.isNullOrEmpty(filter.value)) {
            delete existing[filter.name];
          } else {
            existing[filter.name] = filter.value;
          }
        } else if (filter.value) {
          existing[filter.name] = filter.value;
        } else {
          if (existing[filter.name]) {
            delete existing[filter.name];
          }
        }
      });
      return existing;
    };
    // helper method to return reference to a named filter
    // intended to prevent ugly references like '$scope.searchFilters.filters[3].choices'
    this.searchFilters.getFilterRef = (filterName: string): Filter => {
      return this.searchFilters.filters.find((filter: Filter) => {
        return filter.name === filterName;
      });
    };

    if (!this.searchFilters.waitUntilApply) {
      this.searchFilters.applyFilters();
    }
    this.notifyParent.emit('OnInit End');

    this.searchFilters.filters.forEach((filter: any) => {
      if(filter.segmentMembers) {
        this.filterSegments(filter.segmentMembers);
      }
    });
  }

  clickFilter() {
    const returnObject = {};
    const newAppliedFilters = [];

    this.searchFilters.filters.forEach((filter: Filter) => {
      if (!this.helpers.isEmptyFilter(filter)) {
        returnObject[filter.name] = filter.value;

        const tempFilter = Object.assign({}, filter);

        newAppliedFilters.push(tempFilter);
      }
    });
    this.appliedFilters = newAppliedFilters;

    this.someFiltersNotApplied = this.checkForChanges(this.searchFilters.filters, this.appliedFilters);

    this.applyfilterService.getFilterData(this.appliedFilters);

    this.applyFilterClose.emit(this.searchFilters);

    this.searchFilters.searchCallback();
    this.cancel();
    this.applyfilterService.getPopButtonStatus(true);
  }
  filterSections(filter: Filter, sectionKey: string): boolean {
    const check = filter.section.key === sectionKey;
    return filter.section.key === sectionKey;
  }

  setDefaultsForFilters(): void {
    this.searchFilters.filters.forEach((filter: any) => {
      if (!filter.section) {
        filter.section = { name: 'default', key: 'nosection' };
      }

      // set class for input fields
      const flexMargins = ' mr-sm ml-sm mt-xs';
      if (filter.inputClass) {
        filter.inputClass = filter.inputClass + flexMargins;
      }

      if (!filter.inputClass) {
        // if not specified for individual
        if (!this.searchFilters.inputClass) {
          // if not specified for all
          filter.inputClass = flexMargins;
        } else {
          filter.inputClass = this.searchFilters.inputClass + flexMargins;
        }
      }
      if (!filter.value && !filter.allowEmpty) {
        filter.value = null;
      } else {
        // check for a promise
        if (filter.value) {
          const obsValue = of(filter.value);
          obsValue.subscribe(
            (data: any) => {
              if (data.success) {
                if (data.entity.aaData) {
                  filter.value = data.entity.aaData;
                } else {
                  filter.value = data.entity;
                }
              }
            },
            () => {
              console.warn(filter.name + ' value promise failed');
            }
          );
        }
      }

      // for arrays of objects, set property that contains the value we're handing to the query
      if (!filter.valueProp) {
        filter.valueProp = 'value';
      }

      // for arrays of objects, set property that contains the name displayed
      if (!filter.nameProp) {
        filter.nameProp = 'name';
      }

      if (filter.type === 'radio') {
        // if no choice has "isDefault", then set it on the first one in the array
        const defaultChoice = filter.choices.some((choice: any) => {
          return choice.isDefault;
        });

        if (!defaultChoice) {
          filter.choices[0].isDefault = true;
        }
      }

      // check choices for promise
      if (filter.choices) {
        if (filter.choices) {
          const obsChoices = of(filter.choices);
          obsChoices.subscribe(
            (data: any) => {
              if (data.success) {
                if (data.entity.aaData) {
                  filter.choices = data.entity.aaData;
                } else {
                  filter.choices = data.entity;
                }
              }
            },
            () => {
              console.warn(filter.name + ' choices promise failed');
            }
          );
        }
      }
    });
    // this.scope.$applyAsync();
  }

  checkForChanges(filters: Filter[], compareFilters: Filter[]): any {
    /* checkForChanges internal methods */
    function isDifferenceThanComparison(filter: Filter): boolean {
      return compareFilters.some((applied: Filter) => {
        return applied.name === filter.name && filter.value !== applied.value;
      });
    }

    function isIdenticalToComparison(filter: Filter): boolean {
      return compareFilters.some((applied: Filter) => {
        return applied.name === filter.name && filter.value === applied.value;
      });
    }

    if (!this.helpers.isNullOrEmpty(compareFilters)) {
      return filters.some((filter: Filter) => {
        if (isDifferenceThanComparison(filter)) {
          // if value is different than same filter in compareFilters, get me out of here percy
          return true;
        } else if (isIdenticalToComparison(filter)) {
          return false;
        } else {
          // if filter is not in compareTo, then it's a change so get me out of here percy
          return !this.helpers.isEmptyFilter(filter);
        }
      });
    } else {
      // if compareFilters is null or empty, than any non-empty filter is a new change
      return filters.some((filter: Filter) => {
        return !this.helpers.isEmptyFilter(filter);
      });
    }
  }

  filterSegments(segmentIds: number[]): void {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.inputType && filter.inputType === 'segment-filter') {
        if (filter.segmentMembers) {
          filter.value = filter.segmentMembers;
        } else if (segmentIds && segmentIds.length > 0) {
          filter.value = segmentIds;
        } else {
          // have filter directive not display anything if there are no segments being filtered
          filter.value = null;
        }
      }
    });

    this.someFiltersNotApplied = this.checkForChanges(this.searchFilters.filters, this.appliedFilters);
  }

  filterSegmentsHandler(segmentIds: number[]): void {
    this.filterSegments(segmentIds);
  }

  clearInput(promotionObject: Promotion, filter: Filter): void {
    if (promotionObject) {
      // clearCallback from searchFilter directive calls this when you click the tag
      // when using individual labels
      promotionObject.isSelected = !promotionObject.isSelected; // toggle selection
      const index = this.selectedPromotions.indexOf(promotionObject);
      if (index !== -1) {
        this.selectedPromotions.splice(index, 1);
      }
    } else {
      // clear all or single labels calls this
      // meaning clear all selections
      this.selectedPromotions.forEach((promo) => {
        // should still work since these should be references to the original
        promo.isSelected = !promo.isSelected; // toggle selection
      });

      this.selectedPromotions = [];
    }
  }

  uiSelectRemove(choice: Choice, filter: Filter): void {
    const prop = filter.valueProp ? filter.valueProp : filter.nameProp ? filter.nameProp : 'value';
    if (filter.choices && filter.choices.length > 0) {
      const values = [];
      filter.choices.forEach((data) => {
        if (data.state) {
          values.push(data[prop]);
        }
      });
      filter.value = values;
      filter.selectedCount = values.length;
    }
  }

  clearSimple(filter: Filter, individualValue: String = ''): void {
    let unapplied;
    let appliedIdx;
    /* This is either a filter with a single string value OR a Filter
      with an array value with SINGLE label so clear the value (either a string
      an array) */

    // remove filter from applied list
    appliedIdx = this.appliedFilters.indexOf(filter);
    this.appliedFilters.splice(appliedIdx, 1);

    this.someFiltersNotApplied = this.checkForChanges(this.searchFilters.filters, this.appliedFilters);

    // find it, run the callback if it has one, and then clear the value
    unapplied = undefined;
    this.searchFilters.filters.forEach((unappliedFilter: Filter) => {
      if (filter.name === unappliedFilter.name) {
        unapplied = unappliedFilter;
      }
    });

    // this will only be null if the filters aren't set up correctly
    if (unapplied) {
      if (unapplied.clearCallback) {
        unapplied.clearCallback();
      }
      if (individualValue && unapplied.value.length > 1) {
        //this will removed deleted data from the dropdown filter
        unapplied.value = unapplied.value.filter((value: String) => value !== individualValue);
      } else {
        unapplied.value = null;
      }
    }

    if (filter.type === 'date') {
      this.filterDatepickerList.forEach((filterDatePicker) => {
        this.searchFilters.filters.forEach((f) => {
          if (filter.value === this.formatDate(filterDatePicker.dateModel)) {
            filterDatePicker.clearDate();
          }
        });
      });
    }
  }

  formatDate(dateToFormat: any): string {
    if (dateToFormat !== undefined) {
      try {
        dateToFormat = dateToFormat.year + '-' + dateToFormat.month + '-' + dateToFormat.day;
      } catch (exeption) {}
    } else {
      dateToFormat = null;
    }
    return dateToFormat;
  }

  clearRadio(filter: Filter): boolean {
    /* Radio button filters are special cases because they're assumed to have a default choice.
     * If you don't provide one, we'll provide one for you (index 0 in the array). This means
     * that we don't technically clear it - we just return it to the default state.
     */
    let returnValue = false;

    // set back to default choice
    filter.choices.forEach((choice: Choice) => {
      if (choice.isDefault && filter.value !== choice.value) {
        // if this choice is the default set filter.value (applied filter) to the choice's value
        filter.value = choice.value;
        returnValue = true;
      } else {
        if (!returnValue) {
          // if we've already found the default, we won't change this flag
          returnValue = choice.isDefault && filter.value === choice.value;
        }
      }
    });

    return returnValue;
  }

  clearArray(filter: Filter, individualValue: any): void {
    let appliedIdx: number;
    let arrayValueIdx: number;
    /* Filter value is an array that has labels for each individual value */

    appliedIdx = this.appliedFilters.indexOf(filter);

    // this clears the value in both places for an array
    console.log('inside the clear array ', this.appliedFilters[appliedIdx]);
    // if (this.appliedFilters[appliedIdx] !== undefined) {
    arrayValueIdx = this.appliedFilters[appliedIdx].value.indexOf(individualValue);
    this.appliedFilters[appliedIdx].value.splice(arrayValueIdx, 1);
    // }

    this.someFiltersNotApplied = this.checkForChanges(this.searchFilters.filters, this.appliedFilters);

    this.searchFilters.filters.forEach((unappliedFilter: Filter) => {
      if (filter.name === unappliedFilter.name) {
        if (unappliedFilter.clearCallback) {
          // TODO: check if there is a problem here, originally it was clearCallback(individualValue) however this is never called
          unappliedFilter.clearCallback();
        }

        // uncheck the checkbox for the individualValue
        if (unappliedFilter.choices) {
          unappliedFilter.choices.forEach((choice: Choice) => {
            if (choice[unappliedFilter.nameProp] === individualValue) {
              choice.state = false;
            }
          });
        }
        if (unappliedFilter.selectedCount > 0) {
          unappliedFilter.selectedCount -= 1;
        }

        if (filter.selectedCount > 0) {
          filter.selectedCount -= 1;
        }
      }
    });

    // if the array is empty, we'll remove the whole thing
    // if (this.appliedFilters[appliedIdx] !== undefined) {
    if (this.appliedFilters[appliedIdx].value.length === 0) {
      this.appliedFilters.splice(appliedIdx, 1);

      this.someFiltersNotApplied = this.checkForChanges(this.searchFilters.filters, this.appliedFilters);
      // }
    }
  }

  inputChangeHandler(): void {
    this.clearButtonApplied = false;
    this.someFiltersNotApplied = this.checkForChanges(this.searchFilters.filters, this.appliedFilters);
  }
  cancel(): void {
    this.searchFilters.waitUntilApply = true;
    this.modalHelper.dismissAll();
  }
}
