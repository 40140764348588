import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AppDataService } from '../app-data.service';
import { SecurityService } from '../services/security/security.service';
import { GlobalEventService } from '../services/global-event.service';

import { CALL_FLOW_BUILDER_PATH, PARENTS_ROUTE_PATH } from '../route.constants';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() value: any;
  @Output() sideToggle: EventEmitter<any> = new EventEmitter();
  isLiveAgent: any;
  isLiveAgentManager: any;
  isCSR: any;
  isPartner: any;
  isCSRManager: any;
  isAccountManager: any;
  isProgramManager: any;
  isAdmin: any;
  inter: any;

  navList: any = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public globalData: AppDataService,
    public userRole: SecurityService,
    private globalEventService: GlobalEventService
  ) {
    this.globalEventService.getGlobalEvent().subscribe((response: any) => {
      if (response.toComponent === 'select-organization') {
        this.navList[5].linkDisabled = false;
        this.navList[6].linkDisabled = false;
      }
    });
  }
  ngOnInit(): void {
    this.isLiveAgent = this.globalData.checkPermission('LIVE_AGENT');
    this.isLiveAgentManager = this.globalData.checkPermission('LIVE_AGENT_MANAGER');
    this.isCSR = this.globalData.checkPermission('CSR');
    this.isPartner = this.globalData.checkPermission('PARTNER');
    this.isCSRManager = this.globalData.checkPermission('CSR_MANAGER');
    this.isAccountManager = this.globalData.checkPermission('ACCOUNT_MANAGER');
    this.isProgramManager = this.globalData.checkPermission('PROGRAM_MANAGER');
    this.isAdmin = this.globalData.checkPermission('ADMIN');
    this.navList = [
      {
        index: 0,
        heading: 'Dashboard',
        icon: 'Dashboard',
        link: '/dashboard',
        display: this.isAccountManager,
        linkDisabled: false,
        pages: [
          {
            title: 'Redemptions dashboard',
            link: '/redemption-dashboard/',
            display: this.isAccountManager,
            linkDisabled: false,
            icon: '',
          },
        ],
      },
      {
        index: 1,
        heading: 'Select Organization',
        icon: 'Markets',
        link: `/select-organization`,
        display: (this.isLiveAgent || this.isLiveAgentManager) && !this.isCSR && !this.isCSRManager,
        linkDisabled: false,
        pages: [],
      },
      {
        index: 2,
        heading: 'Organizations',
        icon: 'Markets',
        link: `/${PARENTS_ROUTE_PATH}`,
        display: this.isProgramManager,
        linkDisabled: false,
        pages: [],
      },
      {
        index: 3,
        heading: 'Products',
        icon: 'Programs',
        link: '/products',
        display: this.isProgramManager,
        linkDisabled: false,
        pages: [],
      },
      {
        index: 4,
        heading: 'Communications',
        icon: 'Communications',
        link: '/communications',
        display: this.isProgramManager,
        linkDisabled: false,
        pages: [],
      },
      {
        index: 5,
        heading: 'Survey status',
        icon: 'LiveAgent',
        link: '/live-agent-dashboard',
        display:
          ((this.isLiveAgent || this.isLiveAgentManager) && !this.isCSR && !this.isCSRManager) || this.isAccountManager,
        pages: [],
        linkDisabled:
          (this.isLiveAgent || this.isLiveAgentManager) &&
          !this.isCSR &&
          !this.isCSRManager &&
          this.globalData.orgId === 0,
      },
      {
        index: 6,
        heading: 'Members',
        icon: 'Members',
        link: '/members',
        display:
          this.isPartner ||
          this.userRole.allowFullMemberFunctionality ||
          ((this.isLiveAgent || this.isLiveAgentManager) && !this.isCSR),
        linkDisabled:
          (this.isLiveAgent || this.isLiveAgentManager) &&
          !this.isCSR &&
          !this.isCSRManager &&
          this.globalData.orgId === 0,
        pages: [
          {
            title: 'Notes',
            link: '/interactions',
            display: this.isCSR,
            icon: '',
          },
        ],
      },
      {
        index: 7,
        heading: 'Integrations',
        icon: 'Integrations',
        link: '/integrations',
        display: this.isAccountManager,
        linkDisabled: false,
        pages: [
          {
            title: 'Inbound batch',
            link: '/integrations',
            display: this.isProgramManager,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Outbound batch',
            link: '/outbounds',
            display: this.isProgramManager,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Event sources',
            link: '/eventSources',
            display: this.isProgramManager,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Events',
            link: '/events',
            display: this.isProgramManager,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Measures',
            link: '/measures',
            display: this.isProgramManager,
            linkDisabled: false,
            icon: '',
          },
        ],
      },
      {
        index: 8,
        heading: 'Reports',
        icon: 'Reports',
        link: '/reports',
        display: this.isAccountManager || this.userRole.allowReportsTab,
        linkDisabled: false,
        pages: [],
      },
      {
        index: 9,
        heading: 'Processing',
        icon: 'Processing',
        link: '/processing-log',
        display: this.isCSRManager || this.userRole.allowProcessingTab,
        linkDisabled: false,
        pages: [
          {
            title: 'Processing log',
            link: '/processing-log',
            display: this.isCSRManager,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Activity search',
            link: '/activity-search',
            display: this.isAccountManager,
            linkDisabled: false,
            icon: '',
          },
        ],
      },
      {
        index: 10,
        heading: 'System',
        icon: 'System',
        link: '/system',
        display: this.isProgramManager,
        linkDisabled: false,
        pages: [
          {
            title: 'Segments',
            link: '/segments',
            display: true,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Users',
            link: '/users',
            display: this.isAdmin,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Partners',
            link: '/partners',
            display: this.isAdmin,
            linkDisabled: false,
            icon: '',
          },
          {
            title: 'Call flow builder',
            link: `/${CALL_FLOW_BUILDER_PATH}`,
            display: this.isProgramManager,
            linkDisabled: false,
            icon: '',
          },
        ],
      },
    ];
  }
  selectedindex: number;
  isDisplay: boolean = true;
  selectedSubmenu: string;
  previousIndex: number;
  isExpanded: boolean = false;
  icon: Array<boolean> = [false, false, false, false, false, false, false, false, false];
  submenushow: Array<boolean> = [false, false, false, false, false, false, false, false, false];
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );
  isSelected = (index) => {
    return this.selectedindex === index;
  };

  closeSidenav() {
    this.sideToggle.emit(this.isExpanded);
    this.isExpanded = !this.isExpanded;
    this.submenushow.map((data, idx) => {
      if (data) {
        this.submenushow[idx] = false;
        this.icon[idx] = false;
      }
      if (this.selectedSubmenu && this.selectedindex === idx) {
        this.submenushow[idx] = true;
        this.icon[idx] = true;
      }
    });
  }

  click(item) {
    this.icon[item.index] = !this.icon[item.index];
    this.submenushow[item.index] = !this.submenushow[item.index];
    this.selectedindex = item.index;
    this.selectedSubmenu = '';
    if (item.index != this.previousIndex) {
      //Close previous drop down
      // this.submenushow[this.previousIndex] = false;
      // this.icon[this.previousIndex] = false;
    }
    this.previousIndex = item.index;
  }

  submenuClick(obj, idx) {
    this.selectedSubmenu = obj.title;
    this.selectedindex = idx;
  }
}
