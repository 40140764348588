import React from "react";
import { Edit as EditIcon } from "@mui/icons-material";
import IconButton from "@iridium/iridium-ui/Button/IconButton";

export function editOrganizationTestId(id: number) {
  return `edit-organization-${id}`;
}

export type EditOrganizationButtonProps = {
  testId: string;
  onClick: () => void;
};

function EditOrganizationButton({ onClick, testId }: EditOrganizationButtonProps) {
  return (
    <IconButton aria-label="edit" size="small" onClick={onClick} data-testid={testId}>
      <EditIcon fontSize="inherit" />
    </IconButton>
  );
}

export default EditOrganizationButton;
