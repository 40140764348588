import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppDataService } from '../app-data.service';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  constructor(
    private title: Title,
    public globalData: AppDataService,
    private applyfilterService: ApplyFilterService
  ) {}

  isAccountManager = this.globalData.isAccountManager;
  isAdmin = this.globalData.isAdmin;
  activeId = 1;
  filterFunctions: any;

  ngOnInit(): void {
    this.title.setTitle('Reports');
  }
  tabClick(tab) {
    this.applyfilterService.filterfun.subscribe((data) => {
      this.filterFunctions = data;
    });
    if (this.filterFunctions) {
      this.filterFunctions.clearAll();
    }
    this.applyfilterService.getFilterData([]);
  }
}
