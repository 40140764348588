import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrgConfiguration } from '../data/model';
import { AccessRoleService } from '../services/access-role.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { InstrumentTypeService } from '../services/instrument-type.service';
import { OrganizationConfigurationService } from '../services/organization-configuration.service';

@Component({
  selector: 'app-identifier-types-modal',
  templateUrl: './identifier-types-modal.component.html',
  styleUrls: ['./identifier-types-modal.component.scss']
})
export class IdentifierTypesModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Output() public errorEvent: EventEmitter<any> = new EventEmitter();
  @Input() public orgConfiguration: OrgConfiguration;
  @Input() public organizationId: number;

  identifierTypeForm: FormGroup;
  title: string = ''
  isEdit: boolean = false;

  accessRolesList = [];
  identifierTypesList = [];
  selectedIdentifierTypes = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private accessRoleService: AccessRoleService,
    private responseHelperService: ResponseHelperService,
    private instrumentTypeService: InstrumentTypeService,
    private organizationConfigurationService: OrganizationConfigurationService
  ) { }

  ngOnInit(): void {
    this.orgConfiguration ? this.isEdit = true : this.isEdit = false;

    this.accessRoleService.getAllAccessRoles().subscribe((data: any) => {
      this.accessRolesList = data.entity;
    });

    this.getInstruments();

    if (this.orgConfiguration) {
      this.title = 'Edit Identifier Type';
      this.selectedIdentifierTypes = JSON.parse(this.orgConfiguration.cfgValue);
      this.identifierTypeForm = this.formBuilder.group({
        roleScope: [this.orgConfiguration.roleScope, Validators.required],
        cfgValue: [this.orgConfiguration.cfgValue, Validators.required]
      });
    } else {
      this.title = 'New Identifier Type';
      this.identifierTypeForm = this.formBuilder.group({
        roleScope: ['', Validators.required],
        cfgValue: ['', Validators.required]
      });
    }
  }

  getInstruments(): void {
    this.instrumentTypeService.getInstrumentTypes({ statuses: ['ACTIVE', 'PENDING'] }).subscribe((data: any) => {
      if (this.selectedIdentifierTypes[0] !== "*AllInstruments*") {
        this.identifierTypesList = data.entity.aaData;
      } else {
        this.identifierTypesList = [];
      }
    })
  }

  onSelectAll(): void {
    this.selectedIdentifierTypes = ["*AllInstruments*"];
    this.getInstruments();
  }

  onClearAll(): void {
    this.identifierTypeForm.get('cfgValue').patchValue([]);
    this.getInstruments();
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  ok(): void {
    const formValue = Object.assign({}, this.identifierTypeForm.value);

    formValue.orgId = this.organizationId;
    formValue.cfgCode = 'InstrumentTypeFilter';

    this.selectedIdentifierTypes = ["*AllInstruments*"];

    if (this.identifierTypeForm.controls.cfgValue.value.length !== this.identifierTypesList.length) {
      this.selectedIdentifierTypes = this.identifierTypeForm.controls.cfgValue.value;
    }

    formValue.cfgValue = JSON.stringify(this.selectedIdentifierTypes);

    this.organizationConfigurationService.createOrUpdateOrganizationConfiguration(this.orgConfiguration && this.orgConfiguration.id, formValue).subscribe(() => {
      this.successEvent.emit('success');
    }, (data: any) => {
      this.errorEvent.emit(data);
    });
  }

}
