<form [formGroup]="interactionForm">
  <div class="modal-header">
    <h1>Note subject</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Summary of Issue <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input
        type="text"
        formControlName="subject"
        name="subject"
        placeholder="Summary of Issue"
        class="form-control"
        maxlength="30"
        title="Limited to 30 characters"
      />
      <!-- <div class="mt-sm" [hidden]="interactionForm.controls['subject'].valid">
        <small class="text-danger">Please input summary</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Contact Method <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select formControlName="contactMethod" name="contactMethod" class="form-control mb-0">
        <option *ngFor="let option of contactMethods" value="{{ option.value }}">{{ option.name }}</option>
      </select>
      <!-- <div class="mt-sm" [hidden]="interactionForm.controls['contactMethod'].valid">
        <small class="text-danger">Select contact method</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Contact Reason <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select formControlName="contactReason" name="contactReason" class="form-control mb-0">
        <option *ngFor="let option of contactReasons" value="{{ option.id }}">{{ option.value }}</option>
      </select>
      <!-- <div class="mt-sm" [hidden]="interactionForm.controls['contactReason'].valid">
        <small class="text-danger">Select contact reason</small>
      </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!interactionForm.valid"></app-modal-buttons>
  </div>
</form>
