import { Component } from '@angular/core';
import { MountReactParams } from '../services/react.service';
import CallFlowBuilder, { CallFlowBuilderProps } from './CallFlowBuilder';
import { CallFlowQueryService } from '../services/call-flow-query.service';

@Component({
  selector: 'app-call-flow-builder',
  template: `<app-mount-react [mountReactParams]="mountReactParams"></app-mount-react>`,
})
export class CallFlowBuilderComponent {
  callFlowQueryService: CallFlowQueryService | undefined = undefined;
  mountReactParams: MountReactParams<CallFlowBuilderProps>;

  constructor(callFlowQueryService: CallFlowQueryService) {
    this.callFlowQueryService = callFlowQueryService;
    this.mountReactParams = {
      mountId: 'callflow-builder-id',
      component: CallFlowBuilder,
      props: {
        getCallFlowById: this.callFlowQueryService?.getCallFlowById.bind(this.callFlowQueryService),
        getInstructionWavUrl: this.callFlowQueryService?.getInstructionWavUrl.bind(this.callFlowQueryService),
      },
    };
  }
}
