import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationConfigurationService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  getAllOrganizationConfigurations(params?: any) {
    return this.http.get(this.globalData.baseUrl + 'organizationTypes', this.setParams(params));
  }

  createOrUpdateOrganizationConfiguration(id: number, organizationConfiguration: any): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'organizationTypes/' + id, organizationConfiguration);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'organizationTypes/', organizationConfiguration);
  }

  deleteOrganizationConfiguration(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'organizationTypes/' + id);
  }

}
