import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss'],
})
export class InteractionsComponent implements OnInit {
  constructor(private titleHelper: Title) {}

  ngOnInit(): void {
    this.titleHelper.setTitle('Interactions');
  }
}
