<form [formGroup]="phoneForm" novalidate="" class="com-model">
  <div class="com-outreach-modal">
    <div class="mb-2">
      <span class="title">Contacts</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-4 mt-4">
      <span class="com-title">{{ title }}</span>
    </div>
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label class="com-label">Phone number <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>

          <input
            type="text"
            name="phone_number"
            formControlName="phoneNumber"
            class="form-control"
            autofocus=""
            placeholder="Add phone number"
          />
          <div class="mt-1" [hidden]="phoneForm.controls.phoneNumber.valid">
            <!-- <small class="text-danger">Input phone number</small> -->
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label class="com-label">Phone type <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            class="inputfontsize"
            [items]="phoneTypes"
            bindLabel="typeName"
            bindValue="id"
            formControlName="phoneTypeId"
            placeholder="Select"
          >
          </ng-select>
          <div class="mt-1" [hidden]="phoneForm.controls.phoneTypeId.valid">
            <!-- <small class="text-danger">Select phone type</small> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <div>
            <div class="d-flex justify-content-between">
              <label class="com-label">Status <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <!-- <select class="form-control" formControlName="status" placeholder="Select">
              <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
            </select> -->
            <ng-select
              [items]="statuses"
              class="inputfontsize"
              formControlName="status"
              placeholder="Select"
            ></ng-select>
          </div>
          <div class="mt-1" [hidden]="phoneForm.controls.status.valid">
            <!-- <small class="text-danger">Select status</small> -->
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="primary-check d-flex">
          <div>
            <input type="checkbox" formControlName="isPrimary" id="isPrimary" class="com-input-check" />
          </div>
          <div>
            <label for="isPrimary" class="form-check-label com-label">Primary</label>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn-container mt-4">
      <button class="btn-pop-cancel" type="button" (click)="cancel()">CANCEL</button>
      <button class="btn-pop-success" type="submit" (click)="ok()" [disabled]="!phoneForm.valid || !phoneForm.dirty">
        {{ isEdit ? "SAVE" : "ADD" }}
      </button>
    </div>
  </div>
</form>
