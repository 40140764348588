<div class="modalstyle">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="row">
      <table class="table table-pointable table-bordered">
        <thead>
          <tr>
            <th>Attribute Name</th>
            <th>Value</th>
            <th>Effective From</th>
            <th>Effective To</th>
            <th>Status</th>
            <th class="width-50">Provenance</th>
            <th class="width-5" *ngIf="isProgramManager"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let attribute of attributes">
            <td>{{ attribute.attrName }}</td>
            <td>{{ attribute.attrValue }}</td>
            <td>{{ attribute.effectiveFrom }}</td>
            <td>{{ getEffectiveToDisplay(attribute) }}</td>
            <td>{{ attribute.commandType }}</td>
            <td>
              <div *ngFor="let item of attribute.dataProvenance | keyvalue">
                <div *ngIf="item.value !== null">{{ item.key }}:{{ item.value }}</div>
              </div>
            </td>
            <td *ngIf="isProgramManager">
              <button
                title="delete"
                class="btn btn-outline-primary btn-sm edit action"
                (click)="deleteDetails(attribute)"
              >
                <fa-icon [icon]="faTrashAlt"></fa-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons [showSubmitButton]="false" (cancel)="cancel()"></app-modal-buttons>
  </div>
</div>
