import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class RewardService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getRewardDetails(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'rewards/' + id + '/details');
  }

  getReverseDetailsForReward(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'rewards/' + id + '/reverse-details');
  }

  reverseTransaction(id: number, data: any): any {
    return this.http.post(this.globalData.baseUrl + 'rewards/' + id + '/reverse', data);
  }
}
