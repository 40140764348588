import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faBarcode, faCircle, faPencilAlt, faPlus, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { Product } from 'src/app/data/model';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ProductService } from 'src/app/services/product.service';
import { ProductModalComponent } from '../product-modal/product-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-products-tab',
  templateUrl: './products-tab.component.html',
  styleUrls: ['./products-tab.component.scss'],
})
export class ProductsTabComponent implements OnInit {
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  statuses: Choice[] = [];
  lock: boolean;
  lengthToCompare: number;
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  faCircle = faCircle;
  products: any[] = [];
  promotions: any[] = [];
  selectedProduct: Product;
  lockedTables: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  faBarcode = faBarcode;
  filteredStatuses: Choice[] = [];
  disabled: boolean;
  productCategories: any[] = [];
  statusHelper: string[];
  searchFilters: SearchFilters;

  constructor(
    private tableHelperService: TableHelperService,
    private responseHelper: ResponseHelperService,
    private productService: ProductService,
    private modalHelper: NgbModal,
    private statusHelperService: StatusHelperService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'products-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Product',
          if: true,
          callback: () => {
            this.productModal();
          },
          title: 'New Product',
        },
      ],
    };
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.show = true;
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
    this.setCategories();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getProducts(false);
  }

  getProducts(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.productService.getProducts(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.products = this.products.concat(data.entity.aaData);
        } else {
          this.products = data.entity.aaData;
          this.selectedProduct = null;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      (err) => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  deleteProduct(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete product with ID: ' + id;
    instance.componentInstance.header = 'Delete product?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.productService.deleteProduct(id).subscribe(() => {
        this.responseHelper.success('Product successfully removed', true);
        this.productService.getProduct(id).subscribe((data: any) => {
          if (data.success) {
            this.products[this.products.findIndex((e) => e.id === id)] = data.entity;
          }
        });
      });
    });
  }

  productModal(productId?: number): void {
    if (productId) {
      this.productService.getProduct(productId).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(product?: Product): void {
    const instance = this.modalHelper.open(ProductModalComponent);
    instance.componentInstance.product = product;
    instance.componentInstance.categories = this.productCategories;
    instance.componentInstance.successEvent.subscribe((data) => {
      if (data.success) {
        this.responseHelper.success(
          `Product successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`,
          true
        );
        this.initNewSearch();
      }
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getProducts(false);
  }

  setCategories(): void {
    this.productService.getProductCategories().subscribe((data: any) => {
      if (data.success) {
        this.productCategories = data.entity;
      }
    });
  }

  getProductCategoryById(productCategoryId: number): any {
    return this.productCategories.find((e) => e.id === productCategoryId) || {};
  }

  selectProduct(event: any, product: Product): void {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    this.selectedProduct = product;
  }
}
