import { SecurityService } from 'src/app/services/security/security.service';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { faEye, faCreditCard, faWrench } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter, SearchFilters } from 'src/app/data/class';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { AppDataService } from 'src/app/app-data.service';
import { AccountModel } from 'src/app/data/model';
import { RewardTypeService } from 'src/app/services/reward-type.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { AccountsModalComponent } from '../accounts-modal/accounts-modal.component';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { UtilityService } from 'src/app/services/utility.service';
import { MembersService } from 'src/app/services/members.service';
import { ReversalConfirmationModalComponent } from '../reversal-confirmation-modal/reversal-confirmation-modal.component';
import { RedemptionService } from 'src/app/services/redemption.service';
import { AdjustmentModalComponent } from '../adjustment-modal/adjustment-modal.component';
import { MoreDetailsModalComponent } from 'src/app/more-details-modal/more-details-modal.component';
import { RedemptionItemsModalComponent } from '../redemption-items-modal/redemption-items-modal.component';
import { ToastrService } from 'ngx-toastr';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { RedemptionAddressModalComponent } from '../redemption-address-modal/redemption-address-modal.component';

@Component({
  selector: 'app-accounts-tab',
  templateUrl: './accounts-tab.component.html',
  styleUrls: ['./accounts-tab.component.scss'],
})
export class AccountsTabComponent implements OnInit {
  @Input() public personId: number;
  accountHistory: any[] = [];
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  lock: boolean;
  lengthToCompare: number;
  lockedTables: boolean;
  faEye = faEye;
  faCreditCard = faCreditCard;
  faWrench = faWrench;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  isAdmin = this.globalData.isAdmin;
  isCSR = this.globalData.isCSR;
  isCSRManager = this.globalData.isCSRManager;
  isPartner = this.globalData.isPartner;
  isProgramManager = this.globalData.isProgramManager;
  filteredAccounts = [];
  selectedTxn: any = {};
  accounts: AccountModel[] = [];
  rewardTypeFilter: any = {};
  accountTypes: any[];
  statuses: string[];
  partyRewardTypes: any[] = [];
  show: boolean;
  type = 'accounts';

  constructor(
    public globalData: AppDataService,
    private tableHelper: TableHelperService,
    private rewardTypeService: RewardTypeService,
    private responseHelper: ResponseHelperService,
    private statusHelperService: StatusHelperService,
    private redemptionService: RedemptionService,
    private utilityService: UtilityService,
    private userPreferences: UserPreferencesService,
    private memberService: MembersService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    public securityService: SecurityService
  ) {}

  canReverse = this.securityService.allowReverse;

  ngOnInit(): void {
    this.filter.column = 4;
    this.filter.dir = 'desc';
    this.partyRewardTypes = [];
    this.initRewardTypeFilter();
    this.rewardTypeService.getRewardTypeForParty(this.personId).subscribe((data: any) => {
      data.entity.forEach((rewardType: any) => {
        this.partyRewardTypes.push({
          value: rewardType.name,
          state: false,
        });
      });
      this.searchFilters = {
        formName: 'generic',
        buttonsWithText: true,
        filters: [
          {
            name: 'accounts',
            placeholder: 'Account',
            type: 'array',
            choices: this.partyRewardTypes,
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
          },
          {
            name: 'types',
            placeholder: 'Activity Type',
            type: 'array',
            choices: this.tableHelper.getAccountTypes(),
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
          },
          {
            name: 'from',
            type: 'date',
            placeholder: 'From:',
          },
          {
            name: 'to',
            type: 'date',
            endDate: true,
            placeholder: 'To:',
          },
          {
            name: 'search',
            placeholder: 'Keyword',
          },
        ],
        searchCallback: () => {
          this.initNewSearch();
        },
        addEntityButtons: [
          {
            icon: faWrench,
            title: 'Manual Adjustment',
            if: this.isProgramManager,
            buttonText: 'Manual Adj',
            callback: () => {
              this.adjustmentModal();
            },
          },
        ],
      };
      this.show = true;
      this.accountTypes = this.tableHelper.getAccountTypes();
      this.setAccounts();
    });
  }

  setAccounts(): void {
    this.memberService.getAccounts(this.personId).subscribe((data: any) => {
      if (data.success) {
        this.accounts = data.entity;
      }
    });
  }

  showDetails(data: any, event?: any): void {
    if (event) {
      event.preventDefault();
    }
    this.selectedTxn = data;
    if (this.selectedTxn.accountTxnSelector === 'REDEMPTION') {
      this.redemptionService.getRedemptionItems(data.id).subscribe(
        (redemptionItem: any) => {
          const instance = this.modalHelper.open(RedemptionItemsModalComponent, { size: 'xl' });
          instance.componentInstance.redemptionItems = redemptionItem.entity;
          instance.componentInstance.title = 'Redemption Items for (ID: ' + data.id + ')';
        },
        (data: any) => {}
      );
    } else {
      const instance = this.modalHelper.open(MoreDetailsModalComponent, { size: 'xl' });
      instance.componentInstance.selectedTxn = this.selectedTxn;
    }
  }

  confirmReversal(ach: any, event: any): void {
    event.preventDefault();
    const instance = this.modalHelper.open(ReversalConfirmationModalComponent);
    instance.componentInstance.txnNotes = '';
    instance.componentInstance.reversedBy = this.globalData.username;
    instance.componentInstance.id = ach.id;
    instance.componentInstance.reversedOn = new Date().toISOString().slice(0, 10);
    instance.componentInstance.accounts = this.accounts;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
    });
  }

  hasAccounts(): boolean {
    return this.accounts.length > 0;
  }

  deleteAccount(id: number, e: any): void {
    this.memberService.deleteAccount(this.personId, id).subscribe(
      (data: any) => {
        this.responseHelper.success('Account successfully removed');
        this.setAccounts();
      },
      (data: any) => {
        this.responseHelper.error(this, data.error.error);
      }
    );
  }

  initRewardTypeFilter(): void {
    this.rewardTypeFilter = { statuses: ['ACTIVE', 'PENDING', 'STARTED'], dir: 'asc', column: 1 };
  }

  accountModal(id?: number): void {
    if (this.isCSRManager) {
      this.statuses = this.statusHelperService.getStatus('dropdown');
    }
    this.rewardTypeService.getRewardTypes(this.rewardTypeFilter).subscribe((data: any) => {
      if (id) {
        this.memberService.getAccount(this.personId, id).subscribe((attr: any) => {
          if (attr.success) {
            const instance = this.modalHelper.open(AccountsModalComponent);
            instance.componentInstance.account = attr.entity;
            instance.componentInstance.personId = this.personId;
            instance.componentInstance.rewardTypes = data.entity.aaData;
            instance.componentInstance.successEvent.subscribe(() => {
              this.setAccounts();
            });
          }
        });
      } else {
        const instance = this.modalHelper.open(AccountsModalComponent);
        instance.componentInstance.rewardTypes = data.entity.aaData;
        instance.componentInstance.personId = this.personId;
        instance.componentInstance.successEvent.subscribe(() => {
          this.setAccounts();
        });
      }
    });
  }

  toggleSort(column: number): void {
    this.filter.dir = this.filter.column === column ? this.flipDirection() : 'desc';
    this.filter.column = column;
    this.initNewSearch();
  }

  flipDirection(): string {
    return this.filter.dir === 'desc' ? 'asc' : 'desc';
  }

  adjustmentModal(): void {
    const additionalAttributes = {
      accounts: this.accounts,
      personId: this.personId,
    };
    this.rewardTypeService.getRewardTypes().subscribe((data: any) => {
      const instance = this.modalHelper.open(AdjustmentModalComponent);
      instance.componentInstance.rewardTypes = data.entity;
      instance.componentInstance.personId = this.personId;
      instance.componentInstance.additionalAttributes = additionalAttributes;
      instance.componentInstance.successEvent.subscribe(() => {
        this.setAccounts();
      });
    });
  }

  initNewSearch(): void {
    setTimeout(() => {
      this.initFilter();
      this.getAccountHistory(false);
    }, 0);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
  }

  getAccountHistory(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.filter.memberId = this.personId;
    this.memberService.findAccountTxns(this.utilityService.removeNullAndEmptyProperties(this.filter)).subscribe(
      (data: any) => {
        if (concat) {
          this.accountHistory = this.accountHistory.concat(data.entity.aaData);
        } else {
          this.accountHistory = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      (err) => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  showAddress(txnId: number, event: any): any {
    event.preventDefault();
    this.redemptionService.getRedemption(txnId).subscribe(
      (resp: any) => {
        const instance = this.modalHelper.open(RedemptionAddressModalComponent, { size: 'xl' });
        instance.componentInstance.address = resp.entity;
      },
      (data: any) => {}
    );
  }

  getStyle(amount: number): any {
    return amount < 0 ? { color: 'red' } : {};
  }
}
