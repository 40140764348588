<div style="background: white; display: flex; justify-content: flex-end">
  <button
    title="Zoom to fit"
    (click)="zoomToFit$.next(true); center$.next(true)"
    class="btn btn-outline-primary btn-sm mt-1 mb-1"
  >
    <fa-icon [icon]="faSearch"></fa-icon>
  </button>
  <button title="Update" (click)="update$.next(true)" class="btn btn-outline-primary btn-sm mt-1 mr-2 mb-1">
    <fa-icon [icon]="faSync"></fa-icon>
  </button>
  <button
    style="border: 1px solid #377fbf !important; line-height: 0 !important"
    mat-button
    [matMenuTriggerFor]="menu"
    class="btn btn-outline-primary btn-sm mt-1 mr-2 mb-1"
  >
    <fa-icon [icon]="faCogs"></fa-icon>
    <fa-icon class="ml-1" [icon]="faCaretDown"></fa-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="changeValue($event, 'showMinimap')">
      Show minimap<input
        style="height: 10px; float: right; margin-top: 20px; cursor: pointer"
        type="checkbox"
        [(ngModel)]="showMinimap"
        type="checkbox"
      />
    </button>
    <button (click)="changeValue($event, 'zoomEnabled')" mat-menu-item>
      Enable Zoom<input
        style="height: 10px; float: right; margin-top: 20px; cursor: pointer"
        type="checkbox"
        [(ngModel)]="zoomEnabled"
        type="checkbox"
      />
    </button>
    <button (click)="changeValue($event, 'panningEnabled')" mat-menu-item>
      Enable Panning<input
        style="height: 10px; float: right; margin-top: 20px; cursor: pointer"
        type="checkbox"
        (click)="panningEnabled = !panningEnabled"
        [(ngModel)]="panningEnabled"
        type="checkbox"
      />
    </button>
  </mat-menu>
</div>
<div style="background-color: white">
  <div
    style="padding-right: 20px !important; padding-right: 20px !important"
    class="row d-flex flex-column main-container"
    [style.height.px]="height"
    #graph
  >
    <div
      class="col-lg-12 com-header header-container"
      [style.height.px]="height"
      style="padding-left: 0 !important; padding-right: 0 !important"
    >
      <ngx-graph
        [showMiniMap]="showMinimap"
        [style.height.px]="height"
        [update$]="update$"
        miniMapMaxWidth="200"
        miniMapMaxHeight="200"
        [enableZoom]="zoomEnabled"
        [panningEnabled]="panningEnabled"
        class="chart-container"
        [center$]="center$"
        [zoomToFit$]="zoomToFit$"
        [draggingEnabled]="false"
        [links]="links"
        [nodes]="nodes"
        [autoZoom] = true
      >
        <ng-template #nodeTemplate let-node>
          <svg:g class="node">
            <svg:rect attr.fill="transparent" />
            <svg:foreignObject
              width="90"
              height="50"
              style="background: white"
              [ngClass]="node.id === selectedCommunication.id ? 'selected-comm' : ''"
            >
              <div
                style="text-align: center; display: flex; justify-content: center; background: white"
                class="communication-header cursor-pointer"
              >
                <div class="selectable-div" (click)="selectComm(node)" style="display: flex">
                  <div style="display: flex; flex-wrap: wrap">
                    <div style="width: 100%">
                      <span
                        class="colored-circle"
                        style="margin-top: 15px"
                        [ngClass]="statusHelper[node.status]"
                        [attr.title]="node.status"
                        >{{ node.status.substring(0, 1) }}</span
                      >
                    </div>
                  </div>
                  <span style="margin-left: 5px">
                    <fa-icon class="fa-3x" [icon]="getIcon(node)"></fa-icon>
                  </span>
                </div>
                <div class="ml-1">
                  <div
                    style="width: 100%; margin-top: 8px"
                    title="''{{ node.name }}'' Add Child"
                    (click)="addChild(node, true)"
                  >
                    <fa-icon [icon]="faPlusCircle" class="btn-color"></fa-icon>
                  </div>
                  <div style="width: 100%; cursor: default">
                    <fa-icon title="{{ node.name }}" [icon]="faInfo" class="info-color"></fa-icon>
                  </div>
                </div>
              </div>
            </svg:foreignObject>
          </svg:g>
        </ng-template>
      </ngx-graph>
    </div>
  </div>
</div>
<hr style="cursor: ns-resize" #hr />
