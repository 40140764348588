<div>
  <span *ngIf="show">
    <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
    <div class="filters_container">
      <div class="search-input2">
        <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
        <mat-icon class="search">search</mat-icon>
      </div>
      <div class="right_container2">
        <button class="filter-btn" (click)="FilterModel()">FILTERS<mat-icon class="ml-2">filter_list</mat-icon></button>
      </div>
    </div>
    <app-filter-tags
      *ngIf="searchFilters"
      isPopup="true"
      [(filterButton)]="filterButton"
      [(searchFilters)]="searchFilters"
      [(appliedFilters)]="appliedFilters"
    ></app-filter-tags>
  </span>
  <div class="row">
    <table class="table table-pointable table-bordered">
      <thead>
        <tr>
          <th class="table_field_name" style="padding-left: 2rem">Endpoint type</th>
          <th class="table_field_name">Protocol name</th>
          <th class="table_field_name">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let deliveryProtocol of deliveryProtocols | filter: searchText; let i = index">
          <td>
            <span style="margin: 0 10px">
              <img
                src="../../../assets/images/Api.png"
                alt="Vector"
                style="margin-right: 10px; width: 24px; height: 24px"
                *ngIf="deliveryProtocol.endpointType.name === 'API'"
              />
              <fa-icon [icon]="faPhoneAlt" *ngIf="deliveryProtocol.endpointType.name === 'PHONE'"></fa-icon>
              <fa-icon [icon]="faHome" *ngIf="deliveryProtocol.endpointType.name === 'ADDRESS'"></fa-icon>
              <fa-icon [icon]="faAt" *ngIf="deliveryProtocol.endpointType.name === 'EMAIL'"></fa-icon>
            </span>
            {{ deliveryProtocol.endpointType.name }}
          </td>
          <td>{{ deliveryProtocol.name }}</td>
          <td>
            <div class="float-left actions inline-table">
              <a
                class="btn btn-outline-primary btn-sm edit action"
                (click)="deliveryModal(deliveryProtocol)"
                title="Edit"
                ><fa-icon [icon]="faPencilAlt"></fa-icon
              ></a>
              <a
                title="Edit Delivery Options"
                type="button"
                class="btn btn-outline-primary btn-sm edit action"
                (click)="editOptions(deliveryProtocol)"
                ><fa-icon [icon]="faList"></fa-icon
              ></a>
            </div>
          </td>
        </tr>
        <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent"></tr>
      </tbody>
    </table>
  </div>
</div>
