import React, { useCallback, useEffect, useState } from "react";

import ReactFlow, {
  addEdge,
  Connection,
  Edge,
  ReactFlowProvider,
  removeElements,
  Elements as ReactFlowElements,
  ConnectionLineType,
  FlowElement,
} from "react-flow-renderer";
import { playWav, fetchWavData } from "./services/audio.service";
import {
  CallFlowNodeData,
  getInstructionViewData,
  getLeadingInstructionData,
  InstructionViewData,
  LeadingInstructionData,
  mapCallFlowToReactFlowElements,
  WavDataFromInstruction,
  wavFromSelectedInstructionId,
} from "./services/callflow.service";
import Grid from "@iridium/iridium-ui/Grid";

import { simpleWithExpectedResponse } from "./services/__mocks__/mockReactFlowData";
import { applyPositionsToReactFlowElements } from "./services/react-flow.service";
import { CallFlow, Instruction, InstructionVariant } from "./models/model";
import SelectedInstruction from "./components/SelectedInstruction";

import qs from "qs";
import CheckIcon from "@mui/icons-material/Check";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { Box } from "@mui/material";
import IconButton from "@iridium/iridium-ui/Button/IconButton";
import { TextField } from "@iridium/iridium-ui/node_modules/@mui/material";
import JSONDialog from "./components/JSONDialog";
import SurveyQA from "./components/SurveyQA";

const initialElements = applyPositionsToReactFlowElements({
  elements: simpleWithExpectedResponse.reactFlowElements,
});

function getInitialInstructionViewData() {
  return {
    tags: [],
    instructionIdExpectedResponseMap: [],
    optOutInstructionData: {
      instruction: undefined,
      isOptOutInstructionTypeValid: false,
      isOptOutResponseIdValid: false,
    },
    validatedExpectedResponseViewDataList: [],
  };
}

export type CallFlowBuilderProps = {
  getCallFlowById: (callFlowId: string) => Promise<CallFlow>;
  getInstructionWavUrl: (callFlowId: string, fileName: string) => Promise<string>;
};
const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

export type ReactFlowElementsWithCallFlowNodeData = ReactFlowElements<CallFlowNodeData>;

export default function CallFlowBuilder(props: CallFlowBuilderProps) {
  const { getCallFlowById } = props;
  const [callFlow, setCallFlow] = useState<CallFlow | undefined>(undefined);
  const [elements, setElements] = useState<ReactFlowElementsWithCallFlowNodeData>(initialElements);
  const [selectedInstruction, setSelectedInstruction] = useState<Instruction | undefined>(undefined);
  const [callFlowID, setCallFlowID] = useState<string>((queryParams.callFlowId as string) || "");
  const [audioSource, setAudioSource] = useState<AudioBufferSourceNode | undefined>(undefined);
  const [leadingInstructionDataList, setLeadingInstructionDataList] = useState<LeadingInstructionData[]>([]);
  const [wavDataList, setWavDataList] = useState<WavDataFromInstruction[]>([]);
  const [hiddenEdgeList, setHiddenEdgeList] = useState<string[]>(["FAILURE"]);
  const [isEdgeHidden, setIsEdgeHidden] = useState(false);
  const [expandedVariant, setExpandedVariant] = React.useState<string | false>(false);
  const [selectedVariant, setSelectedVariant] = React.useState<InstructionVariant | false>(false);
  const [instructionViewData, setInstructionViewData] = useState<InstructionViewData>(getInitialInstructionViewData);
  const [isJSONModalOpen, setIsJSONModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (callFlow) {
      createElements(callFlow);
    }
  }, [callFlow]);

  useEffect(() => {
    if (!expandedVariant) {
      setSelectedVariant(false);
    }
  }, [expandedVariant]);

  useEffect(() => {
    if (callFlow) {
      createElements(callFlow);
    }
  }, [hiddenEdgeList]);

  useEffect(() => {
    if (callFlowID) {
      void handleGetCallFlowClick();
    }
  }, []);

  useEffect(() => {
    if (callFlow && selectedInstruction) {
      setExpandedVariant(false);
      setSelectedVariant(false);
      const newLeadingInstructionData = getLeadingInstructionData(selectedInstruction, callFlow);
      setLeadingInstructionDataList(newLeadingInstructionData);
      const newWavDataList = wavFromSelectedInstructionId(selectedInstruction);
      setWavDataList(newWavDataList);
    }
    if (selectedInstruction) {
      if (!hiddenEdgeList.includes(selectedInstruction.id)) {
        setIsEdgeHidden(false);
      } else {
        setIsEdgeHidden(true);
      }
    }
  }, [selectedInstruction, callFlow]);

  useEffect(() => {
    if (callFlow && callFlow.instructions) {
      const newInstructionViewData = getInstructionViewData({
        instructionList: callFlow.instructions,
      });
      setInstructionViewData(newInstructionViewData);
    }
  }, [callFlow?.instructions]);

  function onConnect(connection: Edge | Connection) {
    return setElements((els) =>
      addEdge(
        {
          ...connection,
          type: "smoothstep",
          animated: true,
        },
        els
      )
    );
  }

  function onElementsRemove(elementsToRemove: ReactFlowElements) {
    return setElements((els) => removeElements(elementsToRemove, els));
  }

  function positionElements(direction: string) {
    const positionedElements = applyPositionsToReactFlowElements({
      elements,
      direction,
    });
    setElements(positionedElements);
  }

  const onLayout = useCallback(positionElements, [elements]);

  const handleElementClick = (event: any, element: FlowElement) => {
    const newSelectedInstruction = callFlow?.instructions.find((instruction) => instruction.id === element.id);
    setSelectedInstruction(newSelectedInstruction);
  };

  function createElements(callFlow: CallFlow) {
    const elements = mapCallFlowToReactFlowElements(callFlow, hiddenEdgeList);
    setElements(elements);
  }

  async function handlePlayWav(audioFile?: string): Promise<void> {
    if (audioSource) {
      audioSource.stop();
    }
    if (audioFile && callFlow) {
      const signedUrl = await props.getInstructionWavUrl(callFlow.id, audioFile);
      if (!signedUrl) {
        console.log(`could not get signed URL for ${audioFile}`);
        return;
      }
      const wavData = await fetchWavData(signedUrl);
      if (wavData) {
        playWav(wavData).then((source) => setAudioSource(source));
      }
    }
  }

  async function handleGetCallFlowClick() {
    // f93215dc-ac12-432b-acfe-03e8824eb058 - Preventative
    // f009e1ff-bce4-4fd7-90b1-fcf14af32227 - HRA
    const callFlow = await getCallFlowById(callFlowID);
    setCallFlow(callFlow);
  }

  function handleHideShowEdgeClick(instruction: string) {
    let isEdgeHidden = false;
    let updatedHiddenEdgeList: string[] = hiddenEdgeList;
    if (hiddenEdgeList.includes(instruction)) {
      updatedHiddenEdgeList = hiddenEdgeList.filter((foundInstruction) => foundInstruction !== instruction);
    } else {
      updatedHiddenEdgeList.push(instruction);
    }
    if (selectedInstruction && !updatedHiddenEdgeList.includes(selectedInstruction.id)) {
      isEdgeHidden = true;
    }
    setIsEdgeHidden(isEdgeHidden);
    setHiddenEdgeList(updatedHiddenEdgeList);
    createElements(callFlow);
  }

  function onJSONFormSubmit(callflow: CallFlow) {
    setCallFlow(callflow);
  }

  const reactFlowStyle = {
    background: "#e8ecef",
    "& .react-flow__node.selected": {
      background: "#083658",
      color: "#e8ecef",
    },
  };

  const { tags, instructionIdExpectedResponseMap, optOutInstructionData, validatedExpectedResponseViewDataList } =
    instructionViewData;

  return (
    <Grid container sx={{ backgroundColor: "#e8ecef" }}>
      <Grid item xs={6} sx={{ pl: 2, pr: 2, mt: 2 }}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Grid container flexDirection="row">
                <TextField
                  size="small"
                  id="callFlowIdInput"
                  type="text"
                  autoFocus
                  placeholder="Add Call ID"
                  sx={{
                    mb: 2,
                    mr: "4px",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      minWidth: "210px",
                      "& > fieldset": {
                        "& > legend": {
                          display: "none",
                        },
                        border: "1px solid #e5e6e7",
                      },
                      "&:hover fieldset": {
                        border: "1px solid #e5e6e7",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1px solid #e5e6e7",
                      },
                      ":focus-visible": {
                        outline: "none",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        top: 0,
                      },
                    },
                  }}
                  InputProps={{
                    sx: {
                      fontSize: "13px !important",
                    },
                  }}
                  onChange={(event) => setCallFlowID(event.target.value)}
                />
                <IconButton disabled={!callFlowID} onClick={handleGetCallFlowClick}>
                  <CheckIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container flexDirection="row">
                <IconButton onClick={() => setSelectedInstruction(undefined)}>
                  <ContentPasteSearchIcon />
                </IconButton>
                <IconButton onClick={() => onLayout("TB")}>
                  <SwapVertIcon />
                </IconButton>
                <IconButton onClick={() => onLayout("LR")}>
                  <SwapHorizIcon />
                </IconButton>
                <IconButton
                  onClick={() => setIsJSONModalOpen(true)}
                  size="small"
                  sx={{
                    padding: "5px",
                    backgroundColor: "#083658",
                    color: "white",
                    borderRadius: "4px",
                    width: "35px",
                  }}
                >
                  J
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} height="80vh" sx={reactFlowStyle}>
              <ReactFlowProvider>
                <ReactFlow
                  elements={elements}
                  onElementClick={handleElementClick}
                  onConnect={onConnect}
                  onElementsRemove={onElementsRemove}
                  connectionLineType={"smoothstep" as ConnectionLineType}
                />
              </ReactFlowProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5.95} sx={{ backgroundColor: "#fff" }}>
        <Box overflow="auto" height="90vh">
          {selectedInstruction && (
            <SelectedInstruction
              leadingInstructionDataList={leadingInstructionDataList}
              selectedInstruction={selectedInstruction}
              edgesHidden={isEdgeHidden}
              waveDataList={wavDataList}
              onPlayWav={handlePlayWav}
              onEdgeHideShow={handleHideShowEdgeClick}
              expandedVariant={expandedVariant}
              selectedVariant={selectedVariant}
              setSelectedVariant={setSelectedVariant}
              setExpandedVariant={setExpandedVariant}
            />
          )}
          {!selectedInstruction && <SurveyQA callflow={callFlow} />}
        </Box>
      </Grid>
      <JSONDialog
        isJSONModalOpen={isJSONModalOpen}
        setIsJSONModalOpen={setIsJSONModalOpen}
        callflow={callFlow}
        setCallflow={setCallFlow}
        onJSONFormSubmit={onJSONFormSubmit}
      />
    </Grid>
  );
}
