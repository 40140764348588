import { Injectable } from '@angular/core';
import { EntityStatus } from '../../../app/data/model';

@Injectable({
  providedIn: 'root',
})
export class StatusHelperService {
  importStatusMap = {
    900: 'STAGING',
    901: 'STAGED',
    902: 'STAGING_ERROR',
    903: 'IMPORTING',
    904: 'IMPORTED',
    905: 'IMPORTING_ERROR',
    906: 'RETRY',
    907: 'POSTPROCESSED',
    908: 'COMPLETED',
  };

  constructor() {}

  getStatus(type: string): any {
    if (type === 'badge') {
      return {
        ACTIVE: 'badge-info',
        STARTED: 'badge-info',
        COMPLETED: 'badge-info',
        INACTIVE: 'badge-warning',
        PENDING: 'badge-warning',
        SUSPENDED: 'badge-danger',
        TERMINATED: 'badge-danger',
        RETIRED: 'badge-danger',
        EXPIRED: 'badge-danger',
        DELETED: 'badge-danger',
        FAILED: 'badge-danger',
        STAGING: 'badge-warning',
        STAGED: 'badge-info',
        STAGING_ERROR: 'badge-danger',
        IMPORTING: 'badge-warning',
        IMPORTED: 'badge-info',
        IMPORTING_ERROR: 'badge-danger',
        RETRY: 'badge-warning',
      };
    } else if (type === 'dropdown') {
      return [
        'ACTIVE',
        'STARTED',
        'COMPLETED',
        'INACTIVE',
        'PENDING',
        'SUSPENDED',
        'TERMINATED',
        'RETIRED',
        'EXPIRED',
        'DELETED',
        'FAILED',
      ];
    }
  }

  convertImportStatus(statusCode: string): any {
    return this.importStatusMap[statusCode];
  }
}

// These are copied .scss variable files. If changing these we should consider changing them in the .scss files as well
export const BADGE_SUCCESS_COLOR = 'rgba(61, 133, 61, 1)';
export const BADGE_INFO_COLOR = '#1C84C6';
export const BADGE_WARNING_COLOR = '#F8AC59';
export const BADGE_DANGER_COLOR = '#ED5565';

export function mapStatusToHexColor(status: EntityStatus): string {
  switch (status) {
    case EntityStatus.ACTIVE:
      return BADGE_SUCCESS_COLOR;
    case EntityStatus.STARTED:
    case EntityStatus.COMPLETED:
      return BADGE_INFO_COLOR;
    case EntityStatus.INACTIVE:
    case EntityStatus.PENDING:
      return BADGE_WARNING_COLOR;
    case EntityStatus.TERMINATED:
    case EntityStatus.RETIRED:
    case EntityStatus.EXPIRED:
    case EntityStatus.DELETED:
    case EntityStatus.FAILED:
    case EntityStatus.SUSPENDED:
      return BADGE_DANGER_COLOR;
    default:
      return assertUnreachable(status);
  }
}

function assertUnreachable(x: never): never {
  throw new Error(`Status not found: ${x}`);
}
