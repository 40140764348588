import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-manifest-unsaved-changes-modal',
  templateUrl: './manifest-unsaved-changes-modal.component.html',
  styleUrls: ['./manifest-unsaved-changes-modal.component.scss'],
})
export class ManifestUnsavedChangesModalComponent {
  allow: Subject<boolean> = new Subject<boolean>();
  faTimes = faTimes;

  @Input() manifestComponent: any;

  constructor(private modalHelper: NgbModal) {}

  allowChange(): void {
    this.allow.next(true);
    this.allow.complete();
    this.modalHelper.dismissAll();
  }

  save(): void {
    this.manifestComponent.createJSON();
    this.allowChange();
  }

  cancel(): void {
    this.allow.next(false);
    this.allow.complete();
    this.modalHelper.dismissAll();
  }
}
