import React from "react";
import { Organization } from "../../data/model";
import Grid from "@iridium/iridium-ui/Grid";
import Typography from "@iridium/iridium-ui/Typography";
import { StatusChip } from "./StatusChip";
import { EntityStatus } from "../../data/model";

import EditOrganizationButton, { editOrganizationTestId } from "./EditOrganizationButton";
import { DEFAULT_PARENT_NAME } from "../constants";

export type OrganizationTitleBarProps = {
  parentOrganization?: Organization;
  onEditOrganization: (organization: Organization) => void;
  parentEntityTypeName: string;
};

function OrganizationTitleBar({
  parentOrganization,
  onEditOrganization,
  parentEntityTypeName,
}: OrganizationTitleBarProps) {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography
          variant="h5"
          component="h2"
          sx={(theme) => ({ color: "#000", fontWeight: "500", fontSize: "22px" })}
        >
          {parentEntityTypeName} {parentOrganization === null ? DEFAULT_PARENT_NAME : parentOrganization?.name}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="right">
          <Grid item mr={1}>
            <Typography
              sx={{ color: "rgb(103, 106, 108)", fontSize: "13px !important", fontWeight: "700" }}
              variant="body2"
            >
              Status:
            </Typography>
          </Grid>
          <Grid item mr={parentOrganization ? 1 : 0}>
            <StatusChip
              sx={{ fontWeight: "medium" }}
              status={parentOrganization ? parentOrganization.status : EntityStatus.ACTIVE}
            />
          </Grid>
          {parentOrganization && (
            <Grid item>
              <EditOrganizationButton
                onClick={() => onEditOrganization(parentOrganization)}
                testId={editOrganizationTestId(parentOrganization.id)}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrganizationTitleBar;
