import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SearchFilters, Filter } from '../data/class';
import { ToastrService } from 'ngx-toastr';
import { SurveyService } from 'src/app/services/survey.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { faInfoCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { SurveyModalComponent } from '../survey-modal/survey-modal.component';
import { Survey } from '../data/model';
import { SurveyQuestionsModalComponent } from '../survey-questions-modal/survey-questions-modal.component';
import { ApolloService } from '../services/apollo.service';

@Component({
  selector: 'app-survey-tab',
  templateUrl: './survey-tab.component.html',
  styleUrls: ['./survey-tab.component.scss'],
})
export class SurveyTabComponent implements OnInit {
  lockedTables: boolean;
  isLoading: boolean;
  lock: boolean;
  filter = new Filter();
  limit = 20;
  surveys: any[] = [];
  lengthToCompare: number;
  searchFilters: SearchFilters;
  faInfoCircle = faInfoCircle;
  faPencilAlt = faPencilAlt;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  @Input() personId: number;
  @Input() isCSRManager: boolean;
  selectedNode: Survey;
  submissions: any;
  after = null;
  hasNextPage: boolean;

  constructor(
    private toastr: ToastrService,
    private surveyService: SurveyService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private apolloService: ApolloService
  ) {}

  ngOnInit(): void {
    this.apolloService.createClient();
    this.searchFilters = {
      formName: 'surveys-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'start',
          type: 'date',
          placeholder: 'Start date:',
        },
        {
          name: 'end',
          type: 'date',
          endDate: true,
          placeholder: 'End date:',
        },
        {
          name: 'createdDate',
          type: 'date',
          placeholder: 'Created Date:',
        },
        {
          name: 'submittedDate',
          type: 'date',
          placeholder: 'Submitted Date:',
        },
        {
          name: 'answers',
          placeholder: 'Answers...',
        },
      ],
    };
    this.getSubmissions(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getSubmissions(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getSubmissions(concat: boolean): void {
    this.isLoading = true;
    //  this.filter = this.searchFilters.getFilters(this.filter);
    this.surveyService.getSubmissions(this.personId, this.limit, this.after).subscribe(
      (response: any) => {
        if (concat) {
          this.surveys = this.surveys.concat(response.data.viewer.submissions.edges);
        } else {
          this.selectedNode = null;
          this.surveys = response.data.viewer.submissions.edges;
        }
        this.after = response.data.viewer.submissions.pageInfo.endCursor;
        this.hasNextPage = response.data.viewer.submissions.pageInfo.hasNextPage;
        this.lengthToCompare = response.data.viewer.submissions.edges.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.lockedTables = response.data.viewer.submissions.edges.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  edit(survey: any): void {
    const instance = this.modalHelper.open(SurveyModalComponent, { size: 'lg', animation: true });
    instance.componentInstance.surveyId = survey.node.surveyId;
    instance.componentInstance.answer = survey.node.surveyResponse
      .map((response: any) => response.answer.join(' '))
      .join('\n');
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data.success) {
        this.responseHelperService.success('Survey successfully updated', true);
        this.initNewSearch();
      }
    });
  }

  showDetails(survey: any): void {
    const instance = this.modalHelper.open(SurveyQuestionsModalComponent, { size: 'lg', animation: true });
    instance.componentInstance.survey = survey;
    instance.componentInstance.surveyName = survey.node.surveyData.title;
  }
}
