<div>
  <form>
    <label>Remove an attribute from the member:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="removeAttribute !== null">
        <div *ngFor="let removeAttr of removeAttribute; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeConsequence(removeAttr)"
            title="Remove Consequence"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="attrName_{{ removeAttr.attrName }}">Attribute Name</label>
              <input
                class="form-control m-b"
                type="text"
                id="attrName_{{ removeAttr.attrName }}"
                name="attrName"
                [(ngModel)]="removeAttr.attrName"
                (ngModelChange)="updateConsequences()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Remove Attribute
        </button>
      </div>
    </div>
  </form>
</div>
