<form [formGroup]="measureForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Measure Name</label>
          <input type="text" class="form-control" formControlName="measureName" />
          <div class="mt-2" *ngIf="measureForm.controls.measureName.errors?.required">
            <small class="text-danger">Please input a Measure Name</small>
          </div>
          <div class="mt-2" *ngIf="measureForm.controls.measureName.errors?.minlength">
            <small class="text-danger">Measure Name is required to be at least 3 characters</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Temporal Unit Code</label>
          <select name="temporalUnitCode" class="form-control" formControlName="temporalUnitCode">
            <option *ngFor="let tempUnit of temporalUnits" value="{{ tempUnit.code }}">{{ tempUnit.code }}</option>
          </select>
          <div class="mt-2" [hidden]="measureForm.controls.temporalUnitCode.valid">
            <small class="text-danger">Select unit code</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Unit</label>
          <select name="unit" class="form-control" formControlName="unit">
            <option *ngFor="let unit of units" value="{{ unit.unitAbbreviation }}">
              {{ unit.unitName }} ({{ unit.unitAbbreviation }})
            </option>
          </select>
          <div class="mt-2" [hidden]="measureForm.controls.unit.valid">
            <small class="text-danger">Select unit</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!measureForm.valid"></app-modal-buttons>
  </div>
</form>
