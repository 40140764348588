import { AppDataService } from './../app-data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getConfiguration(code: string): any {
    return this.http.get<any>(this.globalData.baseUrl + 'configuration/' + code);
  }

  getSSOConfig(): any {
    return this.http.get<any>(this.globalData.baseUrl + 'configuration/SSO', { headers: { skipInterceptor: 'true' } });
  }

  getSysTag(): any {
    return this.http.get<any>(this.globalData.baseUrl + 'configuration/systag');
  }

  getLoginBanner(): any {
    return this.http.get<any>(this.globalData.baseUrl + 'configuration/login-banner', {
      headers: { skipInterceptor: 'true' },
    });
  }
}
