<form [formGroup]="surveyForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Answers</label>
      <textarea formControlName="answers" type="text" class="form-control vertical-expand"></textarea>
      <div *ngIf="!surveyForm.controls.answers.valid">
        <small class="text-danger">Input answers</small>
      </div>
    </div>

    <div class="modal-footer">
      <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!surveyForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>
