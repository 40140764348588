import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { Partner } from 'src/app/data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { PartnerService } from '../services/partner.service';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { PersonsService } from '../services/persons.service';
import { AppDataService } from '../app-data.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';

@Component({
  selector: 'app-partner-modal',
  templateUrl: './partner-modal.component.html',
  styleUrls: ['./partner-modal.component.scss'],
})
export class PartnerModalComponent implements OnInit {
  @Input() partner: Partner;
  @Input() roles: any[] = [];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  partnerForm: FormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  faCalendar = faCalendar;
  filteredSegments: any[];

  constructor(
    private statusHelperService: StatusHelperService,
    private partnerService: PartnerService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private dateHelperService: DateHelperService,
    private personsService: PersonsService,
    private globalData: AppDataService,
    private responseHelper: ResponseHelperService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.partner) {
      this.title = 'Edit partner';
      this.isEdit = true;
      this.partnerForm = this.formBuilder.group({
        name: [this.partner.name, Validators.required],
        username: [this.partner.username, Validators.required],
        status: [this.partner.status, Validators.required],
        url: [this.partner.url],
        workingHours: [this.partner.workingHours],
        yearStart: [this.dateHelperService.stringToNgbDate(this.partner.yearStart)],
        partyRoleTypes: [this.partner.partyRoleTypes, Validators.required],
      });
    } else {
      this.title = 'New partner';
      this.isEdit = false;
      this.partnerForm = this.formBuilder.group({
        name: ['', Validators.required],
        username: ['', Validators.required],
        status: ['', Validators.required],
        segmentsCollection: [null, Validators.required],
        workingHours: [''],
        url: [''],
        yearStart: [],
        partyRoleTypes: ['', Validators.required],
      });
    }
    this.getPersonSegments();
  }

  getPersonSegments(): void {
    if (!this.isEdit) {
      const params = {
        children: true,
      };
      this.personsService.getPersonSegments(this.globalData.userId, params).subscribe((data: any) => {
        if (data.success) {
          this.filteredSegments = data.entity;
        }
      });
    }
  }

  clearDateInput(): void {
    this.partnerForm.patchValue({
      yearStart: null,
    });
  }

  ok(): void {
    const value = this.partnerForm.value;
    value.yearStart = this.dateHelperService.ngbDateToString(value.yearStart);
    this.partnerService.createOrUpdatePartner(this.partner && this.partner.id, value).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
      },
      (data: any) => {
        this.responseHelper.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
