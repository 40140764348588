<form [formGroup]="communicationForm" class="com-model" novalidate>
  <div class="com-outreach-modal">
    <div>
      <span class="title word-break">{{ title }}</span>
      <hr class="mb-1" />
    </div>
    <div class="mb-4 mt-4">
      <span class="com-title">{{ heading }}</span>
    </div>
    <div class="">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Name <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>
        <input type="text" class="form-control" formControlName="name" placeholder="Add name" />
        <!-- <div class="mt-2" [hidden]="communicationForm.controls.name.valid">
          <small class="text-danger">Input a Name</small>
        </div> -->
      </div>
      <div class="row" [hidden]="!fromParentCommunication">
        <div class="col">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Endpoint type <span style="color: red">*</span></label>
              <span>(Required)</span>
            </div>
            <ng-select
              class="inputfontsize"
              [items]="endpointTypes"
              bindLabel="name"
              bindValue="name"
              formControlName="endpointType"
              placeholder="Select type"
            ></ng-select>
            <!-- <div class="mt-2" [hidden]="communicationForm.controls.endpointType.valid">
              <small class="text-danger">Select type</small>
            </div> -->
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="d-flex justify-content-between" [hidden]="!fromParentCommunication">
              <label class="com-label">Delivery protocol <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <ng-select
              class="inputfontsize"
              [items]="deliveryProtocols"
              bindLabel="name"
              [(ngModel)]="selectedDeliveryProtocol"
              formControlName="deliveryProtocol"
              placeholder="Select protocol"
            ></ng-select>
            <!-- <div class="mt-2" [hidden]="communicationForm.controls.deliveryProtocol.valid">
              <small class="text-danger">Select protocol</small>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col" [hidden]="!fromParentCommunication">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Type <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <ng-select
              class="inputfontsize"
              [items]="commTypes"
              bindValue="id"
              bindLabel="name"
              formControlName="commTypeId"
              placeholder="Select type"
            ></ng-select>
            <div class="mt-2" [hidden]="communicationForm.controls.commTypeId.valid">
              <small class="text-danger">Select type</small>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Status <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>
        <!-- <select formControlName="status" class="form-control" formControlName="status">
              <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
            </select> -->
        <ng-select
          class="inputfontsize"
          [items]="statuses"
          bindLabel="name"
          bindValue="id"
          formControlName="status"
          placeholder="Select status"
        ></ng-select>
        <!-- <div class="mt-2" [hidden]="communicationForm.controls.status.valid">
              <small class="text-danger">Select status</small>
            </div> -->
      </div>
      <div class="row mt-3" [hidden]="!fromParentCommunication">
        <div class="col">
          <div class="d-flex">
            <div class="mr-2">
              <input
                type="checkbox"
                formControlName="requirePreference"
                id="requirePreference"
                class="com-input-check"
              />
            </div>
            <div>
              <label for="requirePreference" class="com-label">Require preference</label>
            </div>
          </div>
        </div>
        <div class="col" *ngIf="fromParentCommunication">
          <div class="form-group" *ngIf="isEdit">
            <div class="d-flex justify-content-between">
              <label class="com-label">Parent communication <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <ng-select
              [items]="potentialParents"
              bindLabel="name"
              bindValue="id"
              formControlName="parentId"
            ></ng-select>
            <!-- <div class="mt-2" [hidden]="communicationForm.controls.parentId.valid">
              <small class="text-danger">Select Parent Communication</small>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="pop-btn-container mt-4">
      <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
      <button class="btn-pop-success" type="submit" (click)="ok()" [disabled]="!communicationForm.valid" title="Save">
        {{ isEdit ? "SAVE" : "ADD" }}
      </button>
    </div>
  </div>
</form>
