<div>
  <form>
    <label>Restrict by Location Attribute:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="locationAttributeRestriction !== null">
        <div
          *ngFor="let attribRestriction of locationAttributeRestriction; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeRestriction(attribRestriction)"
            title="Remove Restriction"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>Such that the location:</label>
              <select
                name="condition_{{ attribRestriction.attrName }}"
                class="form-control"
                [(ngModel)]="attribRestriction.condition"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let condition of attribConditions"
                  [selected]="attribRestriction.condition === condition.value"
                  [value]="condition.value"
                >
                  {{ condition.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="attrName_2{{ attribRestriction.attrName }}"
                >an Attribute with Name</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="attrName_2{{ attribRestriction.attrName }}"
                name="attrName"
                [(ngModel)]="attribRestriction.attrName"
                (ngModelChange)="updateRestrictions()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="partyAttrValue_{{ attribRestriction.attrName }}"
                >and the Attribute Value matches (leave blank to match any value):</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="partyAttrValue_{{ attribRestriction.attrName }}"
                name="attrValue"
                [(ngModel)]="attribRestriction.attrValue"
                (ngModelChange)="updateRestrictions()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage2_{{ attribRestriction.attrName }}">Message:</label>
              <input
                class="form-control m-b"
                type="text"
                id="friendlyMessage2_{{ attribRestriction.attrName }}"
                name="friendlyMessage"
                [(ngModel)]="attribRestriction.friendlyMessage"
                (ngModelChange)="updateRestrictions()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Location Attribute Restriction
        </button>
      </div>
    </div>
  </form>
</div>
