<div class="middle-box text-center loginscreen">
  <div>
    <h2>{{ loginBanner }}</h2>
    <form class="mt-2" role="form" ng-submit="submit()" [formGroup]="setPasswordForm" *ngIf="!passwordResetComplete">
      <div class="form-group">
        <input
          type="password"
          id="password"
          class="form-control"
          placeholder="New password"
          name="newPassword"
          formControlName="newPassword"
        />
        <div class="mt-2" [hidden]="setPasswordForm.controls.newPassword.valid">
          <span class="text-danger" *ngIf="setPasswordForm.controls.newPassword.errors?.required">
            Input a password
          </span>
          <span class="text-danger" *ngIf="setPasswordForm.controls.newPassword.errors?.invalidPassword">
            Passwords must be at least 8 characters long and include a capital letter, a lowercase letter and a number
          </span>
        </div>
      </div>
      <div class="form-group">
        <input
          type="password"
          id="passwordConfirmation"
          class="form-control"
          placeholder="Confirm new password"
          name="confirmPassword"
          formControlName="repeatPassword"
        />
        <div class="mt-2" [hidden]="setPasswordForm.controls.repeatPassword.valid">
          <span
            class="text-danger"
            *ngIf="
              !setPasswordForm.controls.repeatPassword.errors?.invalidPassword &&
              setPasswordForm.controls.repeatPassword.errors?.invalidMatch
            "
          >
            Passwords do not match
          </span>
          <span class="text-danger" *ngIf="setPasswordForm.controls.repeatPassword.errors?.invalidPassword">
            Passwords must be at least 8 characters long and include a capital letter, a lowercase letter and a number
          </span>
        </div>
      </div>
      <button type="submit" class="btn btn-primary block full-width mb-2" (click)="submit()">Submit</button>
      <a (click)="login()"><small>Login</small></a>
    </form>
    <div *ngIf="passwordResetComplete">
      <h2>Password reset complete. Return <a href="/login">here</a> to log in.</h2>
    </div>
  </div>
</div>
