import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { faBox, faChevronUp, faPencilAlt, faPlus, faUserPlus, faMouse } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Choice } from '../data/class';
import { ProgramService } from '../services/program.service';
import { PromotionTypeService } from '../services/promotion-type.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { Package, Program, Promotion } from '../data/model';
import { PromotionModalComponent } from '../promotion-modal/promotion-modal.component';
import { ToastrService } from 'ngx-toastr';
import { PackageModalComponent } from '../package-modal/package-modal.component';
import { PromotionService } from '../services/promotion.service';
import { GlobalEventService } from '../services/global-event.service';
import { PromotionsTabTackerService } from '../services/promotions-tab-tacker.service';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
})
export class PromotionsComponent implements OnInit {
  promotion: Promotion = new Promotion();
  program: Program;
  limit: number;
  faPlus = faPlus;
  faBox = faBox;
  faMouse = faMouse;
  faUserPlus = faUserPlus;
  programStructure: any = {};
  selectedPackage: any;
  selectedPromotion: any;
  programPackages: any[] = [];
  lockedTables = false;
  filteredStatuses: string[] = [];
  statuses: Choice[];
  campaignsFilter: any[];
  defaultStatuses = ['ACTIVE', 'PENDING', 'STARTED'];
  programId: number;
  promotionId: number;
  defaultStatusFilter = { statuses: this.defaultStatuses };
  sponsorKeyword = '';
  promotionTypes = {};
  colorCnt = -1;
  colors = [
    'promotion-type-green',
    'promotion-type-red',
    'promotion-type-blue',
    'promotion-type-purple',
    'promotion-type-lightgreen',
    'promotion-type-cyan',
    'promotion-type-beige',
    'promotion-type-yellow',
    'promotion-type-grey',
    'promotion-type-lavender',
    'promotion-type-lemon',
    'promotion-type-pink',
  ];
  statusHelper: any[];
  faChevronUp = faChevronUp;
  faPencilAlt = faPencilAlt;
  activeId: number = 1;

  constructor(
    private route: ActivatedRoute,
    private modalHelper: NgbModal,
    private title: Title,
    private promotionTypeService: PromotionTypeService,
    private statusHelperService: StatusHelperService,
    private toastrService: ToastrService,
    private globalEventService: GlobalEventService,
    private promotionService: PromotionService,
    private programService: ProgramService,
    private tabTracker: PromotionsTabTackerService
  ) {}

  ngOnInit(): void {
    this.promotion.status = '';
    this.promotion.typeName = '';
    this.title.setTitle('Promotions');
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.tabTracker.selectedTab.subscribe((value) => {
      var activeId = 1;
      if (value) {
        activeId = value;
      }
      this.activeId = activeId;
    });
    this.route.params.subscribe((params: any) => {
      this.route.queryParams.subscribe((queryParams: any) => {
        this.promotion.id = +params.id;
        this.programId = this.promotion.id;
        this.promotionId = +queryParams.promoId;
        this.getProgramStructure();
      });
    });
  }

  private getProgramStructure() {
    this.programService.getProgramStructure(this.programId, { column: 0, dir: 'ASC' }).subscribe((data: any) => {
      if (data.success) {
        this.programStructure = data.entity;
        this.selectedPromotion = data.entity.promotions[0];
        this.getPromotionTypesForProgram();
        if (this.promotionId) {
          this.programStructure.promotions.forEach((promo: any) => {
            if (promo.id === this.promotionId) {
              this.selectPromotion(promo, null);
            }
            if (promo.children && promo.children.length > 0) {
              this.checkChildren(promo);
            }
          });
        }
        this.getProgramPackages();
        this.getProgram(this.programId);
      }
    });
  }

  getAllPromotionTypes(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.promotionTypeService.getAllPromotionTypes(this.defaultStatusFilter).subscribe((data: any) => {
        if (data.success) {
          resolve(data.entity.aaData);
        } else {
          reject([]);
        }
      });
    });
  }

  getProgramPromotions(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.programService.getProgramPromotions(this.programStructure.id).subscribe((data: any) => {
        if (data.success) {
          resolve(data.entity);
        } else {
          reject([]);
        }
      });
    });
  }

  promotionModal(id?: number): void {
    Promise.all([this.getProgramPromotions(), this.getAllPromotionTypes()]).then((result: any) => {
      const [promotions, types] = result;
      if (id) {
        this.promotionService.getPromotion(id).subscribe((promotion: any) => {
          if (promotion.success) {
            this.openPromotionModal(promotions, types, this.programId, promotion.entity, true);
          }
        });
      } else {
        this.openPromotionModal(promotions, types, this.programId);
      }
    });
  }

  openPromotionModal(
    promotions: Promotion[],
    types: any[],
    programId: number,
    promotion?: Promotion,
    isEdit?: boolean
  ): void {
    const instance = this.modalHelper.open(PromotionModalComponent);
    instance.componentInstance.promotions = promotions;
    instance.componentInstance.types = types;
    instance.componentInstance.promotion = promotion;
    instance.componentInstance.isEdit = isEdit;
    instance.componentInstance.programId = programId;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.getProgramStructure();
        this.toastrService.success(`Promotion successfully ${isEdit ? 'updated' : 'created'}`);
        instance.close();
      }
    });
  }

  packageModal(id?: number): void {
    if (id) {
      this.programService.getProgramPackage(id).subscribe((data: any) => {
        if (data.success) {
          this.openPackagesModal(true, this.programId, data.entity);
        }
      });
    } else {
      this.openPackagesModal(false, this.programId);
    }
  }

  openPackagesModal(isEdit: boolean, programId: number, pac?: Package): void {
    const instance = this.modalHelper.open(PackageModalComponent);
    instance.componentInstance.isEdit = isEdit;
    instance.componentInstance.programId = programId;
    instance.componentInstance.package = pac;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.getProgramPackages();
        this.toastrService.success(`Package successfully ${isEdit ? 'updated' : 'created'}`);
        instance.close();
      }
    });
  }

  selectPromotion(promo: Promotion, event?: any): void {
    this.selectedPromotion = promo;
    this.sendDataBreadCrums();
    if (event) {
      event.stopPropagation();
    }
  }

  private sendDataBreadCrums() {
    const dataToSend: any = {};
    dataToSend.data = { promotionId: this.selectedPromotion.id, programId: this.programId };
    dataToSend.toComponent = 'bread-crumbs';
    this.globalEventService.sendGlobalEvent(dataToSend);
  }

  setPromotions(packageToSet): void {
    this.selectedPackage = packageToSet;
    const dataToSend: any = {};
    dataToSend.data = { promotionId: '', programId: this.programId };
    dataToSend.toComponent = 'bread-crumbs';
    this.globalEventService.sendGlobalEvent(dataToSend);
    const params: any = {
      column: 0, // sort by promotion id
      dir: 'ASC',
    };
    if (this.selectedPackage && this.selectedPackage.id) {
      params.packageId = this.selectedPackage && this.selectedPackage.id;
    }
    this.programService.getProgramStructure(this.programId, params).subscribe((data: any) => {
      this.programStructure = data.entity;
      this.getPromotionTypesForProgram();
    });
  }

  getPromotionTypesForProgram(): void {
    if (this.programStructure && this.promotionTypes) {
      this.programStructure.promotions.forEach((promo: any) => {
        // first time we see a type it goes to a map:
        if (this.promotionTypes[promo.typeName]) {
          promo.typeColor = this.promotionTypes[promo.typeName];
        } else {
          this.promotionTypes[promo.typeName] = this.getNextColor();
          promo.typeColor = this.promotionTypes[promo.typeName];
        }
        if (promo.children && promo.children.length > 0) {
          this.assignColorToChildren(promo);
        }
      });
    }
  }

  getNextColor(): string {
    this.colorCnt++;
    if (this.colorCnt === this.colors.length) {
      this.colorCnt = 0;
    }
    return this.colors[this.colorCnt];
  }

  assignColorToChildren(promo: Promotion): void {
    promo.children.forEach((child: any) => {
      // first time we see a type it goes to a map:
      if (this.promotionTypes[child.typeName]) {
        child.typeColor = this.promotionTypes[child.typeName];
      } else {
        this.promotionTypes[child.typeName] = this.getNextColor();
        child.typeColor = this.promotionTypes[child.typeName];
      }
      if (child.children && child.children.length > 0) {
        this.assignColorToChildren(child);
      }
    });
  }

  checkChildren(promo: Promotion): void {
    promo.children.forEach((child: any) => {
      if (child.id === this.promotionId) {
        this.selectPromotion(child);
      }
      if (child.children && child.children.length > 0) {
        this.checkChildren(child);
      }
    });
  }

  getProgramPackages(): void {
    this.programService.getProgramPackages(this.programStructure.id, {}).subscribe((data: any) => {
      if (data.success) {
        this.programPackages = data.entity;
      }
    });
  }

  getProgram(id: number): void {
    this.programService.getProgram(id).subscribe((data: any) => {
      if (data.sucess) {
        this.program = data.entity;
      }
    });
  }

  promotionChanged(event: Promotion): void {
    console.log('clicked');
    if (this.selectedPackage) {
      if (event.hasSelectedPackage) {
        console.log('if working');
        this.selectedPromotion = event;
        this.sendDataBreadCrums();
      }
    } else {
      this.selectedPromotion = event;
      this.sendDataBreadCrums();
    }
  }
}
