import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationType, EndpointType } from '../data/class';
import { Communication, DeliveryProtocol, Segment } from '../data/model';
import { CommunicationsService } from '../services/communications.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';

@Component({
  selector: 'app-communications-modal',
  templateUrl: './communications-modal.component.html',
  styleUrls: ['./communications-modal.component.scss'],
})
export class CommunicationsModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public communication: Communication;
  @Input() public potentialParents: Communication[];
  @Input() public selectedParent: Communication;
  @Input() public fromParentCommunication: boolean;
  title: string;
  heading: string;
  isEdit: boolean;
  communicationForm: FormGroup;
  filteredSegments = new Array<Segment>();
  deliveryProtocols: DeliveryProtocol[];
  selectedDeliveryProtocol: DeliveryProtocol;
  commTypes: CommunicationType[];
  endpointTypes: EndpointType[];
  selectedSegments = [];
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
    private statusHelperService: StatusHelperService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.communication) {
      const deliveryProtocol = this.communication.communicationDeliveryProtocols[0].deliveryProtocol;
      this.selectedDeliveryProtocol = deliveryProtocol;
      this.title = 'Outreach plan';
      this.heading = 'Edit communication';
      this.isEdit = true;
      this.communicationForm = this.formBuilder.group({
        name: [this.communication.name, Validators.required],
        endpointType: [deliveryProtocol.endpointType.name, Validators.required],
        deliveryProtocol: [deliveryProtocol.id, Validators.required],
        commTypeId: [
          this.communication.communicationType && this.communication.communicationType.id,
          Validators.required,
        ],
        status: [this.communication.status, Validators.required],
        parentId: [this.communication.parentId, this.isFieldRequired()],
        requirePreference: [this.communication.requirePreference || false],
      });

      if (
        this.communication.communicationDeliveryProtocols[0] &&
        this.communication.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name !== ''
      ) {
        this.getDeliveryProtocols(
          this.communication.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name
        );
      }
      this.getCommunicationTypes();
    } else {
      if (this.selectedParent) {
        this.title = `Parent Outreach - ${this.selectedParent.name}`;
        this.communicationForm = this.formBuilder.group({
          name: ['', Validators.required],
          endpointType: ['', Validators.required],
          deliveryProtocol: [[], Validators.required],
          commTypeId: [null, Validators.required],
          status: ['ACTIVE', Validators.required],
          parentId: [this.selectedParent ? this.selectedParent.id : '', this.isFieldRequired()],
          requirePreference: [true],
        });
        this.getCommunicationTypes(false);
      } else {
        this.title = 'Outreach plan';
        this.communicationForm = this.formBuilder.group({
          name: ['', Validators.required],
          endpointType: ['INIT', Validators.required],
          deliveryProtocol: [[], false],
          commTypeId: [null, Validators.required],
          status: ['ACTIVE', Validators.required],
          parentId: [this.selectedParent ? this.selectedParent.id : '', this.isFieldRequired()],
          requirePreference: [true],
        });
        this.getDeliveryProtocols('INIT', true);
        this.getCommunicationTypes(true);
      }

      this.heading = 'Add communication';
      this.isEdit = false;
    }

    this.communicationForm.get('endpointType').valueChanges.subscribe((endpointType: string) => {
      this.communicationForm.patchValue({ deliveryProtocol: null });
      this.getDeliveryProtocols(endpointType);
    });

    this.communicationsService.getAllEndpointTypes(false).subscribe((data: any) => {
      if (data.success) {
        this.endpointTypes = data.entity;
        if (this.selectedParent) {
          const index = this.endpointTypes.findIndex((item) => item.name === 'INIT');
          if (index >= 0) {
            delete this.endpointTypes[index];
            //this.endpointTypes.splice(index, 1);
          }
        }
      }
    });
  }

  isFieldRequired(): any {
    if (this.fromParentCommunication) {
      return Validators.required;
    } else {
      return false;
    }
  }

  getCommunicationTypes(isNewOutreachPlan?: boolean): void {
    this.communicationsService.getCommunicationTypes().subscribe((data: any) => {
      if (data.success) {
        this.commTypes = data.entity;
        if (isNewOutreachPlan) {
          const index = this.commTypes.findIndex((item) => item.name === 'PROGRAM');
          if (index >= 0) {
            this.communicationForm.controls.commTypeId.setValue(this.commTypes[index].id);
          }
        }
      }
    });
  }

  getDeliveryProtocols(endpointTypeName: string, isOutreachPlan?: boolean): void {
    if (endpointTypeName) {
      this.communicationsService
        .getDeliveryProtocols({ endpointTypeName, includeInit: true })
        .subscribe((data: any) => {
          if (data.success) {
            this.deliveryProtocols = data.entity;
            if (isOutreachPlan) {
              const index = this.deliveryProtocols.findIndex((item) => item.name === 'INIT');
              if (index >= 0) {
                this.selectedDeliveryProtocol = this.deliveryProtocols[index];
                this.communicationForm.controls.deliveryProtocol.setValue(this.selectedDeliveryProtocol);
              }
            }
          }
        });
    }
  }

  refreshDeliveryProtocols(): void {
    if (this.communication) {
      this.communication.communicationDeliveryProtocols[0].deliveryProtocol.id = null;
    }
    this.getDeliveryProtocols(this.communicationForm.value.endpointType);
  }

  ok(): void {
    let formValue = this.communicationForm.value;

    formValue = { ...this.communication, ...formValue };
    formValue.communicationDeliveryProtocols = [
      {
        deliveryProtocol: this.selectedDeliveryProtocol,
        /*{
          endpointType: { name: this.communicationForm.controls.endpointType.value },
          id: this.communicationForm.controls.deliveryProtocol.value,
        }*/ isDefault: false,
      },
    ];
    delete formValue.endpointType;
    delete formValue.deliveryProtocol;
    this.communicationsService
      .createOrUpdateCommunications(this.communication && this.communication.id, formValue)
      .subscribe(
        (data) => {
          var message;
          if (this.communication) {
            message = 'Communication successfully updated';
          } else {
            message = 'Communication successfully created';
          }
          this.responseHelperService.success(message, true);
          this.successEvent.emit(data.entity);
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error, false);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
