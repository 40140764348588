import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  createSelectedOrgAuditRecord(orgAudit: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'audit/select-organization', orgAudit);
  }
}
