<form>
  <div class="com-report-modal">
    <div>
      <span class="title">Reports results </span>
      <hr class="mb-0" />
      <!-- <h1>ID {{ execution.id }} - {{ execution.reportName }}</h1> -->
    </div>
    <div class="mb-3 mt-3">
      <span class="com-title">Report parameters</span>
    </div>
    <div class="">
      <div class="form-group">
        <ul class="list-group activity-list">
          <li class="list-group-item first-item pt-0 pb-0">
            <div class="row">
              <div class="table mr-1 mb-0">
                <table class="table table-pointable table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr [hidden]="!reportParams || !reportParams[0]" *ngFor="let param of reportParams">
                      <td class="word-break-break-all">{{ param.substr(0, param.indexOf("=")) }}</td>
                      <td class="break-normal">{{ param.substring(param.indexOf("=") + 1) }}</td>
                    </tr>
                    <tr *ngIf="reportParams.length === 0 || (reportParams.length === 1 && reportParams[0] === '')">
                      <td>No parameters used</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="pop-btn-container">
      <button class="btn-pop-cancel" type="button" (click)="cancel()">cancel</button>
    </div>
  </div>
</form>
