import React, { useEffect } from "react";
import TextField from "@iridium/iridium-ui/TextField";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@iridium/iridium-ui/Grid";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@iridium/iridium-ui/Button";
import IconButton from "@mui/material/IconButton";
import {
  ADD_NEW_ORGANIZATION_TEST_ID,
  ORGANIZATION_SEARCH_INPUT_TEST_ID,
  FILTER_ORGANIZATIONS_TEST_ID,
  ORGANIZATION_SEARCH_SUBMIT_TEST_ID,
} from "../../constants";
import { GetAllHierarchicalOrganizationsForParentParams } from "../../../services/organizations.service";
import { OrganizationListFilter } from "../../Organizations";
import { FilterChips } from "../FilterChips";

type TableToolbarProps = {
  addButtonEntityName: string;
  filter: OrganizationListFilter;
  setFilter: (params: Partial<GetAllHierarchicalOrganizationsForParentParams>) => void;
  onClearFilters: () => void;
  onFilterOpen?: () => void;
  onSearchChange?: (value: string) => void;
  onAddOrganizationClick: () => void;
};

export function TableToolbar({
  onSearchChange,
  onFilterOpen,
  onAddOrganizationClick,
  setFilter,
  filter,
  onClearFilters,
  addButtonEntityName,
}: TableToolbarProps) {
  const [localKeyword, setLocalKeyword] = React.useState(filter.keyword);

  const { keyword, statuses } = filter;

  useEffect(() => {
    setLocalKeyword(filter.keyword);
  }, [filter.keyword]);

  function handleSearchSubmit(event) {
    event.preventDefault();
    setFilter({
      ...filter,
      keyword: localKeyword,
    });
  }

  function handleFilterDelete(key, value) {
    const filterValue = filter[key];
    let newFilterValue: string | string[] = "";

    if (Array.isArray(filterValue)) {
      newFilterValue = filterValue.filter((valueInList) => valueInList !== value);
    }

    setFilter({
      ...filter,
      [key]: newFilterValue,
    });
  }

  const chipFilters = {
    keyword,
    statuses,
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs={6} sx={{ marginTop: "2px !important" }} textAlign="left">
        <form onSubmit={handleSearchSubmit}>
          <TextField
            size="small"
            aria-label="Search organizations"
            id="searchOrganizations"
            placeholder="Search"
            value={localKeyword}
            type="text"
            onChange={(event) => setLocalKeyword(event.target.value)}
            InputProps={{
              inputProps: {
                "data-testid": ORGANIZATION_SEARCH_INPUT_TEST_ID,
              },
              endAdornment: (
                <IconButton
                  onClick={() =>
                    setFilter({
                      ...filter,
                      keyword: localKeyword,
                    })
                  }
                  data-testid={ORGANIZATION_SEARCH_SUBMIT_TEST_ID}
                >
                  <SearchIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </form>
      </Grid>
      <Grid item xs={6}>
        <Grid container columnSpacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={onFilterOpen}
              variant="outlined"
              endIcon={<FilterListIcon />}
              data-testid={FILTER_ORGANIZATIONS_TEST_ID}
            >
              FILTERS
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={onAddOrganizationClick} data-testid={ADD_NEW_ORGANIZATION_TEST_ID}>
              ADD {addButtonEntityName}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={2} mb={2}>
        <Grid item xs={12}>
          <FilterChips
            filter={chipFilters}
            onFilterDelete={handleFilterDelete}
            onClearAllFilters={onClearFilters}
            filterChipPropsLookup={{
              statuses: {
                label: "Status",
                onDelete: handleFilterDelete,
              },
              keyword: {
                label: "Keyword",
                onDelete: handleFilterDelete,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TableToolbar;
