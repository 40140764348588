import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommunicationStrategy } from '../data/model';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { CommunicationsService } from '../services/communications.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';

@Component({
  selector: 'app-communication-strategy-modal',
  templateUrl: './communication-strategy-modal.component.html',
  styleUrls: ['./communication-strategy-modal.component.scss'],
})
export class CommunicationStrategyModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public communicationStrategy: CommunicationStrategy;
  title: string;
  isEdit: boolean;
  communicationStrategyForm: FormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  priority = ['HIGH', 'MEDIUM', 'LOW'];
  comm: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private statusHelperService: StatusHelperService,
    private communicationsService: CommunicationsService,
    private communicationStrategyService: CommunicationStrategyService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.communicationStrategy) {
      this.title = 'Strategy';
      this.isEdit = true;
      this.communicationStrategyForm = this.formBuilder.group({
        name: [this.communicationStrategy.name, Validators.required],
        priority: [this.communicationStrategy.priority, Validators.required],
        activityRewardId: [this.communicationStrategy.activityRewardId],
        noActivityId: [this.communicationStrategy.noActivityId],
        expiringPromotionsId: [this.communicationStrategy.expiringPromotionsId],
        newPromotionsId: [this.communicationStrategy.newPromotionsId],
      });
    } else {
      this.title = 'Strategy';
      this.isEdit = false;
      this.communicationStrategyForm = this.formBuilder.group({
        name: ['', Validators.required],
        priority: [null, Validators.required],
        activityRewardId: [null],
        noActivityId: [null],
        expiringPromotionsId: [null],
        newPromotionsId: [null],
      });
    }
    this.communicationsService.getCommunications({ statuses: ['ACTIVE', 'PENDING'] }).subscribe((comm) => {
      if (comm.success) {
        this.comm = comm.entity.aaData;
      }
    });
  }

  ok(): void {
    const communicationStrategy = this.communicationStrategyForm.value;
    if (this.isEdit) {
      communicationStrategy.status = this.communicationStrategy.status;
    }
    if (communicationStrategy.activityRewardId === 0) {
      communicationStrategy.activityRewardId = null;
    }
    if (communicationStrategy.expiringPromotionsId === 0) {
      communicationStrategy.expiringPromotionsId = null;
    }
    if (communicationStrategy.newPromotionsId === 0) {
      communicationStrategy.newPromotionsId = null;
    }
    if (communicationStrategy.noActivityId === 0) {
      communicationStrategy.noActivityId = null;
    }
    this.communicationStrategyService
      .createOrUpdateCommunicationStrategy(
        this.communicationStrategy && this.communicationStrategy.id,
        communicationStrategy
      )
      .subscribe(
        () => {
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error, false);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
