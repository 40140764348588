<div class="panel-body">
  <div class="row">
    <div class="col-lg-12" *ngIf="show">
      <div class="row">
        <div class="col-md-12">
          <a
            class="btn btn-sm btn-dark-blue float-right mb-2"
            (click)="campaignModal()"
            title="Adds existing promotion to program"
          >
            <fa-icon [icon]="faCalendarPlus"></fa-icon>
          </a>
        </div>
      </div>
      <div class="row table-campaign">
        <table class="table table-pointable table-bordered infinite-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Name</th>
              <th>Program Cap</th>
              <th>Status</th>
              <th>From</th>
              <th>Thru</th>
              <th>Expiry</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of campaigns">
              <td>{{ c.id }}</td>
              <td>{{ c.code }}</td>
              <td>{{ c.name }}</td>
              <td>{{ c.cap }}</td>
              <td>{{ c.status }}</td>
              <td>{{ c.fromDate }}</td>
              <td>{{ c.thruDate }}</td>
              <td>{{ c.expirationDate }}</td>
              <td>
                <div class="float-left actions inline-table">
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-sm delete action float-right"
                    (click)="removeCampaign(selectedPromSponsor.id, c)"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="9" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getCampaigns(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
