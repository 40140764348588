<div id="jsonModal">
  <div class="modal-header">
    <div class="col-md-8">
      <h1>{{ title }}</h1>
    </div>
    <div class="col-md-4 margin-auto">
      <div *ngIf="paseteError" class="alert alert-danger fade show margin-auto" role="alert">
        Pasted text is not valid json
      </div>
      <div *ngIf="successPaste" class="alert alert-success fade show margin-auto" role="alert">
        Data successfully pasted
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row" id="content-area">
      <textarea
        (paste)="pasetEvent($event)"
        id="contentJson"
        class="form-control form-content"
        style="max-height: 78vh"
        name="json"
        [rows]="rows"
        [(ngModel)]="jsonString"
        (ngModelChange)="setJSON($event)"
      ></textarea>
      <div *ngIf="showErrorMessage" class="alert alert-danger fade show margin-auto json-error-message" role="alert">
        {{ jsonErrorMessage }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="marign-right-auto">
      <button id="copy" class="btn btn-save" type="button" (click)="copy()" title="Copy">COPY</button>
      <button id="paste" class="btn btn-save" type="button" (click)="paste()" title="Paste" *ngIf="pasteSupported">
        PASTE
      </button>
    </div>
    <button id="cancel" class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
    <button id="save" class="btn btn-save" type="button" (click)="ok()" title="Save">SAVE</button>
  </div>
</div>
