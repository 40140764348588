import React, { useCallback, useEffect, useState } from "react";
import { Segment } from "../data/model";
import { PersonsService } from "../services/persons.service";
import { HttpEventType } from "@angular/common/http";
import Menu from "@iridium/iridium-ui/node_modules/@mui/material/Menu/Menu";
import Button from "@iridium/iridium-ui/Button";
import FormGroup from "@iridium/iridium-ui/node_modules/@mui/material/FormGroup";

const MAX_SEGMENT_COUNT = 100;

export type SegmentFilterWidgetProps = {
  personsService: PersonsService;
  personId: number;
  maxWidth?: number;
  formatClass?: string;
  segmentId?: number;
  onSelectedSegmentsChange: (selectedSegmentIds: number[]) => void;
};

type State = {
  isSegmentsInitialized: boolean;
  allSegments: Segment[];
  isSegmentIdSelected: Record<number, boolean>;
  selectedCount: number;
  filtered: Segment[];
  query: string;
};

const defaultState: State = {
  isSegmentsInitialized: false,
  allSegments: [],
  selectedCount: 0,
  isSegmentIdSelected: {},
  filtered: [],
  query: "",
};

export function SegmentFilterWidget({
  personsService,
  personId,
  maxWidth,
  formatClass,
  segmentId,
  onSelectedSegmentsChange,
}: SegmentFilterWidgetProps): JSX.Element {
  maxWidth = maxWidth || 300;
  formatClass = formatClass || "";

  const [state, setState] = useState<State>(defaultState);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCheckboxClick(item: Segment) {
    return function (event: React.ChangeEvent<HTMLButtonElement>) {
      event.preventDefault();
      const updated = { ...item, checked: !item.checked };
      setChildrenToMatchSegmentChecked(updated);
    };
  }

  const open = Boolean(anchorEl);

  const fetchSegmentNodes = useCallback(() => {
    personsService
      .getPersonSegmentList(personId, true)
      .toPromise()
      .then((response) => {
        if (response.type === HttpEventType.Response) {
          let allSegments = personsService.levelSegments(response.body.entity);
          setState({ ...state, allSegments, filtered: allSegments, isSegmentsInitialized: true });
        }
      });
  }, [personsService, personId, setState]);

  useEffect(() => {
    if (!state.isSegmentsInitialized) {
      fetchSegmentNodes();
    }
  }, [state.isSegmentsInitialized, fetchSegmentNodes]);

  useEffect(() => {
    const segmentIds = getSelectedSegmentIds(state.isSegmentIdSelected);
    if (!isTooManySegments(segmentIds)) {
      onSelectedSegmentsChange(segmentIds);
    }
  }, [state.isSegmentIdSelected]);

  function clearAll(): void {
    if (state.allSegments) {
      const allSegments = state.allSegments.map((segment) => ({ ...segment, checked: false }));
      setState({
        ...state,
        allSegments,
        filtered: allSegments,
        isSegmentIdSelected: {},
        query: "",
      });
    }
  }

  function getSelectedSegmentIds(isSegmentIdSelected: Record<number, boolean>): number[] {
    if (segmentId) {
      return [segmentId];
    } else {
      return Object.entries(isSegmentIdSelected)
        .filter((entry) => entry[1])
        .map((entry) => parseInt(entry[0]));
    }
  }

  function isTooManySegments(segmentIds: number[]) {
    return segmentIds.length > MAX_SEGMENT_COUNT;
  }

  function setChildrenToMatchSegmentChecked(model: Segment): void {
    const isSegmentIdSelected = Object.assign({}, state.isSegmentIdSelected);
    updateChildrenToMatchParentChecked(model, isSegmentIdSelected);
    // potential performance improvement here
    const allSegments = state.allSegments.map((segment) => ({
      ...segment,
      checked: !!isSegmentIdSelected[segment.id],
    }));
    const filtered = state.filtered.map((segment) => ({ ...segment, checked: !!isSegmentIdSelected[segment.id] }));
    setState({ ...state, isSegmentIdSelected, allSegments, filtered });
  }

  function updateChildrenToMatchParentChecked(model: Segment, isSegmentIdSelected: Record<HttpEventType, boolean>) {
    state.allSegments.forEach((item) => {
      if (item.id === model.id) {
        isSegmentIdSelected[item.id] = model.checked;
      }
      if (item.parentId === model.id) {
        item.checked = model.checked;
        updateChildrenToMatchParentChecked(item, isSegmentIdSelected);
      }
    });
  }

  function addParents(target: Segment[], child: Segment, segments: Segment[]): void {
    const parent = segments.find((segment) => segment.id === child.parentId);
    if (parent && !target.some((item) => item.id === parent.id)) {
      // potential performance improvement here
      target.push(parent);
      addParents(target, parent, segments);
    }
  }

  function getFiltered(query: string): Segment[] {
    if (!query || query == "") {
      return state.allSegments;
    }
    if (query && query.length > 2) {
      const filtered: Segment[] = [];
      // Add all that have code as filter
      state.allSegments.forEach((segment: Segment) => {
        if (segment.code && segment.code.toUpperCase().indexOf(query.toUpperCase()) !== -1) {
          filtered.push(segment);
          addParents(filtered, segment, state.allSegments);
        }
      });
      return filtered;
    }
    return state.filtered;
  }

  function updateQuery(event: React.ChangeEvent<HTMLInputElement>) {
    const query = event.target.value;
    setState({ ...state, query, filtered: getFiltered(query) });
  }

  function getCheckboxRow(item: Segment) {
    return (
      <div key={`chk_div_${item.id}`}>
        <span key={`lbl_${item.id}`} style={{ paddingLeft: `${3 + 2 * (item.level || 0)}em` }}>
          <input
            type={"checkbox"}
            style={{
              verticalAlign: "middle",
            }}
            key={`seg_${item.id}`}
            id={`seg_${item.id}`}
            name={`seg_${item.id}`}
            onChange={handleCheckboxClick(item)}
            checked={!!item.checked}
          />
          <span style={{ paddingLeft: "5px", fontSize: "13px", fontWeight: "400" }}>{item.name}</span>
        </span>
      </div>
    );
  }

  return (
    <>
      {!segmentId && (
        <div
          // [ngClass]="classValue"
          className="search-filter-fields search-filter-bottom segment-filter mr-sm ml-sm"
        >
          <Button variant="outlined" onClick={handleClick}>{`Select segment (${
            state.allSegments.filter((segment) => segment.checked).length
          })`}</Button>
          <Menu className="segment-filter" open={open} onClose={handleClose} anchorEl={anchorEl}>
            <div
              // ngbDropdownMenu
              aria-labelledby="dropdownSegment"
              className="segment-filter-search-box"
            >
              <input
                type="text"
                placeholder="Type minimum 3 characters to filter"
                value={state.query}
                className="form-control"
                style={{ marginBottom: "5px" }}
                onChange={updateQuery}
              />
              {isTooManySegments(getSelectedSegmentIds(state.isSegmentIdSelected)) && (
                <div>
                  <span style={{ color: "red" }}>Please select fewer than 100 segments</span>
                </div>
              )}
              <div>
                <a className="m-b-xs" onClick={clearAll}>
                  Clear all
                </a>
                <span
                  className="float-right"
                  // </div>/*ngIf="toFilter.length > 0 &amp;&amp; query.length > 0"
                >
                  <i>Some segments may be filtered...</i>
                </span>
              </div>
              <div className="segment-filter-container">
                <FormGroup>
                  {state.filtered.map((item) => {
                    return getCheckboxRow(item);
                  })}
                </FormGroup>
              </div>
            </div>
          </Menu>
        </div>
      )}
    </>
  );
}
