<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-9">
      <div class="ibox">
        <div class="ibox-content">
          <h2>Partners Management</h2>
          <div class="row">
            <div class="col-md-12">
              <div>
                <div *ngIf="show" class="row">
                  <app-search-filter class="w-100" [searchFilters]="searchFilters"></app-search-filter>
                </div>
                <div class="row table-partners">
                  <table class="table table-pointable table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let partner of partners; let i = index" (click)="showPartner($event, partner)">
                        <td>
                          <a routerLink="/partners/{{ partner.id }}">{{ partner.id }}</a>
                        </td>
                        <td>{{ partner.name }}</td>
                        <td>{{ partner.status }}</td>
                        <td>
                          <span *ngFor="let role of partner.partyRoleTypes">
                            {{ role.roleName }}
                            <span *ngIf="i != partners.length - 1"> </span>
                          </span>
                        </td>
                      </tr>
                      <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list
                  (onScroll)="getPartners(true)"
                  [lock]="lock"
                  [isLoading]="isLoading"
                ></app-add-more-data-to-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="selectedPartner">
      <div class="ibox">
        <div class="row integration-details-bar"></div>
        <div class="ibox-content">
          <div id="contact-1" class="tab-pane active">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 relative-position">
                    <div>
                      <span class="fa-stack fa-3x">
                        <fa-layers [fixedWidth]="true">
                          <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
                          <fa-icon
                            [inverse]="true"
                            [icon]="faHandshake"
                            transform="shrink-8"
                            stackItemSize="2x"
                          ></fa-icon>
                        </fa-layers>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-12 form-group float-right">
                        <span
                          id="status-label"
                          class="badge float-right"
                          [ngClass]="statusHelper[selectedPartner.status]"
                          >{{ selectedPartner.status }}</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 float-left">
                        <h2 class="overflow-wrap-break-word">
                          <fa-icon [icon]="faUser"></fa-icon>{{ selectedPartner.username }}
                        </h2>
                        <p [hidden]="!selectedPartner.email?.emailAddress">
                          <fa-icon [icon]="faEnvelope"></fa-icon>
                          {{ selectedPartner.email?.emailAddress }}
                        </p>
                        <p [hidden]="!selectedPartner.url">
                          <fa-icon [icon]="faGlobe"></fa-icon
                          ><a href="{{ selectedPartner.url }}"> {{ selectedPartner.url }}</a>
                        </p>
                        <p [hidden]="!selectedPartner.role?.roleName">
                          <fa-icon [icon]="faUserTag"></fa-icon>
                          {{ selectedPartner.role?.roleName }}
                        </p>
                        <p [hidden]="!selectedPartner.role?.roleName">
                          <fa-icon [icon]="faCalendarCheck"></fa-icon>
                          {{ selectedPartner.yearStart }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 form-group float-right">
                <a
                  routerLink="/partners/{{ selectedPartner.id }}"
                  class="btn btn-dark-blue btn-sm float-right"
                  title="View"
                >
                  <fa-icon [icon]="faEye"></fa-icon>
                </a>
              </div>
            </div>
            <div class="client-detail">
              <div class="full-height-scroll">
                <div *ngIf="selectedPartner.attrs?.length">
                  <strong>Attributes</strong>
                  <ul class="list-group clear-list">
                    <li
                      *ngFor="let at of selectedPartner.attrs; let i = index"
                      class="list-group-item"
                      [ngClass]="{ 'fist-item': i == 0 }"
                    >
                      <span class="float-right"> {{ at.attrValue }}</span> {{ at.attrName }}
                    </li>
                  </ul>
                </div>

                <div *ngIf="selectedPartner.segments?.length">
                  <strong>Segments</strong>
                  <ul class="list-group clear-list">
                    <li
                      *ngFor="let sg of selectedPartner.segments; let i = index"
                      class="list-group-item"
                      [ngClass]="{ 'fist-item': i == 0 }"
                    >
                      <span class="float-right"> {{ sg.status }}</span> {{ sg.code }}
                    </li>
                  </ul>
                </div>

                <div *ngIf="selectedPartner.notes" class="member-notes">
                  <strong>Notes</strong>
                  <p>
                    {{ selectedPartner.notes }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div>
</div>
