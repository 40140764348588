import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { AllowedContactInfo } from '../../data/model';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, faTimes, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { CommunicationAllowlistService } from 'src/app/services/communication-allowlist.service';
import { ToastrService } from 'ngx-toastr';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { CommunicationAllowlistModalComponent } from '../../modals/communication-allowlist-modal/communication-allowlist-modal.component';
import { ApplyFilterService } from '../../services/filter-pop-service/applyFilter.service';
import { SearchFilterComponent } from '../../search-filter/search-filter/search-filter.component';

@Component({
  selector: 'app-communication-allowlist',
  templateUrl: './communication-allowlist.component.html',
  styleUrls: ['./communication-allowlist.component.scss'],
})
export class CommunicationAllowlistComponent implements OnInit, OnDestroy {
  lockedTables = false;
  isLoading: boolean;
  lock: boolean;
  filteredStatuses: any[] = [];
  filter = new Filter();
  limit = 20;
  statuses: Choice[];
  allowedContactInfoArray: AllowedContactInfo[] = [];
  hierarchySources: any[] = [];
  lengthToCompare: number;
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  filterdata: SearchFilters;
  filterButton: boolean = false;
  appliedFilters: Filter[];
  searchFilters: SearchFilters;
  searchText: any;
  helpers: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private communicationAllowlistService: CommunicationAllowlistService,
    private modalHelper: NgbModal,
    private responseHelper: ResponseHelperService,
    private toastr: ToastrService,
    private applyfilter: ApplyFilterService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'allowed-contacts-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'type',
          placeholder: 'Type',
          type: 'array',
          choices: [{ value: 'PHONE' }, { value: 'EMAIL' }],
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'Add',
          if: true,
          callback: () => {
            this.openModal();
          },
          title: 'Add Contact Info to the Allow List',
        },
      ],
    };
    this.initNewSearch();
    this.show = true;
  }

  initNewSearch(): void {
    this.applyfilter.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.initFilter();
    this.getAllowedContactInfo(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getAllowedContactInfo(false);
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Type';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
      this.filterButton = true;
    });
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getAllowedContactInfo(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.communicationAllowlistService.getAllowlist(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.allowedContactInfoArray = this.allowedContactInfoArray.concat(data.entity);
        } else {
          this.allowedContactInfoArray = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.isLoading = false;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occurred!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  deleteAllowedContactInfo(contactInfo: AllowedContactInfo): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Remove ' + contactInfo.value + ' from the Allow List';
    instance.componentInstance.header = 'Remove Allowed Contact?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.communicationAllowlistService.deleteFromAllowlist(contactInfo.id).subscribe(
        (data: any) => {
          this.responseHelper.success('Contact Info successfully removed from the Allow List.', true);
          this.initNewSearch();
        },
        (data: any) => {
          this.responseHelper.error(this, data.error.error, true);
        }
      );
    });
  }

  openModal(): void {
    const instance = this.modalHelper.open(CommunicationAllowlistModalComponent);
    instance.componentInstance.successEvent.subscribe((data) => {
      if (data.success) {
        this.toastr.success(`Contact info successfully added to the Allow List.`);
        this.initNewSearch();
      }
    });
  }
}
