import { Injectable } from '@angular/core';
import { gql, Apollo } from 'apollo-angular';
import getLogger from './logging/logging.service';
import { CallFlow, InstructionWavUrl } from '../call-flow-builder/models/model';

const logger = getLogger({ namespace: 'CallFlowQueryService' });
const getCallFlowByIdQuery = gql`
  query CallFlowById($id: String) {
    viewer {
      callFlows(id: $id) {
        id
        callFlowSchemaVersion
        callEventHeaders {
          revelTargetPlatform
        }
        contextAttributes
        masterSurveyId
        name
        voiceVendorType
        memberInformationSource
        programId
        supportedLanguages
        supportedDispositions
        supportedThemes
        programId
        tenantId
        outboundIntroInstructionId
        inboundIntroInstructionId
        unexpectedErrorInstructionId
        voiceMailInstructionId
        outsideTransferWindowInstructionId
        consecutiveNoInputFailureThreshold
        transferHoursEnforced
        transferHoursTimeZone
        transferHoursInactiveDates
        transferHours
        callRecordingLevel
        callRecordingPercentage
        verifyAudioFilesExist
        optOutEnforcementEnabled
        defaultManagedPhoneNumberId
        managedPhoneNumbers {
          id
          phoneNumberE164
          defaultInitialCallState {
            language
            disposition
            sex
            theme
          }
        }
        voiceXmlSettings {
          answeringMachineDetectionSettings {
            finalSilenceInMilliseconds
            timeoutInMilliseconds
            maxTimeInMilliseconds
          }
          inputInstructionSettings {
            id
            confidenceLevel
            sensitivity
            speedVersusAccuracy
            completeTimeoutInMilliseconds
            incompleteTimeoutInMilliseconds
            maxSpeechTimeoutMilliseconds
            timeoutInMilliseconds
            interDigitTimeoutInMilliseconds
            termTimeoutInMilliseconds
            termMaxDigits
          }
        }
        instructions {
          description
          nextInstructionId
          instructionType
          skippedDispositions
          skippedSexes
          skipWhenMemberPhoneNumberRecognized
          invalidInputInstructionId
          voiceXmlInputInstructionSettingsId
          bargeable
          id
          platformMetaData {
            masterCatalogueId
            tags
          }
          instructionVariants {
            language
            utteranceType
            dispositions
            sexes
            theme
            prompts {
              type
              personalizationInformationType
              text
              sayAs
              audioFile
            }
          }
          expectedResponses {
            id
            nextInstructionId
            customDigitUtteranceSet
            customVoiceUtteranceSet {
              language
              utteranceSet
            }
            staticResponseType
            callUpdateValue
            platformMetaData {
              masterCatalogueId
              tags
            }
          }
          retryAttempts {
            bargeable
            instructionVariants {
              language
              utteranceType
              dispositions
              sexes
              theme
              prompts {
                text
                audioFile
                sayAs
                type
                personalizationInformationType
              }
            }
          }
          answersToVerify {
            dateOfBirth
            phoneNumber
          }
          verifyIdentityFailedInstructionId
          verificationType
          verificationDataType
          description
        }
      }
    }
  }
`;
// const getCallFlowByIdQuery = gql`
//   query CallFlowById($id: String) {
//     viewer {
//       callFlows(id: $id) {
//         id
//         masterSurveyId
//         memberInformationSource
//         programId
//         name
//         instructions {
//           description
//           skipWhenMemberPhoneNumberRecognized
//           id
//           platformMetaData {
//             masterCatalogueId
//             tags
//           }
//           instructionVariants {
//             language
//             dispositions
//             sexes
//             theme
//             prompts {
//               type
//               personalizationInformationType
//               text
//               audioFile
//             }
//           }
//           nextInstructionId
//           expectedResponses {
//             id
//             nextInstructionId
//             customDigitUtteranceSet
//             platformMetaData {
//               masterCatalogueId
//               tags
//             }
//           }
//           optOutResponseId
//           instructionType
//         }
//       }
//     }
//   }
// `;
type GetCallFlowByIdResponse = { viewer: { callFlows: CallFlow[] } };

const getInstructionWavUrlQuery = gql`
  query CallFlowById($id: String, $fileName: String) {
    viewer {
      callFlowInstructionWavs(id: $id, fileName: $fileName) {
        callFlowId
        internalFileName
        signedUrl
        signedUrlExpiry
        programId
      }
    }
  }
`;

type GetInstructionWavUrlResponse = { viewer: { callFlowInstructionWavs: InstructionWavUrl[] } };

@Injectable({
  providedIn: 'root',
})
export class CallFlowQueryService {
  constructor(private apollo: Apollo) {}

  stripTypeName(object: any) {
    const typename = '__typename';

    if (!object) {
      return object;
    }
    if (Array.isArray(object)) {
      return object.map((item) => (typeof item === 'object' ? this.stripTypeName(item) : item));
    }
    const keys = Object.keys(object);
    return keys
      .filter((key) => key !== typename)
      .reduce((result, next) => {
        result[next] = typeof object[next] == 'object' ? this.stripTypeName(object[next]) : object[next];
        return result;
      }, {});
  }

  async getCallFlowById(callFlowId: string): Promise<CallFlow> {
    return this.apollo
      .query<GetCallFlowByIdResponse>({
        query: getCallFlowByIdQuery,
        variables: { id: callFlowId },
      })
      .toPromise()
      .then((response) => {
        const stripped = this.stripTypeName(response.data.viewer.callFlows[0]) as CallFlow;
        return stripped;
      });
  }

  async getInstructionWavUrl(callFlowId: string, fileName: string): Promise<string> {
    return this.apollo
      .query<GetInstructionWavUrlResponse>({
        query: getInstructionWavUrlQuery,
        variables: { id: callFlowId, fileName: fileName },
      })
      .toPromise()
      .then((response) => {
        const wavWrapper = response?.data?.viewer?.callFlowInstructionWavs[0];
        const result = wavWrapper?.signedUrl;
        if (!result) {
          console.log(`Could not get signed URL.  Response was ${JSON.stringify(response?.data)}`);
        }
        return result || '';
      });
  }
}
