import React from "react";
import Grid from "@iridium/iridium-ui/Grid";
import { Typography } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CallFlow } from "../models/model";

function optOutTable(callflow: CallFlow) {
  const optOutInstruction = callflow.instructions.filter((instruction) => {
    return instruction.id === "OPTOUT";
  });

  return (
    <TableContainer sx={{ bgcolor: "background.paper", overflow: "hidden" }}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "34%" }}>Key</TableCell>
            <TableCell sx={{ width: "33%" }}>Value</TableCell>
            <TableCell sx={{ width: "33%" }}>Expected</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={"optoutresponseid-row"}>
            <TableCell>
              <Typography variant="body1">optOutResponseId</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                {optOutInstruction[0]?.optOutResponseId === "OPTOUTYES" ? (
                  <span style={{ color: "green" }}>{optOutInstruction[0]?.optOutResponseId}</span>
                ) : (
                  <span style={{ color: "red" }}>{optOutInstruction[0]?.optOutResponseId}</span>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">OPTOUTYES</Typography>
            </TableCell>
          </TableRow>
          <TableRow key={"optoutinstructiontype-row"}>
            <TableCell>
              <Typography variant="body1">instructionType</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">
                {optOutInstruction[0]?.instructionType === "OPT_OUT" ? (
                  <span style={{ color: "green" }}>{optOutInstruction[0]?.instructionType}</span>
                ) : (
                  <span style={{ color: "red" }}>{optOutInstruction[0]?.instructionType}</span>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1">OPT_OUT</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function OptOutTable(props: { callflow: CallFlow }) {
  const { callflow } = props;
  return (
    <Typography variant="body1">
      optOutEnforcementEnabled:{" "}
      {callflow?.optOutEnforcementEnabled ? (
        <>
          <span style={{ color: "green" }}>TRUE</span>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography variant="body1">Opt Out Tags</Typography>
            {optOutTable(callflow)}
          </Grid>
        </>
      ) : (
        <span style={{ color: "orange" }}>FALSE</span>
      )}
    </Typography>
  );
}
