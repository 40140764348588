import { JwtHelperService } from '@auth0/angular-jwt';

const jwtHelperService = new JwtHelperService();

const TOKEN_KEY = 'token';

type Token = {
  sub: string;
  agent: null;
  segmentTree: string[];
  roles: string[];
  iss: string;
  partyExtensions: string[];
  sessionId: string;
  segments: string[];
  aud: string;
  nbf: number;
  exp: number;
  partyId: number;
  iat: number;
  jti: string;
  segmentIds: string[];
};

export function getToken(): string {
  return window.localStorage.getItem(TOKEN_KEY);
}

export function getDecodedToken(): Token {
  const token = getToken();
  return jwtHelperService.decodeToken(token);
}
