import { ChevronList, InstanceAttributeBindings } from './../../data/class';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { faPlus, faTimes, faChevronUp, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-binding',
  templateUrl: './binding.component.html',
  styleUrls: ['./binding.component.scss'],
})
export class BindingComponent {
  @Input() bindings: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  faPlus = faPlus;
  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTrash = faTrash;
  openFieldChevronList: ChevronList = new ChevronList();

  constructor() {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  initBindingObject(): void {
    const tmpObj = new InstanceAttributeBindings();

    if (this.bindings) {
      this.bindings.push(tmpObj);
    } else {
      this.bindings = [];
      this.bindings.push(tmpObj);
    }
  }

  updateBindings(): void {
    this.bindings.forEach((binding) => {
      binding.bindingName = binding.bindingName?.trim();
      binding.bindingValue = binding.bindingValue?.trim();
    });
    this.debouncer.next(this.bindings);
  }

  removeBinding(restriction: any): void {
    this.bindings.splice(this.bindings.indexOf(restriction), 1);
    this.updateBindings();
  }
}
