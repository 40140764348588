import React, { FormEvent, FormEventHandler, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Grid from "@iridium/iridium-ui/Grid";
import { CallFlow } from "../models/model";
import IconButton from "@iridium/iridium-ui/Button/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";

export type JSONDialogProps = {
  title?: string;
  isJSONModalOpen: boolean;
  callflow: CallFlow;
  setIsJSONModalOpen: (boolean) => void;
  setCallflow: (CallFlow) => void;
  onJSONFormSubmit: (CallFlowJSON) => void;
};

function JSONDialog({
  isJSONModalOpen,
  callflow,
  setIsJSONModalOpen,
  onJSONFormSubmit,
  setCallflow,
  title = "JSON Data",
}: JSONDialogProps): JSX.Element {
  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onJSONFormSubmit(callflow);
  };

  const [copyText, setCopyText] = useState("");
  const [modifiedCallFlow, setModifiedCallFlow] = useState(callflow);

  useEffect(() => {
    if (callflow) {
      setModifiedCallFlow(callflow);
    }
  }, [callflow]);

  function handleCopyText() {
    return <p style={{ fontSize: "10px" }}>{copyText}</p>;
  }

  function handleModalClose() {
    setIsJSONModalOpen(false);
  }

  function handleSetCallFlow(callflow) {
    setCallflow(callflow);
    setIsJSONModalOpen(false);
  }

  function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        setCopyText("Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
        setCopyText("Copy Failed!");
      }
    );
  }

  return (
    <Dialog open={isJSONModalOpen} onClose={handleModalClose} fullWidth={true}>
      <form onSubmit={handleFormSubmit}>
        <DialogTitle>
          <Grid container>
            <Grid item xs={11}>
              <p>JSON data</p>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => copyToClipboard(JSON.stringify(callflow, null, 2))}>
                <FileCopyIcon />
              </IconButton>
              {handleCopyText()}
            </Grid>
          </Grid>
          <hr />
        </DialogTitle>

        <DialogContent style={{ height: "600px" }}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                sx={{ width: "100%", mt: "5px" }}
                label={"JSON"}
                multiline={true}
                onChange={(event) => setModifiedCallFlow(JSON.parse(event.target.value))}
                id="CallFlowJSON"
                defaultValue={JSON.stringify(modifiedCallFlow, null, 2)}
                rows={22}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setIsJSONModalOpen(false)}>
            Cancel
          </Button>
          <Button type="submit" onClick={() => handleSetCallFlow(modifiedCallFlow)}>
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default JSONDialog;
