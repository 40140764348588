import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssociatedProgramsModalComponent } from 'src/app/associated-programs-modal/associated-programs-modal.component';
import { Filter, SearchFilters } from 'src/app/data/class';
import { ProgramService } from 'src/app/services/program.service';
import { ToastrService } from 'ngx-toastr';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
@Component({
  selector: 'app-packages-tab',
  templateUrl: './packages-tab.component.html',
  styleUrls: ['./packages-tab.component.scss'],
})
export class PackagesTabComponent implements OnInit, OnDestroy {
  packages: any[];
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  lock: boolean;
  lengthToCompare: number;
  filteredStatuses: Filter[] = [];
  showMoreButton: any = { name: 'Show More', active: true };
  fileNamePlease: any = { name: '', success: false };
  lockedTables: any = { state: false };
  faEye = faEye;
  searchText: any;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  helpers: any;
  filterdata: SearchFilters;

  constructor(
    private programService: ProgramService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private applyFilter: ApplyFilterService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'packages_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Package name',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
    this.initNewSearch();
  }

  associatedProgramsModal(packageName: string): void {
    this.filter = { packageName, offset: 0, name: '' };
    this.programService.getAllPrograms(this.filter).subscribe((data) => {
      if (data.success) {
        const instance = this.modalHelper.open(AssociatedProgramsModalComponent);
        instance.componentInstance.programs = data.entity.aaData;
        instance.componentInstance.packageName = packageName;
      }
    });
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getDistinctProgramPackages(false);
  }

  initNewSearch(): void {
    this.applyFilter.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
      this.filterButton = true;
    });
    this.initFilter();
    this.getDistinctProgramPackages(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getDistinctProgramPackages(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Packages';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getDistinctProgramPackages(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.programService.getDistinctProgramPackages(this.filter).subscribe(
      (data) => {
        if (concat) {
          this.packages = this.packages.concat(data.entity.aaData);
        } else {
          this.packages = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables.state = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables.state;
        this.isLoading = false;
      },
      (err) => {
        this.lockedTables.state = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }
}
