import { StatusHelperService } from './../services/helpers/status-helper.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../services/campaign.service';
import { SponsorsService } from '../services/sponsors.service';
import { CampaignModalComponent } from '../campaign-modal/campaign-modal.component';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss'],
})
export class CampaignComponent implements OnInit {
  campaignId: number;
  sponsorId: number;
  campaign: any;
  sponsor: any;
  faPencilAlt = faPencilAlt;
  active = 1;
  sponsorStats: any;
  filterFunctions: any;
  statistics: any = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private campaignService: CampaignService,
    private sponsorsService: SponsorsService,
    private title: Title,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private applyfilterService: ApplyFilterService
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');

  tabs = [
    { title: 'Promotions', activeId: 2 },
    { title: 'Category', activeId: 7 },
  ];

  ngOnInit(): void {
    this.campaignId = parseInt(this.route.snapshot.paramMap.get('campaignId'), 10);
    if (this.campaignId) {
      this.getCampaign(this.campaignId);
    }
    this.sponsorId = parseInt(this.route.snapshot.paramMap.get('marketId'), 10);
    if (this.sponsorId) {
      this.getSponsor();
      this.getSponsorStats();
    }
  }
  tabClick(tab) {
    this.applyfilterService.filterfun.subscribe((data) => {
      this.filterFunctions = data;
    });
    if (this.filterFunctions) {
      this.filterFunctions.clearAll();
    }
    this.applyfilterService.getFilterData([]);
  }

  getCampaign(id: number): void {
    this.campaignService.getCampaign(id).subscribe(
      (data) => {
        if (data.success) {
          this.campaign = data.entity;
          this.title.setTitle(this.campaign.name);
        }
      },
      () => {
        this.router.navigate(['sponsors']);
      }
    );
  }

  getSponsorStats(): void {
    this.sponsorsService.getSponsorStats({ sponsorPartyId: this.sponsorId, campaignId: this.campaignId }).subscribe(
      (data) => {
        if (data.success) {
          this.sponsorStats = data.entity;
          this.statistics.push({ count: this.sponsorStats.campaignCount, title: 'Programs' });
          this.statistics.push({ count: this.sponsorStats.promotionSponsorCount, title: 'Sponsorships' });
          this.statistics.push({
            count: this.sponsorStats.promotionConfigCount,
            title: 'Configuration',
          });
        }
      },
      () => {}
    );
  }

  getSponsor(): void {
    this.sponsorsService.getSponsor(this.sponsorId).subscribe(
      (data: any) => {
        if (data.success) {
          this.sponsor = data.entity;
        }
      },
      () => {}
    );
  }

  parseResponse(data: any): void {
    if (data.success) {
      this.campaign = data.entity;
    }
  }

  edit(id: number): void {
    this.campaignService.getCampaign(id).subscribe((data) => {
      if (data.success) {
        const instance = this.modalHelper.open(CampaignModalComponent);
        instance.componentInstance.campaign = data.entity;
        instance.componentInstance.successEvent.subscribe(() => {
          this.getCampaign(id);
          instance.close();
        });
      }
    });
  }
}
