import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionType } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PromotionTypeService } from '../services/promotion-type.service';

@Component({
  selector: 'app-promotion-type-modal',
  templateUrl: './promotion-type-modal.component.html',
  styleUrls: ['./promotion-type-modal.component.scss'],
})
export class PromotionTypeModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public promotionType: PromotionType;
  title: string;
  isEdit: boolean;
  promotionTypeForm: FormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private statusHelperService: StatusHelperService,
    private promotionTypeService: PromotionTypeService,
    private modalHelper: NgbModal
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.promotionType) {
      this.title = 'Edit type';
      this.isEdit = true;
      this.promotionTypeForm = this.formBuilder.group({
        name: [this.promotionType.name, Validators.required],
        status: [this.promotionType.status, Validators.required],
      });
    } else {
      this.title = 'New type';
      this.isEdit = false;
      this.promotionTypeForm = this.formBuilder.group({
        name: ['', Validators.required],
        status: ['', Validators.required],
      });
    }
  }

  ok(): void {
    this.promotionTypeService
      .createOrUpdatePromotionType(this.promotionType && this.promotionType.id, this.promotionTypeForm.value)
      .subscribe(
        () => {
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.errorMsg = data.error.error;
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
