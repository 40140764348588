import Chip from "@iridium/iridium-ui/Chip";
import React from "react";
import { getChipPropsFromFilters } from "./filterChips.utils";
import Grid from "@iridium/iridium-ui/Grid";

export type FilterChipsProps<T> = {
  filter: T;
  filterChipPropsLookup: Record<keyof T, { label: string; onDelete: (key, value) => void }>;
  onFilterDelete: (key, value) => void;
  onClearAllFilters: () => void;
};

export function FilterChips<T extends Object>(props: FilterChipsProps<T>) {
  const { filter, filterChipPropsLookup, onFilterDelete, onClearAllFilters } = props;

  const chipPropsList = getChipPropsFromFilters({
    filter,
    onFilterDelete,
    filterChipPropsLookup,
    onClearAllFilters,
  });

  return (
    <Grid container spacing={1}>
      {chipPropsList.map((chipProps, index) => {
        return (
          <Grid item key={`${chipProps.label}${index}`}>
            <Chip {...chipProps} />
          </Grid>
        );
      })}
    </Grid>
  );
}
