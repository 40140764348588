import React from "react";
import Button from "@iridium/iridium-ui/Button";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@iridium/iridium-ui/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import { EntityStatus, Organization } from "../../../data/model";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DateHelperService } from "../../../services/helpers/date-helper.service";
import Select from "@iridium/iridium-ui/Select";
import { CreateOrUpdateOrganizationParams } from "../../../services/organizations.service";
import moment from "moment";
import Grid from "@iridium/iridium-ui/Grid";
import Typography from "@iridium/iridium-ui/Typography";

export const CREATE_OR_EDIT_ORGANIZATION_MODAL_FORM_TEST_ID = "create-or-edit-organization-modal-form";
export const ORGANIZATION_NAME_INPUT_TEST_ID = "organization-name-form-field";
export const STATUS_SELECT_TEST_ID = "status-select";
export const ORGANIZATION_URL_TEST_ID = "organization-url-form-field";
export const ORGANIZATION_LOGO_URL_TEST_ID = "organization-logo-url-form-field";
export const ORGANIZATION_EXTERNAL_ORG_CODE_TEST_ID = "organization-external-code-form-field";
export const ORGANIZATION_CREATE_SUBMIT_BUTTON_TEST_ID = "organization-create-save-button";
const YEAR_START_DATE_FORMAT = "MM/dd";

const dateHelperService = new DateHelperService();

export type OrganizationsModalProps = {
  childEntityLabel: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  organizationBeingEdited?: Organization;
  onSave: (organizationFormData: CreateOrUpdateOrganizationFormFields) => void;
};

export type Option = {
  value: string;
  label: string;
};

const entityStatusOptions: Option[] = Object.values(EntityStatus).map((option) => ({
  value: option.toString(),
  label: option.toString(),
}));

export type CreateOrUpdateOrganizationFormFields = Pick<
  CreateOrUpdateOrganizationParams,
  "name" | "status" | "yearStart" | "url" | "logoUrl" | "externalOrgCode"
>;

function CreateOrEditOrganizationModal({
  isModalOpen,
  onModalClose,
  organizationBeingEdited,
  onSave,
  childEntityLabel,
}: OrganizationsModalProps): JSX.Element {
  const [yearStart, setYearStart] = React.useState<Date | null>();
  const [name, setName] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [logoUrl, setLogoUrl] = React.useState("");
  const [externalOrgCode, setExternalOrgCode] = React.useState("");

  const isEditing = !!organizationBeingEdited;

  function resetState() {
    setYearStart(null);
    setName("");
    setStatus("");
    setUrl("");
    setLogoUrl("");
    setExternalOrgCode("");
  }

  React.useEffect(() => {
    if (isEditing) {
      const status = entityStatusOptions.find(({ value }) => value === organizationBeingEdited.status);
      setStatus(status.value);
      setName(organizationBeingEdited.name);
      if (organizationBeingEdited.yearStart) {
        setYearStart(moment(organizationBeingEdited.yearStart, YEAR_START_DATE_FORMAT).toDate());
      }
      setUrl(organizationBeingEdited.url);
      setLogoUrl(organizationBeingEdited.logoUrl);
      setExternalOrgCode(organizationBeingEdited.setExternalOrgCode)
    } else {
      resetState();
    }
  }, [organizationBeingEdited]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleLogoUrlChange = (event) => {
    setLogoUrl(event.target.value);
  };

  const handleExternalOrgCodeChange = (event) => {
    setExternalOrgCode(event.target.value);
  };
  
  const handleStatusChange = (newOrganizationState) => {
    setStatus(newOrganizationState);
  };
  const handleDateChange = (newValue: Date | null) => {
    setYearStart(newValue);
  };

  function handleSave(event) {
    event.preventDefault();

    let yearStartString = undefined;
    if (yearStart) {
      yearStartString = moment(yearStart).format("MM/DD");
    }
    onSave({
      name,
      status,
      yearStart: yearStartString,
      url: url,
      logoUrl: logoUrl,
      externalOrgCode: externalOrgCode,
    });
    resetState();
  }

  return (
    <Dialog open={isModalOpen} onClose={onModalClose}>
      <DialogTitle>
        {isEditing ? `Editing ${organizationBeingEdited.name}` : `New ${childEntityLabel.toLowerCase()}`}
      </DialogTitle>
      <Grid
        container
        direction="column"
        sx={{ width: "330px", "& .MuiFormControl-root.MuiTextField-root": { width: "100%" } }}
      >
        <form onSubmit={handleSave} data-testid={CREATE_OR_EDIT_ORGANIZATION_MODAL_FORM_TEST_ID}>
          <DialogContent>
            <Stack>
              <div style={{ marginBottom: "6px" }}>
                <Typography>Name</Typography>
                <TextField
                  sx={{ marginBottom: "1rem" }}
                  required
                  disabled={isEditing}
                  autoFocus
                  placeholder="Name"
                  id="name"
                  type="text"
                  fullWidth
                  onChange={(event) => handleNameChange(event)}
                  value={name}
                  InputProps={{ inputProps: { "data-testid": ORGANIZATION_NAME_INPUT_TEST_ID } }}
                />
              </div>
              <div style={{ marginBottom: "6px" }}>
                <Typography>Year start</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    mask="__/__"
                    inputFormat="MM/dd"
                    value={yearStart}
                    onChange={handleDateChange}
                    renderInput={renderDesktopDatePickerInput}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ marginBottom: "6px" }}>
                <Typography>Status</Typography>
                <FormControl fullWidth>
                  <Select
                    id="status-select"
                    placeholder="Select status"
                    onChange={handleStatusChange}
                    value={status}
                    optionPropsList={entityStatusOptions}
                    required
                    InputProps={{ inputProps: { "data-testid": STATUS_SELECT_TEST_ID } }}
                  />
                </FormControl>
              </div>
              <div style={{ marginBottom: "6px" }}>
                <Typography>URL</Typography>
                <TextField
                  sx={{ marginBottom: "1rem" }}
                  autoFocus
                  placeholder="URL"
                  id="url"
                  type="text"
                  fullWidth
                  onChange={(event) => handlUrlChange(event)}
                  value={url}
                  InputProps={{ inputProps: { "data-testid": ORGANIZATION_URL_TEST_ID } }}
                />
              </div>
              <div style={{ marginBottom: "6px" }}>
                <Typography>Organization Logo URL</Typography>
                <TextField
                  sx={{ marginBottom: "1rem" }}
                  autoFocus
                  placeholder="Logo URL"
                  id="looUrl"
                  type="text"
                  fullWidth
                  onChange={(event) => handleLogoUrlChange(event)}
                  value={logoUrl}
                  InputProps={{ inputProps: { "data-testid": ORGANIZATION_LOGO_URL_TEST_ID } }}
                />
              </div>
              <div style={{ marginBottom: "6px" }}>
                <Typography>External organization code</Typography>
                <TextField
                  sx={{ marginBottom: "1rem" }}
                  autoFocus
                  placeholder="External organization code"
                  id="externalOrgCode"
                  type="text"
                  fullWidth
                  onChange={(event) => handleExternalOrgCodeChange(event)}
                  value={externalOrgCode}
                  InputProps={{ inputProps: { "data-testid": ORGANIZATION_EXTERNAL_ORG_CODE_TEST_ID } }}
                />
              </div>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onModalClose} variant="outlined">
              Cancel
            </Button>
            <Button
              disabled={!name || !status}
              variant="contained"
              type="submit"
              data-testid={ORGANIZATION_CREATE_SUBMIT_BUTTON_TEST_ID}
            >
              {isEditing ? "SAVE" : "ADD"}
            </Button>
          </DialogActions>
        </form>
      </Grid>
    </Dialog>
  );
}

/**
 * There are some props that are misaligned from the params that are provided via the renderInput method on
 * the DesktopDatePicker. This is likely due to the slight version mismatches between @mui/lab and @mui/material. Here
 * we set some required props and coerce the values. This should probably be investigated further and a more safe
 * type solution applied.
 */
function renderDesktopDatePickerInput(params) {
  const coercedParams: TextFieldProps = params as TextFieldProps;
  const { id, placeholder } = coercedParams;

  const textFieldProps: TextFieldProps = {
    id: id || "desktop-datepicker-input",
    placeholder: placeholder || "year start",
    ...coercedParams,
  };
  return <TextField {...textFieldProps} />;
}

export default CreateOrEditOrganizationModal;
