import React from "react";
import reactDom from "react-dom";
import { AppThemeProvider } from "@iridium/iridium-ui/AppThemeProvider";
import { createTheme } from "@mui/material";

const cheetahTheme = createTheme({
  palette: { primary: { main: "#377fbf" } },
  components: { MuiButton: { defaultProps: { variant: "contained" } } },
});

export interface MountReactParams<P extends {}> {
  mountId: string;
  component: React.FunctionComponent<P>;
  props?: React.Attributes & P;
}

export function mountReact<P extends {}>(params: MountReactParams<P>): void {
  const { component: Component, props, mountId } = params;

  const mountingElement = document.getElementById(mountId);

  if (mountingElement) {
    reactDom.render(
      <AppThemeProvider appTheme={cheetahTheme}>
        <Component {...props} />
      </AppThemeProvider>,
      mountingElement
    );
  }
}

export function unmountReact(mountId: string): void {
  const mountingElement = getMountingElement(mountId);
  if (mountingElement) {
    reactDom.unmountComponentAtNode(mountingElement);
  }
}

function getMountingElement(mountId: string) {
  return document.getElementById(mountId);
}
