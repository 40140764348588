<div>
  <form>
    <label>Exit when a member updates preferences to:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="optOutPreferences !== null">
        <div
          *ngFor="let optOutPreference of optOutPreferences; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removePreference(optOutPreference)"
            title="Remove Condition"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form #form="ngForm" name="form_{{ i }}">
            <div class="form-group">
              <select
                required
                name="condition_{{ i }}"
                class="form-control"
                [(ngModel)]="optOutPreference.condition"
                (ngModelChange)="updatePreferences()"
              >
                <option
                  *ngFor="let condition of conditions"
                  [selected]="optOutPreference.condition === condition.value"
                  [value]="condition.value"
                >
                  {{ condition.text }}
                </option>
              </select>
              <div
                class="mt-sm"
                *ngIf="optOutPreference.condition === undefined || optOutPreference.condition === null"
              >
                <small class="text-danger">Select condition</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="channel_{{ i }}">A usable:</label>
              <select
                required
                name="channel_{{ i }}"
                class="form-control"
                [(ngModel)]="optOutPreference.channel"
                (ngModelChange)="updatePreferences()"
              >
                <option
                  *ngFor="let endpointType of endpointTypes"
                  [selected]="optOutPreference.channel === endpointType.name"
                  [value]="endpointType.name"
                >
                  {{ endpointType.name }}
                </option>
              </select>
              <div class="mt-sm" [hidden]="optOutPreference.channel">
                <small class="text-danger">Select channel</small>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="optOutPreference.message"
                name="message"
                (ngModelChange)="updatePreferences()"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initPreferenceObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Preference Condition
        </button>
      </div>
    </div>
  </form>
</div>
