import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  functions = ['AVG', 'IDENT', 'MIN', 'MAX', 'SUM'];

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getUnits(): any {
    return this.http.get(this.globalData.baseGoatUrl + 'units');
  }

  getUnitsByMeasureName(measureName: string): any {
    return this.http.get(this.globalData.baseGoatUrl + 'units/measure-name/' + measureName);
  }
}
