import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SegmentMapping } from 'src/app/data/class';
import { ManifestSegmentMappingModalComponent } from '../manifest-segment-mapping-modal/manifest-segment-mapping-modal.component';

@Component({
  selector: 'app-manifest-segment-mapping-form',
  templateUrl: './manifest-segment-mapping-form.component.html',
  styleUrls: ['./manifest-segment-mapping-form.component.scss'],
})
export class ManifestSegmentMappingFormComponent implements OnInit {
  @Input() index: number;
  @Input() segmentMapping: SegmentMapping;
  @Output() public segmentMappingEvent: EventEmitter<any> = new EventEmitter();
  segmentMappingForm: FormGroup;
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;

  constructor(private formBuilder: FormBuilder, private modalHelper: NgbModal) {}

  ngOnInit(): void {
    if (this.index !== null) {
      this.segmentMappingForm = this.formBuilder.group({
        fieldName: [this.segmentMapping.fieldName],
        fieldIndex: [this.segmentMapping.fieldIndex],
        fieldDefault: [{ value: this.segmentMapping.fieldDefault, disabled: true }],
        required: [this.segmentMapping.required],
        fieldLength: [this.segmentMapping.fieldLength],
        fieldRegex: [this.segmentMapping.fieldRegex],
        concatField: [this.segmentMapping.concatField],
        concatDelimiter: [this.segmentMapping.concatDelimiter],
        effectiveDateIndex: [this.segmentMapping.effectiveDateIndex],
        replace: [this.segmentMapping.replace],
      });
    } else {
      this.segmentMappingForm = this.formBuilder.group({
        fieldName: [''],
        fieldIndex: [''],
        fieldDefault: [{ value: '', disabled: true }],
        required: [false],
        fieldLength: [''],
        fieldRegex: [''],
        concatField: [''],
        concatDelimiter: [''],
        effectiveDateIndex: [''],
        replace: [false],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = { ...this.segmentMappingForm.value, fieldDefault: this.segmentMappingForm.get('fieldDefault').value };
    this.segmentMappingEvent.emit({ type, index, value });
    if (type === 'add') {
      this.segmentMappingForm.reset();
    }
  }

  segmentModal(): void {
    const instance = this.modalHelper.open(ManifestSegmentMappingModalComponent);
    instance.componentInstance.segmentCode = this.segmentMappingForm.get('fieldDefault').value;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      this.segmentMappingForm.controls['fieldDefault'].setValue(data);
      instance.close();
    });
  }
}
