<form [formGroup]="filterForm">
  <div class="modal-header">
    <h1>Add Filter</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Filter</label>
      <ng-select [items]="jobs" bindLabel="name" bindValue="id" formControlName="jobInfoId" [clearable]="false">
      </ng-select>
      <div class="mt-2" [hidden]="filterForm.controls.jobInfoId.valid">
        <small class="text-danger">Select filter</small>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!filterForm.valid"></app-modal-buttons>
  </div>
</form>
