import { Component, Input, OnInit } from '@angular/core';
import { MountReactParams } from '../services/react.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ContentService } from '../services/content/content.service';
import { SurveyBuilderDetailView, SurveyBuilderViewProps } from './SurveyBuilder';

@Component({
  template: `<app-mount-react [mountReactParams]="mountReactParams"></app-mount-react>`,
})
export class SurveyBuilderDetailComponent implements OnInit {
  @Input() personId: number;
  public mountReactParams: MountReactParams<SurveyBuilderViewProps> = {
    mountId: 'survey-builder-detail-view-mount',
    component: SurveyBuilderDetailView,
    props: {
      toastr: this.toastr,
      route: this.route,
      navigate: this.router.navigate.bind(this.router),
      contentService: this.contentService,
    },
  };

  constructor(
    private title: Title,
    // private appDataService: AppDataService,
    private contentService: ContentService,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Survey Builder');
  }
}
