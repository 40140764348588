import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { FilterModel } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getFilters(params): any {
    return this.http.get(this.globalData.baseUrl + 'jobs', this.setParams(params));
  }

  getFilter(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'jobs/' + id);
  }

  createOrUpdateFilter(id: number, filter: FilterModel): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'jobs/' + id, filter);
    }
    return this.http.post(this.globalData.baseUrl + 'jobs/', filter);
  }

  deleteFilter(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'jobs/' + id);
  }

  uploadFile(id: number, seletedFile: any, params: any): any {
    return this.http.post(this.globalData.baseUrl + 'jobs/' + id + 'job-jar', seletedFile, params);
  }
}
