<form [formGroup]="productForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="name" />
          <!-- <div class="mt-sm" [hidden]="productForm.controls.name.valid">
            <small class="text-danger">Input name</small>
          </div> -->
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Code <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="code" />
          <!-- <div class="mt-sm" [hidden]="productForm.controls.code.valid">
            <small class="text-danger">Input code</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Product Category <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            [clearable]="false"
            [items]="categories"
            bindLabel="name"
            bindValue="id"
            formControlName="productCategoryId"
          >
          </ng-select>
          <!-- <div class="mt-sm" [hidden]="productForm.controls.productCategoryId.valid">
            <small class="text-danger">Select product category</small>
          </div> -->
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="status" class="form-control">
            <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
          </select>
          <!-- <div class="mt-sm" [hidden]="productForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Manufacturer</label>
          <input type="text" formControlName="manufacturer" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Unit of measure</label>
          <input type="text" formControlName="unitOfMeasure" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Bar Code</label>
          <input type="text" formControlName="barCode" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Serial Number</label>
          <input type="text" formControlName="serialNum" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Universal Product Code</label>
          <input type="text" formControlName="upc" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>International Article Number</label>
          <input type="text" formControlName="ean" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label>Price per unit</label>
          <input type="number" min="0" formControlName="pricePerUnit" class="form-control" />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>Price</label>
          <input type="number" min="0" formControlName="price" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Recommended Retail Price</label>
          <input type="number" min="0" formControlName="rrp" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label>Dimensions</label>
          <input type="text" formControlName="dimensions" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Quantity per package</label>
          <input type="number" min="0" formControlName="quantityPerPackage" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label>Weight</label>
          <input type="text" formControlName="weight" class="form-control" />
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>Length</label>
          <input type="text" formControlName="lenght" class="form-control" />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Average Usage</label>
          <input type="text" formControlName="averageUsage" class="form-control" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea formControlName="description" class="form-control vertical-expand"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!productForm.valid"></app-modal-buttons>
  </div>
</form>
