<form [formGroup]="outreachContentForm">
  <div class="d-flex justify-content-end mt-4 mr-4">
    <mat-icon (click)="cancel()" class="cursor-pointer">close</mat-icon>
  </div>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Language <span style="color: red">*</span></label>
        <span>(Required)</span>
      </div>
      <ng-select
        formControlName="languageId"
        [items]="languages"
        bindLabel="languageName"
        bindValue="id"
        name="languageId"
        [clearable]="false"
      ></ng-select>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Subject </label>
      </div>
      <input formControlName="subject" type="text" class="form-control" />
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="com-label">Select segments <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <ng-select
        [items]="filteredSegments"
        bindLabel="code"
        [multiple]="false"
        formControlName="segmentsCollection"
        [hideSelected]="false"
        [closeOnSelect]="false"
        [clearable]="false"
        [(ngModel)]="segmentCode"
        (change)="updateSelectedSegments($event)"
      >
      </ng-select>
    </div>
    <div class="form-group">
      <label>Brandcode</label>
      <input type="text" class="form-control" formControlName="brandCode" />
    </div>
    <!--<div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Template</label>
      </div>
      <textarea [froalaEditor] class="vertical-expand form-control" formControlName="template"></textarea>
    </div>-->
    <div class="form-group">
      <label>Template</label>
      <div>
        <textarea class="vertical-expand form-control" formControlName="template"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <app-modal-buttons (ok)="ok()" (cancel)="cancel()"> </app-modal-buttons>
    </div>
  </div>
</form>
