<form [formGroup]="executeReportForm" novalidate>
  <div class="modal-header">
    <div class="col-md-12">
      <span class="row">
        <h1>{{ Reportname }}</h1>
      </span>
      <span class="row">
        <h2>Report parameters</h2>
      </span>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group w-100">
        <label>Report notes</label>
        <input
          type="text"
          class="form-control"
          formControlName="reportNotes"
          placeholder="Enter a brief description of the report"
        />
      </div>
    </div>
    <div class="row" *ngIf="!params || params.length === 0">
      <div class="col-md-12"><small>There are no other parameters for this report.</small></div>
    </div>
    <div class="row" *ngFor="let param of params">
      <div class="form-group w-100" *ngIf="isInput(param.paramType) && !param.autocompleteAction">
        <label>{{ removespecialchar(param) }}</label>
        <input
          type="text"
          class="form-control"
          name="regParamName"
          [formControlName]="param.paramCode"
          placeholder="Default: {{ param.defaultValue ? param.defaultValue : 'none' }}"
        />
        <div class="m-t-xs">
          <small
            class="text-danger"
            [hidden]="!param.requiredParam || executeReportForm.controls[param.paramCode].valid"
            >This parameter is required</small
          >
        </div>
      </div>
      <div
        class="form-group w-100"
        *ngIf="param.autocompleteAction && (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR')"
      >
        <label>{{ removespecialchar(param) }}</label>
        <div class="form-group">
          <ng-select
            [items]="autocomplete[param.paramCode]"
            bindLabel="name"
            [formControlName]="param.paramCode"
          ></ng-select>
          <div class="m-t-xs">
            <small
              class="text-danger"
              [hidden]="!param.requiredParam || executeReportForm.controls[param.paramCode].valid"
            >
              This parameter is required</small
            >
          </div>
        </div>
      </div>
      <div class="form-group w-100" *ngIf="param.autocompleteAction && param.paramType === 'ARRAY'">
        <label>{{ removespecialchar(param) }}</label>
        <div class="form-group">
          <ng-select
            [items]="autocompleteArray[param.paramCode]"
            bindLabel="text"
            [multiple]="true"
            [closeOnSelect]="false"
            [formControlName]="param.paramCode"
          ></ng-select>
          <div class="m-t-xs">
            <small
              class="text-danger"
              [hidden]="!param.requiredParam || executeReportForm.controls[param.paramCode].valid"
            >
              This parameter is required</small
            >
          </div>
        </div>
      </div>
      <div class="form-group w-100" *ngIf="param.paramType === 'TIMESTAMP'">
        <label>{{ removespecialchar(param) }}</label>
        <div class="input-group date">
          <input
            class="form-control cursor-pointer"
            readonly
            (click)="datepicker.toggle()"
            placeholder="mm/dd/yyyy"
            name="datepicker"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [formControlName]="param.paramCode"
            [footerTemplate]="footerTemplateDatepicker"
          />
          <ng-template #footerTemplateDatepicker>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate" (click)="clearDate(param.paramCode); datepicker.toggle()">
                Clear
              </button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="datepicker.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
        <div class="m-t-xs">
          <small
            class="text-danger"
            [hidden]="!param.requiredParam || executeReportForm.controls[param.paramCode].valid"
          >
            This parameter is required</small
          >
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!executeReportForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faPaperPlane"></fa-icon> Execute
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>
