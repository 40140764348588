<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-title">
          <h2 class="fullname fontWeight">Outbound batch</h2>
        </div>
        <div class="ibox-content">
          <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
          <div class="filters_container">
            <div class="search-input1">
              <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
              <mat-icon class="search">search</mat-icon>
            </div>
            <div class="right_container1">
              <button class="filter-btn" (click)="FilterModel()">
                FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
              </button>
              <button class="btn-add gaponbothside" (click)="outboundModal()">ADD OUTBOUND BATCH</button>
            </div>
          </div>
          <app-filter-tags
            *ngIf="searchFilters"
            isPopup="true"
            [(filterButton)]="filterButton"
            [(searchFilters)]="searchFilters"
            [(appliedFilters)]="appliedFilters"
          ></app-filter-tags>
          <div class="row">
            <div class="col-md-12">
              <table class="table table-pointable table-bordered infinite-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Report ID</th>
                    <th>Record delimiter</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let o of outbounds | filter: searchText" (click)="showOutbound($event, o)">
                    <td>
                      <a routerLink="/outbounds/{{ o.id }}/loads/">{{ o.id }}</a>
                    </td>
                    <td>{{ o.name }}</td>
                    <td [hidden]="!(o.reportName !== null)">
                      <a routerLink="/reports/{{ o.reportId }}">{{ o.reportName }}</a>
                    </td>
                    <td [hidden]="!(o.reportName === null)">
                      <a routerLink="/reports/{{ o.reportId }}">{{ o.reportId }}</a>
                    </td>
                    <td>{{ o.recordDelimiter }}</td>
                    <td>{{ o.status }}</td>
                  </tr>
                  <tr app-is-there-more [numColumns]="5" [newLengthEvent]="newLengthEvent"></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <app-add-more-data-to-list (onScroll)="getOutbound(true)" [lock]="lock" [isLoading]="isLoading">
        </app-add-more-data-to-list>
      </div>
    </div>
    <div class="col-sm-3" *ngIf="showIntegration()">
      <div class="ibox responsive-no-scroll">
        <div class="row integration-details-bar"></div>
        <div class="ibox-content">
          <div id="contact-1" class="tab-pane active">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 relative-position">
                    <span class="fa-stack fa-3x">
                      <fa-icon class="blue-icon-color" [icon]="faCogs"></fa-icon>
                    </span>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-12 form-group float-right">
                        <span
                          id="status-label"
                          class="badge float-right"
                          [ngClass]="statusHelper[selectedOutbound.status]"
                          >{{ selectedOutbound.status }}</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 float-left">
                        <h2>{{ selectedOutbound.name }}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 float-right">
                <div class="form-group float-right">
                  <a
                    class="btn btn-dark-blue btn-sm pull-right"
                    routerLink="/outbounds/{{ selectedOutbound.id }}/loads"
                    title="View Stages"
                  >
                    <fa-icon [icon]="faFolderOpen"></fa-icon>
                  </a>
                  <a
                    class="btn btn-dark-blue btn-sm pull-right"
                    (click)="outboundModal(selectedOutbound.id)"
                    title="Edit"
                  >
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                </div>
              </div>
            </div>
            <div class="client-detail">
              <strong>Details</strong>
              <ul class="list-group clear-list">
                <li class="list-group-item fist-item">
                  <span class="float-right"> {{ selectedOutbound.createdOn | date: "yyyy-mm-dd" }}</span> Created On
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedOutbound.createdBy }}</span> Created By
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedOutbound.updatedOn | date: "yyyy-mm-dd" }}</span> Updated On
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedOutbound.updatedBy }}</span> Updated By
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div>
</div>
