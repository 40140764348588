<div class="panel-body">
  <!-- <div class="row">
    <div class="col-md-12">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
    </div>
  </div> -->
  <div class="row">
    <div class="col-md-12">
      <table class="table table-pointable table-bordered" width="100%">
        <thead>
          <tr>
            <th>Title</th>
            <th>Name</th>
            <th>Submitted Date</th>
            <th class="width-7">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let survey of surveys"
            (click)="selectedNode = survey.node"
            [class.active]="selectedNode && selectedNode.id === survey.node.id"
          >
            <td>{{ survey.node.surveyData.title }}</td>
            <td>{{ survey.node.surveyData.name }}</td>
            <td>{{ survey.node.submittedAt }}</td>
            <td>
              <div class="float-left actions inline-table">
                <button class="btn btn-default btn-sm edit action" title="Survey details" (click)="showDetails(survey)">
                  <fa-icon [icon]="faInfoCircle"></fa-icon>
                </button>
                <a class="btn btn-dark-blue btn-sm edit action" hidden title="Edit survey" (click)="edit(survey)">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </a>
              </div>
            </td>
          </tr>
          <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
        </tbody>
      </table>
      <app-add-more-data-to-list (onScroll)="getSubmissions(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
