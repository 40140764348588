import { SecurityService } from './../services/security/security.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppDataService } from '../app-data.service';
import { AuthService } from '../auth.service';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  emailError = false;
  passwordError = false;
  loginBanner = '';
  requiresPasswordReset: boolean = false;

  constructor(
    private authService: AuthService,
    private globalData: AppDataService,
    private router: Router,
    private securityService: SecurityService,
    private configurationService: ConfigurationService
  ) {}

  submit(): void {
    if (!this.username || this.username.length < 1) {
      this.emailError = true;
      return;
    }

    if (!this.password || this.password.length < 1) {
      this.passwordError = true;
      return;
    }

    this.authService.login(
      this.username,
      this.password,
      (res, isLoggedIn) => {
        if (isLoggedIn && this.globalData.requiresPasswordReset === false) {
          if (this.globalData.isLiveAgent && !this.globalData.isLiveAgentManager) {
            this.router.navigate(['/select-organization']);
          } else {
            this.router.navigate(['/home']);
          }
        } else if (this.globalData.requiresPasswordReset) {
          this.router.navigate(['/new-password', { party: isLoggedIn }]);
        }
      },
      null
    );
  }

  getLoginBanner(): void {
    this.configurationService.getLoginBanner().subscribe((data) => {
      if (data.success && data.entity) {
        this.loginBanner = data.entity;
      }
    });
  }

  forgotPassword(): void {
    this.router.navigate(['forgot-password']);
  }

  ngOnInit(): void {
    if (localStorage.getItem('username') != null) {
      this.username = localStorage.getItem('username');
    }
    if (localStorage.getItem('token')) {
      if (this.globalData.requiresPasswordReset) {
        this.router.navigate(['/new-password']);
      } else {
        this.router.navigate(['/home']);
      }
    }

    this.getLoginBanner();
  }
  showSelectOrgMsg(): void {}
}
