import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { SecurityService } from '../services/security/security.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private securityService: SecurityService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    // You can set a header to avoid this interceptor as needed
    if (req.headers.has('skipInterceptor')) {
      req = req.clone({
        headers: req.headers.delete('skipInterceptor'),
      });
      return next.handle(req);
    }

    // Get the auth token from the service.
    const authToken = localStorage.getItem('token');

    // if token is expired remove it from localStorage and return req without it in header
    const jwth = new JwtHelperService();
    if (jwth.isTokenExpired(authToken)) {
      localStorage.removeItem('token');
      // we appear to be logged out
      return this.securityService.handleExpiredToken();
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    let authReq = req.clone({ headers: req.headers.set('Authorization', `Bearer ${authToken}`) });
    return next.handle(authReq).pipe(
      map((event: HttpResponse<any>) => {
        if (event instanceof HttpResponse && event.headers.get('X-Cheetah-Token') !== null) {
          localStorage.setItem('token', event.headers.get('X-Cheetah-Token'));
        }
        return event;
      })
    );
  }
}
