<form>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="form-group" (hidden)="(!activityDetails)">
      <ul class="list-group activity-list">
        <li class="list-group-item first-item" style="padding-top: 0px; padding-bottom: 0px">
          <div class="row">
            <div class="table" style="margin-right: 1%; margin-bottom: 0%">
              <table class="table table-pointable table-hover" style="margin-bottom: 0%">
                <thead>
                  <tr>
                    <th width="40%">Name</th>
                    <th width="40%" style="word-break: break-all">Behavior Code</th>
                    <th width="20%" *ngIf="globalData.isPartner" style="text-align: center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bCode of activityDetails.bCodes">
                    <td style="word-break: break-all">{{ bCode.behaviorName }}</td>
                    <td style="word-break: break-all">{{ bCode.behaviorCode }}</td>
                    <td
                      *ngIf="(globalData.isPartner || globalData.isCSR) && !hideButton"
                      style="text-align: center"
                      title="Load Activity"
                    >
                      <a
                        class="btn btn-primary"
                        [routerLink]="['/members/', activityDetails.selectedPersonId]"
                        (click)="cancel({ behaviorToLoad: bCode.id, actionName: 'loadBehavior' })"
                        >Load</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="row">
            <div class="col-md-4">Eligible Date Range:</div>
            <div class="col-md-8 pull-right">{{ activityDetails.startDate }} - {{ activityDetails.endDate }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="" style="font-weight: bold; text-decoration: underline">
      <a
        *ngIf="!activityDetails.hideLink"
        [routerLink]="['/members/', activityDetails.selectedPersonId]"
        (click)="
          cancel({
            actionName: 'searchBehavior',
            params: {
              activityKeyword: activityDetails.regex,
              fromDate: activityDetails.startDate,
              toDate: activityDetails.endDate
            }
          })
        "
        class="ng-binding ng-scope"
        title="Perform a targeted search for activity eligible for this promotion."
      >
        Search for Eligible Activity</a
      >
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning" type="button" (click)="cancel(null)">Close</button>
  </div>
</form>
