<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <div>
        <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
        <div class="row mb-0">
          <table class="table table-pointable table-bordered" width="100%">
            <thead>
              <tr>
                <th>ID</th>
                <th>Code</th>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let contactReason of contactReasons">
                <td>{{ contactReason.id }}</td>
                <td>{{ contactReason.code }}</td>
                <td>{{ contactReason.value }}</td>
                <td>{{ contactReason.status }}</td>
                <td>
                  <div class="actions inline-table float-left">
                    <a
                      class="btn btn-outline-primary btn-sm edit action"
                      (click)="contactReasonModal(contactReason.id)"
                      title="Edit"
                      ><fa-icon [icon]="faPencilAlt"></fa-icon
                    ></a>
                    <button
                      class="btn btn-attention btn-sm delete action"
                      [disabled]="contactReason.status === 'DELETED'"
                      (click)="deleteContactReason(contactReason)"
                      title="Delete"
                    >
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="5" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list
          (onScroll)="getContactReasons(true)"
          [lock]="lock"
          [isLoading]="isLoading"
        ></app-add-more-data-to-list>
      </div>
    </div>
  </div>
</div>
