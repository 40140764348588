import React from "react";
import Grid from "@iridium/iridium-ui/Grid";
import { Typography } from "@mui/material";
import { CallFlow } from "../models/model";
import UsedTagsTable from "./UsedTagsTable";
import { OptOutTable } from "./OptOutTable";
import { NodeWithExpectedResponseTable } from "./NodeWithExpectedResponseTable";

export default function SurveyQA(props: { callflow: CallFlow }) {
  const { callflow } = props;

  if (callflow) {
    return (
      <Grid container rowSpacing={4} sx={{ p: 2 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">Survey Info</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">memberInformationSource: {callflow?.memberInformationSource}</Typography>
        </Grid>
        <Grid item xs={12}>
          <OptOutTable callflow={callflow} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Preventative Survey Tags</Typography>
          <UsedTagsTable callflow={callflow} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Nodes With Expected Responses</Typography>
          <NodeWithExpectedResponseTable callflow={callflow} />
        </Grid>
      </Grid>
    );
  }
  return (
    <Typography variant="h4" sx={{ p: "30px" }}>
      Please load a call flow to begin.
    </Typography>
  );
}
