import { Component, Input, OnInit } from '@angular/core';
import { MountReactParams } from '../services/react.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveAgentSurveys, LiveAgentSurveysProps } from './LiveAgentSurveys';
import { Title } from '@angular/platform-browser';
import { PersonsService } from '../services/persons.service';
import { AppDataService } from '../app-data.service';
import { LiveAgentSurveysService } from '../services/live-agent-surveys.service';

@Component({
  template: `<app-mount-react [mountReactParams]="mountReactParams"></app-mount-react>`,
})
export class LiveAgentDashboardComponent implements OnInit {
  @Input() personId: number;
  public mountReactParams: MountReactParams<LiveAgentSurveysProps> = {
    mountId: 'live-agent-dashboard-view-mount',
    component: LiveAgentSurveys,
    props: {
      toastr: this.toastr,
      route: this.route,
      navigate: this.router.navigate.bind(this.router),
      personsService: this.personsService,
      personId: this.appDataService.userId,
      liveAgentSurveysService: this.liveAgentSurveysService,
      segmentId: this.globalData.segmentId,
      allowExport: this.globalData.allowSurveyExport,
    },
  };

  constructor(
    private title: Title,
    private personsService: PersonsService,
    private appDataService: AppDataService,
    private liveAgentSurveysService: LiveAgentSurveysService,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private globalData: AppDataService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Survey status');
  }
}
