import { mapStatusToHexColor } from "../../services/helpers/status-helper.service";
import React from "react";
import Chip, { ChipProps } from "@iridium/iridium-ui/Chip/Chip";
import { EntityStatus } from "../../data/model";
import { ORGANIZATION_STATUS_CHIP_TEST_ID } from "../constants";

type StatusChipProps = {
  status: EntityStatus | EntityStatus.ACTIVE;
  sx?: ChipProps["sx"];
};

export function StatusChip({ status, sx }: StatusChipProps): JSX.Element {
  const backgroundColor = mapStatusToHexColor(status);
  return (
    <Chip
      label={status}
      sx={{
        ...sx,
        "& .MuiChip-label": {
          fontWeight: "400 !important",
          fontSize: "12px !important",
        },
        backgroundColor,
        color: (theme) => theme.palette.common.white,
      }}
      data-testid={ORGANIZATION_STATUS_CHIP_TEST_ID}
    />
  );
}
