<div>
  <form>
    <label>Instance Attribute Bindings:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="bindings !== null">
        <div *ngFor="let binding of bindings; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeBinding(binding)"
            title="Remove Binding"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="bindingName_{{ i }}">Binding Name:</label>
              <input
                class="form-control m-b"
                id="bindingName_{{ i }}"
                name="bindingName"
                [(ngModel)]="binding.bindingName"
                (ngModelChange)="updateBindings()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="bindingValue_{{ i }}">Binding Value:</label>
              <input
                class="form-control m-b"
                id="bindingValue_{{ i }}"
                name="bindingValue"
                [(ngModel)]="binding.bindingValue"
                (ngModelChange)="updateBindings()"
              />
            </div>
            <div class="form-group">
              <label class="control-label mb-2">Message:</label>
              <textarea
                type="text"
                class="form-control"
                [(ngModel)]="binding.message"
                name="message"
                (ngModelChange)="updateBindings()"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initBindingObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Instance Attribute Bindings
        </button>
      </div>
    </div>
  </form>
</div>
