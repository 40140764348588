import * as dagre from 'dagre';
import { Edge as ReactFlowEdge, isNode, Node as ReactFlowNode } from 'react-flow-renderer';
import { Instruction } from '../models/model';
import { CallFlowNodeData } from './callflow.service';
import { ReactFlowElementsWithCallFlowNodeData } from '../CallFlowBuilder';
import { Node, Connection, Edge } from 'react-flow-renderer/dist/types';

const nodeWidth = 150;
const nodeHeight = 25;

interface ApplyPositionsToReactFlowElementsParams {
  elements: ReactFlowElementsWithCallFlowNodeData;
  direction?: string;
}

export function applyPositionsToReactFlowElements({
  elements,
  direction = 'TB',
}: ApplyPositionsToReactFlowElementsParams): ReactFlowElementsWithCallFlowNodeData {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    const node = el as Node | Connection | Edge;
    if (isNode(node)) {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(node.source, node.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    const node = el as Node | Connection | Edge;
    if (isNode(node)) {
      const nodeWithPosition = dagreGraph.node(node.id);
      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change. Moreover we are shifting the dagre node position
      // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
}

type CreateReactFlowNodeParams = Omit<ReactFlowNode, 'data' | 'type' | 'position'> & {
  label: string;
  instruction: Instruction;
};
export type ReactFlowInstruction = ReactFlowNode<CallFlowNodeData>;

export function createReactFlowNode(params: CreateReactFlowNodeParams): ReactFlowInstruction {
  const { label, id, instruction } = params;
  return {
    id,
    type: 'default',
    data: { label, instruction },
    // This position will be updated in applyPositionsToReactFlowElements
    position: { x: 0, y: 0 },
  };
}

type CreateReactFlowEdgeParams = Omit<ReactFlowEdge, 'animated' | 'type'>;

export function createReactFlowEdge(params: CreateReactFlowEdgeParams): ReactFlowEdge {
  const { source, target, id } = params;
  const idList = [`edge-${id}`, target, source].join('-');
  return {
    ...params,
    label: id,
    animated: false,
    id: idList,
  };
}
