import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { ResponseHelperService } from './helpers/response-helper.service';

@Injectable({
  providedIn: 'root',
})
export class MemberEventsService {
  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getEvents(params: any): any {
    return this.http.get<any>(this.globalData.baseGoatUrl + 'events', this.setParams(params));
  }

  getEventSources(params: any): any {
    return this.http.get<any>(this.globalData.baseGoatUrl + 'events/source', this.setParams(params));
  }

  getEventSource(code: string): any {
    return this.http.get<any>(this.globalData.baseGoatUrl + 'events/source/' + code);
  }

  getMeasureNames(params: any): any {
    return this.http.get<any>(this.globalData.baseGoatUrl + 'events/measureNames', this.setParams(params));
  }

  processSingleEventRef(params: any): any {
    return this.http.get<any>(this.globalData.baseGoatUrl + 'events/process', this.setParams(params));
  }

  processList(params: any[]): any {
    return this.http.post(this.globalData.baseGoatUrl + 'events/process/batch', params);
  }

  createOrUpdateEventSource(data: EventSource): any {
    return this.http.put(this.globalData.baseGoatUrl + 'events/source', data);
  }
}
