import { faTimes, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MemberInstrument } from 'src/app/data/class';

@Component({
  selector: 'app-manifest-member-instrument-form',
  templateUrl: './manifest-member-instrument-form.component.html',
  styleUrls: ['./manifest-member-instrument-form.component.scss'],
})
export class ManifestMemberInstrumentFormComponent implements OnInit {
  @Input() index: number;
  @Input() memberInstrument: MemberInstrument;
  @Output() public memberInstrumentEvent: EventEmitter<any> = new EventEmitter();
  memberInstrumentForm: FormGroup;
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.index !== null) {
      this.memberInstrumentForm = this.formBuilder.group({
        instrumentTypeField: [this.memberInstrument.instrumentTypeField],
        instrumentTypeDefault: [this.memberInstrument.instrumentTypeDefault],
        fieldIndex: [this.memberInstrument.fieldIndex],
        primary: [this.memberInstrument.primary],
        expiryField: [this.memberInstrument.expiryField],
        expiryDefault: [this.memberInstrument.expiryDefault],
        activationField: [this.memberInstrument.activationField],
        activationDefault: [this.memberInstrument.activationDefault],
        hashField: [this.memberInstrument.hashField],
        fieldLength: [this.memberInstrument.fieldLength],
        fieldRegex: [this.memberInstrument.fieldRegex],
        fieldName: [this.memberInstrument.fieldName],
        concatField: [this.memberInstrument.concatField],
        concatDelimiter: [this.memberInstrument.concatDelimiter],
        required: [this.memberInstrument.required],
      });
    } else {
      this.memberInstrumentForm = this.formBuilder.group({
        instrumentTypeField: [''],
        instrumentTypeDefault: [''],
        fieldIndex: [''],
        primary: [false],
        expiryField: [''],
        expiryDefault: [''],
        activationField: [''],
        activationDefault: [''],
        hashField: [''],
        fieldLength: [''],
        fieldRegex: [''],
        fieldName: [''],
        concatField: [''],
        concatDelimiter: [''],
        required: [false],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.memberInstrumentForm.value;
    this.memberInstrumentEvent.emit({ type, index, value });
    if (type === 'add') {
      this.memberInstrumentForm.reset();
    }
  }
}
