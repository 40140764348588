import { map } from 'rxjs/operators';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PartyExtensionService {
  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getNamedExtension(memberId: number, params: any): any {
    this.responseHelperService.setRequestInProgress();
    return this.http.get(this.globalData.baseUrl + 'party-extensions/' + memberId, this.setParams(params)).pipe(
      map(
        (res) => {
          return this.responseHelperService.processResponse(res);
        },
        (err) => {
          return this.responseHelperService.processError(err);
        }
      )
    );
  }
}
