<div class="panel-body">
  <div class="row">
    <div class="col-md-9" *ngIf="show">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Name</th>
              <th>Category</th>
              <th>Price</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let product of products" (click)="selectProduct($event, product)">
              <td>{{ product.id }}</td>
              <td>{{ product.code }}</td>
              <td>{{ product.name }}</td>
              <td>{{ getProductCategoryById(product.productCategoryId).name }}</td>
              <td>{{ product.price }}</td>
              <td>{{ product.status }}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a class="btn btn-outline-primary btn-sm edit action" (click)="productModal(product.id)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                  <button
                    class="btn btn-attention btn-sm delete action"
                    [disabled]="product.status === 'DELETED'"
                    (click)="deleteProduct(product.id)"
                  >
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list
        (onScroll)="getProducts(true)"
        [lock]="lock"
        [isLoading]="isLoading"
      ></app-add-more-data-to-list>
    </div>
    <div class="col-sm-3">
      <div class="row justify-content-center margin-bottom-30">
        <h2><span class="light-blue">Product info:</span></h2>
      </div>
      <div id="contact-1" class="tab-pane active" *ngIf="selectedProduct">
        <div class="row border-card mb-0 margin-top-10">
          <div class="row integration-details-bar width-100"></div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-3 relative-position">
                <span class="fa-stack fa-3x">
                  <fa-layers [fixedWidth]="true">
                    <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
                    <fa-icon [inverse]="true" [icon]="faBarcode" transform="shrink-8" stackItemSize="2x"></fa-icon>
                  </fa-layers>
                </span>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12 form-group float-right">
                    <span
                      id="status-label"
                      class="badge float-right"
                      [ngClass]="statusHelper[selectedProduct.status]"
                      >{{ selectedProduct.status }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 float-left">
                    <h2 class="product-name">{{ selectedProduct.name }}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-card mt-0 bt-0">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Code:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.code }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Product Category:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{
                  getProductCategoryById(selectedProduct.productCategoryId).name
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Manufacturer:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.manufacturer }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Unit of measure:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.unitOfMeasure }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Bar Code:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.barCode }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Serial Number:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.serialNum }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Universal Product Code:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.upc }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <span class="float-left">International Article Number:</span>
              </div>
              <div class="col-md-5">
                <span class="float-right text-right">{{ selectedProduct.ean }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Price per unit:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.pricePerUnit }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Price:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.price }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <span class="float-left">Recommended Retail Price:</span>
              </div>
              <div class="col-md-5">
                <span class="float-right text-right">{{ selectedProduct.rrp }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Dimensions:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.dimensions }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Quantity per package:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.quantityPerPackage }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Weight:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.weight }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Length:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.lenght }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Average Usage:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.averageUsage }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left">Description:</span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{ selectedProduct.description }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div>
</div>
