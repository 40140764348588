<form [formGroup]="reportForm" novalidate="" class="com-model">
  <div class="com-report-modal">
    <div>
      <span class="title">{{ title }}</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-3 mt-3">
      <span class="com-title">Add communication report</span>
    </div>
    <div>
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Name <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>

        <input
          type="text"
          formControlName="name"
          (change)="checkIsAlphaNumerical($event.target.value)"
          name="name"
          class="form-control"
          autofocus
          placeholder="Add name "
        />
        <!-- <div class="mt-2" [hidden]="reportForm.controls.name.valid">
          <small class="text-danger">Input name</small>
        </div> -->
        <!-- <div class="mt-2" [hidden]="isAlphaNumerical">
          <small class="text-danger">Name must be alphanumerical</small>
        </div> -->
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Status <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>
        <ng-select
          class="inputfontsize"
          [items]="statuses"
          bindLabel="name"
          formControlName="status"
          [closeOnSelect]="false"
          placeholder="Select status"
        >
        </ng-select>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Segments <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>
        <ng-select
          class="inputfontsize"
          [items]="filteredSegments"
          bindLabel="name"
          [multiple]="true"
          formControlName="segmentsCollection"
          [hideSelected]="true"
          [closeOnSelect]="false"
          placeholder="Select segments"
        >
        </ng-select>
      </div>
      <div *ngIf="!classification" class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Select classification <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>

        <ng-select
          class="inputfontsize"
          [items]="classifications"
          bindLabel="name"
          bindValue="value"
          formControlName="classification"
          placeholder="Select classification"
        ></ng-select>
      </div>
      <div class="form-group">
        <label class="com-label">Description</label>
        <textarea type="text" formControlName="description" class="form-control vertical-expand"></textarea>
      </div>
      <div class="form-group">
        <label class="com-label">Query</label>
        <textarea type="text" formControlName="sqlQuery" class="form-control vertical-expand"></textarea>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-group form-check">
              <input formControlName="interactiveReport" type="checkbox" class="form-check-input" />
              <label for="interactiveReport" class="com-label"> Interactive </label>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-group form-check">
              <input formControlName="dashboardReport" type="checkbox" class="form-check-input" />
              <label for="dashboardReport" class="com-label"> Dashboard </label>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-group form-check">
              <input formControlName="outboundReport" type="checkbox" class="form-check-input" />
              <label for="outboundReport" class="com-label"> Outbound </label>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="form-group form-check">
              <input formControlName="forStaging" type="checkbox" class="form-check-input" />
              <label for="forStaging" class="com-label"> Integrations </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="reportForm.controls.dashboardReport.value">
        <div class="col-md-12">
          <div class="form-group">
            <label class="com-label">Chart type</label>
            <select formControlName="chartOpt" class="form-control mb-3">
              <option *ngFor="let option of chartOpts" value="{{ option }}">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn-container">
      <button class="btn-pop-cancel" type="button" title="Cancel" (click)="cancel()">CANCEL</button>
      <button
        class="btn-pop-success"
        [disabled]="!(reportForm.valid && isAlphaNumerical)"
        type="submit"
        title="Add"
        (click)="ok()"
      >
        ADD
      </button>
    </div>
  </div>
</form>
