import { Behavior, ChevronList } from './../../data/class';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { faPlus, faTimes, faChevronUp, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-behaviors',
  templateUrl: './behaviors.component.html',
  styleUrls: ['./behaviors.component.scss'],
})
export class BehaviorsComponent implements OnInit {
  @Input() behaviors: any;
  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  faPlus = faPlus;
  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTrash = faTrash;
  openFieldChevronList: ChevronList = new ChevronList();
  units: any;

  constructor(private toastr: ToastrService) {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  //THE VALUE MUST MATCH THE DateUtil.PeriodCode enum
  ngOnInit(): void {
    this.units = [
      { value: 'HOUR', text: 'HOUR' },
      { value: 'DAY', text: 'DAY' },
      { value: 'WEEK', text: 'WEEK' },
      { value: 'MONTH', text: 'MONTH' },
      { value: 'QUARTER', text: 'QUARTER' },
      { value: 'YEAR', text: 'YEAR' },
      { value: 'CAMPAIGN', text: 'CAMPAIGN' },
    ];
  }

  initBehaviorObject(): void {
    const tmpObj = new Behavior();

    if (this.behaviors) {
      this.behaviors.push(tmpObj);
    } else {
      this.behaviors = [];
      this.behaviors.push(tmpObj);
    }
  }

  updateBehaviors(): void {
    this.debouncer.next(this.behaviors);
    this.isValidChange.emit(this.isFormValid());
  }

  removeBehavior(restriction: any): void {
    this.behaviors.splice(this.behaviors.indexOf(restriction), 1);
    this.updateBehaviors();
  }

  handleBehaviorCodeEvent(data: any, behaviorIndex: number): void {
    switch (data.type) {
      case 'add':
        this.addBehaviorCode(data.value, behaviorIndex);
        break;
      case 'save':
        this.saveBehaviorCode(data.index, data.value, behaviorIndex);
        break;
      default:
        this.removeBehaviorCode(data.index, behaviorIndex);
        break;
    }
  }

  addBehaviorCode(selectedBehaviorCodeModel: any, behaviorIndex: number): void {
    if (this.behaviors[behaviorIndex].behaviorCodes === undefined) {
      this.behaviors[behaviorIndex].behaviorCodes = new Array<string>();
    }
    this.behaviors[behaviorIndex].behaviorCodes.push(selectedBehaviorCodeModel.behaviorCode);
    this.updateBehaviors();
    this.toastr.success('Behavior Code added');
  }

  editBehaviorCode(index: number): void {
    if (this.openFieldChevronList.behaviorCodeIndex === index) {
      this.openFieldChevronList.behaviorCodeIndex = null;
    } else {
      this.openFieldChevronList.behaviorCodeIndex = index;
    }
  }

  saveBehaviorCode(i: any, behaviorCode: any, behaviorIndex: number): void {
    if (this.behaviors[behaviorIndex].behaviorCodes[i].fieldIndex === behaviorCode.fieldIndex) {
      this.behaviors[behaviorIndex].behaviorCodes[i] = behaviorCode.behaviorCode;
      this.updateBehaviors();
      this.toastr.success('Behavior Code saved');
    }
    this.toastr.success('Can not save Behavior Code');
  }

  removeBehaviorCode(i: any, behaviorIndex: number): void {
    this.behaviors[behaviorIndex].behaviorCodes.splice(i, 1);
    this.updateBehaviors();
  }

  keyPressNumbers(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  isFormValid(): boolean {
    let result: boolean;
    for (const behavior of this.behaviors) {
      if (!this.isListExists(behavior.behaviorCodes) && this.isUnrequiredFieldsFilled(behavior)) {
        result = false;
      } else {
        result = true;
      }
    }
    return result;
  }

  isListExists(list: any): boolean {
    if (list && list.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isUnrequiredFieldsFilled(behavior: any): boolean {
    if (behavior.period || behavior.periodCount || (behavior.message && behavior.message !== '')) {
      return true;
    } else {
      return false;
    }
  }
}
