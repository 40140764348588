<div class="panel-body">
  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <h3>Nutrition Tags</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!-- TODO: Check if ui-sortable is needed -->
          <!-- <ul class="todo-list mt-0 ui-sortable attribute-mappings"> -->
          <ul class="todo-list mt-3 attribute-mappings">
            <li (click)="setActive($event, tag.id)" *ngFor="let tag of tags">
              <span class="ml-sm">{{ tag.localName }}</span>
              <small class="label float-right" [ngClass]="tagCatogoryColors[tag.remoteCategory]">
                {{ tag.remoteCategory }}
              </small>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-5 col-md-offset-1">
      <div class="row">
        <div class="col-md-12">
          <h3>Attribute Mappings</h3>
        </div>
      </div>
      <div class="row margin-top-10" *ngFor="let group of attrMappings">
        <div class="col-md-12 attribute-mappings-details">
          <!-- TODO: Check if ui-sortable is needed -->
          <!-- <ul class="todo-list m-t ui-sortable"> -->
          <ul class="todo-list mt-3">
            <div class="row attribute-group-details">
              <div class="col-md-6">
                <h3>{{ group.groupName }}</h3>
              </div>
              <div class="col-md-6">
                <button class="btn btn-sm btn-attention float-right" (click)="deleteGroup(group)" title="Delete Group">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
                <button class="btn btn-outline-primary float-right" (click)="groupDialog(group)" title="Edit Group">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </button>
              </div>
            </div>
            <div class="row padding-top-10">
              <div class="col-md-4">
                <label>Attribute Name</label>
              </div>
              <div class="col-md-4">
                <label>Attribute Value</label>
              </div>
              <div class="col-md-4">
                <label>Action</label>
              </div>
            </div>
            <li *ngFor="let mapping of group.mappings">
              <div class="row">
                <div class="col-md-4">
                  <span class="ml-xs-1">{{ mapping.attrNameExpr }}</span>
                </div>
                <div class="col-md-4">
                  <span class="ml-xs-1">{{ mapping.attrValueExpr }}</span>
                </div>
                <div class="col-md-4">
                  <button
                    class="btn btn-sm btn-attention float-right"
                    (click)="deleteMapping(mapping, group)"
                    title="Delete Mapping"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                  <button
                    class="btn btn-sm btn-dark-blue float-right"
                    (click)="attributePairModal(group.id, mapping)"
                    title="Edit Mapping"
                  >
                    <fa-icon [icon]="faEdit"></fa-icon>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div>
            <button
              class="btn btn-sm btn-dark-blue float-right mt-3"
              (click)="attributePairModal(group.id)"
              title="Add New Mapping"
            >
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="attrMappings">
        <div class="col-md-12" *ngIf="!attrMappings.length">
          <span>No mappings for this tag</span>
        </div>
        <div class="col-md-12">
          <button class="btn btn-sm btn-dark-blue float-right mt-3" (click)="groupDialog()" title="Add New Group">
            <fa-icon [icon]="faPlus"></fa-icon>
            Add Group
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
