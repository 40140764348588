import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartyRoleService } from 'src/app/services/party-role.service';
import { PartyRelationshipService } from 'src/app/services/party-relationship.service';
import { FormBuilder, FormsModule } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-party-relationship-restriction',
  templateUrl: './party-relationship-restriction.component.html',
  styleUrls: ['./party-relationship-restriction.component.scss'],
})
export class PartyRelationshipRestrictionComponent implements OnInit {
  @Input() relationshipToLocation: any;
  faTimes = faTimes;
  faPlus = faPlus;
  conditions: any;
  @Input() partyRoles: any;
  @Input() partyRelationships: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
    private partyRoleService: PartyRoleService,
    private partyRelationshipService: PartyRelationshipService
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.conditions = [
      { value: true, text: 'HAS' },
      { value: false, text: 'DOES NOT HAVE' },
    ];
  }

  initRestrictionObject(): void {
    console.log(this.relationshipToLocation);
    var tmpObj = {
      class:
        'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Behavioral$RelationshipToLocation',
    };
    if (this.relationshipToLocation) {
      this.relationshipToLocation.push(tmpObj);
    } else {
      this.relationshipToLocation = [];
      this.relationshipToLocation.push(tmpObj);
    }
    console.log(this.relationshipToLocation);
  }

  updateRestrictions() {
    this.debouncer.next(this.relationshipToLocation);
  }

  removeRestriction(restriction) {
    this.relationshipToLocation.splice(this.relationshipToLocation.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
