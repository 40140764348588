<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Numeric Code</th>
              <th>Alpha Code</th>
              <th>Display Format</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let worldCurrency of worldCurrencies">
              <td>{{ worldCurrency.id }}</td>
              <td>{{ worldCurrency.name }}</td>
              <td>{{ worldCurrency.description }}</td>
              <td>{{ worldCurrency.numericCode }}</td>
              <td>{{ worldCurrency.alphaCode }}</td>
              <td>{{ worldCurrency.displayFormat }}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a
                    class="btn btn-outline-primary btn-sm edit action"
                    (click)="currencyModal(worldCurrency.id)"
                    title="Edit"
                  >
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                  <button
                    class="btn btn-attention btn-sm delete action"
                    [disabled]="worldCurrency.status === 'DELETED'"
                    (click)="deleteCurrency(worldCurrency.id)"
                    title="Delete"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getRewardTypes(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
