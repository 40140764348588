import { Communication } from './../../data/model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { GenderRestriction } from 'src/app/data/class';

@Component({
  selector: 'app-gender-restriction',
  templateUrl: './gender-restriction.component.html',
  styleUrls: ['./gender-restriction.component.scss'],
})
export class GenderRestrictionComponent implements OnInit {
  @Input() selectedComm: Communication;
  @Input() genderRestriction: any;
  @Input() configurationType: string;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('form') form: any;
  conditions: any;
  values: any;
  faTimes = faTimes;
  faPlus = faPlus;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  message: string;

  constructor() {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.values = [
      { value: 'Male', text: 'Male' },
      { value: 'Female', text: 'Female' },
      { value: 'Unspecified', text: 'Unspecified' },
    ];

    this.conditions = [
      { value: true, text: 'MATCHES' },
      { value: false, text: 'DOES NOT MATCH' },
    ];
  }

  initRestrictionObject(): void {
    if (this.configurationType === 'rules') {
      this.genderRestriction = new GenderRestriction();
    } else {
      this.genderRestriction = {
        class:
          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Eligibility$GenderRestriction',
      };
    }
  }

  updateRestrictions() {
    this.debouncer.next(this.genderRestriction);
    this.isValidChange.emit(this.form.valid);
  }

  removeRestriction(restriction) {
    this.genderRestriction = null;
    this.updateRestrictions();
  }
}
