<div>
  <div>
    <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="filters_container">
            <div class="search-input2">
              <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
              <mat-icon class="search">search</mat-icon>
            </div>
            <div class="right_container2">
              <button class="filter-btn" (click)="FilterModel()">
                FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
              </button>
              <button class="btn-add" (click)="createReport()">ADD REPORT</button>
            </div>
          </div>
          <app-filter-tags
            *ngIf="searchFilters"
            isPopup="true"
            [(filterButton)]="filterButton"
            [(appliedFilters)]="appliedFilters"
            [(searchFilters)]="searchFilters"
          ></app-filter-tags>
          <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
        </div>
      </div>
    </div>
    <div class="row" *ngIf="show">
      <div class="col-md-12">
        <div class="row mb-2">
          <div>
            <table class="table table-pointable table-bordered">
              <thead>
                <tr>
                  <!-- <th (click)="toggleSort(0)">
                    <span class="ml-3">ID</span>
                    <app-sort-arrows [selectedColumn]="filter.column" [column]="0" [dir]="filter.dir">
                    </app-sort-arrows>
                  </th> -->
                  <th (click)="toggleSort(2)">
                    <span class="table_field_name" style="padding-left: 2rem">Name</span>
                    <app-sort-arrows [selectedColumn]="filter.column" [column]="2" [dir]="filter.dir">
                    </app-sort-arrows>
                  </th>
                  <th class="table_field_name">Type</th>
                  <th class="table_field_name">Status</th>
                  <th class="table_field_name">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let report of reportsAvailable | filter: searchText; let i = index"
                  (click)="showReportAvaliable($event, report)"
                >
                  <!-- <td>
                    <a routerLink="{{ isAdmin ? '/reports/' + report.id : '' }}" class="ml-3">{{ report.id }}</a>
                  </td> -->
                  <td>
                    <a routerLink="{{ isAdmin ? '/reports/' + report.id : '' }}" class="ml-3">{{ report.name }}</a>
                  </td>
                  <td>
                    <div *ngIf="report.interactiveReport">Interactive</div>
                    <div *ngIf="report.dashboardReport">Dashboard</div>
                    <div *ngIf="report.outboundReport">Outbound</div>
                    <div *ngIf="report.forStaging">Integrations</div>
                    <div
                      *ngIf="
                        !report.interactiveReport &&
                        !report.dashboardReport &&
                        !report.outboundReport &&
                        !report.forStaging
                      "
                    >
                      Unknown
                    </div>
                  </td>
                  <td>{{ report.status }}</td>
                  <td>
                    <div class="aligndiv actions inline-table">
                      <div class="container">
                        <div class="row justify-content-end w-70">
                          <div class="col-sm-6">
                            <a
                              *ngIf="contExteranlAppUrls(report) > 0 && report.interactiveReport"
                              title="Run Report"
                              (click)="runReport(report)"
                              class="btn btn-dark-blue-action btn-sm ml-1"
                            >
                              <fa-icon [icon]="faRocket"></fa-icon>
                            </a>
                            <a
                              *ngIf="contExteranlAppUrls(report) === 0 && report.interactiveReport"
                              routerLink="reports/orb/{{ report.id }}"
                              title="Run Report"
                              class="btn btn-dark-blue-action btn-sm ml-1"
                            >
                              <fa-icon [icon]="faRocket"></fa-icon>
                            </a>
                          </div>
                          <div class="col-sm-6">
                            <a
                              *ngIf="isAdmin"
                              routerLink="/reports/{{ report.id }}"
                              class="manageicon btn btn-dark-blue-action btn-sm"
                              title="Manage"
                            >
                              <fa-icon [icon]="faCog"></fa-icon>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
              </tbody>
            </table>
          </div>
        </div>
        <app-add-more-data-to-list (onScroll)="getReportsAvaliable(true)" [lock]="lock" [isLoading]="isLoading">
        </app-add-more-data-to-list>
      </div>
    </div>
  </div>
</div>
