<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-12 content-container">
      <div class="ibox">
        <div class="ibox-content">
          <div class="m-b">
            <h2 class="fullname">Processing log</h2>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="filters_container">
                  <div class="search-input2">
                    <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
                    <mat-icon class="search">search</mat-icon>
                  </div>
                  <div class="right_container2">
                    <button class="filter-btn" (click)="FilterModel()">
                      FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
                    </button>
                  </div>
                </div>
                <app-filter-tags
                  *ngIf="searchFilters"
                  isPopup="true"
                  [(filterButton)]="filterButton"
                  [(searchFilters)]="searchFilters"
                  [(appliedFilters)]="appliedFilters"
                ></app-filter-tags>
                <div>
                  <table class="table table-pointable table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Process Code</th>
                        <th>Status</th>
                        <th>Finished On</th>
                        <th>Created On</th>
                        <th>Created By</th>
                        <th>Success No</th>
                        <th>Error No</th>
                        <th>Last No</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let processingLog of processingLogs | filter: searchText">
                        <td>
                          <a routerLink="/processing-log/{{ processingLog.id }}">{{ processingLog.id }}</a>
                          <fa-icon
                            [icon]="faExclamationTriangle"
                            *ngIf="processingLog.errorRecNum > 0"
                            class="text-danger ng-scope"
                            title="Processed with (errors)"
                          ></fa-icon>
                        </td>
                        <td class="word-break-break-all">{{ processingLog.procCode }}</td>
                        <td>{{ processingLog.status }}</td>
                        <td>{{ processingLog.finishedOnStr }}</td>
                        <td>{{ processingLog.createdOnStr }}</td>
                        <td>{{ processingLog.createdBy }}</td>
                        <td>{{ processingLog.successRecNum }}</td>
                        <td>{{ processingLog.errorRecNum }}</td>
                        <td>{{ processingLog.lastRecNum }}</td>
                        <td class="word-break-break-all">{{ processingLog.description }}</td>
                      </tr>
                      <tr app-is-there-more [numColumns]="10" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list (onScroll)="getProcessingLogs(true)" [lock]="lock" [isLoading]="isLoading">
                </app-add-more-data-to-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
