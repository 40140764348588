import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class CommunicationAllowlistService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  deleteFromAllowlist(id: number) {
    return this.http.delete(this.globalData.baseUrl + 'chirp/comm-allowlist/' + id);
  }

  getAllowlist(params) {
    return this.http.get(this.globalData.baseUrl + 'chirp/comm-allowlist', this.setParams(params));
  }

  addToAllowlist(params) {
    return this.http.post(this.globalData.baseUrl + 'chirp/comm-allowlist', params);
  }
}
