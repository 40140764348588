import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OutboundService } from '../../services/outbound.service';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { OutboundIntegration } from '../../data/model';
import $ from 'jquery';
import { faTimes, faFolderOpen, faCopy } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-outbound-stage-loads',
  templateUrl: './outbound-stage-loads.component.html',
  styleUrls: ['./outbound-stage-loads.component.scss'],
})
export class OutboundStageLoadsComponent implements OnInit {
  isLoading = true;
  selectedOutboundId: number;
  integrationType: any;
  lockedTables = false;
  lock = false;
  filter = new Filter();
  limit = 20;
  filteredLoadStatuses: any[] = [];
  statuses: Choice[];
  outbounds: OutboundIntegration[];
  lengthToCompare: number;
  selectedLoad: any = null;
  searchFilters: SearchFilters;
  faTimes = faTimes;
  faFolderOpen = faFolderOpen;
  faCopy = faCopy;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private titleHelper: Title,
    private route: ActivatedRoute,
    private router: Router,
    private outboundService: OutboundService,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private toastr: ToastrService
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'outbound-stage-load-search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
    this.titleHelper.setTitle('Outbound Loads');

    this.selectedOutboundId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (this.selectedOutboundId) {
      this.getOutboundImportType(this.selectedOutboundId);
    }

    this.initFilter();
    this.getStatuses();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getOutboundLoad(false);
  }

  getOutboundImportType(id: number): void {
    this.outboundService.getOutbound(id).subscribe(
      (data: any) => {
        if (data.success) {
          this.integrationType = data.entity.importType;
        }
      },
      () => {
        this.router.navigate(['outbounds']);
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('IMPORT');
  }

  getOutboundLoad(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.outboundService.getAllOutboundLoad(this.selectedOutboundId, this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.outbounds = this.outbounds.concat(data.entity.aaData);
        } else {
          this.outbounds = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  showOutboundLoad(event: any, outbound: OutboundIntegration): void {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    this.selectedLoad = outbound;
  }

  filterOutboundLoad(keyword: string): void {
    this.initFilter();
    this.filter.search = keyword;
    this.getOutboundLoad(false);
  }

  setStatuses(): void {
    this.filter.statuses = this.tableHelperService.extractData(this.filteredLoadStatuses);
    this.getOutboundLoad(false);
    this.initFilter();
  }

  showLoad(): boolean {
    if (this.selectedLoad !== null) {
      return true;
    }
    return false;
  }
}
