<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <h2 class="fullname">Communications</h2>
          </div>
        </div>
      </div>

      <div class="grey-box mb-2"></div>
      <div class="tabs-container">
        <mat-tab-group class="remove-border-bottom" animationDuration="0ms" (selectedTabChange)="tabClick($event)">
          <mat-tab label="Outreach Plan">
            <app-communication-management></app-communication-management>
          </mat-tab>
          <mat-tab label="Content Group">
            <app-communication-content-group></app-communication-content-group>
          </mat-tab>
          <mat-tab label="Strategy">
            <app-communication-strategy-tab></app-communication-strategy-tab>
          </mat-tab>

          <mat-tab label="Delivery">
            <app-delivery-protocols-tab></app-delivery-protocols-tab>
          </mat-tab>
          <mat-tab label="Reports">
            <app-reports-available [isAdmin]="isAdmin" [classification]="'Communication'"></app-reports-available>
          </mat-tab>
          <mat-tab label="Reports Results">
            <app-reports-results [isAdmin]="isAdmin" [classification]="'Communication'"></app-reports-results>
          </mat-tab>
          <mat-tab label="History" (click)="tabClick($event)">
            <app-communication-history-tab [tabIndex]="tabIndex"></app-communication-history-tab>
          </mat-tab>
          <mat-tab label="Contact Allowlist" *ngIf="isAdmin && useAllowlist">
            <app-communication-allowlist></app-communication-allowlist>
          </mat-tab>
          <mat-tab label="Channel Configuration" *ngIf="isAdmin">
            <app-communication-channel-configuration></app-communication-channel-configuration>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
