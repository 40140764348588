<div class="panel-body" *ngIf="displaySelect">
  <div class="modal-header">
    <h1>Select Organization</h1>
  </div>
  <div class="row">
    <ng-container *ngFor="let market of markets">
      <div class="col-md-12">
        <div class="widget white-bg">
          <ul class="list-unstyled mt-3" (click)="setGlobalOrg(market)">
            <li><label>Market Name:</label> {{ market.username }}</li>
            <li>
              <img
                src="{{ market.logoUrl !== null ? market.logoUrl : '../assets/images/Icario_Ocean_RGB.png' }}"
                height="110px"
              />
            </li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</div>
