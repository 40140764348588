import React, { Fragment } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CallFlow } from "../models/model";

function getNodeIdMasterCatalogueIDTags(callflow: CallFlow) {
  const nodesWithExpectedResponses = callflow.instructions.filter((node) => {
    return node.expectedResponses !== undefined && node.expectedResponses.length > 0;
  });

  return nodesWithExpectedResponses.map((nodesWithExpectedResponses) => {
    const expectedResponses = nodesWithExpectedResponses.expectedResponses.map((expectedResponse) => {
      return {
        tags: expectedResponse.platformMetaData.tags,
        id: expectedResponse.id,
        masterCatalogueID: expectedResponse.platformMetaData.masterCatalogueId,
        customDigitUtteranceSet: expectedResponse.customDigitUtteranceSet,
        nextInstructionId: expectedResponse.nextInstructionId,
        customVoiceUtteranceSet: expectedResponse.customVoiceUtteranceSet,
      };
    });
    return { node: nodesWithExpectedResponses.id, expectedResponses: expectedResponses };
  });
}

function optOutTable(callflow: CallFlow) {
  const nodesWithExpectedResponsesData = getNodeIdMasterCatalogueIDTags(callflow);
  return (
    <TableContainer sx={{ bgcolor: "background.paper", overflow: "hidden" }}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "25%" }}>Instruction</TableCell>
            <TableCell sx={{ width: "25%" }}>Expected Response ID</TableCell>
            <TableCell sx={{ width: "25%" }}>masterCatalogueID</TableCell>
            <TableCell sx={{ width: "25%" }}>Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nodesWithExpectedResponsesData.map((node) => {
            <TableRow key={"instruction-row"}>
              <TableCell rowSpan={node.expectedResponses?.length + 1}>{node.node}</TableCell>
              {node.expectedResponses.map((expectedResponse) => {
                return (
                  <>
                    <TableCell>{expectedResponse.id}</TableCell>
                    <TableCell>{expectedResponse.masterCatalogueID}</TableCell>
                    <TableCell>{expectedResponse.tags.join(", ")}</TableCell>
                  </>
                );
              })}
            </TableRow>;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export function NodeWithExpectedResponseTable(props: { callflow: CallFlow }) {
  const { callflow } = props;
  const nodesWithExpectedResponsesData = getNodeIdMasterCatalogueIDTags(callflow);

  return (
    <TableContainer sx={{ bgcolor: "background.paper", overflow: "hidden" }}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "17%" }}>Instruction</TableCell>
            <TableCell sx={{ width: "17%" }}>Expected Response ID</TableCell>
            <TableCell sx={{ width: "16%" }}>Utterance</TableCell>
            <TableCell sx={{ width: "17%" }}>Voice Utterance</TableCell>
            <TableCell sx={{ width: "17%" }}>Next Instruction ID</TableCell>

            <TableCell sx={{ width: "16%" }}>Tags</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {nodesWithExpectedResponsesData.map((node) => (
            <Fragment>
              <TableRow key={"instruction-row" + node.node}>
                <TableCell rowSpan={node.expectedResponses?.length + 1}>{node.node}</TableCell>
              </TableRow>
              {node.expectedResponses.map((expectedResponse) => (
                <>
                  <TableRow>
                    <TableCell>{expectedResponse.id}</TableCell>
                    <TableCell>{expectedResponse.customDigitUtteranceSet.join("")}</TableCell>
                    <TableCell style={{ whiteSpace: "pre" }}>
                      {expectedResponse.customVoiceUtteranceSet?.map((voiceSet) => {
                        return voiceSet.language + ": " + voiceSet.utteranceSet.join(", ") + "\n";
                      })}
                    </TableCell>
                    <TableCell>{expectedResponse.nextInstructionId}</TableCell>

                    <TableCell>{expectedResponse.tags.join(", ")}</TableCell>
                  </TableRow>
                </>
              ))}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
