import { CommunicationsService } from 'src/app/services/communications.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { OutreachSequence } from './../data/model';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { PromotionSponsorReminder } from '../data/model';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { PromotionService } from '../services/promotion.service';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, filter, map } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-promotion-sponsor-reminder-modal',
  templateUrl: './promotion-sponsor-reminder-modal.component.html',
  styleUrls: ['./promotion-sponsor-reminder-modal.component.scss'],
})
export class PromotionSponsorReminderModalComponent implements OnInit, AfterViewInit {
  @Input() item: PromotionSponsorReminder;
  @Input() promotionSponsorshipId: number;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  errorMsg = '';
  promotionStart = ['Score Start Date', 'Published Start Date'];
  periods = ['CAMPAIGN', 'YEAR', 'QUARTER', 'MONTH', 'WEEK', 'DAY', 'PLAN YEAR'];
  outreach: any[];
  faTimes = faTimes;
  faSave = faSave;
  promotions$: Observable<any>;
  promotionsLoading = false;
  promotionsInput$ = new Subject<string>();
  selectedPromotions: any = [];
  minLengthTerm = 3;
  @ViewChild('ngselect') ngSelect: NgSelectComponent;

  constructor(
    private sponsorsService: SponsorsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private promotionService: PromotionService
  ) {}

  ngOnInit(): void {
    if (this.item) {
      this.title = 'Edit Outreach Sequence';
      if (!this.item.outreachSequence) {
        this.item.outreachSequence = new OutreachSequence();
      }
    } else {
      this.item = new PromotionSponsorReminder();
      this.title = 'Add New Outreach Sequence';
    }
    this.item.promotionSponsorId = this.promotionSponsorshipId;

    this.communicationsService.getCommunications({ withoutParent: true }).subscribe((data: any) => {
      this.outreach = data.entity.aaData;
      console.log(data.entity.aaData);
    });
    this.loadPromotions();
  }

  ngAfterViewInit(): void {
    this.item.outreachSequence.promotion.length !== 0
      ? (this.selectedPromotions = this.item.outreachSequence.promotion.split(','))
      : (this.selectedPromotions = []);
    this.ngSelect.writeValue(this.selectedPromotions);
  }

  ok(): void {
    this.item.outreachSequence.promotion = this.selectedPromotions.toString();
    this.sponsorsService.upsertSponsorshipReminders(this.promotionSponsorshipId, this.item.id, this.item).subscribe(
      (data: any) => {
        if (data.success) {
          this.responseHelperService.success('Outreach Sequence successfully updated', true);
          this.successEvent.emit();
        }
      },
      (data: any) => {
        this.responseHelperService.error(this, data.errorMsg);
      }
    );
  }

  loadPromotions() {
    this.promotions$ = concat(
      of([]),
      this.promotionsInput$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.minLengthTerm;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.promotionsLoading = true)),
        switchMap((term) => {
          return this.getData(term).pipe(
            catchError(() => of([])),
            tap(() => (this.promotionsLoading = false))
          );
        })
      )
    );
  }

  getData(term: string = null): Observable<any> {
    return this.promotionService.getPromotions({ search: term, limit: 500, offset: 0, dir: 'desc' }).pipe(
      map((resp: any) => {
        if (resp.error) {
          throwError(resp.error);
        } else {
          let array = [];
          resp.entity.aaData.forEach((element) => {
            array.push(element.code);
          });
          return array;
        }
      })
    );
  }

  trackByFn(item: any) {
    return item.imdbID;
  }

  updateNgModel(event: any): void {
    this.selectedPromotions = event;
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
