import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() title: string;
  @Input() chartLabels: Label[];
  @Input() labelName: string;
  @Input() data: any[];
  @Input() charType: any;
  @Input() chartTypeName: string;
  @Input() borderColor = '#0F3957';
  @Input() backgroundColor = '#93c8488c';

  public chartData: ChartDataSets[];
  public chartOptions: ChartOptions;
  public chartColors: Color[];
  public chartLegend = true;
  public chartType: ChartType;
  public chartPlugins = [];

  constructor() {}

  ngOnInit() {
    this.chartType = this.charType;
    this.chartData = [{ data: this.data, label: this.labelName }];
    this.chartOptions = {
      responsive: true,
    };
    this.chartColors = [
      {
        borderColor: this.borderColor,
        backgroundColor: this.backgroundColor,
      },
    ];
  }

  changeChartType(e) {
    this.charType = e.target.value;
    this.ngOnInit();
  }
}
