<div>
  <form>
    <label>Issue a reward as a multiple (linear function) of the submitted activity value (y = mx + b):</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="percentOfValue !== null">
        <div
          *ngFor="let percentReward of percentOfValue; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeConsequence(reward)"
            title="Remove Consequence"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="rewardRate_{{ percentReward.rewardCode }}"
                >Reward Multiplier (rate)</label
              >
              <input
                class="form-control m-b"
                type="number"
                id="rewardRate_{{ percentReward.rewardCode }}"
                name="rewardRate"
                [(ngModel)]="percentReward.rewardRate"
                (ngModelChange)="updateConsequences()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="rewardOffset_{{ percentReward.rewardCode }}"
                >Initial Amount (offset)</label
              >
              <input
                class="form-control m-b"
                type="number"
                id="rewardOffset_{{ percentReward.rewardCode }}"
                name="rewardOffset"
                [(ngModel)]="percentReward.rewardOffset"
                (ngModelChange)="updateConsequences()"
              />
            </div>
            <div class="form-group">
              <label>Reward Type</label>
              <select
                name="rewardCode_{{ percentReward.rewardCode }}"
                class="form-control"
                [(ngModel)]="percentReward.rewardCode"
                (ngModelChange)="updateConsequences()"
              >
                <option
                  *ngFor="let reward of rewards"
                  [selected]="percentReward.rewardCode === reward.code"
                  [value]="reward.code"
                >
                  {{ reward.code }}
                </option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Percentage Reward
        </button>
      </div>
    </div>
  </form>
</div>
