<div class="wrapper wrapper-content" *ngIf="selectedPerson">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <!-- <a class="btn btn-dark-blue btn-sm float-right" (click)="edit(selectedPerson.id)" title="Edit">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </a> -->
            <div>
              <h2 class="com-title">{{ selectedPerson.name }}</h2>
            </div>
            <div class="d-flex align-items-center">
              <dt>Status:</dt>
              <dd class="d-flex align-items-center">
                <span class="badgeactive ml-2 mt-2 rounded-pill" [ngClass]="statusHelper[selectedPerson.status]">{{
                  selectedPerson.status
                }}</span>
              </dd>
              <!-- <a class="btn btn-dark-blue-action btn-sm ml-3" (click)="edit(selectedPerson.id)" title="Edit">
                <fa-icon [icon]="faPencilAlt"></fa-icon>
              </a> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div (click)="openAdmin()" *ngIf="globalData.isProgramManager">
            <a class="btn btn-dark-blue btn-sm mb-3 float-right" title="MEMBER EXPERIENCE">
              Configure Member Experience <fa-icon [icon]="faUsersCog"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <!-- TODO: put this back with data in it.
      <div class="grey-box mb-3"></div>
      -->
      <!-- <div class="row">
        <div class="col-lg-3">
          <dl class="dl-horizontal">
            <div>
              <dt>Status:</dt>
              <dd>
                <span class="badge" [ngClass]="statusHelper[selectedPerson.status]">{{ selectedPerson.status }}</span>
              </dd>
            </div>
            <div>
              <dt>Username:</dt>
              <dd>{{ selectedPerson.username }}</dd>
            </div>
            <div *ngIf="selectedPerson.primaryEmail">
              <div [hidden]="!selectedPerson.primaryEmail.emailAddress">
                <dt>Email:</dt>
                <dd>{{ selectedPerson.primaryEmail.emailAddress }}</dd>
              </div>
            </div>
            <div [hidden]="!selectedPerson.yearStart">
              <dt>Year Start:</dt>
              <dd>{{ selectedPerson.yearStart }}</dd>
            </div>
          </dl>
        </div>
        <div class="col-lg-9" *ngIf="selectedPerson.notes">
          <strong>Notes:</strong>
          <pre class="notes">
            <span class="notes float-left">{{selectedPerson.notes}}</span>
          </pre>
        </div>
      </div> -->
      <!-- <div class="grey-box mb-2"></div> -->
      <div class="col-12 p-0 mb-3">
        <div class="d-flex justify-content-between dyn-bg">
          <div *ngFor="let stat of statistics" class="dyn-bgn">
            <div class="grey-box">
              <div class="stat-title-container">
                <span class="stat-title">{{ stat.title }}</span>
              </div>
              <div class="stat-count-container">
                <span class="stat-count">{{ stat.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-container">
        <mat-tab-group class="remove-border-bottom" animationDuration="0ms" (selectedTabChange)="tabClick($event)">
          <mat-tab label="PROGRAMS">
            <app-campaign-tab [personId]="personId"></app-campaign-tab>
          </mat-tab>
          <mat-tab label="CONTACTS">
            <app-contacts-tab [personId]="personId"></app-contacts-tab>
          </mat-tab>
          <mat-tab label="ATTRIBUTES">
            <app-party-attributes-tab [personId]="personId"></app-party-attributes-tab>
          </mat-tab>
          <mat-tab label="IDENTIFIER TYPES">
            <app-identifier-types-tab
              [personId]="personId"
              [orgUsername]="selectedPerson.username"
            ></app-identifier-types-tab>
          </mat-tab>
          <!-- <mat-tab label="Sponsorship Groups">
              <app-sponsorship-groups-tab
                [personId]="selectedPerson.id"
                [sponsorName]="selectedPerson.name"
              ></app-sponsorship-groups-tab>
          </mat-tab>

          <mat-tab label="Segments">
            <app-segments-tab [personId]="personId" [halfSize]="true"></app-segments-tab>
          </mat-tab> -->
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
