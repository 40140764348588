import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faTrashAlt, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonsService } from './../services/persons.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { Attribute } from '../data/model';
import { AppDataService } from '../app-data.service';

@Component({
  selector: 'app-attributes-details-modal',
  templateUrl: './attributes-details-modal.component.html',
  styleUrls: ['./attributes-details-modal.component.scss'],
  providers: [DatePipe],
})
export class AttributesDetailsModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() attributes: any[];
  @Input() personId: number;
  @Input() allowedToDeleteAttribs: boolean;

  expandedLookup: any = {};
  title = 'More Details';
  faTrashAlt = faTrashAlt;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  isProgramManager: boolean;
  info = false;

  constructor(
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private globalData: AppDataService,
    private personsService: PersonsService
  ) {}

  ngOnInit(): void {
    this.attributes = this.attributes.sort(
      (a: any, b: any) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
    );
    this.isProgramManager = this.globalData.isProgramManager;
  }

  cancel(): void {
    this.successEvent.emit();
    this.modalHelper.dismissAll();
  }

  deleteDetails(attributeDetail: any): void {
    console.log('attributeDetail--', attributeDetail);

    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Remove attribute detail with name: ' + attributeDetail.attrName + '?';
    instance.componentInstance.header = 'Remove attribute detail';
    instance.componentInstance.title = 'Attributes';
    instance.componentInstance.closeOnCancel = false;
    instance.componentInstance.info = !this.info;
    instance.componentInstance.nametag = attributeDetail.attrName;
    instance.componentInstance.name = 'Delete attribute ' + attributeDetail.attrName;
    instance.componentInstance.successEvent.subscribe((data: string) => {
      if (data === 'success') {
        this.personsService.deleteAttribute(this.personId, attributeDetail.attribValueId).subscribe(
          () => {
            this.toastr.success('Attribute detail successfully removed');
            const index = this.attributes.findIndex(
              (detail: any) => detail.attribValueId === attributeDetail.attribValueId
            );
            this.attributes.splice(index, 1);
            instance.close();
            if (this.attributes.length === 0) {
              this.cancel();
            }
          },
          (res: any) => {
            this.toastr.error(res.message);
          }
        );
      } else {
        instance.close();
      }
    });
  }

  getEffectiveToDisplay(attr: Attribute): string {
    const effectiveToDateStr = new Date(attr.effectiveTo);
    if (effectiveToDateStr > new Date('2099-12-31') || effectiveToDateStr < new Date('1980-12-31')) {
      return '';
    } else {
      return attr.effectiveTo;
    }
  }

  provenanceAsString(attribute: any): string {
    if (!attribute.dataProvenance) {
      return '';
    }
    return Object.keys(attribute.dataProvenance)
      .filter((key: string) => ![null, undefined, ''].includes(attribute.dataProvenance[key]))
      .map((key: string) => {
        if (key === 'timeStamp') {
          return (
            key + ': ' + this.datePipe.transform(new Date(attribute.dataProvenance[key]), 'MM/dd/yyyy h:mm a', '+0000')
          );
        }
        return key + ': ' + attribute.dataProvenance[key];
      })
      .join(' , ');
  }
}
