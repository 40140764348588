import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TableHelperService } from '../services/helpers/table-helper.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Filter, Choice, SearchFilters } from '../data/class';
import { UsersService } from '../services/users.service';
import { User } from '../data/model';
import { PartyRoleService } from '../services/party-role.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  statuses: Choice[] = [];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  users: any[] = [];
  promotions: any[] = [];
  lockedTables: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  filteredStatuses: Choice[] = [];
  disabled: boolean;
  show: boolean;
  searchFilters: SearchFilters;
  roles: any[] = [];
  statusHelper: any;
  openTicket: any; // TODO get value for opetTicket variable
  selectedPersonId: number;

  constructor(
    private tableHelperService: TableHelperService,
    private responseHelper: ResponseHelperService,
    private usersService: UsersService,
    private statusHelperService: StatusHelperService,
    private partyRoleService: PartyRoleService,
    private title: Title,
    private modalHelper: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Users');
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.partyRoleService.getAccessRole().subscribe((roles: any) => {
      this.roles = roles.entity.filter((role: any) => (role.roleName !== 'ADMIN') && (role.roleName !== 'SYSTEM_USER') && (role.roleName !== 'MEMBER'));
      console.log(this.roles);
      this.searchFilters = {
        title: 'User Management',
        formName: 'userSearch',
        searchCallback: () => {
          this.initNewSearch();
        },
        filters: [
          {
            name: 'segmentless',
            type: 'segmentless',
            label: 'Only Segment-less',
          },
          {
            name: 'search',
            placeholder: 'Keyword...',
          },
          {
            name: 'statuses',
            placeholder: 'Status',
            type: 'array',
            choices: this.tableHelperService.getStatuses('ENTITY'),
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
          },
          {
            name: 'roles',
            placeholder: 'Role',
            type: 'array',
            choices: this.roles,
            nameProp: 'roleName',
            inputType: 'dropdown',
            valueProp: 'roleName',
          },
          {
            name: 'segmentIds',
            type: 'array',
            inputType: 'segment-filter',
            singleLabel: 'Segment',
          },
        ],
        newUser: () => {
          this.newUser();
        },
        addEntityButtons: [
          {
            icon: faPlus,
            buttonText: 'New Partner',
            if: true,
            callback: () => {
              this.newUser();
            },
            title: 'New user',
          },
        ],
      };
      this.show = true;
    });
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getUsers(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getUsers(false);
  }

  clearAllFilters(): void {
    this.searchFilters.clearAll();
  }

  getUsers(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.usersService.getUsers(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.users = this.users.concat(data.entity);
        } else {
          this.selectedPersonId = null;
          this.users = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  newUser(): void {
    const instance = this.modalHelper.open(UserModalComponent);
    instance.componentInstance.roles = this.roles;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data.success) {
        this.responseHelper.success('User successfully created', true);
        this.initNewSearch();
      }
    });
  }

  selectUser(event: any, user: User): any {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    if (this.searchFilters.getFilters(this.filter).segmentless) {
      this.toastr.warning("Error retrieve users Can not access user, segments don't match");
    } else {
      this.selectedPersonId = user.id;
    }
  }

  goToUser(event: any, user: User): void {
    event.preventDefault();
    if (user.segmentsCollection && user.segmentsCollection.length > 0) {
      this.router.navigate(['/users/' + user.id]);
    }
  }

  navigateToTab(tabId: number): void {
    this.router
      .navigate(['/members/' + this.selectedPersonId], { fragment: tabId === 5 ? 'contacts' : 'accounts' })
      .finally(() => {
        this.router.onSameUrlNavigation = 'ignore'; // Restore config after navigation completes
      });
  }
}
