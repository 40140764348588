<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <div>
        <div class="row"></div>
        <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
        <div class="row table-instrument-types m-b">
          <table class="table table-pointable table-bordered" width="100%">
            <thead>
              <tr>
                <th>ID</th>
                <th>Code</th>
                <th>Ext Code</th>
                <th>Description</th>
                <th>Status</th>
                <th *ngIf="isAdmin">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let it of instrumentTypes">
                <td>{{ it.id }}</td>
                <td>{{ it.code }}</td>
                <td>{{ it.extCode }}</td>
                <td>{{ it.description }}</td>
                <td>{{ it.status }}</td>
                <td *ngIf="isAdmin">
                  <div class="actions inline-table float-left">
                    <a
                      class="btn btn-dark-blue-action btn-sm edit action"
                      (click)="instrumentTypeModal(it.id)"
                      title="Edit"
                      ><fa-icon [icon]="faPencilAlt"></fa-icon
                    ></a>
                    <button
                      class="btn btn-attention btn-sm delete action"
                      [disabled]="it.status === 'DELETED'"
                      (click)="deleteInstrumentType(it)"
                      title="Delete"
                    >
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="isAdmin ? 6 : 5" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list
          (onScroll)="getInstrumentTypes(true)"
          [lock]="lock"
          [isLoading]="isLoading"
        ></app-add-more-data-to-list>
      </div>
    </div>
  </div>
</div>
