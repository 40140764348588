<form [formGroup]="emailForm" novalidate class="com-model">
  <div class="com-outreach-modal">
    <div class="mb-2">
      <span class="title">Contacts</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-4 mt-4">
      <span class="com-title">{{ title }}</span>
    </div>
    <div class="">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Email address <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input
              type="email"
              name="email"
              formControlName="emailAddress"
              class="form-control com-label"
              autofocus
              placeholder="Add email"
            />
            <div class="mt-1" [hidden]="emailForm.controls.emailAddress.valid">
              <!-- <small class="text-danger">Input a valid email address</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Contacts name <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input
              type="text"
              name="email_name"
              formControlName="addressName"
              class="form-control com-label"
              placeholder="Add name"
            />
            <div class="mt-1" [hidden]="emailForm.controls.addressName.valid">
              <!-- <small class="text-danger">Input email name</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group form-check">
            <input
              type="checkbox"
              formControlName="isPrimary"
              id="isPrimary"
              class="form-check-input com-input-check"
            />
            <label for="isPrimary" class="com-label-primary">Primary</label>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn-container mt-4">
      <button class="btn-pop-cancel" type="button" (click)="cancel()">CANCEL</button>
      <button class="btn-pop-success" type="submit" (click)="ok()" [disabled]="!emailForm.valid || !emailForm.dirty">
        {{ isEdit ? "SAVE" : "ADD" }}
      </button>
    </div>
  </div>
</form>
