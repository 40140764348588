import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContentGroupService } from '../../../services/content-group.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from '../../../services/communications.service';
import { ContentGroup } from 'src/app/data/model';

@Component({
  selector: 'app-content-group-modal',
  templateUrl: './content-group-modal.component.html',
  styleUrls: ['./content-group-modal.component.scss'],
})
export class ContentGroupModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public contentGroup: ContentGroup;
  title: string;
  isEdit: boolean;
  contentGroupForm: FormGroup;
  errorMsg: string;
  faTimes = faTimes;
  deliveryProtocols = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private contentGroupService: ContentGroupService
  ) {}

  ngOnInit(): void {
    this.isEdit = this.contentGroup.id ? true : false;
    this.title = this.contentGroup.name ? 'Edit content  group' : 'Create content group';
    this.contentGroupForm = this.formBuilder.group({
      name: [this.contentGroup.name, Validators.required],
      deliveryProtocolId: [{ value: null, disabled: this.isEdit }, Validators.required],
    });
    this.getDeliveryProtocols();
  }

  ok(): void {
    let observer = null;
    if (this.isEdit) {
      const contentGroup: ContentGroup = new ContentGroup();
      contentGroup.id = this.contentGroup.id;
      contentGroup.name = this.contentGroupForm.value.name;
      contentGroup.deliveryProtocolId = this.contentGroupForm.controls.deliveryProtocolId.value;
      observer = this.contentGroupService.updateContentGroup(this.contentGroup.id, contentGroup);
    } else {
      observer = this.contentGroupService.createContentGroup(this.contentGroupForm.value);
    }
    observer.subscribe(
      () => {
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.errorMsg = data.message;
      }
    );
  }

  getDeliveryProtocols(): void {
    this.communicationsService.getDeliveryProtocols({ includeInit: false }).subscribe((data: any) => {
      if (data.success) {
        this.deliveryProtocols = data.entity;
        const deliveryProtocolId = this.deliveryProtocols.find(
          (e) => e.name === this.contentGroup.deliveryProtocolName
        )?.id;
        this.contentGroupForm.patchValue({ deliveryProtocolId });
      }
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
