import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class NutritionService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getNutritionTags(): any {
    return this.http.get<any>(this.globalData.baseUrl + 'nutrition/tags');
  }

  getNutritionTagMappings(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'nutrition/tag/' + id + '/groups/');
  }

  deleteNutritionTagMappings(mappingId: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'nutrition/mappings/' + mappingId);
  }

  deleteNutritionTagMappingsGroup(tagId: number, groupId: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'nutrition/tag/' + tagId + '/groups/' + groupId);
  }

  createOrUpdateGroup(tagId: number, groupId: number, data: any): any {
    if (groupId) {
      return this.http.put<any>(this.globalData.baseUrl + 'nutrition/tag/' + tagId + '/groups/' + groupId, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'nutrition/tag/' + tagId + '/groups/', data);
  }

  createOrUpdateMappingForGroup(groupId: number, mappingId: number, data: any): any {
    if (mappingId) {
      return this.http.put<any>(
        this.globalData.baseUrl + 'nutrition/groups/' + groupId + '/mappings/' + mappingId,
        data
      );
    }
    return this.http.post<any>(this.globalData.baseUrl + 'nutrition/groups/' + groupId + '/mappings', data);
  }
}
