import { TableRowProps } from "@iridium/iridium-ui/Table/TableRow";
import { LeadingInstructionData } from "../services/callflow.service";

export default function createQuestionData(props: LeadingInstructionData): TableRowProps {
  const { leadingExpectedResponseList, leadingInstructionId } = props;
  const expectedResponse = leadingExpectedResponseList[0];
  return {
    rowId: "row-leadingInstruction" + leadingInstructionId,
    variant: "body",
    cellsPropList: [
      { cellId: "cellid-leadingInstructionId", content: leadingInstructionId },
      { cellId: "cellid-leadingExpectedResponseList", content: leadingExpectedResponseList[0].id },
      {
        cellId: "cellid-leadingExpectedResponseTags",
        content: expectedResponse.platformMetaData.tags.join(", "),
      },
    ],
  };
}
