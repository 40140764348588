import { AppDataService } from './../app-data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseHelperService } from './helpers/response-helper.service';
import { Organization, OrganizationPartyId } from '../data/model';
import { mapHttpObservableToPromise, EntityResponsePromise, EntityResponse } from './http.service';
import { EntityPartyRoleName } from '../organizations/services/url-builder.service';
import { OrganizationStatistics } from '../data/model';

export type GetAllHierarchicalOrganizationsForParentParams = {
  parentName: string;
  parentOrgId: number;
  partyRoleName?: string;
  offset: number;
  limit: number;
  order?: string;
  keyword?: string;
  statuses?: string[];
  logoUrl?: string;
  externalOrgCode?: string;
};

export type CreateOrUpdateOrganizationParams = {
  name: string;
  status: string;
  yearStart: string; // Format: MM/dd
  parentCode: string;
  parentId: number;
  partyRoles?: { roleName: EntityPartyRoleName }[];
  url: string;
  logoUrl?: string;
  externalOrgCode?: string;
};

export type GetOrganizationStatisticsParams = {
  sponsorPartyId: OrganizationPartyId;
};

@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {
  private organizationStatisticsPath = `${this.globalData.baseUrl}sponsors/statistics`;
  private organizationPath = `${this.globalData.baseUrl}organizations`;
  private organizationHierarchicalPath = `${this.organizationPath}/hierarchical`;

  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getAllHierarchicalOrganizationsForParent(
    params: GetAllHierarchicalOrganizationsForParentParams
  ): EntityResponsePromise<Organization[]> {
    const observable = this.http.get<EntityResponse<Organization[]>>(
      this.organizationHierarchicalPath,
      this.setParams(params)
    );
    //@ts-ignore
    return mapHttpObservableToPromise<Organization[]>(observable);
  }

  getHierarchicalOrganization(id: string | number): EntityResponsePromise<Organization> {
    const observable = this.http.get<any>(`${this.organizationHierarchicalPath}/${id}`);
    return mapHttpObservableToPromise<Organization>(observable);
  }

  createOrUpdateOrganization(params: CreateOrUpdateOrganizationParams): EntityResponsePromise<unknown> {
    const observable = this.http.post<any>(this.organizationHierarchicalPath, params);
    return mapHttpObservableToPromise(observable);
  }

  getOrganizationStatistics({ sponsorPartyId }): EntityResponsePromise<OrganizationStatistics> {
    const observable = this.http.get<any>(`${this.organizationStatisticsPath}?sponsorPartyId=${sponsorPartyId}`);
    return mapHttpObservableToPromise<OrganizationStatistics>(observable);
  }

  getOrgByUsername(username: string): EntityResponsePromise<Organization> {
    const observable = this.http.get<any>(`${this.organizationPath}/by-username/${username}`);
    return mapHttpObservableToPromise<Organization>(observable);
  }

  getOrgByExternalCode(externalOrgCode: string): EntityResponsePromise<Organization> {
    const observable = this.http.get<any>(`${this.organizationPath}/hierarchical/external-code/${externalOrgCode}`);
    return mapHttpObservableToPromise<Organization>(observable);
  }

  getMarketsForUser(): any {
    return this.http.get<Organization>(`${this.organizationHierarchicalPath}/markets`);
    /*const observable = this.http.get<any>(`${this.organizationHierarchicalPath}/markets`);
    return mapHttpObservableToPromise<Organization>(observable);*/
  }
}
