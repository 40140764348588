import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OutboundService } from '../../services/outbound.service';
import $ from 'jquery';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-outbound-stage-exports',
  templateUrl: './outbound-stage-exports.component.html',
  styleUrls: ['./outbound-stage-exports.component.scss'],
})
export class OutboundStageExportsComponent implements OnInit {
  isLoading = true;
  filter = new Filter();
  lockedTables = false;
  lock = false;
  filteredImportStatus: any[] = [];
  selectedLoadId: number;
  selectedOutboundId: number;
  limit = 20;
  statuses: Choice[];
  stageExport: any;
  lengthToCompare: number;
  faTimes = faTimes;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  searchFilters: SearchFilters;

  constructor(
    private titleHelper: Title,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private router: Router,
    private route: ActivatedRoute,
    private outboundService: OutboundService,
    private toastr: ToastrService
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'outbound-stage-exports-search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
    this.titleHelper.setTitle('Outbound Exports');

    this.selectedLoadId = parseInt(this.route.snapshot.paramMap.get('loadId'), 10);
    this.selectedOutboundId = parseInt(this.route.snapshot.paramMap.get('outboundId'), 10);

    if (!this.selectedLoadId || !this.selectedOutboundId) {
      this.router.navigate(['outbounds']);
    }

    this.initFilter();
    this.getStatuses();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getStageExport(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('IMPORT');
  }

  getStageExport(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.outboundService.getStageExport(this.selectedOutboundId, this.selectedLoadId, this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.stageExport = this.stageExport.concat(data.entity.aaData);
        } else {
          this.stageExport = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  changeFilter(filteredStatuses: any[], statuses: any[], status: any, promotionInstance: any, $event: any): void {
    this.tableHelperService.changeFilter(filteredStatuses, statuses, status, promotionInstance, $event);
    this.setStatuses();
  }

  filterStageExport(keyword: string): void {
    this.initFilter();
    this.filter.search = keyword;
    this.getStageExport(false);
  }

  clearKeyword(): void {
    delete this.filter.search;
    this.initFilter();
    this.getStageExport(false);
  }

  clearAllFilters(): void {
    this.filteredImportStatus = [];
    this.getStatuses();
    this.filter = new Filter();
    this.initFilter();
    this.getStageExport(false);
  }

  setStatuses(): void {
    this.filter.statuses = this.tableHelperService.extractData(this.filteredImportStatus);
    this.getStageExport(false);
    this.initFilter();
  }

  showStageExport(event: any, o: any): void {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
  }
}
