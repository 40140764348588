import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
  @Input() section: any;
  @Input() filter: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();
  selection: any;
  values: string[] = [];
  height = '2%';

  constructor() {}

  ngOnInit(): void {
    this.selection = this.filter.value;
  }

  clearOne(value: string): void {
    if (this.filter.multiple) {
      this.selection = this.selection.filter((e: string) => e !== value);
    } else {
      this.selection = null;
    }
    this.filter.value = this.selection;
    this.filter.selection = this.selection;
  }

  clearSelect(): void {
    if (this.filter.multiple) {
      this.filter.value = [];
      this.filter.selection = [];
      this.selection = [];
    } else {
      this.filter.value = null;
      this.filter.selection = null;
      this.selection = null;
    }
  }

  changeFn(val): void {
    this.filter.value = val;
    if (this.filter.multiple) {
      this.filter.selectedCount = this.filter.value.length;
      this.height = 2 + 1.5 * this.filter.selectedCount + '%';
    }
    this.inputChangeEmitter.emit();
  }

  clearAll(): void {
    if (this.filter.multiple) {
      this.filter.value = [];
    } else {
      this.filter.value = null;
    }
    this.filter.selectedCount = 0;
    this.inputChangeEmitter.emit();
  }
}
