import { Observable } from 'rxjs';
import { UserPreferencesService } from './../../services/user-preferences.service';
import { SecurityService } from 'src/app/services/security/security.service';
import { UploadIntegrationModalComponent } from './../upload-integration-modal/upload-integration-modal.component';
import { IntegrationModalComponent } from './../integration-modal/integration-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { HttpClient } from '@angular/common/http';
import { Integration } from './../../data/model';
import { IntegrationsService } from './../../services/integrations.service';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { Filter, SearchFilters } from './../../data/class';
import { AppDataService } from './../../app-data.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  faPlus,
  faSearchPlus,
  faSpinner,
  faUpload,
  faCogs,
  faPencilAlt,
  faExchangeAlt,
  faEnvelope,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss'],
})
export class IntegrationsComponent implements OnInit, OnDestroy {
  customers: any[];
  filter = new Filter();
  limit = 20;
  isLoading = false;
  integrations: Integration[];
  lengthToCompare: number;
  selectedIntegration: Integration;
  searchFilters: SearchFilters;
  lockedTables = false;
  lock = false;
  faPlus = faPlus;
  faSearchPlus = faSearchPlus;
  faSpinner = faSpinner;
  faUpload = faUpload;
  faCogs = faCogs;
  faPencilAlt = faPencilAlt;
  faExchangeAlt = faExchangeAlt;
  faEnvelope = faEnvelope;
  faFolderOpen = faFolderOpen;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  filterdata: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  helpers: any;
  searchText: any;
  constructor(
    private titleHelper: Title,
    private globalData: AppDataService,
    private tableHelperService: TableHelperService,
    private integrationsService: IntegrationsService,
    private http: HttpClient,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private securityService: SecurityService,
    private userPreferencesService: UserPreferencesService,
    private statusHelperService: StatusHelperService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService
  ) {}
  isProgramManager = this.globalData.isProgramManager;
  isAdmin = this.globalData.isAdmin;
  isAccountManager = this.globalData.isAccountManager;
  statuses = this.tableHelperService.getStatuses('ENTITY');
  statusHelper = this.statusHelperService.getStatus('badge');
  numColumns = this.isAdmin ? 8 : 7;
  getAllCustomers = new Observable((observer: any) => {
    const resultArray: any[] = [];
    this.integrationsService.getAllCustomers().subscribe((data) => {
      if (data.success) {
        data.entity.forEach((name: string, idx: number) => {
          const customer: any = {};
          customer.name = name;
          customer.id = idx;
          resultArray.push(customer);
        });
      }
      observer.next(resultArray);
    });
  });
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }
  ngOnInit(): void {
    this.titleHelper.setTitle('Integrations');
    this.getAllCustomers.subscribe((data: any) => {
      this.customers = data;
      this.searchFilters = {
        waitUntilApply: true,
        formName: 'integrations_search',
        filters: [
          {
            name: 'search',
            placeholder: 'Keyword...',
          },
          {
            name: 'statuses',
            placeholder: 'Status',
            type: 'array',
            choices: this.statuses,
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
          },
          {
            name: 'customers',
            type: 'array',
            placeholder: 'Customers...',
            nameProp: 'name',
            valueProp: 'name',
            inputType: 'select',
            multiple: true,
            choices: this.customers,
          },
        ],
        searchCallback: () => {
          this.initNewSearch();
        },
      };
      this.initNewSearch();
    });
    this.tableHelperService.showTitle();
  }
  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getIntegrations(false);
  }
  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };
  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Integrations';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
    });
  }
  getIntegrations(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.integrationsService.getIntegrations(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.integrations = this.integrations.concat(data.entity);
        } else {
          this.integrations = data.entity;
          this.selectedIntegration = null;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }
  uploadTransformer(element): void {
    const fd = new FormData();
    fd.append('file', element.files[0]);
    const id = element.attributes.getNamedItem('id-el').value;
    this.http
      .post<any>(this.globalData.baseUrl + 'integrations/' + id + '/transformer', fd, {
        withCredentials: true,
      })
      .subscribe(
        () => {
          this.responseHelperService.success('Successfully uploaded file', false);
          this.initNewSearch();
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }
  showIntegration(e, integ): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedIntegration = integ;
  }
  isShownIntegration(): boolean {
    return !!this.selectedIntegration;
  }
  integrationModal(id?: number): void {
    if (id) {
      this.integrationsService.getIntegration(id).subscribe((integration) => {
        if (integration.success) {
          const instance = this.modalHelper.open(IntegrationModalComponent);
          instance.componentInstance.integration = integration.entity;
          instance.componentInstance.customers = this.customers;
          instance.componentInstance.isAdmin = this.isAdmin;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
            instance.close();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(IntegrationModalComponent);
      instance.componentInstance.integration = null;
      instance.componentInstance.customers = this.customers;
      instance.componentInstance.successEvent.subscribe(() => {
        this.initNewSearch();
        instance.close();
      });
    }
  }
  // TODO:
  uploadModal(id: number, e): void {
    if (id) {
      this.integrationsService.getIntegration(id).subscribe((integration) => {
        if (integration.success) {
          const instance = this.modalHelper.open(UploadIntegrationModalComponent);
          instance.componentInstance.integration = integration.entity;
          // var instance = modalHelper.openModal($uibModal, 'uploadIntegrationModal', integration.entity);
          // instance.result.then(function () {
          //   initNewSearch();
          // }).catch(function (res) {
          //   if (!(res === 'cancel' || res === 'escape key press')) {
          //     throw res;
          //   }
          // });
        }
      });
    } else {
      // var instance = modalHelper.openModal($uibModal, 'uploadIntegrationModal');
      // instance.result.then(function () {
      //   initNewSearch();
      // }).catch(function (res) {
      //   if (!(res === 'cancel' || res === 'escape key press')) {
      //     throw res;
      //   }
      // });
    }
  }
}
