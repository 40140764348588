import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AddressModel } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getPrimaryPartyAddress(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-address/' + partyId + '/primary');
  }

  getPartyAddress(partyId: number, addrId: number): any {
    return this.http.get(this.globalData.baseUrl + 'party-address/' + partyId + '/' + addrId);
  }

  createOrUpdateAddresss(partyId: number, id: number, address: AddressModel): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'party-address/' + partyId + '/' + id, address);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'party-address/' + partyId, address);
  }

  getPartyAddresses(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'party-address/party/' + id);
  }

  deletePartyAddress(partyId: number, addrId: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'party-address/' + partyId + '/' + addrId);
  }

  getAddressData(): any {
    return this.http.get(this.globalData.baseUrl + 'party-address/data');
  }
}
