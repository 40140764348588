import { Component, Input, OnInit } from '@angular/core';
import { MountReactParams } from '../services/react.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ContentService } from '../services/content/content.service';
import { QuestionLibraryView, QuestionLibraryViewProps } from './QuestionLibrary';

@Component({
  template: `<app-mount-react [mountReactParams]="mountReactParams"></app-mount-react>`,
})
export class QuestionLibraryComponent implements OnInit {
  @Input() personId: number;
  public mountReactParams: MountReactParams<QuestionLibraryViewProps> = {
    mountId: 'question-library-view-mount',
    component: QuestionLibraryView,
    props: {
      toastr: this.toastr,
      route: this.route,
      navigate: this.router.navigate.bind(this.router),
      contentService: this.contentService,
    },
  };

  constructor(
    private title: Title,
    // private appDataService: AppDataService,
    private contentService: ContentService,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Question Library');
  }
}
