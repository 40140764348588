import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { InstructionVariant } from "../models/model";
import IconButton from "@iridium/iridium-ui/Button/IconButton";
import Grid from "@iridium/iridium-ui/Grid";

export default function WavDataTable(props: {
  selectedVariant: InstructionVariant;
  onPlayWav: (audioFile?: string) => void;
}) {
  const { onPlayWav, selectedVariant } = props;
  console.log(selectedVariant);

  return (
    <TableContainer sx={{ bgcolor: "background.paper", overflow: "hidden" }}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "5%" }} />
            <TableCell>File Name</TableCell>
            <TableCell style={{ width: "30%" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedVariant.prompts.map((wavFile, i) => (
            <TableRow key={i.toString() + "-row"}>
              <TableCell>
                <Grid
                  container
                  sx={{
                    ".MuiIconButton-root.MuiIconButton-colorPrimary.MuiIconButton-sizeSmall": {
                      borderWidth: "0px !important",
                    },
                  }}
                >
                  <Grid item>
                    <IconButton
                      key={(wavFile.audioFile || "") + "-up"}
                      style={{ marginTop: "-12px", marginBottom: "-2px" }}
                    >
                      <KeyboardArrowUpIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      key={(wavFile.audioFile || "") + "-down"}
                      style={{ marginBottom: "-12px", marginTop: "-2px" }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell style={{ borderLeft: "none" }}>
                {wavFile.type === "AUDIO"
                  ? wavFile.audioFile
                  : wavFile.type + ":" + wavFile.personalizationInformationType}
              </TableCell>

              <TableCell align="right" style={{ borderLeft: "none !important", display: "flex" }}>
                {wavFile.type === "AUDIO" ? (
                  <IconButton onClick={() => onPlayWav(wavFile.audioFile)}>
                    <PlayArrowIcon />
                  </IconButton>
                ) : null}
                <IconButton>
                  <CreateIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
