import Button from "@iridium/iridium-ui/Button";
import { Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions } from "@mui/material";
import React, { FormEvent, useState } from "react";
import { ContentService } from "../services/content/content.service";

type State = {
  stackApiKey?: string;
  stackToken?: string;
};

export function ContentStackCredentialsDialog(props: {
  isModalOpen: boolean;
  setIsModalOpen: (open: boolean) => void;
  contentService: ContentService;
}): JSX.Element {
  const [state, setState] = useState<State>({});

  function handleModalClose() {
    props.setIsModalOpen(false);
  }

  function handleFormSubmit(event: FormEvent) {
    event.preventDefault();
    props.contentService.setCredentials(state.stackApiKey || "", state.stackToken || "");
    props.setIsModalOpen(false);
  }

  return (
    <Dialog open={props.isModalOpen} onClose={handleModalClose}>
      <form onSubmit={handleFormSubmit}>
        <DialogTitle>
          Set ContentStack Credentials <hr />
        </DialogTitle>

        <DialogContent>
          <Grid container columnSpacing={1} rowSpacing={2} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                sx={{ width: "50%" }}
                id="stackKey"
                onChange={(event) => {
                  setState({
                    ...state,
                    stackApiKey: event.target.value,
                  });
                }}
                value={state.stackApiKey || ""}
                label={"Stack API Key"}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ width: "50%" }}
                id="stackToken"
                onChange={(event) => {
                  setState({
                    ...state,
                    stackToken: event.target.value,
                  });
                }}
                value={state.stackToken || ""}
                label={"Stack Secret Token"}
                type="password"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Apply</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
