import { AppDataService } from 'src/app/app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailModel } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getPrimaryPartyEmail(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-emails/' + id + '/primary');
  }

  getPrimaryOrLatestPartyEmail(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-emails/' + partyId + '/primary-or-latest');
  }

  createOrUpdateEmail(id: number, email: EmailModel): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'party-emails/' + id, email);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'party-emails/', email);
  }

  getPartyEmails(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'party-emails/party/' + id);
  }

  deletePartyEmail(id: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'party-emails/' + id);
  }

  getPartyEmail(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-emails/' + id);
  }
}
