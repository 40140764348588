<div>
  <div *ngIf="show">
    <div>
      <div class="row">
        <div class="col-sm-12">
          <div class="filters_container">
            <div class="search-input2">
              <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
              <mat-icon class="search">search</mat-icon>
            </div>
            <div class="right_container">
              <button class="filter-btn mr-4" (click)="FilterModel()">
                FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
              </button>
            </div>
          </div>
          <app-filter-tags
            *ngIf="searchFilters"
            isPopup="true"
            [(filterButton)]="filterButton"
            [(appliedFilters)]="appliedFilters"
            [(searchFilters)]="searchFilters"
          ></app-filter-tags>
          <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-pointable table-bordered">
            <thead>
              <tr>
                <th class="table_field_name">Report name</th>
                <th class="table_field_name">Report notes</th>
                <th class="table_field_name">Requested date</th>
                <th class="table_field_name">Status</th>
                <th class="table_field_name">Format</th>
                <th class="table_field_name">Error message</th>
                <th class="table_field_name">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let reportResult of reportResults | filter: searchText; let i = index">
                <!-- <td>{{ reportResult.id }}</td> -->
                <td>{{ reportResult.reportName }}</td>
                <td>{{ reportResult.notes }}</td>
                <!-- <td *ngIf="isAdmin">{{ reportResult.userName }}</td> -->
                <td>{{ reportResult.startedOn | date: "yyyy-MM-dd hh:mm:ss" }}</td>
                <td>{{ reportResult.status }}</td>
                <td>{{ reportResult.extension }}</td>
                <td>{{ reportResult.errorMessage }}</td>
                <!-- <td>
                  <a
                    [hidden]="!reportResult.downloadLink"
                    (click)="download($event, reportResult.downloadLink)"
                    href="{{ reportResult.downloadLink }}"
                    class="btn btn-primary btn-sm m-l float-right"
                    >Download</a
                  >
                </td> -->
                <!-- <td>
                  <div class="float-left actions" class="inline-table">
                    <button
                      class="btn btn-dark-blue btn-sm action"
                      (click)="viewReportParams(reportResult)"
                      title="View Report Parameters"
                    >
                      <fa-icon [icon]="faEye"></fa-icon>
                    </button>
                  </div>
                </td> -->
                <td>
                  <a
                    [hidden]="!reportResult.downloadLink"
                    (click)="download($event, reportResult.downloadLink)"
                    href="{{ reportResult.downloadLink }}"
                    class="btn btn-dark-blue-action btn-sm ml-1"
                    ><fa-icon [icon]="faDownload"></fa-icon
                  ></a>
                  <a
                    class="btn btn-dark-blue-action btn-sm ml-1"
                    (click)="viewReportParams(reportResult)"
                    title="View Report Parameters"
                    ><fa-icon [icon]="faEye"></fa-icon
                  ></a>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
      </div>
      <app-add-more-data-to-list (onScroll)="getReportResults(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
