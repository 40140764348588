<div class="filters_container2">
  <div class="right_container3">
    <button class="filter-btn" (click)="FilterModel()">FILTERS<mat-icon class="ml-2">filter_list</mat-icon></button>
    <button class="btn-add" (click)="newCommunication()">ADD OUTREACH</button>
  </div>
</div>
<app-filter-tags
  *ngIf="searchFilters"
  isPopup="true"
  [(filterButton)]="filterButton"
  [(searchFilters)]="searchFilters"
  [(appliedFilters)]="appliedFilters"
></app-filter-tags>
<div>
  <table class="table table-pointable table-bordered">
    <thead>
      <tr>
        <th class="table_field_name">Name</th>
        <!-- <th class="table_field_name">Total</th> -->
        <th class="table_field_name">Status</th>
        <th class="table_field_name">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let comm of communications">
        <td>
          <a routerLink="/communications/{{ comm.id }}" class="link_next_page">{{ comm.name }}</a>
        </td>
        <!-- <td class="secondary_txt"></td> -->
        <td class="secondary_txt">{{ comm.status }}</td>
        <td>
          <div class="panel-container">
            <button class="btn btn-outline-primary btn-sm edit action" (click)="communicationsModal(comm)" title="Edit">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </button>
          </div>
        </td>
      </tr>
      <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent"></tr>
    </tbody>
  </table>
  <app-add-more-data-to-list (onScroll)="getCommunications(true)" [lock]="lock" [isLoading]="isLoading">
  </app-add-more-data-to-list>
</div>
