<div class="com-segment-modal">
  <div>
    <span class="title">Segment Mappings</span>
    <hr class="mb-1" />
  </div>
  <div class="mb-4 mt-4">
    <span class="com-title">Select Default Field</span>
  </div>
  <div>
    <div>
      <div class="d-flex justify-content-between">
        <label class="com-label">Select default segment field</label>
      </div>
      <app-segment-tree-modal
        [selectedCode]="segmentCode"
        automaticFetch="true"
        (filterSegments)="(null)"
        maxWidth="true"
        ngbDropdownClass="w-100"
        class="w-100"
        [allowMultiple]="false"
        (selectedSegmentChange)="updateSelectedSegment($event)"
      ></app-segment-tree-modal>
    </div>
  </div>
  <div class="pop-btn-container mt-4">
    <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
    <button ngbAutofocus class="btn-pop-success" type="submit" (click)="ok()" title="Save">SAVE</button>
  </div>
</div>
