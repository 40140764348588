<div class="panel-body">
  <div class="row">
    <!-- <div class="col-md-4 mb-2">
      <div class="mt-5 btn-group">
        <button
          class="btn btn-default"
          [ngClass]="{ active: show == 'LIST' }"
          (click)="show = 'LIST'"
          title="Show list layout"
        >
          <fa-icon [icon]="faList"></fa-icon>
        </button>
        <button
          class="btn btn-default"
          [ngClass]="{ active: show == 'CARDS' }"
          (click)="show = 'CARDS'"
          title="Show grid layout"
        >
          <fa-icon [icon]="faTh"></fa-icon>
        </button>
      </div>
    </div> -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
      <div class="filters_container">
        <div class="search-input">
          <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
          <mat-icon class="search">search</mat-icon>
        </div>
        <div class="right_container">
          <button class="filter-btn" (click)="FilterModel()">
            FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
          </button>
          <button class="filter-btn" (click)="importProgramModal()">IMPORT<fa-icon [icon]="faUpload"></fa-icon></button>
          <button class="btn-add" (click)="programModal()">ADD PRODUCT</button>
        </div>
      </div>
      <app-filter-tags
        *ngIf="searchFilters"
        isPopup="true"
        [(appliedFilters)]="appliedFilters"
        [(filterButton)]="filterButton"
        [(searchFilters)]="searchFilters"
      ></app-filter-tags>
      <div class="row" *ngIf="show === 'LIST'">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let program of programs | filter: searchText; let i = index"
              (click)="showProgram($event, program, i)"
            >
              <!-- <td>
                <a routerLink="/programs/{{ program.id }}/promotions/">{{ program.id }}</a>
              </td> -->
              <td>
                <a routerLink="/products/{{ program.id }}/promotions/">{{ program.code }}</a>
              </td>
              <td>{{ program.name }}</td>
              <td>{{ program.status }}</td>
              <td>
                <div class="panel-container">
                  <button
                    class="btn btn-outline-primary btn-sm download action"
                    (click)="exportProgram(program.id)"
                    download="{{ program.name }}.json"
                    title="Export"
                  >
                    <fa-icon [icon]="faDownload"></fa-icon>
                  </button>
                  <a class="btn btn-outline-primary btn-sm edit action" (click)="programModal(program)" title="Edit">
                    <fa-icon [icon]="faPencilAlt"></fa-icon
                  ></a>
                  <button
                    class="btn btn-outline-primary btn-sm delete action"
                    [disabled]="program.status === 'DELETED'"
                    (click)="deleteProgram(program)"
                    title="Delete"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <!-- <div *ngIf="show === 'CARDS'">
        <div *ngFor="let program of programs; let i = index">
          <div class="row" *ngIf="i % 2 === 0">
            <div class="col-md-6 program-card" (click)="showProgram($event, program, i)">
              <div class="row" [ngClass]="colors[i % 4]"></div>
              <div class="row gray-bg program-card-header">
                <span *ngIf="selectedProgram === program" class="program-card-selector" [ngClass]="colors[i % 4]"
                  >SELECTED</span
                >
                <div class="col-md-2 relative-position">
                  <div class="absolute-position top-5">
                    <span class="fa-stack fa-2x">
                      <fa-layers [fixedWidth]="true">
                        <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
                        <fa-icon
                          [inverse]="true"
                          [icon]="getIcon(program)"
                          transform="shrink-8"
                          stackItemSize="2x"
                        ></fa-icon>
                      </fa-layers>
                    </span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="text-uppercase program-card-title" [attr.title]="program.name">
                    <strong>{{ program.name }}</strong>
                  </div>
                </div>
                <div class="col-md-3">
                  <span
                    class="badge status-under float-right program-card-status"
                    [ngClass]="statusHelper[program.status]"
                    >{{ program.status }}</span
                  >
                </div>
              </div>
              <div class="row program-card-body">
                <div class="col-md-2"></div>
                <div class="col-md-10">
                  <pre class="font-bold program-card-pre">{{ program.description }}</pre>
                </div>
              </div>
              <div class="row gray-bg program-card-footer">
                <div class="col-md-2"></div>
                <div class="col-md-4"></div>
                <div class="col-md-6 panel-container">
                  <button
                    class="btn btn-dark-gray btn-xs delete action float-right mr-1"
                    [disabled]="program.status === 'DELETED'"
                    (click)="deleteProgram(program.id)"
                    title="Delete"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                  <button
                    class="btn btn-dark-gray btn-xs download action float-right mr-1"
                    (click)="exportProgram(program.id)"
                    download="{{ program.name }}.json"
                    title="Export"
                  >
                    <fa-icon [icon]="faDownload"></fa-icon>
                  </button>
                  <button
                    class="btn btn-dark-gray btn-xs edit action float-right mr-1"
                    (click)="programModal(program)"
                    title="Edit"
                  >
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </button>
                  <a
                    class="btn btn-dark-gray btn-xs manage action float-right mr-1"
                    routerLink="/programs/{{ program.id }}/promotions/"
                    title="Manage"
                    ><fa-icon [icon]="faCog"></fa-icon
                  ></a>
                </div>
              </div>
              <div class="row" [ngClass]="colors[i % 4]"></div>
            </div>
            <div
              class="col-md-6 program-card"
              (click)="showProgram($event, programs[i + 1], i + 1)"
              *ngIf="programs.length > i + 1"
            >
              <div class="row" [ngClass]="colors[(i + 1) % 4]"></div>
              <div class="row gray-bg program-card-header">
                <span
                  *ngIf="selectedProgram === programs[i + 1]"
                  class="program-card-selector"
                  [ngClass]="colors[(i + 1) % 4]"
                  >SELECTED</span
                >
                <div class="col-md-2 relative-position">
                  <div class="absolute-position top-5">
                    <span class="fa-stack fa-2x">
                      <fa-layers [fixedWidth]="true">
                        <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
                        <fa-icon
                          [inverse]="true"
                          [icon]="getIcon(programs[i + 1])"
                          transform="shrink-8"
                          stackItemSize="2x"
                        ></fa-icon>
                      </fa-layers>
                    </span>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="text-uppercase program-card-title" [attr.title]="programs[i + 1].name">
                    <strong>{{ programs[i + 1].name }}</strong>
                  </div>
                </div>
                <div class="col-md-3">
                  <span
                    class="badge status-under float-right program-card-status"
                    [ngClass]="statusHelper[programs[i + 1].status]"
                    >{{ programs[i + 1].status }}</span
                  >
                </div>
              </div>
              <div class="row program-card-body">
                <div class="col-md-2"></div>
                <div class="col-md-10">
                  <pre class="font-bold program-card-pre">{{ programs[i + 1].description }}</pre>
                </div>
              </div>
              <div class="row gray-bg program-card-footer">
                <div class="col-md-2"></div>
                <div class="col-md-4"></div>
                <div class="col-md-6 panel-container">
                  <button
                    class="btn btn-dark-gray btn-xs delete action float-right mr-1"
                    [disabled]="programs[i + 1].status === 'DELETED'"
                    (click)="deleteProgram(programs[i + 1].id)"
                    title="Delete"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                  <button
                    class="btn btn-dark-gray btn-xs download action float-right mr-1"
                    (click)="exportProgram(programs[i + 1].id)"
                    download="{{ programs[i + 1].name }}.json"
                    title="Export"
                  >
                    <fa-icon [icon]="faDownload"></fa-icon>
                  </button>
                  <button
                    class="btn btn-dark-gray btn-xs edit action float-right mr-1"
                    (click)="programModal(programs[i + 1])"
                    title="Edit"
                  >
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </button>
                  <a
                    class="btn btn-dark-gray btn-xs manage action float-right mr-1"
                    routerLink="/programs/{{ programs[i + 1].id }}/promotions/"
                    title="Manage"
                    ><fa-icon [icon]="faCog"></fa-icon
                  ></a>
                </div>
              </div>
              <div class="row" [ngClass]="colors[(i + 1) % 4]"></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!--  -->
  <!-- <div class="col-lg-4 program-details" *ngIf="selectedProgram" appScrollWithMe isProgram="true"> -->
  <!-- <div class="row" [ngClass]="selectedColor"></div> -->
  <!-- <div class="row bg-gray program-details-title">
      <div class="col-md-3 program-details-icon">
        <div>
          <span class="fa-stack fa-3x">
            <fa-layers [fixedWidth]="true">
              <fa-icon class="blue-icon-color" [icon]="faCircle" stackItemSize="2x"></fa-icon>
              <fa-icon
                [inverse]="true"
                [icon]="getIcon(selectedProgram)"
                transform="shrink-8"
                stackItemSize="2x"
              ></fa-icon>
            </fa-layers>
          </span>
        </div>
      </div>
      <div class="col-md-7 program-details-title-text">Product Details</div>
      <div class="col-md-2 padding-20">
        <span
          class="badge status-under float-right program-card-status"
          [ngClass]="statusHelper[selectedProgram.status]"
          >{{ selectedProgram.status }}</span
        >
      </div>
    </div> -->
  <!-- <div class="row program-details-desc">
      <div class="col-md-3"></div>
      <div class="col-md-9 mt-4">
        <span class="text-uppercase">
          <strong>{{ selectedProgram.name }}</strong>
        </span>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-10 mt-4 program-details-desc-text">
        <pre class="mt-4 program-card-pre">{{ selectedProgram.description }}</pre>
      </div>
    </div> -->
  <!-- <div class="row bg-gray text-center program-details-cards" *ngIf="!anthemPromoView"> -->
  <!-- <div class="col-md-4"> -->
  <!-- <div class="row program-card-bottom program-bg-blue">
        <span class="text-uppercase text-white">Sponsors</span>
      </div> -->
  <!--<div class="col-md-12">
          <span class="text-uppercase"><strong>Sponsor Count</strong></span>
        </div>-->
  <!-- <div class="row bg-white program-card-middle">
        <div class="col-md-12">
          {{ selectedProgram.sponsorCount || 0 }}
        </div>
      </div> -->
  <!-- </div> -->
  <!-- <div class="col-md-4"> -->
  <!-- <div class="row program-card-bottom program-bg-green">
        <span class="text-uppercase text-white">Promotions</span>
      </div> -->
  <!--<div class="row program-card-top">
          <span class="text-uppercase"><strong>Promotions</strong></span>
      </div>-->
  <!-- <div class="row bg-white program-card-middle">
        <div class="col-md-12">
          {{ selectedProgram.promotionCount || 0 }}
        </div>
      </div> -->
  <!-- </div> -->
  <!-- <div class="col-md-4"> -->
  <!-- <div class="row bg-danger program-card-bottom program-bg-red">
        <span class="text-uppercase text-white">Packages</span>
      </div> -->
  <!--<div class="row program-card-top">
          <span class="text-uppercase"><strong>Configured</strong></span>
      </div>-->
  <!-- <div class="row bg-white program-card-middle">
        <div class="col-md-12">
          {{ selectedProgram.packageCount || 0 }}
        </div>
      </div> -->
  <!-- </div> -->
  <!-- </div>990 -->
  <!-- <div class="row" [ngClass]="selectedColor"></div> -->
  <!-- </div> -->
  <app-add-more-data-to-list
    (onScroll)="getPrograms(true)"
    [lock]="lock"
    [isLoading]="isLoading"
  ></app-add-more-data-to-list>
</div>
