import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, QueryList } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { PreferenceRestriction } from 'src/app/data/class';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from 'src/app/services/communications.service';

@Component({
  selector: 'app-preference-restrictions',
  templateUrl: './preference-restrictions.component.html',
  styleUrls: ['./preference-restrictions.component.scss'],
})
export class PreferenceRestrictionsComponent implements OnInit {
  @ViewChildren('form') forms: QueryList<any>;
  @Input() preferenceRestrictions: any;
  @Input() preferenceType: string;
  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  conditions: any[];
  endpointTypes: any[];
  faPlus = faPlus;
  faTimes = faTimes;
  addButtonTitle: string;
  removeButtonTitle: string;

  constructor(private communicationsServie: CommunicationsService) {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    if (this.preferenceType === 'restriction') {
      this.conditions = [
        { value: true, text: 'HAS' },
        { value: false, text: 'DOES NOT HAVE' },
      ];
      this.addButtonTitle = 'Add Preference Restriction';
      this.removeButtonTitle = 'Remove Restriction';
    } else {
      this.conditions = [
        { value: true, text: 'OPT IN' },
        { value: false, text: 'OPT OUT' },
      ];
      this.addButtonTitle = 'Add Preference Condition';
      this.removeButtonTitle = 'Remove Condition';
    }

    this.getAllEndpointTypes();
  }

  getAllEndpointTypes(): void {
    this.communicationsServie.getAllEndpointTypes(false).subscribe((data: any) => {
      this.endpointTypes = data.entity;
    });
  }

  initRestrictionObject(): void {
    const tmpObj = new PreferenceRestriction();

    if (this.preferenceRestrictions) {
      this.preferenceRestrictions.push(tmpObj);
    } else {
      this.preferenceRestrictions = [];
      this.preferenceRestrictions.push(tmpObj);
    }
  }

  updateRestrictions(): void {
    this.debouncer.next(this.preferenceRestrictions);
    let valid = true;
    for (const form of this.forms) {
      valid = valid && form.valid;
    }
    this.isValidChange.emit(valid);
  }

  removeRestriction(restriction: any): void {
    this.preferenceRestrictions.splice(this.preferenceRestrictions.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
