import { StartNoteModalComponent } from './../start-note-modal/start-note-modal.component';
import { UserModalComponent } from './../../user-modal/user-modal.component';
import { Gender } from './../../data/class';
import { AddressService } from './../../services/address.service';
import { Title } from '@angular/platform-browser';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { UsersService } from './../../services/users.service';
import { MemberSetPasswordModalComponent } from './../member-set-password-modal/member-set-password-modal.component';
import { ToastrService } from 'ngx-toastr';
import { MembersService } from './../../services/members.service';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import {
  faEye,
  faSpinner,
  faCircle,
  faUser,
  faStar,
  faEnvelope,
  faHome,
  faFile,
  faPhone,
  faPencilAlt,
  faCompressAlt,
  faExpandAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Person } from '../../data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { PersonsService } from 'src/app/services/persons.service';
import { EmailService } from 'src/app/services/email.service';
import { InstrumentsService } from 'src/app/services/instruments.service';
import { PhoneService } from 'src/app/services/phone.service';
import { SecurityService } from 'src/app/services/security/security.service';
import { PartyExtensionService } from 'src/app/services/party-extension.service';
import { UserPreferencesService } from './../../services/user-preferences.service';
import { ConfigurationService } from './../../services/configuration.service';
import { LanguageService } from 'src/app/services/language.service';
import { PartyRoleService } from 'src/app/services/party-role.service';
import { Phone } from 'src/app/data/model';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  animations: [
    trigger('expandCollapse', [
      state(
        'open',
        style({
          height: '*',
        })
      ),
      state(
        'close',
        style({
          height: '0px',
          visibility: 'hidden',
        })
      ),
      transition('open => close', animate(500)),
      transition('close => open', animate(100)),
    ]),

    trigger('showHide', [
      state(
        'open',
        style({
          opacity: '1',
        })
      ),
      state(
        'close',
        style({
          opacity: '0',
        })
      ),
      transition('open => close', animate(500)),
      transition('close => open', animate(100)),
    ]),
  ],
  selector: 'app-member-details-panel',
  templateUrl: './member-details-panel.component.html',
  styleUrls: ['./member-details-panel.component.scss'],
})
export class MemberDetailsPanelComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() selectedPersonId: number;
  @Output() public navigateEvent: EventEmitter<any> = new EventEmitter();
  @Output() public personChange: EventEmitter<any> = new EventEmitter();
  @Input() stretch: boolean;
  renderDetails = false;
  loading = false; // TODO: Testing value
  failed = false; // TODO: Testing value
  hidePasswords: boolean;
  checkIsSSOInstance = false;
  service: any; // TODO: assign type
  dropdownStatuses: any[];
  props = ['segments', 'email', 'address', 'phones', 'activityInstruments', 'accounts'];
  genders: Gender[];
  faCompressAlt = faCompressAlt;
  faSpinner = faSpinner;
  faEye = faEye;
  faStar = faStar;
  faCircle = faCircle;
  faUser = faUser;
  faEnvelope = faEnvelope;
  faHome = faHome;
  faExpandAlt = faExpandAlt;
  faPhone = faPhone;
  faFile = faFile;
  faPencilAlt = faPencilAlt;
  selectedPerson: Person;
  personCellPhone: Phone = new Phone();
  personOfficePhone: Phone = new Phone();
  personHomePhone: Phone = new Phone();
  allowedToEmulateMember: boolean;
  hasAccessToSensitiveAccounts: boolean;
  isAdmin: boolean;
  isCSR: boolean;
  allowJiraAccess: boolean;
  jiraLink: string;
  primaryLanguage;
  shrinked = true;
  doneAnimation = false;
  @Output() stretchChange = new EventEmitter();

  constructor(
    private title: Title,
    private toastr: ToastrService,
    private modalHelper: NgbModal,
    public globalData: AppDataService,
    private membersService: MembersService,
    private usersService: UsersService,
    private statusHelperService: StatusHelperService,
    private personsService: PersonsService,
    private emailService: EmailService,
    private addressService: AddressService,
    private phoneService: PhoneService,
    private instrumentsService: InstrumentsService,
    public securityService: SecurityService,
    private partyExtensionService: PartyExtensionService,
    private userPreferencesService: UserPreferencesService,
    private configurationService: ConfigurationService,
    private languageService: LanguageService,
    private partyRoleService: PartyRoleService
  ) {
    this.allowJiraAccess = this.userPreferencesService.service.getPreference('layout.allowJiraAccess');
  }

  animationDone(event: AnimationEvent) {
    this.doneAnimation = !this.doneAnimation;
  }

  getSelectedPerson(): Promise<Person> {
    return new Promise((resolve, reject) => {
      this.service.getEntity(this.selectedPersonId).subscribe((data) => {
        if (data.success) {
          resolve(data.entity);
        } else {
          this.toastr.error(data.error);
          reject(data.error);
        }
      });
    });
  }

  ngOnInit(): void {
    // this.allowJiraAccess = this.userPreferencesService.service.getPreference('layout.allowJiraAccess');
    this.getConfiguration();
    if (this.type === 'members' || this.type === 'member') {
      this.service = this.membersService;
    } else if (this.type === 'users' || this.type === 'user') {
      this.service = this.usersService;
    }
    this.initFunction();
  }

  initFunction(): void {
    this.getSelectedPerson().then(
      (data) => {
        this.personChange.emit(data);
        const person = data;
        let requestCounter = 0;
        this.isAdmin = this.globalData.isAdmin;
        this.isCSR = this.globalData.isCSR;
        if (this.isAdmin) {
          this.hasAccessToSensitiveAccounts = true;
        } else {
          this.hasAccessToSensitiveAccounts = this.userPreferencesService.service.getPreference(
            'layout.hasSensitiveMemberAccess'
          );
        }
        let promises = [];
        const params = {
          children: false,
        };
        if (this.isCSR) {
          promises = [
            this.personsService.getPersonSegments(this.selectedPersonId, params),
            this.emailService.getPrimaryOrLatestPartyEmail(this.selectedPersonId),
            this.addressService.getPrimaryPartyAddress(this.selectedPersonId),
            this.phoneService.getPartyPhones(this.selectedPersonId),
            this.instrumentsService.getPartyInstruments(this.selectedPersonId),
            this.membersService.getAccountsSummary(this.selectedPersonId),
          ];
        } else {
          promises = [
            this.personsService.getPersonSegments(this.selectedPersonId, params),
            this.emailService.getPrimaryOrLatestPartyEmail(this.selectedPersonId),
            this.addressService.getPrimaryPartyAddress(this.selectedPersonId),
            this.phoneService.getPartyPhones(this.selectedPersonId),
            this.instrumentsService.getPartyInstruments(this.selectedPersonId),
          ];
        }

        this.getPrimaryLanguage();

        promises.forEach((promise, index) => {
          promise.subscribe(
            (res: any) => {
              requestCounter++;
              this.parsePartyInfo(res, person, this.props[index]);
              this.selectedPerson = person;
              this.title.setTitle(this.selectedPerson.firstName + ' ' + this.selectedPerson.lastName);
              if (index === 3) {
                this.setPhones();
              }
              if (
                requestCounter === 6 ||
                (this.globalData.isLiveAgent && !this.globalData.isCSR && requestCounter === 5)
              ) {
                this.renderDetails = true;
              }
            },
            () => {
              requestCounter++;
              this.toastr.error('Error retrieving ' + this.type + ' ' + this.props[index] + ' for details panel.');
            }
          );
        });
        this.loading = false;
      },
      (msg) => {
        if (this.type === 'users' || this.type === 'user') {
          this.toastr.warning('Error retrieving ' + this.type + ': ' + msg.errorMsg);
          this.failed = true;
        }
      }
    );

    this.partyExtensionService.getNamedExtension(this.selectedPersonId, { name: 'member_extensions' }).subscribe(
      (data: any) => {
        if (
          data.success &&
          data.entity &&
          data.entity.member_extensions.allowedToEmulate !== null &&
          data.entity.member_extensions.allowedToEmulate !== undefined
        ) {
          this.allowedToEmulateMember = data.entity.member_extensions.allowedToEmulate;
        } else {
          this.allowedToEmulateMember = true;
        }
      },
      () => {
        this.toastr.error('Error occured!.');
      }
    );

    if (this.globalData.isCSR) {
      this.securityService.checkIsSSOInstance.then((data: boolean) => {
        this.hidePasswords = false;
        //we need to put this back at some point but for now this is our solution to let CSRs reset live agent passwords: this.type === 'members' ? data : false;
      });
    }

    if (this.globalData.isCSRManager) {
      this.dropdownStatuses = this.statusHelperService.getStatus('dropdown');
    }

    this.securityService.checkIsSSOInstance.then((data: boolean) => {
      this.checkIsSSOInstance = data;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.service) {
      if (this.type === 'members' || this.type === 'member') {
        this.service = this.membersService;
      } else if (this.type === 'users' || this.type === 'user') {
        this.service = this.usersService;
      }
    }
    if (changes.selectedPersonId) {
      this.selectedPersonId = changes.selectedPersonId.currentValue;
    }
    this.initFunction();
  }

  getConfiguration(): void {
    this.configurationService.getConfiguration('JIRA_SSO').subscribe((data: any) => {
      if (data.success && data.entity.length > 0) {
        this.jiraLink = data.entity[0].cfgValue;
      }
    });
  }

  getPrimaryLanguage(): void {
    this.languageService
      .getPrimaryLanguage(this.selectedPersonId, { isPrimary: true, partyId: this.selectedPersonId })
      .subscribe((data: any) => {
        if (data.success && data.entity.length > 0) {
          this.primaryLanguage = data.entity[0].languageName;
        } else {
          this.primaryLanguage = null;
        }
      });
  }

  setPhones(): void {
    this.personHomePhone = null;
    this.personCellPhone = null;
    this.personOfficePhone = null;
    this.selectedPerson.phones.forEach((phone) => {
      if (phone.phoneTypeName === 'Home' && (this.personHomePhone === null || phone.isPrimary)) {
        this.personHomePhone = phone;
      }
      if (phone.phoneTypeName === 'Mobile' && (this.personCellPhone === null || phone.isPrimary)) {
        this.personCellPhone = phone;
      }
      if (phone.phoneTypeName === 'Business' && (this.personOfficePhone === null || phone.isPrimary)) {
        this.personOfficePhone = phone;
      }
    });
  }

  parsePartyInfo(res, person, requestProp): void {
    this.props.forEach((attr) => {
      if (attr === requestProp) {
        const data = Object.assign({}, res);
        if (data && data.success) {
          person[attr] = data.entity;
        }
      }
    });
  }

  stretchStartEmit() {
    if (!this.shrinked) {
      this.stretch = true;
      this.stretchChange.emit(this.stretch);
    }
  }

  stretchEndEmit() {
    if (this.shrinked) {
      this.stretch = false;
      this.stretchChange.emit(this.stretch);
    }
  }

  sendPasswordReset(): void {
    this.membersService.sendPasswordReset(this.selectedPerson.id).subscribe((data: any) => {
      if (data.success) {
        this.selectedPerson.attrs.unshift({
          attrName: 'PASSWORDREQUEST',
          attrValue: true,
        });
        this.toastr.success('Password Reset Request sent');
      }
    });
  }

  isPasswordRequestAvailable(): boolean {
    const attrs = this.selectedPerson.attrs;
    const email = this.selectedPerson.email;
    let result = false;
    if (!(email && email.isPrimary)) {
      result = true;
    } else {
      if (attrs && attrs.length > 0) {
        const passAttr = attrs.filter((attr: any) => attr.attrName === 'PASSWORDREQUEST');
        if (passAttr && passAttr.length > 0) {
          result = passAttr[0].attrValue;
        }
      }
    }
    return result;
  }

  setPassword(): void {
    const instance = this.modalHelper.open(MemberSetPasswordModalComponent);
    instance.componentInstance.person = this.selectedPerson;
    instance.componentInstance.successEvent.subscribe(() => {
      instance.close();
    });
  }

  navigateToTab(tabId: number, event: any): void {
    event.preventDefault();
    this.navigateEvent.emit(tabId);
  }

  instrumentFilter(instrument): boolean {
    return instrument.status === 'ACTIVE' || instrument.status === 'PENDING';
  }

  shrinkMe(): void {
    this.shrinked = !this.shrinked;
  }

  edit(id: number): void {
    this.partyRoleService.getAccessRole().subscribe((data: any) => {
      if (data.success) {
        this.usersService.getUser(id).subscribe((user: any) => {
          if (user.success) {
            user.entity.password = null;
            const instance = this.modalHelper.open(UserModalComponent);
            instance.componentInstance.user = user.entity;
            instance.componentInstance.roles = data.entity;
            instance.componentInstance.successEvent.subscribe(() => {
              this.toastr.success('User success updated');
              instance.close();
            });
          }
        });
      }
    });
  }

  startNote(person: Person): void {
    const interaction: any = {};
    interaction.memberPartyId = person.id;
    const instance = this.modalHelper.open(StartNoteModalComponent);
    instance.componentInstance.interaction = interaction;
    instance.componentInstance.successEvent.subscribe(() => {
      instance.close();
    });
  }
}
