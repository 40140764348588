import React from "react";
import { FormControl, Typography } from "@mui/material";
import Grid from "@iridium/iridium-ui/Grid";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@iridium/iridium-ui/Button";
import { SegmentFilterWidget } from "src/app/widgets/SegmentFilterWidget";
import { PersonsService } from "src/app/services/persons.service";

export type LiveAgentToolbar = {
  setIsModalOpen: (isModalOpen?: boolean) => void;
  exportCSV: (e: any) => void;
  personsService: PersonsService;
  personId: number;
  setFilterSegments: (selectedSegmentIds: number[]) => void;
  segmentId: number;
  allowExport: boolean;
};

export function LiveAgentToolBar({
  setIsModalOpen,
  exportCSV,
  personsService,
  personId,
  setFilterSegments,
  segmentId,
  allowExport,
}: LiveAgentToolbar): JSX.Element {
  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4">Survey status</Typography>
        </Grid>
      </Grid>
      <Grid container pt={2}>
        <Grid container display="flex" justifyContent={"flex-end"} columnSpacing={2}>
          <Grid item>
            {/*Hide the button if allowExport is false*/}
            {allowExport && (
              <Button variant="outlined" onClick={(e) => exportCSV(e)}>
                EXPORT
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormControl>
              <SegmentFilterWidget
                personsService={personsService}
                personId={personId}
                onSelectedSegmentsChange={setFilterSegments}
                segmentId={segmentId}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button variant="outlined" endIcon={<FilterListIcon />} onClick={() => setIsModalOpen(true)}>
              FILTERS
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
