export const PARENT_ROOT = 'Organizations';
export const PARENT = 'Parent';
export const CLIENT = 'Client';
export const LINE_OF_BUSINESS = 'Line of Business';
export const MARKET = 'Market';
export const PROGRAM = 'Program';
export const PACKAGED_SPONSORSHIP = 'Packaged Sponsorship';
export const ADD_NEW_ORGANIZATION_TEST_ID = 'add-new-organization';
export const ORGANIZATION_SEARCH_INPUT_TEST_ID = 'organization-search-input';
export const ORGANIZATION_SEARCH_SUBMIT_TEST_ID = 'organization-search-submit';
export const FILTER_ORGANIZATIONS_TEST_ID = 'filter-organizations';
export const ORGANIZATION_EDIT_TEST_ID = 'organization-edit';
export const ORGANIZATION_STATUS_CHIP_TEST_ID = 'organization-status-chip';
export const DEFAULT_PARENT_NAME = 'Icario';
export const DEFAULT_SEARCH_VALUE = '';
export const ORGANIZATION_FILTER_DIALOG_TEST_ID = 'organization-filter-dialog';
export const ORGANIZATION_FILTER_DIALOG_SUBMIT_BUTTON_TEST_ID = 'organization-submit-button';
export const ORGANIZATION_FILTER_DIALOG_KEYWORD_TEST_ID = 'organization-dialog-search-input';
export const ORGANIZATION_FILTER_DIALOG_STATUS_TEST_ID = 'organization-dialog-status-input';

export const FILTER_CHIP_CLEAR_ALL_TEST_ID = 'filter-chip-clear-all';
