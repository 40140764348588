<form [formGroup]="accountForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Account Type <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select class="form-control" formControlName="accountType">
        <option value="REWARD">Reward</option>
      </select>
      <!-- <div class="mt-1" [hidden]="accountForm.controls.accountType.valid">
        <small class="text-danger">Select account type</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Reward Type <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select [compareWith]="compareById" class="form-control" formControlName="rewardType">
        <option *ngFor="let type of rewardTypes" [ngValue]="type">{{ type.name }}</option>
      </select>
      <!-- <div class="mt-1" [hidden]="accountForm.controls.rewardType.valid">
        <small class="text-danger">Select reward type</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Status <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select class="form-control" formControlName="status">
        <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
      </select>
      <!-- <div class="mt-1" [hidden]="accountForm.controls.status.valid">
        <small class="text-danger">Select status</small>
      </div> -->
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ok()" [disabled]="!accountForm.valid">Save</button>
      <button type="button" class="btn btn-warning" (click)="cancel()">Cancel</button>
    </div>
  </div>
</form>
