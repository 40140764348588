<div>
  <form>
    <label>The Candidate Communication history for the member must contain:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="commCandidateThreshold !== null">
        <div
          *ngFor="let commCandThreshold of commCandidateThreshold; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeThreshold(rewCntThreshold)"
            title="Remove Threshold"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{ commCandThreshold.thresholdValue }}">More than:</label>
              <input
                class="form-control m-b"
                type="number"
                id="thresholdVal_{{ commCandThreshold.thresholdValue }}"
                name="thresholdValue"
                [(ngModel)]="commCandThreshold.thresholdValue"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'COMMUNICATIONS',
                    { object: commCandThreshold, name: 'communicationNames' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >Communications with Names (comma separated list):</a
              >
              <input
                class="form-control m-b"
                type="text"
                id="behCodes_{{ commCandThreshold.thresholdValue }}"
                name="communicationNames"
                [(ngModel)]="commCandThreshold.communicationNames"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label>for the current:</label>
              <select
                name="thresholdPeriod_{{ commCandThreshold.thresholdValue }}"
                class="form-control"
                [(ngModel)]="commCandThreshold.thresholdPeriod && commCandThreshold.thresholdPeriod.value"
                (ngModelChange)="
                  !commCandThreshold.thresholdPeriod
                    ? (commCandThreshold['thresholdPeriod'] = {
                        value: $event,
                        class:
                          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$CommCandidateThreshold$ThresholdPeriod'
                      })
                    : null;
                  updateThresholds()
                "
              >
                <option *ngFor="let period of periods" [value]="period.value">{{ period.text }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Should the count include communications from later in the period than the current activity?</label>
              <select
                name="inclusion_{{ commCandThreshold.thresholdValue }}"
                class="form-control"
                [(ngModel)]="commCandThreshold.thresholdPeriod && commCandThreshold.thresholdPeriod.inclusion"
                (ngModelChange)="
                  !commCandThreshold.thresholdPeriod
                    ? (commCandThreshold['thresholdPeriod'] = {
                        inclusion: $event,
                        class:
                          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$CommCandidateThreshold$ThresholdPeriod'
                      })
                    : null;
                  updateThresholds()
                "
              >
                <option *ngFor="let inclusionVal of inclusionValues" [value]="inclusionVal.value">
                  {{ inclusionVal.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{ commCandThreshold.thresholdValue }}"
                >How many previous periods should be included in the time span (leave empty for only the current
                period):</label
              >
              <input
                class="form-control m-b"
                type="number"
                id="previousPeriodCount_{{ commCandThreshold.thresholdValue }}"
                name="previousPeriodCount"
                [(ngModel)]="commCandThreshold.previousPeriodCount"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{ commCandThreshold.thresholdValue }}"
                >Include counts from members related by (leave blank for current member only or use a comma separated
                list):</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="relationshipName_{{ commCandThreshold.thresholdValue }}"
                name="relationshipName"
                [(ngModel)]="commCandThreshold.relationshipName"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{ commCandThreshold.thresholdValue }}">Message:</label>
              <input
                class="form-control m-b"
                type="text"
                id="friendlyMessage_{{ commCandThreshold.thresholdValue }}"
                name="friendlyMessage"
                [(ngModel)]="commCandThreshold.friendlyMessage"
                (ngModelChange)="updateThresholds()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Candidate Communication Count Threshold
        </button>
      </div>
    </div>
  </form>
</div>
