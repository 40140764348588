<div class="row mt-3">
  <div class="col">
    <div class="d-flex">
      <div class="mr-2">
        <div
          [ngClass]="filter.inputClass"
          class="checkbox checkbox-info search-filter-segmentless search-filter-fields"
          *ngIf="section.open"
        >
          <input
            id="{{ filter.name }}"
            [(ngModel)]="filter.value"
            (change)="inputChangeEmitter.emit()"
            type="checkbox"
            class="com-input-check"
          />
        </div>
      </div>
      <div>
        <label class="filter-label" for="{{ filter.name }}">{{ filter.label }}</label>
      </div>
    </div>
  </div>
</div>
