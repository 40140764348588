import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { IntegrationKey } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class IntegrationKeyService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getIntegrationKeys(params): any {
    return this.http.get(this.globalData.baseUrl + 'integration-keys/', { params: params });
  }

  getIntegrationKey(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'integration-keys/' + id);
  }

  createOrUpdateIntegrationKey(id: number, integrationKey: IntegrationKey): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'integration-keys/' + id, integrationKey);
    }
    return this.http.post(this.globalData.baseUrl + 'integration-keys/', integrationKey);
  }

  deleteIntegrationKey(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'integration-keys/' + id);
  }
}
