<div class="row col-md-12" *ngIf="!isPopup">
  <div class="{{ columnsClass }}">
    <div class="row col-md-12">
      <span class="m-r-xs"><i>Current filters:</i></span>
      <div *ngFor="let filter of appliedFilters">
        <a class="btn btn- btn-md filter-tag" *ngIf="helpers.isStringFilter(filter) || helpers.isBooleanFilter(filter)">
          <a (click)="clearErrorMessage()">
            <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter)">
              <fa-icon [icon]="faTimes"></fa-icon>
            </span>
          </a>
          <span *ngIf="!filter.label || (helpers.isStringFilter(filter) && !filter.label)" class="ng-binding ng-scope"
            >{{ prepareLabel(filter.placeholder) }}
            {{ filter.type === "date" ? (filter.value | date: "yyyy-MM-dd") : filter.value }}</span
          >
          <span *ngIf="filter.label && !helpers.isBooleanFilter(filter)" class="ng-binding ng-scope"
            >{{ prepareLabel(filter.placeholder) }}
            {{ filter.label + ": " + (filter.value | date: "yyyy-MM-dd") }}</span
          >
          <span *ngIf="filter.label && helpers.isBooleanFilter(filter)" class="ng-binding ng-scope"
            >{{ prepareLabel(filter.placeholder) }} {{ filter.label }}</span
          >
        </a>
        <!-- <span *ngIf="helpers.isArrayFilter(filter)">
          <div *ngIf="filter.multiple">
            <span *ngFor="let arrayValue of filter.value">
              <a class="btn btn- btn-md filter-tag">
                <span
                  class="float-right close-button close-button-tag"
                  (click)="searchFilters.clearSelf(filter, arrayValue)"
                >
                  <fa-icon [icon]="faTimes"></fa-icon>
                </span>
                <span class="ng-binding ng-scope">{{ prepareLabel(filter.placeholder) }}{{ arrayValue }}</span>
              </a>
            </span>
          </div>
          <div *ngIf="!filter.multiple">
            <a class="btn btn- btn-md filter-tag">
              <span
                class="float-right close-button close-button-tag"
                (click)="searchFilters.clearSelf(filter, filter.value)"
              >
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
              <span class="ng-binding ng-scope">{{ prepareLabel(filter.placeholder) }}{{ filter.value }}</span>
            </a>
          </div>
        </span> -->

        <span *ngIf="helpers.isArrayFilter(filter)">
          <div *ngIf="filter.multiple">
            <!-- <span *ngFor="let arrayValue of filter.value">
              <a class="btn btn- btn-md filter-tag">
                <span
                  class="float-right close-button close-button-tag"
                  (click)="searchFilters.clearSelf(filter, arrayValue)"
                >
                  <fa-icon [icon]="faTimes"></fa-icon>
                </span>
                <span class="ng-binding ng-scope">{{ prepareLabel(filter.placeholder) }}{{ arrayValue }}</span>
              </a>
            </span> -->
            <mat-chip-list>
              <mat-chip
                class="matchips"
                *ngFor="let arrayValue of filter.value"
                (removed)="searchFilters.clearSelf(filter, arrayValue)"
              >
                {{ prepareLabel(filter.placeholder) }}{{ arrayValue }}

                <mat-icon class="matcancel" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
          <div *ngIf="!filter.multiple">
            <!-- <a class="btn btn- btn-md filter-tag">
              <span
                class="float-right close-button close-button-tag"
                (click)="searchFilters.clearSelf(filter, filter.value)"
              >
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
              <span class="ng-binding ng-scope">{{ prepareLabel(filter.placeholder) }}{{ filter.value }}</span>
            </a> -->
            <mat-chip-list>
              <mat-chip class="matchips" (removed)="searchFilters.clearSelf(filter)">
                {{ prepareLabel(filter.placeholder) }}{{ filter.value }}
                <mat-icon class="matcancel" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </span>

        <a  class="btn btn- btn-md filter-tag" *ngIf="helpers.isSimpleArrayFilter(filter) && !hideTag">
          <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter)">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
          <span class="ng-binding ng-scope"
            >{{ prepareLabel(filter.placeholder) }}{{ filter.singleLabel + " (" + filter.value.length + ")" }}</span
          >
        </a>
        <a
          class="btn btn- btn-md filter-tag"
          ng-repeat-end=""
          *ngIf="filter.type === 'radio' && !helpers.radio.isDefaultChoiceSelected(filter)"
        >
          <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter)">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
          <span class="ng-binding ng-scope"
            >{{ prepareLabel(filter.placeholder) }}{{ helpers.radio.getSelectedChoiceLabel(filter) }}</span
          >
        </a>
      </div>
      <span [hidden]="!helpers.showNoneSelected()">none</span>
    </div>
  </div>
  <div class="{{ columnsNotAppliedClass }}">
    <div class="" [hidden]="!someFiltersNotApplied">
      <span class="filter-tags-text"><i>*Some filters not applied</i></span>
    </div>
  </div>
</div>
<div *ngIf="isPopup">
  <div *ngIf="filterButton" class="mt-2 d-flex mat-overflow">
    <div *ngIf="appliedFilters.length > 0" class="">
      <mat-chip-list>
        <mat-chip class="matchips" (click)="searchFilters.clearAll()"> Clear filters </mat-chip>
      </mat-chip-list>
    </div>
    <div *ngFor="let filter of appliedFilters" class="ml-1">
      <div *ngIf="filter.value">
        <mat-chip-list *ngIf="filter.label && filter.type == 'boolean'">
          <mat-chip class="matchips" (removed)="searchFilters.clearSelf(filter, filter.value[index])">
            {{ prepareLabel(filter.placeholder) }} {{ filter.label }}
            <mat-icon class="matcancel" matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <div *ngIf="checkArrayOrNot(filter.value)" style="display: flex">
          <div *ngIf="filter.inputType === 'segment-filter'">
            <mat-chip-list *ngIf="filter.value.length > 0">
              <mat-chip class="matchips" (removed)="searchFilters.clearSelf(filter, filter.value[index])">
                {{ prepareLabel(filter.placeholder) }}{{ filter.singleLabel + " (" + filter.value.length + ")" }}
                <mat-icon class="matcancel" matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
          <ng-container *ngIf="filter.inputType != 'segment-filter'">
            <div *ngFor="let filterValue of filter.value; let index = index" class="mr-1">
              <mat-chip-list *ngIf="filter.value.length > 0">
                <mat-chip class="matchips" (removed)="searchFilters.clearSelf(filter, filter.value[index])">
                  {{ prepareLabel(filter.placeholder) }}{{ filter.value[index] }}
                  <mat-icon class="matcancel" matChipRemove>cancel</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </ng-container>
        </div>
        <div *ngIf="!checkArrayOrNot(filter.value)">
          <mat-chip-list *ngIf="filter.value.length > 0">
            <mat-chip class="matchips" (removed)="searchFilters.clearSelf(filter, filter.value)">
              {{ prepareLabel(filter.placeholder) }}{{ filter.value }}
              <mat-icon class="matcancel" matChipRemove>cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
</div>
