<div>
  <label>Restrict the Activity Amount:</label>
  <div class="row padded form-group">
    <div class="col-md-12" *ngIf="amountMatch !== null">
      <div *ngFor="let actAmount of amountMatch; let i = index" class="border-card-padding form-group col-lg-12">
        <button
          class="btn btn-attention float-right"
          type="button"
          (click)="removeRestriction(actAmount)"
          title="Remove Restriction"
        >
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
        <form>
          <div class="form-group">
            <select
              name="actAmount_{{ actAmount.value }}"
              class="form-control"
              [(ngModel)]="actAmount.condition"
              (ngModelChange)="updateRestrictions()"
            >
              <option
                *ngFor="let condition of conditions"
                [selected]="actAmount.condition === condition.value"
                [value]="condition.value"
              >
                {{ condition.text }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label" for="actAmountVal_{{ actAmount.matchValue }}">a value of:</label>
            <input
              class="form-control m-b"
              type="number"
              id="actAmountVal_{{ actAmount.matchValue }}"
              name="value"
              [(ngModel)]="actAmount.matchValue"
              (ngModelChange)="updateRestrictions()"
            />
          </div>
          <div class="form-group">
            <label class="control-label" for="actAmountMsg_{{ actAmount.matchValue }}">Message:</label>
            <input
              class="form-control m-b"
              type="text"
              id="actAmountMsg_{{ actAmount.matchValue }}"
              name="msg"
              [(ngModel)]="actAmount.friendlyMessage"
              (ngModelChange)="updateRestrictions()"
            />
          </div>
        </form>
      </div>
    </div>
    <div class="form-group float-right col-md-12">
      <button class="btn btn-default float-right" (click)="initRestrictionObject()">
        <fa-icon [icon]="faPlus"></fa-icon> Add Activity Amount Match
      </button>
    </div>
  </div>
</div>
