<form [formGroup]="sponsorshipForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="form-group col-lg-6">
        <label>Sponsorship Tag</label>
        <div class="form-group">
          <input type="text" formControlName="sponsorshipTag" class="form-control mb-2" />
          <span class="text-danger" *ngIf="sponsorshipForm.controls.sponsorshipTag.errors">
            Sponsorship tag can include a capital letter, a lowercase letter, a number, and '-' and ':' characters
          </span>
        </div>
      </div>
      <div class="form-group col-lg-6">
        <label>Promotion Category</label>
        <select name="promotionCategory" class="form-control" formControlName="categoryId">
          <option *ngFor="let cat of promCategories" value="{{ cat.id }}">{{ cat.name }}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-6">
        <label>Published Start Date</label>
        <div class="input-group date">
          <input
            class="form-control cursor-pointer"
            readonly="readonly"
            (click)="publishedStartDate.toggle()"
            name="publishedStartDate"
            ngbDatepicker
            #publishedStartDate="ngbDatepicker"
            formControlName="publishedStartDate"
            placeholder="yyyy-mm--dd"
            [footerTemplate]="footerTemplatePublishedStartDate"
          />
          <ng-template #footerTemplatePublishedStartDate>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate" (click)="clearDate('publishedStartDate'); publishedStartDate.toggle()">
                Clear
              </button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="publishedStartDate.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6">
        <label>Published End Date</label>
        <div class="input-group date">
          <input
            class="form-control cursor-pointer"
            readonly="readonly"
            (click)="publishedEndDate.toggle()"
            formControlName="publishedEndDate"
            ngbDatepicker
            #publishedEndDate="ngbDatepicker"
            placeholder="yyyy-mm--dd"
            [footerTemplate]="footerTemplatePublishedEndDate"
          />
          <ng-template #footerTemplatePublishedEndDate>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate" (click)="clearDate('publishedEndDate'); publishedEndDate.toggle()">
                Clear
              </button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="publishedEndDate.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-6">
        <label>Processing Start Date</label>
        <div class="input-group date">
          <input
            class="form-control cursor-pointer"
            readonly="readonly"
            (click)="processingStartDate.toggle()"
            formControlName="processingStartDate"
            ngbDatepicker
            #processingStartDate="ngbDatepicker"
            placeholder="yyyy-mm--dd"
            [footerTemplate]="footerTemplateProcessingStartDate"
          />
          <ng-template #footerTemplateProcessingStartDate>
            <div class="width-100 datePickerTemplate">
              <button
                class="buttonDateTemplate"
                (click)="clearDate('processingStartDate'); processingStartDate.toggle()"
              >
                Clear
              </button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="processingStartDate.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6">
        <label>Processing End Date</label>
        <div class="input-group date">
          <input
            class="form-control cursor-pointer"
            readonly="readonly"
            (click)="processingEndDate.toggle()"
            formControlName="processingEndDate"
            ngbDatepicker
            #processingEndDate="ngbDatepicker"
            placeholder="yyyy-mm--dd"
            [footerTemplate]="footerTemplateProcessingEndDate"
          />
          <ng-template #footerTemplateProcessingEndDate>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate" (click)="clearDate('processingEndDate'); processingEndDate.toggle()">
                Clear
              </button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="processingEndDate.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-6">
        <label>Score Start Date</label>
        <div class="input-group date">
          <input
            class="form-control cursor-pointer"
            readonly="readonly"
            formControlName="scoreStartDate"
            (click)="scoreStartDate.toggle()"
            ngbDatepicker
            #scoreStartDate="ngbDatepicker"
            placeholder="yyyy-mm--dd"
            [footerTemplate]="footerTemplateScoreStartDate"
          />
          <ng-template #footerTemplateScoreStartDate>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate" (click)="clearDate('scoreStartDate'); scoreStartDate.toggle()">
                Clear
              </button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="scoreStartDate.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-6">
        <label>Score End Date</label>
        <div class="input-group date">
          <input
            class="form-control cursor-pointer"
            readonly="readonly"
            (click)="scoreEndDate.toggle()"
            formControlName="scoreEndDate"
            ngbDatepicker
            #scoreEndDate="ngbDatepicker"
            placeholder="yyyy-mm--dd"
            [footerTemplate]="footerTemplateScoreEndDate"
          />
          <ng-template #footerTemplateScoreEndDate>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate" (click)="clearDate('scoreEndDate'); scoreEndDate.toggle()">
                Clear
              </button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="scoreEndDate.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6">
        <div class="checkbox checkbox-success">
          <input id="displayLink" formControlName="isHidden" name="isHidden" class="mr-2" type="checkbox" />
          <label for="displayLink"> Is Hidden </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
    <button class="btn-pop-success" type="submit" (click)="ok()" title="Save">SAVE</button>
  </div>
</form>
