import { Observable } from 'rxjs';

export type EntityResponse<T> = {
  entity: T;
  error: Error;
  success: boolean;
  // If someone discovers what these are please type them
  warnings: unknown;
  errfor: unknown[];
};
export type EntityResponsePromise<T> = Promise<EntityResponse<T>>;

/**
 * When calling .toPromise() on an Angular HTTP call the typings are incorrect.
 */
export function mapHttpObservableToPromise<T>(observable: Observable<EntityResponse<T>>): EntityResponsePromise<T> {
  return observable.toPromise() as unknown as EntityResponsePromise<T>;
}

export function mapHttpObservableToBarePromise<T>(observable: Observable<T>): Promise<T> {
  return observable.toPromise() as unknown as Promise<T>;
}
