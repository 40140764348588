import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Program, Package } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  exportProgram(id): any {
    return this.http.get<any>(this.globalData.baseUrl + 'programs/' + id + '/export');
  }

  getAllPrograms(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'programs/', this.setParams(params));
  }

  getProgram(id: number, params?: any): any {
    if (params) {
      return this.http.get<any>(this.globalData.baseUrl + 'programs/' + id, this.setParams(params));
    }
    return this.http.get<any>(this.globalData.baseUrl + 'programs/' + id);
  }

  getProgramPackages(programId: number, params?: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'programs/packages/' + programId, this.setParams(params));
  }

  getProgramPromotions(id: number, limitValue?: number, offsetValue?: number): any {
    if (limitValue !== undefined && offsetValue !== undefined) {
      const params = {
        limit: limitValue,
        offset: offsetValue,
      };
      return this.http.get<any>(this.globalData.baseUrl + 'programs/' + id + '/promotions', this.setParams(params));
    }
    return this.http.get<any>(this.globalData.baseUrl + 'programs/' + id + '/promotions');
  }

  getProgramStructure(id: number, params?: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'programs/' + id + '/structure', this.setParams(params));
  }

  createOrUpdatePrograms(id: number, program: Program): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'programs/' + id, program);
    }
    return this.http.post(this.globalData.baseUrl + 'programs', program);
  }

  deleteProgram(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'programs/' + id);
  }

  findSponsorshipsForImportingProgram(program: any) {
    return this.http.put(this.globalData.baseUrl + 'programs/' + 'find-existing-sponsorships', program);
  }

  importProgram(program: Program) {
    return this.http.post(this.globalData.baseUrl + 'programs/import', program);
  }

  getDistinctProgramPackages(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'programs/packages', this.setParams(params));
  }

  getProgramPackage(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'programs/package/' + id);
  }

  createOrUpdateProgramPackages(id: number, pac: Package): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'programs/package/' + id, pac);
    }
    return this.http.post(this.globalData.baseUrl + 'programs/package', pac);
  }
}
