import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { FormBuilder, Validators, FormsModule } from '@angular/forms';
import { faTimes, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-activity-range-restriction',
  templateUrl: './activity-range-restriction.component.html',
  styleUrls: ['./activity-range-restriction.component.scss'],
})
export class ActivityRangeRestrictionComponent implements OnInit {
  @Input() restrictionDateString: any;
  @Input() restrictionType: any;
  @Input() title: string;
  faTimes = faTimes;
  faCalendar = faCalendar;
  restrictionDateValue: NgbDate;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
    private dateHelperService: DateHelperService
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    if (this.restrictionDateString) {
      (this.restrictionDateValue = this.dateHelperService.stringToNgbDate(
        this.getFormattedDate(this.restrictionDateString)
      )),
        Validators.required;
      console.log(this.restrictionDateValue);
    }
  }

  updateRestrictions() {
    this.debouncer.next(this[this.restrictionType]);
  }

  removeRestriction() {
    this[this.restrictionType] = null;
    this.restrictionDateValue = null;
    this.updateRestrictions();
  }

  formatDate(dateToFormat: any): void {
    this[this.restrictionType] = this.dateHelperService.ngbDateToString(this.restrictionDateValue);
    this.updateRestrictions();
  }

  getFormattedDate(date): string {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    const year = d.getFullYear();
    const month = (1 + d.getMonth()).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');

    return year + '-' + month + '-' + day;
  }

  clearDate(): any {
    this.restrictionDateString = null;
    this.restrictionDateValue = null;
  }
}
