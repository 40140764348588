import { Entry } from './shared.types';

export interface ResponseSetEntry extends Entry {
  response_values: {
    Response_Value: {
      response: string;
      value: string;
    };
  }[];
  scale: string;
}

export type StandardResponseModuleEntry = {
  standard_responses: {
    response_sets: ResponseSetEntry[];
  };
};

export type CustomResponseModuleEntry = {
  custom_responses: {
    response_text: string;
    response_value: string;
  };
};

type QuestionResponseModularField = CustomResponseModuleEntry | StandardResponseModuleEntry;

export interface QuestionResponse {
  responseText: string;
  responseValue: string;
}

export function buildResponsesFromResponseModularFields(fields: QuestionResponseModularField[]): QuestionResponse[] {
  const standard = fields.find((field) => (field as StandardResponseModuleEntry).standard_responses) as
    | StandardResponseModuleEntry
    | undefined;
  if (standard) {
    return standard.standard_responses.response_sets[0].response_values.map((responseValue) => ({
      responseText: responseValue.Response_Value.response,
      responseValue: responseValue.Response_Value.value,
    }));
  }
  const customResponses = (fields as CustomResponseModuleEntry[]) || [];
  return customResponses.map((customResponse) => ({
    responseText: customResponse.custom_responses.response_text,
    responseValue: customResponse.custom_responses.response_value,
  }));
}

export interface QuestionEntry extends Entry {
  question_text: string;
  responses: QuestionResponseModularField[];
  client: { uid: string; _content_type_uid: 'client'; title: string }[];
  question_id: string;
  program_sku: { program_sku: string };
  supported_languages: {
    language: string;
    _metadata?: { uid: string };
  }[];
  disposition: { disposition: string; _metadata?: { uid: string } }[];
  cms_blackout: string;
}

export interface Question {
  uid: string;
  title: string;
  description: string;
  clientTitle: string;
  programSku: string;
  supportedLanguages: string[];
  disposition: string[];
  questionText: string;
  responses: QuestionResponse[];
  questionId: string;
  cms_blackout: string;
}

export function buildQuestion(entry: QuestionEntry): Question {
  return {
    uid: entry.uid,
    title: entry.title,
    description: entry.description,
    questionText: entry.question_text,
    questionId: entry.question_id,
    cms_blackout: entry.cms_blackout,
    responses: buildResponsesFromResponseModularFields(entry.responses),
    clientTitle: entry.client?.map((client) => client.title).join(', '),
    programSku: entry.program_sku?.program_sku || '',
    supportedLanguages: entry.supported_languages?.map((item) => item.language) || [],
    disposition: entry.disposition?.map((item) => item.disposition) || [],
  };
}
