import { EntityStatus } from "../data/model";
import React from "react";
import TextField from "@iridium/iridium-ui/TextField";
import { OrganizationListFilter } from "./Organizations";
import FilterDialog from "./components/FilterDialog";
import Select from "@iridium/iridium-ui/Select";
import {
  ORGANIZATION_FILTER_DIALOG_KEYWORD_TEST_ID,
  ORGANIZATION_FILTER_DIALOG_TEST_ID,
  ORGANIZATION_FILTER_DIALOG_SUBMIT_BUTTON_TEST_ID,
  ORGANIZATION_FILTER_DIALOG_STATUS_TEST_ID,
} from "./constants";
import Grid from "@iridium/iridium-ui/Grid";
import Typography from "@iridium/iridium-ui/Typography";

export type FilterDialogProps = {
  filter: OrganizationListFilter;
  isModalOpen: boolean;
  onModalClose: () => void;
  onFormSubmit: (params: Partial<OrganizationListFilter>) => void;
};

export type Option = {
  value: string;
  label: string;
};

const entityStatusOptions: Option[] = Object.values(EntityStatus).map((option) => ({
  value: option.toString(),
  label: option.toString(),
}));

function OrganizationFilterDialog({ isModalOpen, onModalClose, onFormSubmit, filter }: FilterDialogProps): JSX.Element {
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [keywordFilterLength, setKeywordFilterLength] = React.useState<number>(0);
  const [statusFilterLength, setStatusFilterLength] = React.useState<number>(0);

  function updateDirtyState() {
    keywordFilterLength === 0 && statusFilterLength === 0 ? setIsDirty(false) : setIsDirty(true);
  }

  React.useEffect(() => {
    updateDirtyState();
  }, [keywordFilterLength, statusFilterLength]);

  React.useEffect(() => {
    return () => setIsDirty(false);
  }, [onModalClose]);

  return (
    <FilterDialog
      formTestId={ORGANIZATION_FILTER_DIALOG_TEST_ID}
      submitButtonTestId={ORGANIZATION_FILTER_DIALOG_SUBMIT_BUTTON_TEST_ID}
      filter={filter}
      onFormSubmit={onFormSubmit}
      onModalClose={onModalClose}
      isModalOpen={isModalOpen}
      isDirty={isDirty}
      renderInputs={({ setPendingFilter, pendingFilter }) => {
        setKeywordFilterLength(pendingFilter.keyword.length);
        setStatusFilterLength(pendingFilter.statuses.length);
        return (
          <Grid
            container
            direction="column"
            sx={{ width: "270px", "& .MuiFormControl-root.MuiTextField-root": { width: "100%" } }}
          >
            <div style={{ marginBottom: "6px" }}>
              <Typography>Search keyword</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="search-filter"
                placeholder="Search"
                type="text"
                fullWidth
                onChange={(event) => {
                  setPendingFilter({
                    ...pendingFilter,
                    keyword: event.target.value,
                  });
                }}
                value={pendingFilter.keyword}
                InputProps={{ inputProps: { "data-testid": ORGANIZATION_FILTER_DIALOG_KEYWORD_TEST_ID } }}
                sx={{
                  marginBottom: "1rem",
                }}
              />
            </div>
            <div style={{ marginBottom: "6px" }}>
              <Typography>Select status</Typography>
              <Select
                id="status-select"
                placeholder="Select status"
                multiSelect={true}
                value={pendingFilter.statuses}
                optionPropsList={entityStatusOptions}
                InputProps={{ inputProps: { "data-testid": ORGANIZATION_FILTER_DIALOG_STATUS_TEST_ID } }}
                onChange={(newStatuses) => {
                  setPendingFilter({
                    ...pendingFilter,
                    statuses: [].concat(newStatuses) as string[],
                  });
                }}
              />
            </div>
          </Grid>
        );
      }}
    />
  );
}

export default OrganizationFilterDialog;
