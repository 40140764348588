import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { ProgramService } from '../../services/program.service';
import { PromotionConfigService } from '../../services/promotion-config.service';
import { PromotionService } from '../../services/promotion.service';
import { ConfigurationService } from '../../services/configuration.service';
import { SponsorsService } from '../../services/sponsors.service';
import { CommunicationsService } from '../../services/communications.service';
import { PartyRoleService } from 'src/app/services/party-role.service';
import { PartyRelationshipService } from 'src/app/services/party-relationship.service';
import { RewardTypeService } from 'src/app/services/reward-type.service';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { faSave, faTimes, faCalendar, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PromotionTemplate } from '../../data/model';
import { Promotion } from '../../data/model';
import { RewardTriggerComponent } from '../reward-trigger/reward-trigger.component';
import { ActivityTriggerComponent } from '../activity-trigger/activity-trigger.component';
import { ActivityRangeRestrictionComponent } from '../activity-range-restriction/activity-range-restriction.component';
import { PartyAttributeRestrictionComponent } from '../party-attribute-restriction/party-attribute-restriction.component';
import { AgeRestrictionComponent } from '../age-restriction/age-restriction.component';
import { GenderRestrictionComponent } from '../gender-restriction/gender-restriction.component';
import { ActivityAgeRestrictionComponent } from '../activity-age-restriction/activity-age-restriction.component';
import { ActivityAmountMatchRestrictionComponent } from '../activity-amount-match-restriction/activity-amount-match-restriction.component';
import { ActivityAmountRangeRestrictionComponent } from '../activity-amount-range-restriction/activity-amount-range-restriction.component';
import { ActivityAttributeRestrictionComponent } from '../activity-attribute-restriction/activity-attribute-restriction.component';
import { PartyAttributeIntervalRestrictionComponent } from '../party-attribute-interval-restriction/party-attribute-interval-restriction.component';
import { ActivityAttributeIntervalRestrictionComponent } from '../activity-attribute-interval-restriction/activity-attribute-interval-restriction.component';
import { LocationAttributeRestrictionComponent } from '../location-attribute-restriction/location-attribute-restriction.component';
import { PartyRelationshipRestrictionComponent } from '../party-relationship-restriction/party-relationship-restriction.component';
import { BehaviorCountThresholdComponent } from '../behavior-count-threshold/behavior-count-threshold.component';
import { AttributeIntervalBehaviorCountThresholdComponent } from '../attribute-interval-behavior-count-threshold/attribute-interval-behavior-count-threshold.component';
import { RewardCountThresholdComponent } from '../reward-count-threshold/reward-count-threshold.component';
import { RewardAmountThresholdComponent } from '../reward-amount-threshold/reward-amount-threshold.component';
import { TenderAmountThresholdComponent } from '../tender-amount-threshold/tender-amount-threshold.component';
import { CountMatchThresholdComponent } from '../count-match-threshold/count-match-threshold.component';
import { AttributeIntervalRewardCountThresholdComponent } from '../attribute-interval-reward-count-threshold/attribute-interval-reward-count-threshold.component';
import { CandidateCommunicationCountThresholdComponent } from '../candidate-communication-count-threshold/candidate-communication-count-threshold.component';
import { GatekeeperThresholdComponent } from '../gatekeeper-threshold/gatekeeper-threshold.component';
import { BehaviorCountCapComponent } from '../behavior-count-cap/behavior-count-cap.component';
import { RewardCountCapComponent } from '../reward-count-cap/reward-count-cap.component';
import { AttributeIntervalCountCapComponent } from '../attribute-interval-count-cap/attribute-interval-count-cap.component';
import { RewardEarnCapComponent } from '../reward-earn-cap/reward-earn-cap.component';
import { PerBehaviorCountCapComponent } from '../per-behavior-count-cap/per-behavior-count-cap.component';
import { CandidateCommunicationCapComponent } from '../candidate-communication-cap/candidate-communication-cap.component';
import { FixedRewardConsequenceComponent } from '../fixed-reward-consequence/fixed-reward-consequence.component';
import { PercentageRewardConsequenceComponent } from '../percentage-reward-consequence/percentage-reward-consequence.component';
import { SteppedRewardConsequenceComponent } from '../stepped-reward-consequence/stepped-reward-consequence.component';
import { AddAttributeConsequenceComponent } from '../add-attribute-consequence/add-attribute-consequence.component';
import { RemoveAttributeConsequenceComponent } from '../remove-attribute-consequence/remove-attribute-consequence.component';
import { ChangeLanguageConsequenceComponent } from '../change-language-consequence/change-language-consequence.component';
import { RewardTransferConsequenceComponent } from '../reward-transfer-consequence/reward-transfer-consequence.component';
import { TrackingRewardConsequenceComponent } from '../tracking-reward-consequence/tracking-reward-consequence.component';
import { RelationshipConsequenceComponent } from '../relationship-consequence/relationship-consequence.component';
import { CandidateCommunicationConsequenceComponent } from '../candidate-communication-consequence/candidate-communication-consequence.component';
import { RelatedConfigurationsComponent } from '../related-configurations/related-configurations.component';
import { OptOutConsequenceComponent } from '../opt-out-consequence/opt-out-consequence.component';
import { ValidationComponent } from '../validation/validation.component';
import { JsonPreviewComponent } from '../json-preview/json-preview.component';
import { ConfigSectionComponent } from '../config-section/config-section.component';
import { PromotionsTabTackerService } from 'src/app/services/promotions-tab-tacker.service';

@Component({
  selector: 'app-promotion-template-builder-v2',
  templateUrl: './promotion-template-builder-v2.component.html',
  styleUrls: ['./promotion-template-builder-v2.component.scss'],
})
export class PromotionTemplateBuilderV2Component implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  configType: string;
  selectedProgramId: number;
  selectedPromotionId: number;
  selectedTemplateId: number;
  stringTemplateId: string;
  promotionTemplate: any = new PromotionTemplate();
  promotion: any = new Promotion();
  programPackages: any;
  rewardTypes: any;
  endpointTypes: any;
  partyRoles: any;
  partyRelationships: any;
  communications: any;
  communicationTypes: any;
  ruleSource: any;
  disableEdits: boolean;
  sponsorCount: number;
  isEdit: boolean;
  programForm: FormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faCalendar = faCalendar;
  allIcons = fas;
  allIconKeys = Object.keys(this.allIcons);
  filterIconKeys: string[] = Object.keys(this.allIcons);
  everyThirdIconArray: any[];
  rewardTrigger: string;
  showEligibilityRestrictions: boolean = false;
  showTriggers: boolean = false;
  showThresholds: boolean = false;
  showCaps: boolean = false;
  showConsequences: boolean = false;
  showActivityRestrictions: boolean = false;
  showJsonPreview: boolean = false;
  showValidation: boolean = false;
  showRelatedConfigs: boolean = false;
  showRefSection: boolean = true;
  clone: boolean = false;
  @ViewChild(RewardTriggerComponent) rewardTriggerComponent: RewardTriggerComponent;
  @ViewChild(ActivityTriggerComponent) activityTriggerComponent: ActivityTriggerComponent;
  @ViewChild(ActivityRangeRestrictionComponent) activityRangeRestrictionComponent: ActivityRangeRestrictionComponent;
  @ViewChild(PartyAttributeRestrictionComponent) partyAttributeRestrictionComponent: PartyAttributeRestrictionComponent;
  @ViewChild(AgeRestrictionComponent) ageRestrictionComponent: AgeRestrictionComponent;
  @ViewChild(GenderRestrictionComponent) genderRestrictionComponent: GenderRestrictionComponent;
  @ViewChild(ActivityAgeRestrictionComponent) activityAgeRestrictionComponent: ActivityAgeRestrictionComponent;
  @ViewChild(ActivityAmountMatchRestrictionComponent)
  activityAmountMatchRestrictionComponent: ActivityAmountMatchRestrictionComponent;
  @ViewChild(ActivityAmountRangeRestrictionComponent)
  activityAmountRangeRestrictionComponent: ActivityAmountRangeRestrictionComponent;
  @ViewChild(ActivityAttributeRestrictionComponent)
  activityAttributeRestrictionComponent: ActivityAttributeRestrictionComponent;
  @ViewChild(PartyAttributeIntervalRestrictionComponent)
  partyAttributeIntervalRestrictionComponent: PartyAttributeIntervalRestrictionComponent;
  @ViewChild(ActivityAttributeIntervalRestrictionComponent)
  activityAttributeIntervalRestrictionComponent: ActivityAttributeIntervalRestrictionComponent;
  @ViewChild(LocationAttributeRestrictionComponent)
  locationAttributeRestrictionComponent: LocationAttributeRestrictionComponent;
  @ViewChild(PartyRelationshipRestrictionComponent)
  partyRelationshipRestrictionComponent: PartyRelationshipRestrictionComponent;
  @ViewChild(BehaviorCountThresholdComponent) behaviorCountThresholdComponent: BehaviorCountThresholdComponent;
  @ViewChild(AttributeIntervalBehaviorCountThresholdComponent)
  attributeIntervalBehaviorCountThresholdComponent: AttributeIntervalBehaviorCountThresholdComponent;
  @ViewChild(RewardCountThresholdComponent) rewardCountThresholdComponent: RewardCountThresholdComponent;
  @ViewChild(RewardAmountThresholdComponent) rewardAmountThresholdComponent: RewardAmountThresholdComponent;
  @ViewChild(TenderAmountThresholdComponent) tenderAmountThresholdComponent: TenderAmountThresholdComponent;
  @ViewChild(AttributeIntervalRewardCountThresholdComponent)
  attributeIntervalRewardCountThresholdComponent: AttributeIntervalRewardCountThresholdComponent;
  @ViewChild(CountMatchThresholdComponent) countMatchThresholdComponent: CountMatchThresholdComponent;
  @ViewChild(CandidateCommunicationCountThresholdComponent)
  candidateCommunicationCountThresholdComponent: CandidateCommunicationCountThresholdComponent;
  @ViewChild(GatekeeperThresholdComponent) gatekeeperThresholdComponent: GatekeeperThresholdComponent;
  @ViewChild(BehaviorCountCapComponent) behaviorCountCapComponent: BehaviorCountCapComponent;
  @ViewChild(RewardCountCapComponent) rewardCountCapComponent: RewardCountCapComponent;
  @ViewChild(AttributeIntervalCountCapComponent) attributeIntervalCountCapComponent: AttributeIntervalCountCapComponent;
  @ViewChild(RewardEarnCapComponent) rewardEarnCapComponent: RewardEarnCapComponent;
  @ViewChild(PerBehaviorCountCapComponent) perBehaviorCountCapComponent: PerBehaviorCountCapComponent;
  @ViewChild(CandidateCommunicationCapComponent) candidateCommunicationCapComponent: CandidateCommunicationCapComponent;
  @ViewChild(FixedRewardConsequenceComponent) fixedRewardConsequenceComponent: FixedRewardConsequenceComponent;
  @ViewChild(PercentageRewardConsequenceComponent)
  percentageRewardConsequenceComponent: PercentageRewardConsequenceComponent;
  @ViewChild(SteppedRewardConsequenceComponent) steppedRewardConsequenceComponent: SteppedRewardConsequenceComponent;
  @ViewChild(AddAttributeConsequenceComponent) addAttributeConsequenceComponent: AddAttributeConsequenceComponent;
  @ViewChild(RemoveAttributeConsequenceComponent)
  removeAttributeConsequenceComponent: RemoveAttributeConsequenceComponent;
  @ViewChild(ChangeLanguageConsequenceComponent) changeLanguageConsequenceComponent: ChangeLanguageConsequenceComponent;
  @ViewChild(RewardTransferConsequenceComponent) rewardTransferConsequenceComponent: RewardTransferConsequenceComponent;
  @ViewChild(TrackingRewardConsequenceComponent) trackingRewardConsequenceComponent: TrackingRewardConsequenceComponent;
  @ViewChild(RelationshipConsequenceComponent) relationshipConsequenceComponent: RelationshipConsequenceComponent;
  @ViewChild(CandidateCommunicationConsequenceComponent)
  candidateCommunicationConsequenceComponent: CandidateCommunicationConsequenceComponent;
  @ViewChild(OptOutConsequenceComponent)
  optOutConsequenceComponent: OptOutConsequenceComponent;
  @ViewChild(JsonPreviewComponent) jsonPreviewComponent: JsonPreviewComponent;
  @ViewChild(ValidationComponent) validationComponent: ValidationComponent;
  @ViewChild(RelatedConfigurationsComponent) relatedConfigurationsComponent: RelatedConfigurationsComponent;
  @ViewChild(ConfigSectionComponent) configSectionComponent: ConfigSectionComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private statusHelperService: StatusHelperService,
    private programService: ProgramService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private promotionConfigService: PromotionConfigService,
    private configService: ConfigurationService,
    private promotionService: PromotionService,
    private rewardTypeService: RewardTypeService,
    private partyRoleService: PartyRoleService,
    private partyRelationshipService: PartyRelationshipService,
    private communicationsService: CommunicationsService,
    private sponsorsService: SponsorsService,
    private tabTracker: PromotionsTabTackerService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.tabTracker.selectTab(1);
    var url = this.activatedRoute.snapshot.routeConfig.path;
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.selectedProgramId = Number(params.get('id'));
      this.selectedPromotionId = Number(params.get('promotionId'));
      this.selectedTemplateId = Number(params.get('templateId'));
    });
    this.getDisableTemplateEdits();
    if (this.activatedRoute.snapshot.url.toString().includes('clone')) {
      this.clone = true;
    }
    if (this.selectedTemplateId) {
      this.getPromotionTemplate(this.selectedTemplateId);
    }
    if (this.selectedPromotionId) {
      this.getPromotion(this.selectedPromotionId);
    }
    if (this.selectedProgramId) {
      this.getProgramPackages(this.selectedProgramId);
    }
    this.getRewardTypes();
    this.getRelationships();
    this.getPartyRoles();
    this.getCommunicationTypes();
    this.getCommunications();
    this.getEndpointTypes();
    //the backend complains if all these tags don't exist.
    if (!this.ruleSource) {
      this.ruleSource = {
        class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration',
        schemaName: 'behaviorConfigSchema.2.0.xsd',
      };
      this.ruleSource.triggers = {
        class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Triggers',
      };
      this.ruleSource.restrictions = {
        class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions',
      };
      this.ruleSource.restrictions.behavioral = {
        class:
          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Behavioral',
      };
      this.ruleSource.restrictions.eligibility = {
        class:
          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Eligibility',
      };
      this.ruleSource.schedules = {
        class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules',
      };
      this.ruleSource.schedules.thresholds = {
        class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds',
      };
      this.ruleSource.schedules.caps = {
        class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Caps',
      };
      this.ruleSource.consequences = {
        class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences',
      };
      this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
    }
  }

  getPromotionTemplate(id: number): void {
    this.promotionConfigService.getPromotionTemplate(this.selectedTemplateId, { format: 'json' }).subscribe(
      (data: any) => {
        if (data.success) {
          this.promotionTemplate = data.entity;
          if (this.clone) {
            this.promotionTemplate.name = null;
          }
          this.ruleSource = JSON.parse(this.promotionTemplate.ruleSource);
          if (this.ruleSource.triggers && this.ruleSource.triggers.primaryBehaviorOrRewardedBehavior) {
            if (
              this.ruleSource.triggers.primaryBehaviorOrRewardedBehavior[0].class ===
              'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Triggers$RewardedBehavior'
            ) {
              this.rewardTrigger = 'rewardedBehavior';
            } else if (
              this.ruleSource.triggers.primaryBehaviorOrRewardedBehavior[0].class ===
              'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Triggers$PrimaryBehavior'
            ) {
              this.rewardTrigger = 'primaryBehavior';
            }
          }
        }
      },
      () => {
        this.router.navigate(['/forbidden']);
      }
    );
  }

  getDisableTemplateEdits(): void {
    this.configService.getConfiguration('DISABLE_TEMPLATE_EDITS').subscribe(
      (data: any) => {
        if (data.success && data.entity[0]) {
          this.disableEdits = Boolean(JSON.parse(data.entity[0].cfgValue));
        }
      },
      () => {}
    );
  }

  getPromotion(id: number): void {
    this.promotionService.getPromotion(id).subscribe(
      (data: any) => {
        if (data.success) {
          this.promotion = data.entity;
        }
      },
      () => {}
    );
  }

  getProgramPackages(programId: number): void {
    this.programService.getProgramPackages(programId).subscribe((data) => {
      if (data.success) {
        this.programPackages = data.entity;
      }
    });
  }

  getRewardTypes(): void {
    var rewardTypeFilter = {};
    rewardTypeFilter['statuses'] = ['ACTIVE', 'PENDING'];
    rewardTypeFilter['dir'] = 'asc';
    rewardTypeFilter['column'] = 1;
    this.rewardTypeService.getRewardTypes(rewardTypeFilter).subscribe(
      (data: any) => {
        if (data.success) {
          this.rewardTypes = data.entity.aaData;
        }
      },
      () => {}
    );
  }

  getEndpointTypes(): void {
    this.communicationsService.getAllEndpointTypes(false).subscribe((data: any) => {
      if (data.success) {
        this.endpointTypes = data.entity;
      }
    });
  }
  getRelationships(): void {
    this.partyRelationshipService.getPartyRelationships().subscribe(
      (data: any) => {
        if (data.success) {
          this.partyRelationships = data.entity;
        }
      },
      () => {}
    );
  }

  getPartyRoles(): void {
    this.partyRoleService.getAllRole().subscribe(
      (data: any) => {
        if (data.success) {
          this.partyRoles = data.entity;
        }
      },
      () => {}
    );
  }

  getCommunicationTypes(): void {
    this.communicationsService.getCommunicationTypes().subscribe(
      (data: any) => {
        if (data.success) {
          this.communicationTypes = data.entity;
        }
      },
      () => {}
    );
  }

  //TODO: implement filter to filter this by whatever type is selected
  getCommunications(): void {
    this.communicationsService.getCommunications(null).subscribe(
      (data: any) => {
        if (data.success) {
          this.communications = data.entity.aaData;
        }
      },
      () => {}
    );
  }

  getObjectFromPath(path: string): any {
    if (path) {
      const pathArray = path.split('.');
      return this.getPathRecursive(pathArray, this);
    } else {
      // console.debug('UserPreferences - Bad path: ' + path);
      return null;
    }
  }

  private getPathRecursive(remainingPath: any, currentObject: any): any {
    const target = remainingPath.shift();

    if (typeof target === 'undefined') {
      // something ain't right
      console.log('UserPreferences - getPathRecursive target is undefined: ' + remainingPath);
      return undefined;
    } else {
      // figure out what object we're getting if any
      let object: any;
      if (/^[0-9]*$/.test(target) && currentObject) {
        // check if we have a number
        object = currentObject[parseInt(target, 10)];
      } else if (!/[^\w|]|[0-9]/.test(target) && currentObject) {
        // or a string
        object = currentObject[target];
      } else {
        console.log('getPathRecursive - path contains invalid characters: ' + target);
        object = undefined;
      }

      // decide if we should return
      if (remainingPath.length === 0 || object === undefined) {
        // if the path has been consumed OR we've hit an invalid branch of the path
        // go ahead and return what we've got
        return object;
      } else {
        return this.getPathRecursive(remainingPath, object);
      }
    }
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  setRewardTrigger(code: string): void {
    this.rewardTrigger = code;
  }

  inputPrimaryBehaviorChangeHandler(triggers: any): void {
    this.ruleSource.triggers = triggers;
    this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
  }

  toggleSection(section: any): void {
    if (section.sectionName === 'memberEligibilityRestrictions') {
      this.showEligibilityRestrictions = section.open;
    } else if (section.sectionName === 'triggers') {
      this.showTriggers = section.open;
    } else if (section.sectionName === 'activityEligibilityRestrictions') {
      this.showActivityRestrictions = section.open;
    } else if (section.sectionName === 'thresholds') {
      this.showThresholds = section.open;
    } else if (section.sectionName === 'caps') {
      this.showCaps = section.open;
    } else if (section.sectionName === 'consequences') {
      this.showConsequences = section.open;
    } else if (section.sectionName === 'xmlPreview') {
      this.showJsonPreview = section.open;
    } else if (section.sectionName === 'validation') {
      this.showValidation = section.open;
    } else if (section.sectionName === 'relatedConfigs') {
      this.showRelatedConfigs = section.open;
    }
  }

  toggleOpen(): void {
    if (this.showRefSection) {
      this.showRefSection = false;
    } else {
      this.showRefSection = true;
    }
  }

  inputEligibilityRestrictionChangeHandler(restriction: any, restrictionType: string): void {
    if (restriction === null || restriction.length === 0) {
      delete this.ruleSource.restrictions.eligibility[restrictionType];
    } else {
      this.ruleSource.restrictions.eligibility[restrictionType] = restriction;
    }
    this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
  }

  inputActivityRestrictionChangeHandler(restriction: any, restrictionType: string): void {
    if (restriction === null || restriction.length === 0) {
      delete this.ruleSource.restrictions.behavioral[restrictionType];
    } else {
      this.ruleSource.restrictions.behavioral[restrictionType] = restriction;
    }
    this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
  }

  inputThresholdsChangeHandler(threshold: any, thresholdType: string): void {
    if (threshold === null || threshold.length === 0) {
      delete this.ruleSource.schedules.thresholds[thresholdType];
    } else {
      this.ruleSource.schedules.thresholds[thresholdType] = threshold;
    }
    this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
  }

  inputCapsChangeHandler(cap: any, capType: string): void {
    if (cap === null || cap.length === 0) {
      delete this.ruleSource.schedules.caps[capType];
    } else {
      this.ruleSource.schedules.caps[capType] = cap;
    }
    this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
  }

  inputConsequencesChangeHandler(consequence: any, consequenceType: string): void {
    if (consequence === null || consequence.length === 0) {
      delete this.ruleSource.consequences[consequenceType];
    } else {
      this.ruleSource.consequences[consequenceType] = consequence;
    }
    this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
  }

  saveTemplate(): void {
    var params = {
      format: 'json',
    };
    if (!this.promotionTemplate.promotionId) {
      this.promotionTemplate.promotionId = this.selectedPromotionId;
    }
    if (this.clone) {
      this.promotionTemplate.id = null;
    }
    this.promotionTemplate.ruleSource = JSON.stringify(this.ruleSource);
    this.promotionConfigService
      .savePromotionTemplate(this.promotionTemplate, this.promotionTemplate.id, params)
      .subscribe(
        (data: any) => {
          this.responseHelperService.success('Template Saved');
          this.successEvent.emit('success');
          if (this.clone || !this.promotionTemplate.id) {
            this.promotionTemplate.id = data.entity.id;
            this.router.navigateByUrl(
              '/programs/' +
                this.selectedProgramId +
                '/promotions/' +
                this.selectedPromotionId +
                '/promotion-template/' +
                data.entity.id
            );
          }
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error, false);
        }
      );
  }

  save(): void {
    this.saveTemplate();
  }

  cancel(): void {
    this.location.back();
  }
}
