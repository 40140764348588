<form [formGroup]="contentGroupForm">
  <div class="d-flex justify-content-end mt-4 mr-4">
    <mat-icon (click)="cancel()" id="close-modal-button" class="cursor-pointer">close</mat-icon>
  </div>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="name" autofocus />
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="com-label">Delivery protocol <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <ng-select
        [items]="deliveryProtocols"
        bindLabel="name"
        [clearable]="false"
        bindValue="id"
        formControlName="deliveryProtocolId"
        placeholder="Select protocol"
      ></ng-select>
    </div>

    <div class="modal-footer">
      <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!contentGroupForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>
