<div>
  <form>
    <label>The ACTIVITY history for the member must contain:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="attrIntervalBehaviorCountThreshold !== null">
        <div
          *ngFor="let attrIntBehCntThreshold of attrIntervalBehaviorCountThreshold; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeThreshold(behCntThreshold)"
            title="Remove Threshold"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{ attrIntBehCntThreshold.thresholdValue }}"
                >more than</label
              >
              <input
                class="form-control m-b"
                type="number"
                id="thresholdVal_{{ attrIntBehCntThreshold.thresholdValue }}"
                name="thresholdValue"
                [(ngModel)]="attrIntBehCntThreshold.thresholdValue"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: attrIntBehCntThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >for Behaviors with Codes (comma separated list):</a
              >
              <input
                class="form-control m-b"
                type="text"
                id="behCodes_{{ attrIntBehCntThreshold.thresholdValue }}"
                name="behaviorCodes"
                [(ngModel)]="attrIntBehCntThreshold.behaviorCodes"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="attrName_{{ attrIntBehCntThreshold.thresholdValue }}"
                >For the effective date range (covering the activity date) of the attribute:</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="attrName_{{ attrIntBehCntThreshold.thresholdValue }}"
                name="attrName"
                [(ngModel)]="attrIntBehCntThreshold.attrName"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="attrValue_{{ attrIntBehCntThreshold.thresholdValue }}"
                >with value (leave blank for any):</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="attrValue_{{ attrIntBehCntThreshold.thresholdValue }}"
                name="attrName"
                [(ngModel)]="attrIntBehCntThreshold.attrValue"
                (ngModelChange)="updateThresholds()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{ attrIntBehCntThreshold.thresholdValue }}"
                >Include counts from members related by (leave blank for current member only or use a comma separated
                list):</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="relationshipName_{{ attrIntBehCntThreshold.thresholdValue }}"
                name="relationshipName"
                [(ngModel)]="attrIntBehCntThreshold.relationshipName"
                (ngModelChange)="updateThresholds()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Attribute Interval Behavior Count Threshold
        </button>
      </div>
    </div>
  </form>
</div>
