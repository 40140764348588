import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { BehaviorsService } from 'src/app/services/behaviors.service';
import { Behavior } from 'src/app/data/model';

@Component({
  selector: 'app-behaviors-modal',
  templateUrl: './behaviors-modal.component.html',
  styleUrls: ['./behaviors-modal.component.scss'],
})
export class BehaviorsModalComponent implements OnInit {
  @Input() behavior: Behavior;
  @Input() hierarchySources: any[];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  behaviorsForm: FormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private statusHelperService: StatusHelperService,
    private behaviorsService: BehaviorsService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.behavior) {
      this.title = 'Edit Behavior';
      this.isEdit = true;
      this.behaviorsForm = this.formBuilder.group({
        name: [this.behavior.name, Validators.required],
        code: [this.behavior.code, Validators.required],
        status: [this.behavior.status, Validators.required],
        hierarchySourceId: [this.behavior.hierarchySourceId, Validators.required],
        description: [this.behavior.description],
      });
    } else {
      this.title = 'New Behavior';
      this.isEdit = false;
      this.behaviorsForm = this.formBuilder.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        status: ['', Validators.required],
        hierarchySourceId: [null, Validators.required],
        description: [''],
      });
    }
  }

  ok(): void {
    let id;
    let value: Behavior;
    if (this.isEdit) {
      id = this.behavior.id;
      // On edit it is necessary to send params createdOn, createdBy, updatedOn, updatedetBy. That params are not in form
      value = { ...this.behavior, ...this.behaviorsForm.value };
    } else {
      value = this.behaviorsForm.value;
    }
    this.behaviorsService.createOdUpdateBehavior(id, value).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
