export const DATA_CARD_TEST_ID = 'data-card';

export const CONFIGURATIONS_BORDER_COLOR = '#0C7762';
export const CONFIGURATIONS_TITLE = 'Configurations';
export const CONFIGURATIONS_TEST_ID = `${DATA_CARD_TEST_ID}${`-`}${CONFIGURATIONS_TITLE.toLowerCase()}`;

export const PROGRAMS_BORDER_COLOR = '#0D4774';
export const PROGRAMS_TITLE = 'Programs';
export const PROGRAMS_TEST_ID = `${DATA_CARD_TEST_ID}${`-`}${PROGRAMS_TITLE.toLowerCase()}`;

export const SPONSORS_BORDER_COLOR = '#583889';
export const SPONSORS_TITLE = 'Sponsorships';
export const SPONSORS_TEST_ID = `${DATA_CARD_TEST_ID}${`-`}${SPONSORS_TITLE.toLowerCase()}`;
