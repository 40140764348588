import { Component, OnInit, Input, Output, EventEmitter, Injectable } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {
  NgbDate,
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
  }
}
@Component({
  selector: 'app-filter-datepicker',
  templateUrl: './filter-datepicker.component.html',
  styleUrls: ['./filter-datepicker.component.scss'],
  providers: [
    // { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class FilterDatepickerComponent implements OnInit {
  @Input() section: any;
  @Input() filter: any;
  faCalendar = faCalendar;
  model2: string;
  dateModel: NgbDate;
  minDate: NgbDate;
  maxDate: NgbDate;

  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private dateHelperService: DateHelperService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {}

  ngOnInit(): void {
    if (this.filter && this.filter.placeholder.toLowerCase() === 'dob') {
      this.minDate = new NgbDate(1925, 1, 1);
      const today = new Date();
      this.maxDate = new NgbDate(this.getYear(today), 12, 31);
    }
    this.dateModel = this.dateHelperService.stringToNgbDate(this.filter.value);
    console.log('console for dateModel', this.dateModel);
  }

  getYear(date): number {
    const d = new Date(date);
    const year = d.getFullYear();
    return year;
  }

  clearDate(): void {
    this.dateModel = null;
    this.filter.value = null;
  }

  formatDate(dateToFormat: any): void {
    if (dateToFormat !== null) {
      if (this.filter.endDate) {
        const tomorrow = this.ngbCalendar.getNext(dateToFormat, 'd', 1);
        this.filter.value = this.ngbToString(tomorrow);
      } else {
        this.filter.value = this.ngbToString(dateToFormat);
      }
    } else {
      delete this.filter.value;
    }
    this.inputChangeEmitter.emit();
  }

  private ngbToString(dateToFormat: NgbDate): string {
    return dateToFormat.month + '-' + dateToFormat.day + '-' + dateToFormat.year;
  }
}
