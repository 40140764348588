<div>
  <label>Restrict the Activity Amount (by Range):</label>
  <div class="row padded form-group">
    <div class="col-md-12" *ngIf="amountRange !== null">
      <div *ngFor="let amtRange of amountRange; let i = index" class="border-card-padding form-group col-lg-12">
        <button
          class="btn btn-attention float-right"
          type="button"
          (click)="removeRestriction(amtRange)"
          title="Remove Restriction"
        >
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
        <form>
          <div class="form-group">
            <label class="control-label" for="amtRangeVal_{{ amtRange.lowerBound }}">Such that it:</label>
            <select
              name="amtRange_{{ amtRange.value }}"
              class="form-control"
              [(ngModel)]="amtRange.condition"
              (ngModelChange)="updateRestrictions()"
            >
              <option
                *ngFor="let condition of conditions"
                [selected]="amtRange.condition === condition.value"
                [value]="condition.value"
              >
                {{ condition.text }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label" for="amtRangeVal_{{ amtRange.lowerBound }}">Greater Than or Equal To:</label>
            <input
              class="form-control m-b"
              type="number"
              id="amtRangeVal_{{ amtRange.lowerBound }}"
              name="lowerBound"
              [(ngModel)]="amtRange.lowerBound"
              (ngModelChange)="updateRestrictions()"
            />
          </div>
          <div class="form-group">
            <label class="control-label" for="amtRangeVal_{{ amtRange.lowerBound }}">Less Than or Equal To:</label>
            <input
              class="form-control m-b"
              type="number"
              id="amtRangeVal_{{ amtRange.upperBound }}"
              name="upperBound"
              [(ngModel)]="amtRange.upperBound"
              (ngModelChange)="updateRestrictions()"
            />
          </div>
          <div class="form-group">
            <label class="control-label" for="amtRangeMsg_{{ amtRange.matchValue }}">Message:</label>
            <input
              class="form-control m-b"
              type="text"
              id="amtRangeMsg_{{ amtRange.matchValue }}"
              name="msg"
              [(ngModel)]="amtRange.friendlyMessage"
              (ngModelChange)="updateRestrictions()"
            />
          </div>
        </form>
      </div>
    </div>
    <div class="form-group float-right col-md-12">
      <button class="btn btn-default float-right" (click)="initRestrictionObject()">
        <fa-icon [icon]="faPlus"></fa-icon> Add Amount Range
      </button>
    </div>
  </div>
</div>
