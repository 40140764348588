import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppDataService } from './app-data.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SecurityService } from './services/security/security.service';
import { User } from './data/model';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private urlBase: string = this.globalData.baseUrl + 'v2/auth/oauth/token';

  constructor(
    private globalData: AppDataService,
    private securityService: SecurityService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  externalOrgCode: string;
  // Add type to res and POST
  public login(
    username: string,
    password: string,
    callback: (res, boolean) => void,
    errcb: (error: any) => void
  ): void {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded;')
      // .set('Access-Control-Allow-Origin', '*')
      .set('skipInterceptor', 'true');
    const params = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password)
      .set('client_id', 'CheetahAdmin');
    this.http.post(this.urlBase, params, { headers }).subscribe(
      (res: any) => {
        this.globalData.token = res.accessToken;
        const jwth = new JwtHelperService();
        const dt = jwth.decodeToken(res.accessToken);
        this.globalData.username = dt.sub;
        this.globalData.userId = dt.partyId;
        this.globalData.requiresPasswordReset = dt.requiresPasswordReset;
        this.globalData.restHeaders.Authorization = 'Bearer ' + res.accessToken;
        localStorage.setItem('token', res.accessToken);

        this.securityService.requestCurrentUser.subscribe(
          (user: User) => {
            if (user) {
              callback(res, user);
            }
          },
          (res) => {
            this.securityService.handleLoginError();
          }
        );
      },
      () => {
        this.toastr.error('The login information provided is not valid.  Please enter the correct information.');
      }
    );
  }
}
