import { AppDataService } from '../../app-data.service';
import { Injectable } from '@angular/core';
import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { Entry } from './shared.types';
import { SurveyEntry, buildSurvey, Survey } from './Survey';
import { Question, QuestionEntry, buildQuestion } from './Question';

function getStackHeaders(apiKey: string, token: string, orgId: string): AxiosRequestHeaders {
  return {
    api_key: apiKey,
    authorization: token,
    organization_uid: orgId,
  };
}

export function getGetConfig(apiKey: string, token: string, orgId: string): AxiosRequestConfig {
  const retval = {
    headers: getStackHeaders(apiKey, token, orgId),
    method: 'GET',
  };
  console.log(JSON.stringify(retval));
  return retval;
}

export function getPutConfig(apiKey: string, token: string, orgId: string): AxiosRequestConfig {
  return {
    headers: getStackHeaders(apiKey, token, orgId),
    method: 'PUT',
  };
}

export function getPostConfig(apiKey: string, token: string, orgId: string): AxiosRequestConfig {
  return {
    headers: getStackHeaders(apiKey, token, orgId),
    method: 'POST',
  };
}

export enum ContentTypeUid {
  survey = 'survey',
  question = 'question',
}

const searchClauseFieldsMap: Record<ContentTypeUid, string[]> = {
  [ContentTypeUid.survey]: ['title', 'description'],
  [ContentTypeUid.question]: ['title', 'description', 'question_text'],
};
function buildSearchClause(searchText: string | undefined | null, contentTypeUid: string): string {
  if (!searchText) {
    return '';
  }
  const searchTextRegex = { $regex: searchText, $options: 'i' };
  const searchTerms = searchClauseFieldsMap[contentTypeUid].map((fieldName) => ({ [fieldName]: searchTextRegex }));
  return `&query=${JSON.stringify({ $or: searchTerms })}`;
}

interface ContentGetOptions {
  contentTypeUid: ContentTypeUid;
  searchText?: string;
  offset?: number;
  limit?: number;
  entryUid?: string;
  includes: string;
}

const ICH_API_KEY = 'ICH_API_KEY';
const ICH_STACK_TOKEN = 'ICH_STACK_TOKEN';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  // private utilityService: UtilityService = new UtilityService();
  // @ts-ignore
  private stackApiKey = ''; // TODO All of these calls should run through Aggie or Cheetah API.
  // @ts-ignore
  private stackToken = ''; // Putting these secrets in local env variables is acceptable for technical prototype only.
  private orgId = 'blt2a2f830c908353cc';
  constructor(private globalData: AppDataService) {}

  private surveyDetailIncludes = [
    `&include[]=client`,
    `include[]=questions.standard_response_question.question`,
    `include[]=questions.standard_response_question.question.responses.standard_responses.response_sets`,
    `include[]=questions.standard_response_question.response_set`,
    `include[]=questions.custom_response_question.question`,
  ].join('&');

  private questionDetailIncludes = [`&include[]=client`, `include[]=responses.standard_responses.response_sets`].join(
    '&'
  );

  setCredentials(stackApiKey: string, stackToken: string) {
    localStorage.setItem(ICH_API_KEY, stackApiKey);
    localStorage.setItem(ICH_STACK_TOKEN, stackToken);
    this.initCredentials();
  }

  initCredentials() {
    this.stackApiKey = localStorage.getItem(ICH_API_KEY) || '';
    this.stackToken = localStorage.getItem(ICH_STACK_TOKEN) || '';
  }

  async getSurveys(options?: { searchText?: string; entryUid?: string }): Promise<Survey[]> {
    const { searchText, entryUid } = options || {};
    const entries = await this.getContentEntries<SurveyEntry>({
      contentTypeUid: ContentTypeUid.survey,
      includes: this.surveyDetailIncludes,
      searchText,
      entryUid,
    });

    return entries.map((entry) => buildSurvey(entry));
  }

  async getQuestions(options?: { searchText?: string; entryUid?: string }): Promise<Question[]> {
    const { searchText, entryUid } = options || {};
    const entries = await this.getContentEntries<QuestionEntry>({
      contentTypeUid: ContentTypeUid.question,
      includes: this.questionDetailIncludes,
      searchText,
      entryUid,
    });

    return entries.map((entry) => buildQuestion(entry));
  }

  async getContentEntries<T>(options: ContentGetOptions): Promise<T[]> {
    if (!this.stackApiKey || !this.stackToken) {
      this.initCredentials();
      if (!this.stackApiKey || !this.stackToken) {
        console.log('need credentials to continue...');
        return [];
      }
    }
    if (options.entryUid) {
      const response = await axios.get<{ entry: Entry }>(
        `https://api.contentstack.io/v3/content_types/${options.contentTypeUid}/entries/${options.entryUid}?locale=en-us${options.includes}`,
        getGetConfig(this.stackApiKey, this.stackToken, this.orgId)
      );
      return (response?.data?.entry ? [response.data.entry] : ([] as T[])) as T[];
    }
    const searchClause = buildSearchClause(options.searchText, options.contentTypeUid);
    const response: AxiosResponse = await axios.get<Entry[]>(
      `https://api.contentstack.io/v3/content_types/${options.contentTypeUid}/entries?locale=en-us${searchClause}${options.includes}`,
      getGetConfig(this.stackApiKey, this.stackToken, this.orgId)
    );
    return response.data.entries as T[];
  }
}
