<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <div class="filters_container">
        <div class="search-input">
          <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
          <mat-icon class="search">search</mat-icon>
        </div>
        <div class="right_container">
          <button class="filter-btn" (click)="FilterModel()">
            FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
          </button>
          <button class="btn-add" (click)="openModal()">ADD ALLOWLIST ENTRY</button>
        </div>
      </div>
      <app-filter-tags
        *ngIf="searchFilters"
        isPopup="true"
        [(appliedFilters)]="appliedFilters"
        [(filterButton)]="filterButton"
        [(searchFilters)]="searchFilters"
      ></app-filter-tags>
      <div class="row">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>Type</th>
              <th>Value</th>
              <th>Description</th>
              <th>Created By</th>
              <th>Created On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let each of allowedContactInfoArray | filter: searchText">
              <td>{{ each.type }}</td>
              <td>{{ each.value }}</td>
              <td>{{ each.description }}</td>
              <td>{{ each.createdBy }}</td>
              <td>{{ each.createdOn }}</td>
              <td>
                <div class="float-left actions inline-table">
                  <button class="btn btn-attention btn-sm delete action" (click)="deleteAllowedContactInfo(each)">
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getAllowedContactInfo(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
