<form [formGroup]="functionForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Function Name</label>
          <select name="functionName" class="form-control" formControlName="functionName">
            <option *ngFor="let function of functions" value="{{ function }}">{{ function }}</option>
          </select>
          <div class="mt-2" [hidden]="functionForm.controls.functionName.valid">
            <small class="text-danger">Please select function</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Temporal Unit Code</label>
          <select name="temporalUnitCode" class="form-control" formControlName="temporalUnitCode">
            <option *ngFor="let tempUnit of temporalUnits" value="{{ tempUnit.code }}">{{ tempUnit.code }}</option>
          </select>
          <div class="mt-2" [hidden]="functionForm.controls.temporalUnitCode.valid">
            <small class="text-danger">Please select unit code</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!functionForm.valid"></app-modal-buttons>
  </div>
</form>
