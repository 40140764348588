import React, { FormEvent, FormEventHandler, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Grid from "@iridium/iridium-ui/Grid";
import { LiveAgentSurveyFilterCriteria } from "src/app/services/live-agent-surveys.service";

export type LiveAgentFilterDialogProps = {
  filter: LiveAgentSurveyFilterCriteria | undefined;
  title?: string;
  isModalOpen: boolean;
  liveAgentManagerList: [];
  isLiveAgentManager: boolean;
  setIsModalOpen: (boolean) => void;
  onFormSubmit: (LiveAgentSurveyFilterCriteria) => void;
  renderInputs(params: {
    setPendingFilter: (filter: LiveAgentSurveyFilterCriteria) => void;
    pendingFilter: LiveAgentSurveyFilterCriteria;
  });
};

function LiveAgentFilterDialog({
  isModalOpen,
  liveAgentManagerList,
  isLiveAgentManager,
  setIsModalOpen,
  onFormSubmit,
  filter,
  title = "Apply Filters",
}: LiveAgentFilterDialogProps): JSX.Element {
  const [pendingFilter, setPendingFilter] = useState<LiveAgentSurveyFilterCriteria>(filter);
  const [agentList, setAgentList] = useState([]);
  const [selectedAgentName, setSelectedAgentName] = useState<string>(null);

  useEffect(() => {
    setPendingFilter(filter);
    setAgentList(liveAgentManagerList);
  }, [filter, isModalOpen]);

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onFormSubmit(pendingFilter);
  };

  function resetStateAndClose() {
    setPendingFilter(filter);
    setIsModalOpen(false);
  }

  function initFilter() {
    filter = {
      segments: [],
      startAssessmentDate: null,
      endAssessmentDate: null,
      firstName: "",
      lastName: "",
      primaryInstrumentNumber: "",
      completedByUserIds: [],
      limit: 20,
      offset: 0,
      statuses: [],
    };
  }

  function restartFilters() {
    initFilter();
    setPendingFilter(filter);
    setSelectedAgentName(null);
  }

  function handleModalClose() {
    setIsModalOpen(false);
  }

  function getLiveAgentFilterDialog() {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ pl: 1, pb: 2 }}>
            Agent Name:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="agent-autocomplete"
            options={agentList || []}
            sx={{ width: "50%" }}
            value={selectedAgentName}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => <TextField {...params} label="Agent" />}
            onChange={(event, agent: { label: string; id: number }) => {
              if (agent) {
                setPendingFilter({
                  ...pendingFilter,
                  completedByUserIds: [agent.id],
                });
                setSelectedAgentName(agent.label);
              } else {
                setPendingFilter({
                  ...pendingFilter,
                  completedByUserIds: [],
                });
                setSelectedAgentName(null);
              }
            }}
          />
        </Grid>
      </>
    );
  }

  return (
    <Dialog open={isModalOpen} onClose={handleModalClose}>
      <form onSubmit={handleFormSubmit}>
        <DialogTitle>
          Survey Status <hr />
        </DialogTitle>

        <DialogContent>
          <Grid container columnSpacing={1} rowSpacing={2} sx={{ mb: 2, mt: 1 }}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ pl: 1, mb: 2 }}>
                Apply filters
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pl: 1 }}>
                Assessment Date:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start"
                  clearable
                  value={pendingFilter.startAssessmentDate}
                  onChange={(newValue) => {
                    setPendingFilter({
                      ...pendingFilter,
                      startAssessmentDate: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End"
                  clearable
                  value={pendingFilter.endAssessmentDate}
                  onChange={(newValue) => {
                    setPendingFilter({
                      ...pendingFilter,
                      endAssessmentDate: newValue,
                    });
                  }}
                  renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pl: 1 }}>
                First Name:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ width: "50%" }}
                id="firstName"
                onChange={(event) => {
                  setPendingFilter({
                    ...pendingFilter,
                    firstName: event.target.value,
                  });
                }}
                value={pendingFilter.firstName || ""}
                label={"Add First Name"}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pl: 1 }}>
                Last Name:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ width: "50%" }}
                onChange={(event) => {
                  setPendingFilter({
                    ...pendingFilter,
                    lastName: event.target.value,
                  });
                }}
                value={pendingFilter.lastName || ""}
                label={"Add Last Name"}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pl: 1 }}>
                Instrument Number:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ width: "70%" }}
                onChange={(event) => {
                  setPendingFilter({
                    ...pendingFilter,
                    primaryInstrumentNumber: event.target.value,
                  });
                }}
                value={pendingFilter.primaryInstrumentNumber || ""}
                label={"Add Primary Instrument Number"}
              />
            </Grid>
          </Grid>
          {isLiveAgentManager ? getLiveAgentFilterDialog() : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={restartFilters}>Reset Filters</Button>
          <Button type="submit">Apply</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LiveAgentFilterDialog;
