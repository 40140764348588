import MuiTableHead, { TableHeadProps as MuiTableHeadProps } from "@mui/material/TableHead";
import React from "react";

export default function TableHead(props: MuiTableHeadProps) {
  const propsWithDefaults: MuiTableHeadProps = {
    ...props,
    sx: {
      ...props.sx,
      backgroundColor: (theme) => theme.palette.grey["100"],
    },
  };
  return <MuiTableHead {...propsWithDefaults} />;
}
