import getLogger from '../../services/logging/logging.service';
import {
  MARKET_ID_KEY,
  MARKET_ROUTE_PATH_WITH_ID,
  PARENT_ID_KEY,
  CLIENT_ID_KEY,
  LINE_OF_BUSINESS_ID_KEY,
  PARENT_ROUTE_WITH_ID_KEY,
  CLIENT_ROUTE_PATH_WITH_ID,
  LINE_OF_BUSINESS_ROUTE_PATH_WITH_ID,
} from '../../route.constants';

export enum EntityPartyRoleName {
  PARENT = 'Parent Organization',
  CLIENT = 'Client',
  LOB = 'Line of Business',
  MARKET = 'Market',
}

const logger = getLogger({ namespace: 'Organizations' });

export type OrganizationHierarchyIdLookup = {
  parentId?: number;
  clientId?: number;
  lineOfBusinessId?: number;
  marketId?: number;
};

export type NavigateToOrganizationBasedOnChildTypeParams = {
  navigateMethod: (pathList: string[]) => void;
  organizationIdToRouteTo: number;
  childEntityPartyRoleName: EntityPartyRoleName;
} & OrganizationHierarchyIdLookup;

type NavigateToOrganizationRouteParams = {
  path: string;
} & OrganizationHierarchyIdLookup;

function replacePathWithOrganizationParameters({
  parentId,
  clientId,
  lineOfBusinessId,
  marketId,
  path,
}: NavigateToOrganizationRouteParams) {
  return path
    .replace(`:${PARENT_ID_KEY}`, parentId?.toString())
    .replace(`:${CLIENT_ID_KEY}`, clientId?.toString())
    .replace(`:${MARKET_ID_KEY}`, marketId?.toString())
    .replace(`:${LINE_OF_BUSINESS_ID_KEY}`, lineOfBusinessId?.toString());
}

export type GetPathWithReplacementsParams = Omit<NavigateToOrganizationBasedOnChildTypeParams, 'navigateMethod'>;

export function getPathWithReplacements(params: GetPathWithReplacementsParams) {
  const { parentId, clientId, lineOfBusinessId, marketId, childEntityPartyRoleName, organizationIdToRouteTo } = params;
  switch (childEntityPartyRoleName) {
    case EntityPartyRoleName.PARENT: {
      return replacePathWithOrganizationParameters({
        ...params,
        parentId: organizationIdToRouteTo,
        path: PARENT_ROUTE_WITH_ID_KEY,
      });
    }
    case EntityPartyRoleName.CLIENT:
      return replacePathWithOrganizationParameters({
        ...params,
        clientId: organizationIdToRouteTo,
        path: CLIENT_ROUTE_PATH_WITH_ID,
      });
    case EntityPartyRoleName.LOB:
      return replacePathWithOrganizationParameters({
        ...params,
        lineOfBusinessId: organizationIdToRouteTo,
        path: LINE_OF_BUSINESS_ROUTE_PATH_WITH_ID,
      });
    case EntityPartyRoleName.MARKET:
      return replacePathWithOrganizationParameters({
        ...params,
        marketId: organizationIdToRouteTo,
        path: MARKET_ROUTE_PATH_WITH_ID,
      });
    default: {
      const errorMessage = 'Attempted to navigate with an unknown childType or childType was undefined';
      logger.error({
        message: errorMessage,
        data: {
          ...params,
          entityRoleName: childEntityPartyRoleName,
        },
      });
      throw new Error(errorMessage);
    }
  }
}

export function navigateToOrganizationBasedOnChildType({
  organizationIdToRouteTo,
  parentId,
  clientId,
  lineOfBusinessId,
  marketId,
  childEntityPartyRoleName,
  navigateMethod,
}: NavigateToOrganizationBasedOnChildTypeParams) {
  const navigateToOrganizationRouteParams: NavigateToOrganizationRouteParams = {
    parentId,
    clientId,
    lineOfBusinessId,
    marketId,
    // This gets set below
    path: '',
  };

  const pathWithReplacements = getPathWithReplacements({
    ...navigateToOrganizationRouteParams,
    childEntityPartyRoleName,
    organizationIdToRouteTo,
  });

  navigateMethod([pathWithReplacements]);
}
