<div [ngClass]="filter.inputClass" class="search-filter-bottom d-flex" *ngIf="section.open">
  <input
    type="search"
    [hidden]="filter.hide"
    class="form-control font-relative-width"
    [ngClass]="{ 'form-control-new font-relative-width': packagepop }"
    placeholder="{{ filter.placeholder }}"
    [(ngModel)]="filter.value"
    (ngModelChange)="inputChangeEmitter.emit()"
  />
</div>
