<form [formGroup]="formGroup">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Identifier Type <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            id="instrument-modal-instrument-types"
            [items]="instrumentTypes"
            bindLabel="code"
            [clearable]="false"
            formControlName="instrumentType"
            (change)="setInstrumentTypeValidation($event)"
          >
          </ng-select>
          <!-- <div class="mt-sm" [hidden]="formGroup.controls.instrumentType?.valid">
            <small class="text-danger">Select instrument type</small>
          </div> -->
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Identifier Number <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input
            id="instrument-modal-instrument-number"
            [mask]="instrumentNumberInputMask || undefined"
            [showMaskTyped]="instrumentNumberInputMask && true"
            [dropSpecialCharacters]="shouldDropSpecialCharacters"
            type="text"
            class="form-control"
            formControlName="instrumentNumber"
          />
          <div class="mt-sm" data->
            <small class="text-danger" id="instrument-number-validation-small">{{
              this.getInstrumentNumberHelperText()
            }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Account</label>
          <input type="text" class="form-control" formControlName="account" />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select id="instrument-modal-status-dropdown" class="form-control" formControlName="status" required>
            <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
          </select>
          <!-- <div class="mt-sm" [hidden]="formGroup.controls.status?.valid">
            <small class="text-danger">Select status</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Holder Name</label>
          <input type="text" class="form-control" formControlName="holderName" />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Country</label>
          <input type="text" class="form-control" formControlName="country" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Activation Date</label>
          <div class="input-group date">
            <input
              class="form-control cursor-pointer"
              readonly="readonly"
              (click)="activationDate.toggle()"
              formControlName="activationDate"
              ngbDatepicker
              #activationDate="ngbDatepicker"
              [footerTemplate]="footerTemplateActivationDate"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="activationDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Expiration Date</label>
          <div class="input-group date">
            <input
              class="form-control cursor-pointer"
              readonly="readonly"
              (click)="expiryDate.toggle()"
              formControlName="expiryDate"
              ngbDatepicker
              #expiryDate="ngbDatepicker"
              [footerTemplate]="footerTemplateExpiryDate"
            />
            <!--              (ngModelChange)="formatDate('expiryDate')"-->
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="expiryDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Cancelation Date</label>
          <div class="input-group date">
            <input
              class="form-control cursor-pointer"
              readonly="readonly"
              (click)="cancelationDate.toggle()"
              formControlName="cancelationDate"
              ngbDatepicker
              #cancelationDate="ngbDatepicker"
              [footerTemplate]="footerTemplateCancelationDate"
            />
            <!--              (ngModelChange)="formatDate('cancelationDate')"-->

            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="cancelationDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Close Date</label>
          <div class="input-group date">
            <input
              class="form-control cursor-pointer"
              readonly="readonly"
              (click)="closeDate.toggle()"
              formControlName="closeDate"
              ngbDatepicker
              #closeDate="ngbDatepicker"
              [footerTemplate]="footerTemplateCloseDate"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="closeDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="checkbox checkbox-success">
          <input id="isPrimary" class="mr-2" type="checkbox" formControlName="isPrimary" />
          <label for="isPrimary"> Primary </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!formGroup.valid"></app-modal-buttons>
  </div>
</form>

<ng-template #footerTemplateActivationDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate" (click)="clearDateInput('activationDateValue'); activationDate.toggle()">
      Clear
    </button>
  </div>
</ng-template>

<ng-template #footerTemplateExpiryDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate" (click)="clearDateInput('expiryDateValue'); expiryDate.toggle()">Clear</button>
  </div>
</ng-template>
<ng-template #footerTemplateCancelationDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate" (click)="clearDateInput('cancelationDateValue'); cancelationDate.toggle()">
      Clear
    </button>
  </div>
</ng-template>

<ng-template #footerTemplateCloseDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate" (click)="clearDateInput('closeDateValue'); closeDate.toggle()">Clear</button>
  </div>
</ng-template>
