<div class="wrapper wrapper-content" *ngIf="communication">
  <div class="col-lg-12 com-header header-container">
    <h1 class="width-100">
      <span class="cursor-pointer" (click)="selectComm(communication)">
        <fa-icon [icon]="getIcon(communication)"></fa-icon>
        {{ communication.name }}
      </span>
    </h1>
  </div>
  <app-communication-parent-tree
    (selectedCommunicationChanges)="selectComm($event)"
    [selectedCommunication]="selectedComm"
    [rootComm]="communication"
    (communicationChanged)="communicationChanged($event)"
    (addedCommunicationChanges)="getCommunicationStructure()"
  >
  </app-communication-parent-tree>
  <div class="row p-3 d-flex flex-column" style="padding-top: 0 !important">
    <div class="col-lg-12 com-header" style="margin: 0px !important">
      <h2 class="com-title">{{ selectedComm?.name }}</h2>
      <div *ngIf="selectedComm" class="d-flex align-items-center">
        <span class="com-status">Status:</span>&nbsp;&nbsp;
        <!-- <span class="com-badge rounded-pill mr-3" [ngClass]="statusHelper[selectedComm.status]">{{
          selectedComm.status
        }}</span> -->
        <div class="new-badge rounded-pill mr-3" [ngClass]="statusHelper[selectedComm.status]">
          <span class="badge-statusmsg">{{ selectedComm.status }}</span>
        </div>
        <!-- <button type="button" class="btn btn-outline-primary mr-2 com-btn-test" title="Test">TEST</button> -->
        <button class="btn btn-outline-primary btn-sm" (click)="edit(selectedComm)">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12" *ngIf="selectedComm">
        <div>
          <div class="ibox-content-new" style="border: none">
            <!-- <div class="col-12 com-grey"></div> -->
            <!-- <div class="row">
              <div class="col-lg-12">
                <div class="m-b-md">
                  TODO: implement edit and test modals
                  <button class="btn btn-primary btn-sm float-right" (click)="edit(selectedComm)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon> Edit
                  </button>
                  <button
                    *ngIf="appDataService.isAdmin &amp;&amp; currentUser === 'admin'"
                    class="btn btn-primary btn-sm float-right m-r-sm"
                    (click)="test(selectedComm)"
                  >
                    <fa-icon [icon]="faClipboardCheck"></fa-icon> Test
                  </button>
                  <h2>{{ selectedComm.name }}</h2>
                </div>
              </div>
            </div> -->
            <!-- <div class="row">
              <div class="col-lg-4">
                <dl class="dl-horizontal">
                  <div>
                    <dt>Status:</dt>
                    <dd>
                      <span class="badge" [ngClass]="statusHelper[selectedComm.status]">{{ selectedComm.status }}</span>
                    </dd>
                  </div>
                  <div>
                    <dt>Type:</dt>
                    <dd class="username">{{ selectedComm.communicationType.name }}</dd>
                  </div>
                  <div>
                    <dt>Endpoint Type:</dt>
                    <dd class="username">
                      {{
                        selectedComm.communicationDeliveryProtocols?.length > 0
                          ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name
                          : ""
                      }}
                    </dd>
                  </div>
                  <div>
                    <dt>Protocol:</dt>
                    <dd class="username">
                      {{
                        selectedComm.communicationDeliveryProtocols?.length > 0
                          ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.name
                          : ""
                      }}
                    </dd>
                  </div>
                </dl>
              </div>
            </div> -->
            <div class="tabs-container">
              <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>Segments</a>
                  <ng-template ngbNavContent>
                    <app-communication-segments-tab [commId]="commId"></app-communication-segments-tab>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Attributes</a>
                  <ng-template ngbNavContent>
                    <app-communication-attributes-tab [commId]="commId"></app-communication-attributes-tab>
                  </ng-template>
                </li>
                <li [ngbNavItem]="3">
                  <a ngbNavLink>Attachments</a>
                  <ng-template ngbNavContent>
                    <app-communication-attachments-tab [commId]="commId"></app-communication-attachments-tab>
                  </ng-template>
                </li>
                <li [ngbNavItem]="4">
                  <a ngbNavLink>History</a>
                  <ng-template ngbNavContent>
                    <app-communication-history-tab [commId]="commId"></app-communication-history-tab>
                  </ng-template>
                </li>
                <li [ngbNavItem]="5">
                  <a ngbNavLink>Content</a>
                  <ng-template ngbNavContent>
                    <app-communication-content-tab
                      [commId]="commId"
                      [endpointType]="
                        selectedComm.communicationDeliveryProtocols.length > 0
                          ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name
                          : ''
                      "
                    >
                    </app-communication-content-tab>
                  </ng-template>
                </li>
                <li [ngbNavItem]="6">
                  <a ngbNavLink>Communication Configuration</a>
                  <ng-template ngbNavContent>
                    <app-communication-configuration-tab [selectedComm]="selectedComm"></app-communication-configuration-tab>
                  </ng-template>
                </li>
              </ul> -->

              <mat-tab-group
                ngbNav
                #nav="ngbNav"
                class="remove-border-bottom nav-tabs"
                animationDuration="0ms"
                (selectedTabChange)="tabClick($event)"
              >
                <mat-tab label="CONFIGURATION">
                  <app-communication-configuration-tab [selectedComm]="selectedComm">
                  </app-communication-configuration-tab>
                </mat-tab>
                <mat-tab label="CONTENT">
                  <ng-template matTabContent>
                    <app-communication-content-tab
                      [selectedComm]="selectedComm"
                      deliveryProtocolName="{{ getDeliveryProtocolName() }}"
                      [endpointType]="
                        selectedComm.communicationDeliveryProtocols.length > 0
                          ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name
                          : ''
                      "
                    >
                    </app-communication-content-tab>
                  </ng-template>
                </mat-tab>
                <mat-tab label="ATTRIBUTES">
                  <app-communication-attributes-tab [commId]="commId"></app-communication-attributes-tab>
                </mat-tab>
                <mat-tab label="ATTACHMENTS">
                  <app-communication-attachments-tab [commId]="commId"></app-communication-attachments-tab>
                </mat-tab>
                <mat-tab label="HISTORY" (click)="tabClick($event)">
                  <app-communication-history-tab
                    [commId]="commId"
                    [tabIndex]="tabIndex"
                  ></app-communication-history-tab>
                </mat-tab>
              </mat-tab-group>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--/CONTAINER-->
</div>
