import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { CommunicationAllowlistService } from 'src/app/services/communication-allowlist.service';
import { AllowedContactInfo } from 'src/app/data/model';

@Component({
  selector: 'app-communication-allowlist-modal',
  templateUrl: './communication-allowlist-modal.component.html',
  styleUrls: ['./communication-allowlist-modal.component.scss'],
})
export class CommunicationAllowlistModalComponent implements OnInit {
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  allowedContactInfoForm: FormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  types = ['PHONE', 'EMAIL'];

  constructor(
    private statusHelperService: StatusHelperService,
    private communicationAllowlistService: CommunicationAllowlistService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    this.title = 'New Allow List Entry';
    this.isEdit = false;
    this.allowedContactInfoForm = this.formBuilder.group({
      value: ['', Validators.required],
      type: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ok(): void {
    let allowedContactInfo = new AllowedContactInfo();

    allowedContactInfo.value = this.allowedContactInfoForm.get('value').value;
    allowedContactInfo.type = this.allowedContactInfoForm.get('type').value;
    allowedContactInfo.description = this.allowedContactInfoForm.get('description').value;

    this.communicationAllowlistService.addToAllowlist(allowedContactInfo).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
        this.modalHelper.dismissAll();
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
