import { GlobalEventService } from '../services/global-event.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TicketService } from '../services/ticket.service';
import { AppDataService } from '../app-data.service';
import { faChevronDown, faPaste, faPause, faFlag, faFileAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent {
  @Input() ticket: any;
  @Input() isTicketsOpened: boolean;
  @Input() interactionText: string;
  @Input() escalateTooltip: string;
  @Input() openedTickets: any[];
  @Output() openedTicketsChange = new EventEmitter();
  @Output() isTicketsOpenedChange = new EventEmitter();
  @Output() interactionTextChange = new EventEmitter();
  @Output() ticketChange = new EventEmitter();
  @Output() escalateTooltipChange = new EventEmitter();

  filterModel: any = { myTickets: true };
  disableTickets = false;
  faChevronDown = faChevronDown;
  faPaste = faPaste;
  faPause = faPause;
  faFlag = faFlag;
  faFile = faFileAlt;
  ticketsInitialized: boolean = false;

  constructor(
    private ticketService: TicketService,
    private globalData: AppDataService,
    private globalEventService: GlobalEventService
  ) {
    this.globalEventService.getGlobalEvent().subscribe((response: any) => {
      if (response.toComponent === 'tickets') {
        if (response.action === 'resolve') {
          this.resolveTicket(response.data);
        } else {
          this.openTicket(response.data);
        }
      }
    });
  }

  isCSRManager = this.globalData.isCSRManager;
  currentUserId = this.globalData.userId;

  toggleFilter(): void {
    this.getTickets().subscribe((tickets: any) => {
      this.openedTickets = tickets.entity.aaData;
      this.ticketsInitialized = true;
    });
  }

  getTickets(): any {
    if (this.filterModel.myTickets) {
      return this.ticketService.getTicketList({
        status: ['ACTIVE', 'INACTIVE', 'ESCALATED'],
        startedBy: this.currentUserId,
      });
    } else {
      return this.ticketService.getTicketList({
        status: ['ACTIVE', 'INACTIVE', 'ESCALATED'],
      });
    }
    this.ticketsInitialized = true;
  }

  toggleTickets(): void {
    if (this.openedTickets?.length > 0 && this.ticketsInitialized) {
      this.isTicketsOpened = !this.isTicketsOpened;
      this.isTicketsOpenedChange.emit(this.isTicketsOpened);
    } else {
      this.getTickets().subscribe((tickets: any) => {
        this.openedTickets = tickets.entity.aaData;
        this.isTicketsOpened = !this.isTicketsOpened;
        this.isTicketsOpenedChange.emit(this.isTicketsOpened);
        this.ticketsInitialized = true;
      });
    }
  }

  openTicket(ticket: any): void {
    this.ticketService.getTicketById(ticket.id).subscribe((ticketNew: any) => {
      this.ticket = ticketNew.entity;
      this.removeTicketFromList(ticket);
      this.addTicketToList(this.ticket);
      if (this.ticket.escalated) {
        this.escalateTooltip = 'Ticket already escalated';
      } else {
        this.escalateTooltip = 'Escalate Ticket';
      }
      if (this.ticket.contactReasonCode === 'TRG') {
        this.interactionText =
          'NAME OF PERSON FILING? * ;\n' +
          'RELATIONSHIP TO ENROLLEE? * ;\n' +
          'DATE FILED? * ;\n' +
          'DATE OF OCCURRENCE? * ;\n' +
          'CONTENTS OF GRIEVANCE?\n' +
          ' WHO IS THE ENROLLEE UPSET WITH? * \n' +
          ' WHAT ARE THEY UPSET ABOUT? * \n' +
          ' WHERE DID IT OCCUR?  *\n' +
          ' WHY IS THE MEMBER UPSET? * ;\n' +
          'STEPS TAKEN TO RESOLVE? * ;\n' +
          'GRIEVANCE RESOLVED? * ;\n' +
          'DECLINED TO FILE FORMAL GRIEVANCE? * ;';
      } else {
        this.interactionText = '';
      }
      this.ticketChange.emit(this.ticket);
      this.escalateTooltipChange.emit(this.escalateTooltip);
      this.interactionTextChange.emit(this.interactionText);
    });
  }

  addTicketToList(ticket: any): void {
    const index = this.openedTickets.findIndex((t: any) => t.id === ticket.id);
    if (index === -1 && this.openedTickets && this.ticketsInitialized) {
      this.openedTickets.push(ticket);
      this.openedTicketsChange.emit(this.openedTickets);
    }
  }

  removeTicketFromList(ticket: any): void {
    const index = this.openedTickets.findIndex((t) => t.id === ticket.id);
    if (index !== -1) {
      this.openedTickets.splice(index, 1);
      this.openedTicketsChange.emit(this.openedTickets);
    }
  }

  resolveTicket(ticket: any): void {
    if (this.ticket && this.ticket.id === ticket.id) {
      this.ticket = null;
      this.ticketChange.emit(this.ticket);
    } else {
      this.removeTicketFromList(ticket.data);
    }
  }
}
