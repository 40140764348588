import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { Filter, SearchFilters } from './../../data/class';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ProcessingActivityLogService } from 'src/app/services/processing-activity-log.service';
import { faCalendar, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationStrategyConfiguration } from 'src/app/data/model';

@Component({
  selector: 'app-processing-logs',
  templateUrl: './processing-logs.component.html',
  styleUrls: ['./processing-logs.component.scss'],
})
export class ProcessingLogsComponent implements OnInit, OnDestroy {
  show: boolean;
  filteredStatuses = [];
  filter: Filter = new Filter();
  dtInstance: any = {};
  limit = 20;
  processingLogs = [];
  lengthToCompare: number;
  appliedFilters: Filter[];
  lockedTables: boolean;
  lock: boolean;
  isLoading: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  faCalendar = faCalendar;
  fromDate: NgbDate;
  toDate: NgbDate;
  faTimes = faTimes;
  searchFilters: SearchFilters;
  searchText: any;
  filterdata: SearchFilters;
  filterButton: boolean = false;
  faExclamationTriangle = faExclamationTriangle;
  configurations: CommunicationStrategyConfiguration[] = [];
  helpers: any;

  constructor(
    private tableHelperService: TableHelperService,
    private title: Title,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private modalHelper: NgbModal,
    private processingActivityLogService: ProcessingActivityLogService,
    private applyfilterService: ApplyFilterService,
    private dateHelperService: DateHelperService
  ) {}

  tableHelper = this.tableHelperService;
  statuses = this.tableHelperService.getStatuses('ENTITY');

  ngOnDestroy(): void {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }
  ngOnInit(): void {
    this.show = true;
    this.searchFilters = {
      formName: 'processing-logs-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'statuses',
          placeholder: 'Select status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          label: 'Status',
        },
        {
          name: 'startDate',
          label: 'Start date',
          type: 'date',
          placeholder: 'mm/dd/yyyy',
        },
        {
          name: 'endDate',
          label: 'End date',
          type: 'date',
          endDate: true,
          placeholder: 'End Date',
        },
        {
          name: 'stageLoadId',
          label: 'File ID',
          placeholder: 'Select file ID',
        },
        {
          name: 'search',
          label: 'Keyword',
          placeholder: 'Search keyword',
        },
      ],
    };
    this.title.setTitle('Processing Logs');
    // this.initFilter();
    this.initNewSearch();
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getProcessingLogs(false);
    this.configurations = null;
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Processing Log';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }
  getProcessingLogs(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.processingActivityLogService
      .getProcessingLogs(this.utilityService.removeNullAndEmptyProperties(this.filter))
      .subscribe(
        (data: any) => {
          if (concat) {
            this.processingLogs = this.processingLogs.concat(data.entity.aaData);
          } else {
            this.processingLogs = data.entity.aaData;
          }
          this.lengthToCompare = data.entity.aaData.length;
          this.newLengthEvent.emit(this.lengthToCompare);
          this.filter.offset += this.limit;
          this.lockedTables = data.entity.aaData.length !== this.limit;
          this.lock = this.lockedTables;
          this.isLoading = false;
        },
        () => {
          this.toastr.error('Error occured');
          this.lockedTables = false;
          this.lock = false;
          this.isLoading = false;
        }
      );
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    this.getProcessingLogs(false);
  }
}
