import Grid from "@iridium/iridium-ui/Grid";
import TextField from "@iridium/iridium-ui/TextField";
import Button from "@iridium/iridium-ui/Button";
import React from "react";

export const CALL_FLOW_ID_INPUT_TEST_ID = "call-flow-input";
export const SAVE_CALL_FLOW_BUTTON_TEST_ID = "save-call-flow-button";
export const IRIDIUM_VOICE_CONTENT_FORM_TEST_ID = "iridium-voice-content-form";

export type IridiumVoiceContentProps = {
  onSubmit: () => void;
  callFlowInError: boolean;
  callFlowId: string;
  callFlowIdHelperText: React.ReactNode;
  onCallFlowIdChange: (callFlowId) => void;
  disableSubmit: boolean;
};

export function IridiumVoiceContent(props: IridiumVoiceContentProps) {
  const { onSubmit, callFlowId, callFlowIdHelperText, onCallFlowIdChange, callFlowInError, disableSubmit } = props;

  function handleFormSubmit(event) {
    event.preventDefault();
    onSubmit();
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <form onSubmit={handleFormSubmit} data-testid={IRIDIUM_VOICE_CONTENT_FORM_TEST_ID}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <TextField
                id="call-flow-id-input"
                InputProps={{ inputProps: { "data-testid": CALL_FLOW_ID_INPUT_TEST_ID } }}
                placeholder="Call Flow ID"
                value={callFlowId}
                sx={{ minWidth: 350 }}
                onChange={(event) => onCallFlowIdChange(event.target.value)}
                name="callFlowId"
                error={callFlowInError}
                helperText={callFlowIdHelperText}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" disabled={disableSubmit} data-testid={SAVE_CALL_FLOW_BUTTON_TEST_ID}>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
