<div class="panel-body">
  <div class="des_container">
    <div class="market_logos">
      <img src="../../../assets/images/Asset 7.png" class="dec_logo" />
    </div>
    <div class="ml-2"><span class="font-weight-bold">Organization configurations:</span>&nbsp; To be added!</div>
  </div>
  <div class="row">
    <div class="col-lg-12" *ngIf="show">
      <div class="filters_container">
        <div class="search-input">
          <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
          <mat-icon class="search" (click)="initNewSearch()">search</mat-icon>
        </div>
        <div class="right_container">
          <button class="filter-btn" (click)="FilterModel()">
            FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
          </button>
          <button class="btn-add btn-long" (click)="openIdentifierModal()">ADD IDENTIFIER TYPE</button>
        </div>
      </div>
      <app-filter-tags
        *ngIf="searchFilters"
        isPopup="true"
        [(filterButton)]="filterButton"
        [(searchFilters)]="searchFilters"
        [(appliedFilters)]="appliedFilters"
      ></app-filter-tags>
      <div class="row">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th style="vertical-align: inherit">Role Name</th>
              <th style="vertical-align: inherit">Identifier types</th>
              <th style="vertical-align: inherit">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let i of identifiers">
              <td>{{ i.roleScope }}</td>
              <td>{{ i.cfgCode }}</td>
              <td>
                <div class="actions float-left inline-table">
                  <a class="btn btn-outline-primary btn-sm edit action" (click)="openIdentifierModal(i)" title="Edit">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                  <button
                    class="btn btn-outline-primary btn-sm delete action"
                    (click)="openDeleteIdentifierModal(i)"
                    title="Delete"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getIdentifierTypes(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
