<div class="mt-3">
  <div class="col-12 d-flex">
    <div class="market_logos">
      <img src="../../../assets/images/Asset 9.png" class="dec_logo" />
    </div>
    <div class="ml-2"><span class="font-weight-bold">Contact:</span>&nbsp; Market contact details</div>
  </div>
</div>
<!-- <div class="row">
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-4" *ngFor="let adr of addresses">
        <div class="widget style1 navy-bg">
          <div class="row">
            <div class="col-sm-3">
              <span class="fa-stack fa-3x">
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="faHome" stackItemSize="2x"></fa-icon>
                </fa-layers>
              </span>
            </div>
            <div class="col-sm-9 text-lg-right">
              <div>
                <span class="word-break-break-all">{{ adr.address1 }} </span>
              </div>
              <div>
                <span class="word-break-break-all">{{ adr.address2 }} </span>
              </div>
              <h3 title="{{ adr.city + ', ' + formatState(adr.state) }}" class="font-bold elipsis">
                {{ adr.city + ", " + formatState(adr.state) }}
              </h3>
              <span *ngIf="adr.isPrimary"><fa-icon [icon]="faStar"></fa-icon> Primary</span
              ><span *ngIf="!adr.isPrimary"> </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span *ngIf="adr.isDefaultComm"><fa-icon [icon]="faStar"></fa-icon> Default</span>
            </div>
            <div class="col-md-9" *ngIf="isProgramManager">
              <div class="form-group float-right">
                <button type="button" class="btn btn-warning btn-sm" (click)="addressModal(adr.addressId)">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </button>
                <button
                  [disabled]="addresses.length === 1"
                  type="button"
                  class="btn btn-danger btn-sm"
                  (click)="deletePartyAddress(adr, adr.isPrimary)"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1" *ngIf="isProgramManager">
    <a class="btn btn-sm btn-primary float-right contact" (click)="addressModal()"
      ><fa-icon [icon]="faHome"></fa-icon> New address</a
    >
  </div>
</div> -->
<div class="col-12 d-flex mt-5">
  <div class="pr-2 pl-2 col-4">
    <div class="card mr-1 p-0">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <span class="mr-2">
            <mat-icon>home</mat-icon>
          </span>
          <span class="font-weight-bold"> Addresses</span>
        </div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <a class="nav-link active pt-2 pb-2 pl-4 pr-4" *ngIf="isProgramManager" (click)="addressModal()">ADD</a>
          </li>
        </ul>
      </div>
      <div class="card-body p-0">
        <div *ngFor="let adr of addresses">
          <div class="d-flex justify-content-between align-items-center mt-3 mr-3 ml-3">
            <div class="d-flex">
              <span class="word-break-break-all">{{ adr.address1 }}&nbsp;</span>
              <span class="word-break-break-all">{{ adr.address2 }} </span>
            </div>
            <div class="d-flex">
              <a class="btn btn-outline-primary btn-sm edit action" (click)="addressModal(adr.addressId)" title="Edit"
                ><fa-icon [icon]="faPencilAlt"></fa-icon
              ></a>
              <button
                class="btn btn-outline-primary btn-sm delete action"
                [disabled]="addresses.length === 1"
                (click)="deletePartyAddress(adr, adr.isPrimary)"
                title="Delete"
              >
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          </div>
          <hr class="shrinked" />
        </div>
      </div>
    </div>
  </div>
  <div class="pr-2 pl-2 col-4">
    <div class="card mr-1 p-0">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <span class="mr-2"><mat-icon> phone</mat-icon></span>
          <span class="font-weight-bold"> Phone number</span>
        </div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <a class="nav-link active pt-2 pb-2 pl-4 pr-4" *ngIf="isProgramManager" (click)="phoneModal()">ADD</a>
          </li>
        </ul>
      </div>
      <div class="card-body p-0">
        <div *ngFor="let ph of phones">
          <div class="d-flex justify-content-between align-items-center mt-3 mr-3 ml-3">
            <div class="d-flex align-items-center">
              <span class="word-break-break-all mr-3">{{ ph.phoneNumber }}&nbsp;</span>
              <span class="word-break-break-all font-weight-bold text-white badgeactive rounded-pill">{{
                ph.status
              }}</span>
            </div>
            <div class="d-flex">
              <a class="btn btn-outline-primary btn-sm edit action" (click)="phoneModal(ph.id)" title="Edit"
                ><fa-icon [icon]="faPencilAlt"></fa-icon
              ></a>
              <button
                class="btn btn-outline-primary btn-sm delete action"
                [disabled]="phones.length === 1"
                (click)="deletePhone(ph, ph.isPrimary)"
                title="Delete"
              >
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          </div>
          <hr class="shrinked" />
        </div>
      </div>
    </div>
  </div>
  <div class="col-4 pr-2 pl-2">
    <div class="card mr-1 p-0">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <span class="mr-2"><mat-icon> email</mat-icon></span>
          <span class="font-weight-bold"> Email address</span>
        </div>
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <a class="nav-link active pt-2 pb-2 pl-4 pr-4" *ngIf="isProgramManager" (click)="emailModal()">ADD</a>
          </li>
        </ul>
      </div>
      <div class="card-body p-0">
        <div *ngFor="let em of emails">
          <div class="d-flex justify-content-between align-items-center mt-3 mr-3 ml-3">
            <div class="">
              <span class="word-break-break-all">{{ em.emailAddress }}</span>
            </div>
            <div class="d-flex">
              <a class="btn btn-outline-primary btn-sm edit action" (click)="emailModal(em.id)" title="Edit"
                ><fa-icon [icon]="faPencilAlt"></fa-icon
              ></a>
              <button
                class="btn btn-outline-primary btn-sm delete action"
                [disabled]="emails.length === 1"
                (click)="deletePartyEmail(em, em.isPrimary)"
                title="Delete"
              >
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          </div>
          <hr class="shrinked" />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <hr class="shrinked" /> -->
<!-- <div class="row">
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-4" *ngFor="let em of emails">
        <div class="widget style1 lazur-bg">
          <div class="row">
            <div class="col-sm-3">
              <span class="fa-stack fa-3x">
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="faEnvilope" stackItemSize="2x"></fa-icon>
                </fa-layers>
              </span>
            </div>
            <div class="col-sm-9 text-lg-right">
              <span *ngIf="em.isPrimary"><fa-icon [icon]="faStar"></fa-icon> Primary</span>
              <h3 title="{{ em.emailAddress }}" class="font-bold elipsis" class="word-break-break-all">
                {{ em.emailAddress }}
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span *ngIf="em.isDefaultCommunication"><fa-icon [icon]="faStar"></fa-icon> Default</span>
            </div>
            <div class="col-md-9" *ngIf="isProgramManager">
              <div class="form-group float-right">
                <button type="button" class="btn btn-warning btn-sm" (click)="emailModal(em.id)">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </button>
                <button
                  [disabled]="emails.length === 1"
                  type="button"
                  class="btn btn-danger btn-sm"
                  (click)="deletePartyEmail(em, em.isPrimary)"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1" *ngIf="isProgramManager">
    <a class="btn btn-sm btn-info float-right contact" (click)="emailModal()"
      ><fa-icon [icon]="faAt"></fa-icon> New email</a
    >
  </div>
</div> -->
<!-- <hr class="shrinked" /> -->
<!-- <div class="row">
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-4" *ngFor="let ph of phones">
        <div class="widget style1 blue-bg">
          <div class="row">
            <div class="col-sm-3">
              <span class="fa-stack fa-3x">
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="faPhone" stackItemSize="2x"></fa-icon>
                </fa-layers>
              </span>
            </div>
            <div class="col-sm-9 text-lg-right">
              <div *ngIf="ph.isPrimary"><fa-icon [icon]="faStar"></fa-icon> Primary</div>
              <span>{{ ph.status }}</span>
              <h3 class="font-bold elipsis" title="{{ ph.phoneNumber }}" class="word-break-break-all">
                {{ ph.phoneNumber }}
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span *ngIf="ph.isDefaultCommunication"> <fa-icon [icon]="faStar"></fa-icon> Default</span>
            </div>
            <div class="col-md-9" *ngIf="isProgramManager">
              <div class="form-group float-right">
                <button type="button" class="btn btn-warning btn-sm" (click)="phoneModal(ph.id)">
                  <fa-icon [icon]="faEdit"></fa-icon>
                </button>
                <button
                  [disabled]="phones.length === 1"
                  type="button"
                  class="btn btn-danger btn-sm"
                  (click)="deletePhone(ph, ph.isPrimary)"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1" *ngIf="isProgramManager">
    <a class="btn btn-sm btn-success float-right contact" (click)="phoneModal()"
      ><fa-icon [icon]="faPhone"></fa-icon> New phone</a
    >
  </div>
</div> -->
