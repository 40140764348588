import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  username: string;
  loginBanner = '';

  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private toastr: ToastrService,
    private forgotPasswordService: ForgotPasswordService
  ) {}

  submit() {
    if (!this.username || this.username.length < 1) {
      this.toastr.info('Input field is empty');
      return;
    }

    this.forgotPasswordService.forgotPassword(this.username).subscribe((res) => this.toastr.info('Check your email'));
    this.router.navigate(['login']);
  }

  login() {
    this.router.navigate(['login']);
  }
}
