import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { faTimes, faSave, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/app/data/model';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { UsersService } from '../services/users.service';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { PersonsService } from '../services/persons.service';
import { AppDataService } from '../app-data.service';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
})
export class UserModalComponent implements OnInit {
  @Input() user: User;
  @Input() roles: any[] = [];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  statuses = this.statusHelperService.getStatus('dropdown');
  faCalendar = faCalendar;
  title: string;
  userForm: FormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  filteredSegments: any[] = [];
  genders: any[] = [];
  minDate;

  constructor(
    private statusHelperService: StatusHelperService,
    private responseHelper: ResponseHelperService,
    private usersService: UsersService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private dateHelperService: DateHelperService,
    private personsService: PersonsService,
    private globalData: AppDataService
  ) {}

  ngOnInit(): void {
    this.minDate = {
      year: 1925,
      month: 1,
      day: 1,
    };
    if (this.user) {
      this.title = 'Edit User';
      this.isEdit = true;
      this.userForm = this.formBuilder.group({
        firstName: [this.user.firstName, Validators.required],
        lastName: [this.user.lastName, Validators.required],
        status: [this.user.status, Validators.required],
        username: [this.user.username, Validators.compose([Validators.required, Validators.minLength(3)])],
        email: [this.user.emailsList],
        notes: [this.user.notes],
        dateOfBirth: [this.dateHelperService.stringToNgbDate(this.user.dateOfBirth)],
        gender: [this.user.gender],
      });
    } else {
      this.title = 'New User';
      this.isEdit = false;
      this.userForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        status: ['', Validators.required],
        password: ['', this.passwordValidator],
        repeatPassword: ['', this.passwordValidator],
        segmentsCollection: ['', Validators.required],
        accessRoleCollection: ['', Validators.required],
        username: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
        email: [''],
        notes: [''],
        dateOfBirth: [],
        gender: [],
      });
    }
    this.getPersonSegments();
    this.getAllGenders();
  }

  clearDateInput(name: string): void {
    this.userForm.patchValue({
      dateOfBirth: null,
    });
  }

  getAllGenders(): void {
    this.personsService.getAllGenders().subscribe((data: any) => {
      if (data.success) {
        this.genders = data.entity;
      }
    });
  }

  getPersonSegments(): void {
    if (!this.isEdit) {
      const params = {
        children: true,
      };
      this.personsService.getPersonSegments(this.globalData.userId, params).subscribe((data: any) => {
        if (data.success) {
          this.filteredSegments = data.entity;
        }
      });
    }
  }

  ok(): void {
    let value = this.userForm.value;
    if (!this.isEdit) {
      delete value.accessRoleCollection.id;
      value.accessRoleCollection = [value.accessRoleCollection];
    }
    value.partyEmails = [{ emailAddress: value.email }];
    delete value.email;
    value.dateOfBirth = this.dateHelperService.ngbDateToString(value.dateOfBirth);
    delete value.repeatPassword;
    if (this.isEdit) {
      value = { ...this.user, ...value };
    }
    this.usersService.createOrUpdateUser(this.user && this.user.id, value).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
      },
      (data: any) => {
        this.responseHelper.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  compareFn(user1: any, user2: any): boolean {
    return user1 && user2 && user1.id === user2.id;
  }

  passwordValidator(control: FormControl): { [s: string]: string } | null {
    const value = control.value as string;
    if (!value || value.length === 0) {
      return { passwordValidation: '' };
    }
    const errorMessage =
      'Password is required to be at least 8 characters long and include a capital letter, a lowercase letter and a number';
    if (value.length < 8) {
      return { passwordValidation: errorMessage };
    }
    let hasDigit = false;
    let hasUpperCase = false;
    let hasLowerCase = false;
    for (let i = 0; i < value.length; i++) {
      const characterCode = value.charCodeAt(i);
      if (characterCode >= 48 && characterCode <= 57) {
        hasDigit = true;
      } else if (characterCode >= 65 && characterCode <= 90) {
        hasUpperCase = true;
      } else if (characterCode >= 97 && characterCode <= 122) {
        hasLowerCase = true;
      }
    }
    return hasDigit && hasLowerCase && hasUpperCase ? null : { passwordValidation: errorMessage };
    // return null when is password valid;
  }
}
