<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <!--promotions-->
    <!-- <div class="col-sm-4">
      <div class="ibox">
        <div class="ibox-title">
          <div class="row">
            <div class="col-md-9 float-left">
              <h2 class="break-all">Promotions in {{ sponsorshipTag }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 float-right">
              <button
                type="button"
                class="float-right btn btn-dark-blue btn-sm"
                (click)="promotionModal('program')"
                title="Add Sponsorship"
              >
                <fa-icon [icon]="faUserPlus"></fa-icon>
              </button>
              <button
                class="btn btn-sm btn-dark-blue float-right"
                (click)="campaignModal()"
                [disabled]="!selectedPromSponsor"
                title="Associate program to sponsorship group"
              >
                <fa-icon [icon]="faCalendarPlus"></fa-icon>
              </button>
            </div>
          </div>
          <div class="row" *ngIf="show">
            <div class="col-md-12">
              <ul class="list-none pl-0">
                <li
                  *ngFor="let promo of packagedSponsorshipStructure.promotionSponsors"
                  (click)="selectPromotion(promo, $event)"
                >
                  <app-promo-tree
                    [promotion]="promo"
                    [selectedPromotion]="selectedPromSponsor"
                    (promotionChanged)="promotionChanged($event)"
                    [type]="'packaged-sponsorship'"
                  >
                  </app-promo-tree>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!--/promotions-->

    <!-- <div class="col-xl-12 d-flex ">
      <div class="col-md-3 p-0">
        <div class="ibox">
          <div class="ibox-title">
            <div class="row">
              <div class="col-md-9 float-left">
                <h2 class="break-all">Promotions in {{ sponsorshipTag }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 float-right">
                <button
                  type="button"
                  class="float-right btn btn-dark-blue btn-sm"
                  (click)="promotionModal('program')"
                  title="Add Sponsorship"
                >
                  <fa-icon [icon]="faUserPlus"></fa-icon>
                </button>
                <button
                  class="btn btn-sm btn-dark-blue float-right"
                  (click)="campaignModal()"
                  [disabled]="!selectedPromSponsor"
                  title="Associate program to sponsorship group"
                >
                  <fa-icon [icon]="faCalendarPlus"></fa-icon>
                </button>
              </div>
            </div>
            <div class="row" *ngIf="show">
              <div class="">
                <ul class="list-none pl-0">
                  <li
                    *ngFor="let promo of packagedSponsorshipStructure.promotionSponsors"
                    (click)="selectPromotion(promo, $event)"
                  >
                    <app-promo-tree
                      [promotion]="promo"
                      [selectedPromotion]="selectedPromSponsor"
                      (promotionChanged)="promotionChanged($event)"
                      [type]="'packaged-sponsorship'"
                    >
                    </app-promo-tree>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ibox col-md-9">
        <div class="ibox-content" *ngIf="selectedPromotion && selectedPromSponsor">
          <div class="row">
            <div class="col-lg-12">
              <div class="mb-md-2">
                <h2>
                  Sponsor {{ selectedSponsor.name }} Details for {{ selectedPromotion.code }}
                  <app-status-select
                    [type]="type"
                    [selectedModel]="selectedPromSponsor"
                    [permission]="globalData.isCSRManager && securityService.allowStatusUpdates"
                  ></app-status-select>
                  <a class="btn btn-dark-blue btn-sm float-right" (click)="edit(selectedPromSponsor.id)" title="Edit">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-10">
              <div class="row">
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Promotion Category:</dt>
                      <dd>
                        <span>{{ selectedCategory }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Is Hidden:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.isHidden }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Published Start Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.publishedStartDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Processing Start Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.processingStartDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Score Start Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.scoreStartDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Published End Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.publishedEndDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Processing End Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.processingEndDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Score End Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.scoreEndDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs-container mt-2">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Display</a>
                <ng-template ngbNavContent>
                  <app-display-xml-tab
                    [selectedPromSponsor]="selectedPromSponsor"
                    [sponsorshipTag]="sponsorshipTag"
                  ></app-display-xml-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Configurations</a>
                <ng-template ngbNavContent>
                  <app-configurations-tab
                    [promoSponsorId]="selectedPromSponsor.id"
                    [sponsorName]="selectedSponsor.name"
                    [sponsorId]="selectedSponsor.id"
                  >
                  </app-configurations-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink>Segments</a>
                <ng-template ngbNavContent>
                  <app-segments-tab
                    [personId]="selectedPromSponsor.id"
                    [sponsorId]="selectedPromSponsor.sponsorPartyId"
                    [type]="type"
                    [halfSize]="true"
                  ></app-segments-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink>Programs</a>
                <ng-template ngbNavContent>
                  <app-promotion-sponsor-campaign-tab
                    [selectedPromSponsor]="selectedPromSponsor"
                    [selectedPromotion]="selectedPromotion"
                    type="type"
                  >
                  </app-promotion-sponsor-campaign-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="5">
                <a ngbNavLink>Reminders</a>
                <ng-template ngbNavContent>
                  <app-promotion-sponsor-reminders-tab [selectedPromSponsor]="selectedPromSponsor">
                  </app-promotion-sponsor-reminders-tab>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="d-flex align-items-center justify-content-between"
      style="background-color: white; height: 100%; margin-left: 15px; width: 100%; margin-right: 15px"
    >
      <h1 class="mt-3 mb-3 pl-3 col-9 com-title">
        {{ headername }}
        <!-- Centene / WellCare / Medicaid / John Deer : Goodness Rewards 2021 - Flue test program mont
          need to make this actually work instead of just have dummy data -->
      </h1>
      <span class="font-weight-bold d-flex align-items-center col-2" style="justify-content: space-evenly">
        Status :
        <app-status-select [type]="type" [selectedModel]="selectedPromSponsor" [permission]="false"></app-status-select>
        <a class="btn btn-outline-secondary btn-sm" (click)="edit(selectedPromSponsor.id)" title="Edit">
          <fa-icon [icon]="faPencilAlt"></fa-icon>
        </a>
      </span>
      <!-- //[permission]="globalData.isCSRManager && securityService.allowStatusUpdates" -->
    </div>
    <div class="col-xl-12 d-flex">
      <div class="col-3" style="background-color: white; height: 100%">
        <div class="mb-3">
          <button
            class="add-spo-btn"
            (click)="campaignModal()"
            [disabled]="!selectedPromSponsor"
            title="Associate program to sponsorship group"
          >
            ADD PROGRAM
          </button>
        </div>
        <div class="row" *ngIf="show">
          <ul class="list-none">
            <div>
              <li
                *ngFor="let promo of packagedSponsorshipStructure.promotionSponsors; let idx = index"
                (click)="selectPromotion(promo, 'Click')"
              >
                <app-promo-tree
                  [promotion]="promo"
                  [selectedPromotion]="selectedPromSponsor"
                  (promotionChanged)="promotionChanged($event)"
                  [type]="'packaged-sponsorship'"
                >
                </app-promo-tree>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div class="ibox col-9">
        <div class="ibox-content-new" *ngIf="selectedPromotion && selectedPromSponsor">
          <!-- <div class="row">
            <div class="col-lg-12">
              <div class="mb-md-2">
                <h2>
                  Sponsor {{ selectedSponsor.name }} Details for {{ selectedPromotion.code }}
                  <app-status-select
                    [type]="type"
                    [selectedModel]="selectedPromSponsor"
                    [permission]="globalData.isCSRManager && securityService.allowStatusUpdates"
                  ></app-status-select>
                  <a class="btn btn-dark-blue btn-sm float-right" (click)="edit(selectedPromSponsor.id)" title="Edit">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                </h2>
              </div>
            </div>
          </div> -->
          <!-- <div class="row">
            <div class="col-lg-10">
              <div class="row">
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Promotion Category:</dt>
                      <dd>
                        <span>{{ selectedCategory }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Is Hidden:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.isHidden }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Published Start Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.publishedStartDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Processing Start Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.processingStartDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Score Start Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.scoreStartDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Published End Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.publishedEndDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Processing End Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.processingEndDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="col-lg-4">
                  <dl class="dl-horizontal">
                    <div>
                      <dt class="normal-font-weight">Score End Date:</dt>
                      <dd>
                        <span>{{ selectedPromSponsor.scoreEndDate | date: "yyyy-MM-dd" }}</span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div> -->
          <!-- TODO: put this back with data in it.
          <div
            class="col-12 justify-content-center p-0"
            style="background-color: #e2e2e2; height: 111px; border-radius: 5px"
          ></div>-->
          <!-- <div class="tabs-container mt-2">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Display</a>
                <ng-template ngbNavContent>
                  <app-display-xml-tab
                    [selectedPromSponsor]="selectedPromSponsor"
                    [sponsorshipTag]="sponsorshipTag"
                  ></app-display-xml-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Configurations</a>
                <ng-template ngbNavContent>
                  <app-configurations-tab
                    [promoSponsorId]="selectedPromSponsor.id"
                    [sponsorName]="selectedSponsor.name"
                    [sponsorId]="selectedSponsor.id"
                  >
                  </app-configurations-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink>Segments</a>
                <ng-template ngbNavContent>
                  <app-segments-tab
                    [personId]="selectedPromSponsor.id"
                    [sponsorId]="selectedPromSponsor.sponsorPartyId"
                    [type]="type"
                    [halfSize]="true"
                  ></app-segments-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink>Programs</a>
                <ng-template ngbNavContent>
                  <app-promotion-sponsor-campaign-tab
                    [selectedPromSponsor]="selectedPromSponsor"
                    [selectedPromotion]="selectedPromotion"
                    type="type"
                  >
                  </app-promotion-sponsor-campaign-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="5">
                <a ngbNavLink>Reminders</a>
                <ng-template ngbNavContent>
                  <app-promotion-sponsor-reminders-tab [selectedPromSponsor]="selectedPromSponsor">
                  </app-promotion-sponsor-reminders-tab>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div> -->
          <div class="mt-3">
            <mat-tab-group class="remove-border-bottom" animationDuration="0ms">
              <mat-tab label="DISPLAY">
                <app-display-xml-tab
                  [selectedPromSponsor]="selectedPromSponsor"
                  [sponsorshipTag]="sponsorshipTag"
                ></app-display-xml-tab>
              </mat-tab>
              <mat-tab label="CONFIGURATIONS">
                <app-configurations-tab
                  [promoSponsorId]="selectedPromSponsor.id"
                  [sponsorName]="selectedSponsor.name"
                  [sponsorId]="selectedSponsor.id"
                >
                </app-configurations-tab>
              </mat-tab>
              <mat-tab label="PROGRAMS">
                <app-promotion-sponsor-campaign-tab
                  [selectedPromSponsor]="selectedPromSponsor"
                  [selectedPromotion]="selectedPromotion"
                  type="type"
                >
                </app-promotion-sponsor-campaign-tab>
              </mat-tab>

              <mat-tab label="OUTREACH">
                <app-promotion-sponsor-reminders-tab [selectedPromSponsor]="selectedPromSponsor">
                </app-promotion-sponsor-reminders-tab>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
