import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-json-modal',
  templateUrl: './json-modal.component.html',
  styleUrls: ['./json-modal.component.scss'],
  providers: [JsonPipe],
})
export class JsonModalComponent implements OnInit {
  @Input() title = '';
  @Input() json;
  @Input() jsonChangeCallBack: (json: any) => void;
  rows = 0;
  jsonString = '';
  paseteError = false;
  successPaste = false;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  jsonValid = false;
  showErrorMessage = false;
  pasteSupported = true;
  jsonErrorMessage = '';
  errorPosition: number;
  errorLine: number;

  constructor(private jsonPipe: JsonPipe, private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    navigator.userAgent.indexOf('Firefox') != -1 ? (this.pasteSupported = false) : (this.pasteSupported = true);
    this.jsonString = this.jsonPipe.transform(this.json);
    this.rows = this.jsonString.split('\n').length;
  }

  setJSON(event, pasteEvent = false): void {
    this.showErrorMessage = false;
    try {
      const json = JSON.parse(event);
      this.jsonString = event;
      this.rows = event.split('\n').length;
      this.jsonChangeCallBack(json);
      this.jsonValid = true;
      if (pasteEvent) {
        this.successPaste = true;
        setTimeout(() => {
          this.successPaste = false;
        }, 2000);
      }
    } catch (e) {
      this.jsonErrorMessage = e.message;
      if (pasteEvent) {
        this.paseteError = true;
        setTimeout(() => {
          this.paseteError = false;
        }, 2000);
      } else {
        this.jsonValid = false;
      }
    }
  }

  handleInvalidJson(): void {
    this.errorPosition = Number(this.jsonErrorMessage.split('position ')[1]);
    if (!isNaN(this.errorPosition)) {
      this.errorLine = this.findErrorLine(this.errorPosition);
      this.jsonErrorMessage = this.jsonErrorMessage + ' (line ' + ++this.errorLine + ').';
      this.highlightErrorLine(this.errorLine);
    }
    this.showErrorMessage = true;
  }

  findErrorLine(position: number): any {
    var lineNumber;
    var charCount = 0;
    var lines = this.jsonString.split('\n');
    for (var i = 0; i <= lines.length; i++) {
      try {
        if (charCount + lines[i].length < position) {
          charCount += lines[i].length;
        } else {
          lineNumber = i;
          break;
        }
      } catch (e) {
        this.jsonErrorMessage;
      }
    }
    return lineNumber;
  }

  highlightErrorLine(lineNumber: number): any {
    lineNumber--;
    var lines = this.jsonString.split('\n');
    var startPos = 0,
      endPos = this.jsonString.length;
    for (var i = 0; i < lines.length; i++) {
      if (i == lineNumber) {
        break;
      }
      startPos += lines[i].length + 1;
    }
    var endPos = lines[lineNumber].length + startPos;
    var textarea = document.getElementById('contentJson') as HTMLTextAreaElement;
    textarea.focus();
    textarea.setSelectionRange(startPos, endPos);
  }

  copy(): void {
    navigator.clipboard.writeText(this.jsonString).then(
      () => {},
      (error) => {
        console.log(error);
      }
    );
  }

  paste(): void {
    navigator.clipboard.readText().then(
      (clipText: string) => {
        this.setJSON(clipText, true);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  documentPaste(): void {
    const htmlInput = document.querySelector('#contentJson') as HTMLInputElement;
    htmlInput.select();
    document.execCommand('paste');
    htmlInput.setSelectionRange(0, 0);
  }

  pasetEvent(event): void {
    if (this.pasteSupported) {
      event.preventDefault();
      this.paste();
    }
  }

  ok(): void {
    if (this.jsonValid) {
      this.successEvent.emit(this.jsonString);
    } else {
      this.handleInvalidJson();
    }
  }

  cancel(): void {
    this.activeModal.close();
  }
}
