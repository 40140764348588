import { SponsorsService } from './../services/sponsors.service';
import { PersonsService } from './../services/persons.service';
import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from './../services/helpers/status-helper.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Organization, Segment } from '../data/model';
import { AppDataService } from './../app-data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sponsor-modal',
  templateUrl: './sponsor-modal.component.html',
  styleUrls: ['./sponsor-modal.component.scss'],
})
export class SponsorModalComponent implements OnInit {
  @Input() public sponsor: Organization;
  @Input() public selectedPersonId: number;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  isEdit: boolean;
  selectedSegments = new Array<Segment>();
  filteredSegments = new Array<Segment>();
  faSave = faSave;
  faTimes = faTimes;
  sponsorForm: FormGroup;
  errorMsg: string;

  constructor(
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private personsService: PersonsService,
    private sponsorsService: SponsorsService,
    private globalData: AppDataService,
    private formBuilder: FormBuilder
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.sponsor) {
      this.title = 'Edit Market';
      this.isEdit = true;

      this.sponsorForm = this.formBuilder.group({
        name: [this.sponsor.name, Validators.required],
        username: [this.sponsor.username, Validators.required],
        // url: [this.sponsor.url],
        // workingHours: [this.sponsor.workingHours],
        yearStart: [this.sponsor.yearStart],
        status: [this.sponsor.status, Validators.required],
      });
    } else {
      this.title = 'Market management';
      this.isEdit = false;

      this.sponsorForm = this.formBuilder.group({
        name: ['', Validators.required],
        username: ['', Validators.required],
        // url: [''],
        // workingHours: [''],
        yearStart: [''],
        status: ['', Validators.required],
        segmentsCollection: ['', Validators.required],
      });
      const params = {
        children: true,
      };
      this.personsService.getPersonSegments(this.globalData.userId, params).subscribe((data: any) => {
        if (data.success) {
          this.filteredSegments = data.entity;
        }
      });
    }
  }

  ok(): void {
    const id = this.selectedPersonId ? this.selectedPersonId : null;
    if (id !== null) {
      this.sponsorForm.value['username'] = this.sponsor.username;
    }
    this.sponsorsService.createOrUpdateSponsor(id, this.sponsorForm.value).subscribe(
      () => {
        this.responseHelperService.success('Market successfully updated', true);
        this.successEvent.emit('success');
      },
      (err: any) => {
        this.responseHelperService.error(this, err.error.error);
      }
    );
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }
}
