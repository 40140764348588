<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-title">
          <!-- <div class="float-right" [hidden]="!isProgramManager">
            <a class="btn btn-sm btn-dark-blue" (click)="integrationModal()" title="New Integration">
              <fa-icon [icon]="faPlus"></fa-icon>
            </a>
          </div> -->
          <h2 class="fullname">Integrations</h2>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div class="row" [hidden]="!isProgramManager">
                <div *ngIf="searchFilters">
                  <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
                </div>
                <div class="filters_container">
                  <div class="search-input1">
                    <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
                    <mat-icon class="search">search</mat-icon>
                  </div>
                  <div class="right_container1">
                    <button class="filter-btn" (click)="FilterModel()">
                      FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
                    </button>
                    <button class="btn-add gaponbothside" (click)="integrationModal()">ADD Integration</button>
                  </div>
                </div>
                <app-filter-tags
                  *ngIf="searchFilters"
                  isPopup="true"
                  [(filterButton)]="filterButton"
                  [(searchFilters)]="searchFilters"
                  [(appliedFilters)]="appliedFilters"
                ></app-filter-tags>
              </div>
              <div class="row">
                <table class="table table-pointable table-bordered infinite-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Customer name</th>
                      <th>File type</th>
                      <th>File name pattern</th>
                      <th>Status</th>
                      <th *ngIf="isAdmin">Transform class</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let integ of integrations | filter: searchText"
                      (click)="showIntegration($event, integ)"
                    >
                      <td>
                        <a routerLink="/integrations/{{ integ.id }}/loads/">{{ integ.name }}</a>
                      </td>

                      <td>{{ integ.customerName }}</td>
                      <td>{{ integ.fileType }}</td>
                      <td>{{ integ.fileNamePattern }}</td>
                      <td>{{ integ.status }}</td>
                      <td *ngIf="isAdmin">
                        <input
                          class="upload-input"
                          id="upload-input-{{ integ.id }}"
                          type="file"
                          name="file"
                          (change)="uploadTransformer($event.target)"
                          [attr.id-el]="integ.id"
                        />
                        <label
                          class="btn btn-outline-primary btn-sm delete action"
                          for="upload-input-{{ integ.id }}"
                          title="Upload Class file"
                        >
                          <fa-icon [icon]="faUpload"></fa-icon>
                        </label>
                        &nbsp;{{ integ.recordTransformerName }}
                      </td>
                      <td>
                        <div
                          style="
                            width: auto;
                            height: auto;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            gap: 10px;
                          "
                        >
                          <a
                            *ngIf="integ.acceptUploads == true"
                            class="btn btn-outline-primary btn-sm delete action"
                            title="Upload"
                            (click)="uploadModal(integ.id)"
                          >
                            <fa-icon [icon]="faUpload" class="mr-1"></fa-icon>Upload
                          </a>
                          <a
                            class="btn btn-outline-primary btn-sm delete action"
                            title="View Messages"
                            *ngIf="securityService.allowAdvancedIntegrationFeatures"
                            routerLink="/integrations/{{ integ.id }}/messages"
                          >
                            <fa-icon [icon]="faEnvelope"></fa-icon>
                          </a>
                          <a
                            class="btn btn-outline-primary btn-sm delete action"
                            (click)="integrationModal(integ.id)"
                            title="Edit Integration"
                            *ngIf="securityService.allowAdvancedIntegrationFeatures"
                          >
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                          </a>
                          <a
                            class="btn btn-outline-primary btn-sm delete action"
                            routerLink="/integrations/{{ integ.id }}/manifest"
                            title="Edit Manifest"
                            *ngIf="securityService.allowAdvancedIntegrationFeatures"
                          >
                            <fa-icon [icon]="faExchangeAlt"></fa-icon>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr app-is-there-more [numColumns]="numColumns" [newLengthEvent]="newLengthEvent"></tr>
                  </tbody>
                </table>
              </div>
              <app-add-more-data-to-list (onScroll)="getIntegrations(true)" [lock]="lock" [isLoading]="isLoading">
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
