import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from './../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class ChannelConfigurationService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getAllChannelConfigurationForDeliveryProtocol(id: number, params: any): any {
    return this.http.get<any>(
      `${this.globalData.baseUrl}chirp/channel-configuration/delivery-protocol/${id}`,
      this.setParams(params)
    );
  }

  createOrUpdateChannelConfiguration(channelConfiguration: any, changeValue?: boolean): any {
    if (channelConfiguration.id) {
      return this.http.put(
        `${this.globalData.baseUrl}chirp/channel-configuration`,
        channelConfiguration,
        this.setParams({ updateValue: changeValue })
      );
    }
    return this.http.post(`${this.globalData.baseUrl}chirp/channel-configuration`, channelConfiguration);
  }

  deleteChannelConfiguration(id: number): any {
    return this.http.delete(`${this.globalData.baseUrl}chirp/channel-configuration/${id}`);
  }
}
