import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Campaign } from '../../data/model';
import { CampaignDetailsComponent } from '../../modals/campaign-details-component/campaign-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-info-panel',
  templateUrl: './campaign-info-panel.component.html',
  styleUrls: ['./campaign-info-panel.component.scss'],
})
export class CampaignInfoPanelComponent implements OnInit {
  @Input() campaigns: Campaign[];
  showAllCampaigns: boolean;
  faInfoCircle = faInfoCircle;

  constructor(private dialog: MatDialog) {
    this.showAllCampaigns = false;
  }

  ngOnInit(): void {
    console.log('Hello from CampaignInfoPanel');
  }

  filterCampaigns(camp: Campaign): boolean {
    return camp.status === 'ACTIVE' || this.showAllCampaigns === true;
  }

  showCampaignDetails(camp: any): void {
    const instance = this.dialog.open(CampaignDetailsComponent, { width: '60vw' });
    instance.componentInstance.campaign = camp;
  }
}
