<div [ngClass]="filter.inputClass" class="search-filter-fields" *ngIf="section.open">
  <input
    type="search"
    class="form-control font-relative-width"
    placeholder="{{ filter.placeholder }}"
    [(ngModel)]="filter.value"
    (ngModelChange)="inputChangeEmitter.emit(filter)"
    title="{{ filter.placeholder }} should only contain numbers"
    appNumbersOnly
    [model]="filter"
  />
</div>
