<form [formGroup]="eventSourceForm" novalidate class="integ-model">
  <div class="integ-report-modal">
    <div>
      <span class="title">{{ title }}</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-3 mt-3">
      <span class="integ-title">Add event source</span>
    </div>
    <div class="">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label>Code <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>
        <input type="text" class="form-control" formControlName="code" />
        <!-- <div class="mt-2" [hidden]="eventSourceForm.controls.code.valid">
          <small class="text-danger">Input a Code</small>
        </div> -->
      </div>
      <div class="form-group">
        <label>Description</label>
        <input type="text" class="form-control" formControlName="description" />
      </div>
      <div class="pop-btn-container">
        <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
        <button class="btn btn-save" type="submit" [disabled]="!eventSourceForm.valid" (click)="ok()" title="Save">
          ADD
        </button>
      </div>
    </div>
  </div>
</form>
