import { OptOutPreference } from './../../data/class';
import { CommunicationsService } from 'src/app/services/communications.service';
import { debounce } from 'rxjs/operators';
import { Subject, interval } from 'rxjs';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';

@Component({
  selector: 'app-opt-out-preferences',
  templateUrl: './opt-out-preferences.component.html',
  styleUrls: ['./opt-out-preferences.component.scss'],
})
export class OptOutPreferencesComponent implements OnInit {
  @ViewChildren('form') forms: QueryList<any>;
  @Input() optOutPreferences: any;
  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  conditions: any[];
  endpointTypes: any[];
  faPlus = faPlus;
  faTimes = faTimes;

  constructor(private communicationsServie: CommunicationsService) {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.conditions = [
      { value: true, text: 'OPT IN' },
      { value: false, text: 'OPT OUT' },
    ];

    this.getAllEndpointTypes();
  }

  getAllEndpointTypes(): void {
    this.communicationsServie.getAllEndpointTypes(false).subscribe((data: any) => {
      this.endpointTypes = data.entity;
    });
  }

  initPreferenceObject(): void {
    const tmpObj = new OptOutPreference();

    if (this.optOutPreferences) {
      this.optOutPreferences.push(tmpObj);
    } else {
      this.optOutPreferences = [];
      this.optOutPreferences.push(tmpObj);
    }
  }

  updatePreferences(): void {
    this.debouncer.next(this.optOutPreferences);
    let valid = true;
    for (const form of this.forms) {
      valid = valid && form.valid;
    }
    this.isValidChange.emit(valid);
  }

  removePreference(preference: any): void {
    this.optOutPreferences.splice(this.optOutPreferences.indexOf(preference), 1);
    this.updatePreferences();
  }
}
