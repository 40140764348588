<div class="open-ticket" #toggleForm ngDraggable>
  <div class="ticket-header">
    <div class="row">
      <div class="col-sm-12">
        <span class="float-right button-group">
          <button
            class="btn btn-xs btn-outline btn-warning"
            (click)="createJiraTicket(ticket)"
            title="{{ escalateTooltip }}"
            *ngIf="isCSRManager"
            [disabled]="ticket.escalated"
          >
            <fa-icon [icon]="faFlag"></fa-icon>
          </button>
          <a
            class="btn btn-xs btn-outline btn-warning"
            (click)="pauseInteraction(ticket)"
            title="Pause Interaction"
            [hidden]="!(ticket.status === 'ACTIVE')"
          >
            <fa-icon [icon]="faPause"></fa-icon>
          </a>
          <a
            class="btn btn-xs btn-outline btn-warning"
            (click)="resumeInteraction(ticket)"
            title="Resume Interaction"
            [hidden]="!(ticket.status === 'INACTIVE' || ticket.status === 'ESCALATED')"
          >
            <fa-icon [icon]="faPlay"></fa-icon>
          </a>
          <a
            class="btn btn-xs btn-outline btn-primary"
            (click)="completeInteraction(ticket)"
            title="Resolve Interaction"
          >
            <fa-icon [icon]="faCheck"></fa-icon>
          </a>
          <a class="collapse-link btn btn-xs btn-outline btn-info" (click)="pauseTicket(ticket)" title="Minimize">
            <fa-icon [icon]="faMinus"></fa-icon>
          </a>
        </span>
        <div class="subject">
          {{ ticket.subject }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 party-name">
        <a routerLink="/members/{{ ticket.memberPartyId }}" title="Visit this member"
          >{{ ticket.memberFirstName }} {{ ticket.memberLastName }} ({{ ticket.memberPartyId }})</a
        >
      </div>
    </div>
  </div>
  <div class="ticket ibox-content" id="comments">
    <div id="commentsLength">
      <div class="feed-activity-list" *ngFor="let entry of ticket.entryList">
        <div class="interactions feed-element">
          <div class="media-body">
            <strong>{{ entry.createdByStr }}</strong>
            <br />
            <div class="well" #logEntry (click)="focusMe()">
              <span>{{ entry.logEntry }}</span>
            </div>
            <div class="text-muted">
              {{ entry.entryTimestamp | date: "medium" }}
              <a
                *ngIf="entry.createdByStr !== 'SYSTEM' && isCSRManager && !cannotEdit"
                (click)="editLog($event)"
                class="btn btn-xs btn-white float-right edit"
              >
                <fa-icon [icon]="faPencilAlt"></fa-icon> Edit
              </a>
              <a (click)="saveLog(entry, $event)" class="btn btn-xs btn-primary float-right save display-none">
                <fa-icon [icon]="faSave"></fa-icon> Save
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ibox-footer">
    <form #f="ngForm" name="form">
      <div class="col-md-12">
        <div class="form-chat">
          <strong *ngIf="!interactionTextOpenTicket">Additional Information:</strong>
          <div class="input-group">
            <textarea
              rows="3"
              type="text"
              [(ngModel)]="interactionTextOpenTicket"
              name="interactionTextOpenTicket"
              class="form-control vertical-expand"
              [disabled]="ticket.status !== 'ACTIVE'"
            ></textarea>
            <span class="input-group-btn">
              <button
                class="btn btn-primary mt-120"
                [disabled]="ticket.status !== 'ACTIVE'"
                (click)="postInteraction(interactionTextOpenTicket, ticket.id)"
              >
                <fa-icon [icon]="faPaperPlane"></fa-icon>
                Post
              </button>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
