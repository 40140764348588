import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, faTimes, faPencilAlt, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FilterService } from 'src/app/services/filter.service';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { FilterModel } from 'src/app/data/model';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from 'src/app/app-data.service';

@Component({
  selector: 'app-filters-tab',
  templateUrl: './filters-tab.component.html',
  styleUrls: ['./filters-tab.component.scss'],
})
export class FiltersTabComponent implements OnInit {
  lockedTables: boolean;
  isLoading: boolean;
  lock: boolean;
  filteredStatuses: any[] = [];
  filter = new Filter();
  limit = 20;
  statuses: Choice[];
  filters: FilterModel[] = [];
  lengthToCompare: number;
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  faUpload = faUpload;
  searchFilters: SearchFilters;
  f: any = {};
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private filterService: FilterService,
    private modalHelper: NgbModal,
    private responseHelper: ResponseHelperService,
    private toastr: ToastrService,
    private http: HttpClient,
    private globalData: AppDataService
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'filter-search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Filter',
          if: true,
          callback: () => {
            this.filterModal();
          },
          title: 'New Filter',
        },
      ],
    };
    this.initFilter();
    this.getStatuses();
    this.show = true;
  }

  initNewSearch(): void {
    this.initFilter();
    this.getFilters(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    this.filter.jobSelector = 'F';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  filterModal(id?: number): void {
    if (id) {
      this.filterService.getFilter(id).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(filter?: FilterModel): void {
    const instance = this.modalHelper.open(FilterModalComponent);
    instance.componentInstance.filter = filter;
    instance.componentInstance.jobSelector = this.filter.jobSelector;
    instance.componentInstance.successEvent.subscribe((data) => {
      if (data.success) {
        this.responseHelper.success(
          `Filter successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`,
          true
        );
        this.initFilter();
        this.getFilters(false);
      }
    });
  }

  getFilters(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.filterService.getFilters(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.filters = this.filters.concat(data.entity);
        } else {
          this.filters = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.isLoading = false;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  deleteFilter(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete filter with ID: ' + id;
    instance.componentInstance.header = 'Delete filter?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.filterService.deleteFilter(id).subscribe(
        () => {
          this.responseHelper.success('Filter successfully removed', true);
          this.filterService.getFilter(id).subscribe((data: any) => {
            if (data.success) {
              this.filters[this.filters.findIndex((e) => e.id === id)] = data.entity;
            }
          });
        },
        (data: any) => {
          this.responseHelper.error(this, data.error.error, true);
        }
      );
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getFilters(false);
  }

  uploadFile(element: any, event: any): void {
    const fd = new FormData();
    const id = event.attributes.getNamedItem('id').value;
    fd.append('file', element.target.files[0]);
    this.http.post<any>(this.globalData.baseUrl + 'jobs/' + id + '/job-jar/', fd).subscribe(
      (data) => {
        this.responseHelper.success('Successfully uploaded file', false);
      },
      (data) => {
        this.responseHelper.error(this, data);
      }
    );
  }
}
