import { Component, Input } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { PromotionService } from '../../services/promotion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionBehaviorCodeModalComponent } from '../../modals/promotion-behavior-code-modal/promotion-behavior-code-modal.component';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-button-show-promo-details',
  templateUrl: './button-show-promo-details.component.html',
  styleUrls: ['./button-show-promo-details.component.scss'],
})
export class ButtonShowPromoDetailsComponent {
  @Input() personId: number;
  @Input() promotion;
  private activityDetails: {
    regex: string;
    endDate: any;
    id: any;
    bCodes: any[];
    selectedPersonId: any;
    hideLink: boolean;
    startDate: any;
  };
  private promotionService: PromotionService;
  hideButton;

  constructor(promotionService: PromotionService, private modalHelper: NgbModal) {
    this.modalHelper = modalHelper;
    this.promotionService = promotionService;
  }
  faInfoCircle = faInfoCircle;

  @HostListener('click') onClick() {
    this.showDetail();
  }

  showDetail(): void {
    var id;
    if (this.promotion.configCount > 0) {
      id = this.promotion.promoSponsorId;
    } else if (this.promotion.childPromoSponsorId > 0 && this.promotion.childConfigCount > 0) {
      id = this.promotion.childPromoSponsorId;
    }

    this.activityDetails = {
      id: id,
      startDate: this.promotion.promoStartDate,
      endDate: this.promotion.promoEndDate,
      selectedPersonId: this.personId,
      bCodes: [],
      regex: '',
      hideLink: false,
    };

    var tpl = 'tabset/tpl/modal/behavior-code-modal.tpl.html';

    if (id) {
      this.promotionService.getBehaviorCodes(id).subscribe((bCodes) => {
        if (bCodes.success) {
          this.activityDetails.bCodes = bCodes.entity;
          this.activityDetails.regex = this.activityDetails.bCodes.find(
            (bCode) => bCode.behaviorCode === 'regex'
          ).behaviorName;
          this.hideButton = false;
          if (this.activityDetails.regex.indexOf('.*') > 0) {
            this.activityDetails.bCodes = [
              {
                behaviorCode: 'Multiple Behavior Codes',
                behaviorName: 'Multiple Behavior Codes',
              },
            ];
            this.hideButton = true;
          }
          if (this.activityDetails.regex.indexOf('DOES_NOT_SCORE') > 0) {
            this.activityDetails.bCodes = [
              {
                behaviorCode: 'Non scoring promotion',
                behaviorName: 'Non scoring promotion',
              },
            ];
            this.activityDetails.hideLink = true;
          } else if (this.activityDetails.regex === '()') {
            this.activityDetails.bCodes = [
              {
                behaviorCode: 'No Behavior Code Configuration',
                behaviorName: 'No Behavior Code Configuration',
              },
            ];
            this.activityDetails.hideLink = true;
          } else {
            this.activityDetails.bCodes = this.activityDetails.bCodes.filter((bCode) => bCode.behaviorCode !== 'regex');
          }
          this.openModal(this.activityDetails, this.personId, this.hideButton);
        }
      });
    }
  }

  openModal(activityDetails: any, personId: number, hideButton: boolean): void {
    const instance = this.modalHelper.open(PromotionBehaviorCodeModalComponent);

    instance.componentInstance.title = 'More Details';
    instance.componentInstance.activityDetails = activityDetails;
    instance.componentInstance.personId = personId;
    instance.componentInstance.hideButton = hideButton;

    instance.componentInstance.successEvent.subscribe((data: any) => {
      instance.close();
    });
  }
}
