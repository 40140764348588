import { OrganizationsService } from '../services/organizations.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MountReactParams } from '../services/react.service';
import Organizations, { OrganizationsProps } from './Organizations';
import { NavigationService } from '../react/services/navigation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EntityPartyRoleName } from './services/url-builder.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  template: ` <app-mount-react [mountReactParams]="mountReactParams"></app-mount-react>`,
})
export class LinesOfBusinessComponent implements OnInit {
  public mountReactParams: MountReactParams<OrganizationsProps> = {
    mountId: 'line-of-business-view-mount',
    component: Organizations,
    props: {
      toastr: this.toastr,
      getHierarchicalOrganization: this.organizationsService.getHierarchicalOrganization.bind(
        this.organizationsService
      ),
      getAllHierarchicalOrganizationsForParent: this.organizationsService.getAllHierarchicalOrganizationsForParent.bind(
        this.organizationsService
      ),
      createOrUpdateOrganization: this.organizationsService.createOrUpdateOrganization.bind(this.organizationsService),
      getOrganizationStatistics: this.organizationsService.getOrganizationStatistics.bind(this.organizationsService),
      getOrgByUsername: this.organizationsService.getOrgByUsername.bind(this.organizationsService),
      childEntityPartyRoleName: EntityPartyRoleName.MARKET,
      route: this.route,
      navigate: this.router.navigate.bind(this.router),
    },
  };

  constructor(
    private title: Title,
    private navigationService: NavigationService,
    private organizationsService: OrganizationsService,
    public router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Lines of Business');
  }
}
