import React from "react";
import { Instruction, InstructionVariant } from "../models/model";
import TableHead from "@mui/material/TableHead/TableHead";
import TableContainer from "@mui/material/TableContainer/TableContainer";

import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import TableCell from "@mui/material/TableCell/TableCell";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import Box from "@mui/material/Box/Box";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";

import Paper from "@iridium/iridium-ui/Paper";
import Collapse from "@iridium/iridium-ui/Table/Collapse";
import IconButton from "@iridium/iridium-ui/Button/IconButton";

function ExpandedRow(props: {
  selectedVariant: InstructionVariant;
  expandedVariant: string | false;
  setExpandedVariant: (expandedVariant?: string | false) => void;
  setSelectedVariant: (selectedVariant?: InstructionVariant | false) => void;
  index: number;
}) {
  function setVariantState() {
    props.setExpandedVariant(props.expandedVariant === "row" + props.index ? false : "row" + props.index);
    props.setSelectedVariant(props.selectedVariant);
  }
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" onClick={() => setVariantState()}>
            {props.expandedVariant === "row" + props.index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ borderLeftWidth: 0 }}>Variant {props.index}</TableCell>
        <TableCell align="right" sx={{ borderLeftWidth: 0 }} style={{ display: "flex" }}>
          <IconButton>
            <CreateIcon />
          </IconButton>
          <IconButton>
            <DeleteIcon />
          </IconButton>
          <IconButton>
            <FileCopyIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#e1f1ff" }} colSpan={6}>
          <Collapse in={props.expandedVariant === "row" + props.index ? true : false} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <p>Language: {props.selectedVariant.language}</p> <p>Sexes: {props.selectedVariant.sexes.join(", ")}</p>
              <p>Disposition: {props.selectedVariant.dispositions}</p> <p>Theme: {props.selectedVariant.theme}</p>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function NodeVariantTable(props: {
  selectedInstruction: Instruction;
  expandedVariant: string | false;
  setExpandedVariant: (expandedVariant?: string) => void;
  setSelectedVariant: (selectedVariant?: InstructionVariant | false) => void;
}) {
  const { selectedInstruction, expandedVariant, setSelectedVariant, setExpandedVariant } = props;
  return (
    <TableContainer component={Paper} sx={{ overflow: "hidden" }}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "5%" }} />
            <TableCell>Variant</TableCell>
            <TableCell style={{ width: "30%" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedInstruction.instructionVariants.map((selectedVariantData, index) => (
            <ExpandedRow
              key={index.toString() + "-ExpandedRow"}
              selectedVariant={selectedVariantData}
              expandedVariant={expandedVariant}
              index={index}
              setSelectedVariant={setSelectedVariant}
              setExpandedVariant={setExpandedVariant}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
