import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-buttons',
  templateUrl: './modal-buttons.component.html',
  styleUrls: ['./modal-buttons.component.scss'],
})
export class ModalButtonsComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() ok = new EventEmitter();
  @Input() submitDisabled = false;
  @Input() submitText = 'Save';
  @Input() cancelText = 'Cancel';
  @Input() showSubmitButton = true;

  constructor() {}

  ngOnInit(): void {
    console.log('Not implemented yet');
  }
}
