import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Filter, SearchFilters } from 'src/app/data/class';
import { AppDataService } from 'src/app/app-data.service';
import { PersonsService } from 'src/app/services/persons.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import { Attribute } from 'src/app/data/model';
import { CommunicationAttributeModalComponent } from 'src/app/communication-tabs/communication-attribute-modal/communication-attribute-modal.component';
import {
  faChevronDown,
  faChevronRight,
  faInfo,
  faPencilAlt,
  faStopwatch,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SecurityService } from 'src/app/services/security/security.service';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { AttributesDetailsModalComponent } from '../attributes-details-modal/attributes-details-modal.component';
import { SearchFilterComponent } from '../search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';

@Component({
  selector: 'app-party-attributes-tab',
  templateUrl: './party-attributes-tab.component.html',
  styleUrls: ['./party-attributes-tab.component.scss'],
  providers: [DatePipe],
})
export class PartyAttributesTabComponent implements OnInit, OnDestroy {
  @Input() resource: any;
  @Input() personId: number;
  @Input() hideInfoButton: boolean;
  @Input() hideExpireButton: boolean;
  searchFilters: SearchFilters;
  selectedAttr: Attribute;
  attrs: Attribute[];
  attribProv: Attribute[];
  filter = new Filter();
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faInfo = faInfo;
  faPencilAlt = faPencilAlt;
  faStopwatch = faStopwatch;
  faTrash = faTrash;
  expandedLookup = [];
  disableActions: string;
  allowedToEditAttribs: boolean;
  allowedToAddAttribs: boolean;
  currentDate: any;
  helpers: any;
  filterButton: boolean = false;
  filterdata: SearchFilters;
  appliedFilters: Filter[];
  searchText: any;
  attribute: false;

  constructor(
    public globalData: AppDataService,
    public appDataService: AppDataService,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private personsService: PersonsService,
    private promotionService: PromotionService,
    private securityService: SecurityService,
    private toastr: ToastrService,
    private userPreferencesService: UserPreferencesService,
    private datePipe: DatePipe,
    private applyfilterService: ApplyFilterService
  ) {}

  ngOnDestroy(): void {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.allowedToEditAttribs = this.securityService.allowedToEditAttribs;
    this.allowedToAddAttribs = this.securityService.allowedToAddAttribs;
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.attrs = [];
    this.searchFilters = {
      formName: 'generic',
      buttonsWithText: true,
      showSearchFilters: true,
      filters: [
        {
          name: 'effectiveDate',
          type: 'date',
          placeholder: 'mm/dd/yyyy',
          label: 'Display attributes as of',
        },
      ],
      searchCallback: () => {
        this.setAttributes();
      },
      addEntityButtons: [
        {
          icon: faPlus,
          title: 'Add Attribute',
          buttonText: 'Add Attribute',
          callback: () => {
            this.prepareAttributeModal();
          },
          if: this.appDataService.isProgramManager || this.allowedToAddAttribs,
        },
      ],
    };
    this.setAttributes();
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        console.log('Filter Test data', existing[filter.name], filter.value);
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  setAttributes(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    if (this.filter) {
      this.filter = this.getFilters(this.filter);
      this.disableActions = this.filter.effectiveDate;
    }
    this.personsService.getPartyAttributeHistory(this.personId, this.filter).subscribe((attrs: any) => {
      if (attrs.success) {
        this.attrs = attrs.entity;
        this.setDates(this.attrs);
        this.attrs = this.attrs.sort(
          (a: any, b: any) => new Date(b.effectiveFrom).getTime() - new Date(a.effectiveFrom).getTime()
        );
      }
    });
  }

  prepareAttributeModal(attribute?: Attribute): void {
    this.selectedAttr = null;
    this.partyAttrModal(attribute);
  }

  partyAttrModal(attr: Attribute): void {
    this.selectedAttr = attr;
    this.openAttributeModal();
  }
  checkArrayOrNot(array): Boolean {
    return Array.isArray(array);
  }
  prepareLabel(placeholder: string): string {
    if (!placeholder || placeholder === '') {
      return '';
    }
    return placeholder.replace(/\.\.\.$/g, '').replace(/:$/g, '') + ': ';
  }
  openfilterModel(): void {
    const filterInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    filterInstance.componentInstance.searchFilters = this.searchFilters;
    filterInstance.componentInstance.isPopup = true;
    filterInstance.componentInstance.heading = 'Attributes';
    filterInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  openAttributeModal(): void {
    const instance = this.modalHelper.open(CommunicationAttributeModalComponent, { windowClass: 'myCustomModalClass' });
    instance.componentInstance.attr = this.selectedAttr;
    instance.componentInstance.personId = this.personId;
    instance.componentInstance.resourceType = 'party';
    instance.componentInstance.successEvent.subscribe(() => {
      this.setAttributes();
      instance.close();
    });
  }

  showDetails(data: any): void {
    const name = encodeURIComponent(data.attrName);
    this.personsService.getAttributeProvenance(this.personId, name).subscribe((attribProvs: any) => {
      if (attribProvs.success) {
        this.attribProv = attribProvs.entity;
        this.setDates(this.attribProv);
        const instance = this.modalHelper.open(AttributesDetailsModalComponent, { size: 'xl' });
        instance.componentInstance.attributes = this.attribProv;
        instance.componentInstance.personId = this.personId;
        instance.componentInstance.successEvent.subscribe(() => {
          this.setAttributes();
        });
      }
    });
  }

  getStatus(attribute: Attribute): any {
    if (attribute.effectiveTo) {
      const dateToCompare = this.filter && this.filter.effectiveDate ? new Date(this.filter.effectiveDate) : new Date();
      const effectiveTo = new Date(attribute.effectiveTo);
      dateToCompare.setHours(0, 0, 0, 0);
      effectiveTo.setHours(0, 0, 0, 0);
      if (dateToCompare >= effectiveTo) {
        return 'EXPIRED';
      } else {
        return 'ACTIVE';
      }
    } else {
      return 'ACTIVE';
    }
  }

  expireAttribute(attr: Attribute): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Expire attribute with name: ' + attr.attrName + '?';
    instance.componentInstance.header = 'Attribute management';
    instance.componentInstance.nametag = attr.attrName;
    instance.componentInstance.attribute = !this.attribute;
    instance.componentInstance.successEvent.subscribe(() => {
      const date = new Date();
      attr.effectiveTo = date.toUTCString();
      attr.effectiveFrom = date.toUTCString();
      attr.commandType = 'CREATE';
      this.personsService.createOrUpdateAttribute(attr.attrValueId, attr).subscribe(
        () => {
          this.responseHelperService.success('Attribute successfully expired', true);
          this.setAttributes();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
    });
  }

  formatDate(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + (d.getDate() - 1);
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  getEffectiveToDisplay(attr: Attribute): string {
    const effectiveToDateStr = new Date(attr.effectiveTo);
    if (effectiveToDateStr > new Date('2099-12-31')) {
      return '';
    } else {
      return attr.effectiveTo;
    }
  }

  getEffectiveFromDisplay(attr: Attribute): string {
    const effectiveFromDateStr = new Date(attr.effectiveFrom);
    if (effectiveFromDateStr > new Date('2099-12-31')) {
      return '';
    } else {
      return attr.effectiveFrom;
    }
  }
  initNewSearch(): void {
    this.applyFilter();
  }

  applyFilter(): void {
    // eslint-disable-next-line max-len
    // hide the actions if they are looking at the past to avoid all the confusion with users trying to edit/delete attributes that no longer exist.
    this.disableActions = this.filter.effectiveDate;
    this.setAttributes();
  }

  setDates(attrs: Attribute[]): void {
    attrs.forEach((attr: Attribute) => {
      attr.effectiveFrom = this.datePipe.transform(new Date(attr.effectiveFrom), 'MM/dd/yyyy h:mm a', '+0000');
      attr.effectiveTo = this.datePipe.transform(new Date(attr.effectiveTo), 'MM/dd/yyyy h:mm a', '+0000');
    });
  }
}
