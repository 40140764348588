import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  faTimes,
  faSave,
  faCalendar,
  faPlus,
  faTrash,
  faPlusSquare,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { PersonsService } from '../services/persons.service';
import { MembersService } from '../services/members.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { ActivityInstrument, InstrumentType, ProspectiveMember } from '../data/model';
import { InstrumentTypeService } from '../services/instrument-type.service';
import {
  getDisplayMask,
  shouldDropSpecialCharacters,
  validateInstrumentNumber,
} from '../member/member-tabs/instrument-modal/instrument-modal.util';
import { getHelperTextForControl } from '../services/helper-text.service';
import { Gender } from 'src/app/data/model';
import { Router } from '@angular/router';
import { AddressService } from '../services/address.service';
import { AppDataService } from '../app-data.service';
import { CommunicationsService } from '../services/communications.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectOrganizationComponent } from '../select-organization/select-organization.component';

type ActivityInstrumentControls = {
  instrumentType: AbstractControl;
  instrumentNumber: AbstractControl;
  id: number;
};

@Component({
  selector: 'app-prospective-modal',
  templateUrl: './prospective-modal.component.html',
  styleUrls: ['./prospective-modal.component.scss'],
})
export class ProspectiveModalComponent implements OnInit {
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  member: ProspectiveMember = new ProspectiveMember();
  @Input() personId: number;
  selectedSegment: any = [];
  instrumentTypes: InstrumentType[];
  activityInstruments: ActivityInstrument[];
  faCalendar = faCalendar;
  memberForm: FormGroup;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;
  faTrash = faTrash;
  faPlusSquare = faPlusSquare;
  faArrowRight = faArrowRight;
  genders: Gender[] = [];
  segments: any[];
  title = 'Prospective member';
  nextId = 0;
  prospectiveMemberId: string;
  stateCode: any;
  stateList: any;
  showExistingMemberLink: boolean;
  existingMember: any;

  constructor(
    private modalHelper: NgbModal,
    private instrumentTypeService: InstrumentTypeService,
    private formBuilder: FormBuilder,
    private personsService: PersonsService,
    private membersService: MembersService,
    private responseHelperService: ResponseHelperService,
    private dateHelperService: DateHelperService,
    private router: Router,
    private addressService: AddressService,
    public globalData: AppDataService,
    private communicationsService: CommunicationsService
  ) {}

  ngOnInit(): void {
    this.getAllStates();
    this.getAllGenders();
    this.getInstrumentTypes();
    this.memberForm = this.formBuilder.group({
      firstName: [this.member.firstName, Validators.required],
      lastName: [this.member.lastName, Validators.required],
      middleInitial: [this.member.middleInitial],
      dateOfBirth: [this.dateHelperService.stringToNgbDate(this.member.dateOfBirth), Validators.required],
      activityInstruments: this.formBuilder.array([]),
      gender: [this.member.gender, Validators.required],
    });

    if (this.globalData.orgUsername) {
      const ind = this.globalData.orgUsername.split(':', 1).join(':').length;

      this.title = this.title + ' for ' + this.globalData.orgUsername.substr(ind).substr(1);
    }

    this.addInstrument();
  }

  getDisplayMaskByInstrumentType(instrumentType: InstrumentType) {
    return getDisplayMask(instrumentType);
  }

  getShouldDropSpecialCharacters(instrumentType: InstrumentType) {
    return shouldDropSpecialCharacters(instrumentType);
  }

  // Angular 11 does not support strongly typed FormGroups.
  getInstrumentFormGroupControls(index: number): ActivityInstrumentControls {
    const controls = this.getActivityInstrumentsFormArray().controls[index];
    if (!controls) return null;
    return (controls as FormArray).controls as unknown as ActivityInstrumentControls;
  }

  getInstrumentTypeControl(index: number): AbstractControl | null {
    const instrumentFormGroupControls = this.getInstrumentFormGroupControls(index);
    return instrumentFormGroupControls?.instrumentType;
  }

  getInstrumentNumberControl(index: number): AbstractControl | null {
    const instrumentFormGroupControls = this.getInstrumentFormGroupControls(index);
    return instrumentFormGroupControls?.instrumentNumber;
  }

  getInstrumentNumberHelperText(instrumentNumber: AbstractControl): string {
    return getHelperTextForControl({
      defaultHelperText: 'Input identifier',
      control: instrumentNumber,
    });
  }

  getAllGenders(): void {
    this.personsService.getAllGenders().subscribe((data) => {
      if (data.success) {
        this.genders = data.entity;
      }
    });
  }

  getInstrumentTypes(): void {
    this.instrumentTypeService.getInstrumentTypes({ statuses: ['ACTIVE', 'PENDING'] }).subscribe((data: any) => {
      if (data.success) {
        this.instrumentTypes = data.entity.aaData;
      }
    });
  }

  setInstrumentTypeValidation(event: InstrumentType, formArray: FormArray): void {
    if (!event?.id) {
      return;
    }
    const controls = formArray.controls as unknown as ActivityInstrumentControls;
    const instrumentNumberControl: AbstractControl = controls.instrumentType;
    instrumentNumberControl.updateValueAndValidity();
  }

  applyAndAddAnother(): void {
    this.save();
  }

  applyAndNavigate(): void {
    this.save();
  }

  updateSelectedSegments(segment: any): void {
    if (segment.checked) {
      this.selectedSegment = segment;
    } else {
      this.selectedSegment = [];
    }
  }

  getSegment(): Promise<any> {
    if (this.globalData.orgId) {
      return new Promise((resolve, reject) => {
        this.communicationsService.getSegmentByCode(this.globalData.orgUsername).subscribe((data) => {
          if (data.success) {
            resolve(data.entity);
          } else {
            reject(null);
          }
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }
  }

  selectMarket(): void {
    this.router.navigate(['/select-organization']);
  }

  ok(action: string): void {
    this.member = this.memberForm.value;
    this.member.middleInitial = this.member.middleInitial || null;
    this.member.dateOfBirth = this.dateHelperService.ngbDateToString(this.memberForm.value.dateOfBirth);
    this.member.activityInstruments = this.member.activityInstruments.map((instrument: any, index: number) => {
      return {
        ...instrument,
        isPrimary: index === 0,
        instrSelector: 'select',
        status: 'ACTIVE',
      };
    });
    let segmentsCollection: any = [];
    if (this.selectedSegment) {
      delete this.selectedSegment.checked;
    }

    let segment;
    this.getSegment().then((data) => {
      segment = data;
      if (segment) {
        segmentsCollection.push(segment);
      } else {
        segmentsCollection.push(this.selectedSegment);
      }
      this.member.segmentsCollection = segmentsCollection;
      this.membersService.createProspectiveMember(this.member, { stateCode: this.stateCode }).subscribe(
        (data: any) => {
          if (data.success) {
            this.responseHelperService.success('Prospective member created', true);

            this.prospectiveMemberId = data.entity.id;
            if (action === 'navigate') {
              this.successEvent.emit(true);
              this.router.navigate(['/members/' + this.prospectiveMemberId]);
            } else if (action === 'add-another') {
              this.successEvent.emit(true);
              const instance = this.modalHelper.open(ProspectiveModalComponent);
            } else {
              this.successEvent.emit(true);
            }
          }
        },
        (data: any) => {
          if (
            data.error.error ===
            'A member with this instrument already exists. The member will now be reassigned to you.'
          ) {
            this.membersService.reassignProspectiveMember(this.member).subscribe((data: any) => {
              if (data.success) {
                this.responseHelperService.success('Member Reassigned', false);
                this.showExistingMemberLink = true;
                this.existingMember = data.entity;
              }
            });
          } else {
            this.responseHelperService.error(this, data.errorMsg);
          }
        }
      );
    });
  }

  save(): void {}

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  clearDateInput(): void {
    this.memberForm.patchValue({
      dateOfBirth: null,
    });
  }

  compareFn(gender1: Gender, gender2: Gender): boolean {
    return gender1 && gender2 && gender1.id === gender2.id;
  }

  removeInstrument(index: number): void {
    const instrumentsArray = this.getActivityInstrumentsFormArray();
    instrumentsArray.removeAt(index);
  }

  addInstrument(): void {
    this.getActivityInstrumentsFormArray().push(this.newInstrument());
  }

  setInstrumentTypeBasedValidation(instrumentNumber: AbstractControl, instrumentType: AbstractControl) {
    instrumentNumber.setValidators([
      Validators.compose([
        Validators.required,
        (abstractControl) => validateInstrumentNumber(abstractControl, instrumentType),
      ]),
    ]);
    instrumentType.valueChanges.subscribe((_value) => {
      instrumentNumber.updateValueAndValidity();
    });
  }

  newInstrument(): FormGroup {
    const index = this.nextId;
    this.nextId++;
    const instrumentNumber = undefined;
    const instrumentType = undefined;
    const group = this.formBuilder.group({
      instrumentNumber: [instrumentNumber],
      instrumentType: [instrumentType, Validators.required],
      id: [index],
    });
    this.setInstrumentTypeBasedValidation(group.controls.instrumentNumber, group.controls.instrumentType);
    return group;
  }

  getActivityInstrumentsFormArray(): FormArray {
    return this.memberForm.controls.activityInstruments as FormArray;
  }

  getAllStates(): void {
    this.addressService.getAddressData().subscribe((data: any) => {
      this.stateList = data.entity.states;
    });
  }

  selectOrganization(): void {
    this.modalHelper.dismissAll();
    const instance = this.modalHelper.open(SelectOrganizationComponent, { size: 'lg', animation: true });
    instance.componentInstance.isModal = true;
  }
}
