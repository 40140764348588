import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PromotionCategory } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class PromotionCategoryService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getAllPromotionCategories(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-categories', this.setParams(params));
  }

  createOrUpdatePromotionCategory(id: number, promotionCategory: PromotionCategory): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'promotion-categories/' + id, promotionCategory);
    }
    return this.http.post(this.globalData.baseUrl + 'promotion-categories', promotionCategory);
  }

  deletePromotionCategory(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'promotion-categories/' + id);
  }
}
