import { Choice, Filter } from 'src/app/data/class';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownComponent implements OnInit {
  @Input() section: any;
  @Input() filter: any;
  @Input() heading: string;
  @Output() inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private modalHelper: NgbModal) {}

  ngOnInit(): void {
    if (this.filter.value) {
      this.filter.selectedCount = 0;
      this.filter.choices.forEach((choice: Choice) => {
        if (this.filter.value.includes(choice[this.filter.nameProp])) {
          this.filter.selectedCount++;
          choice.state = true;
        }
      });
    }
  }

  toggleSelection(choice: Choice, filter: Filter): void {
    if (filter.singleSelection) {
      filter.choices.forEach((each: Choice) => {
        if (choice !== each) {
          each.state = false;
        }
      });
    }

    const prop = filter.valueProp ? filter.valueProp : filter.nameProp ? filter.nameProp : 'value';
    if (filter.choices && filter.choices.length > 0) {
      const values = [];
      filter.choices.forEach((data) => {
        if (data.state) {
          values.push(data[prop]);
        }
      });
      filter.value = values;
      filter.selectedCount = values.length;
    }

    this.inputChangeEmitter.emit();
  }

  clearOne(data): void {
    this.filter.choices.filter((choice: Choice) => {
      if (choice.value === data) choice.state = false;
    });
  }

  clearAll(): void {
    this.filter.selectedCount = 0;
    this.filter.choices.forEach((choice: Choice) => {
      choice.state = false;
    });
  }
  cancel(): void {
    this.modalHelper.dismissAll();
  }
}
