<form [formGroup]="instrumentTypeForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Code <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="code" />
      <!-- <div class="mt-1" [hidden]="instrumentTypeForm.controls.code.valid">
        <small class="text-danger">Input a Code</small>
      </div> -->
    </div>

    <div class="form-group">
      <label>External Code</label>
      <input type="text" class="form-control" formControlName="extCode" />
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Status <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select formControlName="status" class="form-control">
        <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
      </select>
      <!-- <div class="mt-1" [hidden]="instrumentTypeForm.controls.status.valid">
        <small class="text-danger">Select status</small>
      </div> -->
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea formControlName="description" type="text" class="form-control vertical-expand"></textarea>
    </div>

    <div class="form-group">
      <label>Input Mask Regex</label>
      <input
        type="text"
        placeholder="Ex. [A-NP-Z][0-9][A-NP-Z]"
        class="form-control"
        formControlName="inputMaskRegex"
        pattern="^[A-Z0-9\[\]\-]*$"
      />
      <div class="mt-1" [hidden]="!instrumentTypeForm.controls.inputMaskRegex.valid">
        <small class=""
          >Enter a regex including only digits, uppercase letters, dashes and brackets without spaces.</small
        >
      </div>
      <div class="mt-1" [hidden]="instrumentTypeForm.controls.inputMaskRegex.valid">
        <small class="text-danger"
          >Error: Input Mask Regex can only include digits, uppercase letters, dashes and brackets without
          spaces.</small
        >
      </div>
    </div>

    <div class="form-group">
      <label>Display Format</label>
      <input
        type="text"
        placeholder="Ex. AAA-AA-AAAA"
        class="form-control"
        formControlName="displayFormat"
        pattern="^[A\-]*$"
      />
      <div class="mt-1" [hidden]="!instrumentTypeForm.controls.displayFormat.valid">
        <small class="">Enter groups of uppercase As separated by dashes without spaces.</small>
      </div>
      <div class="mt-1" [hidden]="instrumentTypeForm.controls.displayFormat.valid">
        <small class="text-danger"
          >Error: Display Format can only contain uppercase As separated by dashes without spaces.</small
        >
      </div>
    </div>

    <div class="modal-footer">
      <app-modal-buttons
        (ok)="ok()"
        (cancel)="cancel()"
        [submitDisabled]="!instrumentTypeForm.valid"
      ></app-modal-buttons>
      <!-- <button
        class="btn btn-dark-blue"
        type="submit"
        [disabled]="!instrumentTypeForm.valid"
        (click)="ok()"
        title="Save"
      >
        <fa-icon [icon]="faSave"></fa-icon>
      </button>
      <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button> -->
    </div>
  </div>
</form>
