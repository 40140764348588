<div class="panel-body">
  <div class="col-md-12" *ngIf="show">
    <div class="row" [hidden]="!(personId === null)">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
    </div>
    <div class="row" *ngIf="jiraLink && jiraLink.length > 0 && jiraAccess">
      <div
        class="col-md-12 form-group pull-right"
        style="margin-bottom: 5px"
        *ngIf="jiraLink && jiraLink.length > 0 && jiraAccess"
      >
        <a class="btn btn-success btn-sm float-right" href="{{ jiraLink }}" target="_blank" style="color: #fff">JIRA</a>
      </div>
    </div>
    <div class="row mb-3">
      <table class="table table-pointable table-bordered table-layout-auto" width="100%">
        <thead>
          <tr>
            <th class="width-3"></th>
            <th class="width-5">ID</th>
            <th class="width-10" *ngIf="!personId">Member</th>
            <th [ngClass]="!personId ? 'width-10' : 'width-20'">Note Title</th>
            <th class="width-12">Contact Method</th>
            <th class="width-10">Contact Reason</th>
            <th class="width-10">Status</th>
            <th class="width-10">Created</th>
            <th class="width-10">Last Modified</th>
            <th class="width-10">Created by</th>
            <th class="width-8">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let note of notes">
            <tr (click)="selectNote(note)">
              <td>
                <span class="display-inline-block w-100 align-text-center">
                  <ng-container
                    *ngIf="selectedNoteId === note.id && isDetailsShowed && selectedTicket; else chevronDown"
                  >
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                  </ng-container>
                  <ng-template #chevronDown>
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                  </ng-template>
                </span>
              </td>
              <td title="{{ note.id }}">{{ note.id }}</td>
              <td title="{{ note.memberPartyId }}" *ngIf="!personId">{{ note.memberPartyId }}</td>
              <td title="{{ note.subject }}">
                <span>{{ note.subject }}</span>
              </td>
              <td title="{{ note.contactMethod }}">
                <span>{{ note.contactMethod }}</span>
              </td>
              <td title="{{ note.contactReasonCode }}">
                <span>{{ note.contactReasonCode }}</span>
              </td>
              <td title="{{ note.status }}">{{ note.status }}</td>
              <td title="{{ note.created }}">{{ note.created }}</td>
              <td title="{{ note.lastModified }}">{{ note.lastModified }}</td>
              <td title="{{ note.createdBy }}">{{ note.createdBy }}</td>
              <td>
                <div [hidden]="!(note.status !== 'COMPLETED')">
                  <div [hidden]="!(note.status !== 'DELETED')">
                    <a class="clear-filter" (click)="update(note)" id="update{{ note.id }}">Update</a>
                    <br /><br />
                    <a class="clear-filter" (click)="resolve(note)" id="resolve{{ note.id }}">Resolve</a>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="selectedNoteId === note.id && isDetailsShowed && selectedTicket">
              <td [attr.colspan]="numColumns">
                <table class="table table-pointable member-notes-tab-details-table">
                  <tr class="align-text-left" *ngFor="let ticket of selectedTicket.entryList">
                    <td class="width-55" title="{{ ticket.logEntry }}"><strong>Note: </strong>{{ ticket.logEntry }}</td>
                    <td class="width-20" title="{{ ticket.createdByStr }}">
                      <strong>Created By: </strong> {{ ticket.createdByStr }}
                    </td>
                    <td class="width-20" title="{{ ticket.entryTimestamp | date: 'yyyy-MM-dd HH:mm:ss' }}">
                      <strong>Date: </strong>{{ ticket.entryTimestamp | date: "yyyy-MM-dd HH:mm:ss" }}
                    </td>
                    <td class="width-5" *ngIf="!cannotEdit">
                      <button
                        title="Edit message"
                        class="btn btn-primary btn-sm float-right"
                        (click)="editLog(selectedTicket.id, ticket.id)"
                        *ngIf="ticket.createdByStr.toUpperCase() !== 'SYSTEM'"
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </ng-container>
          <tr app-is-there-more [numColumns]="numColumns" [newLengthEvent]="newLengthEvent"></tr>
        </tbody>
      </table>
    </div>
    <app-add-more-data-to-list (onScroll)="getInteractions(true)" [lock]="lock" [isLoading]="isLoading">
    </app-add-more-data-to-list>
  </div>
</div>
