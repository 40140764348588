import { Component, Input, OnInit } from '@angular/core';
import { faPiggyBank, faKey, faInfoCircle, faCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { MembersService } from '../../services/members.service';
import { ConfigurationService } from '../../services/configuration.service';
import { ToastrService } from 'ngx-toastr';
import { PartyExtensionService } from '../../services/party-extension.service';
import { InstrumentsService } from './../../services/instruments.service';
import { openWindow } from 'src/app/data/window-open-function';

@Component({
  selector: 'app-anthem-eligible-promotions-panel',
  templateUrl: './anthem-eligible-promotions-panel.component.html',
  styleUrls: ['./anthem-eligible-promotions-panel.component.scss'],
})
export class AnthemEligiblePromotionsPanelComponent implements OnInit {
  faPiggyBank = faPiggyBank;
  faKey = faKey;
  faInfoCircle = faInfoCircle;
  faCircle = faCircle;
  faChevronDown = faChevronDown;

  constructor(
    private membersService: MembersService,
    private configurationService: ConfigurationService,
    private toastr: ToastrService,
    private partyExtensionService: PartyExtensionService,
    private instrumentsService: InstrumentsService
  ) {
    this.console = console;
  }
  @Input() promoGroups: any;
  @Input() activeCategory: any;
  @Input() promoCategories: any;
  @Input() personId: number;
  console: any;
  primaryInstrumentCode: string;
  memberUrl: string;

  ngOnInit(): void {
    this.getPrimaryInstrumentForParty(this.personId);
    this.getMemberUrl(this.personId);
  }
  getPrimaryInstrumentForParty(partyId: number): void {
    this.instrumentsService.getPrimaryInstrumentForParty(partyId).subscribe((resp: any) => {
      if (resp.success && resp.entity) {
        this.primaryInstrumentCode = resp.entity.instrumentNumber;
      }
    });
  }

  getMemberUrl(partyId: number): void {
    // first see if it's defined at the party_extension level
    this.partyExtensionService.getNamedExtension(partyId, 'member_extensions').subscribe((data: any) => {
      if (data.success && data.entity && data.entity.member_extensions && data.entity.member_extensions.memberUrl) {
        this.memberUrl = data.entity.member_extensions.memberUrl;
      } else {
        this.configurationService.getConfiguration('MEMBER_UI_LOCATION').subscribe((configData: any) => {
          if (configData.success && configData.entity && configData.entity.length > 0) {
            this.memberUrl = configData.entity[0].cfgValue;
          } else {
            // otherwise this just isn't configured, so fail it.
            this.toastr.error('Member Impersonation is not configured.');
          }
        });
      }
    });
  }

  openSurvey = function (promotion) {
    var token = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sessionId;
    if (this.token !== null && promotion.buttonUrl !== null && this.primaryInstrumentCode !== null) {
      var emulationRequestLog = {
        partyIdEmulated: this.personId,
        authorizedBy: 'SYSTEM',
        notes: promotion.buttonUrl,
        emulationTarget: 'SURVEY',
      };
      this.membersService.logEmulate(this.personId, emulationRequestLog).subscribe((data: any) => {
        if (data.success) {
          var url =
            this.memberUrl +
            '/redirect?token=' +
            token +
            '&path=' +
            promotion.buttonUrl +
            '&on_behalf_of=' +
            this.primaryInstrumentCode +
            '&fullscreen=t';
          openWindow(url, '_blank', 'location=yes,height=1000,width=800,left=100,scrollbars=yes,status=yes');
        }
      });
    }
  };
}
