import { UserPreferencesService } from './../services/user-preferences.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Choice, Filter, SearchFilters } from '../data/class';
import { Organization } from '../data/model';
import { TableHelperService } from '../services/helpers/table-helper.service';
import { SponsorsService } from '../services/sponsors.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sponsor-tab',
  templateUrl: './sponsor-tab.component.html',
  styleUrls: ['./sponsor-tab.component.scss'],
})
export class SponsorTabComponent implements OnInit, OnChanges {
  @Input() public promotionId: number;
  limit: number;
  isLoading = false;
  filter: Filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  selectedSponsor: Organization;
  sponsors = new Array<Organization>();
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  lockedTables: boolean;

  constructor(
    private tableHelperService: TableHelperService,
    private sponsorService: SponsorsService,
    private userPreferences: UserPreferencesService,
    private toastr: ToastrService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.promotionId &&
      !changes.promotionId.firstChange &&
      changes.promotionId.previousValue !== changes.promotionId.currentValue
    ) {
      this.initNewSearch();
    }
  }

  ngOnInit(): void {
    this.setStatuses();
    this.searchFilters = {
      formName: 'promotionTemplateTab_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.statuses,
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          value: this.userPreferences.service.getPreference('communications.defaultFilterValues.status'),
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
  }

  initNewSearch(): void {
    this.initFilter();
    this.getSponsors(false);
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    this.filter.promotionId = this.promotionId;
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  getSponsors(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.filter.promotionId = this.promotionId;
    this.isLoading = true;
    this.sponsorService.getAllSponsors(this.filter).subscribe(
      (data: any) => {
        const aaData = data.entity.aaData || [];
        if (concat) {
          this.sponsors = this.sponsors.concat(aaData);
        } else {
          this.sponsors = aaData;
        }
        this.lengthToCompare = aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = aaData.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }
}
