import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Segment } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { PersonsService } from '../services/persons.service';

@Component({
  selector: 'app-segment-modal',
  templateUrl: './segment-modal.component.html',
  styleUrls: ['./segment-modal.component.scss'],
})
export class SegmentModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public segment: Segment;
  @Input() public parentId: number;
  @Input() public isNew: boolean;
  title: string;
  segmentForm: FormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private responseHelperService: ResponseHelperService,
    private statusHelperService: StatusHelperService,
    private personService: PersonsService,
    private modalHelper: NgbModal
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (!this.isNew) {
      this.title = 'Edit Segment';
      this.segmentForm = this.formBuilder.group({
        name: [this.segment.name, Validators.required],
        code: [this.segment.code, Validators.required],
        status: [this.segment.status, Validators.required],
        description: [this.segment.description],
      });
    } else {
      this.title = 'New Segment';
      this.segmentForm = this.formBuilder.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        status: ['', Validators.required],
        description: [''],
      });
    }
  }

  ok(): void {
    const value = this.segmentForm.value;
    if (this.isNew) {
      value.parentId = this.parentId;
    } else {
      value.id = this.segment.id;
      value.parentId = this.segment.parentId;
    }
    this.personService.createOrUpdateSegment(this.isNew ? null : this.segment.id, value).subscribe(
      (result: any) => {
        this.responseHelperService.success(`Segment successfully ${this.isNew ? 'created' : 'updated'}`, true);
        this.successEvent.emit(result);
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
