import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Filter } from 'src/app/data/class';

@Injectable({
  providedIn: 'root',
})
export class ApplyFilterService {
  //BehaviourSubject Declaration
  private popFilterData = new BehaviorSubject<Filter[]>([]);
  currentFilterData = this.popFilterData.asObservable();

  private filterFunctions = new BehaviorSubject<any>([]);
  filterfun = this.filterFunctions.asObservable();

  private filterButton = new BehaviorSubject<boolean>(false);
  filterButtonClicked = this.filterButton.asObservable();

  //funtions update the BehaviourSubject
  getFilterData(data: Filter[]) {
    this.popFilterData.next(data);
  }

  getFunctions(data: any) {
    this.filterFunctions.next(data);
  }

  getPopButtonStatus(status: boolean) {
    this.filterButton.next(status);
  }
}
