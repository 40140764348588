<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-title">
      <h2>Notes management</h2>
    </div>
    <div class="ibox-content">
      <app-notes-tab [personId]="null"> <app-notes-tab> </app-notes-tab></app-notes-tab>
    </div>
  </div>
</div>
