import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Phone } from 'src/app/data/model';
import { PhoneService } from 'src/app/services/phone.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-phone-modal',
  templateUrl: './phone-modal.component.html',
  styleUrls: ['./phone-modal.component.scss'],
})
export class PhoneModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public phone: Phone = new Phone();
  @Input() public partyId: number;
  @Input() public phoneTypes: any[];
  title: string;
  isEdit: boolean;
  phoneForm: FormGroup;
  errorMsg: string;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private phoneService: PhoneService,
    private responseHelper: ResponseHelperService,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.phone.id) {
      this.title = 'Edit phone number';
      this.isEdit = true;
    } else {
      this.title = 'Add phone number';
      this.isEdit = false;
    }
    this.phoneForm = this.formBuilder.group({
      phoneNumber: [this.phone.phoneNumber, Validators.required],
      phoneTypeId: [this.phone.phoneTypeId, Validators.required],
      status: [this.phone.status, Validators.required],
      isPrimary: [this.phone.isPrimary],
    });
  }

  ok(): void {
    const value = this.phoneForm.value;
    value.partyId = this.partyId;
    this.phoneService.createOrUpdatePhone(this.phone.id, { ...this.phone, ...value }).subscribe(
      () => {
        this.successEvent.emit('success');
      },
      (data: any) => {
        this.responseHelper.error(this, data.error.error);
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
