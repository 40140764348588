import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Filter, SearchFilters } from 'src/app/data/class';
import { AppDataService } from 'src/app/app-data.service';
import { PersonsService } from 'src/app/services/persons.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import { Attribute } from 'src/app/data/model';
import { CommunicationAttributeModalComponent } from 'src/app/communication-tabs/communication-attribute-modal/communication-attribute-modal.component';
import {
  faChevronDown,
  faChevronRight,
  faInfo,
  faPencilAlt,
  faStopwatch,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SecurityService } from 'src/app/services/security/security.service';

@Component({
  selector: 'app-promotion-attributes-tab',
  templateUrl: './promotion-attributes-tab.component.html',
  styleUrls: ['./promotion-attributes-tab.component.scss'],
})
export class PromotionAttributesTabComponent implements OnInit, OnChanges {
  @Input() promotionId: number;
  searchFilters: SearchFilters;
  selectedAttr: Attribute;
  attrs: Attribute[];
  filter = new Filter();
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faInfo = faInfo;
  faPencilAlt = faPencilAlt;
  faStopwatch = faStopwatch;
  faTrash = faTrash;
  expandedLookup = [];
  disableActions: string;
  allowedToEditAttribs: boolean;

  constructor(
    public globalData: AppDataService,
    public appDataService: AppDataService,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private personsService: PersonsService,
    private promotionService: PromotionService,
    private securityService: SecurityService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.allowedToEditAttribs = this.securityService.allowedToEditAttribs;

    this.attrs = [];
    this.searchFilters = {
      formName: 'generic',
      buttonsWithText: false,
      showSearchFilters: false,
      filters: [],
      searchCallback: () => {
        this.setAttributes();
      },
      addEntityButtons: [
        {
          icon: faPlus,
          title: 'Add Attribute',
          buttonText: 'Add Attribute',
          callback: () => {
            this.prepareAttributeModal();
          },
          if: this.appDataService.isCSRManager,
        },
      ],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.promotionId &&
      !changes.promotionId.firstChange &&
      changes.promotionId.previousValue !== changes.promotionId.currentValue
    ) {
      this.setAttributes();
    }
  }

  setAttributes(): void {
    if (this.filter) {
      this.filter = this.searchFilters.getFilters(this.filter);
      this.disableActions = this.filter.effectiveDate;
    }

    this.promotionService.getAttributes(this.promotionId).subscribe((attrs: any) => {
      if (attrs.success) {
        this.attrs = attrs.entity;
        this.attrs = this.attrs.sort((a: any, b: any) => b.id - a.id);
      }
    });
  }

  prepareAttributeModal(attribute?: Attribute): void {
    this.selectedAttr = null;
    this.partyAttrModal(attribute);
  }

  partyAttrModal(attr: Attribute): void {
    this.selectedAttr = attr;
    this.openAttributeModal();
  }

  openAttributeModal(): void {
    const instance = this.modalHelper.open(CommunicationAttributeModalComponent);
    instance.componentInstance.attr = this.selectedAttr;
    instance.componentInstance.promotionId = this.promotionId;
    instance.componentInstance.resourceType = 'promotion';
    instance.componentInstance.successEvent.subscribe(() => {
      this.setAttributes();
      instance.close();
    });
  }

  initNewSearch(): void {
    this.applyFilter();
  }

  applyFilter(): void {
    this.setAttributes();
  }
}
