import { ChipProps } from '@iridium/iridium-ui/Chip/Chip';
import { FilterChipsProps } from './FilterChips';
import { FILTER_CHIP_CLEAR_ALL_TEST_ID } from '../constants';

function createLabel({ value, label }: { value: string; label: string }): string {
  return `${label}: ${value}`;
}

export function getChipPropsFromFilters<T extends Object>({
  filter,
  filterChipPropsLookup,
  onFilterDelete,
  onClearAllFilters,
}: FilterChipsProps<T>): ChipProps[] {
  const filterChipProps = Object.keys(filter).reduce((prevChipProps, key) => {
    const value = filter[key];
    if (!value || (value.length && value.length === 0)) {
      return prevChipProps;
    }

    const { label, onDelete } = filterChipPropsLookup[key];

    let newChipProps: ChipProps | ChipProps[];
    if (Array.isArray(value)) {
      newChipProps = value.map((valueItem) => ({
        label: createLabel({
          // @ts-ignore - data-testid works but is not on the MUI type
          'data-testid': `filter-chip-${valueItem}`,
          value: valueItem,
          label,
        }),
        onDelete: (event) => onFilterDelete(key, valueItem),
      }));
    } else {
      newChipProps = {
        // @ts-ignore - data-testid works but is not on the MUI type
        'data-testid': `filter-chip-${value}`,
        label: createLabel({
          value,
          label,
        }),
        onDelete: (event) => onFilterDelete(key, value),
      };
    }

    return prevChipProps.concat(newChipProps);
  }, [] as ChipProps[]);

  if (filterChipProps.length) {
    filterChipProps.unshift({
      // @ts-ignore - data-testid works but is not on the MUI type
      'data-testid': FILTER_CHIP_CLEAR_ALL_TEST_ID,
      label: 'Clear filters',
      onClick: onClearAllFilters,
    });
  }
  return filterChipProps;
}
