<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="full-height-scroll">
        <div class="filters_container2">
          <div class="right_container3">
            <button class="filter-btn" (click)="FilterModel()">
              FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
            </button>
            <button class="btn-add" style="height: 36px" (click)="addOutreachContent()">ADD Outreach Content</button>
          </div>
        </div>
        <app-filter-tags
          *ngIf="searchFilters"
          isPopup="true"
          [(filterButton)]="filterButton"
          [(searchFilters)]="searchFilters"
          [(appliedFilters)]="appliedFilters"
        ></app-filter-tags>
        <div class="table-communication-strategy">
          <table class="table table-pointable table-bordered">
            <thead>
              <tr>
                <th class="table_field_name">Name</th>
                <th class="table_field_name">Language</th>
                <th class="table_field_name">Template</th>
                <th class="table_field_name">Subject</th>
                <th class="table_field_name">Segment name</th>
                <th class="table_field_name">Segment code</th>
                <th class="table_field_name">Brand code</th>
                <th class="table_field_name">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let outreachContent of outreachContents">
                <td>{{ outreachContent.contentGroup.name }}</td>
                <td>{{ outreachContent.language.languageName }}</td>
                <td>
                  <button
                    [disabled]="!outreachContent.template"
                    class="btn btn-primary btn-sm"
                    (click)="templateModal(outreachContent.template)"
                  >
                    <fa-icon [icon]="faFileCode"></fa-icon> Preview Template
                  </button>
                </td>
                <td>{{ outreachContent.subject }}</td>
                <td>{{ outreachContent.segment.name }}</td>
                <td style="word-wrap: break-word; word-break: break-all">{{ outreachContent.segment.code }}</td>
                <td>{{ outreachContent.brandCode }}</td>
                <td>
                  <div class="float-left actions inline-table">
                    <a
                      class="btn btn-outline-primary btn-sm edit action"
                      (click)="addOutreachContent(outreachContent)"
                      title="Edit"
                    >
                      <fa-icon [icon]="faPencilAlt"></fa-icon>
                    </a>
                    <button
                      class="btn btn-outline-primary btn-sm edit action"
                      (click)="deleteOutreachContent(outreachContent)"
                      title="Delete"
                    >
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list (onScroll)="getOutreachContents(true)" [lock]="lockedTables" [isLoading]="isLoading">
        </app-add-more-data-to-list>
      </div>
    </div>
  </div>
</div>
