import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPencilAlt, faTrash, faFileCode } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { Output, EventEmitter, OnInit, Component, OnDestroy } from '@angular/core';
import { OutreachContentService } from '../services/outreach-content.service';
import { OutreachContentModalComponent } from './outreach-content-modal/outreach-content-modal.component';
import { ActivatedRoute } from '@angular/router';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';
import { SearchFilterComponent } from '../search-filter/search-filter/search-filter.component';
import { LanguageService } from '../services/language.service';
import { Filter, SearchFilters } from '../data/class';
import { OutreachContent } from '../data/model';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { OutreachContentTemplateModalComponent } from 'src/app/outreach-content/outreach-content-template-modal/outreach-content-template-modal.component';

@Component({
  selector: 'app-outreach-content',
  templateUrl: './outreach-content.component.html',
  styleUrls: ['./outreach-content.component.scss'],
})
export class OutreachContentComponent implements OnInit, OnDestroy {
  constructor(
    private outreachContentService: OutreachContentService,
    private modalHelper: NgbModal,
    private languageService: LanguageService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService,
    private activatedRoute: ActivatedRoute
  ) {}

  contentGroupId: number | undefined;
  communicationContentName: string | undefined;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faFileCode = faFileCode;
  filter = new Filter();
  searchFilters: SearchFilters;
  isLoading = false;
  lockedTables = false;
  lengthToCompare: number;
  languageSubscription: Subscription;
  filterSubscription: Subscription;
  languages: any[] = [];
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  outreachContents: OutreachContent[] = [];
  appliedFilters: Filter[];
  filterButton: boolean = false;

  ngOnInit(): void {
    this.languageSubscription = this.languageService.getLanguages().subscribe((res: any) => {
      if (res.success) {
        this.languages = res.entity;
        this.searchFilters = {
          formName: 'Outraech content',
          filters: [
            {
              name: 'brandCode',
              placeholder: 'Brand Code',
              label: 'Brand Code',
            },
            {
              name: 'languageId',
              placeholder: 'Select Language',
              label: 'Select Language',
              choices: this.languages,
              nameProp: 'languageName',
              valueProp: 'id',
              inputType: 'select',
              multiple: true,
              type: 'array',
              section: { key: 'firstRow' },
            },
            {
              name: 'segmentId',
              placeholder: 'Select segment',
              label: 'Segment',
              type: 'array',
              inputType: 'segment-filter',
              singleLabel: 'Segment',
            },
          ],
          searchCallback: () => {
            this.initNewSearch();
          },
        };
      }
    });

    this.contentGroupId = +this.activatedRoute.snapshot.params.id;
    this.communicationContentName = this.activatedRoute.snapshot.queryParams.name;

    this.initNewSearch();
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
    this.filterSubscription?.unsubscribe();
    if (typeof this.searchFilters?.clearAll === 'function') {
      this.searchFilters?.clearAll();
    }
  }

  initNewSearch(): void {
    this.filterSubscription = this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getOutreachContents(false);
  }

  getFilters = (existing: Filter): any => {
    (this.searchFilters?.filters || []).forEach((filter: Filter) => {
      if (![null, undefined].includes(filter.value)) {
        existing[filter.name] = filter.value;
      } else {
        delete existing[filter.name];
      }
    });
    return existing;
  };

  templateModal(template: string): void {
    const instance = this.modalHelper.open(OutreachContentTemplateModalComponent);
    instance.componentInstance.template = template;
  }

  getOutreachContents(concat?: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    const observer = this.communicationContentName
      ? this.outreachContentService.getOutreachContensBycontentGroupName(this.communicationContentName, this.filter)
      : this.outreachContentService.getOutreachContens(this.filter);
    observer.subscribe(
      (data: any) => {
        if (concat) {
          this.outreachContents = this.outreachContents.concat(data.entity.aaData);
        } else {
          this.outreachContents = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.filter.limit;
        this.isLoading = false;
        this.lockedTables = this.lengthToCompare !== this.filter.limit;
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.newLengthEvent.emit(0);
      }
    );
  }

  initFilter(): void {
    this.filter = new Filter();
    this.filter.limit = 20;
    this.filter.offset = 0;
  }

  addOutreachContent(outreachContent?: OutreachContent): void {
    const instance = this.modalHelper.open(OutreachContentModalComponent);
    instance.componentInstance.outreachContent = outreachContent || new OutreachContent(this.contentGroupId);
    instance.componentInstance.languages = this.languages;
    instance.componentInstance.successEvent.subscribe(() => {
      this.toastr.success('Outreach content record successfully ' + (outreachContent ? 'updated' : 'created'));
      instance.close();
      this.initFilter();
      this.getOutreachContents(false);
    });
  }

  deleteOutreachContent(outreachContent: OutreachContent): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete outreach content with id: ' + outreachContent.id;
    instance.componentInstance.header = 'Delete outreach content?';
    instance.componentInstance.promotionEdit = true;
    instance.componentInstance.onlyConfirm = true;
    instance.componentInstance.successEvent.subscribe(() => {
      instance.close();
      this.outreachContentService.deleteOutreachContent(outreachContent.id).subscribe(
        () => {
          this.toastr.success('Outreach content record successfully deleted');
          this.initNewSearch();
        },
        () => {
          this.toastr.error('Error');
        }
      );
    });
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Outreach content';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data: any) => {
      this.searchFilters = data;
      this.applyfilterService.getFunctions(this.searchFilters);
    });
  }
}
