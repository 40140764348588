<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <div>
              <h2 class="com-title">{{ campaign.name }}</h2>
            </div>
            <div class="d-flex align-items-center">
              <dt>Status:</dt>

              <dd class="d-flex align-items-center">
                <span class="badgeactive ml-2 mt-2 rounded-pill" [ngClass]="statusHelper[campaign.status]">{{
                  campaign.status
                }}</span>
              </dd>
              <!-- <a class="btn btn-dark-blue-action btn-sm ml-3" (click)="edit(campaign.id)" title="Edit">
                <fa-icon [icon]="faPencilAlt"></fa-icon>
              </a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- TODO: put this back with data in it. -->
      <div class="col-12 p-0 mb-3">
        <div class="d-flex justify-content-between dyn-bg">
          <div *ngFor="let stat of statistics" class="dyn-bgn">
            <div class="grey-box">
              <div class="stat-title-container">
                <span class="stat-title">{{ stat.title }}</span>
              </div>
              <div class="stat-count-container">
                <span class="stat-count" *ngIf="stat.count === null">0</span>
                <span class="stat-count" *ngIf="stat.count !== null">{{ stat.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-container">
        <mat-tab-group class="remove-border-bottom" animationDuration="0ms" (selectedTabChange)="tabClick($event)">
          <mat-tab label="PROMOTIONS">
            <app-promotions-tab
              [personId]="sponsorId"
              [sponsorName]="sponsor.name"
              [campaign]="campaign"
            ></app-promotions-tab>
          </mat-tab>
          <mat-tab label="CATEGORY">
            <app-promotion-category-tab [sponsorId]="sponsorId" [sponsor]="sponsor"> </app-promotion-category-tab>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
