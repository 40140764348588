import { QuestionEntry, QuestionResponse, ResponseSetEntry, buildResponsesFromResponseModularFields } from './Question';
import { Entry } from './shared.types';

export type StandardResponseQuestionEntry = {
  standard_response_question: {
    question: [QuestionEntry];
    response_set: [ResponseSetEntry];
  };
};

export type CustomResponseQuestionEntry = {
  custom_response_question: {
    question: [QuestionEntry];
    custom_response_list: {
      custom_responses: { response_text: string; response_value: string };
    }[];
  };
};
type SurveyQuestionModularField = StandardResponseQuestionEntry | CustomResponseQuestionEntry;

export interface SurveyEntry extends Entry {
  questions: SurveyQuestionModularField[];
  client: { uid: string; _content_type_uid: 'client'; title: string }[];
  program_sku: { program_sku: string };
  supported_languages: {
    language: string;
    _metadata?: { uid: string };
  }[];
  disposition: { disposition: string; _metadata?: { uid: string } }[];
}

export interface Survey {
  uid: string;
  title: string;
  description: string;
  clientTitle: string;
  programSku: string;
  supportedLanguages: string[];
  disposition: string[];
  questions: SurveyQuestion[];
}

export interface SurveyQuestion {
  uid: string;
  questionText: string;
  responses: QuestionResponse[];
  clientTitle?: string;
  questionId: string;
  programSku?: string;
  supportedLanguages: string[];
  disposition: string[];
  cmsBlackout: string;
}

function buildSurveyQuestionResponses(entry: SurveyQuestionModularField): QuestionResponse[] {
  if ((entry as StandardResponseQuestionEntry).standard_response_question) {
    const { question, response_set } = (entry as StandardResponseQuestionEntry).standard_response_question;
    const surveyQuestionResponseSet = response_set.length
      ? response_set[0].response_values.map((responseValue) => ({
          responseText: responseValue.Response_Value.response,
          responseValue: responseValue.Response_Value.value,
        }))
      : [];
    if (surveyQuestionResponseSet.length) {
      return surveyQuestionResponseSet;
    }
    return buildResponsesFromResponseModularFields(question[0]?.responses || []);
  }
  if ((entry as CustomResponseQuestionEntry).custom_response_question) {
    const { custom_response_list } = (entry as CustomResponseQuestionEntry).custom_response_question;
    return custom_response_list?.map((responseValue) => ({
      responseText: responseValue.custom_responses.response_text,
      responseValue: responseValue.custom_responses.response_text,
    }));
  }
}

export function buildSurveyQuestion(entry: SurveyQuestionModularField): SurveyQuestion {
  const questionEntry: QuestionEntry =
    (entry as StandardResponseQuestionEntry)?.standard_response_question?.question[0] ||
    (entry as CustomResponseQuestionEntry).custom_response_question?.question[0];
  if (!questionEntry) {
    throw new Error('not implemented');
  }
  return {
    uid: questionEntry.uid,
    questionText: questionEntry.question_text,
    responses: buildSurveyQuestionResponses(entry),
    clientTitle: questionEntry.client.map((client) => client.title).join(', '),
    questionId: questionEntry.question_id,
    programSku: questionEntry.program_sku?.program_sku,
    supportedLanguages: questionEntry.supported_languages.map((item) => item.language),
    disposition: questionEntry.disposition.map((item) => item.disposition),
    cmsBlackout: questionEntry.cms_blackout,
  };
}

export function buildSurvey(entry: SurveyEntry): Survey {
  return {
    uid: entry.uid,
    title: entry.title,
    description: entry.description,
    clientTitle: entry.client?.map((client) => client.title).join(', '),
    programSku: entry.program_sku?.program_sku || '',
    supportedLanguages: entry.supported_languages?.map((item) => item.language) || [],
    disposition: entry.disposition?.map((item) => item.disposition) || [],
    questions: entry.questions?.map((questionEntry) => buildSurveyQuestion(questionEntry)) || [],
  };
}
