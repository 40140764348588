<form [formGroup]="groupForm">
  <div class="modal-header">
    <h1>Add Group</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Group Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="groupName" />
      <!-- <div class="mt-1" [hidden]="groupForm.controls.groupName.valid">
        <small class="text-danger">Input a Code</small>
      </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!groupForm.valid"></app-modal-buttons>
  </div>
</form>
