<div class="eligible-promotions-panel" *ngIf="promoGroups?.length > 0">
  <nav mat-tab-nav-bar mat-align-tabs="left">
    <a
      mat-tab-link
      label="{{ category.name }}"
      *ngFor="let category of promoCategories | sort: 'order'"
      (click)="activeCategory = category.name"
      [active]="activeCategory == category.name"
      >{{ category.name }}</a
    >
  </nav>
  <!-- Promos start-->
  <div class="promo-pod" *ngFor="let group of promoGroups; let i = index; trackBy: i">
    <!-- nav list for grouped promos -->
    <div class="tabs-container" [hidden]="group.promos.length < 2 || activeCategory !== group.promos[0].category"></div>
    <div>
      <!-- Parent Promo -->
      <mat-card
        *ngFor="let promo of group.promos | sort: 'displayOrder'; first as isFirst"
        [hidden]="activeCategory !== promo.category"
      >
        <!-- orderBy: 'displayOrder'-->
        <div
          class="promotion-container row promotion-flag-container"
          [hidden]="activeCategory !== promo.category || promo.isSelected === false"
        >
          <div class="col-sm-11">
            <div class="left-notifications" *ngIf="promo.hasGatekeeper">
              <div class="left-notification" [attr.title]="'Complete to unlock incentives'">GATEKEEPER</div>
            </div>
            <div
              class="points"
              [hidden]="
                !promo.currencyValue ||
                (promo.thresholdGroup && promo.thresholdGroupCount > 1 && promo.thresholdGroupMin)
              "
            >
              <p class="desktop-amount"><span [hidden]="promo.hideDollarSign">$</span>{{ promo.displayAmount }}</p>
              <p class="desktop-currency" [hidden]="!promo.hideDollarSign">{{ promo.displayCurrency }}</p>
            </div>
            <div
              [hidden]="
                promo.currencyValue ||
                promo.hasGatekeeper ||
                (promo.thresholdGroup && promo.thresholdGroupCount > 1 && promo.thresholdGroupMin)
              "
              class="desktop-reward-image"
            >
              <img [src]="promo.displayRewardImageUrl" alt="Icon for non-currency award" />
            </div>
            <div
              [hidden]="!(!promo.currencyValue && !promo.displayAmount && promo.hasGatekeeper)"
              class="desktop-reward-image"
              style="position: relative"
            >
              <fa-stack style="margin-top: 28px">
                <fa-icon [icon]="faCircle" stackItemSize="2x" style="color: #4089cb"></fa-icon>
                <fa-icon [icon]="faKey" stackItemSize="1x" inverse="true"></fa-icon>
              </fa-stack>
            </div>
            <div
              [hidden]="!(promo.thresholdGroup && promo.thresholdGroupCount > 1 && promo.thresholdGroupMin)"
              class="desktop-reward-image"
              style="position: relative"
            >
              <fa-stack style="margin-top: 28px">
                <fa-icon [icon]="faCircle" stackItemSize="2x" style="color: #4089cb"></fa-icon>
                <fa-icon [icon]="faPiggyBank" stackItemSize="1x" inverse="true"></fa-icon>
              </fa-stack>
            </div>
            <!-- threshold group promos -->
            <div class="promotion-details" *ngIf="promo.thresholdGroup">
              <div class="promotion-title-and-subtitle">
                <h3>{{ promo.thresholdGroupTitle }}</h3>
              </div>
            </div>
            <div class="desktop-description" [hidden]="promo.hideDetails" *ngIf="promo.thresholdGroup">
              <div class="row">
                <div class="col-md-8">
                  <div [innerHTML]="promo.description"></div>
                  <p [innerHTML]="promo.disclaimer" class="desktop-disclaimer"></p>
                </div>
              </div>
            </div>
            <div *ngFor="let promotion of promo.promotions" [hidden]="promo.hideDetails">
              <div class="desktop-description-child-promo" *ngIf="promo.thresholdGroup">
                <div class="promotion-title-and-subtitle">
                  <mat-card-title
                    >{{ promotion.title }}
                    <fa-icon
                      [icon]="faInfoCircle"
                      size="lg"
                      *ngIf="promotion.displayMoreInfo"
                      tooltip-trigger
                      tooltip-animation="false"
                      tooltip-placement="bottom"
                      style="color: red"
                    ></fa-icon>
                  </mat-card-title>
                  <mat-card-subtitle class="subtitle" *ngIf="promotion.nonMemberFacingTitle"
                    >({{ promotion.nonMemberFacingTitle }})</mat-card-subtitle
                  >
                  <mat-card-subtitle class="hidden-sm subtitle">{{ promotion.subtitle }}</mat-card-subtitle>
                </div>
              </div>
              <mat-card-content
                class="desktop-description"
                [hidden]="promotion.hideDetails"
                *ngIf="promo.thresholdGroup"
              >
                <div class="row">
                  <div class="col-md-8">
                    <div [innerHTML]="promotion.description"></div>
                    <p [innerHTML]="promotion.disclaimer" class="desktop-disclaimer"></p>
                  </div>
                  <div class="col-sm-3 action-item" [hidden]="promotion.hideAllActions || promo.hideDetails">
                    <span [innerHTML]="promo.videoHTML" [hidden]="!promotion.hidePromoAction"></span>
                    <a
                      class="promotion-action-item"
                      (click)="openSurvey(promotion)"
                      [hidden]="promotion.hidePromoAction"
                      >{{ promotion.buttonText }}</a
                    >
                  </div>
                </div>
              </mat-card-content>
              <!-- Child -->
              <div
                class="desktop-description-child-promo"
                [hidden]="promo.hideDetails"
                *ngIf="promotion.children.length > 0 && promo.thresholdGroup"
              >
                <div class="row">
                  <div class="col-md-8">
                    <div [innerHTML]="promotion.description"></div>
                    <p [innerHTML]="promotion.disclaimer" class="desktop-disclaimer"></p>
                  </div>
                  <div class="col-sm-3 action-item" [hidden]="promotion.hideAllActions || promo.hideDetails">
                    <span [innerHTML]="promo.videoHTML" [hidden]="!promotion.hidePromoAction"></span>
                    <a
                      class="promotion-action-item"
                      (click)="openSurvey(promotion)"
                      [hidden]="promotion.hidePromoAction"
                      >{{ promotion.buttonText }}</a
                    >
                  </div>
                </div>
                <div
                  class="row promotion-li"
                  *ngFor="let child of promotion.children | sort: 'displayOrder'"
                  [hidden]="child.hide === true"
                >
                  <!-- orderBy: 'displayOrder'-->
                  <div class="promotion-li-icon col-md-1" *ngIf="child.displayCompletionIndicator">
                    <fa-icon
                      *ngIf="child.badgeData?.childIconClass"
                      [icon]="child.badgeData?.childIconClass"
                      size="lg"
                      [attr.title]="child.badgeData?.tooltip"
                      [attr.style]="child.badgeData?.style"
                    ></fa-icon>
                  </div>

                  <div class="promotion-li-text col-md-11" style="margin-right: 15px">
                    {{ child.title }}
                    <div [hidden]="child.description === '' || child.description === null" style="display: inline">
                      : {{ child.description }}
                    </div>
                    <div class="promotion-li-currency" [hidden]="!child.currencyValue">
                      (
                      <span [hidden]="child.hideDollarSign">$</span>
                      <span>{{ child.displayAmount }}</span>
                      <span [hidden]="!child.hideDollarSign">&nbsp;{{ child.displayCurrency }}</span
                      >)
                    </div>
                    &nbsp;
                    <app-button-show-promo-details
                      *ngIf="child.displayMoreInfo"
                      [promotion]="child"
                      [personId]="personId"
                    ></app-button-show-promo-details>
                    <div class="row" *ngIf="child.subtitle">
                      <div class="col-md-8">
                        <span [innerHTML]="child.subtitle" class="promotion-li-text-gr"></span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-8">
                        <span [innerHTML]="child.disclaimer" class="desktop-disclaimer"></span>
                      </div>
                    </div>
                  </div>
                  <!-- Grandchildren -->
                  <div
                    class="row promotion-li"
                    *ngFor="let grandchild of child.grandchildren | sort: 'displayOrder'"
                    style="margin-left: 15px"
                  >
                    <!-- orderBy: 'displayOrder' -->
                    <span
                      class="promotion-li-icon col-md-1"
                      style="margin-left: -15px"
                      [attr.title]="grandchild.badgeData?.tooltip"
                    >
                      <fa-icon
                        *ngIf="grandchild.badgeData?.childIconClass"
                        [icon]="grandchild.badgeData?.childIconClass"
                        size="lg"
                        [attr.style]="grandchild.badgeData?.style"
                      ></fa-icon>
                    </span>

                    <div class="promotion-li-text-gr li col-md-4">
                      {{ grandchild.title }}
                      <div
                        [hidden]="grandchild.description === '' || grandchild.description === null"
                        style="display: inline"
                      >
                        : {{ grandchild.description }}
                      </div>
                      <div class="promotion-li-currency" [hidden]="!grandchild.currencyValue">
                        (
                        <span [hidden]="grandchild.hideDollarSign">$</span>
                        <span>{{ grandchild.displayAmount }}&nbsp;</span>
                        <span [hidden]="!grandchild.hideDollarSign">{{ grandchild.displayCurrency }}</span
                        >)
                      </div>
                      &nbsp;
                      <app-button-show-promo-details
                        *ngIf="grandchild.displayMoreInfo"
                        [promotion]="grandchild"
                        [personId]="personId"
                      ></app-button-show-promo-details>
                      <div
                        class="action-item pull-right no-top-margin"
                        [hidden]="grandchild.hidePromoAction || promo.hideDetails"
                      >
                        <a
                          class="promotion-action-item"
                          (click)="openSurvey(grandchild)"
                          [hidden]="grandchild.hidePromoAction"
                          >{{ grandchild.buttonText }}</a
                        >
                      </div>
                      <div class="row">
                        <div class="col-md-8">
                          <span [innerHTML]="grandchild.disclaimer" class="desktop-disclaimer"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Non threshold groups -->
            <div class="promotion-details" *ngIf="!promo.thresholdGroup">
              <div class="promotion-title-and-subtitle">
                <h3>
                  {{ promo.title }}
                  <app-button-show-promo-details
                    *ngIf="promo.displayMoreInfo"
                    [promotion]="promo"
                    [personId]="personId"
                  ></app-button-show-promo-details>
                </h3>
                <p class="subtitle" *ngIf="promo.nonMemberFacingTitle">({{ promo.nonMemberFacingTitle }})</p>
                <div class="hidden-sm subtitle">{{ promo.subtitle }}</div>
              </div>
              <div [hidden]="promo.hideDetails">
                <a class="results-button" [attr.href]="promo.resultsUrl" [hidden]="!promo.showResults">
                  {{ promo.resultsLabel }}
                  <i class="fa {{ promo.resultsIcon }}"></i>
                </a>
              </div>
            </div>
            <div class="desktop-description" [hidden]="promo.hideDetails" *ngIf="!promo.thresholdGroup">
              <div class="row">
                <div class="col-md-8">
                  <div [innerHTML]="promo.description"></div>
                  <p [innerHTML]="promo.disclaimer" class="desktop-disclaimer"></p>
                  <div *ngFor="let capGroup of capGroups">
                    <p
                      class="desktop-disclaimer"
                      *ngIf="capGroup.capGroupName === promo.capGroup"
                      [innerHTML]="capGroup.capGroupMsg"
                    ></p>
                  </div>
                </div>
                <div class="col-sm-3 action-item" [hidden]="promo.hideAllActions || promo.hideDetails">
                  <span [innerHTML]="promo.videoHTML" [hidden]="!promo.hidePromoAction"></span>
                  <a class="promotion-action-item" (click)="openSurvey(promo)" [hidden]="promo.hidePromoAction">{{
                    promo.buttonText
                  }}</a>
                </div>
              </div>
            </div>
            <!-- Child -->
            <div
              class="desktop-description-child-promo"
              [hidden]="promo.hideDetails"
              *ngIf="promo.children.length > 0 && !promo.thresholdGroup"
            >
              <div
                class="row promotion-li"
                *ngFor="let child of promo.children | sort: 'displayOrder'"
                [hidden]="child.hide === true"
              >
                <div class="promotion-li-icon col-md-1" *ngIf="child.displayCompletionIndicator">
                  <fa-icon
                    *ngIf="child.badgeData?.childIconClass"
                    [icon]="child.badgeData?.childIconClass"
                    [attr.title]="child.badgeData?.tooltip"
                    [attr.style]="child.badgeData?.style"
                  ></fa-icon>
                </div>

                <div class="promotion-li-text col-md-11" style="margin-right: 15px">
                  <span [hidden]="!child.isGatekeeper">Gatekeeper: &nbsp;</span>{{ child.title }}
                  <div [hidden]="child.description === '' || child.description === null" style="display: inline">
                    : {{ child.description }}
                  </div>
                  <div class="promotion-li-currency" [hidden]="!child.currencyValue">
                    (
                    <span [hidden]="child.hideDollarSign">$</span>
                    <span>{{ child.displayAmount }}</span>
                    <span [hidden]="!child.hideDollarSign">&nbsp;{{ child.displayCurrency }}</span
                    >)
                  </div>
                  &nbsp;
                  <app-button-show-promo-details
                    *ngIf="child.displayMoreInfo"
                    [promotion]="child"
                    [personId]="personId"
                  ></app-button-show-promo-details>
                  <div class="row" *ngIf="child.isGatekeeper && !child.badgeData?.complete">
                    <div class="col-md-8">
                      <span class="desktop-disclaimer">Complete this activity to unlock more incentives</span>
                    </div>
                  </div>
                  <div class="row" *ngIf="child.subtitle">
                    <div class="col-md-8">
                      <span [innerHTML]="child.subtitle" class="promotion-li-text-gr"></span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-8">
                      <span [innerHTML]="child.disclaimer" class="desktop-disclaimer"></span>
                      <div *ngFor="let capGroup of capGroups">
                        <span
                          class="desktop-disclaimer"
                          *ngIf="capGroup.capGroupName === child.capGroup"
                          [innerHTML]="capGroup.capGroupMsg"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Grandchildren -->
                <div
                  class="row promotion-li"
                  *ngFor="let grandchild of child.grandchildren | sort: 'displayOrder'"
                  style="margin-left: 15px"
                >
                  <span
                    class="promotion-li-icon col-md-1"
                    style="margin-left: -15px"
                    [attr.title]="grandchild.badgeData?.tooltip"
                  >
                    <fa-icon
                      *ngIf="grandchild.badgeData?.childIconClass"
                      [icon]="grandchild.badgeData?.childIconClass"
                      size="lg"
                      [attr.style]="grandchild.badgeData?.style"
                    ></fa-icon>
                  </span>

                  <div class="promotion-li-text-gr li col-md-8">
                    {{ grandchild.title }}
                    <div
                      [hidden]="grandchild.description === '' || grandchild.description === null"
                      style="display: inline"
                    >
                      : {{ grandchild.description }}
                    </div>
                    <div class="promotion-li-currency" [hidden]="!grandchild.currencyValue">
                      (
                      <span [hidden]="grandchild.hideDollarSign">$</span>
                      <span>{{ grandchild.displayAmount }}&nbsp;</span>
                      <span [hidden]="!grandchild.hideDollarSign">{{ grandchild.displayCurrency }}</span
                      >)
                    </div>
                    &nbsp;
                    <app-button-show-promo-details
                      *ngIf="grandchild.displayMoreInfo"
                      [promotion]="grandchild"
                      [personId]="personId"
                    ></app-button-show-promo-details>
                    <div
                      class="action-item pull-right no-top-margin"
                      [hidden]="grandchild.hidePromoAction || promo.hideDetails"
                    >
                      <a
                        class="promotion-action-item"
                        (click)="openSurvey(grandchild)"
                        [hidden]="grandchild.hidePromoAction"
                        >{{ grandchild.buttonText }}</a
                      >
                    </div>
                    <div class="row">
                      <div class="col-md-8">
                        <span [innerHTML]="grandchild.disclaimer" class="desktop-disclaimer"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [hidden]="promo.hideDetails" class="col-md-12 col-sm-12 toggle-up-container">
              <div class="reasonable-alternative-container pull-left" [hidden]="promo.hideDetails">
                <div [hidden]="!promo.alternativesConfiguration.displayLink">
                  <fa-icon
                    [icon]="faInfoCircle"
                    size="lg"
                    tooltip="promo.alternativesConfiguration.toolTip"
                    tooltip-placement="bottom"
                    >&nbsp;
                  </fa-icon>
                  <a
                    class="reasonable-alternative"
                    [attr.href]="promo.alternativesConfiguration.linkLabel"
                    target="_blank"
                    >{{ promo.alternativesConfiguration.linkLabel }}</a
                  >
                </div>
              </div>
            </div>
            <div class="notifications">
              <div
                class="notification {{ promo.newFlag.type }}"
                [innerHTML]="promo.newFlag.label"
                *ngIf="promo.newFlag.label"
              ></div>
              <div
                class="notification {{ promo.badgeData?.type }}"
                [attr.title]="promo.badgeData?.tooltip"
                [innerHTML]="promo.badgeData?.label"
                *ngIf="promo.badgeData?.started"
              ></div>
            </div>
          </div>
          <div class="col-sm-1 pull-right">
            <a class="detail-chevron pull-right" (click)="promo.hideDetails = !promo.hideDetails">
              <span [hidden]="!promo.hideDetails"><fa-icon [icon]="faChevronDown" size="lg"></fa-icon></span>
              <span [hidden]="promo.hideDetails"
                ><fa-icon [icon]="faChevronDown" size="lg" rotate="180"></fa-icon
              ></span>
            </a>
          </div>
          <div class="overlay" [hidden]="!promo.badgeData?.complete"></div>
        </div>
      </mat-card>
    </div>
  </div>
  <!--      </amat-tab-link>-->
  <!--    </navmat-tab-nav-bar>-->
  <!--    <uib-tabset scrollable-tabs style="background-color: #e6f3e1; margin-bottom: 1em;">-->
  <!--      <uib-tab [ngClass]="{'active': first}" heading="{{category.name}}" *ngFor="let category in promoCategories | orderBy: 'order'"-->
  <!--               (click)="selectCategory($event)">-->
  <!--      </uib-tab>-->
  <!--    </uib-tabset>-->
</div>
