import { AppDataService } from '../app-data.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LiveAgentSurvey, User } from '../data/model';
import { mapHttpObservableToPromise, EntityResponsePromise, EntityResponse } from './http.service';
import { InfiniteScrollPageFields } from '../widgets/InfiniteScrollUtility';
import { UtilityService } from './utility.service';

export interface LiveAgentSurveyFilterCriteria extends InfiniteScrollPageFields {
  segments: number[];
  startAssessmentDate?: Date;
  endAssessmentDate?: Date;
  firstName?: string;
  lastName?: string;
  primaryInstrumentNumber?: string;
  completedByUserIds?: number[];
  limit: number;
  offset: number;
  statuses: string[];
}

type ParamType = {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: 'body';
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class LiveAgentSurveysService {
  isLiveAgent: any;
  isLiveAgentManager: any;
  isCSR: any;
  isPartner: any;
  isCSRManager: any;
  isAccountManager: any;
  isProgramManager: any;
  isAdmin: any;
  private utilityService: UtilityService = new UtilityService();
  private liveAgentSurveyPath = `${this.globalData.baseUrl}surveys/live-agent`;
  private liveAgentManagerSurveyPath = `${this.globalData.baseUrl}surveys/live-agent-manager`;
  private exportCSVPath = `${this.globalData.baseUrl}surveys/live-agent-manager/export`;
  private exportLiveAgentCSVPath = `${this.globalData.baseUrl}surveys/live-agent/export`;
  private liveAgentListPath = `${this.globalData.baseUrl}users/live-agents`;
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParamsLiveAgentSurvey(params: LiveAgentSurveyFilterCriteria, manager: boolean): ParamType {
    const modifiedParams: ParamType = {
      observe: 'body',
      params: this.utilityService.removeNullAndEmptyProperties({
        segments: params.segments.map((segment) => segment?.toString()),
        offset: params.offset?.toString() || null,
        limit: params.limit?.toString() || null,
        assessmentStartDate: params.startAssessmentDate?.toUTCString() || null,
        assessmentEndDate: params.endAssessmentDate?.toUTCString() || null,
        primaryInstrumentNumber: params.primaryInstrumentNumber || null,
        memberFirstName: params.firstName || null,
        memberLastName: params.lastName || null,
        completedByUserIds: manager ? params.completedByUserIds?.map((id) => id.toString()) || [] : null,
      }),
    };
    return modifiedParams;
  }

  setParamsForGetLiveAgentList(): ParamType {
    const paramsToSend: ParamType = {
      observe: 'body',
      params: { roles: ['LIVE_AGENT', 'LIVE_AGENT_MANAGER'] },
    };
    return paramsToSend;
  }

  setParamsForExportCSV(params: any): ParamType {
    const paramsToSend: ParamType = {
      observe: 'body',
      params: this.utilityService.removeNullAndEmptyProperties({
        segments: params.segments.map((segment) => segment?.toString()),
        assessmentStartDate: params.startAssessmentDate?.toUTCString() || null,
        assessmentEndDate: params.endAssessmentDate?.toUTCString() || null,
        primaryInstrumentNumber: params.primaryInstrumentNumber || null,
        memberFirstName: params.firstName || null,
        memberLastName: params.lastName || null,
        completedByUserIds: params.completedByUserIds?.map((id) => id.toString()) || [],
      }),
      responseType: 'text' as 'json',
    };
    return paramsToSend;
  }

  getLiveAgentSurveys(options?: LiveAgentSurveyFilterCriteria): EntityResponsePromise<LiveAgentSurvey[]> {
    const observable = this.http.get<EntityResponse<LiveAgentSurvey[]>>(
      `${this.liveAgentSurveyPath}`,
      this.setParamsLiveAgentSurvey(options, false)
    );
    return mapHttpObservableToPromise<LiveAgentSurvey[]>(observable);
  }

  getLiveAgentManagerSurveys(options?: LiveAgentSurveyFilterCriteria): EntityResponsePromise<LiveAgentSurvey[]> {
    const observable = this.http.get<EntityResponse<LiveAgentSurvey[]>>(
      `${this.liveAgentManagerSurveyPath}`,
      this.setParamsLiveAgentSurvey(options, true)
    );
    return mapHttpObservableToPromise<LiveAgentSurvey[]>(observable);
  }

  getLiveAgentList(): EntityResponsePromise<User[]> {
    const observable = this.http.get<EntityResponse<User[]>>(
      `${this.liveAgentListPath}`,
      this.setParamsForGetLiveAgentList()
    );
    return mapHttpObservableToPromise<User[]>(observable);
  }

  getPagedResult(surveys: LiveAgentSurvey[], options?: LiveAgentSurveyFilterCriteria): LiveAgentSurvey[] {
    return surveys.slice(options.offset, options.offset + options.limit);
  }

  canAccessLiveAgentDashboard(): boolean {
    this.isLiveAgent = this.globalData.checkPermission('LIVE_AGENT');
    this.isLiveAgentManager = this.globalData.checkPermission('LIVE_AGENT_MANAGER');
    this.isCSR = this.globalData.checkPermission('CSR');
    this.isCSRManager = this.globalData.checkPermission('CSR_MANAGER');
    this.isAccountManager = this.globalData.checkPermission('ACCOUNT_MANAGER');
    if (
      ((this.isLiveAgent || this.isLiveAgentManager) && (!this.isCSR || !this.isCSRManager)) ||
      this.isAccountManager
    ) {
      return true;
    }
    return false;
  }

  shouldUseLiveAgentManagerEndpoint(): boolean {
    this.isLiveAgentManager = this.globalData.checkPermission('LIVE_AGENT_MANAGER');
    this.isCSR = this.globalData.checkPermission('CSR');
    this.isCSRManager = this.globalData.checkPermission('CSR_MANAGER');
    this.isAccountManager = this.globalData.checkPermission('ACCOUNT_MANAGER');
    if ((this.isLiveAgentManager && (!this.isCSR || !this.isCSRManager)) || this.isAccountManager) {
      return true;
    }
    return false;
  }

  exportLiveAgentManager(params: any): any {
    const observable = this.http.get(`${this.exportCSVPath}`, this.setParamsForExportCSV(params));
    return observable.toPromise() as unknown as EntityResponsePromise<any>;
  }
  exportLiveAgent(params: any): any {
    const observable = this.http.get(`${this.exportLiveAgentCSVPath}`, this.setParamsForExportCSV(params));
    return observable.toPromise() as unknown as EntityResponsePromise<any>;
  }
}
