<form [formGroup]="partnerForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="name" />
          <!-- <div class="mt-1" [hidden]="partnerForm.controls.name.valid">
            <small class="text-danger">Input a Name</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Username <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="username" />
          <!-- <div class="mt-1" [hidden]="partnerForm.controls.username.valid">
            <small class="text-danger">Input a Username</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Url</label>
          <input type="text" class="form-control" formControlName="url" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Working Hours</label>
          <input type="text" class="form-control" formControlName="workingHours" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Year Start</label>
          <div class="input-group date">
            <input
              class="form-control cursor-pointer"
              readonly="readonly"
              (click)="yearStart.toggle()"
              placeholder="mm/dd/yyyy"
              name="yearStart"
              ngbDatepicker
              #yearStart="ngbDatepicker"
              formControlName="yearStart"
              [footerTemplate]="footerTemplateYearStart"
            />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="yearStart.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="status" class="form-control" formControlName="status">
            <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
          </select>
          <!-- <div class="mt-1" [hidden]="partnerForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isEdit">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Select segments <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            [items]="filteredSegments"
            bindLabel="code"
            [multiple]="true"
            formControlName="segmentsCollection"
            [hideSelected]="true"
            [closeOnSelect]="false"
          >
          </ng-select>
          <!-- <div class="mt-1" [hidden]="partnerForm.controls.segmentsCollection.valid">
            <small class="text-danger">Select role</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Select Role <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <ng-select
            [items]="roles"
            bindLabel="roleName"
            [multiple]="true"
            formControlName="partyRoleTypes"
            [hideSelected]="true"
            [closeOnSelect]="false"
          >
          </ng-select>
          <!-- <div class="mt-1" [hidden]="partnerForm.controls.partyRoleTypes.valid">
            <small class="text-danger">Select role</small>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!partnerForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>

<ng-template #footerTemplateYearStart>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate" (click)="clearDateInput('endDate'); yearStart.toggle()">Clear</button>
  </div>
</ng-template>
