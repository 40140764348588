<div class="panel-body">
  <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
  <div class="row">
    <div class="col-md-12">
      <div>
        <div class="row">
          <table class="table table-pointable table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sponsor of sponsors">
                <td>
                  <a routerLink="/markets/{{ sponsor.id }}">{{ sponsor.id }}</a>
                </td>
                <td>{{ sponsor.name }}</td>
                <td>{{ sponsor.status }}</td>
              </tr>
              <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list
          (onScroll)="getSponsors(true)"
          [lock]="lock"
          [isLoading]="isLoading"
        ></app-add-more-data-to-list>
      </div>
    </div>
  </div>
</div>
