<div class="modal-content modalstyle">
  <div class="modal-body" style="padding-top: 24px">
    <div class="mb-4">
      <a (click)="cancel()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
      <p class="title">Manifest</p>
      <div class="border-bottom"></div>
    </div>
    <span class="expire">Unsaved changes</span>
    <br />
    <span>You have unsaved changes. Would you like to save your changes?</span>
  </div>
  <div class="pop-btn-container">
    <button type="button" class="btn-pop-cancel" (click)="allowChange()">DISCARD</button>
    <button type="button" class="btn-pop-success" (click)="save()">SAVE</button>
  </div>
</div>
