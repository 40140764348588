<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Subject</label>
      <input type="text" [(ngModel)]="subject" name="subject" placeholder="Ticket name" class="form-control" />
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea
        type="text"
        [(ngModel)]="description"
        name="description"
        placeholder="Description"
        class="form-control vertical-expand"
      >
      </textarea>
    </div>
    <div class="custom-dropzone" ngx-dropzone [multiple]="true" (change)="onSelect($event)">
      <ngx-dropzone-label>Drop files here to upload</ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label
          >{{ f.name }}<br />
          ({{ f.type }})<br />
          ({{ f.size / 1000000 | number: "1.0-2" }} MB)</ngx-dropzone-label
        >
      </ngx-dropzone-preview>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" [disabled]="lockSave || !subject || !description" (click)="ok()">
      Save
    </button>
    <button class="btn btn-warning" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
