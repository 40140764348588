import { Injectable } from '@angular/core';
import $ from 'jquery';
import { Choice } from 'src/app/data/class';

@Injectable({
  providedIn: 'root',
})
export class TableHelperService {
  innerText: string;

  constructor() {}

  changeFilter(filtered: any[], toFilter: any[], selected: any, instance: any, $event: any, collapsible?: any): void {
    const idx = filtered.findIndex((item) => item.value === selected.value);
    let selectedIdx: number;

    if (idx > -1) {
      filtered.splice(idx, 1);
      selectedIdx = toFilter.findIndex((item) => item.value === selected.value);
      if (selectedIdx > -1) {
        toFilter[selectedIdx].state = false;
      }
    } else {
      filtered.push(selected);
      selectedIdx = toFilter.indexOf(selected);
      if (selectedIdx > -1) {
        toFilter[selectedIdx].state = true;
      }
    }

    if (instance) {
      if (collapsible) {
        instance.DataTable.draw();
      } else {
        instance.rerender();
      }
    }

    if ($event) {
      $event.stopPropagation();
    }
  }

  extractData(array: any[]): any {
    if (array && array.length > 0) {
      const values = [];
      array.forEach((data: any) => {
        values.push(data.value ? data.value : data.id);
      });
      return values;
    }
    return null;
  }

  getStatuses(type: string): Choice[] {
    if (type.toUpperCase() === 'ENTITY') {
      return [
        { value: 'ACTIVE', state: false },
        { value: 'STARTED', state: false },
        { value: 'COMPLETED', state: false },
        { value: 'INACTIVE', state: false },
        { value: 'PENDING', state: false },
        { value: 'SUSPENDED', state: false },
        { value: 'TERMINATED', state: false },
        { value: 'RETIRED', state: false },
        { value: 'EXPIRED', state: false },
        { value: 'DELETED', state: false },
        { value: 'FAILED', state: false },
      ];
    } else if (type.toUpperCase() === 'IMPORT') {
      return [
        { value: 'STAGING', state: false },
        { value: 'STAGED', state: false },
        { value: 'STAGING_ERROR', state: false },
        { value: 'IMPORTING', state: false },
        { value: 'IMPORTED', state: false },
        { value: 'IMPORTING_ERROR', state: false },
        { value: 'RETRY', state: false },
        { value: 'POSTPROCESSED', state: false },
        { value: 'COMPLETED', state: false },
      ];
    } else if (type.toUpperCase() === 'NOTES') {
      return [
        { value: 'ACTIVE', state: false },
        { value: 'COMPLETED', state: false },
        { value: 'ESCALATED', state: false },
        { value: 'INACTIVE', state: false },
        { value: 'DELETED', state: false },
      ];
    } else if (type.toUpperCase() === 'REPORTS') {
      return [
        { value: 'STARTED', state: false },
        { value: 'COMPLETED', state: false },
        { value: 'FAILED', state: false },
      ];
    } else if (type.toUpperCase() === 'AVAILABLE_REPORTS') {
      return [
        { value: 'ACTIVE', state: false },
        { value: 'STARTED', state: false },
        { value: 'COMPLETED', state: false },
        { value: 'INACTIVE', state: false },
        { value: 'PENDING', state: false },
        { value: 'SUSPENDED', state: false },
        { value: 'TERMINATED', state: false },
        { value: 'RETIRED', state: false },
        { value: 'EXPIRED', state: false },
        { value: 'DELETED', state: false },
        { value: 'FAILED', state: false },
      ];
    } else if (type.toUpperCase() === 'CONTACT_REASONS') {
      return [
        { value: 'ACTIVE', state: false },
        { value: 'PENDING', state: false },
        { value: 'DELETED', state: false },
      ];
    } else if (type.toUpperCase() === 'DROPDOWN') {
      return [
        { value: 'ACTIVE', state: false },
        { value: 'STARTED', state: false },
        { value: 'COMPLETED', state: false },
        { value: 'INACTIVE', state: false },
        { value: 'PENDING', state: false },
        { value: 'SUSPENDED', state: false },
        { value: 'TERMINATED', state: false },
        { value: 'RETIRED', state: false },
        { value: 'EXPIRED', state: false },
        { value: 'DELETED', state: false },
        { value: 'FAILED', state: false },
      ];
    } else {
      return [];
    }
  }

  showTitle(): void {
    $('.table').on('mouseenter', 'td', () => {
      $(this).attr('title', this.innerText);
    });
  }

  getAccountTypes = () => [
    { value: 'REWARD', state: false },
    { value: 'REDEMPTION', state: false },
    { value: 'REVERSE', state: false },
    { value: 'ADJUSTMENT', state: false },
    { value: 'EXPIRY', state: false },
  ];

  getProtocolTypes = () => [
    { value: 'FTP', state: false },
    { value: 'FTPS', state: false },
    { value: 'SFTP', state: false },
  ];

  getCredentialTypes = () => [
    { value: 'BASIC', state: false },
    { value: 'SSH', state: false },
  ];

  getRoles = () => [
    {value: 'ADMIN', state: false},
    {value: 'SYSTEM_USER', state: false},
    {value: 'PROGRAM_MANAGER', state: false},
    {value: 'ACCOUNT_MANAGER', state: false},
    {value: 'CSR_MANAGER', state: false},
    {value: 'PARTNER', state: false},
    {value: 'CSR', state: false},
    {value: 'LIVE_AGENT_MANAGER', state: false},
    {value: 'LIVE_AGENT', state: false},
    {value: 'MEMBER', state: false},
  ]
}
