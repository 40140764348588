<div [ngClass]="filter.inputClass" class="search-filter-fields" *ngIf="section.open">
  <div class="input-group dat font-relative-width">
    <input
      class="form-control cursor-pointer bgcolor"
      [minDate]="minDate"
      [maxDate]="maxDate"
      placeholder="{{ filter.placeholder }}"
      name="d"
      ngbDatepicker
      readonly
      [footerTemplate]="footerTemplateDateModel"
      #d="ngbDatepicker"
      [(ngModel)]="model2"
      (click)="d.toggle()"
      (ngModelChange)="formatDate($event)"
    />
    <ng-template #footerTemplateDateModel>
      <div class="width-100 datePickerTemplate">
        <button class="buttonDateTemplate" (click)="clearDate(); d.toggle()">Clear</button>
      </div>
    </ng-template>
    <div class="input-group-append">
      <button class="btn calendar" (click)="d.toggle()" type="button">
        <img src="../../../assets/images/calendar-regular.svg" />
      </button>
    </div>
  </div>
</div>
