<form [formGroup]="communicationStrategyForm" class="com-model" novalidate>
  <div class="com-strategy-modal">
    <div>
      <span class="title">{{ title }}</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-3 mt-3">
      <span class="com-title">{{ isEdit ? "Edit " : "Add " }}communication strategy</span>
    </div>
    <div>
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Name <span style="color: red">*</span></label>
          <span>(Required)</span>
        </div>
        <input type="text" class="form-control" formControlName="name" autofocus placeholder="Add name " />
        <!-- <div class="mt-2" [hidden]="communicationStrategyForm.controls['name'].valid">
          <small class="text-danger">Input a Name</small>
        </div> -->
      </div>

      <div class="form-group">
        <label class="com-label">New earning opportunity</label>
        <ng-select
          class="inputfontsize"
          [items]="comm"
          bindLabel="name"
          bindValue="id"
          formControlName="newPromotionsId"
          placeholder="Select earning opportunity"
        ></ng-select>
      </div>

      <div class="form-group">
        <label class="com-label">Expiring earning opportunity</label>
        <ng-select
          class="inputfontsize"
          [items]="comm"
          bindLabel="name"
          bindValue="id"
          formControlName="expiringPromotionsId"
          placeholder="Select earning opportunity"
        ></ng-select>
      </div>

      <div class="form-group">
        <label class="com-label">No activity received</label>
        <ng-select
          class="inputfontsize"
          [items]="comm"
          bindLabel="name"
          bindValue="id"
          formControlName="noActivityId"
          placeholder="Select activity"
        ></ng-select>
      </div>

      <div class="form-group">
        <label class="com-label">Earned activity reward</label>
        <ng-select
          class="inputfontsize"
          [items]="comm"
          bindLabel="name"
          bindValue="id"
          formControlName="activityRewardId"
          placeholder="Select reward"
        ></ng-select>
      </div>

      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Priority <span style="color: red">*</span></label>
          <span>(Required)</span>
        </div>
        <ng-select
          class="inputfontsize"
          [items]="priority"
          formControlName="priority"
          placeholder="Select priority"
        ></ng-select>
      </div>
    </div>
    <div class="pop-btn-container">
      <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
      <button
        class="btn-pop-success"
        type="submit"
        [disabled]="!communicationStrategyForm.valid"
        (click)="ok()"
        title="Save"
      >
        {{ isEdit ? "SAVE" : "ADD" }}
      </button>
    </div>
  </div>
</form>
