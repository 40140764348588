import { DateHelperService } from './../services/helpers/date-helper.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faCalendar, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sponsorship-modal',
  templateUrl: './sponsorship-modal.component.html',
  styleUrls: ['./sponsorship-modal.component.scss'],
})
export class SponsorshipModalComponent implements OnInit {
  @Input() promoSponsor: any;
  @Input() promCategories: any;
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  title = 'Edit sponsorship';
  sponsorshipForm: FormGroup;
  errorMsg = '';
  publishedStartDateValue: NgbDate;
  publishedEndDateValue: NgbDate;
  processingStartDateValue: NgbDate;
  processingEndDateValue: NgbDate;
  scoreStartDateValue: NgbDate;
  scoreEndDateValue: NgbDate;
  displayLink: boolean;

  faTimes = faTimes;
  faCalendar = faCalendar;
  faSave = faSave;

  constructor(
    private statusHelperService: StatusHelperService,
    private sponsorsService: SponsorsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private dateHelperService: DateHelperService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    this.sponsorshipForm = this.formBuilder.group({
      sponsorshipTag: [this.promoSponsor.sponsorshipTag, Validators.pattern(/^[a-zA-Z\d\-\_\:\ (\)]+$/)],
      categoryId: [this.promoSponsor.categoryId],
      publishedStartDate: [this.dateHelperService.stringToNgbDate(this.promoSponsor.publishedStartDate)],
      publishedEndDate: [this.dateHelperService.stringToNgbDate(this.promoSponsor.publishedEndDate)],
      processingStartDate: [this.dateHelperService.stringToNgbDate(this.promoSponsor.processingStartDate)],
      processingEndDate: [this.dateHelperService.stringToNgbDate(this.promoSponsor.processingEndDate)],
      scoreStartDate: [this.dateHelperService.stringToNgbDate(this.promoSponsor.scoreStartDate)],
      scoreEndDate: [this.dateHelperService.stringToNgbDate(this.promoSponsor.scoreEndDate)],
      isHidden: [this.promoSponsor.isHidden],
      sponsorPartyId: [this.promoSponsor.sponsorPartyId],
      status: [this.promoSponsor.status],
      id: [this.promoSponsor.id],
      promotionId: [this.promoSponsor.promotionId],
      displayXML: [this.promoSponsor.displayXML],
    });
  }

  ok(): void {
    this.formatDate();
    this.sponsorsService
      .createOrUpdatePromotionSponsor(this.promoSponsor.id, this.sponsorshipForm.value, 'xml')
      .subscribe(
        (data: any) => {
          if (data.success) {
            this.responseHelperService.success('Sponsorship successfully updated', true);
            this.successEvent.emit();
          }
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  clearDate(name: string): void {
    this.sponsorshipForm.patchValue({
      [name]: null,
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  formatDate(): void {
    if (this.sponsorshipForm.controls.publishedStartDate.value) {
      const stringDate = this.dateHelperService.ngbDateToString(this.sponsorshipForm.controls.publishedStartDate.value);
      this.sponsorshipForm.controls.publishedStartDate.setValue(stringDate);
    }
    if (this.sponsorshipForm.controls.publishedEndDate.value) {
      const stringDate = this.dateHelperService.ngbDateToString(this.sponsorshipForm.controls.publishedEndDate.value);
      this.sponsorshipForm.controls.publishedEndDate.setValue(stringDate);
    }
    if (this.sponsorshipForm.controls.processingStartDate.value) {
      const stringDate = this.dateHelperService.ngbDateToString(
        this.sponsorshipForm.controls.processingStartDate.value
      );
      this.sponsorshipForm.controls.processingStartDate.setValue(stringDate);
    }
    if (this.sponsorshipForm.controls.processingEndDate.value) {
      const stringDate = this.dateHelperService.ngbDateToString(this.sponsorshipForm.controls.processingEndDate.value);
      this.sponsorshipForm.controls.processingEndDate.setValue(stringDate);
    }
    if (this.sponsorshipForm.controls.scoreStartDate.value) {
      const stringDate = this.dateHelperService.ngbDateToString(this.sponsorshipForm.controls.scoreStartDate.value);
      this.sponsorshipForm.controls.scoreStartDate.setValue(stringDate);
    }
    if (this.sponsorshipForm.controls.scoreEndDate.value) {
      const stringDate = this.dateHelperService.ngbDateToString(this.sponsorshipForm.controls.scoreEndDate.value);
      this.sponsorshipForm.controls.scoreEndDate.setValue(stringDate);
    }
  }
}
