import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromotionsTabTackerService {
  private tab = new BehaviorSubject<any>(null);
  selectedTab = this.tab.asObservable();

  constructor() {}

  selectTab(tab: number) {
    this.tab.next(tab);
  }
}
