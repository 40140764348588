import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationStrategyConfiguration } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPlus, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { CommunicationsService } from '../services/communications.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import * as moment from 'moment';
import { JobService } from '../services/job.service';

@Component({
  selector: 'app-communication-strategy-configuration-modal',
  templateUrl: './communication-strategy-configuration-modal.component.html',
  styleUrls: ['./communication-strategy-configuration-modal.component.scss'],
})
export class CommunicationStrategyConfigurationModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public communicationStrategyConfiguration: CommunicationStrategyConfiguration;
  @Input() public commStrategyId: number;
  @Input() filterIds: number[] = [];
  endpointTypes: any[] = [];
  title: string;
  isEdit: boolean;
  jobs: any[];
  filterAdd: any = [];
  communicationStrategyConfigurationForm: FormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  faPlus = faPlus;
  faTrash = faTrash;
  optInOptOutOptions = [
    { value: true, text: 'Opt-in' },
    { value: false, text: 'Opt-out' },
  ];
  period = ['DAY', 'WEEK', 'MONTH', 'YEAR', 'CAMPAIGN'];
  days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  timeOptions: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private responseHelperService: ResponseHelperService,
    private statusHelperService: StatusHelperService,
    private communicationStrategyService: CommunicationStrategyService,
    private communicationsService: CommunicationsService,
    private modalHelper: NgbModal,
    private jobService: JobService
  ) {}
  public val: string;
  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    this.jobService.getJobs({ jobSelector: 'F' }).subscribe((data: any) => {
      if (data.success) {
        this.jobs = data.entity.filter((job: any) => !this.filterIds.includes(job.id));
      }
    });
    // 96 = 4 * 24. 4 options for every hour
    const offset = new Date().getTimezoneOffset();
    for (let i = 0; i < 96; i++) {
      const hours = Math.floor(i / 4);
      const minutes = (i % 4) * 15;
      this.timeOptions.push({
        value: this.formatDigit(hours) + ':' + this.formatDigit(minutes) + ':00',
        label: moment.parseZone().hour(hours).minutes(minutes).seconds(0).millisecond(0).toDate(),
      });
    }
    if (this.communicationStrategyConfiguration) {
      this.title = 'Edit Configuration';
      this.isEdit = true;
      this.communicationStrategyConfigurationForm = this.formBuilder.group({
        fromTime: [this.communicationStrategyConfiguration.fromTime, Validators.required],
        toTime: [this.communicationStrategyConfiguration.toTime, Validators.required],
        optInOptOut: [this.communicationStrategyConfiguration.optInOptOut, Validators.required],
        endpointTypeName: [this.communicationStrategyConfiguration.endpointTypeName, Validators.required],
        blackOutPeriods: [
          this.communicationStrategyConfiguration.blackOutPeriods &&
            this.communicationStrategyConfiguration.blackOutPeriods.split(','),
        ],
        commCaps: this.formBuilder.array([]),
        filters: this.formBuilder.array([]),
      });
      if (this.communicationStrategyConfiguration.commCaps) {
        this.communicationStrategyConfiguration.commCaps.forEach((commCap: any) => {
          this.addCommCap(commCap.maxCommunicationsNum, commCap.maxCommunicationsPeriod, commCap.id);
        });
      }

      if (this.communicationStrategyConfiguration.filters) {
        this.communicationStrategyConfiguration.filters.forEach((filter: any) => {
          this.addFilter(filter.id, filter.jobInfoId, filter.endpointTypeName, filter.ordernum);
        });
      }
    } else {
      this.title = 'Add configuration';
      this.isEdit = false;
      this.communicationStrategyConfigurationForm = this.formBuilder.group({
        fromTime: [null, Validators.required],
        toTime: [null, Validators.required],
        optInOptOut: [null, Validators.required],
        endpointTypeName: [null, Validators.required],
        blackOutPeriods: [],
        commCaps: this.formBuilder.array([]),
        filters: this.formBuilder.array([]),
      });
    }

    this.communicationsService.getAllEndpointTypes(false).subscribe((endpointTypes: any) => {
      if (endpointTypes.success) {
        this.endpointTypes = endpointTypes.entity;
      }
    });
  }

  ok(): void {
    const formValue = Object.assign({}, this.communicationStrategyConfigurationForm.value);
    formValue.fromTime = this.convertToMiliSeconds(formValue.fromTime);
    formValue.toTime = this.convertToMiliSeconds(formValue.toTime);
    if (formValue.blackOutPeriods) {
      formValue.blackOutPeriods = formValue.blackOutPeriods.join(',');
    }
    formValue.commStrategyId = this.commStrategyId;
    this.communicationStrategyService
      .createOrUpdateCommunicationStrategyConfiguration(
        this.communicationStrategyConfiguration && this.communicationStrategyConfiguration.id,
        formValue
      )
      .subscribe(
        () => {
          this.successEvent.emit('success');
        },
        (data: any) => {
          this.responseHelperService.error(this, data.message, false);
        }
      );
  }

  removeCommCap(i: number): void {
    this.getCommCaps().removeAt(i);
  }
  removeFilter(i: number): void {
    this.getaddfilter().removeAt(i);
  }

  newfilter(id?: number, jobInfoId?: number, endpointTypeName?: string, ordernum?: number): FormGroup {
    return this.formBuilder.group({
      jobInfoId: [jobInfoId || null, Validators.required],
      endpointTypeName: [
        endpointTypeName || this.communicationStrategyConfigurationForm.controls.endpointTypeName.value,
      ],
      commStrategyConfigurationId: [
        this.communicationStrategyConfiguration && this.communicationStrategyConfiguration.id,
      ],
      ordernum: [ordernum || (this.communicationStrategyConfigurationForm.controls.filters as FormArray).length + 1],
      id: [id],
    });
  }
  addFilter(id?: number, jobInfoId?: number, endpointTypeName?: string, ordernum?: number) {
    this.getaddfilter().push(this.newfilter(id, jobInfoId, endpointTypeName, ordernum));
  }
  addCommCap(maxCommunicationsNum?: string, maxCommunicationsPeriod?: string, id?: number): void {
    this.getCommCaps().push(this.newCommCaps(maxCommunicationsNum, maxCommunicationsPeriod, id));
  }

  newCommCaps(maxCommunicationsNum?: string, maxCommunicationsPeriod?: string, id?: number): FormGroup {
    return this.formBuilder.group({
      maxCommunicationsNum: [maxCommunicationsNum || '', Validators.required],
      maxCommunicationsPeriod: [maxCommunicationsPeriod || '', Validators.required],
      commStrategyConfigurationId: [
        this.communicationStrategyConfiguration && this.communicationStrategyConfiguration.id,
      ],
      id: [id],
    });
  }

  getCommCaps(): FormArray {
    return this.communicationStrategyConfigurationForm.controls.commCaps as FormArray;
  }
  getaddfilter(): FormArray {
    return this.communicationStrategyConfigurationForm.controls.filters as FormArray;
  }
  cancel(): void {
    this.modalHelper.dismissAll();
  }

  formatDigit(digit: number): any {
    return digit > 9 ? digit : '0' + digit;
  }

  convertToMiliSeconds(value: string): number {
    const hours = Number(value.split(':')[0]);
    const minutes = Number(value.split(':')[1]);
    const date = moment.utc().hour(hours).minutes(minutes).seconds(0).millisecond(0);
    return date.unix() * 1000;
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
