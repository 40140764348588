import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-header-generic',
  templateUrl: './filter-header-generic.component.html',
  styleUrls: ['./filter-header-generic.component.scss'],
})
export class FilterHeaderGenericComponent {
  @Input() searchFilters: any;

  constructor() {}
}
