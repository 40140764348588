<div class="panel-body">
  <div class="row">
    <div class="full-width">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <small><em>Note: All times are UTC</em></small>
      <table
        [(ngModel)]="attrs"
        name="attrs"
        ngDefaultControl
        class="table table-hover table-bordered table-pointable infinite-table"
        width="100%"
      >
        <thead>
          <tr>
            <th class="width-23">Name</th>
            <th class="width-33">Value</th>
            <th>Display Name</th>
            <th class="width-10-rem">
              <span class="float-left">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="attrs.length < 1">
            <td class="text-center" colspan="4">No attributes to show</td>
          </tr>
          <ng-container>
            <tr *ngFor="let attr of attrs">
              <td [attr.title]="attr.attrName">{{ attr.attrName }}</td>
              <td [attr.title]="attr.attrValue" class="word-break-break-all">{{ attr.attrValue }}</td>
              <td>{{ attr.displayName }}</td>
              <td class="table-buttons">
                <a
                  class="btn btn-sm float-left btn-dark-blue"
                  *ngIf="!disableActions && allowedToEditAttribs"
                  (click)="partyAttrModal(attr)"
                  [hidden]="disableActions"
                  title="Edit"
                >
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
