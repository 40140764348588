import { ManifestUnsavedChangesModalComponent } from './../manifest/manifest-unsaved-changes-modal/manifest-unsaved-changes-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ResponseHelperService } from './helpers/response-helper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationGuard implements CanDeactivate<any> {
  constructor(private modalHelper: NgbModal, private responseHelperService: ResponseHelperService) {}

  public canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (component.unsavedChanges) {
      const instance = this.modalHelper.open(ManifestUnsavedChangesModalComponent, { size: 'sm' });
      instance.componentInstance.manifestComponent = component;

      return instance.componentInstance.allow.asObservable();
    } else {
      return true;
    }
  }
}
