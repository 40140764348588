<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="ibox">
        <div class="ibox-title">
          <h2>Integration messages for: {{ selectedIntegrationName }}</h2>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div full-scroll>
                <div class="row">
                  <div class="row ml-3">
                    <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
                  </div>
                  <div class="row mt-3">
                    <table class="table table-pointable table-bordered infinite-table">
                      <thead>
                        <tr>
                          <th class="width-7">ID</th>
                          <th class="width-7">Stage load ID</th>
                          <th class="width-10">Created on</th>
                          <th class="width-10">Operation</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let msg of integrationMessages">
                          <td>{{ msg.id }}</td>
                          <td>
                            <a
                              title="{{ msg.stageLoadId }}"
                              routerLink="/integrations/{{ selectedIntegrationId }}/loads/{{ msg.stageLoadId }}"
                              >{{ msg.stageLoadId }}</a
                            >
                          </td>
                          <td>{{ msg.createdOn }}</td>
                          <td>{{ msg.operation }}</td>
                          <td>{{ msg.message }}</td>
                        </tr>
                        <tr app-is-there-more [numColumns]="5" [newLengthEvent]="newLengthEvent"></tr>
                      </tbody>
                    </table>
                  </div>
                  <app-add-more-data-to-list
                    (onScroll)="getIntegrationMessages(true)"
                    [lock]="lock"
                    [isLoading]="isLoading"
                  >
                  </app-add-more-data-to-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
