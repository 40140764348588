// This file exists so that we can more safely navigate to routes within our application. If adding imports
// (probably shouldn't be) to this file be sure to not create circular dependencies.
export const PARENTS_ROUTE_PATH = 'parents';
export const PARENT_ID_KEY = 'parentId';
export const PARENT_ROUTE_WITH_ID_KEY = `${PARENTS_ROUTE_PATH}/:${PARENT_ID_KEY}`;
export const CLIENT_ROUTE_PATH = 'clients';
export const CLIENT_ID_KEY = `clientId`;
export const CLIENT_ROUTE_PATH_WITH_ID = `${PARENT_ROUTE_WITH_ID_KEY}/clients/:${CLIENT_ID_KEY}`;
export const LINE_OF_BUSINESS_ROUTE_PATH = 'lines-of-business';
export const LINE_OF_BUSINESS_ID_KEY = `lineOfBusinessId`;
export const LINE_OF_BUSINESS_ROUTE_PATH_WITH_ID = `${CLIENT_ROUTE_PATH_WITH_ID}/lines-of-business/:${LINE_OF_BUSINESS_ID_KEY}`;
export const MARKET_ROUTE_PATH = 'markets';
export const MARKET_ID_KEY = `marketId`;
export const MARKET_ROUTE_PATH_WITH_ID = `${LINE_OF_BUSINESS_ROUTE_PATH_WITH_ID}/markets/:${MARKET_ID_KEY}`;
export const PROGRAM_ROUTE_PATH = 'programs';
export const PROGRAM_ID_KEY = 'campaignId';
export const PROGRAM_ROUTE_PATH_WITH_ID = `${MARKET_ROUTE_PATH_WITH_ID}/programs/:${PROGRAM_ID_KEY}`;
export const PACKAGED_SPONSORSHIP_ROUTE_PATH = 'packaged-sponsorship';
export const SPONSORSHIP_TAG = 'sponsorshipTag';
export const SPONSORSHIP_GROUP_NAME = 'sponsorshipTag';
export const PACKAGED_SPONSORSHIP_ROUTE_PATH_WITH_TAG = `${PROGRAM_ROUTE_PATH_WITH_ID}/packaged-sponsorship/:${SPONSORSHIP_GROUP_NAME}`;
export const LIVE_AGENT_DASHBOARD_ROUTE_PATH = 'live-agent-dashboard';

export const CALL_FLOW_BUILDER_PATH = 'call-flow-builder';
export const CALL_FLOW_BUILDER_TITLE = 'Call Flow Builder';
