import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContactMethodHelperService {
  constructor() {}

  getContactMethods(type: string): any {
    if (type === 'name') {
      return {
        PHONE_CALL: 'Phone Call',
        EMAIL: 'Email',
        FAX: 'Fax',
        OTHER: 'Other',
      };
    } else if (type === 'value') {
      return [
        { name: 'Phone Call', value: 'PHONE_CALL' },
        { name: 'Email', value: 'EMAIL' },
        { name: 'Fax', value: 'FAX' },
        { name: 'Other', value: 'OTHER' },
      ];
    }
  }
}
