<form [formGroup]="addressForm" novalidate="" class="com-model" autocomplete="off">
  <div class="com-outreach-modal">
    <div class="mb-2">
      <span class="title">Contacts</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-4 mt-4">
      <span class="com-title">{{ title }}</span>
    </div>
    <div class="mt-2">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Country <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <ng-autocomplete
              #ngAutoCompleteStatic
              placeholder="Add country"
              #country
              formControlName="country"
              (selected)="selectCourty($event)"
              [searchKeyword]="'name'"
              [data]="addressData.countries"
              [customFilter]="filterByNameAndCode"
              keyProperty="code"
              [itemTemplate]="stateTemplate"
              [notFoundTemplate]="notFoundTemplate"
            >
            </ng-autocomplete>
            <div class="mt-1" [hidden]="addressForm.controls.country.valid">
              <!-- <small class="text-danger">Enter country</small> -->
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">State, province, or territory <span style="color: red">*</span></label>
            </div>
            <ng-autocomplete
              #ngAutoCompleteStatic
              [data]="addressData.states"
              formControlName="state"
              (selected)="selectState($event)"
              [searchKeyword]="'name'"
              [customFilter]="filterByNameAndCode"
              [itemTemplate]="stateTemplate"
              [notFoundTemplate]="notFoundTemplate"
            >
            </ng-autocomplete>
            <div class="mt-1" [hidden]="addressForm.controls.state.valid">
              <!-- <small class="text-danger">Enter state, province, or territory</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">City <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input
              type="text"
              name="city"
              class="form-control"
              formControlName="city"
              placeholder="Add city"
              autocomplete="off"
            />
            <div class="mt-1" [hidden]="addressForm.controls.city.valid">
              <!-- <small class="text-danger">Enter city</small> -->
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Postal code <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input
              type="text"
              name="postal_code"
              formControlName="postalCode"
              placeholder="Add postal code"
              class="form-control"
              autocomplete="off"
            />
            <div class="mt-1" [hidden]="addressForm.controls.postalCode.valid">
              <!-- <small class="text-danger">Enter postal code</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Address line 1 <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <input
              type="text"
              name="address1"
              formControlName="address1"
              placeholder="Add address"
              class="form-control"
              autocomplete="off"
            />
            <div class="mt-1" [hidden]="addressForm.controls.address1.valid">
              <!-- <small class="text-danger">Enter street name</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label class="com-label">Address line 2</label>
            <input
              type="text"
              formControlName="address2"
              placeholder="Add address"
              class="form-control"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label">Address type <span style="color: red">*</span></label>
              <span class="mb-1">(Required)</span>
            </div>
            <ng-select
              class="inputfontsize"
              placeholder="Select"
              [items]="addressData.addressTypes"
              bindLabel="name"
              bindValue="name"
              formControlName="addressType"
            >
            </ng-select>
            <div class="mt-1" [hidden]="addressForm.controls.addressType.valid">
              <!-- <small class="text-danger">Select addess type</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-12">
          <div class="form-group form-check">
            <input
              type="checkbox"
              formControlName="isPrimary"
              id="isPrimary"
              class="form-check-input com-input-check"
            />
            <label for="isPrimary" class="com-label-primary">Primary</label>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn-container mt-0">
      <button class="btn-pop-cancel" type="button" (click)="cancel()">CANCEL</button>
      <button
        class="btn-pop-success"
        type="submit"
        (click)="ok()"
        [disabled]="!addressForm.valid || !addressForm.dirty"
      >
        {{ isEdit ? "SAVE" : "ADD" }}
      </button>
    </div>
  </div>
</form>

<ng-template #stateTemplate let-item>
  <a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>
