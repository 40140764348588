import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter, SearchFilters } from 'src/app/data/class';
import { Communication, Language } from 'src/app/data/model';
import { ContentGroupService } from 'src/app/services/content-group.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-commnunication-outreach-content-modal',
  templateUrl: './commnunication-outreach-content-modal.component.html',
  styleUrls: ['./commnunication-outreach-content-modal.component.scss'],
})
export class CommnunicationOutreachContentModalComponent implements OnInit {
  @Input() public communication: Communication;
  @Input() public contentGroup: any;
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  commContentGroupForm: FormGroup;
  languages: Language[];
  errorMsg: string;
  faTimes = faTimes;
  contentGroups: any;
  filter = new Filter();
  searchFilters: SearchFilters;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private contentGroupService: ContentGroupService,
    private responseHelperService: ResponseHelperService
  ) {}

  ngOnInit(): void {
    if (this.contentGroup) {
      this.commContentGroupForm = this.formBuilder.group({
        contentGroupId: [this.contentGroup.id, Validators.required],
      });
    } else {
      this.commContentGroupForm = this.formBuilder.group({
        contentGroupId: ['', Validators.required],
      });
    }

    var protocolIds = [];
    this.communication.communicationDeliveryProtocols.forEach((communicationDeliveryProtocol) => {
      protocolIds.push(communicationDeliveryProtocol.deliveryProtocol.id);
    });
    this.contentGroupService.getContentGroupsByProtocols({ protocolIds: protocolIds }).subscribe((res: any) => {
      this.contentGroups = res.entity.aaData;
    });
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  ok(): void {
    const contentGroupId = this.commContentGroupForm.get('contentGroupId').value;
    this.contentGroupService
      .setContentGroupToCommunication(contentGroupId, this.communication.id)
      .subscribe((res: any) => {
        this.responseHelperService.success(
          this.contentGroup
            ? 'Content Group successfully changed'
            : 'Communication successfully added to Content Group ',
          true
        );
        this.successEvent.emit(contentGroupId);
      });
    () => {
      this.responseHelperService.error(this, this.errorMsg);
    };
  }
}
