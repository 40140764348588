import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter, SearchFilters } from '../../data/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { WorldCurrencyService } from '../../services/world-currency.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { WorldCurrency } from '../../data/model';
import { faPlus, faTimes, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { WorldCurrencyModalComponent } from '../world-currency-modal/world-currency-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-world-currencies-tab',
  templateUrl: './world-currencies-tab.component.html',
  styleUrls: ['./world-currencies-tab.component.scss'],
})
export class WorldCurrenciesTabComponent implements OnInit {
  searchFilters: SearchFilters;
  isLoading = false;
  lockedTables = false;
  lock: boolean;
  limit = 20;
  filter = new Filter();
  lengthToCompare: number;
  worldCurrencies: WorldCurrency[];
  show = false;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalHelper: NgbModal,
    private worldCurrencyService: WorldCurrencyService,
    private responseHelperService: ResponseHelperService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'world_currencies_search',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Currency',
          if: true,
          callback: () => {
            this.currencyModal();
          },
          title: 'New Currency',
        },
      ],
    };
    this.initFilter();
    this.show = true;
  }

  initNewSearch(): void {
    this.initFilter();
    this.getWorldCurrencies(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  currencyModal(id?: number): void {
    if (id) {
      this.worldCurrencyService.getCurrency(id).subscribe((data: any) => {
        if (data.success) {
          const instance = this.modalHelper.open(WorldCurrencyModalComponent);
          instance.componentInstance.currency = data.entity;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initFilter();
            this.getWorldCurrencies(false);
            instance.close();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(WorldCurrencyModalComponent);
      instance.componentInstance.successEvent.subscribe(() => {
        this.initFilter();
        this.getWorldCurrencies(false);
        instance.close();
      });
    }
  }

  deleteCurrency(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete product with ID: ' + id;
    instance.componentInstance.header = 'Delete product?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.worldCurrencyService.deleteCurrency(id).subscribe(
        (data: any) => {
          this.responseHelperService.success('Reward type successfully removed', false);
          this.initFilter();
          this.getWorldCurrencies(false);
          instance.close();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
    });
  }

  getWorldCurrencies(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.worldCurrencyService.getCurrencies(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.worldCurrencies = this.worldCurrencies.concat(data.entity);
        } else {
          this.worldCurrencies = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      (data: any) => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }
}
