<div class="middle-box text-center loginscreen">
  <div>
    <h3>Welcome to Icario Connect</h3>
    <h2>{{ loginBanner }}</h2>
    <form class="mt-2" role="form" ng-submit="submit()">
      <div class="form-group">
        <input
          type="text"
          id="username"
          class="form-control"
          placeholder="Username"
          required=""
          [(ngModel)]="username"
          name="username"
        />
      </div>
      <button type="submit" class="btn btn-primary block full-width mb-2" (click)="submit()">Send</button>
      <a (click)="login()"><small>Login</small></a>
    </form>
  </div>
</div>
