<div id="mobile-promotion" class="col-sm-12">
  <div class="image-points">
    <div class="mobile-image-container promo-image"></div>
    <div class="mobile-points" [hidden]="!currencyValue">
      <p><span [hidden]="hideDollarSign">$</span> {{ model.displayAmount }}</p>
      <p class="mobile-currency" [hidden]="!hideDollarSign">{{ model.displayCurrency }}</p>
    </div>
    <div class="mobile-reward-image" [hidden]="currencyValue">
      <img src="{{ model.displayRewardImageUrl }}" alt="Icon for non-currency award" />
    </div>
  </div>
  <div class="details">
    <p class="mobile-title">{{ model.title }}</p>
    <p class="mobile-subtitle">{{ model.subtitle }}</p>
    <p
      class="details-link"
      *ngIf="model.resultsIndicator"
      href="{{ model.resultsIndicator.resultsUrl }}"
      [hidden]="!model.resultsIndicator.showResults"
    >
      {{ model.resultsIndicator.resultsLabel }}
      <!-- TODO: -->
      <!-- <fa-icon [icon]="model.resultsIndicator.resultsIcon"></fa-icon> -->
    </p>
    <p class="details-link" (click)="showFullDetails()" [hidden]="!hideDetails">
      full details
      <fa-icon [icon]="faChevronUp"></fa-icon>
    </p>
    <button class="mobile-action" [hidden]="hideAllActions">
      <span [hidden]="!hidePromoAction">
        {{ model.videoHTML }}
      </span>
      <a href="{{ model.buttonUrl }}" [hidden]="hidePromoAction">{{ model.buttonText }}</a>
    </button>
  </div>
  <!-- TODO: -->
  <div class="mobile-description" [hidden]="hideDetails" [innerHtml]="model.description | stringToHtml"></div>
  <p id="mobile-disclaimer" [hidden]="hideDetails">
    {{ model.disclaimer }}
  </p>
  <div class="mobile-alternative-container" [hidden]="hideDetails" *ngIf="model.alternativesConfiguration?.displayLink">
    <span [hidden]="!model.alternativesConfiguration.displayLink">
      <a class="reasonable-alternative">
        {{ model.alternativesConfiguration.linkLabel }}
        Reasonable Alternative
      </a>
      &nbsp;
      <!-- TODO: -->
      <!-- <i class="fa fa-info-circle" tooltip="{{model.alternativesConfiguration.toolTip}}"
          tooltip-trigger="" tooltip-animation="false" tooltip-placement="bottom"></i> -->
      <fa-icon [icon]="faInfoCircle"></fa-icon>
    </span>
  </div>
  <div class="col-md-12 col-sm-12 toggle-up-container" [hidden]="hideDetails">
    <a type="button" class="float-right details-up-toggle" (click)="hideFullDetails()">
      <span class="sr-only">Collapse content</span>
      <fa-icon [icon]="faAngleUp" class="fa-angle-up mobile"></fa-icon>
    </a>
  </div>
</div>
<div id="desktop-promotion" class="promotion-container row w-100">
  <div class="col-sm-9">
    <div class="points" [hidden]="!currencyValue">
      <p class="desktop-amount"><span [hidden]="hideDollarSign">$</span>{{ model.displayAmount }}</p>
      <p class="desktop-currency" [hidden]="!hideDollarSign">{{ model.displayCurrency }}</p>
    </div>
    <div [hidden]="currencyValue" class="desktop-reward-image">
      <img src="{{ model.displayRewardImageUrl }}" alt="Icon for non-currency award" />
    </div>
    <div class="promotion-details">
      <img src="{{ model.imageUrl }}" class="hero-image" alt="image for {{ model.title }}" />
      <div class="promotion-title-and-subtitle">
        <h3>{{ model.title }}</h3>
        <div class="hidden-sm">{{ model.subtitle }}</div>
      </div>
      <a class="details-toggle mr-2" (click)="showFullDetails()" [hidden]="!hideDetails">
        full details
        <fa-icon [icon]="faChevronDown"></fa-icon>
      </a>
      <a
        class="results-button"
        href="model.resultsIndicator.resultsUrl"
        *ngIf="model.alternativesConfiguration?.displayLink"
        [hidden]="!model.resultsIndicator.showResults"
      >
        {{ model.resultsIndicator.resultsLabel }}
        <!-- TODO: -->
        <!-- <fa-icon [icon]="model.resultsIndicator.resultsIcon"></fa-icon> -->
      </a>
    </div>
    <div class="desktop-description" [hidden]="hideDetails">
      <div [innerHtml]="model.description | stringToHtml"></div>
      <p class="desktop-disclaimer">
        {{ model.disclaimer }}
      </p>
    </div>
    <div
      class="reasonable-alternative-container"
      [hidden]="hideDetails"
      *ngIf="model.alternativesConfiguration?.displayLink"
    >
      <span [hidden]="!model.alternativesConfiguration.displayLink">
        <a class="reasonable-alternative">
          {{ model.alternativesConfiguration.linkLabel }}
          Reasonable Alternative
        </a>
        <!-- TODO: -->
        <!-- <i class="fa fa-info-circle" tooltip="{{model.alternativesConfiguration.toolTip}}"
            tooltip-trigger="" tooltip-animation="false" tooltip-placement="bottom"></i> -->
        <fa-icon [icon]="faInfoCircle"></fa-icon>
      </span>
    </div>
  </div>
  <div class="col-sm-3 action-item" [hidden]="hideAllActions" *ngIf="model.buttonText">
    <span [hidden]="!hidePromoAction"> {{ model.videoHTML }} </span>
    <a class="promotion-action-item" href="{{ model.buttonUrl }}" [hidden]="hidePromoAction">{{ model.buttonText }}</a>
  </div>
  <div [hidden]="hideDetails" class="col-md-12 col-sm-12 toggle-up-container">
    <a (click)="hideFullDetails()" type="button" class="float-right">
      <span>Collapse content</span>
      <fa-icon [icon]="faAngleUp"></fa-icon>
    </a>
  </div>
</div>
