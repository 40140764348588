import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@iridium/iridium-ui/Button";
import { useEffect } from "react";

export type FilterDialogProps<T> = {
  formTestId?: string;
  submitButtonTestId?: string;
  filter: T;
  isDirty: boolean;
  title?: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  onFormSubmit: (filters: T) => void;
  renderInputs(params: { setPendingFilter: (filter: T) => void; pendingFilter: T });
};

function FilterDialog<T>({
  formTestId,
  submitButtonTestId,
  isModalOpen,
  onModalClose,
  onFormSubmit,
  filter,
  renderInputs,
  isDirty,
  title = "Apply Filters",
}: FilterDialogProps<T>): JSX.Element {
  const [pendingFilter, setPendingFilter] = React.useState<T>(filter);

  useEffect(() => {
    setPendingFilter(filter);
  }, [filter, isModalOpen]);

  function handleFormSubmit(event) {
    event.preventDefault();
    onFormSubmit(pendingFilter);
  }

  function resetStateAndClose() {
    setPendingFilter(filter);
    onModalClose();
  }

  return (
    <Dialog open={isModalOpen} onClose={onModalClose}>
      <form onSubmit={handleFormSubmit} data-testid={formTestId}>
        <DialogTitle>Apply filters</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            {renderInputs({
              setPendingFilter,
              pendingFilter,
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose} variant="outlined">
            Cancel
          </Button>
          <Button disabled={!isDirty} variant="contained" type="submit" data-testid={submitButtonTestId}>
            Apply
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FilterDialog;
