<form [formGroup]="integrationServerForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="name" />
          <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.name.valid">
            <small class="text-danger">Please input name</small>
          </div> -->
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Username <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>

          <input type="text" class="form-control" formControlName="username" />
          <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.username.valid">
            <small class="text-danger">Please input username</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="status" class="form-control">
            <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
          </select>
          <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div> -->
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>URL <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" class="form-control" formControlName="url" />
          <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.url.valid">
            <small class="text-danger">Please input URL</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Password <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>

          <input type="password" autocomplete="new-password" class="form-control" formControlName="password" />
          <div class="mt-sm" [hidden]="integrationServerForm.controls.password.valid">
            <small class="text-danger">{{
              integrationServerForm.controls.password.value.length !== 0
                ? "Password is required to be at least 5 characters long"
                : ""
            }}</small>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Repeat Password <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="password" class="form-control" formControlName="repeatPassword" />
          <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.repeatPassword.valid">
            <small class="text-danger">Please repeat password</small>
          </div> -->
          <div
            class="mt-sm"
            [hidden]="
              !integrationServerForm.controls.repeatPassword.value ||
              integrationServerForm.controls.password.value === integrationServerForm.controls.repeatPassword.value
            "
          >
            <small class="text-danger">Passwords do not match</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Protocol Type <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="protocolType" class="form-control">
            <option *ngFor="let option of protocolTypes" value="{{ option.value }}">{{ option.value }}</option>
          </select>
          <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.protocolType.valid">
            <small class="text-danger">Select protocol type</small>
          </div> -->
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Credential Type <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select formControlName="credentialType" class="form-control">
            <option *ngFor="let option of credentialTypes" value="{{ option.value }}">{{ option.value }}</option>
          </select>
          <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.credentialType.valid">
            <small class="text-danger">Select credential type</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>SSH Key <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <textarea formControlName="sshKey" class="form-control vertical-expand"></textarea>
      <!-- <div class="mt-sm" [hidden]="integrationServerForm.controls.sshKey.valid">
        <small class="text-danger">Please input SSH key</small>
      </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons
      (ok)="ok()"
      (cancel)="cancel()"
      [submitDisabled]="
        !integrationServerForm.valid ||
        integrationServerForm.controls.password.value !== integrationServerForm.controls.repeatPassword.value
      "
    >
    </app-modal-buttons>
    <!-- <button class="btn btn-dark-blue" type="submit" [disabled]="
        !integrationServerForm.valid ||
        integrationServerForm.controls.password.value !== integrationServerForm.controls.repeatPassword.value
      " (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button> -->
  </div>
</form>
