<form [formGroup]="sponsorForm" class="modalform" novalidate>
  <div class="d-flex justify-content-end mt-4 mr-4">
    <mat-icon (click)="cancel()" class="cursor-pointer">close</mat-icon>
  </div>
  <!-- <div class="modal-header">

  </div> -->
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="model-heading">{{ title }}</div>
    <hr />
    <div class="model-sub-heading">{{ isEdit ? "Edit market" : "Add new market" }}</div>
    <div class="form-group">
      <label>Name</label>
      <input type="text" class="form-control" formControlName="name" placeholder="Add name" />
      <!-- <div class="mt-2" [hidden]="sponsorForm.controls['name'].valid">
        <small class="text-danger">Input a Name</small>
      </div> -->
    </div>
    <div class="form-group">
      <label>Username</label>
      <input type="text" class="form-control" formControlName="username" />
      <div class="mt-2" [hidden]="sponsorForm.controls['username'].valid">
        <small class="text-danger">Input a Username</small>
      </div>
    </div>
    <!-- <div class="form-group">
      <label>URL</label>
      <input type="text" class="form-control" formControlName="url" />
      <div class="mt-2" [hidden]="sponsorForm.controls['url'].valid">
        <small class="text-danger">Input a URL</small>
      </div>
    </div> -->
    <!-- <div class="form-group">
      <label>Working Hours</label>
      <input type="text" class="form-control" formControlName="workingHours" />
    </div> -->
    <div class="form-group">
      <label>Year Start</label>
      <input type="date" class="form-control" formControlName="yearStart" placeholder="mm/dd/yyyy" />
    </div>
    <div class="form-group">
      <label>Status</label>
      <select formControlName="status" class="form-control">
        <option value="" disabled selected hidden>Select status</option>
        <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
      </select>
      <!-- <div class="mt-2" [hidden]="sponsorForm.controls['status'].valid">
        <small class="text-danger">Select status</small>
      </div> -->
    </div>
    <div class="form-group" *ngIf="!isEdit">
      <label>Segment</label>
      <ng-select
        [items]="filteredSegments"
        bindLabel="code"
        [multiple]="true"
        formControlName="segmentsCollection"
        [hideSelected]="true"
        [closeOnSelect]="false"
        placeholder="Select segment"
      >
      </ng-select>
      <div class="mt-2" [hidden]="sponsorForm.controls['segmentsCollection'].valid">
        <!-- <small class="text-danger">Select segments</small> -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">
      <!-- <fa-icon [icon]="faTimes"></fa-icon> -->
      CANCEL
    </button>
    <button class="btn-pop-success" type="submit" [disabled]="!sponsorForm.valid" (click)="ok()" title="Save">
      <!-- <fa-icon [icon]="faSave"></fa-icon> -->
      {{ isEdit ? "EDIT" : "ADD" }}
    </button>
  </div>
</form>
