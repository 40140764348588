import { Component, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../services/campaign.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() okButton: string;
  @Input() cancelButton: string;
  @Input() text: string;
  @Input() header: string;
  @Input() title: string;
  @Input() nametag: string;
  @Input() id: number;
  @Input() program: boolean;
  @Input() category: boolean;
  @Input() attribute: boolean;
  @Input() info: boolean;
  @Input() programspop: boolean;
  @Input() Segments: boolean;
  @Input() promotionTypepop: boolean;
  @Input() promotionpop: boolean;
  @Input() promotionEdit: boolean;
  @Input() importProduct: boolean;
  @Input() Strategy: boolean;
  @Input() closeOnCancel = true;
  @Input() dismissAllOnCancel: boolean = true;
  @Input() onlyConfirm: boolean = false;
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Output() public cancelEvent: EventEmitter<any> = new EventEmitter();
  errorMsg: string;
  constructor(
    private modalHelper: NgbModal,
    private campaignService: CampaignService,
    private responseHelperService: ResponseHelperService
  ) { }

  cancel(): void {
    if (this.closeOnCancel && this.dismissAllOnCancel) {
      this.modalHelper.dismissAll();
    } else if (!this.dismissAllOnCancel) {
      this.cancelEvent.emit();
    } else {
      this.successEvent.emit('cancel');
    }
  }
  confirmDelete() {
    this.successEvent.emit('success');
  }
}
