<div class="modal-header">
  <h1>{{ surveyName }}</h1>
</div>
<div class="modal-body">
  <div class="form-group">
    <ul class="list-group">
      <li class="list-group-item pt-0 pb-0">
        <div class="row">
          <div class="table mt-0">
            <table class="table table-pointable table-hover mb-0">
              <thead>
                <tr>
                  <th class="width-10 break-all">Type</th>
                  <th class="width-25">Question</th>
                  <th class="width-50">Answers</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let question of survey">
                  <td class="break-normal">{{ question.type }}</td>
                  <td class="break-normal">{{ question.question }}</td>
                  <td class="break-normal">{{ formatAnswers(question.answer) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-warning" type="button" (click)="cancel()">Close</button>
</div>
