import { Logger } from '../services/logging/logging.service';
import { UtilityService } from '../services/utility.service';

// These utilities provide a wrapper for infinite scroll logic, as seen on LiveAgentSurveys.

// UtilityService should really be a collection of exported functions, or a static class or something.
// Instantiating angular services directly like I am here should be avoided.
const utilityService = new UtilityService();

export interface InfiniteScrollPageFields {
  limit: number;
  offset: number;
}

export interface InfiniteScrollState<T, FilterT extends InfiniteScrollPageFields> {
  isLoading: boolean;
  canLoadMore: boolean;
  nextOffset: number;
  filter: FilterT;
  currentResultsFilter: FilterT;
  data: T[];
}

function getNextOffset<T, FilterT extends InfiniteScrollPageFields>(
  dataState: InfiniteScrollState<T, FilterT>,
  concatResults?: boolean
): number {
  return concatResults ? dataState.nextOffset : 0;
}

export function getInfiniteScrollNextFilter<T, FilterT extends InfiniteScrollPageFields>(
  dataState: InfiniteScrollState<T, FilterT>,
  concatResults?: boolean
): FilterT {
  return utilityService.removeNullAndEmptyProperties({
    ...dataState.filter,
    offset: getNextOffset(dataState, concatResults),
  });
}

export function getInfiniteScrollState<T, FilterT extends InfiniteScrollPageFields>(
  concatResults: boolean,
  newData: T[],
  currentResultsFilter: FilterT,
  dataState: InfiniteScrollState<T, FilterT>
): InfiniteScrollState<T, FilterT> {
  const canLoadMore = newData?.length === dataState.filter.limit;
  const nextOffset = currentResultsFilter.offset + newData?.length;
  if (concatResults) {
    newData = dataState.data.concat(newData);
  }
  return {
    ...dataState,
    canLoadMore,
    data: newData,
    nextOffset,
    isLoading: false,
    currentResultsFilter,
  };
}

export function logInfiniteScrollError<FilterT extends InfiniteScrollPageFields>(
  logger: Logger,
  filterQueryParams: FilterT,
  error: any
) {
  logger.error({
    message: `An error occurred while fetching the list`,
    data: {
      filterQueryParams,
      error,
    },
  });
}
