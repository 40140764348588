import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';
import { OutreachContent } from '../data/model';
import { Observable } from 'rxjs';
import { mapHttpObservableToPromise } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class OutreachContentService {
  contentUrl = this.globalData.baseUrl + 'content';
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getOutreachContensBycontentGroupName(contentGroupName: string, params: any = {}): Observable<any> {
    return this.http.get(this.contentUrl + '/group/' + contentGroupName, { params });
  }

  getOutreachContens(params: any = {}): Observable<any> {
    return this.http.get(this.contentUrl + '/group', { params });
  }

  deleteOutreachContent(id: number): Observable<any> {
    return this.http.delete(this.contentUrl + '/outreach-content/' + id);
  }

  createOrUpdateOutreachContent(outreachContent: OutreachContent): Observable<any> {
    return this.http.put(this.contentUrl + '/outreach-content', outreachContent);
  }

  getCommunicationContentsFromContentGroups = function (params: any = {}): any {
    return this.http.get(`${this.contentUrl}/groups/contents`, { params });
  };

  getCommunicationContentsAsPromise(params: any = {}) {
    return mapHttpObservableToPromise<OutreachContent[]>(this.getCommunicationContentsFromContentGroups(params));
  }

  createOrUpdateContentAsPromise(content: OutreachContent) {
    return mapHttpObservableToPromise(this.createOrUpdateOutreachContent(content));
  }
}
