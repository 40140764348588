import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { JobService } from '../services/job.service';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-filter-dialog',
  templateUrl: './add-filter-dialog.component.html',
  styleUrls: ['./add-filter-dialog.component.scss'],
})
export class AddFilterDialogComponent implements OnInit {
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  @Input() endpointTypeName: string;
  @Input() commStrategyConfigurationId: number;
  @Input() ordernum: number;
  @Input() filterIds: number[] = [];
  title: string;
  filterForm: FormGroup;
  errorMsg = '';
  jobs: any[];
  faTimes = faTimes;

  constructor(
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private jobService: JobService,
    private communicationStrategyService: CommunicationStrategyService,
    private responseHelper: ResponseHelperService
  ) {}

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      jobInfoId: [null, Validators.required],
    });
    this.jobService.getJobs({ jobSelector: 'F' }).subscribe((data: any) => {
      if (data.success) {
        this.jobs = data.entity.filter((job: any) => !this.filterIds.includes(job.id));
      }
    });
  }

  ok(): void {
    this.communicationStrategyService
      .addFilterToStrategy(this.commStrategyConfigurationId, {
        ...this.filterForm.value,
        endpointTypeName: this.endpointTypeName,
        commStrategyConfigurationId: this.commStrategyConfigurationId,
        ordernum: this.ordernum,
      })
      .subscribe(
        (data: any) => {
          this.successEvent.emit(data);
          this.responseHelper.success('Filter added successfully!', true);
        },
        (data: any) => {
          this.errorMsg = data.error.error;
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
