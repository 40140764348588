import { ErrorHandler } from '@angular/core';
import getLogger from '../services/logging/logging.service';

const logger = getLogger({ namespace: 'AppErrorHandler' });

class AppErrorHandler implements ErrorHandler {
  handleError(error) {
    logger.error({ message: error });
  }
}

export default AppErrorHandler;
