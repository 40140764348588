<form [formGroup]="identifierTypeForm" novalidate>
    <div class="d-flex justify-content-end mt-4 mr-4">
        <mat-icon (click)="cancel()" class="cursor-pointer">close</mat-icon>
    </div>
    <div class="modal-header">
        <h1>{{ title }}</h1>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label>Access role <span style="color: red">*</span></label>
                        <span class="mb-1">(Required)</span>
                    </div>
                    <select name="accessRole" class="form-control" formControlName="roleScope">
                        <option *ngFor="let accessRole of accessRolesList" value="{{ accessRole.roleName }}">
                            {{accessRole.description}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label>Identifier type <span style="color: red">*</span></label>
                        <span class="mb-1">(Required)</span>
                    </div>
                    <ng-select [items]="identifierTypesList" [(ngModel)]="selectedIdentifierTypes" bindValue="code" bindLabel="code" [multiple]="true"
                        formControlName="cfgValue" [hideSelected]="true" [closeOnSelect]="false" [clearable]="true">
                        <ng-template ng-header-tmp>
                            <div>
                                <button class="btn btn-primary font-weight-bold" (click)="onSelectAll()">Select All</button>
                                <button class="btn btn-secondary font-weight-bold" (click)="onClearAll()">Clear All</button>
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-primary font-weight-bold mr-2" type="button" (click)="cancel()" title="Cancel">
            CANCEL
        </button>
        <button class="btn btn-primary font-weight-bold" type="submit" [disabled]="!identifierTypeForm.valid"
            (click)="ok()" title="Save">
            {{ isEdit ? "EDIT" : "ADD" }}
        </button>
    </div>
</form>