import { CallFlow, ExpectedResponse, Instruction } from '../models/model';
import { Edge as ReactFlowEdge } from 'react-flow-renderer';
import {
  applyPositionsToReactFlowElements,
  createReactFlowEdge,
  createReactFlowNode,
  ReactFlowInstruction,
} from './react-flow.service';
import { ReactFlowElementsWithCallFlowNodeData } from '../CallFlowBuilder';

type MapInstructionToReactFlowElementsParams = {
  instructions: Instruction[];
  inputInstructionIdList?: string[];
  hiddenEdgeList: string[];
};

export type ReactFlowElement = (ReactFlowInstruction | ReactFlowEdge)[];

export type CallFlowNodeData = { label: string; instruction: Instruction };

export function mapInstructionsToReactFlowElements({
  instructions,
  hiddenEdgeList,
}: MapInstructionToReactFlowElementsParams): ReactFlowElementsWithCallFlowNodeData {
  return instructions.reduce((prevElements, instruction) => {
    const newElements = [...prevElements];
    const { nextInstructionId, id: instructionId, expectedResponses } = instruction;

    const node = createReactFlowNode({
      id: instructionId,
      label: instructionId,
      instruction,
    });
    newElements.push(node);

    if (nextInstructionId && !hiddenEdgeList.includes(nextInstructionId)) {
      newElements.push(
        createReactFlowEdge({
          id: instructionId,
          source: instructionId,
          target: nextInstructionId,
        })
      );
    }

    if (expectedResponses) {
      const expectedResponseReactFlowEdgeList = expectedResponses
        .filter((expectedResponse) => !hiddenEdgeList.includes(expectedResponse.nextInstructionId))
        .map((filteredExpectedResponse) => {
          const { id: expectedResponseId, nextInstructionId } = filteredExpectedResponse;
          return createReactFlowEdge({
            id: expectedResponseId,
            target: nextInstructionId,
            source: instructionId,
          });
        });
      newElements.push(...expectedResponseReactFlowEdgeList);
    }

    return newElements;
  }, [] as ReactFlowElement);
}

export function mapInstructionsToReactFlowElementsAndPosition(
  params: MapInstructionsToReactFlowElementsAndPositionParams
): ReactFlowElementsWithCallFlowNodeData {
  const { instructions, hiddenEdgeList } = params;
  const reactFlowElements = mapInstructionsToReactFlowElements({
    instructions,
    hiddenEdgeList,
  });
  return applyPositionsToReactFlowElements({ elements: reactFlowElements });
}

type MapInstructionsToReactFlowElementsAndPositionParams = {
  instructions: Instruction[];
  hiddenEdgeList: string[];
};

export function mapCallFlowToReactFlowElements(
  callFlow: CallFlow,
  hiddenEdgeList: string[]
): ReactFlowElementsWithCallFlowNodeData {
  return mapInstructionsToReactFlowElementsAndPosition({
    instructions: callFlow.instructions,
    hiddenEdgeList: hiddenEdgeList,
  });
}

export type InstructionIdExpectedResponseViewDataMap = {
  instructionId: string;
  expectedResponseViewDataList: ExpectedResponseViewData[];
};

export type ExpectedResponseViewData = {
  id: string;
  tags: string[];
  // Some expected responses must have no tags, while others require that tags be present.
  tagValidationType: TagValidationType;
  areTagsValid: boolean;
};

const expectedResponseIdsThatMustNotHaveTags = ['IDV', 'AMD', 'INBOUND', 'OUTBOUND'];
const expectedResponseIdsThatMustHaveTags = ['Q'];

enum TagValidationType {
  MUST_HAVE_TAGS = 'MUST_HAVE_TAGS',
  MUST_NOT_HAVE_TAGS = 'MUST_NOT_HAVE_TAGS',
  NO_VALIDATION = 'NO_VALIDATION',
}

function getTagValidationType(params: { expectedResponseId: string }): TagValidationType {
  const { expectedResponseId } = params;
  if (!!expectedResponseIdsThatMustHaveTags.find((idQuery) => expectedResponseId.includes(idQuery))) {
    return TagValidationType.MUST_HAVE_TAGS;
  }
  if (!!expectedResponseIdsThatMustNotHaveTags.find((idQuery) => expectedResponseId.includes(idQuery))) {
    return TagValidationType.MUST_NOT_HAVE_TAGS;
  }
  return TagValidationType.NO_VALIDATION;
}

function getTagsValidity(params: { tags: string[]; tagValidationType: TagValidationType }) {
  const { tags, tagValidationType } = params;
  const hasTags = tags.length > 0;
  return (
    (tagValidationType === TagValidationType.MUST_HAVE_TAGS && hasTags) ||
    (tagValidationType === TagValidationType.MUST_NOT_HAVE_TAGS && !hasTags)
  );
}

export type LeadingInstructionData = {
  leadingInstructionId: string;
  leadingExpectedResponseList: ExpectedResponse[];
};

export type WavInstructionData = {
  language: string;
  theme: string;
  audioFile?: string;
};

export function getLeadingInstructionData(
  selectedInstruction: Instruction,
  callFlow: CallFlow
): LeadingInstructionData[] {
  return callFlow.instructions
    .map((instruction) => {
      let leadingExpectedResponseList: ExpectedResponse[] = [];
      if (instruction.expectedResponses) {
        leadingExpectedResponseList = instruction.expectedResponses.filter((expectedResponse) => {
          return expectedResponse.nextInstructionId === selectedInstruction.id;
        });
      }
      return {
        leadingInstructionId: instruction.id,
        leadingExpectedResponseList: leadingExpectedResponseList,
      };
    })
    .filter(({ leadingExpectedResponseList }) => leadingExpectedResponseList.length > 0);
}

function getInstructionIdExpectedResponseMap(instruction: Instruction): InstructionIdExpectedResponseViewDataMap {
  const { id, expectedResponses } = instruction;
  let expectedResponseViewDataList: ExpectedResponseViewData[] = [];
  if (expectedResponses) {
    expectedResponseViewDataList = expectedResponses.map((expectedResponse) => {
      const { id: expectedResponseId, platformMetaData } = expectedResponse;
      const { tags } = platformMetaData;
      const tagValidationType = getTagValidationType({ expectedResponseId });
      return {
        id: expectedResponse.id,
        tagValidationType: getTagValidationType({ expectedResponseId }),
        areTagsValid: getTagsValidity({
          tagValidationType,
          tags,
        }),
        tags,
      };
    });
  }
  return {
    instructionId: id,
    expectedResponseViewDataList,
  };
}

export type OptOutInstructionData = {
  instruction?: Instruction;
  isOptOutResponseIdValid: boolean;
  isOptOutInstructionTypeValid: boolean;
};
export type InstructionViewData = {
  tags: string[];
  instructionIdExpectedResponseMap: InstructionIdExpectedResponseViewDataMap[];
  optOutInstructionData: OptOutInstructionData;
  validatedExpectedResponseViewDataList: ExpectedResponseViewData[];
};

function getAllTagsFromInstruction({ expectedResponses, platformMetaData }: Instruction) {
  const usedTags: string[] = [...platformMetaData.tags];

  if (expectedResponses) {
    const expectedResponseTags: string[] = expectedResponses.flatMap(
      (expectedResponse) => expectedResponse.platformMetaData.tags
    );
    usedTags.push(...expectedResponseTags);
  }

  return usedTags;
}

interface GetInstructionViewDataParams {
  instructionList: Instruction[];
}

function getInitialInstructionViewData(): InstructionViewData {
  return {
    tags: [],
    instructionIdExpectedResponseMap: [],
    validatedExpectedResponseViewDataList: [],
    optOutInstructionData: {
      isOptOutResponseIdValid: false,
      isOptOutInstructionTypeValid: false,
      instruction: undefined,
    },
  };
}

export const OPT_OUT_INSTRUCTION_TYPE: string = 'OPT_OUT';
export const OPTOUT_INSTRUCTION_ID: string = 'OPTOUT';
export const OPTOUTYES_RESPONSE_ID: string = 'OPTOUTYES';

/**
 * Handles gathering data from the instructionList for our immediate rendering needs. If data is only needed after
 * selecting an instruction consider doing that when an instruction is selected.
 */
export function getInstructionViewData({ instructionList }: GetInstructionViewDataParams): InstructionViewData {
  return instructionList.reduce((prevValue, instruction) => {
    const instructionIdExpectedResponseMap = getInstructionIdExpectedResponseMap(instruction);
    const tags = getAllTagsFromInstruction(instruction);
    const validatedExpectedResponseViewData = instructionIdExpectedResponseMap.expectedResponseViewDataList.filter(
      (expectedResponseViewData) => expectedResponseViewData.tagValidationType !== TagValidationType.NO_VALIDATION
    );

    let optOutInstructionData: OptOutInstructionData = prevValue.optOutInstructionData;
    if (instruction.id === OPTOUT_INSTRUCTION_ID) {
      optOutInstructionData = {
        instruction,
        ...validateOptOutInstruction(instruction),
      };
    }

    return {
      tags: prevValue.tags.concat(tags),
      instructionIdExpectedResponseMap: prevValue.instructionIdExpectedResponseMap.concat(
        instructionIdExpectedResponseMap
      ),
      optOutInstructionData,
      validatedExpectedResponseViewDataList: prevValue.validatedExpectedResponseViewDataList.concat(
        validatedExpectedResponseViewData
      ),
    };
  }, getInitialInstructionViewData());
}

export function validateOptOutInstruction(instruction: Instruction) {
  return {
    isOptOutResponseIdValid: instruction.optOutResponseId === OPTOUTYES_RESPONSE_ID,
    isOptOutInstructionTypeValid: instruction.instructionType === OPT_OUT_INSTRUCTION_TYPE,
  };
}

export type WavDataFromInstruction = {
  audioFile?: string;
  language: string;
  theme: string;
};

export function wavFromSelectedInstructionId(instruction: Instruction): WavDataFromInstruction[] {
  const instructionVariantList = instruction.instructionVariants;
  const wavDataFromInstruction = instructionVariantList?.flatMap((instructionVariant) => {
    return instructionVariant.prompts
      .filter((prompt) => !!prompt.audioFile)
      .map((prompt) => ({
        language: instructionVariant.language,
        theme: instructionVariant.theme,
        audioFile: prompt.audioFile,
      }));
  });

  return wavDataFromInstruction || [];
}

export async function getCallFlow(callFlowId: string): Promise<CallFlow> {
  const result = await fetch(`https://api.revel-health.com/channel-service/voice/callflow/${callFlowId}`);
  return result.json();
}

export function mapReactFlowToCallFlowInstructions(params: { elements: ReactFlowElement }): Instruction[] {
  const element = params.elements.filter((element) => element.type);
  return element.map((element) => element.data.instruction);
}
