import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faTimes, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-behavior-code-form',
  templateUrl: './behavior-code-form.component.html',
  styleUrls: ['./behavior-code-form.component.scss'],
})
export class BehaviorCodeFormComponent implements OnInit {
  @Input() behaviorCodeFormModel: any;
  @Input() index: any;
  @Output() behaviorCodeEvent = new EventEmitter();
  behaviorCodeForm: FormGroup;
  originalBehaviorCode: string;
  faTimes = faTimes;
  faPlus = faPlus;
  faSave = faSave;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.index !== null) {
      this.behaviorCodeForm = this.formBuilder.group({
        behaviorCode: this.behaviorCodeFormModel,
      });
      this.originalBehaviorCode = this.behaviorCodeFormModel;
    } else {
      this.behaviorCodeForm = this.formBuilder.group({
        behaviorCode: [''],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.behaviorCodeForm.value;
    this.behaviorCodeEvent.emit({ type, index, value });
    if (type === 'add') {
      this.behaviorCodeForm.reset();
    }
  }

  resetEdit(): void {
    this.behaviorCodeForm.controls.promotionCode.setValue(this.originalBehaviorCode);
  }
}
