<form [formGroup]="communicationStrategyConfigurationForm" class="com-model" novalidate>
  <div class="com-strategy-modal">
    <div class="mb-3 mt-3">
      <span class="com-strategy">Strategy</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-3">
      <span class="com-title">{{ title }}</span>
    </div>
    <div class="">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label class="com-label"
                >Delivery window</label
              >

              <span class="mb-1">(Required)</span>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <ng-select
                  class="inputfontsize"
                  [items]="timeOptions"
                  bindValue="value"
                  formControlName="fromTime"
                  [clearable]="false"
                  [searchable]="false"
                  placeholder="Select window"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <div class="select-align-center">{{ item.label | date: "hh:mm a" }}</div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <div class="select-align-center">{{ item.label | date: "hh:mm a" }}</div>
                  </ng-template>
                </ng-select>
              </div>
              <!-- <div class="col-sm-2 hotizontal-line">
                <hr class="width-40" />
              </div> -->
              <div class="col-sm-6">
                <ng-select
                  class="inputfontsize"
                  [items]="timeOptions"
                  bindValue="value"
                  formControlName="toTime"
                  [clearable]="false"
                  [searchable]="false"
                  placeholder="Select window"
                >
                  <ng-template ng-label-tmp let-item="item">
                    <div class="select-align-center">{{ item.label | date: "hh:mm a" }}</div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item">
                    <div class="select-align-center">{{ item.label | date: "hh:mm a" }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="input-daterange input-group"></div>
            <div
              class="mt-2"
              [hidden]="
                communicationStrategyConfigurationForm.controls.fromTime.valid &&
                communicationStrategyConfigurationForm.controls.toTime.valid
              "
            >
              <!-- <small class="text-danger">Input delivery window</small> -->
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="com-label">Blackout periods </label>
        <ng-select
          class="inputfontsize"
          [items]="days"
          [multiple]="true"
          [hideSelected]="true"
          [closeOnSelect]="false"
          [clearable]="false"
          formControlName="blackOutPeriods"
          placeholder="Select periods"
        >
        </ng-select>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Opt-in/opt-out <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>
        <ng-select
          class="inputfontsize"
          [items]="optInOptOutOptions"
          bindLabel="text"
          bindValue="value"
          formControlName="optInOptOut"
          [hideSelected]="true"
          [clearable]="false"
          placeholder="Opt-in/opt-out"
        >
        </ng-select>
        <!-- <div class="mt-2" [hidden]="communicationStrategyConfigurationForm.controls.optInOptOut.valid">
          <small class="text-danger">Select Option</small>
        </div> -->
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label class="com-label">Endpoint type <span style="color: red">*</span></label>
          <span class="mb-1">(Required)</span>
        </div>
        <ng-select
          class="inputfontsize"
          [items]="endpointTypes"
          bindLabel="name"
          bindValue="name"
          formControlName="endpointTypeName"
          [hideSelected]="true"
          [clearable]="false"
          placeholder="Select endpoint type"
        >
        </ng-select>
        <!-- <div class="mt-2" [hidden]="communicationStrategyConfigurationForm.controls.endpointTypeName.valid">
          <small class="text-danger">Select type</small>
        </div> -->
      </div>
      <div class="form-group mb-4">
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <label class="com-label">Filters</label>
            <button
            class="btn btn-outline-primary add-camp"
            type="button"
            title="Add New Campaign Attribute"
            (click)="addFilter()"
            >
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>
          <div formArrayName="filters">
            <div
            class="row"
            *ngFor="let Addfilte of getaddfilter().controls; let i = index"
            [formGroupName]="i"
            >
              <div class="col-md-2">
                <div class="roundshape topgap float-left">
                  <span class="inner-circle">{{ i + 1 }}</span>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <ng-select
                    class="inputfontsize w-20"
                    [items]="jobs"
                    bindLabel="name"
                    bindValue="id"
                    [hideSelected]="true"
                    formControlName="jobInfoId"
                    [clearable]="false"
                    placeholder="Select filter"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2">
                <button
                class="btn btn-dark-blue btn-sm delete mt-2 float-right"
                (click)="removeFilter(i)"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
              
            </div>
          </div>    
        </div>
      </div>
      <div class="form-group mb-4">
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <label class="com-label">Communication caps</label>
            <button
              class="btn btn-outline-primary add-camp"
              type="button"
              (click)="addCommCap()"
              title="Add New Campaign Attribute"
            >
              <fa-icon [icon]="faPlus"></fa-icon>
            </button>
          </div>

          <div class="row" [hidden]="getCommCaps().controls.length === 0">
            <div class="col-md-5">
              <div>
                <label class="com-label">Cap</label>
              </div>
            </div>
            <div class="col-md-5">
              <div>
                <label class="com-label">Per period</label>
              </div>
            </div>
          </div>
          <div formArrayName="commCaps">
            <div class="row" *ngFor="let commCap of getCommCaps().controls; let i = index" [formGroupName]="i">
              <div class="col-md-5">
                <div class="form-group">
                  <input
                    type="number"
                    name="maxCommunicationsNum"
                    class="inputborder"
                    formControlName="maxCommunicationsNum"
                    placeholder="Cap"
                  />
                  <div class="mt-2" [hidden]="commCap.controls.maxCommunicationsNum.valid">
                    <!-- <small class="text-danger">Input communication cap</small> -->
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <select class="inputborder" formControlName="maxCommunicationsPeriod">
                  <option
                    [selected]="commCap.maxCommunicationsPeriod === option"
                    *ngFor="let option of period"
                    value="{{ option }}"
                  >
                    {{ option }}
                  </option>
                </select>
                <div class="mt-2" [hidden]="commCap.controls.maxCommunicationsPeriod.valid">
                  <!-- <small class="text-danger">Input communication period</small> -->
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group float-right">
                  <button class="btn btn-dark-blue btn-sm delete float-right" (click)="removeCommCap(i)">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pop-btn-container">
      <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
      <button
        class="btn-pop-success"
        type="submit"
        [disabled]="!communicationStrategyConfigurationForm.valid"
        (click)="ok()"
        title="Save"
      >
        {{ isEdit ? "SAVE" : "ADD" }}
      </button>
    </div>
  </div>
</form>
