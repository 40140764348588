<div class="ibox" *ngIf="tabIndex === 6 || tabIndex === 999">
  <ng-template #content let-modal>
    <div class="col-lg-12 com-model">
      <div class="form-horizontal" *ngIf="resize">
        <div class="model_container">
          <div class="model_heading">History</div>
          <div class="body_heading">Apply filters</div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="control-label">Party ID</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="partyId"
                    [(ngModel)]="partyId"
                    (change)="setFilter('partyId', partyId)"
                    placeholder="Add party ID"
                  />
                  <a
                    *ngIf="partyId"
                    class="btn bg-transparent button-inside-input closeBtn"
                    (click)="clearInput('partyId')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </a>
                </div>
              </div>
              <div class="form-group">
                <div class="control-label">First name</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="firstName"
                    [(ngModel)]="firstName"
                    (change)="setFilter('firstName', firstName)"
                    placeholder="Add name"
                  />
                  <a
                    *ngIf="firstName"
                    class="btn bg-transparent button-inside-input closeBtn"
                    (click)="clearInput('firstName')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </a>
                </div>
              </div>
              <div class="form-group">
                <div class="control-label">Last name</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="lastName"
                    [(ngModel)]="lastName"
                    (change)="setFilter('lastName', lastName)"
                    placeholder="Add name"
                  />
                  <a
                    *ngIf="lastName"
                    class="btn bg-transparent button-inside-input closeBtn"
                    (click)="clearInput('lastName')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </a>
                </div>
              </div>
              <div class="form-group">
                <div class="control-label">Party attribute name</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="pAttributeName"
                    [(ngModel)]="pAttributeName"
                    (change)="setFilter('pAttributeName', pAttributeName)"
                    placeholder="Add attribute name"
                  />
                  <a
                    *ngIf="pAttributeName"
                    class="btn bg-transparent button-inside-input closeBtn"
                    (click)="clearInput('pAttributeName')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </a>
                </div>
              </div>
              <div class="form-group">
                <div class="control-label">Party attribute value</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="pAttributeValue"
                    [(ngModel)]="pAttributeValue"
                    (change)="setFilter('pAttributeValue', pAttributeValue)"
                    placeholder="Add attribute value"
                  />
                  <a
                    *ngIf="pAttributeValue"
                    class="btn bg-transparent button-inside-input closeBtn"
                    (click)="clearInput('pAttributeValue')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </a>
                </div>
              </div>
              <div class="form-group">
                <div class="control-label">Segments</div>
                <div class="input-group">
                  <app-segment-filter
                    (filterSegments)="handleFilterSegments($event)"
                    style="width: 100%"
                    class="custom-droupdown"
                  ></app-segment-filter>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <div class="control-label">Keyword</div>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    name="keyword"
                    [(ngModel)]="keyword"
                    (change)="setFilter('keyword', keyword)"
                    placeholder="Add keyword"
                  />
                  <a
                    *ngIf="keyword"
                    class="btn bg-transparent button-inside-input closeBtn"
                    (click)="clearInput('keyword')"
                  >
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </a>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <div class="control-label">Outreach start</div>
                    <input
                      class="cursor-pointer form-control bg_icon"
                      (click)="aFromDate.toggle()"
                      (ngModelChange)="setFilter('activityFromDate', $event, true)"
                      placeholder="mm/dd/yyyy"
                      name="aFromDate"
                      id="aFromDate"
                      ngbDatepicker
                      #aFromDate="ngbDatepicker"
                      [(ngModel)]="activityFromDate"
                      [footerTemplate]="aFromTemplate"
                    />
                    <ng-template #aFromTemplate>
                      <div class="width-100 datePickerTemplate">
                        <button
                          class="buttonDateTemplate"
                          (click)="setFilter('activityFromDate', null); aFromDate.toggle()"
                        >
                          Clear
                        </button>
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-md-6">
                    <div class="control-label">Outreach end</div>
                    <input
                      class="cursor-pointer form-control bg_icon"
                      (click)="aToDate.toggle()"
                      placeholder="mm/dd/yyyy"
                      (ngModelChange)="setFilter('activityToDate', $event, true)"
                      name="aToDate"
                      ngbDatepicker
                      #aToDate="ngbDatepicker"
                      [(ngModel)]="activityToDate"
                      [footerTemplate]="aToTemplate"
                    />
                    <ng-template #aToTemplate>
                      <div class="width-100 datePickerTemplate">
                        <button
                          class="buttonDateTemplate"
                          (click)="setFilter('activityToDate', null); aToDate.toggle()"
                        >
                          Clear
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="control-label">Outreach created on dates</div>
                <div class="row">
                  <div class="col-md-6">
                    <input
                      class="cursor-pointer form-control bg_icon"
                      (click)="cFromDate.toggle()"
                      (ngModelChange)="setFilter('createdFromDate', $event, true)"
                      placeholder="mm/dd/yyyy"
                      name="cFromDate"
                      ngbDatepicker
                      #cFromDate="ngbDatepicker"
                      [(ngModel)]="createdFromDate"
                      [footerTemplate]="cFromTemplate"
                    />
                    <ng-template #cFromTemplate>
                      <div class="width-100 datePickerTemplate">
                        <button
                          class="buttonDateTemplate"
                          (click)="setFilter('createdFromDate', null); cFromDate.toggle()"
                        >
                          Clear
                        </button>
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-md-6">
                    <input
                      class="cursor-pointer form-control bg_icon"
                      (click)="cToDate.toggle()"
                      placeholder="mm/dd/yyyy"
                      (ngModelChange)="setFilter('createdToDate', $event, true)"
                      name="cToDate"
                      ngbDatepicker
                      #cToDate="ngbDatepicker"
                      [(ngModel)]="createdToDate"
                      [footerTemplate]="cThruTemplate"
                    />
                    <ng-template #cThruTemplate>
                      <div class="width-100 datePickerTemplate">
                        <button class="buttonDateTemplate" (click)="setFilter('createdToDate', null); cToDate.toggle()">
                          Clear
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="control-label">Communication name</div>
                <div class="">
                  <ng-select
                    class="inputfontsize"
                    [items]="communications"
                    bindLabel="name"
                    bindValue="name"
                    name="communicationName"
                    [(ngModel)]="communicationName"
                    (change)="setFilter('communicationName', communicationName)"
                    theme="bootstrap"
                    placeholder="Select communication name"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end; flex-direction: row; gap: 10px">
            <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">Cancel</button>
            <button type="submit" class="btn-pop-success" [disabled]="isLoading" (click)="initNewSearchHistory(true)">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="filters_container">
    <div class="search-input2">
      <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
      <mat-icon class="search">search</mat-icon>
    </div>
    <div class="right_container2">
      <button class="filter-btn" (click)="open(content)">FILTERS<mat-icon class="ml-2">filter_list</mat-icon></button>
    </div>
  </div>

  <!-- TABLE: -->

  <div style="margin-bottom: 10px">
    <mat-chip-list *ngIf="chipDisplay">
      <mat-chip class="matchips" (click)="clearAllChip()">
        <span>Clear filters</span>
      </mat-chip>
      <div *ngFor="let item of filter | keyvalue" style="display: flex">
        <mat-chip class="matchips" *ngIf="chipConditionalrendering(item.key)">
          <span>{{ item.key }}: {{ item.value }}</span>
          <mat-icon class="matcancel" matChipRemove (click)="removeChip(item.key)">cancel</mat-icon>
        </mat-chip>
      </div>
    </mat-chip-list>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div>
        <div class="row"></div>
        <div class="row">
          <table class="table table-pointable table-bordered" width="100%">
            <thead>
              <tr>
                <th class="table_field_name" style="padding-left: 2rem" (click)="test()">Name</th>
                <th class="table_field_name">Party ID</th>
                <th class="table_field_name table_trace_id">Trace ID</th>
                <th class="table_field_name">Delivery protocol</th>
                <th class="table_field_name">Communication date</th>
                <th class="table_field_name">Is delivered</th>
                <th class="table_field_name">Processing status</th>
              </tr>
            </thead>
            <tbody >
              <ng-container *ngFor="let c of commHistory | filter: searchText; let i = index">
                <tr (click)="expandedLookup[i] = !expandedLookup[i]">
                  <td>
                    <span *ngIf="!expandedLookup[i]" title="Expand to see content" style="padding: 20px">
                      <fa-icon [icon]="faChevronRight"></fa-icon>
                    </span>
                    <span *ngIf="expandedLookup[i]" title="Expand to see history" style="padding: 20px">
                      <fa-icon [icon]="faChevronDown"></fa-icon>
                    </span>

                    <span>{{ c.communicationName }}</span>
                  </td>
                  <td>{{ c.partyId }}</td>
                  <td>{{ c.traceId }}</td>
                  <td>{{ c.deliveryProtocol }}</td>
                  <td>{{ c.communicationTimestamp }}</td>
                  <td>{{ c.delivered }}</td>
                  <!-- <td>{{ c.processingStatus }}</td> -->
                  <td>
                    <!-- tooltip in this span-->
                    <span
                      [ngClass]="getBadgeclass(c.processingStatus)"
                      class="rounded-pill"
                      [attr.title]="c.errorMessage"
                    >
                      {{ c.processingStatus }}
                    </span>
                  </td>
                </tr>
                <tr class="additional-data" *ngIf="expandedLookup[i]">
                  <td colspan="5" class="pb-0 pt-0">
                    <table class="reward-log table table-pointable additional">
                      <tbody>
                        <tr class="align-text-left">
                          <td><b>Content:</b>{{ c.content }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </ng-container>
              <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list (onScroll)="getCommunicationsHistory(true)" [lock]="lock" [isLoading]="isLoading">
        </app-add-more-data-to-list>
      </div>
    </div>
  </div>

  <ng-template #footerTemplateToDateValue>
    <div class="width-100 datePickerTemplate">
      <button class="buttonDateTemplate" (click)="clearDateInput('toDateValue'); toDate.toggle()">Clear</button>
    </div>
  </ng-template>

  <ng-template #footerTemplateFromDateValue>
    <div class="width-100 datePickerTemplate">
      <button class="buttonDateTemplate" (click)="clearDateInput('fromDateValue'); fromDate.toggle()">Clear</button>
    </div>
  </ng-template>
</div>
