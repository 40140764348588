import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { RewardType, Currency } from '../../data/model';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { RewardTypeService } from '../../services/reward-type.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reward-type-modal',
  templateUrl: './reward-type-modal.component.html',
  styleUrls: ['./reward-type-modal.component.scss'],
})
export class RewardTypeModalComponent implements OnInit {
  @Input() rewardType: RewardType;
  @Input() currencies: Currency[];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  rewardTypeForm: FormGroup;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private statusHelperService: StatusHelperService,
    private rewardTypeService: RewardTypeService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.rewardType) {
      this.title = 'Edit Reward Type';
      this.rewardTypeForm = this.formBuilder.group({
        name: [this.rewardType.name, Validators.required],
        code: [this.rewardType.code, Validators.required],
        status: [this.rewardType.status, Validators.required],
        currencyId: [this.rewardType.currencyId, Validators.required],
        description: [this.rewardType.description],
        isExpiring: [this.rewardType.isExpiring],
        expiryMonths: [{ value: this.rewardType.expiryMonths, disabled: !this.rewardType.isExpiring }],
      });
    } else {
      this.title = 'New Reward Type';
      this.rewardTypeForm = this.formBuilder.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        status: ['', Validators.required],
        currencyId: ['', Validators.required],
        description: [''],
        isExpiring: [false],
        expiryMonths: [{ value: '', disabled: true }],
      });
    }

    this.onChangeIsExpiring();
  }

  onChangeIsExpiring(): void {
    this.rewardTypeForm.get('isExpiring').valueChanges.subscribe((isExpiring: boolean) => {
      if (isExpiring) {
        this.rewardTypeForm.get('expiryMonths').enable();
      } else {
        this.rewardTypeForm.get('expiryMonths').reset();
        this.rewardTypeForm.get('expiryMonths').disable();
      }
    });
  }

  ok(): void {
    this.rewardTypeService
      .createOdUpdateRewardType(this.rewardType && this.rewardType.id, this.rewardTypeForm.value)
      .subscribe(
        (data: any) => {
          this.responseHelperService.success('Reward type successfully updated', true);
          this.successEvent.emit();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
