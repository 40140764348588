<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-md">
            <h2 class="fullname">System Configuration</h2>
          </div>
        </div>
      </div>
      <div class="tabs-container">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Reward Types</a>
            <ng-template ngbNavContent>
              <app-reward-types-tab></app-reward-types-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Identifier Types</a>
            <ng-template ngbNavContent>
              <app-instrument-types-tab></app-instrument-types-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>Behaviors</a>
            <ng-template ngbNavContent>
              <app-behaviors-tab></app-behaviors-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink>Locations</a>
            <ng-template ngbNavContent>
              <app-locations-tab></app-locations-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink>Products</a>
            <ng-template ngbNavContent>
              <app-products-tab></app-products-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="6">
            <a ngbNavLink>World Currencies</a>
            <ng-template ngbNavContent>
              <app-world-currencies-tab></app-world-currencies-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="7">
            <a ngbNavLink>Job Management</a>
            <ng-template ngbNavContent>
              <app-job-management-tab></app-job-management-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="8">
            <a ngbNavLink>Integration Servers</a>
            <ng-template ngbNavContent>
              <app-integration-servers></app-integration-servers>
            </ng-template>
          </li>
          <li [ngbNavItem]="9">
            <a ngbNavLink>Integration Keys</a>
            <ng-template ngbNavContent>
              <app-integration-keys-tab></app-integration-keys-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="10">
            <a ngbNavLink>Attribute Mappings</a>
            <ng-template ngbNavContent>
              <app-attribute-mappings-tab></app-attribute-mappings-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="11">
            <a ngbNavLink>Contact Reasons</a>
            <ng-template ngbNavContent>
              <app-contact-reasons-tab></app-contact-reasons-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="12">
            <a ngbNavLink>Filters</a>
            <ng-template ngbNavContent>
              <app-filters-tab></app-filters-tab>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>
