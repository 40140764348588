<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <h2 class="fullname">Reports</h2>
          </div>
        </div>
      </div>
      <!-- <div class="tabs-container">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Reports available</a>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Reports results</a>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div> -->
      <mat-tab-group class="remove-border-bottom" animationDuration="0ms" (selectedTabChange)="tabClick($event)">
        <mat-tab label="Reports">
          <app-reports-available [isAdmin]="isAdmin"></app-reports-available>
        </mat-tab>
        <mat-tab label="Report results">
          <app-reports-results [isAdmin]="isAdmin"></app-reports-results>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
