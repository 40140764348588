<div class="panel-body">
  <div *ngIf="(isCSR && !isPartner) || isCSRManager">
    <div class="panel-body">
      <div class="row">
        <div class="col-md-12">
          <div class="ibox-tools form-group">
            <button class="btn btn-primary btn-sm" (click)="accountModal()" [hidden]="!isAdmin">
              <fa-icon [icon]="faCreditCard"></fa-icon> New account
            </button>
          </div>
          <h3>Member Accounts</h3>
        </div>
      </div>
      <div class="mt-5">
        <div class="col-md-12">
          <table class="table table-hover table-pointable table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Currency</th>
                <th>Balance</th>
                <th>Expiring</th>
                <th>Reward Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let acc of accounts" [hidden]="accounts.length === 0">
                <td>{{ acc.id }}</td>
                <td>{{ acc.currency }}</td>
                <td>{{ acc.balance || 0 | number: "1.2-2" }}</td>
                <td>{{ acc.amountExpiring || 0 | number: "1.2-2" }}</td>
                <td>{{ acc.rewardType.name }}</td>
                <td class="status-editor">
                  <app-status-select
                    [type]="type"
                    [selectedModel]="acc"
                    [permission]="globalData.isCSRManager && securityService.allowStatusUpdates"
                  ></app-status-select>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row" [hidden]="!(accounts.length === 0)">
            <div class="col-md-12 mb-1-md text-center">No data available in table</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-body mt-2">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12" *ngIf="show">
            <div class="full-height-scroll txn">
              <div class="row">
                <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
              </div>
              <div class="row table-program mb-1">
                <table class="table table-hover table-pointable table-bordered" width="100%">
                  <thead>
                    <tr>
                      <th (click)="toggleSort(0)">
                        ID
                        <app-sort-arrows [selectedColumn]="filter.column" [column]="0" [dir]="filter.dir">
                        </app-sort-arrows>
                      </th>
                      <th (click)="toggleSort(5)">
                        Amount
                        <app-sort-arrows [selectedColumn]="filter.column" [column]="5" [dir]="filter.dir">
                        </app-sort-arrows>
                      </th>
                      <th>Account</th>
                      <th>Activity Type</th>
                      <th (click)="toggleSort(14)">
                        Original Activity Date
                        <app-sort-arrows [selectedColumn]="filter.column" [column]="14" [dir]="filter.dir">
                        </app-sort-arrows>
                      </th>
                      <th (click)="toggleSort(4)">
                        Date
                        <app-sort-arrows [selectedColumn]="filter.column" [column]="4" [dir]="filter.dir">
                        </app-sort-arrows>
                      </th>
                      <th>More Detail</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let ach of accountHistory">
                      <td>{{ ach.id }}</td>
                      <td [ngStyle]="getStyle(ach.amount)">{{ ach.amount }}</td>
                      <td>{{ ach.rewardType.name }}</td>
                      <td>{{ ach.accountTxnSelector }}</td>
                      <td>{{ ach.originalActivityDateStr }}</td>
                      <td>{{ ach.txnDateTime | date: "yyyy-MM-dd HH:mm:ss" }}</td>
                      <td *ngIf="ach.accountTxnSelector === 'REWARD'" (click)="showDetails(ach, $event)">
                        <a href="" *ngIf="ach.promotion.name"> {{ ach.promotion.name }}</a>
                        <a href="" *ngIf="!ach.promotion.name">N/A </a>
                      </td>
                      <td *ngIf="ach.accountTxnSelector === 'REDEMPTION'" (click)="showDetails(ach, $event)">
                        <a href="" *ngIf="ach.orderId">{{ ach.orderId }}</a
                        ><a href="" *ngIf="!ach.orderId">N/A</a>
                      </td>
                      <td *ngIf="ach.accountTxnSelector === 'REVERSE'" (click)="showDetails(ach, $event)">
                        <a href="" *ngIf="ach.originalAccountTxnId">{{ ach.originalAccountTxnId }}</a
                        ><a *ngIf="!ach.originalAccountTxnId">N/A</a>
                      </td>
                      <td *ngIf="ach.accountTxnSelector === 'EXPIRY'" (click)="showDetails(ach, $event)">
                        <a href="">Note</a>
                      </td>
                      <td *ngIf="ach.accountTxnSelector === 'ADJUSTMENT'" (click)="showDetails(ach, $event)">
                        <a href="">Note</a>
                      </td>
                      <td *ngIf="!ach.accountTxnSelector" (click)="showDetails(ach)">
                        <a class="stretched-link">Not supported type</a>
                      </td>
                      <td *ngIf="ach.accountTxnSelector.toUpperCase() === 'REWARD'">
                        <span *ngIf="ach.reversedBy" title="Reversed By: {{ ach.reversedBy }} On: ach.reversedOnStr"
                          >Reversed By: {{ ach.reversedBy }} On: {{ ach.reversedOnStr }}</span
                        >
                        <span *ngIf="!ach.reversedBy"
                          ><a href="" (click)="confirmReversal(ach, $event)" *ngIf="canReverse">reverse</a></span
                        >
                      </td>
                      <td *ngIf="ach.accountTxnSelector.toUpperCase() !== 'REWARD'">
                        <span *ngIf="ach.accountTxnSelector === 'REDEMPTION'"
                          ><a
                            href=""
                            *ngIf="isProgramManager || securityService.allowReverse"
                            (click)="showAddress(ach.id, $event)"
                            >address</a
                          ></span
                        >
                      </td>
                    </tr>
                    <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
                  </tbody>
                </table>
              </div>
              <app-add-more-data-to-list
                (onScroll)="getAccountHistory(true)"
                [lock]="lock"
                [isLoading]="isLoading"
              ></app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
