<div>
  <form>
    <label>Transfer rewards from one reward type to another:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="transferRewards !== null">
        <div *ngFor="let transfer of transferRewards; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeConsequence(transfer)"
            title="Remove Consequence"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>From Reward Type</label>
              <select
                name="fromRewardType_{{ transfer.fromRewardType }}"
                class="form-control"
                [(ngModel)]="transfer.fromRewardType"
                (ngModelChange)="updateConsequences()"
              >
                <option *ngFor="let fromReward of rewardTypes" [value]="fromReward.code">{{ fromReward.code }}</option>
              </select>
            </div>
            <div class="form-group">
              <label>To Reward Type</label>
              <select
                name="toRewardType_{{ transfer.fromRewardType }}"
                class="form-control"
                [(ngModel)]="transfer.toRewardType"
                (ngModelChange)="updateConsequences()"
              >
                <option
                  *ngFor="let toReward of rewardTypes"
                  [selected]="transfer.toRewardType === toReward.code"
                  [value]="toReward.code"
                >
                  {{ toReward.code }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Only transfer for the current period (leave blank for any):</label>
              <select
                name="period_{{ transfer.period }}"
                class="form-control"
                [(ngModel)]="transfer.period"
                (ngModelChange)="updateConsequences()"
              >
                <option
                  *ngFor="let period of periods"
                  [selected]="transfer.period === period.value"
                  [value]="period.value"
                >
                  {{ period.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: transfer, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Promotions"
                >Only transfer rewards for promotions (comma separated list or blank for any):</a
              >
              <input
                class="form-control m-b"
                type="text"
                id="promotionCodes_{{ transfer.fromRewardType }}"
                name="promotionCodes"
                [(ngModel)]="transfer.promotionCodes"
                (ngModelChange)="updateConsequences()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Transfer Rewards
        </button>
      </div>
    </div>
  </form>
</div>
