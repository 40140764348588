import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Segment } from '../data/model';
import { PersonsService } from '../services/persons.service';
import { Subject } from 'rxjs';
import { AppDataService } from '../app-data.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-segment-tree-modal',
  templateUrl: './segment-tree-modal.component.html',
  styleUrls: ['./segment-tree-modal.component.scss'],
})
export class SegmentTreeModalComponent implements OnInit {
  @Input() class: string;
  @Input() ngbDropdownClass = 'btn-group filter';
  @Input() maxWidth;
  @Output() filterSegments: EventEmitter<any> = new EventEmitter(true);
  @Output() selectedSegmentChange = new EventEmitter();
  @Input() personId: number;
  @Input() allowMultiple: boolean;
  @Input() selectedCode: string;
  @Input() automaticFetch: boolean;
  segmentsFetched = false;
  filtered: Segment[];
  allSegments: Segment[];
  toFilter: number[] = [];
  selectedSegments: Segment[];
  toRemove: number[];
  toAdd: number[];
  query = '';
  classValue: string;
  subject: Subject<any> = new Subject<any>();
  faChevronDown = faChevronDown;

  constructor(private personsService: PersonsService, private appDataService: AppDataService) {
    this.subject.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      this.filtered = [];
      if (this.query && this.query.length > 2) {
        this.allSegments.forEach((segment: Segment) => {
          if (segment.code && segment.code.toUpperCase().indexOf(this.query.toUpperCase()) !== -1) {
            this.filtered.push(segment);
          }
        });
        for (let i = this.allSegments.length - 1; i >= 0; i--) {
          if (this.isParent(this.allSegments[i].id)) {
            const idx = this.getIdx(this.allSegments[i].id);
            this.filtered.splice(idx, 0, this.allSegments[i]);
          }
        }
      } else {
        this.filtered = [...this.allSegments];
      }
    });
  }

  isParent(id: number): boolean {
    let parent = false;
    let inArray = true;
    this.filtered.forEach((segment: Segment) => {
      if (segment.parentId === id) {
        parent = true;
      }
      if (segment.id === id) {
        inArray = false;
      }
    });
    return parent && inArray;
  }

  getIdx(segmentId: number): number {
    const indexs = [];
    this.filtered.forEach((seg: Segment, idx: number) => {
      if (seg.parentId === segmentId) {
        indexs.push(idx);
      }
    });
    return Math.min.apply(null, indexs);
  }

  ngOnInit(): void {
    this.filtered = [];
    this.toFilter = [];
    const self = this;

    this.classValue = this.class ? this.class + ' font-relative-width' : 'col-md-2 font-relative-width';

    if (this.automaticFetch) {
      this.getAllSegments();
    }
  }

  getClass(level: number): string {
    return 'level_' + level;
  }

  getAllSegments(): void {
    if (!this.segmentsFetched) {
      this.personsService
        .getPersonSegmentList(this.personId ? this.personId : this.appDataService.userId, false)
        .subscribe((data: any) => {
          if (data.status === 200) {
            this.allSegments = data.body.entity;
            this.filtered = data.body.entity;
            this.segmentsFetched = true;
            this.allSegments = this.personsService.levelSegments(data.body.entity);
            this.filtered = this.personsService.levelSegments(data.body.entity);
            if (this.selectedCode) {
              this.filtered.forEach((seg: Segment) => {
                if (seg.code === this.selectedCode) {
                  seg.checked = true;
                  this.selectedSegments = [];
                  this.selectedSegments.push(seg);
                }
              });
            }
          }
        });
    }
  }

  addSegments(model: Segment): void {
    if (!this.allowMultiple) {
      this.filtered.forEach((seg: Segment) => {
        if (seg.checked && seg.code !== model.code) {
          seg.checked = !seg.checked;
        }
      });
    }

    this.selectedSegmentChange.emit(model);
    if (!this.allowMultiple || this.selectedSegments === undefined) {
      this.selectedSegments = [];
    }
    this.selectedSegments.push(model);
  }

  clearAll(): void {
    if (this.allSegments) {
      this.filtered = this.allSegments;
      this.filtered.forEach((seg: Segment) => {
        if (seg.checked) {
          seg.checked = !seg.checked;
        }
      });
      this.toFilter = [];
      this.toRemove = [];
      this.toAdd = [];
      this.query = '';
      this.filterSegments.emit(this.toFilter);
      this.selectedSegments = null;
    }
  }

  applyFilter(query: string): void {
    this.subject.next(query);
  }
}
