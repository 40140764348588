<div class="full-width">
  <div class="row search-filter-header-top">
    <div class="col-md-6" [hidden]="!searchFilters.title">
      <h2>{{ searchFilters.title }}</h2>
    </div>
    <div class="col-md-6">
      <a class="float-right search-filter-clear full-width" (click)="searchFilters.clearAll()"
        ><span class="float-right">Clear all filters</span></a
      >
    </div>
  </div>
  <!--Segmentless checkbox is typically in the header-->
  <div class="row search-filter-header-bottom m-b">
    <div class="col-md-3">
      <div
        class="checkbox checkbox-info search-filter-segmentless"
        *ngIf="appDataService.isAdmin &amp;&amp; !appDataService.externalOrgCode &amp;&amp; searchFilters.segmentLessFilter.type === 'segmentless'"
      >
        <div class="row">
          <div class="col-md-1">
            <input
              id="{{ searchFilters.formName + '-includeSegmentless' | idAttribute }}"
              [(ngModel)]="searchFilters.segmentLessFilter.value"
              (change)="filterSegments(null)"
              type="checkbox"
            />
          </div>
          <div class="col-md-8 pl-0">
            <label for="{{ searchFilters.formName + '-includeSegmentless' | idAttribute }}"
              >Show only segment-less</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <button
        type="submit"
        class="btn btn-call-to-action float-right search-filter-apply"
        [disabled]="appDataService.isRequestInProgress"
      >
        <fa-icon [hidden]="!appDataService.isRequestInProgress" [icon]="faSpinner" class="rotate fa-fw"></fa-icon>
        <span [hidden]="!!appDataService.isRequestInProgress">Apply</span>
      </button>
      <button
        [hidden]="!appDataService.showProspective"
        (click)="searchFilters.addEntityButtons[0].callback()"
        class="btn btn-call-to-action float-right"
      >
        Create prospective member
      </button>
    </div>
  </div>
</div>
