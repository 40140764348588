<form [formGroup]="worldCurrencyForm">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Name <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" formControlName="name" class="form-control" />
          <!-- <div class="mt-sm" [hidden]="worldCurrencyForm.controls['name'].valid">
            <small class="text-danger">Input name</small>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Alpha Code <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" formControlName="alphaCode" class="form-control" />
          <!-- <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['alphaCode'].errors?.required">
            <small class="text-danger">Input alpha code</small>
          </div> -->
          <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['alphaCode'].errors?.pattern">
            <small class="text-danger">Alpha code must contain exactly 3 letters</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label>Numeric Code <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <input type="text" formControlName="numericCode" class="form-control" />
          <!-- <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['numericCode'].errors?.required">
            <small class="text-danger">Input numeric code</small>
          </div> -->
          <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['numericCode'].errors?.pattern">
            <small class="text-danger">Numeric code must contain exactly 3 digits</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Display Format</label>
          <input type="text" formControlName="displayFormat" class="form-control" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Description</label>
          <textarea formControlName="description" class="form-control vertical-expand"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!worldCurrencyForm.valid"></app-modal-buttons>
  </div>
</form>
