<div class="panel-body">
  <div class="des_container">
    <div class="market_logos">
      <img class="" src="../../../assets/images/Asset 19.png" class="market_logos_promotions" />
    </div>
    <div class="ml-2">
      <span class="font-weight-bold">Category:</span>&nbsp; Topics like wellness or prevention that individual
      promotions are grouped under
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-md-12 d-flex justify-content-end">
      <button
        class="btn btn-outline-primary font-weight-bold d-flex align-items-center mr-3 pl-3 pr-3"
        (click)="filterPopUp()"
      >
        FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
      </button>
      <button class="btn btn-primary" (click)="promotionCategoryModal()">ADD CUSTOMER</button>
    </div> -->
    <div class="filters_container">
      <div class="search-input2">
        <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
        <mat-icon class="search">search</mat-icon>
      </div>
      <div class="right_container2">
        <button class="filter-btn" (click)="filterPopUp()">FILTERS<mat-icon class="ml-2">filter_list</mat-icon></button>
        <button class="btn-add" (click)="promotionCategoryModal()" style="height: 36px">ADD CATEGORY</button>
      </div>
    </div>
    <app-filter-tags
      *ngIf="searchFilters"
      isPopup="true"
      [(filterButton)]="filterButton"
      [(searchFilters)]="searchFilters"
      [(appliedFilters)]="appliedFilters"
    ></app-filter-tags>
    <div class="col-md-12">
      <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
      <div class="row">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <!-- <th>ID</th> -->
              <!--<th>Market</th>-->
              <th>Name</th>
              <th>Sort</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pc of promotionCategories | filter: searchText">
              <!-- <td>{{ pc.id }}</td> -->
              <!--<td>
                <a routerLink="/markets/{{ pc.sponsorPartyId }}">{{ pc.sponsorName }}</a>
              </td>-->
              <td>{{ pc.name }}</td>
              <td>{{ pc.sort }}</td>
              <td>{{ pc.status }}</td>
              <td>
                <div class="actions inline-table float-left">
                  <a
                    class="btn btn-outline-primary btn-sm edit action"
                    (click)="promotionCategoryModal(pc)"
                    title="Edit"
                    ><fa-icon [icon]="faPencilAlt"></fa-icon
                  ></a>
                  <button
                    class="btn btn-outline-primary btn-sm delete action"
                    [disabled]="pc.status === 'DELETED'"
                    (click)="deletePromotionCategory(pc)"
                    title="Delete"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list
        (onScroll)="getPromotionCategories(true)"
        [lock]="lock"
        [isLoading]="isLoading"
      ></app-add-more-data-to-list>
    </div>
  </div>
</div>
