import React from "react";
import Grid from "@iridium/iridium-ui/Grid";
import Paper from "@iridium/iridium-ui/Paper";
import { Organization } from "../data/model";
import { DataCard as DataCardProps } from "./components/DataCard/DataCard";
import OrganizationTitleBar from "./components/OrganizationTitleBar";
import { OrganizationsTable } from "./components/OrganizationsTable/OrganizationsTable";
import CreateOrEditOrganizationModal, {
  CreateOrUpdateOrganizationFormFields,
} from "./components/OrganizationsModal/CreateOrEditOrganizationModal";
import OrganizationFilterDialog from "./OrganizationFilterDialog";
import { OrganizationListFilter } from "./Organizations";
import ContentWrapper from "../react/components/ContentWrapper";
import TableToolbar from "./components/OrganizationsTable/TableToolbar";
import DataCardContainer from "./components/DataCard/DataCardContainer";

export type OrganizationsViewProps = {
  childEntityLabel: string;
  onClearFilters: () => void;
  filter: OrganizationListFilter;
  organizationList: Organization[];
  organizationStatistics: DataCardProps[];
  parentOrganization: Organization;
  organizationBeingEdited: Organization;
  isCreateOrEditModalOpen: boolean;
  isFilterModalOpen: boolean;
  onCreatedOrEditModalClose: () => void;
  onFilterOpenModalClose: () => void;
  onParentOrganizationSelect: (organization: Organization) => void;
  onEditOrganization: (organization: Organization) => void;
  applyFilter: (params: OrganizationListFilter) => void;
  onSaveOrganization: (params: CreateOrUpdateOrganizationFormFields) => void;
  onAddOrganization: () => void;
  onSearchChange: (event) => void;
  onFilterOpen: () => void;
  isLoading: boolean;
  infiniteScrollRef: React.Ref<any>;
  hasNoResults: boolean;
  canLoadMore: boolean;
  setOrganizationPath: (path: string) => void;
  organizationPath: string;
};

function OrganizationsView({
  organizationList,
  organizationStatistics,
  parentOrganization,
  isCreateOrEditModalOpen,
  onCreatedOrEditModalClose,
  onSaveOrganization,
  onEditOrganization,
  onSearchChange,
  onParentOrganizationSelect,
  onAddOrganization,
  isLoading,
  infiniteScrollRef,
  hasNoResults,
  canLoadMore,
  applyFilter,
  isFilterModalOpen,
  onFilterOpenModalClose,
  onFilterOpen,
  filter,
  setOrganizationPath,
  organizationPath,
  organizationBeingEdited,
  onClearFilters,
  childEntityLabel,
}: OrganizationsViewProps): JSX.Element {
  return (
    <ContentWrapper>
      <Grid container component={Paper} p={3} pt={2}>
        <Grid item xs={12}>
          <OrganizationTitleBar
            onEditOrganization={onEditOrganization}
            parentOrganization={parentOrganization}
            parentEntityTypeName={parentOrganization ? parentOrganization.partyRoles[0].roleName : null}
          />
        </Grid>
        <Grid item xs={12} mt={2} mb={2.5}>
          <DataCardContainer organizationStatistics={organizationStatistics} />
        </Grid>
        <Grid item xs={12} mt={2}>
          <TableToolbar
            addButtonEntityName={childEntityLabel}
            onClearFilters={onClearFilters}
            setFilter={applyFilter}
            onFilterOpen={onFilterOpen}
            onAddOrganizationClick={onAddOrganization}
            onSearchChange={onSearchChange}
            filter={filter}
          />
          <OrganizationsTable
            canLoadMore={canLoadMore}
            infiniteScrollRef={infiniteScrollRef}
            isLoading={isLoading}
            organizationsList={organizationList}
            selectedOrganization={parentOrganization}
            onOrganizationSelect={onParentOrganizationSelect}
            onEditOrganization={onEditOrganization}
            setOrganizationPath={setOrganizationPath}
            organizationPath={organizationPath}
            entityTypeName={childEntityLabel}
          />
        </Grid>
        <CreateOrEditOrganizationModal
          childEntityLabel={childEntityLabel}
          isModalOpen={isCreateOrEditModalOpen}
          onModalClose={onCreatedOrEditModalClose}
          organizationBeingEdited={organizationBeingEdited}
          onSave={onSaveOrganization}
        />
        <OrganizationFilterDialog
          isModalOpen={isFilterModalOpen}
          onModalClose={onFilterOpenModalClose}
          onFormSubmit={(filter) => applyFilter(filter)}
          filter={filter}
        />
      </Grid>
    </ContentWrapper>
  );
}

export default OrganizationsView;
