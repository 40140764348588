<div>
  <form>
    <label>Restrict by Relationship to Location:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="relationshipToLocation !== null">
        <div
          *ngFor="let relationshipRestriction of relationshipToLocation; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeRestriction(attribRestriction)"
            title="Remove Restriction"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>Such that the member:</label>
              <select
                name="condition_{{ relationshipRestriction.relationshipName }}"
                class="form-control"
                [(ngModel)]="relationshipRestriction.condition"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let condition of conditions"
                  [selected]="relationshipRestriction.condition === condition.value"
                  [value]="condition.value"
                >
                  {{ condition.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>the relationship: </label>
              <select
                name="relationship_{{ relationshipRestriction.relationshipName }}"
                class="form-control"
                [(ngModel)]="relationshipRestriction.relationshipName"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let relationship of partyRelationships"
                  [selected]="relationshipRestriction.relationshipName === relationship.relationshipName"
                  [value]="relationship.relationshipName"
                >
                  {{ relationship.relationshipName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>from Party Role: </label>
              <select
                name="fromRole_{{ relationshipRestriction.relationshipName }}"
                class="form-control"
                [(ngModel)]="relationshipRestriction.fromPartyRole"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let role of partyRoles"
                  [selected]="relationshipRestriction.fromPartyRole === role.roleName"
                  [value]="role.roleName"
                >
                  {{ role.roleName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>to Party Role: </label>
              <select
                name="toRole_{{ relationshipRestriction.relationshipName }}"
                class="form-control"
                [(ngModel)]="relationshipRestriction.toPartyRole"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let role of partyRoles"
                  [selected]="relationshipRestriction.toPartyRole === role.roleName"
                  [value]="role.roleName"
                >
                  {{ role.roleName }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>to the location on the activity. </label>
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{ relationshipRestriction.relationshipName }}"
                >Message:</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="friendlyMessage_{{ relationshipRestriction.relationshipName }}"
                name="friendlyMessage"
                [(ngModel)]="relationshipRestriction.friendlyMessage"
                (ngModelChange)="updateRestrictions()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Party Relationship Restriction
        </button>
      </div>
    </div>
  </form>
</div>
