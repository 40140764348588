import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss'],
})
export class DocumentModalComponent {
  @Input() document: any;

  constructor(public dialogRef: MatDialogRef<DocumentModalComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
