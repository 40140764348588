import { UserPreferencesService } from './../../services/user-preferences.service';
import { SecurityService } from './../../services/security/security.service';
import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { ProcessingActivityLogService } from './../../services/processing-activity-log.service';
import { Filter, SearchFilters } from './../../data/class';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { AppDataService } from './../../app-data.service';
import { IntegrationsService } from './../../services/integrations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Integration } from 'src/app/data/model';
import {
  faExchangeAlt,
  faCheckSquare,
  faSpinner,
  faCalendar,
  faTimes,
  faFolderOpen,
  faCog,
  faSyncAlt,
  faCopy,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { GlobalEventService } from 'src/app/services/global-event.service';
import { SearchFilterComponent } from '../../search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-integration-stage-load',
  templateUrl: './integration-stage-load.component.html',
  styleUrls: ['./integration-stage-load.component.scss'],
})
export class IntegrationStageLoadComponent implements OnInit {
  selectedIntegrationId: number;
  integration: Integration;
  selectedIntegrationName: string;
  lockedTables = false;
  lock = false;
  lockRaw = false;
  lockedTablesRaw = false;
  isLoading = false;
  isLoadingRaw = false;
  rawDataSearchHappening = false;
  selectedCustomers: any[] = [];
  filteredLoadStatuses: any[] = [];
  select: any = {};
  filter = new Filter();
  filterRaw = new Filter();
  rawDataSearchFilter: any = {};
  limit = 20;
  statuses: any;
  customers: any[] = [];
  loads: any[] = [];
  lengthToCompare: number;
  filteredLoadTypes: any[] = [];
  selectedLoad: any;
  processingLogs: any;
  loadInstance: any;
  searchResults: any;
  fromDateValue: number;
  toDateValue: number;
  faExchangeAlt = faExchangeAlt;
  faCheckSquare = faCheckSquare;
  faSyncAlt = faSyncAlt;
  faSpinner = faSpinner;
  faCalendar = faCalendar;
  faCopy = faCopy;
  faTimes = faTimes;
  faFolderOpen = faFolderOpen;
  faFileImport = faFileImport;
  allowedAdvanced: any;
  appliedFilters: Filter[];
  filterButton: boolean = false;
  faCog = faCog;
  searchFilters: SearchFilters;
  searchFiltersRaw: SearchFilters;
  integrationId: number;
  showSearch = false;
  filterdata: SearchFilters;
  searchText: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchFiltersChild') searchFiltersChild: SearchFilterComponent;
  @ViewChild('searchFiltersRowChild') searchFiltersRowChild: SearchFilterComponent;
  helpers: any;

  constructor(
    private titleHelper: Title,
    private route: ActivatedRoute,
    private router: Router,
    private integrationsService: IntegrationsService,
    private globalData: AppDataService,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private processingActivityLogService: ProcessingActivityLogService,
    private responseHelperService: ResponseHelperService,
    private securityService: SecurityService,
    private utiltyService: UtilityService,
    private userPreferencesService: UserPreferencesService,
    private globalEventService: GlobalEventService,
    private toastr: ToastrService,
    private applyfilterService: ApplyFilterService,
    private modalHelper: NgbModal
  ) {}

  statusHelper = this.statusHelperService.getStatus('badge');
  isProgramManager = this.globalData.isProgramManager;
  isAdmin = this.globalData.isAdmin;
  convertImportStatus = this.statusHelperService.convertImportStatus;

  numColumns = this.isAdmin ? 7 : 6;

  ngOnInit(): void {
    this.getAllCustomers();
    this.searchFilters = {
      formName: 'integration-stage-load-search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('IMPORT'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
        {
          name: 'customer',
          type: 'array',
          placeholder: 'Customers...',
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'select',
          multiple: true,
          choices: this.customers,
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
      onClear: () => {
        this.searchFiltersRowChild.searchFilters.clearAll();
      },
    };

    this.searchFiltersRaw = {
      formName: 'integration-stage-load-search-raw-data',
      filters: [
        {
          name: 'search',
          placeholder: 'Search raw data...',
        },
        {
          name: 'fromDate',
          type: 'date',
          placeholder: 'From date',
        },
        {
          name: 'toDate',
          type: 'date',
          endDate: true,
          placeholder: 'To Date',
        },
      ],
      searchCallback: () => {
        this.initNewSearchRaw();
      },
      onClear: () => {
        this.searchFiltersChild.searchFilters.clearAll();
      },
    };
    this.titleHelper.setTitle('File loads');
    this.allowedAdvanced =
      typeof this.securityService.allowAdvancedIntegrationFeatures === 'function'
        ? this.securityService.allowAdvancedIntegrationFeatures
        : this.isProgramManager;
    this.initFilter();
    this.setStatuses();
    this.integrationId = +this.route.snapshot.paramMap.get('id');
    this.initNewSearch();
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    if (this.integrationId) {
      this.getIntegration(this.integrationId);
    }
  }

  initNewSearchRaw(): void {
    this.initFilterRaw();
    this.searchRawData();
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  initFilterRaw(): void {
    this.filterRaw.limit = 10;
    this.filterRaw.offset = 0;
    this.filterRaw.dir = 'desc';
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };
  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Integrations';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
    });
  }

  getIntegration(id: number): void {
    this.filter = this.getFilters(this.filter);
    this.integrationsService.getIntegration(id).subscribe(
      (data) => {
        if (data.success) {
          this.selectedIntegrationId = data.entity.id;
          this.integration = data.entity;
          this.selectedIntegrationName = data.entity.name;
          const dataToSend: any = {};
          dataToSend.data = { integrationName: this.selectedIntegrationName, id: this.selectedIntegrationId };
          dataToSend.toComponent = 'bread-crumbs';
          this.globalEventService.sendGlobalEvent(dataToSend);
          this.getLoads(false);
        }
      },
      () => {
        this.router.navigate(['integrations']);
      }
    );
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('IMPORT');
  }

  getAllCustomers(): void {
    this.integrationsService.getAllCustomers().subscribe((data) => {
      if (data.success) {
        this.customers = data.entity;
        this.showSearch = true;
        this.customers = data.entity.map((p) => ({
          value: p,
        }));
        this.searchFilters.filters[2].choices = this.customers;
      }
    });
  }

  getLoads(concat: boolean): void {
    this.isLoading = true;
    this.integrationsService.getLoads(this.selectedIntegrationId, this.filter).subscribe(
      (data) => {
        if (concat) {
          this.loads = this.loads.concat(data.entity);
        } else {
          this.loads = data.entity;
        }
        this.lengthToCompare = data.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  showLoad(e: any, load: any): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedLoad = load;
    this.processingActivityLogService.getProcessingLogs({ stageLoadId: this.selectedLoad.id }).subscribe((logs) => {
      this.processingLogs = logs.entity.aaData;
    });
    console.log(load, 'loadding');
  }

  isShownLoad(): boolean {
    if (this.selectedLoad !== undefined) {
      return true;
    }
    return false;
  }

  startImport(id: number): void {
    this.integrationsService.startImport(id).subscribe(
      (data) => {
        this.responseHelperService.success('Imported started ', false);
        this.loadInstance.rerender();
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  retryImport(id: number): void {
    this.integrationsService.retryImport(id).subscribe(
      (data) => {
        this.responseHelperService.success('Staged load marked for Retry', false);
        this.loadInstance.rerender();
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  startProcessing(id: number): void {
    this.processingActivityLogService.startProcessing(id).subscribe(
      (data) => {
        this.responseHelperService.success('Processing started', false);
        this.loadInstance.rerender();
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      }
    );
  }

  searchRawData(): void {
    this.filterRaw = this.searchFiltersRaw.getFilters(this.filterRaw);
    this.rawDataSearchHappening = false;
    if (this.filterRaw.search || this.filterRaw.fromDate || this.filterRaw.toDate) {
      this.isLoadingRaw = true;
      this.integrationsService.searchLoads(this.integrationId, this.filterRaw).subscribe((data) => {
        this.rawDataSearchHappening = true;
        if (data.success === true) {
          this.searchResults = data.entity;
          this.isLoadingRaw = false;
        }
      });
    }
  }
}
