import { Injectable } from '@angular/core';
import { AppDataService } from '../../app-data.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ResponseHelperService {
  constructor(
    private appDataService: AppDataService,
    private ngbModalService: NgbModal,
    private toastr: ToastrService
  ) {}

  success(msg: string, isModal?: boolean): void {
    if (msg) {
      this.toastr.success(msg);
      console.log(msg);
    } else {
      this.toastr.success('Default success message');
      console.log('Default success message');
    }

    if (isModal) {
      this.ngbModalService.dismissAll();
    }
  }

  error(scope: any, msg: string, popupFlag?: boolean): void {
    let message = 'Error in request';
    if (typeof msg === 'string') {
      message = msg;
    }

    if (Array.isArray(msg)) {
      message = message + ':\n';
      msg.forEach((each) => {
        message = message + each + '\n';
      });
    }

    if (popupFlag) {
      this.toastr.error(message);
    } else {
      scope.errorMsg = message;
    }
  }

  processResponse(res: any): any {
    this.appDataService.isRequestInProgress = false;
    return res;
  }

  processError(e: any): any {
    const msg = { status: '', statusText: '', errorMsg: '' };
    msg.status = e.status;
    msg.statusText = e.statusText;
    msg.errorMsg = e.data.error || 'Unknown Server Error';
    this.appDataService.isRequestInProgress = false;
    return msg;
  }

  setRequestInProgress(): void {
    this.appDataService.isRequestInProgress = true;
  }
}
