import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Filter, SearchFilters } from 'src/app/data/class';
import { AppDataService } from 'src/app/app-data.service';
import { PersonsService } from 'src/app/services/persons.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import { Attribute } from 'src/app/data/model';
import { CommunicationAttributeModalComponent } from 'src/app/communication-tabs/communication-attribute-modal/communication-attribute-modal.component';
import {
  faChevronDown,
  faChevronRight,
  faInfo,
  faPencilAlt,
  faStopwatch,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SecurityService } from 'src/app/services/security/security.service';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';

@Component({
  selector: 'app-communication-attributes-tab',
  templateUrl: './communication-attributes-tab.component.html',
  styleUrls: ['./communication-attributes-tab.component.scss'],
})
export class CommunicationAttributesTabComponent implements OnInit, OnChanges {
  @Input() commId: number;
  selectedAttr: Attribute;
  searchFilters: SearchFilters;
  attrs: Attribute[];
  attribProv: Attribute[];
  filter = new Filter();
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faInfo = faInfo;
  faPencilAlt = faPencilAlt;
  faStopwatch = faStopwatch;
  faTrash = faTrash;
  expandedLookup = [];
  disableActions: string;
  allowedToEditAttribs: boolean;

  constructor(
    public globalData: AppDataService,
    public appDataService: AppDataService,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private personsService: PersonsService,
    private promotionService: PromotionService,
    private securityService: SecurityService,
    private toastr: ToastrService,
    private userPreferencesService: UserPreferencesService
  ) {}

  ngOnInit(): void {
    this.allowedToEditAttribs = this.securityService.allowedToEditAttribs;

    this.attrs = [];
    this.searchFilters = {
      filters: [],
      formName: 'generic',
      buttonsWithText: false,
      showSearchFilters: false,
      searchCallback: () => {
        this.setAttributes();
      },
      addEntityButtons: [
        {
          icon: faPlus,
          title: 'Add Attribute',
          buttonText: 'Add Attribute',
          callback: () => {
            this.prepareAttributeModal();
          },
          if: this.appDataService.isCSRManager,
        },
      ],
    };
    this.setAttributes();
  }

  ngOnChanges(): void {
    this.setAttributes();
  }

  setAttributes(): void {
    this.communicationsService.getAttributes(this.commId).subscribe((attrs: any) => {
      if (attrs.success) {
        this.attrs = attrs.entity;
        this.attrs = this.attrs.sort((a: any, b: any) => b.id - a.id);
      }
    });
  }

  prepareAttributeModal(attribute?: Attribute): void {
    this.selectedAttr = null;
    if (attribute !== null && attribute !== undefined) {
      this.communicationsService.getAttribute(attribute.attrValueId).subscribe((res: any) => {
        if (res.success) {
          this.selectedAttr = res.entity;
        }
      });
    }
    this.openAttributeModal();
  }

  partyAttrModal(attr: Attribute): void {
    this.selectedAttr = attr;
    this.openAttributeModal();
  }

  openAttributeModal(): void {
    const instance = this.modalHelper.open(CommunicationAttributeModalComponent);
    instance.componentInstance.attr = this.selectedAttr;
    instance.componentInstance.commId = this.commId;
    instance.componentInstance.resourceType = 'communication';
    instance.componentInstance.successEvent.subscribe(() => {
      this.setAttributes();
      instance.close();
    });
  }

  formatDate(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + (d.getDate() - 1);
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  deleteAttribute(id: number, attrName: string): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete attribute with name: ' + attrName;
    instance.componentInstance.header = 'Delete Attribute';
    instance.componentInstance.successEvent.subscribe(() => {
      this.communicationsService.deleteAttribute(id).subscribe(() => {
        this.responseHelperService.success('Attribute successfully deleted');
        this.setAttributes();
        instance.close();
      });
    });
  }

  applyFilter(): void {
    this.setAttributes();
  }
}
