export function openWindow(url: string, target?: string, features?: string): void {
  if (window.navigator.userAgent.includes('iPad')) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    if (target) {
      a.target = target;
    }
    a.click();
    document.body.removeChild(a);
  } else {
    window.open(url, target, features);
  }
}
