import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { FilterService } from 'src/app/services/filter.service';
import { FilterModel } from 'src/app/data/model';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss'],
})
export class FilterModalComponent implements OnInit {
  @Input() filter: FilterModel;
  @Input() jobSelector: string;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  filterForm: FormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private statusHelperService: StatusHelperService,
    private filterService: FilterService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.filter) {
      this.title = 'Edit Filter';
      this.isEdit = true;
      this.filterForm = this.formBuilder.group({
        name: [this.filter.name, Validators.required],
        className: [this.filter.className, Validators.required],
        status: [this.filter.status, Validators.required],
        description: [this.filter.description],
        enabled: [this.filter.enabled],
      });
    } else {
      this.title = 'New Filter';
      this.isEdit = false;
      this.filterForm = this.formBuilder.group({
        name: ['', Validators.required],
        className: ['', Validators.required],
        status: ['', Validators.required],
        description: [''],
        enabled: [false],
      });
    }
  }

  ok(): void {
    let id;
    let value: FilterModel;
    if (this.isEdit) {
      id = this.filter.id;
      // On edit it is necessary to send params createdOn, createdBy, updatedOn, updatedetBy. That params are not in form
      value = { ...this.filter, ...this.filterForm.value };
    } else {
      value = this.filterForm.value;
    }
    value.jobSelector = this.jobSelector;
    this.filterService.createOrUpdateFilter(id, value).subscribe(
      (data: any) => {
        this.successEvent.emit(data);
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
