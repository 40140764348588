import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-manifest-segment-mapping-modal',
  templateUrl: './manifest-segment-mapping-modal.component.html',
  styleUrls: ['./manifest-segment-mapping-modal.component.scss'],
})
export class ManifestSegmentMappingModalComponent {
  @Input() segmentCode: number;
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();

  constructor(private modalHelper: NgbModal) {}

  ok(): void {
    this.successEvent.emit(this.segmentCode);
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  updateSelectedSegment(segment: any): void {
    if (segment.checked) {
      this.segmentCode = segment.code;
    } else {
      this.segmentCode = null;
    }
  }
}
