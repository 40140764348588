import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { PromotionType } from 'src/app/data/model';
import { PromotionTypeModalComponent } from 'src/app/promotion-type-modal/promotion-type-modal.component';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { PromotionTypeService } from 'src/app/services/promotion-type.service';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
@Component({
  selector: 'app-promotion-types-tab',
  templateUrl: './promotion-types-tab.component.html',
  styleUrls: ['./promotion-types-tab.component.scss'],
})
export class PromotionTypesTabComponent implements OnInit, OnDestroy {
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  tabs: any[];
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  filteredStatuses: Filter[] = [];
  promotionTypes: any[] = [];
  promotions: any[] = [];
  selectedCampaign: any;
  searchText: any;
  helpers: any;
  filterdata: SearchFilters;
  filterButton: boolean = false;
  appliedFilters: Filter[];
  showMoreButton: any = { name: 'Show More', active: true };
  fileNamePlease: any = { name: '', success: false };
  lockedTables: any = { state: false };
  promotionTypepop: boolean = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private toastr: ToastrService,
    private promotionTypeService: PromotionTypeService,
    private modalHelper: NgbModal,
    private title: Title,
    private applyfilter: ApplyFilterService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'campaignSearch',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Promotion Type',
          if: true,
          callback: () => {
            this.promotionTypeModal();
          },
          title: 'New Promotion Type',
        },
      ],
    };
    this.initNewSearch();
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getPromotionType(false);
  }

  initNewSearch(): void {
    this.applyfilter.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.initFilter();
    this.getPromotionType(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getPromotionType(false);
  }

  FilterModel() {
    const popUpInstance = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    popUpInstance.componentInstance.searchFilters = this.searchFilters;
    popUpInstance.componentInstance.isPopup = true;
    popUpInstance.componentInstance.heading = 'Type';
    popUpInstance.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
      this.filterButton = true;
    });
  }

  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  getPromotionType(concat: boolean): void {
    this.filter = this.getFilters(this.filter);
    this.isLoading = true;
    this.promotionTypeService.getAllPromotionTypes(this.filter).subscribe(
      (data) => {
        if (concat) {
          this.promotionTypes = this.promotionTypes.concat(data.entity.aaData);
        } else {
          this.promotionTypes = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables.state = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables.state;
        this.isLoading = false;
      },
      (err) => {
        this.lockedTables.state = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  deletePromotionType(promotionType: PromotionType): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete Type with ID: ' + promotionType.id;
    instance.componentInstance.header = 'Delete Type?';
    instance.componentInstance.nametag = promotionType.name;
    instance.componentInstance.id = promotionType.id;
    instance.componentInstance.promotionTypepop = !this.promotionTypepop;
    instance.componentInstance.successEvent.subscribe(() => {
      this.promotionTypeService.deletePromotionType(promotionType.id).subscribe(() => {
        this.toastr.success('Type successfully removed');
        this.initFilter();
        this.initNewSearch();
        instance.close();
        promotionType.status = 'DELETED';
      });
    });
  }

  promotionTypeModal(promotionType?: PromotionType): void {
    const instance = this.modalHelper.open(PromotionTypeModalComponent);
    instance.componentInstance.promotionType = promotionType;
    instance.componentInstance.successEvent.subscribe((data) => {
      if (data) {
        this.toastr.success(`Type  ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initNewSearch();
        instance.close();
      }
    });
  }
}
