import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { Promotion } from 'src/app/data/model';
import { FormBuilder, FormsModule } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-related-configurations',
  templateUrl: './related-configurations.component.html',
  styleUrls: ['./related-configurations.component.scss'],
})
export class RelatedConfigurationsComponent implements OnInit {
  @Input() configType: string;
  @Input() cfgId: number;
  @Input() templateId: number;
  @Input() promotion: Promotion;
  triggeredConfigs: any;
  triggeringConfigs: any;
  thresholdRelatedConfigs: any;
  capRelatedConfigs: any;
  gatekeeperConfigs: any;
  templateSponsors: any;
  sponsorCount: number;
  faCheck = faCheck;

  debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
    private promotionConfigService: PromotionConfigService,
    private responseHelperService: ResponseHelperService
  ) {}

  ngOnInit(): void {
    if (this.configType === 'promoSponsor' && this.cfgId) {
      this.getRelatedForSponsorship();
    } else if (this.templateId && this.configType !== 'promoSponsor') {
      this.getRelatedForTemplate();
    }
  }

  getRelatedForSponsorship(): any {
    if (this.cfgId && this.configType === 'promoSponsor') {
      this.promotionConfigService.findTriggeredConfigs(this.cfgId, true).subscribe(
        (data: any) => {
          if (data.success) {
            var triggered = '<ul>';
            for (var i = 0; i < data.entity.length; i++) {
              triggered +=
                '<li>Promotion: ' +
                data.entity[i].promotionCode +
                '&nbsp;&nbsp;Configuration: <a href="/markets/' +
                data.entity[i].sponsorPartyId +
                '/sponsorship/' +
                data.entity[i].promotionSponsorId +
                '/configuration/' +
                data.entity[i].cfgId +
                '">' +
                data.entity[i].cfgRef +
                '</a></li>';
            }
            triggered += '</ul>';
            this.triggeredConfigs = triggered;
          }
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error, true);
        }
      );

      this.promotionConfigService.findTriggeringConfigs(this.cfgId, true).subscribe(
        (data: any) => {
          if (data.success) {
            var triggering = '<ul>';
            for (var i = 0; i < data.entity.length; i++) {
              triggering +=
                '<li>Promotion: ' +
                data.entity[i].promotionCode +
                '&nbsp;&nbsp;Configuration: <a href="/markets/' +
                data.entity[i].sponsorPartyId +
                '/sponsorship/' +
                data.entity[i].promotionSponsorId +
                '/configuration/' +
                data.entity[i].cfgId +
                '">' +
                data.entity[i].cfgRef +
                '</a></li>';
            }
            triggering += '</ul>';
            this.triggeringConfigs = triggering;
          }
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error, true);
        }
      );

      this.promotionConfigService.findThresholdRelatedConfigs(this.cfgId, true).subscribe(
        (data: any) => {
          if (data.success) {
            var thresholdRelated = '<ul>';
            for (var i = 0; i < data.entity.length; i++) {
              thresholdRelated +=
                '<li>Promotion: ' +
                data.entity[i].promotionCode +
                '&nbsp;&nbsp;Configuration: <a href="/markets/' +
                data.entity[i].sponsorPartyId +
                '/sponsorship/' +
                data.entity[i].promotionSponsorId +
                '/configuration/' +
                data.entity[i].cfgId +
                '">' +
                data.entity[i].cfgRef +
                '</a></li>';
            }
            thresholdRelated += '</ul>';
            this.thresholdRelatedConfigs = thresholdRelated;
          }
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error, true);
        }
      );

      this.promotionConfigService.findCapRelatedConfigs(this.cfgId, true).subscribe(
        (data: any) => {
          if (data.success) {
            var capRelated = '<ul>';
            for (var i = 0; i < data.entity.length; i++) {
              capRelated +=
                '<li>Promotion: ' +
                data.entity[i].promotionCode +
                '&nbsp;&nbsp;Configuration: <a href="/markets/' +
                data.entity[i].sponsorPartyId +
                '/sponsorship/' +
                data.entity[i].promotionSponsorId +
                '/configuration/' +
                data.entity[i].cfgId +
                '">' +
                data.entity[i].cfgRef +
                '</a></li>';
            }
            capRelated += '</ul>';
            this.capRelatedConfigs = capRelated;
          }
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error, true);
        }
      );

      this.promotionConfigService.findGatekeeperConfigs(this.cfgId, true).subscribe(
        (data: any) => {
          if (data.success) {
            var gatekeepers = '<ul>';
            for (var i = 0; i < data.entity.length; i++) {
              gatekeepers +=
                '<li>Promotion: ' +
                data.entity[i].promotionCode +
                '&nbsp;&nbsp;Configuration: <a href="/markets/' +
                data.entity[i].sponsorPartyId +
                '/sponsorship/' +
                data.entity[i].promotionSponsorId +
                '/configuration/' +
                data.entity[i].cfgId +
                '">' +
                data.entity[i].cfgRef +
                '</a></li>';
            }
            gatekeepers += '</ul>';
            this.gatekeeperConfigs = gatekeepers;
          }
        },
        (data) => {
          this.responseHelperService.error(this, data.error.error, true);
        }
      );
    }
  }

  getRelatedForTemplate(): any {
    this.promotionConfigService.findSponsorsUsingTemplate(this.templateId).subscribe(
      (data: any) => {
        if (data.success) {
          var sponsors = '<ul>';
          this.sponsorCount = 0;
          for (var i = 0; i < data.entity.length; i++) {
            this.sponsorCount++;
            sponsors +=
              '<li>Sponsorship: <a href="/markets/' +
              data.entity[i].sponsorPartyId +
              '/packaged-sponsorship/' +
              data.entity[i].sponsorshipTag +
              '?promoSponsorId=' +
              data.entity[i].promotionSponsorId +
              '">' +
              data.entity[i].promotionSponsorId +
              '</a>' +
              '&nbsp;&nbsp;Configuration: <a href="/markets/' +
              data.entity[i].sponsorPartyId +
              '/sponsorship/' +
              data.entity[i].promotionSponsorId +
              '/configuration/' +
              data.entity[i].cfgId +
              '">' +
              data.entity[i].cfgRef +
              '</a></li>';
          }
          sponsors += '</ul>';
          this.templateSponsors = sponsors;
        }
      },
      (data) => {
        this.responseHelperService.error(this, data.error.error, true);
      }
    );
  }
}
