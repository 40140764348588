<div class="search-filter-radio-field" [ngClass]="filter.inputClass" *ngIf="section.open">
  <label class="control-label">{{ filter.label }}</label>
  <span *ngFor="let choice of filter.choices; let i = index">
    <span>
      <div class="radio radio-primary radio-inline">
        <input
          type="radio"
          name="filter.name"
          id="{{ filter.choices[i].label + '-' + i }}"
          [(ngModel)]="filter.value"
          [checked]="filter.choices[i].value === filter.value"
          [value]="filter.choices[i].value"
          (ngModelChange)="inputChangeEmitter.emit(filter); filter.onChangeCallback(filter)"
        />
        <label class="radio-label" for="{{ filter.choices[i].label + '-' + i }}">
          {{ filter.choices[i].label }}
        </label>
      </div>
    </span>
  </span>
</div>
