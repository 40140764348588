<div [ngClass]="filter.inputClass" class="search-filter-fields" *ngIf="section.open" [ngStyle]="{ height: height }">
  <ng-select
    [hideSelected]="true"
    (change)="changeFn(selection)"
    (clear)="clearAll()"
    [(ngModel)]="selection"
    class="font-relative-width"
    [items]="filter.choices"
    bindLabel="{{ filter.nameProp }}"
    bindValue="{{ filter.valueProp }}"
    [multiple]="filter.multiple"
    [closeOnSelect]="!filter.multiple"
    placeholder="{{ filter.placeholder }}"
  >
  </ng-select>
</div>
