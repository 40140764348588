import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { AppDataService } from 'src/app/app-data.service';
import { DatePipe } from '@angular/common';
import { Filter, SearchFilters } from 'src/app/data/class';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { Program, InstrumentType } from 'src/app/data/model';
import { ProcessingActivityLogService } from 'src/app/services/processing-activity-log.service';
import {
  faTrophy,
  faChevronRight,
  faChevronDown,
  faCheckCircle,
  faHistory,
  faChevronUp,
  faFireExtinguisher,
  faSpinner,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbDate, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProcessingService } from '../services/processing.service';
import { ActivityService } from '../services/activity.service';
import { Title } from '@angular/platform-browser';
import { ImportChannelHelper } from '../services/ImportChannelHelper.service';
import { ProgramService } from '../services/program.service';
import { ConfigurationService } from '../services/configuration.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { PromotionService } from '../services/promotion.service';
import { RewardTypeService } from '../services/reward-type.service';
import { PromotionTypeService } from '../services/promotion-type.service';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { UtilityService } from '../services/utility.service';
import { ReversalConfirmationModalComponent } from '../member/member-tabs/reversal-confirmation-modal/reversal-confirmation-modal.component';
import { SegmentFilterComponent } from 'src/app/segment-filter/segment-filter.component';
import { Subject } from 'rxjs';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
@Component({
  selector: 'app-activity-search',
  templateUrl: './activity-search.component.html',
  styleUrls: ['./activity-search.component.scss'],
  providers: [DatePipe],
})
export class ActivitySearchComponent implements OnInit, OnChanges, OnDestroy {
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild(SegmentFilterComponent) filterSegment: SegmentFilterComponent;
  canReverse: boolean;
  selectedActivityId: number;
  filter = new Filter();
  limit: number;
  selectedActivities: number[] = [];
  activities: any[] = []; // TODO: assign type
  lockedTables = false;
  isLoading: boolean;
  lock: boolean;
  lengthToCompare: number;
  expandedLookup: any = {};
  faTrophy = faTrophy;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faCheckCircle = faCheckCircle;
  faChevronUp = faChevronUp;
  faHistory = faHistory;
  faSpinner = faSpinner;
  faFireExtinguisher = faFireExtinguisher;
  faCheckSquare = faCheckSquare;
  activity: any = {};
  importChannels: any[] = [];
  isProgramManager: boolean;
  isCSRManager: boolean;
  searchParamsApplied: boolean;
  pAttributeValueMessage = false;
  select: any = { promotions: [] };
  rewardedOnly: any = {};
  includeReversed: boolean;
  filteredSegmentIds: number[] = [];
  rewardTypesFilter: any[] = [];
  programs: Program[];
  checkAll: boolean;
  isSelected: any = {};
  resize = true;
  maxReprocessRecords = 0;
  promotionsFilter: any[];
  promotionTypesFilter: any[];
  activityInstruments: InstrumentType[];
  aAttributeValueMessage: boolean;
  disableRewardFilters: boolean;
  instrument: string;
  partyId: string;
  firstName: string;
  lastName: string;
  pAttributeName: string;
  pAttributeValue: string;
  rewardStatusRadio = 2;
  rewardAmtFrom: string;
  keyword: string;
  aFrom: NgbDate;
  aThru: NgbDate;
  cFrom: NgbDate;
  cThru: NgbDate;
  bCode: string;
  aAttributeName: string;
  aAttributeValue: string;
  rewardAmtTo: string;
  activityDetails: any[] = [];
  rewardType: string;
  promotionType: number;
  modelpop: any;
  chipData: any;
  closeResult = '';
  searchText: any;
  chipDisplay: boolean = false;
  subject: Subject<any> = new Subject<any>();
  filterdata: SearchFilters;
  applyChip: boolean = false;
  appliedFilters: Filter[];
  filterButton: boolean = false;

  constructor(
    public appDataService: AppDataService,
    private responseHelperService: ResponseHelperService,
    private processingService: ProcessingService,
    private activityService: ActivityService,
    private processingActivityLogService: ProcessingActivityLogService,
    private datePipe: DatePipe,
    private modalHelper: NgbModal,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private globalData: AppDataService,
    private title: Title,
    private importChannelHelper: ImportChannelHelper,
    private programService: ProgramService,
    private configurationService: ConfigurationService,
    private promotionService: PromotionService,
    private rewardTypeService: RewardTypeService,
    private promotionTypeService: PromotionTypeService,
    private dateSerice: DateHelperService,
    private utilityService: UtilityService,
    private applyfilterService: ApplyFilterService
  ) {}
  ngOnDestroy() {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  open(content) {
    this.modelpop = this.modalService.open(content, { size: 'lg', windowClass: 'filter_model' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  cancel() {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {
    this.title.setTitle('Activity Search');
    this.importChannels = this.importChannelHelper.getImportChannels();
    this.isProgramManager = this.globalData.isProgramManager;
    this.isCSRManager = this.globalData.isCSRManager;
    this.setPromotionTypeFilter();
    this.setRewardTypeFilter();
    this.getPrograms();
    this.initNewSearch();
    // this.subject.pipe(debounceTime(300), distinctUntilChanged()).subscribe((item) => {
    //   if (item.search !== '') {
    //     this.filter.search = item.search;
    //     console.log('this.filter', this.filter);
    //   } else {
    //     delete this.filter.search;
    //   }
    //   this.initFilter();
    // });
  }

  ngOnChanges(): void {
    this.getPrograms();
    this.initNewSearch();
  }

  setPromotionsFilter(programId?: number, limit?: number, offset?: number): void {
    const promosLimit = limit || 500;
    let promosOffset = offset || 0;
    if (this.promotionsFilter && this.promotionsFilter.length > 5000) {
      this.toastr.error('Too many Promotions to list in Filter.');
      return;
    }
    let promoResponse;
    if (programId === -1) {
      // -1 means the default one we inserted earlier, so get all promos
      promoResponse = this.promotionService.getPromotions({ limit: promosLimit, offset: promosOffset, dir: 'desc' });
    } else {
      promoResponse = this.programService.getProgramPromotions(programId, promosLimit, promosOffset);
    }
    promoResponse.subscribe((resp: any) => {
      if (resp.success) {
        if (!this.promotionsFilter || offset === 0) {
          this.promotionsFilter = [];
        }
        const data = programId === -1 ? resp.entity.aaData : resp.entity;
        this.promotionsFilter = data;
        if (data.length === promosLimit) {
          promosOffset += promosLimit;
          this.setPromotionsFilter(programId, promosLimit, promosOffset);
        }
      }
    });
  }

  setRewardTypeFilter(): void {
    this.rewardTypeService.getRewardTypes().subscribe((rewardTypes: any) => {
      this.rewardTypesFilter = rewardTypes.entity.aaData;
    });
  }

  setPromotionTypeFilter(): void {
    this.promotionTypeService.getAllPromotionTypes({}).subscribe((promotionTypes: any) => {
      this.promotionTypesFilter = promotionTypes.entity.aaData;
    });
  }

  getPrograms(limit?: number, offset?: number): void {
    if (this.programs && this.programs.length > 5000) {
      this.toastr.error('Too many Programs to list in Filter.');
      return;
    }
    const programsLimit = limit || 500;
    const programsOffset = offset || 0;
    this.programService
      .getAllPrograms({ limit: programsLimit, offset: programsOffset, dir: 'desc' })
      .subscribe((data: any) => {
        if (data.success) {
          if (!this.programs) {
            this.programs = [{ id: -1, name: '- All Programs -', code: null }];
          }
          this.programs = this.programs.concat(data.entity.aaData);
          if (data.entity.aaData.length === programsLimit) {
            this.getPrograms(500, programsOffset + programsLimit);
          }
        }
      });
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'DESC';
    // this.filter.promotions = [...this.select.promotions];
    // this.filter.segmentIds = this.filteredSegmentIds;
  }
  clearForm(objKey) {
    if (objKey === 'partyId') {
      this.partyId = '';
    } else if (objKey === 'instrument') {
      this.instrument = '';
    } else if (objKey === 'firstName') {
      this.firstName = '';
    } else if (objKey === 'lastName') {
      this.lastName = '';
    } else if (objKey === 'pAttributeName') {
      this.pAttributeName = '';
    } else if (objKey === 'pAttributeValue') {
      this.pAttributeValue = '';
    } else if (objKey === 'keyword') {
      this.keyword = '';
    } else if (objKey === 'bCode') {
      this.bCode = '';
    } else if (objKey === 'aAttributeName') {
      this.aAttributeName = '';
    } else if (objKey === 'aAttributeValue') {
      this.aAttributeValue = '';
    } else if (objKey === 'rewardType') {
      this.rewardType = '';
    } else if (objKey === 'rewardAmtFrom') {
      this.rewardAmtFrom = '';
    } else if (objKey === 'includeReversed') {
      this.includeReversed = false;
    }
  }

  chipConditionalrendering(value) {
    if (value === 'column') {
      return false;
    } else if (value === 'limit') {
      return false;
    } else if (value === 'dir') {
      return false;
    } else if (value === 'offset') {
      return false;
    } else {
      return true;
    }
  }

  deleteChip(check: boolean, key: string) {
    if (check) {
      delete this.filter[key];
    }
  }

  clearAllChip(): void {
    for (var k in this.filter) {
      this.clearForm(k);
      if (k === 'limit') {
        this.deleteChip(false, k);
      } else if (k === 'offset') {
        this.deleteChip(false, k);
      } else if (k === 'column') {
        this.deleteChip(false, k);
      } else if (k === 'dir') {
        this.deleteChip(false, k);
      } else {
        this.deleteChip(true, k);
      }
    }
    this.chipDisplay = false;
    this.initFilter();
    this.getActivities(false);
  }
  removeChip(filterKey) {
    delete this.filter[filterKey];
    this.clearForm(filterKey);
    this.getActivities(false);
  }

  triggerProcessingOfAllMatching(): void {
    this.activityService
      .getActivitiesForReprocessingCount(this.utilityService.removeNullAndEmptyProperties(this.filter))
      .subscribe(
        (data: any) => {
          const activityCount = data.entity;
          this.configurationService.getConfiguration('MAX_REPROCESS_RECORDS').subscribe(
            (configuration: any) => {
              if (configuration.success) {
                this.maxReprocessRecords = configuration.entity[0] ? configuration.entity[0].cfgValue : 10000;
              } else {
                this.maxReprocessRecords = 10000;
              }
              let header: string;
              let text: string;
              let cancelButton: string;
              let okButton: string;
              if (activityCount < this.maxReprocessRecords) {
                text = 'This will re-submit ' + activityCount + ' activities. Are you sure you want to continue?';
                header = 'Confirm';
                cancelButton = 'No';
                okButton = 'Yes';
              } else {
                text =
                  'The number of records to re-submit(' +
                  activityCount +
                  ') exceeds the maximum number of re-submit records configured for your database(' +
                  this.maxReprocessRecords +
                  '). Limit your criteria and try again.';
                header = 'Maximum Records Exceeded';
                okButton = 'OK';
              }
              const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
              instance.componentInstance.text = text;
              instance.componentInstance.header = header;
              instance.componentInstance.cancelButton = cancelButton;
              instance.componentInstance.okButton = okButton;
              instance.componentInstance.promotionEdit = true;
              instance.componentInstance.onlyConfirm = true;
              instance.componentInstance.successEvent.subscribe(() => {
                this.processingService.findAndProcessList(this.filter).subscribe(
                  () => {
                    this.initNewSearch();
                    this.responseHelperService.success('Records successfully re-rubmited', true);
                  },
                  (error: any) => {
                    this.responseHelperService.error(this, error.errorMsg, true);
                  }
                );
              });
            },
            () => {
              this.responseHelperService.error(this, 'Error finding configurations', true);
            }
          );
        },
        () => {
          this.responseHelperService.error(this, 'Error finding activites for reprocessing', true);
        }
      );
  }

  initNewSearch(): void {
    this.initFilter();
    this.getActivities(false);
  }

  triggerProcessing(): void {
    this.processingService.processList(this.selectedActivities).subscribe(
      () => {
        this.responseHelperService.success('Activity successfully sent for processing');
        this.selectedActivities = [];
        this.activities.forEach((activity: any) => (this.isSelected[activity.id] = false));
        this.checkAll = false;
      },
      (data: any) => {
        this.responseHelperService.error(this, data.error.error, true);
      }
    );
  }

  selectActivity(activity: any, index: number): void {
    this.selectedActivityId = activity.id;
    this.processingActivityLogService.getProcessingHistoryForActivity(activity.id).subscribe((data: any) => {
      if (data.success) {
        this.activityDetails[index].logs = data.entity;
        if (data.entity.length >= 0) {
          this.activityService.getAttributesForActivity(activity.id).subscribe((activityAttributes: any) => {
            if (activityAttributes.success) {
              this.activityDetails[index].attributes = activityAttributes.entity;
              this.activityService.getRewardsForActivity(activity.id).subscribe((rewards: any) => {
                if (rewards.success) {
                  this.activityDetails[index].rewards = rewards.entity;
                }
              });
            }
          });
        }
      }
    });
  }

  setFilter(filterKey: string, filterValue: any, convertFromNgbToString?: boolean): void {
    if (convertFromNgbToString) {
      this[filterKey] = this.dateSerice.ngbDateToString(filterValue);
      this.filter[filterKey] = this.dateSerice.ngbDateToString(filterValue);
    } else {
      this[filterKey] = filterValue;
      this.filter[filterKey] = filterValue;
    }
    switch (filterKey) {
      case 'pAttributeValue': {
        if (filterValue !== undefined) {
          this.pAttributeValueMessage = true;
          if (filterValue === '') {
            this.pAttributeValueMessage = false;
          }
        }
        break;
      }
      case 'aAttributeValue': {
        if (filterValue !== undefined) {
          this.aAttributeValueMessage = true;
          if (filterValue === '') {
            this.aAttributeValueMessage = false;
          }
        }
        break;
      }
    }
  }

  disableInvalidFilters(filterName: string, filterValue: any): void {
    if (filterName === 'rewarded' && filterValue === 0) {
      this.disableRewardFilters = false;
    } else if (filterName === 'rewarded' && filterValue === 1) {
      this.disableRewardFilters = true;
    } else if (filterName === 'rewarded' && filterValue === 2) {
      this.disableRewardFilters = false;
    }
  }

  selectOneCb(activityId: number): void {
    if (this.isSelected[activityId]) {
      this.selectedActivities.push(activityId);
    } else {
      const activityIndex = this.selectedActivities.findIndex((id: number) => id === activityId);
      if (activityIndex > -1) {
        this.selectedActivities.splice(activityIndex, 1);
      }
    }
    this.checkAll = this.selectedActivities.length > 0;
  }

  getActivities(concat: boolean): void {
    this.isLoading = true;
    this.activityService.findAllForParty(this.utilityService.removeNullAndEmptyProperties(this.filter)).subscribe(
      (data: any) => {
        const activities = (data.entity && data.entity.aaData) || [];
        if (concat) {
          this.activities = this.activities.concat(activities);
          this.activityDetails = this.activityDetails.concat(
            activities.map((p: any) => ({
              id: p.id,
              rewards: [],
              attributes: [],
              activityLog: [],
              description: '',
            }))
          );
        } else {
          this.activities = activities;
          this.activityDetails = this.activities.map((p: any) => ({
            id: p.id,
            rewards: [],
            attributes: [],
            activityLog: [],
            activityLogMessages: [],
            description: '',
          }));
          this.expandedLookup = {};
          this.isSelected = {};
          this.checkAll = false;
        }
        this.lengthToCompare = activities.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = activities.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
        for (var k in this.filter) {
          if (k === 'limit' || k === 'offset' || k === 'column' || k === 'dir') {
            this.chipDisplay = false;
          } else {
            this.chipDisplay = true;
          }
        }

        this.modalService.dismissAll();
      },
      () => {
        this.lockedTables = false;
        this.isLoading = false;
        this.lock = false;
        this.toastr.error('Error occured!');
        this.modalService.dismissAll();
      }
    );
  }
  filterLength(data) {
    const size = Object.keys(data).length;
    return size;
  }

  selectAllCb(): void {
    if (this.checkAll) {
      this.selectedActivities = this.activities.map((a: any) => a.id);
    } else {
      this.selectedActivities = [];
    }
    this.activities.forEach((activity: any) => (this.isSelected[activity.id] = this.checkAll));
  }

  applyFilter(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initNewSearch();
    this.searchParamsApplied = true;
    this.selectedActivities = [];
    this.activities.forEach((activity: any) => (this.isSelected[activity.id] = false));
    this.checkAll = false;
  }

  resizeIbox(): void {
    this.resize = !this.resize;
  }

  triggerReversalOfAllMatching(): void {
    this.activityService
      .getActivitiesForReprocessingCount(this.utilityService.removeNullAndEmptyProperties(this.filter))
      .subscribe(
        (activities: any) => {
          const activityCount = activities.entity;
          this.configurationService.getConfiguration('MAX_REPROCESS_RECORDS').subscribe(
            (configuration: any) => {
              if (configuration.success) {
                this.maxReprocessRecords = configuration.entity[0] ? configuration.entity[0].cfgValue : 10000;
              } else {
                this.maxReprocessRecords = 10000;
              }
              let header: string;
              let text: string;
              let cancelButton: string;
              let okButton: string;
              if (activityCount < this.maxReprocessRecords) {
                text =
                  'This will reverse all rewards for ' +
                  activityCount +
                  ' activities. Are you sure you want to continue?';
                header = 'Confirm';
                cancelButton = 'No';
                okButton = 'Yes';
              } else {
                text =
                  'The number of records to reverse(' +
                  activityCount +
                  ') exceeds the maximum number of reverse records configured for your database(' +
                  this.maxReprocessRecords +
                  '). Limit your criteria and try again.';
                header = 'Maximum Records Exceeded';
                okButton = 'OK';
              }
              const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
              instance.componentInstance.text = text;
              instance.componentInstance.header = header;
              instance.componentInstance.cancelButton = cancelButton;
              instance.componentInstance.okButton = okButton;
              instance.componentInstance.promotionEdit = true;
              instance.componentInstance.onlyConfirm = true;
              instance.componentInstance.successEvent.subscribe(() => {
                this.activityService.findAndReverseList(this.filter).subscribe(
                  () => {
                    this.initNewSearch();
                    this.responseHelperService.success('Records successfully re-rubmited', true);
                  },
                  (error: any) => {
                    this.responseHelperService.error(this, error.errorMsg, true);
                  }
                );
              });
            },
            () => {
              this.responseHelperService.error(this, 'Error finding configurations', true);
            }
          );
        },
        (data: any) => {
          this.responseHelperService.error('Error finding activites for reversing', data.errorMsg, true);
        }
      );
  }

  confirmReversal(activityIndex: number, id: number, event: any): void {
    event.preventDefault();
    const instance = this.modalHelper.open(ReversalConfirmationModalComponent);
    instance.componentInstance.id = id;
    instance.componentInstance.reversedOn = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    instance.componentInstance.reversedBy = this.globalData.username;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      this.expandedLookup = [];
      if (data) {
        const rewardIndex = this.activityDetails[activityIndex].rewards.findIndex((reward: any) => reward.id === id);
        if (rewardIndex > -1) {
          this.activityDetails[activityIndex].rewards[rewardIndex].reversedBy = this.globalData.username;
        }
        instance.close();
      }
    });
  }

  toggle(activityId: number): void {
    setTimeout(() => {
      this.expandedLookup[activityId] = !this.expandedLookup[activityId];
    }, 50);
  }

  filterSegments(segmentIds: number[]): void {
    if (segmentIds && segmentIds.length > 0) {
      this.filteredSegmentIds = segmentIds;
    } else {
      // have filter directive not display anything if there are no segments being filtered
      this.filteredSegmentIds = null;
    }
  }
  filterSegmentsHandler(segmentIds: number[]): void {
    this.filterSegments(segmentIds);
  }
}
