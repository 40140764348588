import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Label } from 'ng2-charts';
import { forkJoin } from 'rxjs';
import { RewardTypeService } from 'src/app/services/reward-type.service';
import { Filter, SearchFilters } from '../data/class';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { RedemptionService } from '../services/redemption.service';

@Component({
  selector: 'app-redemption-dashboard',
  templateUrl: './redemption-dashboard.component.html',
  styleUrls: ['./redemption-dashboard.component.scss'],
  providers: [DatePipe],
})
export class RedemptionDashboardComponent implements OnInit {
  searchFilters: SearchFilters;
  rewardTypeFilter: any = {};
  rewardsTypes: any = [];
  gotRewardTypes = false;
  show: boolean;
  limit = 20;
  filter = new Filter();
  redemptions = [];
  isLoading: boolean;
  lengthToCompare: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  lockedTables: boolean;
  lock: boolean;
  chartLabelsDays: Label[];
  chartLabelsWeeks: Label[];
  filterRedemptionCosts = new Filter();
  allDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  weekdays = [];
  days = [];
  data6weeksCost = [];
  data6weeksCount = [];
  data7daysCost = [];
  data7daysCount = [];
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  collapseCost = false;
  collapseCount = false;
  firstClickCost = true;
  firstClickCount = true;

  constructor(
    private rewardTypeService: RewardTypeService,
    private responseHelperService: ResponseHelperService,
    private redemptionService: RedemptionService,
    private datePipe: DatePipe,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Redemptions dashboard');
    this.initRewardTypeFilter();
    this.rewardTypeService.getRewardTypes(this.rewardTypeFilter).subscribe((data: any) => {
      this.searchFilters = {
        formName: 'redemptionsSearch',
        title: 'Redemptions search',
        filters: [
          {
            name: 'vendor',
            placeholder: 'Vendor',
          },
          {
            name: 'orderStartDate',
            type: 'date',
            placeholder: 'Order start date',
          },
          {
            name: 'orderEndDate',
            type: 'date',
            endDate: true,
            placeholder: 'Order end date',
          },
          {
            name: 'totalCostLower',
            type: 'number',
            placeholder: 'Cost from ',
          },
          {
            name: 'totalCostUpper',
            type: 'number',
            placeholder: 'Cost to ',
          },
          {
            name: 'segments',
            type: 'array',
            inputType: 'segment-filter',
            singleLabel: 'Segment',
          },
          {
            name: 'totalPointsLower',
            type: 'number',
            placeholder: 'Points from ',
          },
          {
            name: 'totalPointsUpper',
            type: 'number',
            placeholder: 'Points to ',
          },
          {
            name: 'catalogCode',
            placeholder: 'Catalog code',
          },
          {
            name: 'rewardTypeId',
            placeholder: 'Reward type',
            choices: data.entity.aaData,
            nameProp: 'name',
            valueProp: 'id',
            inputType: 'select',
            type: 'array',
            multiple: false,
          },
        ],
        searchCallback: () => {
          this.initNewSearch();
        },
      };
      this.show = true;
      this.last6weeks();
      this.last7days();
      this.chartLabelsWeeks = this.weekdays;
      this.chartLabelsDays = this.days;
    });
  }

  initNewSearch(): void {
    this.initFilter();
    this.getRedemptions(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  initRewardTypeFilter(): void {
    this.rewardTypeFilter = { statuses: ['ACTIVE', 'PENDING', 'STARTED'], dir: 'asc', column: 1 };
  }

  getRedemptions(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.redemptionService.getRedemptions(this.filter).subscribe(
      (redemptionItem: any) => {
        if (concat) {
          this.redemptions = this.redemptions.concat(redemptionItem.entity);
        } else {
          this.redemptions = redemptionItem.entity;
        }
        this.lengthToCompare = redemptionItem.entity.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = redemptionItem.entity.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      (error: any) => {
        this.responseHelperService.error(this, error.errorMsg, true);
      }
    );
  }

  collapseTotal(chart) {
    if (chart === 'count') {
      this.collapseCount = !this.collapseCount;
      if (this.firstClickCost) {
        this.last6weeks('count');
        this.last7days('count');
        this.firstClickCost = false;
      }
    } else if (chart === 'cost') {
      this.collapseCost = !this.collapseCost;
      if (this.firstClickCount) {
        this.last6weeks('cost');
        this.last7days('cost');
        this.firstClickCount = false;
      }
    }
  }

  last6weeks(get?) {
    const milisInWeek = 7 * 24 * 3600 * 1000;
    var day = 1000 * 60 * 60 * 24;
    const curr = new Date();
    let last = new Date();
    let first = new Date();
    const subscribersCost = [];
    const subscribersCount = [];
    for (let i = 0; i < 6; i++) {
      if (i === 0) {
        last = new Date(curr.getTime());
      } else {
        last = new Date(last.getTime() - milisInWeek);
      }
      first = new Date(last.getTime() - milisInWeek);

      let cp = {
        orderEndDate: this.datePipe.transform(new Date(last.getTime() - day), 'yyyy-MM-dd'),
        orderStartDate: this.datePipe.transform(first, 'yyyy-MM-dd'),
      };
      if (get === 'cost') {
        subscribersCost.push(this.redemptionService.getRedemptionCost(cp));
      } else if (get === 'count') {
        subscribersCount.push(this.redemptionService.getRedemptionCount(cp));
      } else {
        var f = first.toISOString().split('T')[0].split('-');
        var l = last.toISOString().split('T')[0].split('-');
        this.weekdays.unshift(f[0] + '-' + f[1] + '-' + f[2] + ' / ' + l[0] + '-' + l[1] + '-' + l[2]);
      }
    }
    if (get === 'cost') {
      this.forkDataCost(subscribersCost, 'weeks');
    } else if (get === 'count') {
      this.forkDataCount(subscribersCount, 'weeks');
    }
  }

  last7days(get?) {
    var day = 1000 * 60 * 60 * 24;
    const currectDate = new Date();
    const subscribersCost7 = [];
    const subscribersCount7 = [];
    let last = new Date();

    for (let i = 0; i < 7; i++) {
      if (i === 0) {
        last = new Date(currectDate.getTime());
      } else {
        last = new Date(last.getTime() - day);
      }
      let cp = {
        orderEndDate: this.datePipe.transform(last, 'yyyy-MM-dd'),
        orderStartDate: this.datePipe.transform(last, 'yyyy-MM-dd'),
      };
      if (get === 'cost') {
        subscribersCost7.push(this.redemptionService.getRedemptionCost(cp));
      } else if (get === 'count') {
        subscribersCount7.push(this.redemptionService.getRedemptionCount(cp));
      } else {
        this.days.unshift(this.allDays[last.getDay()]);
      }
    }
    if (get === 'cost') {
      this.forkDataCost(subscribersCost7, 'days');
    } else if (get === 'count') {
      this.forkDataCount(subscribersCount7, 'days');
    }
  }

  forkDataCost(subscribers, daysOrWeeks) {
    forkJoin(subscribers).subscribe((response) => {
      response.forEach((cost: any) => {
        if (daysOrWeeks === 'weeks') {
          if (cost.entity) {
            this.data6weeksCost.unshift(cost.entity);
          } else {
            this.data6weeksCost.unshift(0);
          }
        } else if (daysOrWeeks === 'days') {
          if (cost.entity) {
            this.data7daysCost.unshift(cost.entity);
          } else {
            this.data7daysCost.unshift(0);
          }
        }
      });
    });
  }

  forkDataCount(subscribers, daysOrWeeks) {
    forkJoin(subscribers).subscribe((response) => {
      response.forEach((count: any) => {
        if (daysOrWeeks === 'weeks') {
          if (count.entity) {
            this.data6weeksCount.unshift(count.entity);
          } else {
            this.data6weeksCount.unshift(0);
          }
        } else if (daysOrWeeks === 'days') {
          if (count.entity) {
            this.data7daysCount.unshift(count.entity);
          } else {
            this.data7daysCount.unshift(0);
          }
        }
      });
    });
  }
}
