import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, QueryList } from '@angular/core';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { AttributeRestriction } from 'src/app/data/class';

@Component({
  selector: 'app-party-attribute-restriction',
  templateUrl: './party-attribute-restriction.component.html',
  styleUrls: ['./party-attribute-restriction.component.scss'],
})
export class PartyAttributeRestrictionComponent implements OnInit {
  @ViewChildren('form') forms: QueryList<any>;
  @Input() isValid: boolean;
  @Output() isValidChange: EventEmitter<any> = new EventEmitter();
  @Input() partyAttributeRestriction: any;
  @Input() configurationType: string;
  attribConditions: any;
  trueFalse: any;
  faTimes = faTimes;
  faPlus = faPlus;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();

  constructor() {
    this.debouncer.pipe(debounce(() => interval(500))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    console.log(this.partyAttributeRestriction);
    this.attribConditions = [
      { value: true, text: 'HAS' },
      { value: false, text: 'DOES NOT HAVE' },
    ];

    this.trueFalse = [
      { value: true, text: 'true' },
      { value: false, text: 'false' },
    ];
  }

  initRestrictionObject(): void {
    let tmpObj: any;
    if (this.configurationType === 'rules') {
      tmpObj = new AttributeRestriction();
      tmpObj.attrValue = '(.*)';
    } else {
      tmpObj = {
        attrValue: '(.*)',
        class:
          'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Eligibility$PartyAttributeRestriction',
      };
    }

    if (this.partyAttributeRestriction) {
      this.partyAttributeRestriction.push(tmpObj);
    } else {
      this.partyAttributeRestriction = [];
      this.partyAttributeRestriction.push(tmpObj);
    }
    console.log(this.partyAttributeRestriction);
  }

  updateRestrictions(): void {
    if (this.configurationType === 'rules') {
      this.debouncer.next(this.partyAttributeRestriction);
      let valid = true;
      for (const form of this.forms) {
        valid = valid && form.valid;
      }
      this.isValidChange.emit(valid);
    } else {
      this.debouncer.next(this.partyAttributeRestriction);
    }
  }

  removeRestriction(restriction) {
    this.partyAttributeRestriction.splice(this.partyAttributeRestriction.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
