import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormsModule } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-remove-attribute-consequence',
  templateUrl: './remove-attribute-consequence.component.html',
  styleUrls: ['./remove-attribute-consequence.component.scss'],
})
export class RemoveAttributeConsequenceComponent {
  @Input() removeAttribute: any;
  faTimes = faTimes;
  faPlus = faPlus;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal
  ) {
    this.debouncer.pipe(debounce(() => interval(1000))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  initConsequenceObject(): void {
    console.log(this.removeAttribute);
    var tmpObj = {
      class:
        'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences$RemoveAttribute',
    };
    if (this.removeAttribute) {
      this.removeAttribute.push(tmpObj);
    } else {
      this.removeAttribute = [];
      this.removeAttribute.push(tmpObj);
    }
    console.log(this.removeAttribute);
  }

  updateConsequences() {
    this.debouncer.next(this.removeAttribute);
  }

  removeConsequence(consequence) {
    this.removeAttribute.splice(this.removeAttribute.indexOf(consequence), 1);
    this.updateConsequences();
  }
}
