import {
  Component,
  OnDestroy,
  AfterViewInit,
  Input,
  OnInit,
  HostListener,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { mountReact, MountReactParams, unmountReact } from '../../services/react.service';

@Component({
  selector: 'app-mount-react',
  template: '<div id="{{mountId}}"></div>',
})
export class ReactComponent<P> implements OnDestroy, AfterViewInit, OnInit, OnChanges {
  @Input() public mountReactParams: MountReactParams<P>;

  public mountId: string;

  ngOnInit(): void {
    this.mountId = this.mountReactParams.mountId;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mount(changes.mountReactParams.currentValue);
  }

  mount(params = this.mountReactParams): void {
    mountReact(params);
  }

  ngAfterViewInit(): void {
    this.mount();
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    unmountReact(this.mountId);
  }
}
