<div>
  <form>
    <label>The activity date must be:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="activityWindow !== null">
        <div
          *ngFor="let activityWindow of activityWindow; let i = index"
          class="border-card-padding form-group col-lg-12"
        >
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeRestriction(activityWindow)"
            title="Remove Restriction"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <select
                name="condition_{{ activityWindow.windowStart }}"
                class="form-control"
                [(ngModel)]="activityWindow.condition"
                (ngModelChange)="updateRestrictions()"
              >
                <option
                  *ngFor="let condition of conditions"
                  [selected]="activityWindow.condition === condition.value"
                  [value]="condition.value"
                >
                  {{ condition.text }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="windowStart_{{ activityWindow.windowStart }}"
                >the window starting on (mm-dd):</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="windowStart_{{ activityWindow.windowStart }}"
                name="windowStartValue"
                [(ngModel)]="activityWindow.windowStart"
                (ngModelChange)="updateRestrictions()"
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="windowEnd_{{ activityWindow.windowEnd }}">and ending on (mm-dd):</label>
              <input
                class="form-control m-b"
                type="text"
                id="windowEnd_{{ activityWindow.windowEnd }}"
                name="windowEndValue"
                [(ngModel)]="activityWindow.windowEnd"
                (ngModelChange)="updateRestrictions()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Activity Window Restriction
        </button>
      </div>
    </div>
  </form>
</div>
