import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PromotionType } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class PromotionTypeService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getAllPromotionTypes(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-types/', this.setParams(params));
  }

  createOrUpdatePromotionType(id: number, promotionType: PromotionType): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'promotion-types/' + id, promotionType);
    }
    return this.http.post(this.globalData.baseUrl + 'promotion-types', promotionType);
  }

  deletePromotionType(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'promotion-types/' + id);
  }

  getPromotionTypesForSponsorshipTag(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-types/sponsorship-tag', this.setParams(params));
  }
}
