import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { mapHttpObservableToPromise } from './http.service';
import { Language } from '../data/model';

type GetLanguagesParams = { isoCode: 'ADMIN' | string };

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getLanguages = function (params?: GetLanguagesParams): any {
    if (params) {
      return this.http.get(this.globalData.baseUrl + 'languages/', this.setParams(params));
    } else {
      return this.http.get(this.globalData.baseUrl + 'languages/');
    }
  };

  async getLanguagesAsPromise(params: GetLanguagesParams) {
    const observable = this.getLanguages(params);
    return mapHttpObservableToPromise<Language[]>(observable);
  }

  getPrimaryLanguage = function (partyId: number, params: any): any {
    return this.http.get(this.globalData.baseUrl + 'party-languages/' + partyId, this.setParams(params));
  };
}
