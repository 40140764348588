<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <div class="row"></div>
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered infinite-table" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Name</th>
              <th>Description</th>
              <th>World Currency</th>
              <th>Expiring</th>
              <th>Exi Months</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let rt of rewardTypes">
              <td>{{ rt.id }}</td>
              <td>{{ rt.code }}</td>
              <td>{{ rt.name }}</td>
              <td>{{ rt.description }}</td>
              <td>{{ getWorldCurrencieName(rt.currencyId) }}</td>
              <td>{{ rt.isExpiring }}</td>
              <td>{{ rt.expiryMonths }}</td>
              <td>{{ rt.status }}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a class="btn btn-outline-primary btn-sm edit action" (click)="rewardTypeModal(rt.id)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                  <button
                    class="btn btn-attention btn-sm delete action"
                    [disabled]="rt.status === 'DELETED'"
                    (click)="deleteRewardType(rt.id)"
                  >
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="9" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getRewardTypes(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
