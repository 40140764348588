import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppDataService } from '../app-data.service';
import { ApplyFilterService } from '../services/filter-pop-service/applyFilter.service';
import { CommunicationHistoryTabComponent } from '../communication-tabs/communication-history-tab/communication-history-tab.component';
@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss'],
})
export class CommunicationsComponent implements OnInit {
  @ViewChild(CommunicationHistoryTabComponent) child;
  constructor(
    private titleHelper: Title,
    private applyfilterService: ApplyFilterService,
    private globalData: AppDataService
  ) {}

  active = 1;
  filterFunctions: any;
  isAdmin = this.globalData.isAdmin;
  useAllowlist = this.globalData.useAllowlist;
  tabIndex: number;

  ngOnInit(): void {
    this.titleHelper.setTitle('Communications');
  }

  tabClick(tab) {
    this.tabIndex = tab.index;
    if (this.isAdmin && this.useAllowlist) {
      tab.index == 7 ? this.titleHelper.setTitle('Allow List') : this.titleHelper.setTitle('Communications');
    }

    if (tab.index == 6) {
      this.child.clearAllChip();
    }
    this.applyfilterService.filterfun.subscribe((data) => {
      this.filterFunctions = data;
    });
    if (typeof this.filterFunctions.clearAll === 'function') {
      this.filterFunctions.clearAll();
    }
    this.applyfilterService.getFilterData([]);
  }
}
