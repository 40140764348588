<div class="panel-body">
  <div class="col-md-12">
    <div class="row">
      <!-- <app-search-filter packagepop="true" [searchFilters]="searchFilters"></app-search-filter>
       -->
    </div>

    <div class="row">
      <div class="filters_container">
        <div class="search-input1">
          <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
          <mat-icon class="search">search</mat-icon>
        </div>
        <div class="right_container right-margin">
          <button class="filter-btn" (click)="FilterModel()">
            FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
          </button>
        </div>
      </div>
      <app-filter-tags
        *ngIf="searchFilters"
        isPopup="true"
        [(filterButton)]="filterButton"
        [(searchFilters)]="searchFilters"
        [(appliedFilters)]="appliedFilters"
      ></app-filter-tags>
      <table class="table table-pointable table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th class="width-10">From date</th>
            <th class="width-10">To date</th>
            <th class="width-5"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pckg of packages | filter: searchText">
            <td>{{ pckg.packageName }}</td>
            <td>{{ pckg.startDate }}</td>
            <td>{{ pckg.endDate }}</td>
            <td>
              <div class="float-left actions inline-table">
                <button
                  class="btn btn-outline-primary btn-sm edit action"
                  (click)="associatedProgramsModal(pckg.packageName)"
                  title="View Associated Programs"
                >
                  <fa-icon [icon]="faEye"></fa-icon>
                </button>
              </div>
            </td>
          </tr>
          <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
        </tbody>
      </table>
    </div>
    <app-add-more-data-to-list
      (onScroll)="getDistinctProgramPackages(true)"
      [lock]="lock"
      [isLoading]="isLoading"
    ></app-add-more-data-to-list>
  </div>
</div>
