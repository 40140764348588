<div class="wrapper wrapper-content">
  <div class="row">
    <!-- STAGE IMPORT -->
    <div class="col-sm-12">
      <div class="ibox">
        <div class="ibox-title">
          <div class="row">
            <div class="col-sm-8">
              <h2 class="fullname">Records</h2>
            </div>
            <div class="col-sm-4"></div>
          </div>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="filters_container">
                  <div class="search-input">
                    <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
                    <mat-icon class="search">search</mat-icon>
                  </div>
                  <div class="right_container">
                    <button class="filter-btn" (click)="FilterModel()">
                      FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
                    </button>
                  </div>
                </div>
                <app-filter-tags
                  *ngIf="searchFilters"
                  isPopup="true"
                  [(filterButton)]="filterButton"
                  [(searchFilters)]="searchFilters"
                  [(appliedFilters)]="appliedFilters"
                ></app-filter-tags>
                <div class="row table-integrations">
                  <table class="table table-pointable table-bordered infinite-table">
                    <thead>
                      <tr>
                        <th class="width-3"></th>
                        <th class="width-8-rem">ID</th>
                        <th class="width-12-rem">Record Status</th>
                        <th>Type</th>
                        <th>Created On</th>
                        <th>Record Number</th>
                        <th>Validation Code</th>
                        <th>Validation Message</th>
                        <th *ngIf="isProgramManager">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let import of imports | filter: searchText">
                        <tr (click)="showImport($event, import)">
                          <td (click)="import.rawData && showRawData(import.id)">
                            <div>
                              <span
                                *ngIf="rawViewData !== import.id"
                                title="Expand to see history"
                                [hidden]="!import.rawData"
                              >
                                <fa-icon [icon]="faChevronRight"></fa-icon>
                              </span>
                              <span
                                *ngIf="rawViewData === import.id"
                                title="Expand to see history"
                                [hidden]="!import.rawData"
                              >
                                <fa-icon [icon]="faChevronDown"></fa-icon>
                              </span>
                            </div>
                          </td>
                          <td>{{ import.id }}</td>
                          <td>{{ import.status }}</td>
                          <td>{{ import.importType }}</td>
                          <td>{{ import.createdOn | date: "yyyy-MM-dd" }}</td>
                          <td>{{ import.recordNumber }}</td>
                          <td>{{ import.validationCode }}</td>
                          <td>{{ import.validationMessage }}</td>
                          <td *ngIf="isProgramManager" class="white-space-normal animate-fade">
                            <a
                              [disabled]="import.status === 'RETRY'"
                              class="text-success"
                              (click)="retrySingleImport(import.stageLoadId, import.id)"
                              title="Retry"
                              ><fa-icon [icon]="faSyncAlt"></fa-icon>
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="rawViewData === import.id" class="additional-data DFF4FF-background">
                          <td class="border-right-0"></td>
                          <td colspan="8"><strong>Raw Data: </strong>{{ import.rawData }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
              <app-add-more-data-to-list (onScroll)="getImports(true)" [lock]="lock" [isLoading]="isLoading">
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--IMPORT SELECTED-->
  </div>
</div>
