import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationsService } from '../services/communications.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';

@Component({
  selector: 'app-communications-test-modal',
  templateUrl: './communications-test-modal.component.html',
  styleUrls: ['./communications-test-modal.component.scss'],
})
export class CommunicationsTestModalComponent implements OnInit {
  @Input() commId: number;
  title = 'Send a Test Communication';
  commMessage: any;
  partyId: number;
  externalProgramId: string;
  promotionCode: string;
  communicationTestForm: FormGroup;
  errorMsg: string;
  faPaperPlane = faPaperPlane;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService
  ) {}

  ngOnInit(): void {
    this.communicationTestForm = this.formBuilder.group({
      partyId: [this.partyId, Validators.required],
      externalProgramId: [this.externalProgramId, Validators.required],
      promotionCode: [this.promotionCode],
    });
  }

  ok(): void {
    let formValue = this.communicationTestForm.value;
    this.commMessage = {};
    this.commMessage.communicationId = this.commId;
    this.commMessage.partyId = formValue.partyId;
    if (formValue.externalProgramId) {
      this.commMessage.externalProgramId = formValue.externalProgramId.trim();
    }
    if (formValue.promotionCode) {
      this.commMessage.promotionCode = formValue.promotionCode.trim();
    }
    this.commMessage.instanceAttributes = [];
    this.commMessage.attachments = [];

    this.communicationsService.sendTestNotification(this.commMessage).subscribe(
      (data: any) => {
        this.responseHelperService.success('Test Notification Sent to CHIRP for Processing...', true);
      },
      (data: any) => {
        this.errorMsg = data.error.error;
      }
    );
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
