<div>
  <form>
    <label>Change member's language:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="changeLanguage !== null">
        <div *ngFor="let changeLang of changeLanguage; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeConsequence(changeLang)"
            title="Remove Consequence"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="activityAttrName_{{ changeLang.activityAttrName }}"
                >Attribute Name</label
              >
              <input
                class="form-control m-b"
                type="text"
                id="activityAttrName_{{ changeLang.activityAttrName }}"
                name="activityAttrName"
                [(ngModel)]="changeLang.activityAttrName"
                (ngModelChange)="updateConsequences()"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Change member's language
        </button>
      </div>
    </div>
  </form>
</div>
