import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../services/security/security.service';
import { AppDataService } from '../app-data.service';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss'],
})
export class LoggedOutComponent implements OnInit {
  isSSO = false;
  constructor(private securityService: SecurityService, private globalData: AppDataService) {}

  ngOnInit(): void {
    this.securityService.checkIsSSOInstance.then((data: boolean) => {
      this.isSSO = data;
    });
  }

  redirectToLogin(): void {
    this.securityService.cleanOauth();
    localStorage.removeItem('token');
    this.globalData.loggedIn = false;
  }
}
