import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { Behavior } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class BehaviorsService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getBehaviors(params?: any): any {
    return this.http.get(this.globalData.baseUrl + 'behaviors', this.setParams(params));
  }

  getBehavior(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'behaviors/' + id);
  }

  createOdUpdateBehavior(id: number, behavior: Behavior): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'behaviors/' + id, behavior);
    }
    return this.http.post(this.globalData.baseUrl + 'behaviors', behavior);
  }

  deleteBehavior(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'behaviors/' + id);
  }

  getBehaviorsByRegex(params): any {
    return this.http.get(this.globalData.baseUrl + 'promotions/behaviors/pattern/', this.setParams(params));
  }
}
