<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-4">
      <div class="ibox">
        <div class="ibox-title">
          <div class="row flex-header">
            <div class="col-md-6 flex-header-name">
              <h2 class="import-message">{{ programStructure.name }}</h2>
            </div>
            <div class="col-md-6 flex-header-title">
              <h3>Promotion Management</h3>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <div class="row mb-1">
                <div class="float-left col-sm-3">
                  <h4 class="control-label">Product Package:</h4>
                </div>
                <div class="col-sm-6 form-group">
                  <ng-select
                    [items]="programPackages"
                    bindLabel="packageName"
                    bindValue="packageName"
                    [hideSelected]="true"
                    [closeOnSelect]="false"
                    (change)="setPromotions($event)"
                  >
                  </ng-select>
                </div>
                <div class="col-sm-3 button-panel-sq">
                  <div class="flex-pull-right button-panel-sq">
                    <button class="btn btn-sm btn-dark-blue" (click)="promotionModal()" title="New Promotion">
                      <fa-icon [icon]="faUserPlus"></fa-icon>
                    </button>
                    <button class="btn btn-dark-blue btn-sm" (click)="packageModal()" title="New package">
                      <span>
                        <fa-icon [icon]="faMouse" transform="shrink-1 left-0.5 right-4"></fa-icon>
                        <fa-icon [icon]="faPlus" transform="shrink-5 right-4 up-6"></fa-icon>
                      </span>
                    </button>
                    <button
                      class="btn btn-dark-blue btn-sm edit"
                      [hidden]="!selectedPackage"
                      (click)="packageModal(selectedPackage.id)"
                      title="Edit package"
                    >
                      <span>
                        <fa-icon [icon]="faMouse" transform="shrink-1 left-0.5 right-4"></fa-icon>
                        <fa-icon [icon]="faPencilAlt" transform="shrink-5 right-4 down-6"></fa-icon>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="list-wrapper cursor-pointer">
                <div
                  [hidden]="!promotion.children || promotion.children.length == 0"
                  class="row promo-element"
                  [ngClass]="{
                    'faded-promo': !promotion.hasSelectedPackage && selectedPackage,
                    'active-promo': promotion == selectedPromotion
                  }"
                  title="{{ promotion.name }}"
                >
                  <div class="col-md-1">
                    <span class="float-left colored-circle uppercase" [ngClass]="promotion.typeColor">{{
                      promotion.typeName.substring(0, 1)
                    }}</span>
                  </div>
                  <div class="col-md-9 promotion-div uppercase">
                    <fa-icon [hidden]="!promotion || !promotion.children" [icon]="faChevronUp"></fa-icon>
                    {{ promotion.name }}
                  </div>
                  <div class="col-md-2">
                    <span class="float-right colored-circle" [ngClass]="statusHelper[promotion.status]">{{
                      promotion.status.substring(0, 1)
                    }}</span>
                    <span class="float-right mr-1"> (0) </span>
                  </div>
                </div>
                <ul [hidden]="!promotion.children" class="list-none">
                  <li
                    *ngFor="let promo of promotion.children"
                    (click)="selectPromotion(promo, $event)"
                    [ngClass]="'parent-id-' + promo.parentId"
                  >
                    <app-promo-tree
                      [promotion]="promo"
                      [selectedPackage]="selectedPackage"
                      [selectedPromotion]="selectedPromotion"
                      (promotionChanged)="promotionChanged($event)"
                    >
                    </app-promo-tree>
                  </li>
                </ul>
              </div>
              <ul class="list-none pl-0" [hidden]="!programStructure.promotions">
                <li *ngFor="let promo of programStructure.promotions" (click)="selectPromotion(promo, $event)">
                  <app-promo-tree
                    [promotion]="promo"
                    [selectedPackage]="selectedPackage"
                    [selectedPromotion]="selectedPromotion"
                    (promotionChanged)="promotionChanged($event)"
                  >
                  </app-promo-tree>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedPromotion" class="col-sm-8 float-left">
      <div id="promotionDetails" table="#tableId">
        <div class="ibox">
          <div class="ibox-content">
            <div class="row">
              <div class="col-lg-12">
                <div class="mb-3">
                  <a
                    class="btn btn-dark-blue btn-sm float-right"
                    (click)="promotionModal(selectedPromotion.id)"
                    title="Edit"
                    ><fa-icon [icon]="faPencilAlt"></fa-icon
                  ></a>
                  <h2 class="fullname">{{ selectedPromotion.name }}</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <div class="row">
                  <div class="col-md-4">
                    <label>Status:</label>
                  </div>
                  <div class="col-md-8">
                    <span class="badge" [ngClass]="statusHelper[selectedPromotion.status]">{{
                      selectedPromotion.status
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label>Code:</label>
                  </div>
                  <div class="col-md-8">
                    <span class="notes float-left">{{ selectedPromotion.code }}</span>
                  </div>
                </div>
                <div class="row" *ngIf="selectedPackage">
                  <div class="col-md-6">
                    <label>Filtered by Package:</label>
                  </div>
                  <div class="col-md-6">
                    <span class="notes float-left">{{ selectedPackage.packageName }}</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-9" *ngIf="selectedPromotion.description">
                <strong>Description:</strong>
                <pre class="notes">
                  <span class="notes float-left">{{selectedPromotion.description}}</span>
                </pre>
              </div>
            </div>
            <div class="tabs-container">
              <div class="wrapper wrapper-content">
                <div class="ibox">
                  <div class="ibox-content">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mb-3">
                          <h2 class="fullname">Product Management</h2>
                        </div>
                      </div>
                    </div>
                    <div class="tabs-container">
                      <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink>Promotion Templates</a>
                          <ng-template ngbNavContent>
                            <app-promotion-templates-tab
                              [selectedProgramId]="programId"
                              [selectedPersonId]="selectedPromotion.id"
                              [selectedPackageId]="selectedPackage?.id"
                            ></app-promotion-templates-tab>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                          <a ngbNavLink>Promotion Display Templates</a>
                          <ng-template ngbNavContent>
                            <app-promotion-display-templates-tab
                              [selectedProgramId]="programId"
                              [selectedPersonId]="selectedPromotion.id"
                              [selectedPackageId]="selectedPackage?.id"
                            ></app-promotion-display-templates-tab>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                          <a ngbNavLink>Markets</a>
                          <ng-template ngbNavContent>
                            <app-sponsor-tab
                              [promotionId]="selectedPromotion && selectedPromotion.id"
                            ></app-sponsor-tab>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                          <a ngbNavLink>Attributes</a>
                          <ng-template ngbNavContent>
                            <app-promotion-attributes-tab
                              [promotionId]="selectedPromotion.id"
                            ></app-promotion-attributes-tab>
                          </ng-template>
                        </li>
                      </ul>
                      <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
