import { Component, Input, OnInit } from '@angular/core';
import { faAngleUp, faChevronDown, faChevronUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-promotion-preview-modal',
  templateUrl: './promotion-preview-modal.component.html',
  styleUrls: ['./promotion-preview-modal.component.scss'],
})
export class PromotionPreviewModalComponent implements OnInit {
  @Input() model: any;
  currencyValue = false;
  hideDetails = true;
  faChevronDown = faChevronDown;
  faInfoCircle = faInfoCircle;
  faAngleUp = faAngleUp;
  faChevronUp = faChevronUp;
  hideAllActions = true;
  hideDollarSign = false;
  hidePromoAction = false;

  constructor() {}

  ngOnInit(): void {
    if (this.model.videoHTML === '' && this.hidePromoAction) {
      this.hideAllActions = true;
    } else {
      this.hideAllActions = false;
    }

    if (
      this.model.displayAmount === '' ||
      this.model.displayAmount === null ||
      this.model.displayAmount === undefined
    ) {
      this.currencyValue = false;
    } else {
      this.currencyValue = true;
    }

    const dollarExp = new RegExp('^dollar', 'i');

    if (dollarExp.test(this.model.displayCurrency)) {
      this.hideDollarSign = false;
    } else {
      this.hideDollarSign = true;
    }
  }

  showFullDetails(): void {
    this.hideDetails = false;
  }
  hideFullDetails(): void {
    this.hideDetails = true;
  }
}
