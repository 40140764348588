import { AppDataService } from './../app-data.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseHelperService } from './helpers/response-helper.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SponsorsService {
  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getAllSponsors(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'sponsors/', this.setParams(params));
  }

  getSponsor(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'organizations/' + id);
  }

  createOrUpdateSponsor(id: number, data: any): any {
    if (id !== null) {
      return this.http.put<any>(this.globalData.baseUrl + 'organizations/' + id, data);
    } else {
      return this.http.post<any>(this.globalData.baseUrl + 'organizations/', data);
    }
  }

  getSponsorshipGroups(sponsorId: number, params: any): any {
    this.responseHelperService.setRequestInProgress();
    return this.http
      .get<any>(
        this.globalData.baseUrl + 'promotion-sponsors/' + sponsorId + '/sponsorship-groups',
        this.setParams(params)
      )
      .pipe(
        map(
          (res) => {
            return this.responseHelperService.processResponse(res);
          },
          (err) => {
            return this.responseHelperService.processError(err);
          }
        )
      );
  }

  getSponsorPromotions(id: number, params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'sponsors/' + id + '/promotions', this.setParams(params));
  }

  addPromotionToSponsor(data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'promotion-sponsors/multiple', data);
  }

  createPackagedSponsorship(data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'promotion-sponsors/packaged-sponsorship', data);
  }

  getPromotionSponsorSegmentsTree(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-sponsors/' + id + '/segments/tree');
  }

  getPromotionSponsorSegments(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-sponsors/' + id + '/segments');
  }

  addSegmentsToPromotionSponsor(id: number, data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'promotion-sponsors/' + id + '/segments', data);
  }

  removePromotionSponsorSegments(sponsorId: number, segmentId: number): any {
    return this.http.delete<any>(
      this.globalData.baseUrl + 'promotion-sponsors/' + sponsorId + '/segments/' + segmentId
    );
  }

  createOrUpdatePromotionSponsor(id: number, data: any, format?: string): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'promotion-sponsors/' + id + '?format=' + format, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'promotion-sponsors', data);
  }

  getPackagedSponsorship(id: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'promotion-sponsors/' + id + '/packaged-sponsorship-structure',
      this.setParams(params)
    );
  }

  getPromotionSponsor(id: number, params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-sponsors/' + id, this.setParams(params));
  }

  getConfigurations(id: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'promotion-configurations/promotion-sponsor/' + id,
      this.setParams(params)
    );
  }

  getSponsorshipReminders(sponsorId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-sponsors/' + sponsorId + '/reminders');
  }

  deleteSponsorshipReminder(sponsorId: number, reminderId: number): any {
    return this.http.delete<any>(
      this.globalData.baseUrl + 'promotion-sponsors/' + sponsorId + '/reminder/' + reminderId
    );
  }

  upsertSponsorshipReminders(sponsorId: number, reminderId: number, data: any): any {
    if (reminderId) {
      return this.http.put<any>(
        this.globalData.baseUrl + 'promotion-sponsors/' + sponsorId + '/reminder/' + reminderId,
        data
      );
    } else {
      return this.http.post<any>(this.globalData.baseUrl + 'promotion-sponsors/' + sponsorId + '/reminder', data);
    }
  }

  updateDisplayXml(id: number, data: any, format?: string): any {
    if (format) {
      return this.http.put<any>(
        this.globalData.baseUrl + 'promotion-sponsors/' + id + '/display/?format=' + format,
        data,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      );
    } else {
      return this.http.put<any>(this.globalData.baseUrl + 'promotion-sponsors/' + id + '/display/', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      });
    }
  }

  getSponsorStats(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'sponsors/statistics', this.setParams(params));
  }
}
