<form [formGroup]="promotionForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="name" autofocus />
      <!-- <div class="mt-1" [hidden]="promotionForm.controls['name'].valid">
        <small class="text-danger">Input a Name</small>
      </div> -->
    </div>
    <div [hidden]="isEdit" class="form-group">
      <div class="d-flex justify-content-between">
        <label>Code <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="code" />
      <!-- <div class="mt-1" [hidden]="promotionForm.controls['code'].valid">
        <small class="text-danger">Input a Code</small>
      </div> -->
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Status <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select formControlName="status" class="form-control">
        <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
      </select>
      <!-- <div class="mt-1" [hidden]="promotionForm.controls['status'].valid">
        <small class="text-danger">Select status</small>
      </div> -->
    </div>

    <div class="form-group">
      <label>Parent Promotion</label>
      <ng-select [items]="promotions" bindLabel="name" bindValue="id" formControlName="parentId" [closeOnSelect]="true">
      </ng-select>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Type <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <ng-select
        [items]="types"
        bindLabel="name"
        bindValue="id"
        formControlName="typeId"
        [closeOnSelect]="true"
        [clearable]="false"
      >
      </ng-select>
      <!-- <div class="mt-1" [hidden]="promotionForm.controls['typeId'].valid">
        <small class="text-danger">Select type</small>
      </div> -->
    </div>

    <div class="form-group">
      <label>Description</label>
      <textarea formControlName="description" type="text" class="form-control vertical-expand"></textarea>
    </div>

    <div class="modal-footer">
      <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!promotionForm.valid"></app-modal-buttons>
    </div>
  </div>
</form>
