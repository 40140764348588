<form name="importProgram">
  <div class="modal-header">
    <h1>Import Product</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group row">
      <div class="col-sm-2 float-left">
        <input
          class="upload-input"
          id="upload-input-{{ job.id }}"
          type="file"
          name="file"
          (change)="changeListener($event)"
        />
        <label class="btn btn-default btn-sm" for="upload-input-{{ job.id }}" title="Upload program JSON">
          <fa-icon *ngIf="!isLoading" [icon]="faUpload"></fa-icon>
          <fa-icon *ngIf="isLoading" class="fa-5x fa-spin" [icon]="faSpinner"></fa-icon>
        </label>
      </div>
      <div class="col-sm-10">
        <div
          class="alert"
          [ngClass]="{ 'alert-success': fileNamePlease.success, 'alert-danger': !fileNamePlease.success }"
          *ngIf="fileNamePlease.name"
        >
          <p>{{ fileNamePlease.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" (click)="ok()" [disabled]="!fileNamePlease.success">Import</button>
    <button class="btn btn-warning" type="button" (click)="cancel()">Cancel</button>
  </div>
</form>
