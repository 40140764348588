import { SecurityService } from './../services/security/security.service';
import { AppDataService } from './../app-data.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { AddCampaignModalComponent } from './../add-campaign-modal/add-campaign-modal.component';
import { ProgramService } from 'src/app/services/program.service';
import { AddSponsorshipModalComponent } from './../add-sponsorship-modal/add-sponsorship-modal.component';
import { CampaignService } from './../services/campaign.service';
import { SponsorshipModalComponent } from './../sponsorship-modal/sponsorship-modal.component';
import { PromotionCategoryService } from './../services/promotion-category.service';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PromotionService } from '../services/promotion.service';
import { Filter } from '../data/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faUserPlus, faCalendarPlus, faChevronUp, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { PromotionTypeService } from '../services/promotion-type.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { GlobalEventService } from '../services/global-event.service';
import { PromotionSponsorCampaignTabComponent } from '../promotion-sponsor-campaign-tab/promotion-sponsor-campaign-tab.component';

@Component({
  selector: 'app-packaged-sponsorship',
  templateUrl: './packaged-sponsorship.component.html',
  styleUrls: ['./packaged-sponsorship.component.scss'],
})
export class PackagedSponsorshipComponent implements OnInit {
  @ViewChild(PromotionSponsorCampaignTabComponent) promotionSponsorCampaignTabComponent: PromotionSponsorCampaignTabComponent;
  selectedSponsorId: number;
  sponsorshipTag: string = null;
  packagedSponsorshipStructure: any;
  promCategories: any;
  selectedSponsor: any;
  selectedPromotion: any;
  selectedPromSponsor: any;
  selectedCategory: string;
  filter = new Filter();
  promotionTypes: any;
  show = false;
  type = 'packagedSponsorship';
  defaultStatusFilter: any = {};
  indexValue: number;
  promoSponsorId: number;
  value: boolean = false;
  headername: '';
  // var promoSponsorId = Number($location.search().promoSponsorId);

  colors = [
    'promotion-type-green',
    'promotion-type-red',
    'promotion-type-blue',
    'promotion-type-purple',
    'promotion-type-lightgreen',
    'promotion-type-cyan',
    'promotion-type-beige',
    'promotion-type-yellow',
    'promotion-type-grey',
    'promotion-type-lavender',
    'promotion-type-lemon',
    'promotion-type-pink',
  ];
  defaultStatuses = ['ACTIVE', 'PENDING', 'STARTED'];
  colorCnt = -1;
  activeId = 1;
  faUserPlus = faUserPlus;
  faCalendarPlus = faCalendarPlus;
  faChevronUp = faChevronUp;
  faPencilAlt = faPencilAlt;

  constructor(
    private route: ActivatedRoute,
    private sponsorsService: SponsorsService,
    private promotionCategoryService: PromotionCategoryService,
    private promotionService: PromotionService,
    private modalHelper: NgbModal,
    private promotionTypeService: PromotionTypeService,
    private statusHelperService: StatusHelperService,
    private campaignService: CampaignService,
    private programService: ProgramService,
    private dateHelperService: DateHelperService,
    public globalData: AppDataService,
    private globalEventService: GlobalEventService,
    public securityService: SecurityService
  ) {
    this.globalEventService.getGlobalEvent().subscribe((response: any) => {
      if (response.toComponent === 'packaged-sponsorship') {
        this.promotionChanged(response.data);
      }
    });
  }

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.selectedSponsorId = parseInt(this.route.snapshot.paramMap.get('marketId'), 10);
    this.sponsorshipTag = this.route.snapshot.paramMap.get('sponsorshipTag')
      ? this.route.snapshot.paramMap.get('sponsorshipTag')
      : null;

    var url = this.route.snapshot.routeConfig.path;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.route.queryParams.subscribe((queryParams: any) => {
        this.promoSponsorId = +queryParams.promoSponsorId;
      });
    });

    if (this.selectedSponsorId) {
      this.sponsorsService
        .getPackagedSponsorship(this.selectedSponsorId, { sponsorshipTag: this.sponsorshipTag, format: 'json' })
        .subscribe((data: any) => {
          if (data.success) {
            this.parseResponse(data);
          }
        });
    }

    this.initTypeFilter();

    this.defaultStatusFilter.status = this.defaultStatuses;
  }

  promotionChanged(event: any): void {
    event.isChecked = true;
    this.selectPromotion({ id: event.id, promotionId: event.promotionId });
  }

  parseResponse(data: any): void {
    this.packagedSponsorshipStructure = data.entity;
    this.packagedSponsorshipStructure.promotionSponsors.forEach((element, index) => {
      element.index = index;
    });
    this.packagedSponsorshipStructure.promotionSponsors.forEach((element, value) => {
      element.value = !this.value;
    });
    this.show = true;
    this.sponsorsService.getSponsor(this.selectedSponsorId).subscribe((sponsorData: any) => {
      if (sponsorData.success) {
        this.selectedSponsor = sponsorData.entity;
        const params: any = {
          sponsorId: this.selectedSponsorId,
        };
        this.promotionCategoryService.getAllPromotionCategories(params).subscribe((promData: any) => {
          if (promData.success) {
            this.promCategories = promData.entity.aaData;
            // if they have drilled into this screen from a single promotion sponsorship we need to highlight it
            if (this.promoSponsorId && this.packagedSponsorshipStructure) {
              this.packagedSponsorshipStructure.promotionSponsors.forEach((promoSponsor: any) => {
                if (promoSponsor.id === this.promoSponsorId) {
                  this.selectPromotion(promoSponsor, null);
                }
                if (promoSponsor.children && promoSponsor.children.length > 0) {
                  this.checkChildren(promoSponsor);
                }
              });
            }
          }
        });
      }
    });
    this.getPromotionTypesForTag();
  }

  getPromotionTypesForTag(): void {
    this.promotionTypeService.getPromotionTypesForSponsorshipTag(this.filter).subscribe((data: any) => {
      if (data.success) {
        this.promotionTypes = data.entity;
        this.promotionTypes.forEach((type: any) => {
          type.color = this.getNextColor();
        });
      }

      if (this.packagedSponsorshipStructure && this.promotionTypes) {
        this.packagedSponsorshipStructure.promotionSponsors.forEach((promo: any) => {
          this.promotionTypes.forEach((type: any) => {
            if (promo.promotionType === type.name) {
              promo.typeColor = type.color;
            }
          });
          if (promo.children && promo.children.length > 0) {
            this.assignColorToChildren(promo);
          }
        });
        this.colorCnt = -1;
      }
    });
  }
  selectPromotion(promoSponsor: any, event?: any): any {
    this.promotionService.getPromotion(promoSponsor.promotionId).subscribe((promotionData: any) => {
      if (promotionData.success) {
        this.headername = promotionData.entity.name;
        this.selectedPromotion = promotionData.entity;
        this.sponsorsService
          .getPromotionSponsor(promoSponsor.id, { format: 'json' })
          .subscribe((promoSponsorData: any) => {
            this.selectedPromSponsor = promoSponsorData.entity;
            this.convertDates(this.selectedPromSponsor);
            this.promCategories.forEach((category: any) => {
              if (category.id === this.selectedPromSponsor.categoryId) {
                //this.selectedPromSponsor.category = category.name;
                this.selectedCategory = category.name;
                return;
              }
            });
          });
      }
    });

    if (event) {
      event.stopPropagation();
    }
  }

  checkChildren(promoSponsor: any): any {
    promoSponsor.children.forEach((child: any) => {
      if (child.id === this.promoSponsorId) {
        this.selectPromotion(child, null);
      }
      if (child.children && child.children.length > 0) {
        this.checkChildren(child);
      }
    });
  }

  initTypeFilter(): void {
    this.filter.sponsorshipTag = this.sponsorshipTag;
  }

  assignColorToChildren(promo: any): void {
    promo.children.forEach((child: any) => {
      this.promotionTypes.forEach((type: any) => {
        if (child.promotionType === type.name) {
          child.typeColor = type.color;
        }
      });
      if (child.children && child.children.length > 0) {
        this.assignColorToChildren(child);
      }
    });
  }

  getNextColor(): any {
    if (this.colorCnt > 2) {
      this.colorCnt = -1;
    }
    this.colorCnt++;
    return this.colors[this.colorCnt];
  }

  toggleChildren($event: any): void {
    $($($($event.currentTarget).parents('.list-wrapper')[0]).children('ul')[0]).toggleClass('hidden');
    $($event.currentTarget).toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
    $event.stopPropagation();
  }

  getPackagedSponsorshipStructure(): void {
    this.sponsorsService
      .getPackagedSponsorship(this.selectedSponsorId, { sponsorshipTag: this.sponsorshipTag, format: 'json' })
      .subscribe((data: any) => {
        if (data.success) {
          this.packagedSponsorshipStructure = data.entity;
          this.getPromotionTypesForTag();
        }
      });
  }

  edit(sponsorId: number): void {
    this.sponsorsService.getPromotionSponsor(sponsorId, 'json').subscribe((data: any) => {
      if (data.success) {
        const item = data.entity;
        const additionalAttributes = {
          promCategories: this.promCategories,
        };

        const instance = this.modalHelper.open(SponsorshipModalComponent);
        instance.componentInstance.promoSponsor = data.entity;
        instance.componentInstance.promCategories = this.promCategories;
        instance.componentInstance.successEvent.subscribe(() => {
          this.selectPromotion(data.entity);
        });
      }
    });
  }

  promotionModal(type: string): void {
    const item: any = {};
    item.type = type;

    if (this.type === 'campaign') {
      this.campaignService.getAllCampaigns(this.defaultStatusFilter).subscribe((camp: any) => {
        if (camp.success) {
          item.data = camp.entity.aaData;
          const instance = this.modalHelper.open(AddSponsorshipModalComponent, { windowClass: 'wider-modal-window' });
          instance.componentInstance.item = item;
          instance.componentInstance.personId = this.selectedSponsorId;
          instance.componentInstance.sponsorName = this.selectedSponsor.name;
          instance.componentInstance.successEvent.subscribe(() => {
            this.getPackagedSponsorshipStructure();
          });
        }
      });
    } else {
      this.programService.getAllPrograms(this.defaultStatusFilter).subscribe((prog: any) => {
        if (prog.success) {
          item.data = prog.entity.aaData;
          const instance = this.modalHelper.open(AddSponsorshipModalComponent, { windowClass: 'wider-modal-window' });
          instance.componentInstance.item = item;
          instance.componentInstance.personId = this.selectedSponsorId;
          instance.componentInstance.sponsorName = this.selectedSponsor.name;
          instance.componentInstance.successEvent.subscribe(() => {
            this.getPackagedSponsorshipStructure();
          });
        }
      });
    }
  }

  campaignModal(): void {
    const instance = this.modalHelper.open(AddCampaignModalComponent);
    instance.componentInstance.inputData = {
      promotionSponsorId: this.selectedPromSponsor.id,
      promotionId: this.selectedPromotion.id,
      sponsorId: this.selectedPromSponsor.sponsorPartyId,
    };
    instance.componentInstance.successEvent.subscribe(() => {
      this.getPackagedSponsorshipStructure();
      this.promotionSponsorCampaignTabComponent.getCampaigns(false);
    });
  }

  convertDates(promoSponsor: any): void {
    if (promoSponsor.displayNewStatusUntilDate) {
      promoSponsor.displayNewStatusUntilDate = this.dateHelperService.convertToUTC(
        promoSponsor.displayNewStatusUntilDate
      );
    }
    if (promoSponsor.publishedStartDate) {
      promoSponsor.publishedStartDate = this.dateHelperService.convertToUTC(promoSponsor.publishedStartDate);
    }
    if (promoSponsor.publishedEndDate) {
      promoSponsor.publishedEndDate = this.dateHelperService.convertToUTC(promoSponsor.publishedEndDate);
    }
    if (promoSponsor.processingStartDate) {
      promoSponsor.processingStartDate = this.dateHelperService.convertToUTC(promoSponsor.processingStartDate);
    }
    if (promoSponsor.processingEndDate) {
      promoSponsor.processingEndDate = this.dateHelperService.convertToUTC(promoSponsor.processingEndDate);
    }
    if (promoSponsor.scoreStartDate) {
      promoSponsor.scoreStartDate = this.dateHelperService.convertToUTC(promoSponsor.scoreStartDate);
    }
    if (promoSponsor.scoreEndDate) {
      promoSponsor.scoreEndDate = this.dateHelperService.convertToUTC(promoSponsor.scoreEndDate);
    }
  }
}
