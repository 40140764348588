<div>
  <button class="btn btn-attention float-left" type="button" (click)="doValidation()" title="Click to Validate">
    <fa-icon [icon]="faCheck"></fa-icon>
  </button>
  <label>Click to validate</label>
  <div class="ibox-content">
    <h5>Errors</h5>
    <div class="row">
      <div class="cheetah-msgs cheetah-validation-error" [innerHTML]="validationErrors"></div>
    </div>
    <h5>Warnings</h5>
    <div class="row">
      <div class="cheetah-msgs cheetah-validation-warn" [innerHTML]="validationWarnings"></div>
    </div>
  </div>
</div>
