import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { WorldCurrencyService } from '../../services/world-currency.service';
import { Currency, RewardType } from '../../data/model';
import { RewardTypeService } from '../../services/reward-type.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { faPlus, faTimes, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { RewardTypeModalComponent } from '../reward-type-modal/reward-type-modal.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reward-types-tab',
  templateUrl: './reward-types-tab.component.html',
  styleUrls: ['./reward-types-tab.component.scss'],
})
export class RewardTypesTabComponent implements OnInit {
  lockedTables = false;
  lock: boolean;
  isLoading = false;
  filteredStatuses: any[] = [];
  limit = 20;
  statuses: Choice[];
  currencies: Currency[] = [];
  rewardTypes: RewardType[] = [];
  lengthToCompare: number;
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  searchFilters: SearchFilters;
  filter = new Filter();
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private worldCurrencyService: WorldCurrencyService,
    private rewardTypeService: RewardTypeService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'reward-types-search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Reward Type',
          if: true,
          callback: () => {
            this.rewardTypeModal();
          },
          title: 'New Reward Type',
        },
      ],
      searchCallback: () => {
        this.initNewSearch();
      },
    };
    this.getStatuses();
    this.setCurrencies();
    this.show = true;
  }

  initNewSearch(): void {
    this.initFilter();
    this.getRewardTypes(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  setCurrencies(): void {
    this.worldCurrencyService.getCurrencies().subscribe((data: any) => {
      if (data.success) {
        this.currencies = data.entity;
      }
    });
  }

  getWorldCurrencieName(id: number): any {
    if (!id) {
      return null;
    }
    return this.getWorldCurrencieIndex(id).name;
  }

  getWorldCurrencieIndex(id: number): any {
    // var index = $filter('filter')(scope.currencies, function (currency) {return currency.id === id;})[0];
    const index = this.currencies.filter((currency) => {
      return currency.id === id;
    })[0];

    return index;
  }

  rewardTypeModal(id?: number): void {
    if (id) {
      this.rewardTypeService.getRewardType(id).subscribe((data: any) => {
        if (data.success) {
          const instance = this.modalHelper.open(RewardTypeModalComponent);
          instance.componentInstance.rewardType = data.entity;
          instance.componentInstance.currencies = this.currencies;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initFilter();
            this.getRewardTypes(false);
            instance.close();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(RewardTypeModalComponent);
      instance.componentInstance.currencies = this.currencies;
      instance.componentInstance.successEvent.subscribe(() => {
        this.initFilter();
        this.getRewardTypes(false);
        instance.close();
      });
    }
  }

  getRewardTypes(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.rewardTypeService.getRewardTypes(this.filter).subscribe(
      (data: any) => {
        if (concat) {
          this.rewardTypes = this.rewardTypes.concat(data.entity.aaData);
        } else {
          this.rewardTypes = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      },
      () => {
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  deleteRewardType(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete reward type with ID: ' + id;
    instance.componentInstance.header = 'Delete Reward type?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.rewardTypeService.deleteRewardType(id).subscribe(
        (data: any) => {
          this.responseHelperService.success('Reward type successfully removed', false);
          this.initFilter();
          this.getRewardTypes(false);
          instance.close();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
    });
  }

  filterRewardTypes(keyword: string): void {
    this.initFilter();
    this.filter.search = keyword;
    this.getRewardTypes(false);
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getRewardTypes(false);
  }
}
