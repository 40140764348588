<div>
  <form>
    <label>Issue a reward with a Fixed Amount</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="fixedReward !== null">
        <div *ngFor="let reward of fixedReward; let i = index" class="border-card-padding form-group col-lg-12">
          <button
            class="btn btn-attention float-right"
            type="button"
            (click)="removeConsequence(reward)"
            title="Remove Consequence"
          >
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="reward_{{ reward.rewardCode }}">Reward Amount</label>
              <input
                class="form-control m-b"
                type="number"
                id="reward_{{ reward.rewardCode }}"
                name="reward"
                [(ngModel)]="reward.rewardAmount"
                (ngModelChange)="updateConsequences()"
              />
            </div>
            <div class="form-group">
              <label>Reward Type</label>
              <select
                name="reward_{{ reward.rewardCode }}"
                class="form-control"
                [(ngModel)]="reward.rewardCode"
                (ngModelChange)="updateConsequences()"
              >
                <option
                  *ngFor="let reward of rewardTypes"
                  [selected]="reward.rewardCode === reward.code"
                  [value]="reward.code"
                >
                  {{ reward.code }}
                </option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Fixed Amount Reward
        </button>
      </div>
    </div>
  </form>
</div>
