import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, FormsModule } from '@angular/forms';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AppDataService } from 'src/app/app-data.service';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-config-section',
  templateUrl: './config-section.component.html',
  styleUrls: ['./config-section.component.scss'],
})
export class ConfigSectionComponent implements OnInit {
  @Input() title: string;
  @Input() sectionName: string;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  debouncer: Subject<any[]> = new Subject<any[]>();
  section: any = [];

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private statusHelperService: StatusHelperService,
    private programService: ProgramService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private promotionConfigService: PromotionConfigService,
    private configService: ConfigurationService,
    private promotionService: PromotionService,
    private appDataService: AppDataService
  ) {
    this.debouncer.pipe(debounce(() => interval(10))).subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    this.section.sectionName = this.sectionName;
    this.section.open = false;
  }

  toggleOpen(): void {
    this.section.open = !this.section.open;
    this.debouncer.next(this.section);
  }
}
