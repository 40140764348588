<form [formGroup]="commAttrForm" class="modalstyle">
  <div>
    <div style="margin-bottom: 1.3rem">
      <span class="title">Attributes</span>
    </div>
    <hr style="margin-bottom: 1.5rem" />
    <div class="mb-3">
      <span class="com-title">{{ title }}</span>
    </div>
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="com-label">Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" [readonly]="edit" formControlName="attrName" placeholder="Add name" />
      <div class="mt-0" [hidden]="commAttrForm.controls['attrName'].valid">
        <!-- <small class="text-danger">Please input attribute name</small> -->
      </div>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="com-label">Value <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="attrValue" placeholder="Add value" />
      <div class="mt-0" [hidden]="commAttrForm.controls['attrValue'].valid">
        <!-- <small class="text-danger">Please input value</small> -->
      </div>
    </div>
    <!-- <div class="form-group">
      <small><em>Note: All times are UTC</em></small>
    </div> -->
    <div class="form-group" *ngIf="resourceType === 'party'">
      <div class="d-flex justify-content-between">
        <label class="com-label">Effective date <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <div class="input-group date">
        <input
          class="form-control cursor-pointer bgcolor"
          readonly
          placeholder="mm/dd/yyyy"
          name="effectiveFrom"
          ngbDatepicker
          #effectiveFrom="ngbDatepicker"
          formControlName="effectiveFrom"
          (click)="effectiveFrom.toggle()"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="effectiveFrom.toggle()" type="button">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="resourceType === 'party'">
      <label class="com-label">Expiration date</label>
      <div class="input-group date">
        <input
          class="form-control cursor-pointer bgcolor"
          readonly
          (click)="effectiveTo.toggle()"
          placeholder="mm/dd/yyyy"
          name="effectiveTo"
          ngbDatepicker
          #effectiveTo="ngbDatepicker"
          formControlName="effectiveTo"
          [footerTemplate]="footerTemplateEffectiveTo"
        />
        <ng-template #footerTemplateEffectiveTo>
          <div class="width-100 datePickerTemplate">
            <button class="buttonDateTemplate" (click)="clearDate('effectiveTo'); effectiveTo.toggle()">Clear</button>
          </div>
        </ng-template>

        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="effectiveTo.toggle()" type="button">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="resourceType === 'promotion'">
      <label class="com-label">Display name</label>
      <input type="text" class="form-control" formControlName="displayName" />
    </div>
  </div>
  <div class="d-flex justify-content-end" style="margin-top: 2rem">
    <!-- <button
      class="btn btn-dark-blue"
      type="submit"
      [disabled]="!commAttrForm.valid || !commAttrForm.dirty"
      (click)="ok()"
      title="Save"
    >
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button> -->
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitText]="edit ? 'SAVE' : 'ADD'"></app-modal-buttons>
  </div>
</form>
