<form [formGroup]="adjustmentForm" novalidate>
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Account <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <select class="form-control" formControlName="accountId">
        <option *ngFor="let account of additionalAttributes.accounts" value="{{ account.id }}">
          {{ account.id + "-" + account.rewardType.code }}
        </option>
      </select>
      <!-- <div class="mt-1" [hidden]="adjustmentForm.controls.accountId.valid">
        <small class="text-danger">Select account</small>
      </div> -->
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Amount <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="number" class="form-control" formControlName="amount" />
      <!-- <div class="mt-1" [hidden]="adjustmentForm.controls.amount.valid">
        <small class="text-danger">Please input amount</small>
      </div> -->
    </div>
    <div class="form-group">
      <label>Notes</label>
      <textarea class="form-control vertical-expand" formControlName="txnNotes"></textarea>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ok()" [disabled]="!adjustmentForm.valid">Save</button>
      <button type="button" class="btn btn-warning" (click)="cancel()">Cancel</button>
    </div>
  </div>
</form>
