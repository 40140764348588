<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <h2 class="fullname">Communication Strategy</h2>
          </div>
        </div>
      </div>
      <div class="tabs-container">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Endpoint</a>
            <ng-template ngbNavContent>
              <app-endpoint-tab [commStrategyId]="selectedCommStrategy.id"></app-endpoint-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Campaign</a>
            <ng-template ngbNavContent>
              <app-strategy-campaign-tab [commStrategyId]="selectedCommStrategy.id"></app-strategy-campaign-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>Communication History</a>
            <ng-template ngbNavContent>
              <app-comm-strategy-history-tab [commStrategyId]="selectedCommStrategy.id"></app-comm-strategy-history-tab>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>
