import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  // Next time this component is being changed and tested please consider fixing this lint issue
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-is-there-more]',
  templateUrl: './is-there-more.component.html',
  styleUrls: ['./is-there-more.component.scss'],
})
export class IsThereMoreComponent implements OnInit {
  @Input() numColumns: number;
  @Input() newLengthEvent: any;
  @Input() waitUntilApply: boolean;
  @Input() memberDocuments: boolean;
  newLength: number;
  message: string;
  hide = false;

  @Output() numColumnsChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.waitUntilApply) {
      this.message = 'No more results';
      this.hide = false;
    }
    if (this.memberDocuments) {
      this.newLengthEvent.subscribe((newLengthValue: any) => {
        if (newLengthValue === 0) {
          this.message = 'No more results';
          this.hide = false;
        } else if (newLengthValue < 1) {
          this.message = 'No more results';
          this.hide = false;
        } else {
          this.message = '';
          this.hide = true;
        }
      });
    } else {
      this.newLengthEvent.subscribe((newLengthValue: any) => {
        if (newLengthValue === 0) {
          this.message = 'No more results';
          this.hide = false;
        } else if (newLengthValue < 20) {
          this.message = 'No more results';
          this.hide = false;
        } else {
          this.message = '';
          this.hide = true;
        }
      });
    }
  }
}
