import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getLocations(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'locations', this.setParams(params));
  }

  getAllLocations(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'locations/all', this.setParams(params));
  }

  getLocation(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'locations/' + id);
  }

  createOrUpdateLocation(id: number, data: Location): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'locations/' + id, data);
    }
    return this.http.post(this.globalData.baseUrl + 'locations', data);
  }

  deleteLocation(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'locations/' + id);
  }

  getLocationsByRegex(params): any {
    return this.http.get(this.globalData.baseUrl + 'locations/pattern/', this.setParams(params));
  }
}
