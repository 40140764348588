import { AbstractControl } from '@angular/forms';

/**
 * Renders the first error message or returns the default helperText
 */
export function getHelperTextForControl({
  defaultHelperText,
  control,
}: {
  defaultHelperText: string;
  control?: AbstractControl;
}) {
  if (control) {
    if (control.valid) {
      return null;
    }

    if (control.errors) {
      // Some keys don't contain messages but only true/false values
      const errorKeysWithMessages = Object.keys(control.errors).filter((key) => key !== 'required');
      if (errorKeysWithMessages.length > 0) {
        return control.errors[errorKeysWithMessages[0]];
      }
    }
  }
  return defaultHelperText;
}
