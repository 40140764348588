import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.scss'],
})
export class CampaignDetailsComponent {
  @Input() campaign;

  constructor(public dialogRef: MatDialogRef<CampaignDetailsComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
