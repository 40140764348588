import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ReportClassificationHelperService {
  constructor() {}

  getClassifications(): any {
    return [
      { name: 'Communication', value: 'COMMUNICATION' },
      { name: 'Redemption', value: 'REDEMPTION' },
      { name: 'Program/Activity', value: 'PROGRAM_ACTIVITY' },
      { name: 'Processing/Import', value: 'PROCESSING_IMPORT' },
    ];
  }
}
