<form [formGroup]="attributePairForm">
  <div class="modal-header">
    <h1>Add Attribute Mappings</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Attribute Name</label>
      <input type="text" class="form-control" formControlName="attrNameExpr" />
    </div>
    <div class="form-group">
      <label>Attribute Value</label>
      <input type="text" class="form-control" formControlName="attrValueExpr" />
    </div>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="!attributePairForm.valid"></app-modal-buttons>
  </div>
</form>
