import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { OutreachConfigSchema30 } from '../data/class';
import { DeliveryProtocol, DeliveryOption } from '../data/model';
import { ResponseHelperService } from './helpers/response-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CommunicationsService {
  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  prepareCommunication(params: any, id?: number): any {
    const paramsToSend: any = {};
    paramsToSend.attrName = params.name;
    paramsToSend.attrValue = params.value;
    paramsToSend.commId = id;
  }

  getCommunications(params?: any): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications', this.setParams(params));
  }

  getCommunication(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/' + id);
  }

  getCommunicationStructure(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/' + id + '/structure');
  }

  createOrUpdateCommunications(id: number, data: any): any {
    if (data.id) {
      return this.http.put(this.globalData.baseUrl + 'chirp/communications/' + id, data);
    }
    return this.http.post(this.globalData.baseUrl + 'chirp/communications', data);
  }

  getCommunicationTypes(): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/type');
  }

  getCommunicationsHistory = function (params: any): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/history', this.setParams(params));
  };

  /******************___Segments___*********************/

  addSegmentsToCommunication(params: any): any {
    return this.http.post(this.globalData.baseUrl + 'chirp/communications/' + params.commId + '/segments', params);
  }

  getCommunicationSegmentsTree = function (id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/' + id + '/segments', {
      params: {
        tree: true,
      },
    });
  };

  getSegmentByCode(code: string): any {
    return this.http.get(this.globalData.baseUrl + 'segments/by-code/' + code);
  }

  removeCommunicationSegments = function (commId: number, segmentId: number): any {
    return this.http.delete(this.globalData.baseUrl + 'chirp/communications/' + commId + '/segments/' + segmentId);
  };

  /******************___Attributes___*********************/

  getAttributes(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/' + id + '/attributes/');
  }

  createOrUpdateAttribute(id: number, data: any): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'chirp/communications/attributes/' + id, data);
    }
    return this.http.post(this.globalData.baseUrl + 'chirp/communications/attributes', data);
  }

  deleteAttribute(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'chirp/communications/attributes/' + id);
  }

  getAttribute(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/attribute/' + id);
  }

  /******************___Attachments___*********************/

  getAttachments(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/' + id + '/attachments/');
  }

  getAttachment(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/communications/attachment/' + id);
  }

  createOrUpdateAttachment(id: number, data: any): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'chirp/communications/attachments/' + id, data);
    }
    return this.http.post(
      this.globalData.baseUrl + 'chirp/communications/' + data.communicationId + '/attachments',
      data
    );
  }

  deleteAttachment(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'chirp/communications/attachments/' + id);
  }

  /******************___Delivery Protocols___*********************/

  getDeliveryProtocols(params: any): any {
    return this.http.get(this.globalData.baseChirpUrl + 'delivery-protocol/', this.setParams(params));
  }

  getDeliveryProtocol(id: number): any {
    return this.http.get(this.globalData.baseChirpUrl + 'delivery-protocol/' + id);
  }

  getDeliveryOptions(id: number): any {
    return this.http.get(`${this.globalData.baseChirpUrl}delivery-protocol/${id}/delivery-options`);
  }

  upsertDeliveryProtocol(protocol: DeliveryProtocol): any {
    return this.http.put(this.globalData.baseChirpUrl + 'delivery-protocol/', protocol);
  }

  deleteDeliveryOption(protocolId: number, optionId: number): any {
    return this.http.delete(
      this.globalData.baseChirpUrl + 'delivery-protocol/' + protocolId + '/delivery-options/' + optionId
    );
  }

  upsertDeliveryOptions(id: number, data: any): any {
    return this.http.put(this.globalData.baseChirpUrl + 'delivery-protocol/' + id + '/delivery-options/', data);
  }

  insertDeliveryOptions(deliveryOption: DeliveryOption): any {
    return this.http.post(this.globalData.baseChirpUrl + 'delivery-protocol/delivery-options/', deliveryOption);
  }

  updateDeliveryOptions(deliveryOption: DeliveryOption, changeValue?: boolean): any {
    return this.http.put(
      this.globalData.baseChirpUrl + 'delivery-protocol/delivery-options/',
      deliveryOption,
      this.setParams({ updateValue: changeValue })
    );
  }

  /******************   Misc   *********************/

  sendTestNotification(data: any): any {
    return this.http.post(this.globalData.baseUrl + 'chirp/notification/test', data);
  }

  getAllEndpointTypes(includeInit: boolean): any {
    return this.http.get(
      this.globalData.baseUrl + 'chirp/communication/endpoint',
      this.setParams({ includeInit: includeInit })
    );
  }

  updateRulesConfiguration(communicationId: number, rules: OutreachConfigSchema30): any {
    return this.http.put(this.globalData.baseUrl + 'chirp/communications/' + communicationId + '/rules', rules);
  }
}
