<form #f="ngForm" name="form" class="com-model">
  <div class="com-strategy-modal">
    <div>
      <span class="title">{{ title }}</span>
      <hr class="mb-0" />
    </div>
    <div class="mb-3 mt-4">
      <span class="com-title">{{ isEdit ? "Edit " : "Add " }}outbound batch</span>
    </div>
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="com-label">Name <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="integration.name"
        name="name"
        required
        placeholder="Add name"
      />
      <!-- <div class="mt-sm" [hidden]="integration.name">
            <small class="text-danger">Input Name</small>
          </div> -->
    </div>
    <!-- <div class="form-group">
          <div class="d-flex justify-content-between">
            <label class="com-label">Status <span style="color: red">*</span></label>
            <span class="mb-1">(Required)</span>
          </div>
          <select [(ngModel)]="integration.status" class="form-control" name="status" required>
            <option *ngFor="let option of statuses" value="{{ option }}">{{ option }}</option>
          </select>
          <div class="mt-sm" [hidden]="integration.status">
            <small class="text-danger">Select status</small>
          </div>
        </div> -->

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="com-label">Status <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <ng-select
        class="inputfontsize"
        [items]="statuses"
        bindLabel="name"
        bindValue="id"
        formControlName="newPromotionsId"
        placeholder="Select status"
      ></ng-select>
    </div>

    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label class="com-label">Report <span style="color: red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input
        type="text"
        class="form-control"
        [(ngModel)]="integration.recordDelimiter"
        name="recordDelimiter"
        required
        placeholder="Select report"
      />
      <!-- <select [(ngModel)]="integration.reportId" class="form-control" name="reportId" required>
            <option *ngFor="let report of reports" value="{{ report.id }}" (click)="addReportName(report)">
              {{ report.name }}
            </option>
          </select> -->
      <!-- <div class="mt-sm" [hidden]="integration.reportId">
            <small class="text-danger">Select report</small>
          </div> -->
    </div>

    <div class="d-flex justify-content-between">
      <label class="com-label">Record delimiter <span style="color: red">*</span></label>
      <span class="mb-1">(Required)</span>
    </div>
    <ng-select
      class="inputfontsize"
      [items]="reports"
      bindLabel="name"
      bindValue="id"
      (click)="addReportName(reports)"
      formControlName="newPromotionsId"
      placeholder="Add record delimiter"
    ></ng-select>
    <!-- <div class="mt-sm" [hidden]="integration.recordDelimiter">
      <small class="text-danger">Input record delimiter</small>
        </div> -->
  </div>
  <div class="pop-btn-container margingap">
    <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
    <button class="btn-pop-success" type="submit" (click)="ok()" title="Add">ADD</button>
  </div>
</form>
