<form [formGroup]="channelForm">
  <div class="modal-header div-display">
    <h1>{{ title }}</h1>
    <div *ngIf="campaign">
      <div class="row col-md-12">
        <span class="mt-3">Channel: {{ deliveryProtocol.name }}</span>
      </div>
      <div class="row col-md-12">
        <span class="mt-2">Program: {{ campaign.name }}</span>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Promotion</label>
      </div>
      <input
        disabled="true"
        *ngIf="isDeliveryOption"
        type="text"
        class="form-control input-height"
        placeholder="Promotion"
      />
      <ng-select
        *ngIf="!isDeliveryOption"
        [virtualScroll]="true"
        [items]="promotions"
        [typeahead]="promotionSelect$"
        bindLabel="code"
        bindValue="id"
        name="promotionselect"
        theme="bootstrap"
        formControlName="promotionId"
        (scrollToEnd)="getPromotions(true)"
        placeholder="Select Promotion"
        #select
      >
      </ng-select>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Communication</label>
      </div>
      <ng-select
        *ngIf="!isDeliveryOption"
        [virtualScroll]="true"
        [items]="communications"
        [typeahead]="commSelect$"
        bindLabel="name"
        bindValue="id"
        name="communicationselect"
        theme="bootstrap"
        formControlName="communicationId"
        (scrollToEnd)="getCommunications(true)"
        placeholder="Select Communication"
        #select
      >
      </ng-select>
      <input
        *ngIf="isDeliveryOption"
        disabled="true"
        type="text"
        class="form-control input-height"
        placeholder="Communication"
      />
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Name <span class="color-red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input type="text" class="form-control" formControlName="name" />

      <div class="m-t-xs" [hidden]="channelForm.controls['name'].valid">
        <small class="text-danger">Input setting name</small>
      </div>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>Value <span class="color-red">*</span></label>
        <span class="mb-1">(Required)</span>
      </div>
      <input
        (keyup)="changeValue ? '' : sensitiveValueChanged($event)"
        type="text"
        class="form-control"
        formControlName="value"
      />

      <div class="m-t-xs" [hidden]="channelForm.controls['value'].valid">
        <small class="text-danger">Input setting value</small>
      </div>
    </div>
    <div class="form-group">
      <div class="checkbox checkbox-success">
        <input formControlName="sensitive" id="sensitive" class="mr-2" type="checkbox" name="sensitive" />
        <ng-container>
          <label for="sensitive"> Sensitive Information </label>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-pop-cancel" type="button" (click)="cancel()" title="Cancel">CANCEL</button>
    <button (click)="saveForm()" [disabled]="!channelForm.valid" class="btn btn-save" type="submit" title="Add">
      <span *ngIf="isDeliveryOption">{{ deliveryOption ? "UPDATE" : "ADD" }}</span>
      <span *ngIf="!isDeliveryOption">{{ channelConfig.id ? "UPDATE" : "ADD" }}</span>
    </button>
  </div>
</form>
