import React from "react";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CallFlow } from "../models/model";

export const tagsToDisplay = ["WillGet", "NoGet", "HaveIt", "WillChange", "NoChange", "REPEAT"];

function findTag(tag: string, callflow: CallFlow) {
  let found = false;
  callflow.instructions.map((instruction) => {
    if (instruction.expectedResponses) {
      instruction.expectedResponses.map((expectedResponse) => {
        if (expectedResponse.platformMetaData.tags) {
          expectedResponse.platformMetaData.tags.map((tagInstruction) => {
            if (tagInstruction.toLowerCase().search(tag.toLowerCase()) > -1) {
              found = true;
            }
          });
        }
      });
    }
  });
  return found;
}

export default function UsedTagsTable(props: { callflow: CallFlow }) {
  const { callflow } = props;
  return (
    <TableContainer sx={{ bgcolor: "background.paper", overflow: "hidden" }}>
      <Table
        sx={{
          "& .MuiTableCell-root": {
            fontSize: "13px !important",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "50%" }}>Tag</TableCell>
            <TableCell>Found?</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={"willget-row"}>
            <TableCell>WillGet</TableCell>
            <TableCell style={{ borderLeft: "none" }}>
              {findTag("WillGet", callflow) ? (
                <span style={{ color: "green" }}>FOUND</span>
              ) : (
                <span style={{ color: "red" }}>NOT FOUND</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow key={"noget-row"}>
            <TableCell>NoGet</TableCell>
            <TableCell style={{ borderLeft: "none" }}>
              {findTag("NoGet", callflow) ? (
                <span style={{ color: "green" }}>FOUND</span>
              ) : (
                <span style={{ color: "red" }}>NOT FOUND</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow key={"haveit-row"}>
            <TableCell>HaveIt</TableCell>
            <TableCell style={{ borderLeft: "none" }}>
              {findTag("HaveIt", callflow) ? (
                <span style={{ color: "green" }}>FOUND</span>
              ) : (
                <span style={{ color: "red" }}>NOT FOUND</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow key={"willchange-row"}>
            <TableCell>WillChange</TableCell>
            <TableCell style={{ borderLeft: "none" }}>
              {findTag("WillChange", callflow) ? (
                <span style={{ color: "green" }}>FOUND</span>
              ) : (
                <span style={{ color: "red" }}>NOT FOUND</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow key={"nochange-row"}>
            <TableCell>NoGet</TableCell>
            <TableCell style={{ borderLeft: "none" }}>
              {findTag("NoChange", callflow) ? (
                <span style={{ color: "green" }}>FOUND</span>
              ) : (
                <span style={{ color: "red" }}>NOT FOUND</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow key={"repeat-row"}>
            <TableCell>REPEAT</TableCell>
            <TableCell style={{ borderLeft: "none" }}>
              {findTag("repeat", callflow) ? (
                <span style={{ color: "green" }}>FOUND</span>
              ) : (
                <span style={{ color: "red" }}>NOT FOUND</span>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
