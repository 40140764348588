import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: any, field: string, sortAttributes?: boolean): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    if (sortAttributes) {
      array.sort((a: any, b: any) => {
        if (a.value[0][field].toLowerCase() < b.value[0][field].toLowerCase()) {
          return -1;
        } else if (a.value[0][field].toLowerCase() > b.value[0][field].toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      array.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return array;
  }
}
