import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
})
export class FilterTagsComponent implements OnInit {
  @Input() searchFilters: any;
  @Input() appliedFilters: any;
  @Input() helpers: any;
  @Input() someFiltersNotApplied: any;
  @Input() isPopup: boolean;
  @Input() filterButton: boolean;
  columnsClass: string;
  columnsNotAppliedClass: string;
  faTimes = faTimes;
  tagLabel: string;
  hideTag = false;

  constructor() {}

  ngOnInit(): void {
    this.columnsClass = this.searchFilters.filterColumns ? 'col-md-' + this.searchFilters.filterColumns : 'col-md-10';
    this.columnsNotAppliedClass = this.searchFilters.filterColumns
      ? 'col-md-' + (12 - this.searchFilters.filterColumns)
      : 'col-md-2';

    if (this.searchFilters.segmentMembers === undefined) {
      this.hideTag = true;
    }
  }
  checkArrayOrNot(array): Boolean {
    return Array.isArray(array);
  }

  prepareLabel(placeholder: string): string {
    if (!placeholder || placeholder === '') {
      return '';
    }
    return placeholder.replace(/\.\.\.$/g, '').replace(/:$/g, '') + ': ';
  }
}
