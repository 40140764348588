import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-string',
  templateUrl: './filter-string.component.html',
  styleUrls: ['./filter-string.component.scss'],
})
export class FilterStringComponent {
  @Input() filter: any;
  @Input() section: any;
  @Input() packagepop: boolean;

  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}
}
