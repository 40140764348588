import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root',
})
export class RedemptionService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getRedemptionItems(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'redemptions/' + id + '/items');
  }

  getRedemption(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'redemptions/' + id);
  }

  updateRedemption(id: number, data: any): any {
    return this.http.put<any>(this.globalData.baseUrl + 'redemptions/' + id, data);
  }

  updateRedemptionItem(id: number, itemId: number, data: any): any {
    return this.http.put<any>(this.globalData.baseUrl + 'redemptions/' + id + '/status/' + itemId, data);
  }

  getRedemptions(params?: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'redemptions', this.setParams(params));
  }

  getRedemptionCount(params?: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'redemptions/count', this.setParams(params));
  }

  getRedemptionCost(params?: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'redemptions/cost', this.setParams(params));
  }
}
