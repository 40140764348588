import { AppDataService } from './../../app-data.service';
import { Component, Input } from '@angular/core';
import { faRunning, faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter-header-activity-tab',
  templateUrl: './filter-header-activity-tab.component.html',
  styleUrls: ['./filter-header-activity-tab.component.scss'],
})
export class FilterHeaderActivityTabComponent {
  @Input() searchFilters: any;
  @Input() selectedActivitiesCount: any;
  faRunning = faRunning;
  faSpinner = faSpinner;
  faCheckCircle = faCheckCircle;

  constructor(public globalData: AppDataService) {}
}
