import React from "react";
import Grid from "@iridium/iridium-ui/Grid";
import DataCard, { DataCard as DataCardProps } from "./DataCard";

export interface DataCardContainer {
  organizationStatistics: DataCardProps[];
}

export default function DataCardContainer({ organizationStatistics }: DataCardContainer): JSX.Element {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      {organizationStatistics.map((statistic) => {
        return (
          <DataCard
            key={statistic.title}
            title={statistic.title}
            borderTopColor={statistic.borderTopColor}
            data={statistic.data}
            testId={statistic.testId}
          />
        );
      })}
    </Grid>
  );
}
