import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private location: Location, public router: Router, private route: ActivatedRoute) {}

  navigate(url: string) {
    console.log('navigate to ', url);
    this.location.replaceState(url);
  }
}
