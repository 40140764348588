import { AbstractControl, ValidationErrors } from '@angular/forms';
import { InstrumentType } from 'src/app/data/model';

export const RegexFailureText = 'That does not match the expected format.  Please try again.';

export function validateInstrumentNumber(
  instrumentNumberControl: AbstractControl,
  instrumentTypeControl: AbstractControl
): ValidationErrors {
  const instrumentType = instrumentTypeControl?.value;
  if (
    instrumentType &&
    instrumentNumberControl.value &&
    instrumentType.inputMaskRegex &&
    instrumentType.inputMaskRegex != '^$'
  ) {
    if (!new RegExp(instrumentType.inputMaskRegex).test(instrumentNumberControl.value)) {
      return { regexTest: RegexFailureText };
    }
  }
}

export function getDisplayMask(instrumentType?: InstrumentType): string {
  return instrumentType?.displayFormat || undefined;
}

export function shouldDropSpecialCharacters(instrumentType: InstrumentType): boolean {
  if (!instrumentType || !instrumentType.inputMaskRegex) {
    return false;
  }
  return !instrumentType.inputMaskRegex.includes(']-[');
}

export function getDisplayText(displayMask: string, instrumentNumber: string): string {
  if (!displayMask) {
    return instrumentNumber;
  }
  let strippedInstrument = instrumentNumber.replace('-', '');
  if (displayMask.search('-') === -1) {
    return strippedInstrument;
  }

  let modifiedInstrument = '';
  let instrumentCharacterLocation = 0;
  for (let i = 0; i < displayMask.length; i++) {
    if (displayMask.charAt(i) === '-') {
      modifiedInstrument += '-';
      continue;
    }
    modifiedInstrument += strippedInstrument.charAt(instrumentCharacterLocation);
    instrumentCharacterLocation += 1;
    if (instrumentCharacterLocation > strippedInstrument.length - 1) {
      return modifiedInstrument;
    }
  }
  if (instrumentCharacterLocation != strippedInstrument.length) {
    modifiedInstrument += strippedInstrument.substring(instrumentCharacterLocation, strippedInstrument.length);
  }
  return modifiedInstrument;
}
