import React, { useEffect, useState } from "react";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import ContentWrapper from "../react/components/ContentWrapper";
import { ContentService } from "../services/content/content.service";
import getLogger from "../services/logging/logging.service";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import { Survey } from "../services/content/Survey";
import Typography from "@iridium/iridium-ui/Typography";
import Grid from "@iridium/iridium-ui/Grid/Grid";
import TextField from "@iridium/iridium-ui/TextField/TextField";
import IconButton from "@iridium/iridium-ui/Button/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { ContentStackCredentialsDialog } from "./ContentStackCredentialsDialog";
import Button from "@iridium/iridium-ui/Button/Button";

export type SurveyBuilderViewProps = {
  toastr: ToastrService;
  route: ActivatedRoute;
  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean>;
  contentService: ContentService;
};

type SurveyBuilderState = {
  surveys: Survey[];
  selectedSurvey?: Survey;
  searchText: string;
  isCredentialModalOpen: boolean;
};

type SurveyBuilderDetailState = {
  survey?: Survey;
  paramSurveyId?: string;
};

const logger = getLogger({ namespace: "SurveyBuilderView" });

export function SurveyBuilderView(props: SurveyBuilderViewProps): JSX.Element {
  const [state, setState] = useState<SurveyBuilderState>({ surveys: [], searchText: "", isCredentialModalOpen: false });

  useEffect(() => {
    props.contentService.getSurveys().then((result) => {
      // console.log(`writing to state survey result: ${JSON.stringify(result)}`);
      setState({ ...state, surveys: result });
    });
  }, [setState]);

  function setIsModalOpen(open: boolean) {
    setState({ ...state, isCredentialModalOpen: open });
  }

  function doSearch() {
    props.contentService.getSurveys({ searchText: state.searchText }).then((result) => {
      setState({ ...state, surveys: result });
    });
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
    doSearch();
  }

  function getTable(): JSX.Element {
    return (
      <TableContainer
        sx={{
          fontSize: "13px !important",
          overflowX: "hidden !important",
          mt: 2,
          height: "70vh",
        }}
      >
        <Table
          style={{ width: "100%" }}
          sx={{
            "& .MuiTableCell-root": {
              fontSize: "13px !important",
              border: "1px solid rgba(224, 224, 224, 1)",
            },
          }}
          aria-label="survey builder table"
          size="medium"
        >
          <TableHead sx={{ backgroundColor: (theme) => theme.palette.grey["100"] }}>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="center">Client</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Program ID</TableCell>
              <TableCell align="center">Program SKU</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.surveys.map((surveyRow: Survey, index) => (
              <TableRow
                key={surveyRow.uid}
                hover
                // onClick={() => setState({ ...state, selectedSurvey: surveyRow })}
                onClick={() => props.navigate([surveyRow.uid], { relativeTo: props.route })}
                sx={{ cursor: "pointer" }}
              >
                <TableCell align="left">{surveyRow.title}</TableCell>
                <TableCell align="center">{surveyRow.clientTitle}</TableCell>
                <TableCell align="center">{surveyRow.description}</TableCell>
                <TableCell align="center">{""}</TableCell>
                <TableCell align="center">{""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <ContentWrapper>
        <div>
          <h1>Survey Builder</h1>
          <Grid container alignItems="center">
            <Grid item xs={6} sx={{ marginTop: "2px !important" }} textAlign="left">
              <form onSubmit={handleSearchSubmit}>
                <TextField
                  size="small"
                  aria-label="Search surveys"
                  id="searchSurveys"
                  placeholder="Search"
                  value={state.searchText}
                  type="text"
                  onChange={(event) => setState({ ...state, searchText: event.target.value })}
                  InputProps={{
                    inputProps: {},
                    endAdornment: (
                      <IconButton onClick={() => doSearch()} data-testid={"survey_search_id"}>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              </form>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button onClick={() => setState({ ...state, isCredentialModalOpen: !state.isCredentialModalOpen })}>
                Set Credentials
              </Button>
            </Grid>
          </Grid>
          {getTable()}
          <ContentStackCredentialsDialog
            isModalOpen={state.isCredentialModalOpen}
            setIsModalOpen={setIsModalOpen}
            contentService={props.contentService}
          ></ContentStackCredentialsDialog>
        </div>
      </ContentWrapper>
    </>
  );
}

export function SurveyBuilderDetailView(props: SurveyBuilderViewProps): JSX.Element {
  const [state, setState] = useState<SurveyBuilderDetailState>({});

  useEffect(() => {
    const subscription = props.route.params?.subscribe((params) => {
      props.contentService.getSurveys({ entryUid: params.id }).then((result) => {
        setState({ ...state, survey: result[0] });
      });
    });

    // return subscription?.unsubscribe;
  }, [setState]);

  function getDetail(): JSX.Element {
    if (!state.survey) {
      return null;
    }
    const questions = state.survey?.questions;
    return (
      <div>
        <a onClick={() => props.navigate(["survey-builder"])}>Back to search...</a>
        <h2>{state.survey.title}</h2>

        <TableContainer
          sx={{
            fontSize: "13px !important",
            overflowX: "hidden !important",
            mt: 2,
          }}
        >
          <Table
            style={{ width: "50%" }}
            sx={{
              "& .MuiTableCell-root": {
                fontSize: "13px !important",
                border: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
            aria-label="survey builder table"
            size="medium"
          >
            <TableHead sx={{ backgroundColor: (theme) => theme.palette.grey["100"] }}>
              <TableRow>
                <TableCell align="center">Client</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Program ID</TableCell>
                <TableCell align="center">Program SKU</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={state.survey.uid}>
                <TableCell align="center">{state.survey.clientTitle}</TableCell>
                <TableCell align="center">{state.survey.description}</TableCell>
                <TableCell align="center">{""}</TableCell>
                <TableCell align="center">{""}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          sx={{
            fontSize: "13px !important",
            overflowX: "hidden !important",
            mt: 2,
            height: "70vh",
          }}
        >
          <Table
            style={{ width: "100%" }}
            sx={{
              "& .MuiTableCell-root": {
                fontSize: "13px !important",
                border: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
            aria-label="survey builder table"
            size="medium"
          >
            <TableHead sx={{ backgroundColor: (theme) => theme.palette.grey["100"] }}>
              <TableRow>
                <TableCell align="center">Component</TableCell>
                <TableCell align="center">Content</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={`SurveyIntroRow`}>
                <TableCell size="small" align="left">
                  Survey Intro
                </TableCell>
                <TableCell size="medium" align="left">
                  ( intro placeholder )
                </TableCell>
              </TableRow>
              {questions.map((question, index) => [
                <TableRow key={`${question.uid}-${index.toString()}`}>
                  <TableCell size="small" align="left">{`Q${index + 1}`}</TableCell>
                  <TableCell size="medium" align="left">
                    {[
                      <Typography
                        display="block"
                        style={{ paddingBottom: "10px" }}
                        variant="body1"
                        key={`${question.uid}-${index.toString()}-question`}
                      >
                        {question.questionText}
                      </Typography>,
                    ].concat(
                      question.responses?.map((response, index2) => (
                        <Typography
                          display="block"
                          variant="body2"
                          key={`${question.uid}-${index.toString()}-${index2}`}
                        >
                          {response.responseText}
                        </Typography>
                      )) || []
                    )}
                  </TableCell>
                </TableRow>,
              ])}
              <TableRow key={`SurveyExitRow`}>
                <TableCell size="small" align="left">
                  Survey Exit
                </TableCell>
                <TableCell size="medium" align="left">
                  ( exit placeholder )
                </TableCell>
              </TableRow>
              <TableRow key={`SurveyDisclaimerRow`}>
                <TableCell size="small" align="left">
                  Web Disclaimers
                </TableCell>
                <TableCell size="medium" align="left">
                  ( disclaimer placeholder )
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return (
    <>
      <ContentWrapper>
        <div>
          <h1>Survey Builder</h1>
          {getDetail()}
        </div>
      </ContentWrapper>
    </>
  );
}
