<div class="panel-body">
  <div class="des_container">
    <div class="market_logos">
      <img src="../../../assets/images/Asset 7.png" class="dec_logo" />
    </div>
    <div class="ml-2">
      <span class="font-weight-bold">Programs:</span>&nbsp; The timeframe between outreach to members and their response
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12" *ngIf="show">
      <div class="filters_container">
        <div class="search-input">
          <input
            class="search-input-field"
            [searchFilters]="searchFilters"
            placeholder="Search"
            [(ngModel)]="searchText"
          />
          <mat-icon class="search">search</mat-icon>
        </div>
        <div class="right_container">
          <button class="filter-btn" (click)="FilterModel()">
            FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
          </button>
          <button class="btn-add" (click)="openCampaignModal()">ADD PROGRAM</button>
        </div>
      </div>
      <app-filter-tags
        *ngIf="searchFilters"
        isPopup="true"
        [(filterButton)]="filterButton"
        [(searchFilters)]="searchFilters"
        [(appliedFilters)]="appliedFilters"
      ></app-filter-tags>
      <div class="row">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th style="vertical-align: inherit">Name</th>
              <th style="vertical-align: inherit">Program Id</th>
              <th style="vertical-align: inherit">Code</th>
              <th style="vertical-align: inherit">Program Cap</th>
              <th style="vertical-align: inherit">Communication Strategy</th>

              <th style="vertical-align: inherit">Status</th>
              <th style="vertical-align: inherit">From</th>
              <th style="vertical-align: inherit">Thru</th>
              <th style="vertical-align: inherit">Expiry</th>
              <th style="vertical-align: inherit">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let c of campaigns | filter: searchText" (click)="showCampaign($event, c)">
              <td [attr.title]="c.name">
                <a *ngIf="this.personId" routerLink="{{ url }}/programs/{{ c.id }}">{{ c.name }}</a>
                <span *ngIf="!this.personId">{{ c.name }}</span>
              </td>
              <td>{{ c.externalProgramId }}</td>
              <td [attr.title]="c.code">{{ c.code }}</td>
              <td>{{ c.cap }}</td>
              <td>{{ c.communicationStrategyName }}</td>
              <td>{{ c.status }}</td>
              <td>{{ c.fromDate }}</td>
              <td>{{ c.thruDate }}</td>
              <td>{{ c.expirationDate }}</td>
              <td>
                <div class="actions float-left inline-table">
                  <a class="btn btn-outline-primary btn-sm edit action" (click)="openCampaignModal(c)" title="Edit"
                    ><fa-icon [icon]="faPencilAlt"></fa-icon
                  ></a>
                  <button
                    class="btn btn-outline-primary btn-sm delete action"
                    [disabled]="c.status === 'DELETED'"
                    (click)="deleteCampaign(c)"
                    title="Delete"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="9" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list
        (onScroll)="getCampaigns(true)"
        [lock]="lock"
        [isLoading]="isLoading"
      ></app-add-more-data-to-list>
    </div>
    <!-- <div class="col-lg-3">
      <div class="row justify-content-center">
        <h2><span class="light-blue">Promotions:</span></h2>
      </div>
      <span class="row justify-content-center" [hidden]="promotions.length > 0">No promotions</span>
      <div [hidden]="promotions.length === 0" class="row integration-details-bar"></div>
      <div
        [hidden]="promotions.length === 0"
        class="border-card"
        *ngFor="let prom of promotions; let i = index"
        [ngClass]="{ 'margin-top-10': i > 0 }"
      >
        <a routerLink="/programs/{{ prom.program.id }}/promotions">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span>Name:</span>
              </div>
              <div class="col-md-8 text-right">
                <span>{{ prom.name }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span>Type:</span>
              </div>
              <div class="col-md-8 text-right">
                <span>{{ prom.promotionTypeName }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span>Program:</span>
              </div>
              <div class="col-md-8 text-right">
                <span>{{ prom.program.name }}</span>
              </div>
            </div>
            <div class="row" [hidden]="!prom.promotionCategoryName">
              <div class="col-md-4">
                <span>Category:</span>
              </div>
              <div class="col-md-8 text-right">
                <span>{{ prom.promotionCategoryName }}</span>
              </div>
            </div>
            <div class="row" [hidden]="!(prom.promotionCategoryName &amp;&amp; prom.sponsorCap)">
              <div class="col-md-4">
                <span>Sponsor Cap:</span>
              </div>
              <div class="col-md-8 text-right">
                <span>{{ prom.sponsorCap }}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div [hidden]="promotions.length === 0" class="row integration-details-bar"></div>
      <div class="row member-notes justify-content-center">
        <button
          [hidden]="!selectedCampaign"
          [disabled]="!showMoreButton.active"
          class="btn btn-sm btn-dark-blue"
          type="button"
          (click)="showMore()"
        >
          {{ showMoreButton.name }}
        </button>
      </div>
    </div> -->
  </div>
</div>
