import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';
import { ContentGroup } from '../data/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentGroupService {
  contentGroupUrl = this.globalData.baseUrl + 'content';
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getContentGrops(params: any = {}): Observable<any> {
    return this.http.get(this.globalData.baseUrl + 'content/groups', this.setParams(params));
  }

  getContentGropsByName(name: string): Observable<any> {
    return this.http.get(this.contentGroupUrl, this.setParams({ name }));
  }

  getContentGroupById(id: number): Observable<any> {
    return this.http.get(`${this.contentGroupUrl}/group/find/${id}`);
  }

  setContentGroupToCommunication(id: number, communicationId: number): Observable<any> {
    return this.http.put(`${this.contentGroupUrl}/group/${id}/communication/${communicationId}`, {});
  }

  getContentGroupsByProtocols = function (params: any = {}): any {
    return this.http.get(`${this.contentGroupUrl}/groups/protocol`, { params });
  };

  setContentGroup = function (contentGroup: number): any {
    return this.http.get(`${this.contentGroupUrl}/group/`, { contentGroup });
  };

  deleteContentGrop(id: number): Observable<any> {
    return this.http.delete(this.contentGroupUrl + '/group/' + id);
  }

  updateContentGroup(id: number, contentGroup: ContentGroup): Observable<any> {
    return this.http.put(this.contentGroupUrl + '/group/' + id, contentGroup);
  }

  createContentGroup(contentGroup: ContentGroup): Observable<any> {
    return this.http.post(this.contentGroupUrl + '/group', contentGroup);
  }
}
