import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { PromotionCategory } from 'src/app/data/model';
import { PromotionCategoryModalComponent } from 'src/app/promotion-category-modal/promotion-category-modal.component';
import { SearchFilterComponent } from 'src/app/search-filter/search-filter/search-filter.component';
import { ApplyFilterService } from 'src/app/services/filter-pop-service/applyFilter.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { PromotionCategoryService } from 'src/app/services/promotion-category.service';

@Component({
  selector: 'app-promotion-category-tab',
  templateUrl: './promotion-category-tab.component.html',
  styleUrls: ['./promotion-category-tab.component.scss'],
})
export class PromotionCategoryTabComponent implements OnInit, OnDestroy {
  @Input() sponsor: any;
  @Input() sponsorId: number;
  show: boolean;
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  filteredStatuses: Filter[] = [];
  promotionCategories: any[] = [];
  showMoreButton: any = { name: 'Show More', active: true };
  fileNamePlease: any = { name: '', success: false };
  lockedTables: any = { state: false };
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  helpers: any;
  filterButton: boolean = false;
  filterdata: SearchFilters;
  appliedFilters: Filter[];
  searchText: any;
  nametag: string;
  promotionpop: boolean = false;

  constructor(
    private tableHelperService: TableHelperService,
    private toastr: ToastrService,
    private promotionCategoryService: PromotionCategoryService,
    private modalHelper: NgbModal,
    private title: Title,
    private applyfilterService: ApplyFilterService
  ) {}

  ngOnDestroy(): void {
    if (this.filterdata?.clearAll) {
      this.filterdata?.clearAll();
    }
  }

  ngOnInit(): void {
    this.title.setTitle('Products');
    this.show = true;
    this.searchFilters = {
      formName: 'promoCategorySearch',
      searchCallback: () => {
        this.initNewSearch();
      },
      filters: [
        {
          name: 'search',
          placeholder: 'Add keyword',
          label: 'Keywords',
        },
        {
          name: 'statuses',
          placeholder: 'Select status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          label: 'Status',
        },
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Promotion Category',
          if: true,
          callback: () => {
            this.promotionCategoryModal();
          },
          title: 'New Promotion Category',
        },
      ],
    };
    this.initNewSearch();
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getPromotionCategories(false);
  }

  initNewSearch(): void {
    this.applyfilterService.currentFilterData.subscribe((data) => {
      this.appliedFilters = data;
    });
    this.applyfilterService.filterButtonClicked.subscribe((data: boolean) => {
      this.filterButton = data;
    });
    this.initFilter();
    this.getPromotionCategories(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getPromotionCategories(false);
  }
  getFilters = (existing: Filter): any => {
    this.searchFilters.filters.forEach((filter: Filter) => {
      if (filter.type === 'radio') {
        if (this.helpers.isNullOrEmpty(filter.value)) {
          delete existing[filter.name];
        } else {
          existing[filter.name] = filter.value;
        }
      } else if (filter.value) {
        existing[filter.name] = filter.value;
      } else {
        if (existing[filter.name]) {
          delete existing[filter.name];
        }
      }
    });
    return existing;
  };

  checkArrayOrNot(array): Boolean {
    return Array.isArray(array);
  }
  prepareLabel(placeholder: string): string {
    if (!placeholder || placeholder === '') {
      return '';
    }
    return placeholder.replace(/\.\.\.$/g, '').replace(/:$/g, '') + ': ';
  }

  getPromotionCategories(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.getFilters(this.filter);
    this.promotionCategoryService.getAllPromotionCategories(this.filter).subscribe(
      (data) => {
        if (concat) {
          this.promotionCategories = this.promotionCategories.concat(data.entity.aaData);
        } else {
          this.promotionCategories = data.entity.aaData;
        }
        this.lengthToCompare = data.entity.aaData.length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables.state = data.entity.aaData.length !== this.limit;
        this.lock = this.lockedTables.state;
        this.isLoading = false;
      },
      (err) => {
        this.lockedTables.state = false;
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    if (this.sponsorId) {
      this.filter.sponsorId = this.sponsorId;
    }
  }

  setStatuses(): void {
    this.searchFilters.filters[1].value = this.tableHelperService.extractData(this.filteredStatuses);
  }
  filterPopUp() {
    const filterpop = this.modalHelper.open(SearchFilterComponent, { windowClass: 'myCustomModalClass' });
    filterpop.componentInstance.searchFilters = this.searchFilters;
    filterpop.componentInstance.isPopup = true;
    filterpop.componentInstance.heading = 'Category';
    filterpop.componentInstance.applyFilterClose.subscribe((data) => {
      this.filterdata = data;
      this.applyfilterService.getFunctions(this.filterdata);
    });
  }

  deletePromotionCategory(promotionCategory: PromotionCategory): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete category type with ID: ' + promotionCategory.id;
    instance.componentInstance.header = 'Delete Category?';
    instance.componentInstance.title = 'Category';
    instance.componentInstance.nametag = promotionCategory.name;
    instance.componentInstance.id = promotionCategory.id;
    instance.componentInstance.promotionpop = !this.promotionpop;
    instance.componentInstance.successEvent.subscribe(() => {
      this.promotionCategoryService.deletePromotionCategory(promotionCategory.id).subscribe(() => {
        this.toastr.success('Category removed');
        this.initFilter();
        this.getPromotionCategories(false);
        instance.close();
        promotionCategory.status = 'DELETED';
      });
    });
  }

  promotionCategoryModal(promotionCategory?: PromotionCategory): void {
    const instance = this.modalHelper.open(PromotionCategoryModalComponent);
    instance.componentInstance.promotionCategory = promotionCategory;
    instance.componentInstance.sponsorId = this.sponsorId;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.toastr.success(`Category successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initNewSearch();
        instance.close();
      }
    });
  }
}
