<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="d-flex justify-content-between">
      <label> Available segments: <span style="color: red">*</span></label>
      <span>(Required)</span>
    </div>
    <ng-select
      [(ngModel)]="segment"
      [items]="segments"
      bindLabel="code"
      [hideSelected]="true"
      [closeOnSelect]="true"
      name="segmentFilter"
    >
    </ng-select>
  </div>
  <div class="modal-footer">
    <app-modal-buttons (ok)="ok()" (cancel)="cancel()" [submitDisabled]="f.form.invalid"></app-modal-buttons>
  </div>
</form>
