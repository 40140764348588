import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Campaign, DeliveryOption, DeliveryProtocol } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ChannelConfigurationModalComponent } from 'src/app/communication-tabs/channel-configuration-modal/channel-configuration-modal.component';
import { Filter } from '../../data/class';
import { CampaignService } from '../../services/campaign.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { ChannelConfigurationService } from 'src/app/services/channel-configuration.service';
import { faCheck, faPencilAlt, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-communication-channel-configuration',
  templateUrl: './communication-channel-configuration.component.html',
  styleUrls: ['./communication-channel-configuration.component.scss'],
})
export class CommunicationChannelConfigurationComponent implements OnInit {
  deliveryProtocols: DeliveryProtocol[];
  deliveryOptions: DeliveryOption[];
  channelAttributes: any[];
  programs: any[];
  selectedDeliveryProtocol: DeliveryProtocol;
  selectedCampaign: Campaign;
  filter = new Filter();
  lengthToCompare: number = 10;
  show: boolean = false;
  limit = 10;
  input$ = new Subject<string>();
  faCheck = faCheck;
  faTimes = faTimes;
  faPlus = faPlus;
  faPencil = faPencilAlt;
  faTrash = faTrash;

  constructor(
    private communicationsService: CommunicationsService,
    private channelConfigurationService: ChannelConfigurationService,
    private campaignService: CampaignService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getDeliveryProtocols();
    this.filter.limit = 10;
    this.filter.offset = 0;
  }

  getDeliveryProtocols(): void {
    this.communicationsService.getDeliveryProtocols({includeInit: false}).subscribe(
      (data: any) => {
        this.show = true;
        this.deliveryProtocols = data.entity;
      },
      (e) => {
        this.toastr.error(e.error.error);
      }
    );
  }

  selectDeliveryProtocol(deliveryProtocol: DeliveryProtocol) {
    this.selectedDeliveryProtocol = deliveryProtocol;
    this.filter.offset = 0;
    this.lengthToCompare = 10;
    this.onSearch();
    this.getDeliveryOptions();
    this.getCampaigns(false);
  }

  selectCampaign(campaign: Campaign) {
    this.selectedCampaign = campaign;
    this.getChannelConfiguration();
  }

  getDeliveryOptions() {
    if (this.selectedDeliveryProtocol) {
      this.communicationsService.getDeliveryOptions(this.selectedDeliveryProtocol.id).subscribe((data: any) => {
        this.deliveryOptions = data.entity;
      });
    } else {
      this.deliveryOptions = [];
      this.channelAttributes = [];
      this.selectedCampaign = null;
    }
  }

  getChannelConfiguration() {
    if (this.selectedDeliveryProtocol && this.selectedCampaign) {
      this.channelConfigurationService
        .getAllChannelConfigurationForDeliveryProtocol(this.selectedDeliveryProtocol.id, {
          campaignId: this.selectedCampaign.id,
        })
        .subscribe(
          (data: any) => {
            this.channelAttributes = data.entity;
          },
          (e) => {
            this.toastr.error(e.error.error);
          }
        );
    } else {
      this.channelAttributes = [];
      this.selectedCampaign = null;
    }
  }

  onSearch() {
    this.input$
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => this.campaignService.getAllCampaigns(this.setFilter(term)))
      )
      .subscribe((data: any) => {
        this.programs = data.entity.aaData;
        this.filter.offset += this.limit;
      });
  }

  setFilter(term): Filter {
    this.filter.offset = 0;
    this.lengthToCompare = 10;
    if (term) {
      this.filter.code = term;
    } else {
      delete this.filter.code;
    }
    return this.filter;
  }

  getCampaigns(concat: boolean) {
    if (this.lengthToCompare === this.limit) {
      this.campaignService.getAllCampaigns(this.filter).subscribe(
        (data: any) => {
          if (concat) {
            this.programs = this.programs.concat(data.entity.aaData);
          } else {
            this.programs = data.entity.aaData;
          }
          this.lengthToCompare = data.entity.aaData.length;
          this.filter.offset += this.limit;
        },
        (e) => {
          this.toastr.error(e.error.error);
        }
      );
    }
  }

  removeDeliveryOption(deliveryOption) {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = `Remove Delivery Option with name: ${deliveryOption.name}?`;
    instance.componentInstance.header = 'Remove Delivery Option';
    instance.componentInstance.successEvent.subscribe(() => {
      this.communicationsService.deleteDeliveryOption(this.selectedDeliveryProtocol.id, deliveryOption.id).subscribe(
        () => {
          this.getDeliveryOptions();
          this.responseHelperService.success('Delivery Option Successfully Deleted');
          this.modalHelper.dismissAll();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
    });
  }

  removeChannelConfiguration(channelConfiguration) {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = `Remove Channel Configuration with name: ${channelConfiguration.name}?`;
    instance.componentInstance.header = 'Remove Channel Configuration';
    instance.componentInstance.successEvent.subscribe(() => {
      this.channelConfigurationService.deleteChannelConfiguration(channelConfiguration.id).subscribe(
        () => {
          this.getChannelConfiguration();
          this.responseHelperService.success('Channel Configuration Successfully Deleted');
          this.modalHelper.dismissAll();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
    });
  }

  openChannelConfigurationModal(option, channelConfig): void {
    const instance = this.modalHelper.open(ChannelConfigurationModalComponent);
    if (option) {
      instance.componentInstance.deliveryOption = option;
      instance.componentInstance.isDeliveryOption = true;
    } else if (channelConfig) {
      instance.componentInstance.channelConfig = channelConfig;
      instance.componentInstance.isDeliveryOption = false;
    } else {
      if (this.selectedCampaign) {
        instance.componentInstance.isDeliveryOption = false;
      } else {
        instance.componentInstance.isDeliveryOption = true;
      }
    }
    instance.componentInstance.deliveryProtocol = this.selectedDeliveryProtocol;
    instance.componentInstance.campaign = this.selectedCampaign;
    instance.componentInstance.successEvent.subscribe(() => {
      this.getChannelConfiguration();
      this.getDeliveryOptions();
    });
  }
}
