import { UserPreferencesService } from './../user-preferences.service';
// import { UsersService } from './../users.service';

import { JwtHelperService } from '@auth0/angular-jwt';
import { AppDataService } from './../../app-data.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../configuration.service';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../data/model';
import $ from 'jquery';
import { GlobalEventService } from '../global-event.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  OAUTH_TOKEN = 'token';
  runAuthGrantForSSOUser: undefined;
  jwth = new JwtHelperService();

  allowEventsAndMeasuresTabs: boolean;
  allowProcessingTab: boolean;
  allowFullMemberFunctionality: boolean;
  allowSurveysTab: boolean;
  allowDocumentsTab: boolean;
  allowReportsTab: boolean;
  allowStatusUpdates: boolean;
  allowReverse: boolean;
  allowAdvancedIntegrationFeatures: boolean;
  allowedToEditAttribs: boolean;
  allowedToAddAttribs: boolean;
  deferredCurrentUser: Observable<any>;
  currentUser: User;
  isSSO: Boolean;
  subscribeVariable: Subscription;
  externalOrgCode: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService,
    private globalData: AppDataService,
    private userPreferencesService: UserPreferencesService,
    private globalEventService: GlobalEventService
  ) {}

  authCodeGrant = new Observable((observer: any) => {
    // clear out the existing token FIRST for two reasons:
    // 1. The oAuthInterceptor sticks it in the auth header and the auth request will fail since
    // the endpoint tries to parse the client_id from the Authorization header
    // 2. We don't want to accidentally leave the token hanging around in case
    // this fails and a new user gets an old user's token (shouldn't share machines but...)
    this.cleanOauth();
    let authCode = localStorage.getItem('authCodeInProgress');
    $.post(
      this.globalData.baseUrl + 'v2/auth/oauth/token',
      {
        client_id: 'CheetahAdmin',
        grant_type: 'authorization_code',
        code: authCode,
      },
      (response: any) => {
        console.log(this.jwth.decodeToken(response.accessToken));
        localStorage.setItem('token', response.accessToken);
        localStorage.setItem('lastGoodCode', authCode);
        localStorage.removeItem('authCodeInProgress');
        this.setCurrentUser.subscribe(observer);
        this.router.navigate(['/members']);
      }
    ).fail((resp) => {
      this.setCurrentUser.subscribe(observer);
    });
  });

  setCurrentUser = new Observable<User>((observer: any) => {
    const token = localStorage.getItem('token');
    if (token && !this.isTokenExpired()) {
      const decodedToken = this.jwth.decodeToken(token);
      const user = new User();
      user.username = decodedToken.sub;
      user.id = decodedToken.partyId;
      user.authorities = decodedToken.roles;

      this.currentUser = user;
      this.globalData.loggedIn = true;
      this.globalData.token = token;
      this.globalData.restHeaders.Authorization = 'Bearer ' + token;
      this.globalData.userId = decodedToken.partyId;
      this.globalData.username = decodedToken.sub;
      this.globalData.loggedIn = true;
      this.globalData.isCSR = this.globalData.checkPermission('CSR');
      this.globalData.isLiveAgent = this.globalData.checkPermission('LIVE_AGENT');
      this.globalData.isLiveAgentManager = this.globalData.checkPermission('LIVE_AGENT_MANAGER');
      this.globalData.isPartner = this.globalData.checkPermission('PARTNER');
      this.globalData.isCSRManager = this.globalData.checkPermission('CSR_MANAGER');
      this.globalData.isAccountManager = this.globalData.checkPermission('ACCOUNT_MANAGER');
      this.globalData.isProgramManager = this.globalData.checkPermission('PROGRAM_MANAGER');
      this.globalData.isAdmin = this.globalData.checkPermission('ADMIN');
      this.globalData.requiresPasswordReset = decodedToken.requiresPasswordReset;
      this.currentUser = user;
      this.globalData.username = this.currentUser.username;
      this.route.params.subscribe((params: any) => {
        this.route.queryParams.subscribe((queryParams: any) => {
          this.externalOrgCode = queryParams.externalOrgCode;
          if (this.externalOrgCode !== null && this.externalOrgCode !== undefined) {
            dataToSend.data = { externalOrgCode: this.externalOrgCode };
            dataToSend.toComponent = 'app';
            this.globalEventService.sendGlobalEvent(dataToSend);
          }
        });
      });
      this.initSecurity.subscribe(() => {
        this.configurationService.getConfiguration('USE_ALLOWLIST').subscribe((data: any) => {
          this.globalData.useAllowlist =
            data.success && data.entity && data.entity[0] && data.entity[0].cfgValue.toLowerCase() === 'true';
        });
        this.userPreferencesService.service.init.subscribe(() => {
          this.setPreferenceBasedRoleAdjustments();
          this.globalData.appReady = true;
          observer.next(user);
          observer.complete();
        });
      });
      const dataToSend: any = {};
    } else {
      this.handleLoginError();
      observer.error('No logged in user found');
      observer.complete();
    }
  });

  initSecurity = new Observable((observer: any) => {
    // let other stuff use this observable
    this.globalData.promotionPodType = this.getPromotionView;
    this.getAllowSurveyExport.subscribe(
      (value: any) => {
        this.globalData.allowSurveyExport = value;
        if (this.globalData.isAdmin) {
          this.globalData.allowSurveyExport = true;
        }
        observer.next();
        observer.complete();
      },
      () => {
        this.globalData.allowSurveyExport = false;
        observer.next();
        observer.complete();
      }
    );
  });

  isTokenExpired = (): boolean => {
    let token = localStorage.getItem(this.OAUTH_TOKEN);
    return this.jwth.isTokenExpired(token);
  };

  isLoggedIn = new Observable<boolean>((observer: any) => {
    this.requestCurrentUser.subscribe((user: User) => observer.next(user !== undefined && user !== null));
  });

  requestCurrentUser = new Observable<User>((observer: any) => {
    this.checkIsSSOInstance.then((isSSO) => {
      if (isSSO && this.router.url !== '/login') {
        let authCode = null;

        let currentUrl = $(location).attr('href');
        this.route.params.subscribe((params: any) => {
          this.route.queryParams.subscribe((queryParams: any) => {
            if (this.route.snapshot.queryParams['iPlanetDirectoryPro']) {
              authCode = queryParams.iPlanetDirectoryPro;
            } else {
              authCode = this.getCookie('iPlanetDirectoryPro');
            }

            let lastGoodCode = localStorage.getItem('lastGoodCode');
            let lastBadCode = localStorage.getItem('lastBadCode');
            let notAtLogin = this.router.url.indexOf('login') === -1;
            let authCodeInProgress = localStorage.getItem('authCodeInProgress');

            // a new authCode should trigger a new SSO auth_code grant
            let isNewAuthCode =
              authCode && authCode !== lastGoodCode && authCode !== lastBadCode && authCode !== authCodeInProgress;

            // If not outstanding request, go ahead and create one. if there's an outstanding request for the user,
            // then we should only replace it if we have new authCode OR if it has already been resolved.
            if (isNewAuthCode) {
              localStorage.setItem('authCodeInProgress', authCode);
            }

            // conditions that indicate we should run the auth_code grant again
            let runAuthGrantForSSOUser = notAtLogin && isNewAuthCode;

            if (runAuthGrantForSSOUser) {
              // we have a SSO user with a NEW authCode so run the authCodeGrant
              this.authCodeGrant.subscribe(observer);
            } else {
              // No information exists to run the grant, so they must be logged out
              // or already logged in - try to set the user
              this.setCurrentUser.subscribe(observer);
            }
          });
        });
      } else {
        // regular folks are trying to login via the login screen
        this.setCurrentUser.subscribe(observer);
      }
    });
  });

  handleLoginError = (): void => {
    this.cleanOauth();
    this.globalData.onLogout(false);
    let redirectState =
      this.isSSO || (this.isSSO === undefined && this.router.url !== '/login') ? '/logged-out' : '/login';

    // // TODO: set last location so we can return the user to that page after login
    // if (
    //   this.globalData.appConfig &&
    //   this.globalData.appConfig.ssoLogoutRedirectUrl &&
    //   this.globalData.appConfig.ssoLogoutRedirectUrl !== 'DEFAULT' &&
    //   this.globalData.appConfig.ssoLogoutRedirectUrl !== '${SSO_LOGOUT_REDIRECT_URL-DEFAULT}' &&
    //   this.isSSO
    // ) {
    //   // special redirection for certain clients
    //   var relayState = encodeURIComponent(window.location.href);
    //   window.location.replace(this.globalData.appConfig.ssoLogoutRedirectUrl + '&RelayState=' + relayState);
    // } else {
    this.router.navigate([redirectState]); // redirect to login/logged-out via the state name
    // }
  };

  handleExpiredToken = (): Observable<any> => {
    this.cleanOauth();
    this.globalData.onLogout(false);
    return this.isLoggedIn;
  };

  // Is the current user an administrator?
  isAdmin = new Observable((observer: any) => {
    const isAdmin = !!(this.currentUser && this.currentUser.authorities.indexOf('ADMIN') >= 0);
    observer.next(isAdmin);
  });

  isProgramManager = new Observable((observer: any) => {
    let isProgramManager = !!(this.currentUser && this.currentUser.authorities.indexOf('PROGRAM_MANAGER') >= 0);
    if (!isProgramManager) {
      this.isAdmin.subscribe((result: boolean) => {
        isProgramManager = result;
      });
    }
    observer.next(isProgramManager);
  });

  isAccountManager = new Observable((observer: any) => {
    let isAccountManager = !!(this.currentUser && this.currentUser.authorities.indexOf('ACCOUNT_MANAGER') >= 0);
    if (!isAccountManager) {
      this.isProgramManager.subscribe((result: boolean) => {
        isAccountManager = result;
      });
    }
    observer.next(isAccountManager);
  });

  isCSRManager = new Observable((observer: any) => {
    let isCSRManager = !!(this.currentUser && this.currentUser.authorities.indexOf('CSR_MANAGER') >= 0);
    if (!isCSRManager) {
      this.isAccountManager.subscribe((result: boolean) => {
        isCSRManager = result;
      });
    }
    observer.next(isCSRManager);
  });

  isPartner = new Observable((observer: any) => {
    let isPartner = !!(this.currentUser && this.currentUser.authorities.indexOf('PARTNER') >= 0);
    if (!isPartner) {
      this.isCSRManager.subscribe((result: boolean) => {
        isPartner = result;
      });
    }
    observer.next(isPartner);
  });

  isCSR = new Observable((observer: any) => {
    let isCSR = !!(this.currentUser && this.currentUser.authorities.indexOf('CSR') >= 0);
    if (!isCSR) {
      this.isPartner.subscribe((result: boolean) => {
        isCSR = result;
      });
    }
    observer.next(isCSR);
  });

  cleanOauth = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('PartyExtensions');
    localStorage.removeItem('orgId');
    localStorage.removeItem('externalOrgCode');
    localStorage.removeItem('orgUsername');
    localStorage.removeItem('segmentId');
    localStorage.removeItem('organizationLogoUrl');
    this.userPreferencesService.service.handleLogout();
    this.currentUser = undefined;
    this.globalData.username = undefined;
  };

  isAuthenticated = (): boolean => {
    // we should always have a token if we're logged in - having a currentUser set should not be enough
    const token = localStorage.getItem('token');
    return !!token && !!this.currentUser;
  };

  checkIsSSOInstance = new Promise((resolve) => {
    $.get(this.globalData.baseUrl + 'configuration/SSO', (data: any) => {
      this.isSSO = data.success && data.entity && data.entity === 'true';
      // cookie based flag to override the flag for testing
      if (this.getCookie('qa-sso-flag')) {
        this.isSSO = true;
      }
      resolve(this.isSSO);
    });
  });

  cannotEdit = new Observable((observer: any) => {
    this.configurationService.getConfiguration('CANNOT_EDIT').subscribe(
      (data: any) => {
        observer.next(data.success && data.entity && data.entity.length > 0 && data.entity[0].cfgValue === 'true');
        observer.complete();
      },
      () => {
        observer.error('Error getting cannot edit');
      }
    );
  });

  getPromotionView = new Observable<string>((observer: any) => {
    this.configurationService.getConfiguration('ANTHEM_PROMO_VIEW').subscribe(
      (data: any) => {
        if (data.success && data.entity && data.entity.length > 0 && data.entity[0].cfgValue === 'true') {
          observer.next('anthem-commercial');
        } else {
          observer.next('standard');
        }
        observer.complete();
      },
      () => {
        observer.error('Error getting anthem promo view');
      }
    );
  });

  getAllowSurveyExport = new Observable<string>((observer: any) => {
    this.configurationService.getConfiguration('ALLOW_SURVEY_EXPORT').subscribe(
      (data: any) => {
        if (data.success && data.entity && data.entity.length > 0 && data.entity[0].cfgValue === 'true') {
          observer.next(true);
        } else if (this.globalData.isAdmin) {
          observer.next(true);
        } else {
          observer.next(false);
        }
        observer.complete();
      },
      () => {
        observer.error('Error getting allow survey export');
      }
    );
  });

  allowEmulate = new Observable((observer: any) => {
    this.configurationService.getConfiguration('ALLOW_EMULATE').subscribe(
      (data: any) => {
        observer.next(data.success && data.entity && data.entity.length > 0 && data.entity[0].cfgValue === 'true');
        observer.complete();
      },
      () => {
        observer.error('Error getting allow emulate');
      }
    );
  });

  disableTemplateEdit = new Observable((observer: any) => {
    this.configurationService.getConfiguration('DISABLE_TEMPLATE_EDITS').subscribe(
      (data: any) => {
        observer.next(data.success && data.entity && data.entity.length > 0 && data.entity[0].cfgValue === 'true');
        observer.complete();
      },
      () => {
        observer.error('Error getting disable template edits');
      }
    );
  });

  useCSVReports = new Observable((observer: any) => {
    this.configurationService.getConfiguration('CSV_REPORTS').subscribe(
      (data: any) => {
        observer.next(data.success && data.entity && data.entity.length > 0 && data.entity[0].cfgValue === 'true');
        observer.complete();
      },
      () => {
        observer.error('Error getting CSV reports');
      }
    );
  });

  private setPreferenceBasedRoleAdjustments() {
    const allowProcessingTabRoleFunction = this.userPreferencesService.service.getPreference(
      'layout.allowProcessingTabRole'
    );
    if (this[allowProcessingTabRoleFunction] && this[allowProcessingTabRoleFunction].subscribe) {
      this[allowProcessingTabRoleFunction].subscribe((result: boolean) => {
        this.allowProcessingTab = result;
      });
    } else {
      this.allowProcessingTab = this.globalData.isCSRManager;
    }
    const allowFullMemberFunctionalityRoleFunction = this.userPreferencesService.service.getPreference(
      'layout.allowFullMemberFunctionalityRole'
    );
    if (this[allowFullMemberFunctionalityRoleFunction] && this[allowFullMemberFunctionalityRoleFunction].subscribe) {
      this[allowFullMemberFunctionalityRoleFunction].subscribe((result: boolean) => {
        this.allowFullMemberFunctionality = result;
      });
    } else {
      this.allowFullMemberFunctionality = false;
    }
    const allowReportsTabRoleFunction = this.userPreferencesService.service.getPreference('layout.allowReportsTabRole');
    if (this[allowReportsTabRoleFunction] && this[allowReportsTabRoleFunction].subscribe) {
      this[allowReportsTabRoleFunction].subscribe((result: boolean) => {
        this.allowReportsTab = result;
      });
    } else {
      this.allowReportsTab = false;
    }
    const allowEventsAndMeasuresRoleFunction = this.userPreferencesService.service.getPreference(
      'layout.allowEventsAndMeasuresRole'
    );
    if (this[allowEventsAndMeasuresRoleFunction] && this[allowEventsAndMeasuresRoleFunction].subscribe) {
      this[allowEventsAndMeasuresRoleFunction].subscribe((result: boolean) => {
        this.allowEventsAndMeasuresTabs = result;
      });
    } else {
      this.allowEventsAndMeasuresTabs = true;
    }
    const allowStatusUpdatesRoleFunction = this.userPreferencesService.service.getPreference(
      'layout.allowStatusUpdatesRole'
    );
    if (this[allowStatusUpdatesRoleFunction] && this[allowStatusUpdatesRoleFunction].subscribe) {
      this[allowStatusUpdatesRoleFunction].subscribe((result: boolean) => {
        this.allowStatusUpdates = result;
      });
    } else {
      this.allowStatusUpdates = this.globalData.isCSRManager;
    }
    const allowReverseRoleFunction = this.userPreferencesService.service.getPreference('layout.allowReverseRole');
    if (this[allowReverseRoleFunction] && this[allowReverseRoleFunction].subscribe) {
      this[allowReverseRoleFunction].subscribe((result: boolean) => {
        this.allowReverse = result;
      });
    } else {
      this.allowReverse = true;
    }
    const allowAdvancedIntegrationFeaturesFunction = this.userPreferencesService.service.getPreference(
      'layout.allowAdvancedIntegrationFeaturesRole'
    );
    if (this[allowAdvancedIntegrationFeaturesFunction] && this[allowAdvancedIntegrationFeaturesFunction].subscribe) {
      this[allowAdvancedIntegrationFeaturesFunction].subscribe((result: boolean) => {
        this.allowAdvancedIntegrationFeatures = result;
      });
    } else {
      this.allowAdvancedIntegrationFeatures = this.globalData.isAdmin;
    }
    const allowedToEditAttribsRoleFunction = this.userPreferencesService.service.getPreference(
      'layout.allowedToEditAttribsRole'
    );
    if (this[allowedToEditAttribsRoleFunction] && this[allowedToEditAttribsRoleFunction].subscribe) {
      this[allowedToEditAttribsRoleFunction].subscribe((result: boolean) => {
        this.allowedToEditAttribs = result;
      });
    } else {
      this.allowedToEditAttribs = this.globalData.isCSRManager && this.globalData.isAccountManager;
    }
    const allowedToAddAttribsRoleFunction = this.userPreferencesService.service.getPreference(
      'layout.allowedToAddAttribsRole'
    );
    if (this[allowedToAddAttribsRoleFunction] && this[allowedToAddAttribsRoleFunction].subscribe) {
      this[allowedToAddAttribsRoleFunction].subscribe((result: boolean) => {
        this.allowedToAddAttribs = result;
      });
    } else {
      this.allowedToAddAttribs = this.globalData.isCSRManager && this.globalData.isProgramManager;
    }
    const allowSurveysTabRoleFunction = this.userPreferencesService.service.getPreference('layout.allowSurveysTabRole');
    if (this[allowSurveysTabRoleFunction] && this[allowSurveysTabRoleFunction].subscribe) {
      this[allowSurveysTabRoleFunction].subscribe((result: boolean) => {
        this.allowSurveysTab = result;
      });
    } else {
      this.allowSurveysTab = this.globalData.isAdmin;
    }
    const allowDocumentsTabRoleFunction =
      this.userPreferencesService.service.getPreference('layout.allowDocumentsTabRole');
    if (this[allowDocumentsTabRoleFunction] && this[allowDocumentsTabRoleFunction].subscribe) {
      this[allowDocumentsTabRoleFunction].subscribe((result: boolean) => {
        this.allowDocumentsTab = result;
      });
    } else {
      this.allowDocumentsTab = this.globalData.isAdmin;
    }
  }

  private redirect(url: string): void {
    url = url || '/';
    this.router.navigateByUrl(url);
  }

  private processResponse(res: any): any {
    return res.data;
  }

  private processError(e: any): void {
    const msg = [];
    if (e.status) {
      msg.push(e.status);
    }
    if (e.statusText) {
      msg.push(e.statusText);
    }
    if (msg.length === 0) {
      msg.push('Unknown Server Error');
    }

    // TODO: return $q.reject(msg.join(' '));
  }

  private getCookie(name: string): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  /*
  private getUserFullName(userId: number): any {
     this.usersService.getUser(userId).subscribe((user: any) => {
        if (user.success) {
          return user.entity.firstName;
        }
    });
  }*/
}
