import React, { useEffect, useState } from "react";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import ContentWrapper from "../react/components/ContentWrapper";
import { ContentService } from "../services/content/content.service";
import getLogger from "../services/logging/logging.service";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Typography from "@iridium/iridium-ui/Typography";
import Grid from "@iridium/iridium-ui/Grid/Grid";
import TextField from "@iridium/iridium-ui/TextField/TextField";
import IconButton from "@iridium/iridium-ui/Button/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Question } from "../services/content/Question";

export type QuestionLibraryViewProps = {
  toastr: ToastrService;
  route: ActivatedRoute;
  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean>;
  contentService: ContentService;
};

type QuestionLibraryState = {
  questions: Question[];
  searchText: string;
};

const logger = getLogger({ namespace: "QuestionLibraryView" });

export function QuestionLibraryView(props: QuestionLibraryViewProps): JSX.Element {
  const [state, setState] = useState<QuestionLibraryState>({ questions: [], searchText: "" });

  useEffect(() => {
    props.contentService.getQuestions().then((result) => {
      setState({ ...state, questions: result });
    });
  }, [setState]);

  function doSearch() {
    props.contentService.getQuestions({ searchText: state.searchText }).then((result) => {
      setState({ ...state, questions: result });
    });
  }

  function handleSearchSubmit(event) {
    event.preventDefault();
    doSearch();
  }

  function getTable(): JSX.Element {
    return (
      <TableContainer
        sx={{
          fontSize: "13px !important",
          overflowX: "hidden !important",
          mt: 2,
          height: "70vh",
        }}
      >
        <Table
          style={{ width: "100%" }}
          sx={{
            "& .MuiTableCell-root": {
              fontSize: "13px !important",
              border: "1px solid rgba(224, 224, 224, 1)",
            },
          }}
          aria-label="question library table"
          size="medium"
        >
          <TableHead sx={{ backgroundColor: (theme) => theme.palette.grey["100"] }}>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Client</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Question Text</TableCell>
              <TableCell align="left">Responses</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.questions.map((questionRow: Question, index) => (
              <TableRow
                key={questionRow.uid}
                hover
                // onClick={() => props.navigate([questionRow.uid], { relativeTo: props.route })}
                sx={{ cursor: "pointer" }}
              >
                <TableCell align="left">{questionRow.title}</TableCell>
                <TableCell align="left">{questionRow.clientTitle}</TableCell>
                <TableCell align="left">{questionRow.description}</TableCell>
                <TableCell align="left">{questionRow.questionText}</TableCell>
                <TableCell align="left">
                  {questionRow.responses?.map((response, index2) => (
                    <Typography
                      display="block"
                      variant="body2"
                      key={`${questionRow.uid}-${index.toString()}-${index2}`}
                    >
                      {response.responseText}
                    </Typography>
                  )) || []}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <>
      <ContentWrapper>
        <div>
          <h1>Question Library</h1>
          <Grid container alignItems="center">
            <Grid item xs={6} sx={{ marginTop: "2px !important" }} textAlign="left">
              <form onSubmit={handleSearchSubmit}>
                <TextField
                  size="small"
                  aria-label="Search questions"
                  id="searchQuestions"
                  placeholder="Search"
                  value={state.searchText}
                  type="text"
                  onChange={(event) => setState({ ...state, searchText: event.target.value })}
                  InputProps={{
                    inputProps: {},
                    endAdornment: (
                      <IconButton onClick={() => doSearch()} data-testid={"question_search_id"}>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Grid>
          {getTable()}
        </div>
      </ContentWrapper>
    </>
  );
}
