import { FieldProperty } from './../../data/class';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-manifest-field-property-form',
  templateUrl: './manifest-field-property-form.component.html',
  styleUrls: ['./manifest-field-property-form.component.scss'],
})
export class ManifestFieldPropertyFormComponent implements OnInit {
  @Input() index: number;
  @Input() fieldProperty: FieldProperty;
  @Output() public fieldPropertyEvent: EventEmitter<any> = new EventEmitter();
  fieldPropertyForm: FormGroup;
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.index !== null) {
      this.fieldPropertyForm = this.formBuilder.group({
        fieldIndex: [this.fieldProperty.fieldIndex],
        fieldName: [this.fieldProperty.fieldName],
        fieldDefault: [this.fieldProperty.fieldDefault],
        hashField: [this.fieldProperty.hashField],
        concatField: [this.fieldProperty.concatField],
        concatDelimiter: [this.fieldProperty.concatDelimiter],
        required: [this.fieldProperty.required],
        fieldLength: [this.fieldProperty.fieldLength],
        fieldRegex: [this.fieldProperty.fieldRegex],
      });
    } else {
      this.fieldPropertyForm = this.formBuilder.group({
        fieldIndex: [''],
        fieldName: [''],
        fieldDefault: [''],
        hashField: [''],
        concatField: [''],
        concatDelimiter: [''],
        required: [false],
        fieldLength: [''],
        fieldRegex: [''],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.fieldPropertyForm.value;
    this.fieldPropertyEvent.emit({ type, index, value });
    if (type === 'add') {
      this.fieldPropertyForm.reset();
    }
  }
}
