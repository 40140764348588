import { Instruction } from '../../models/model';
import { Elements as ReactFlowElements } from 'react-flow-renderer';

type ReactFlowTestData = {
  instructions: Partial<Instruction>[];
  reactFlowElements: ReactFlowElements;
};
export const simple: ReactFlowTestData = {
  instructions: [
    {
      id: 'CALL_START',
      nextInstructionId: 'CALL_END',
    },
    {
      id: 'CALL_END',
    },
  ],
  reactFlowElements: [
    {
      data: {
        instruction: {
          id: 'CALL_START',
          nextInstructionId: 'CALL_END',
        },
        label: 'CALL_START',
      },
      id: 'CALL_START',
      position: {
        x: 0,
        y: 0,
      },
      type: 'default',
    },
    {
      animated: false,
      id: 'edge-CALL_START-CALL_END-CALL_START',
      label: 'CALL_START',
      source: 'CALL_START',
      target: 'CALL_END',
    },
    {
      data: {
        instruction: { id: 'CALL_END' },
        label: 'CALL_END',
      },
      id: 'CALL_END',
      position: {
        x: 0,
        y: 75,
      },
      type: 'default',
    },
  ],
};

export const simpleWithExpectedResponse: ReactFlowTestData = {
  instructions: [
    {
      id: 'CALL_START',
      nextInstructionId: 'QUESTION1',
    },
    {
      id: 'QUESTION1',
      expectedResponses: [
        // @ts-ignore
        {
          id: 'QUESTION-1-YES',
          nextInstructionId: 'QUESTION-1-RESPONSE-YES',
        },
        // @ts-ignore
        {
          id: 'QUESTION-1-NO',
          nextInstructionId: 'QUESTION-1-RESPONSE-NO',
        },
      ],
    },
    {
      id: 'QUESTION-1-RESPONSE-YES',
      nextInstructionId: 'CALL_END',
    },
    {
      id: 'QUESTION-1-RESPONSE-NO',
      nextInstructionId: 'CALL_END',
    },
    {
      id: 'CALL_END',
    },
  ],
  reactFlowElements: [
    {
      data: {
        instruction: {
          id: 'CALL_START',
          nextInstructionId: 'QUESTION1',
        },
        label: 'CALL_START',
      },
      id: 'CALL_START',
      position: {
        x: 100,
        y: 0,
      },
      type: 'default',
    },
    {
      animated: false,
      id: 'edge-CALL_START-QUESTION1-CALL_START',
      label: 'CALL_START',
      source: 'CALL_START',
      target: 'QUESTION1',
    },
    {
      data: {
        instruction: {
          expectedResponses: [
            {
              id: 'QUESTION-1-YES',
              nextInstructionId: 'QUESTION-1-RESPONSE-YES',
            },
            {
              id: 'QUESTION-1-NO',
              nextInstructionId: 'QUESTION-1-RESPONSE-NO',
            },
          ],
          id: 'QUESTION1',
        },
        label: 'QUESTION1',
      },
      id: 'QUESTION1',
      position: {
        x: 100,
        y: 75,
      },
      type: 'default',
    },
    {
      animated: false,
      id: 'edge-QUESTION-1-YES-QUESTION-1-RESPONSE-YES-QUESTION1',
      label: 'QUESTION-1-YES',
      source: 'QUESTION1',
      target: 'QUESTION-1-RESPONSE-YES',
    },
    {
      animated: false,
      id: 'edge-QUESTION-1-NO-QUESTION-1-RESPONSE-NO-QUESTION1',
      label: 'QUESTION-1-NO',
      source: 'QUESTION1',
      target: 'QUESTION-1-RESPONSE-NO',
    },
    {
      data: {
        instruction: {
          id: 'QUESTION-1-RESPONSE-YES',
          nextInstructionId: 'CALL_END',
        },
        label: 'QUESTION-1-RESPONSE-YES',
      },
      id: 'QUESTION-1-RESPONSE-YES',
      position: {
        x: 0,
        y: 150,
      },
      type: 'default',
    },
    {
      animated: false,
      id: 'edge-QUESTION-1-RESPONSE-YES-CALL_END-QUESTION-1-RESPONSE-YES',
      label: 'QUESTION-1-RESPONSE-YES',
      source: 'QUESTION-1-RESPONSE-YES',
      target: 'CALL_END',
    },
    {
      data: {
        instruction: {
          id: 'QUESTION-1-RESPONSE-NO',
          nextInstructionId: 'CALL_END',
        },
        label: 'QUESTION-1-RESPONSE-NO',
      },
      id: 'QUESTION-1-RESPONSE-NO',
      position: {
        x: 200,
        y: 150,
      },
      type: 'default',
    },
    {
      animated: false,
      id: 'edge-QUESTION-1-RESPONSE-NO-CALL_END-QUESTION-1-RESPONSE-NO',
      label: 'QUESTION-1-RESPONSE-NO',
      source: 'QUESTION-1-RESPONSE-NO',
      target: 'CALL_END',
    },
    {
      data: {
        instruction: {
          id: 'CALL_END',
        },
        label: 'CALL_END',
      },
      id: 'CALL_END',
      position: {
        x: 100,
        y: 225,
      },
      type: 'default',
    },
  ],
};
