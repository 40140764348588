<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="ibox">
        <div class="ibox-title">
          <div class="row">
            <div class="col-md-12">
              <!-- <span class="float-right">
                <a
                  class="btn btn-dark-blue btn-sm"
                  routerLink="/integrations/{{ selectedIntegrationId }}/manifest"
                  *ngIf="isProgramManager"
                  title="Edit Manifest"
                >
                  <fa-icon [icon]="faExchangeAlt"></fa-icon>
                </a>
              </span> -->
              <h2 class="fullname">File loads</h2>
            </div>
          </div>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div class="full-height-scroll" full-scroll>
                <div class="row mb-0 mt-3">
                  <div class="filters_container">
                    <div class="search-input1">
                      <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
                      <mat-icon class="search">search</mat-icon>
                    </div>
                    <div class="right_container1">
                      <button class="filter-btn" (click)="FilterModel()">
                        FILTERS<mat-icon class="ml-2">filter_list</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <!-- <app-search-filter #searchFiltersChild [searchFilters]="searchFilters"></app-search-filter> -->
                    <app-filter-tags
                      *ngIf="searchFilters"
                      isPopup="true"
                      [(filterButton)]="filterButton"
                      [(searchFilters)]="searchFilters"
                      [(appliedFilters)]="appliedFilters"
                    ></app-filter-tags>
                    <app-search-filter #searchFiltersRowChild [searchFilters]="searchFiltersRaw"></app-search-filter>
                    <div class="col-sm-8">
                      <i>Searches all files for this integration and returns the 10 most recent results.</i>
                    </div>
                  </div>
                </div>
                <div *ngIf="isAdmin && searchResults !== undefined && rawDataSearchHappening" class="row mt-3">
                  <table class="table table-pointable table-bordered">
                    <thead>
                      <tr>
                        <th class="width-7">Stage load ID</th>
                        <th class="width-10">Created on</th>
                        <th class="width-10">Status</th>
                        <th>Raw data</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let r of searchResults">
                        <td>
                          <a routerLink="/integrations/{{ selectedIntegrationId }}/loads/{{ r.stageLoadId }}/">{{
                            r.stageLoadId
                          }}</a>
                        </td>
                        <td>{{ r.createdOn | date: "yyyy-mm-dd" }}</td>
                        <td>{{ r.status }}</td>
                        <td>{{ r.rawData }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list [isLoading]="isLoadingRaw" [isInMiddle]="true"> </app-add-more-data-to-list>
                <div class="row mt-3">
                  <table class="table table-pointable table-bordered">
                    <thead>
                      <tr>
                        <!-- <th>ID</th> -->
                        <th>File name</th>
                        <th>Load date</th>
                        <th>Record status</th>
                        <th>Staging finishing time</th>
                        <th>Importing finishing time</th>
                        <th>Record count</th>
                        <th>Customer name</th>
                        <!-- <th *ngIf="isAdmin || integration.openAccess">Uploaded By</th> -->
                        <th>Messages</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let load of loads | filter: searchText" (click)="showLoad($event, load)">
                        <!-- <td>
                          <a routerLink="/integrations/{{ selectedIntegrationId }}/loads/{{ load.id }}/">{{
                            load.id
                          }}</a>
                        </td> -->
                        <td title="{{ load.fileName }}" class="word-break-break-all" stat matTooltip="{{ load.id }}">
                          <a routerLink="/integrations/{{ selectedIntegrationId }}/loads/{{ load.id }}/">
                            {{ load.fileName }}</a
                          >
                        </td>
                        <td>{{ load.loadDate }}</td>
                        <td>{{ load.status }}</td>
                        <td>{{ load.stagingFinishTime | date: "short" }}</td>

                        <td>
                          {{ load.importingFinishTime | date: "short" }}
                        </td>
                        <td>{{ load.recordCount }}</td>
                        <td>{{ load.customerName }}</td>
                        <td>{{ load.messages[0].message }}</td>
                        <!-- <td *ngIf="isAdmin || integration.openAccess">{{ load.uploadedBy }}</td> -->
                        <td>
                          <a
                            class="btn btn-outline-primary btn-sm edit action"
                            routerLink="/integrations/{{ selectedIntegrationId }}/loads/{{ load.id }}"
                            title="View Imports"
                          >
                            <fa-icon [icon]="faCopy"></fa-icon>
                          </a>
                          <div
                            class="form-group float-left"
                            *ngIf="
                              isProgramManager &&
                              allowedAdvanced &&
                              (load.status.toUpperCase() === 'STAGED' || load.status.toUpperCase() === 'RETRY')
                            "
                          >
                            <button
                              class="btn btn-dark-blue btn-sm float-left"
                              (click)="startImport(load.id)"
                              *ngIf="
                                allowedAdvanced &&
                                (load.status.toUpperCase() === 'STAGED' || load.status.toUpperCase() === 'RETRY')
                              "
                              title="Import"
                            >
                              <fa-icon [icon]="faFileImport"></fa-icon>
                            </button>
                          </div>
                          <div
                            class="form-group float-left"
                            *ngIf="
                              isProgramManager &&
                              (load.status.toUpperCase() === 'IMPORTING_ERROR' ||
                                load.status.toUpperCase() === 'POSTPROCESSED' ||
                                load.status.toUpperCase() === 'IMPORTED')
                            "
                          >
                            <button
                              class="btn btn-dark-blue btn-sm float-left"
                              (click)="retryImport(load.id)"
                              title="Retry"
                              *ngIf="
                                allowedAdvanced &&
                                (load.status.toUpperCase() === 'IMPORTING_ERROR' ||
                                  load.status.toUpperCase() === 'POSTPROCESSED' ||
                                  load.status.toUpperCase() === 'IMPORTED')
                              "
                            >
                              <fa-icon [icon]="faSyncAlt"></fa-icon>
                            </button>
                          </div>
                          <!-- TODO Check if this should be removed from all integrations or added to all -->
                          <!-- <div
                            class="form-group float-left"
                            *ngIf="integrationType.toUpperCase() === 'TRANSACTION' && isProgramManager"
                          >
                            <button
                              *ngIf="integrationType.toUpperCase() === 'TRANSACTION' && allowedAdvanced"
                              class="btn btn-dark-blue btn-sm float-right"
                              [disabled]="load.status.toUpperCase() !== 'POSTPROCESSED'"
                              (click)="startProcessing(selectedLoad.id)"
                              title="Process"
                            >
                              <fa-icon [icon]="faCog"></fa-icon>
                            </button>
                          </div> -->
                        </td>
                      </tr>
                      <tr app-is-there-more [numColumns]="9" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list (onScroll)="getLoads(true)" [lock]="lock" [isLoading]="isLoading">
                </app-add-more-data-to-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-3" *ngIf="isShownLoad()">
      <div class="ibox" appScrollWithMe>
        <div class="row integration-details-bar"></div>
        <div class="ibox-content">
          <div id="contact-1" class="tab-pane active">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 relative-position">
                    <div>
                      <fa-icon class="fa-3x blue-icon-color" [icon]="faFolderOpen"></fa-icon>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-12 form-group float-right">
                        <span
                          id="status-label"
                          class="badge float-right"
                          [ngClass]="statusHelper[selectedLoad.status]"
                          >{{ selectedLoad.status }}</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 float-left">
                        <h2 class="word-break-break-all">{{ selectedLoad.fileName }}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">

              </div>
            </div>
            <div class="client-detail">
              <strong>Details</strong>
              <ul class="list-group clear-list">
                <li class="list-group-item fist-item">
                  <span class="float-right"> {{ selectedLoad.integrationId }}</span> Integration ID
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.integrationVersion }}</span> Integration Version
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.loadDate }}</span> Load Date
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.loadRun }}</span> File Type
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.customerName }}</span> Customer Name
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.fileName }}</span> File Name
                </li>
                <li class="list-group-item" *ngIf="isAdmin">
                  <span class="float-right"> {{ selectedLoad.uploadedBy }} </span> Uploaded By
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.recordCount }}</span> Record Count
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.createdOn | date: "short" }}</span> Created On
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.stagingFinishTime | date: "short" }}</span> Staging Finish
                  Time
                </li>
                <li class="list-group-item">
                  <span class="float-right"> {{ selectedLoad.importingFinishTime | date: "short" }}</span> Importing
                  Finish Time
                </li>
              </ul>
            </div>
          </div>
          <div class="client-detail" *ngIf="processingLogs?.length">
            <strong>Processing logs</strong>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-pointable table-bordered infinite-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Finished on</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let log of processingLogs">
                      <td>
                        <a [routerLink]="'/processing-log/log'">{{ log.id }}</a>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row integration-details-bar"></div>
    </div> -->
  </div>
</div>
