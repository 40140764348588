import { Component, OnInit, Input } from '@angular/core';
import {
  faPhone,
  faStar,
  faEdit,
  faHome,
  faAt,
  faTrash,
  faEnvelope,
  faPencilAlt,
  faHouseUser,
} from '@fortawesome/free-solid-svg-icons';
import { EmailService } from 'src/app/services/email.service';
import { PhoneService } from 'src/app/services/phone.service';
import { AddressService } from 'src/app/services/address.service';
import { Phone, EmailModel, AddressModel } from 'src/app/data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { AppDataService } from 'src/app/app-data.service';
import { PhoneModalComponent } from 'src/app/member/member-tabs/phone-modal/phone-modal.component';
import { EmailModalComponent } from 'src/app/member/member-tabs/email-modal/email-modal.component';
import { AddressModalComponent } from 'src/app/member/member-tabs/address-modal/address-modal.component';

@Component({
  selector: 'app-contacts-tab',
  templateUrl: './contacts-tab.component.html',
  styleUrls: ['./contacts-tab.component.scss'],
})
export class ContactsTabComponent implements OnInit {
  faPhone = faPhone;
  faStar = faStar;
  faEdit = faEdit;
  faHome = faHome;
  faAt = faAt;
  faHouseUser = faHouseUser;
  faTrash = faTrash;
  faEnvilope = faEnvelope;
  faPencilAlt = faPencilAlt;
  @Input()
  personId: number;
  phones: Phone[];
  emails: EmailModel[];
  addresses: AddressModel[];
  isProgramManager: boolean;

  constructor(
    private emailService: EmailService,
    private modalHelper: NgbModal,
    private toastr: ToastrService,
    private responseHelper: ResponseHelperService,
    private addresService: AddressService,
    private phoneService: PhoneService,
    private appDataService: AppDataService
  ) {}

  ngOnInit(): void {
    this.isProgramManager = this.appDataService.isProgramManager;
    this.setPhones();
    this.setEmails();
    this.setAddresses();
  }

  formatState(state: string): string {
    return state.includes('-') ? state.split('-')[1] : state;
  }

  setPhones(): void {
    this.phoneService.getPartyPhones(this.personId).subscribe((phones: any) => {
      if (phones.success) {
        this.phones = phones.entity;
      }
    });
  }

  setEmails(): void {
    this.emailService.getPartyEmails(this.personId).subscribe((emails: any) => {
      if (emails.success) {
        this.emails = emails.entity;
      }
    });
  }

  setAddresses(): void {
    this.addresService.getPartyAddresses(this.personId).subscribe((addresses: any) => {
      if (addresses.success) {
        this.addresses = addresses.entity;
      }
    });
  }

  phoneModal(id?: number): any {
    this.phoneService.getPhoneTypes().subscribe((types: any) => {
      if (types.success) {
        if (id) {
          this.phoneService.getPhoneByIdAndParty(id, this.personId).subscribe((phone: any) => {
            if (phone.success) {
              const instance = this.modalHelper.open(PhoneModalComponent);
              instance.componentInstance.phone = phone.entity;
              instance.componentInstance.phoneTypes = types.entity;
              instance.componentInstance.partyId = this.personId;
              instance.componentInstance.successEvent.subscribe(() => {
                this.responseHelper.success('Phone successfully updated', true);
                this.setPhones();
              });
            }
          });
        } else {
          const instance = this.modalHelper.open(PhoneModalComponent);
          instance.componentInstance.phoneTypes = types.entity;
          instance.componentInstance.partyId = this.personId;
          instance.componentInstance.successEvent.subscribe(() => {
            this.responseHelper.success('Phone successfully created', true);
            this.setPhones();
          });
        }
      }
    });
  }

  emailModal(id?: number): any {
    if (id) {
      this.emailService.getPartyEmail(id).subscribe((email: any) => {
        if (email.success) {
          const instance = this.modalHelper.open(EmailModalComponent);
          instance.componentInstance.email = email.entity;
          instance.componentInstance.partyId = this.personId;
          instance.componentInstance.successEvent.subscribe(() => {
            this.responseHelper.success('Email successfully updated', true);
            this.setEmails();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(EmailModalComponent);
      instance.componentInstance.partyId = this.personId;
      instance.componentInstance.successEvent.subscribe(() => {
        this.responseHelper.success('Email successfully created', true);
        this.setEmails();
      });
    }
  }

  addressModal(id?: number): any {
    this.addresService.getAddressData().subscribe((data: any) => {
      if (data.success) {
        if (id) {
          this.addresService.getPartyAddress(this.personId, id).subscribe((address: any) => {
            if (address.success) {
              const instance = this.modalHelper.open(AddressModalComponent);
              instance.componentInstance.address = address.entity;
              instance.componentInstance.addressData = data.entity;
              instance.componentInstance.partyId = this.personId;
              instance.componentInstance.numberOfAddresses = this.addresses.length;
              instance.componentInstance.successEvent.subscribe(() => {
                this.responseHelper.success('Address successfully updated', true);
                this.setAddresses();
              });
            }
          });
        } else {
          const instance = this.modalHelper.open(AddressModalComponent);
          let address: AddressModel = new AddressModel();
          address.country = 'US';
          address.addressType = 'Home';
          instance.componentInstance.address = address;
          instance.componentInstance.addressData = data.entity;
          instance.componentInstance.numberOfAddresses = this.addresses.length;
          instance.componentInstance.partyId = this.personId;
          instance.componentInstance.successEvent.subscribe(() => {
            this.responseHelper.success('Address successfully created', true);
            this.setAddresses();
          });
        }
      }
    });
  }

  deletePartyEmail(email: EmailModel, isPrimary: boolean): any {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.header = 'Delete email';
    instance.componentInstance.text = `Delete email address: ${email.emailAddress} ?`;
    instance.componentInstance.successEvent.subscribe(() => {
      if (isPrimary && this.emails.length > 1) {
        this.toastr.error('You must change primary email before deleting it');
      } else {
        this.emailService.deletePartyEmail(email.id).subscribe(
          (data: any) => {
            this.responseHelper.success('Email successfully removed', true);
            this.setEmails();
          },
          (data: any) => {
            this.responseHelper.error(this, data.error.error);
          }
        );
      }
    });
  }

  deletePhone(phone: Phone, isPrimary: boolean): any {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = `Delete phone number: ${phone.id} ?`;
    instance.componentInstance.header = 'Delete phone number';
    instance.componentInstance.successEvent.subscribe(() => {
      if (isPrimary && this.phones.length > 1) {
        this.toastr.error('You must change primary phone before deleting it');
      } else {
        this.phoneService.deletePhone(phone.id, this.personId).subscribe(
          (data: any) => {
            this.responseHelper.success('Phone successfully removed', true);
            this.setPhones();
          },
          (data: any) => {
            this.responseHelper.error(this, data.error.error);
          }
        );
      }
    });
  }

  deletePartyAddress(address: AddressModel, isPrimary: boolean): any {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.header = 'Delete address';
    instance.componentInstance.title = 'Contacts';
    instance.componentInstance.text = 'Delete address: ?';
    instance.componentInstance.successEvent.subscribe(() => {
      if (isPrimary && this.addresses.length > 1) {
        this.toastr.error('You must change primary address before deleting it');
      } else {
        this.addresService.deletePartyAddress(this.personId, address.addressId).subscribe(
          (data: any) => {
            this.responseHelper.success('Address successfully removed', true);
            this.setAddresses();
          },
          (data: any) => {
            this.responseHelper.error(this, data.error.error);
          }
        );
      }
    });
  }
}
