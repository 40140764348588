<div class="panel-body">
  <div class="col-md-12">
    <!-- <app-search-filter [searchFilters]="searchFilters"></app-search-filter> -->
    <div class="filters_container">
      <div class="search-input">
        <input class="search-input-field" placeholder="Search" [(ngModel)]="searchText" />
        <mat-icon class="search">search</mat-icon>
      </div>
      <div class="right_container">
        <button class="filter-btn" (click)="FilterModel()">FILTERS<mat-icon class="ml-2">filter_list</mat-icon></button>
        <button class="btn-add" (click)="promotionTypeModal()">ADD TYPE</button>
      </div>
    </div>
    <app-filter-tags
      *ngIf="searchFilters"
      isPopup="true"
      [(appliedFilters)]="appliedFilters"
      [(filterButton)]="filterButton"
      [(searchFilters)]="searchFilters"
    ></app-filter-tags>
    <div class="row">
      <table class="table table-pointable table-bordered">
        <thead>
          <tr>
            <!-- <th>ID</th> -->
            <th>Name</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pt of promotionTypes | filter: searchText">
            <!-- <td>{{ pt.id }}</td> -->
            <td>{{ pt.name }}</td>
            <td>{{ pt.status }}</td>
            <td>
              <div class="actions inline-table float-left">
                <a class="btn btn-outline-primary btn-sm edit action" (click)="promotionTypeModal(pt)" title="Edit"
                  ><fa-icon [icon]="faPencilAlt"></fa-icon
                ></a>
                <button
                  class="btn btn-outline-primary btn-sm delete action"
                  [disabled]="pt.status === 'DELETED'"
                  (click)="deletePromotionType(pt)"
                  title="Delete"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </td>
          </tr>
          <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent"></tr>
        </tbody>
      </table>
    </div>
    <app-add-more-data-to-list
      (onScroll)="getPromotionType(true)"
      [lock]="lock"
      [isLoading]="isLoading"
    ></app-add-more-data-to-list>
  </div>
</div>
