<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{ errorMsg }}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-12 reminder-modal">
        <h2>Outreach Sequence</h2>
        <div class="custom-group">
          <h3>If Member hasn’t finished promotion(s):</h3>
          <div class="form-group">
            <label>Promotion with Code</label>
            <ng-select
              #ngselect
              [items]="promotions$ | async"
              bindLabel="promotions"
              [trackByFn]="trackByFn"
              [minTermLength]="minLengthTerm"
              [loading]="promotionsLoading"
              typeToSearchText="Please enter {{ minLengthTerm }} or more characters"
              [typeahead]="promotionsInput$"
              [ngModel]="selectedPromotions"
              [multiple]="true"
              [hideSelected]="true"
              [closeOnSelect]="false"
              [clearable]="true"
              (change)="updateNgModel($event)"
            >
            </ng-select>
          </div>
          <h3>In the last</h3>
          <div class="form-group">
            <label>Number of Periods</label>
            <input
              type="number"
              min="0"
              class="form-control"
              name="outreachSequence.numberOfPeriods"
              [(ngModel)]="item.outreachSequence.numberOfPeriods"
            />
          </div>
          <div class="form-group">
            <label>Periods</label>
            <select name="outreachSequence.period" [(ngModel)]="item.outreachSequence.period" class="form-control">
              <option
                [selected]="item.outreachSequence.period === option"
                *ngFor="let option of periods"
                value="{{ option }}"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <h3>Associate with Sequence</h3>
          <div class="form-group">
            <label>Outreach Plan</label>
            <ng-select
              class="inputfontsize"
              [items]="outreach"
              bindLabel="name"
              bindValue="id"
              name="outreachSequence.outreachId"
              [(ngModel)]="item.outreachSequence.outreachId"
            ></ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn-pop-cancel" type="button" (click)="cancel()">CANCEL</button>
    <button class="btn-pop-success" type="submit" (click)="ok()">ADD</button>
  </div>
</form>
